import { chartColor } from '../utils/chartColor';
import { formatDollarsForDisplay } from '../utils/formatCentsForDisplay';
import { Line } from 'react-chartjs-2';
import React from 'react';

export default class BaseLineChart extends React.Component {
	static defaultProps = {
		axisPadding: 10_000,
		isCurrency: true,
	};

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		let labels = [...this.props.xAxisValues];
		let values = [...this.props.values];

		if (this.props.values?.length === 1) {
			labels = [...this.props.xAxisValues, ...this.props.xAxisValues];
			values = [0, ...this.props.values];
		}
		const data = {
			datasets: [
				{
					backgroundColor: chartColor.colors.yellow,
					borderColor: chartColor.colors.yellow,
					data: values,
					fill: false,
					lineTension: 0,
					tension: 0.1,
				},
			],
			labels: labels,
		};

		const max = Math.max(...values);
		const min = Math.min(...values);

		const options = {
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false,
				},
			},
			responsive: true,
			scales: {
				xAxes: {
					ticks: {
						padding: 12,
					},
				},
				yAxes: {
					max: max + this.props.axisPadding,
					min: Math.max(min - this.props.axisPadding, 0),
					ticks: {
						callback: this.props.isCurrency
							? /**
							   * @param {number} value
							   * @returns {string}
							   */
							  (value) => formatDollarsForDisplay(value)
							: undefined,
						padding: 12,
					},
				},
			},
		};

		const plugins = [
			{
				/**
				 * @param {{ ctx: any; chartArea: { left: number; top: number; width: number; height: number; }; }} chart
				 */
				beforeDraw: function (chart) {
					const {
						ctx,
						chartArea: { left, top, width, height },
					} = chart;
					ctx.save();
					ctx.beginPath();
					ctx.rect(left, top, width, height);
					ctx.strokeStyle = chartColor.borderOuterColor;
					ctx.lineWidth = 4;
					ctx.stroke();
					ctx.restore();
				},
			},
		];
		return (
			// @ts-ignore plugins type is not fixed yet it will be updated
			<Line data={data} options={options} plugins={plugins} type="line" />
		);
	}
}
