import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from '../App/Internationalization';

import paths from 'config/local/paths';

export default class AgreesToTermsOfUseLabelContent extends React.Component {
	// eslint-disable-next-line class-methods-use-this
	render() {
		return (
			<React.Fragment>
				{translate('registration.terms.agree')}
				<Link
					rel="noreferrer noopener"
					target="_blank"
					to={paths.termsOfUse}
				>
					{translate('registration.terms.link')}
				</Link>
			</React.Fragment>
		);
	}
}
