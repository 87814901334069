import { withAdministratorService, withGroupService } from 'service-container';
import captureError from 'utils/captureError';
import FieldSearchableSelectAsync from './FieldSearchableSelectAsync';
import React from 'react';
import { array } from '@mooveguru/js-utilities';
class FieldGroupSelect extends React.Component {
	constructor(props) {
		super(props);

		this.groupCountMax = 100;

		this.initialGroup = this.props.initialGroup
			? this.mapGroupsToOptions(array.wrap(this.props.initialGroup))
			: [];

		this.queryGroup = this.queryGroup.bind(this);
		this.mapGroupsToOptions = this.mapGroupsToOptions.bind(this);
	}

	/**
	 * @param {{id: string; name: string; initial?: boolean}[]} groupObjList
	 * @returns {{value: string; label: string; initial?: boolean}[]}
	 */
	mapGroupsToOptions(groupObjList) {
		return groupObjList.map((group) => ({
			value: group.id,
			label: group.name,
		}));
	}

	/**
	 * @param {string} query
	 * @returns {Promise<{value: string; label: string; initial?: boolean}[]>}
	 */
	async queryGroup(query = '') {
		try {
			// Hit API to query available groups
			const data = await this.props.groupService.searchGroup(
				query,
				1,
				this.groupCountMax
			);

			const { data: groupList } = data;

			// Add default group to group list if it isn't already there
			const includesDefault = groupList.find((group) =>
				this.initialGroup?.find(
					(initialGroup) => initialGroup.value === group.id
				)
			)
				? groupList
				: [...this.initialGroup, ...groupList];

			// Remove any excluded groups
			const withoutExcludes = includesDefault
				.filter(Boolean)
				.filter((group) => !this.props.exclude.includes(group.id));

			return this.mapGroupsToOptions(withoutExcludes);
		} catch (error) {
			captureError(error);
		}

		return [];
	}

	render() {
		return (
			<FieldSearchableSelectAsync
				cacheOptions={true}
				className={this.props.className}
				defaultOptions={true}
				defaultValue={this.initialGroup}
				inputClass={this.props.inputClass}
				isClearable={true}
				isMultipleSelect={this.props.isMultipleSelect}
				label={this.props.label}
				labelClass={this.props.labelClass}
				loadOptions={this.queryGroup}
				name={this.props.name}
				onChange={this.props.onChange}
				required={this.props.required}
			/>
		);
	}
}

FieldGroupSelect.defaultProps = {
	exclude: [],
	isClearable: FieldSearchableSelectAsync.defaultProps.isClearable,
	isMultipleSelect: FieldSearchableSelectAsync.defaultProps.isMultipleSelect,
};

export default withAdministratorService(withGroupService(FieldGroupSelect));
