import { chartColor } from '../../../utils/chartColor';
import BaseDonutChart from '../../../shared/BaseDonutChart';
import React from 'react';

export default class ConfidenceChart extends React.Component {
	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const confidence = this.props.confidence;
		const hideInnerText = this.props.hideInnerText;
		const data = {
			datasets: [
				{
					backgroundColor: [
						this.props.muted
							? chartColor?.colors.mutedGreen
							: chartColor?.colors.green,
						chartColor?.colors.gray,
					],
					data: [confidence, 100 - confidence],
					label: 'Confidence',
				},
			],
		};

		return (
			<BaseDonutChart
				data={data}
				hideInnerText={hideInnerText}
				text={confidence}
				type="doughnut"
			/>
		);
	}
}
