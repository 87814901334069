/** @typedef {import('./FieldSlugType').default} Props */

import { translate } from '../../../../App/Internationalization';
import FormField from '../FormField';
import InputSlug from '../../Inputs/Slug/InputSlug';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class FieldSlug extends React.Component {
	static defaultProps = {
		className: FormField.defaultProps.className,
		disabled: InputSlug.defaultProps.disabled,
		help: translate('global.forms.help.slug'),
		inputClass: InputSlug.defaultProps.className,
		labelClass: FormField.defaultProps.labelClass,
		name: InputSlug.defaultProps.name,
		required: InputSlug.defaultProps.required,
	};

	/** @returns {JSX.Element} */
	render() {
		return (
			<FormField
				className={this.props.className}
				help={this.props.help}
				inputName={this.props.name}
				label={
					this.props.label ??
					translate('global.forms.inputs.slug.label')
				}
				labelClass={this.props.labelClass}
				name={this.props.name}
				required={this.props.required}
			>
				<InputSlug
					className={this.props.inputClass}
					disabled={this.props.disabled}
					name={this.props.name}
					required={this.props.required}
				/>
			</FormField>
		);
	}
}
