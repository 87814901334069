import { translate } from '../../../App/Internationalization';
import AgentCard from '../../../App/Homeowner/shared/AgentCard';
import BaseHero from '../../../shared/BaseHero';
import ComparableMarketCard from '../../../App/Homeowner/shared/ComparableMarketCard';
import images from '../../../config/local/images';
import MortgageInformationCard from './MortgageInformationCard';
import PriceHistoryCard from '../../../App/Homeowner/Finances/PriceHistoryCard';
import PropertyValuation from './PropertyValuations';
import React from 'react';
import SeeWhatYouCanGet from '../../../App/shared/HomeValue/SeeWhatYouCanGet';
import TaxHistoryCard from '../../../App/Homeowner/Finances/TaxHistoryCard';
import { withBreakpoint } from '../../../shared/BreakpointProvider';

/** @extends {React.Component<{ readonly isBelowBreakpoint: boolean }>} */
class Finances extends React.Component {
	/**  @returns {JSX.Element} */
	render() {
		const components = {
			agent: <AgentCard key="homeowner-dashboard-agent " />,
			avms: <PropertyValuation key="homeowner-dashboard-avms" />,
			comps: <ComparableMarketCard key="homeowner-dashboard-comps" />,
			marketValue: (
				<SeeWhatYouCanGet key="homeowner-dashboard-marketValue" />
			),
			mortgage: (
				<MortgageInformationCard key="homeowner-dashboard-mortgage" />
			),
			priceHistory: (
				<PriceHistoryCard key="homeowner-dashboard-priceHistory" />
			),
			taxHistory: <TaxHistoryCard key="homeowner-dashboard-taxHistory" />,
		};

		const hero = images.homeowners.finances.header;
		const title = translate('homeowner.pages.finances.title');

		if (this.props.isBelowBreakpoint) {
			return (
				<React.Fragment>
					<BaseHero heroImageUrl={hero} title={title} />
					<section className="app-block-list">
						{components.avms}
						{components.marketValue}
						{components.agent}
						{components.comps}
						{components.mortgage}
						{components.taxHistory}
						{components.priceHistory}
					</section>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<h1 className="hl-ms-4 my-3">{title}</h1>

				<section className="app-responsive-area app-responsive-sidebar-grid">
					<section className="responsive-main app-block-list">
						{components.avms}
						{components.comps}
						{components.taxHistory}
						{components.priceHistory}
					</section>

					<section className="responsive-secondary app-block-list">
						<figure>
							<img alt="" className="illustration" src={hero} />
						</figure>

						{components.marketValue}
						{components.agent}
						{components.mortgage}
					</section>
				</section>
			</React.Fragment>
		);
	}
}

export default withBreakpoint(Finances);
