/**
 * @typedef {import('../Component').FilterValue} Value
 */
import FieldCheckbox from '../../../../../shared/Forms/Fields/FieldCheckbox';
import React from 'react';
import ToggleList from './ToggleList';

/**
 * @extends {ToggleList}
 */
export default class CheckList extends ToggleList {
	_className = 'check';

	/**
	 * @protected
	 * @param {string} name
	 * @param {string} label
	 * @param {Value} value
	 * @returns {React.ReactNode}
	 */
	_renderInput(name, label, value) {
		const inputName = this._inputName(name);
		const id = this._inputId(name, label);

		return (
			<FieldCheckbox
				className="my-2"
				id={id}
				key={name + label}
				label={label}
				name={inputName}
				onChange={this._handleChange}
				required={false}
				value={value}
			/>
		);
	}
}
