/**
 * @typedef {import('../Component').SearchQuery} SearchQuery
 * @typedef {import("./Props").default} Props
 */

import BaseForm from '../../../../../shared/Forms/BaseForm';
import React from 'react';
import SearchBox from '../../../../../shared/Forms/Fields/SearchBox';

/** @extends	{React.Component<Props>} */
export default class SearchFilter extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		this._handleSubmit = this._handleSubmit.bind(this);
		this._handleReset = this._handleReset.bind(this);
	}

	/**
	 * @param {{search: string}} value
	 * @returns {void}
	 */
	_handleSubmit(value) {
		const search = value?.search?.trim();

		if (['', null, undefined].includes(search)) {
			return;
		}

		if (this.props.search === undefined) {
			return;
		}

		/** @type {SearchQuery} */
		const filterList = {};

		this.props.search.forEach((field) => {
			filterList[`search[${field}]`] = search;
		});

		this.props.onSubmit({ ...filterList });
	}

	/** @returns {void} */
	_handleReset() {
		const query = this.props.query;

		const searchKeyExists = Object.keys(query).some(
			(key) => key.match(/^search/) !== null
		);

		if (!searchKeyExists) {
			return;
		}

		this.props.search.forEach((field) => {
			delete query[`search[${field}]`];
		});

		this.props.onSubmit({
			...query,
		});
	}

	render() {
		return (
			<div>
				<BaseForm noRequiredText={true} onSubmit={this._handleSubmit}>
					<SearchBox name="search" onClear={this._handleReset} />
				</BaseForm>
			</div>
		);
	}
}
