//@ts-nocheck
// FILE BEING MOVED TO HomeownerService.js

import apiUrls from 'config/local/api-urls';
import convertStringToTitleCase from 'utils/convertStringToTitleCase';
import convertToNumberIfCoercible from 'utils/convertToNumberIfCoercible';
import convertYearMonthDayToDate from 'utils/convertYearMonthDayToDate';
import HttpClient from '@mooveguru/js-http-client';
import utilities from '@mooveguru/js-utilities';
const httpClient = new HttpClient();

/** @typedef {import('./PropertyData').default} PropertyData */
/** @typedef {import ('../../Marketplace/Property').default} Property */

/**
 * @deprecated
 * @param {string} accessToken
 * @returns {Promise<string|null>}
 */
export async function getHomeownerName(accessToken) {
	const response = await httpClient.get(
		apiUrls.me.homeowner.root,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}

	return parseNameData(response.body.results.name);
}

/**
 * @deprecated
 * @param accessToken
 */
export async function getHomeownerAgent(accessToken) {
	const response = await httpClient.get(
		apiUrls.me.homeowner.agent,
		new Headers({ 'X-Access-Token': accessToken })
	);

	const results = response.body?.agent;

	return !results
		? {}
		: {
				email: results?.email,
				headshotUrl: results?.headshot
					? `${apiUrls.root}/${results?.headshot}`
					: null,
				name: results?.name ? parseNameData(results?.name) : undefined,
				occupation: results?.occupation ?? 'agent',
				phone: {
					landline: results?.phone?.landline,
					mobile: results?.phone?.mobile,
				},
				slug: results?.slug,
				websiteUrl: results?.website_url,
		  };
}

/**
 * @deprecated
 * @param accessToken
 * @returns {Promise<{address: Property[], id: string, isPrimary: boolean, title: string}>}
 */
export async function getHomeownerProperties(accessToken) {
	const response = await httpClient.get(
		apiUrls.me.homeowner.properties.root,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}

	const results = response.body.results;

	return results.map((result) => ({
		address: {
			streetAddress1: utilities.string.convertToTitleCase(
				result.address.street_address_1,
				' '
			),
			streetAddress2: utilities.string.convertToTitleCase(
				result.address.street_address_2,
				' '
			),
			city: utilities.string.convertToTitleCase(result.address.city, ' '),
			state: result.address.state,
			postalCode: result.address.postal_code,
			country: result.address.country,
		},
		id: result.id,
		isPrimary: result.is_primary,
		title: result.title,
	}));
}

/**
 * @deprecated
 * @param accessToken
 */
export async function getHomeownerPrimaryPropertyAddress(accessToken) {
	const response = await httpClient.get(
		apiUrls.me.homeowner.properties.primary,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}

	const results = response.body.results;

	return {
		streetAddress: results.address.street_address_1,
		zip: results.address.postal_code,
	};
}

/**
 * @deprecated
 * @param {string} accessToken
 * @returns {Promise<PropertyData|null>}
 */
export async function getHomeownerPrimaryProperty(accessToken) {
	const data = await getHomeownerPrimaryPropertyData(accessToken);
	const { property, user } = data;

	const primaryHomeownerProperty = user.properties.find(Boolean);

	if (primaryHomeownerProperty === undefined) {
		throw new Error('Homeowner does not have primary Property.');
	}

	return {
		address: {
			streetAddress1: utilities.string.convertToTitleCase(
				property.address.street_address_1,
				' '
			),
			streetAddress2: utilities.string.convertToTitleCase(
				property.address.street_address_2,
				' '
			),
			city: utilities.string.convertToTitleCase(
				property.address.city,
				' '
			),
			state: property.address.state,
			postalCode: property.address.postal_code,
		},
		// TODO: Refactor the details object when API has consistent response.
		//       consumer-portal-frontend may rely on current response format,
		//       do not want to change at this time and risk breaking it.
		id: primaryHomeownerProperty._id ?? null,
		isPrimary: true,
		title: primaryHomeownerProperty.title ?? null,
	};
}

export async function getHomeownerMoveProgress(accessToken) {
	const response = await httpClient.get(
		apiUrls.me.tasks.root,
		new Headers({
			'X-Access-Token': accessToken,
		})
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}

	const results = response.body.results;

	const completedTasksCount = results.movingTasks.filter(
		(task) => task.completed_at !== null
	).length;
	const allTasksCount = results.movingTasks.length;
	const progress = Math.floor(
		100 * (completedTasksCount / allTasksCount || 0)
	);

	return {
		total: allTasksCount,
		completed: completedTasksCount,
		percent: progress,
	};
}

async function getHomeownerPrimaryPropertyData(accessToken) {
	// TODO: The "/property/get-property/" endpoint behaves unexpectedly.
	//       Ideally, there would be one endpoint here that could be hit by
	//       property ID or just return this information for primary property
	//       via access token, without needing the homeowner ID. The server
	//       can already identify the homeowner with the access token.

	const homeownerResponse = await httpClient.get(
		apiUrls.me.homeowner.root,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!homeownerResponse.isOk) {
		throw new Error(homeownerResponse.message);
	}

	const propertyResponse = await httpClient.get(
		`${apiUrls.property['get-property']}/${homeownerResponse.body.results.id}`,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!propertyResponse.isOk) {
		throw new Error(propertyResponse.message);
	}

	return propertyResponse.body.results;
}

// TODO: Move homeowner dashboard over to property service when homeowner gets moved to new service
/**
 * @param {string} accessToken
 * @param {string} propertyId
 * @returns {import('./PropertyProvider/PropertyProvider').Comps[]}
 */
export async function getComparables(accessToken, propertyId) {
	const response = await httpClient.get(
		`${apiUrls.properties.root}/${propertyId}/${apiUrls.properties.comparables}`,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!response.isOk) {
		return {
			listings: [],
			median: {
				listed: null,
				sold: null,
			},
		};
	}

	return {
		image: response.body.image,
		listings: response.body.listings.map((property) => ({
			date: property.date,
			location: {
				address: property.location.address,
				latitude: property.location.latitude,
				longitude: property.location.longitude,
			},
			price: property.price,
			property: {
				bathroomCount: property.property.bathroom_count,
				bedroomCount: property.property.bedroom_count,
				squareFootage: property.property.square_footage,
			},
			status: property.status,
		})),
		median: {
			listed: response.body.median.listed,
			sold: response.body.median.sold,
		},
	};
}

/**
 * @deprecated
 * @param name
 */
function parseNameData(name) {
	const { first, last } = name;

	if (first && last) {
		return `${first} ${last}`;
	}

	if (first || last) {
		return `${first ?? ''}${last ?? ''}`;
	}

	return null;
}
