import { withAuth } from 'shared/AuthProvider';
import { withUserService } from 'service-container';
import ChangePasswordForm from 'App/Profile/ChangePasswordForm';
import React from 'react';
import { translate } from '../Internationalization';

const InjectedChangePasswordForm = withAuth(
	withUserService(ChangePasswordForm)
);

export default class ChangePasswordCard extends React.Component {
	render() {
		return (
			<React.Fragment>
				<h2 className="h-ms-5 mb-6">
					{translate('settings.sections.password')}
				</h2>
				<InjectedChangePasswordForm />
			</React.Fragment>
		);
	}
}
