/**
 * @typedef {import('../../../App/shared/AgentService/Agent').default} Agent
 * @typedef {import('../../../shared/AgentContactService/AgentContact').default} AgentContact
 * @typedef {import('./ContactComponent').ContactsData} ContactsData
 * @typedef {import('./ContactComponent').GroupContactsData} GroupContactsData
 * @typedef {import('./ContactComponent').Props} Props
 * @typedef {import('../../../types/AuthContext').default} Context
 * @typedef {import('./ContactComponent').State} State
 * @typedef {import('../../shared/Query/Filters/Component').QueryParameters} FilterQueryParameters
 * @typedef {import('../../shared/Query/Parameters').default} QueryParameters
 */
import { translate } from '../../Internationalization';
import {
	withAgentContactService,
	withExportService,
} from '../../../service-container';
import { withAuth } from '../../../shared/AuthProvider';
import { withSettings } from '../../../shared/SettingProvider/SettingProvider';
import arrayHasConsecutiveNumbers from '../../../utils/arrayHasConsecutiveNumbers';
import BaseButton from '../../../shared/BaseButton/BaseButton';
import captureError from '../../../utils/captureError';
import ContactsTable from '../shared/ContactsTable';
import ContactTabs from '../../shared/ContactTabs/ContactTabs';
import Feature from '../../../shared/Feature';
import FormWarningMessages from '../../../shared/Forms/Messages/FormWarningMessages';
import images from '../../../config/local/images';
import LinkButton from '../../../shared/LinkButton';
import Loading from '../../../shared/Loading';
import MoverScoreFilter from '../../shared/Query/Filters/MoverScore/MoverScoreFilter';
import NoContent from '../../../shared/NoContent';
import paths from '../../../config/local/paths';
import React from 'react';
import RegisteredFilter from '../../shared/Query/Filters/Registered/RegisteredFilter';
import SearchFilter from '../../shared/Query/Filters/Search/SearchFilter';
import SuccessOrWarningMessage from '../../../shared/Forms/Messages/SucessOrWarningMessage';

const EXPORT_FLAG_NAME = 'agent:export_contacts';

/** @extends {React.Component<Props, State>} */
export class ViewContacts extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		/**
		 * @type {State}
		 */
		this.state = {
			activeGroupName: null,
			activeTab: this.props.location?.state?.activeTab ?? 'allContacts',
			allContactsCount: 0,
			contactList: [],
			currentPageNumber: 1,
			deleteRan: 0,
			errorMessage: null,
			filterQuery: this.#sort,
			hasPendingExport: true,
			groupsContactsCounts: [],
			isGettingData: true,
			lastPageNumber: 0,
			myContactsCount: 0,
			successOrWarningMessage:
				this.props.location?.state?.message ?? null,
			tableIsGettingData: false,
		};

		this._handleExport = this._handleExport.bind(this);
		this._handleSubmitQuery = this._handleSubmitQuery.bind(this);
		this._resetTableAndTabs = this._resetTableAndTabs.bind(this);
		this._setCurrentPageNumber = this._setCurrentPageNumber.bind(this);
		this._setLastPageNumber = this._setLastPageNumber.bind(this);
		this._setAllTabs = this._setAllTabs.bind(this);
		this._setTable = this._setTable.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			const tabCounts =
				await this.props.agentContactService.getContactsTabs();

			const activeTab = tabCounts.groupsContactsCounts?.length
				? 'allContacts'
				: 'myContacts';

			const hasPendingExport = await this.#hasPendingExport();

			this.setState(
				/**
				 * @param {State} prev
				 * @returns {State}
				 */
				(prev) => ({
					...prev,
					activeTab,
					allContactsCount: tabCounts.allContactsCount,
					groupsContactsCounts: tabCounts.groupsContactsCounts,
					hasPendingExport,
					isGettingData: false,
					myContactsCount: tabCounts.myContactsCount,
				})
			);

			return this.#setInitialTable(activeTab);
		} catch (error) {
			captureError(error);

			this.setState(
				/**
				 * @param {State} prev
				 * @returns {State}
				 */
				(prev) => ({
					...prev,
					errorMessage: translate('global.error'),
					isGettingData: false,
				})
			);
		}
	}

	/**
	 * @returns {Promise<boolean>}
	 */
	async #hasPendingExport() {
		if (this.props.settings.features.get(EXPORT_FLAG_NAME)) {
			return this.props.exportService.hasPendingExport();
		}

		return true;
	}

	/**
	 * @param {string} activeTab
	 * @returns {Promise<void>}
	 */
	async #setInitialTable(activeTab) {
		if (activeTab === 'myContacts') {
			return this.#setMyContactsTable();
		}

		return this.#setAllContactsTable();
	}

	/**
	 * @param {Props} prevProps
	 * @param {State} prevState
	 * @returns {Promise<void>}
	 */
	// deepcode ignore ReactIncorrectReturnValue: False error, doesn't return a value
	async componentDidUpdate(prevProps, prevState) {
		if (this.state.currentPageNumber === prevState.currentPageNumber) {
			return;
		}

		await this._setTable();
	}

	/**
	 * @returns {Promise<void>}
	 */
	async _setAllTabs() {
		const tabCounts =
			await this.props.agentContactService.getContactsTabs();

		this.setState({
			allContactsCount: tabCounts.allContactsCount,
			groupsContactsCounts: tabCounts.groupsContactsCounts,
			myContactsCount: tabCounts.myContactsCount,
		});
	}

	/**
	 * @protected
	 * @returns {Promise<void>}
	 */
	async _resetTableAndTabs() {
		const activeTab = this.state.activeTab;

		await this._setTable(activeTab);

		this.setState(
			/**
			 * @param {State} prev
			 * @returns {State}
			 */
			(prev) => ({
				...prev,
				deleteRan: prev.deleteRan + 1,
			})
		);
	}

	/**
	 * @param {string} [tabOrId]
	 * @protected
	 * @returns {Promise<void>}
	 */
	async _setTable(tabOrId) {
		const tab = tabOrId ?? this.state.activeTab;
		this.setState(
			/**
			 * @param {State} prev
			 * @returns {State}
			 */
			(prev) => ({
				...prev,
				activeTab: tab,
				successOrWarningMessage: null,
				tableIsGettingData: true,
			})
		);

		if (tab === 'myContacts') {
			return this.#setMyContactsTable();
		}

		if (tab === 'allContacts') {
			return this.#setAllContactsTable();
		}

		return this.#setGroupContactsTable(tab);
	}

	/**
	 * @protected
	 * @param {number} pageNumber
	 * @returns {void}
	 */
	_setCurrentPageNumber(pageNumber) {
		this.setState(
			/**
			 * @param {State} prev
			 * @returns {State}
			 */
			(prev) => ({ ...prev, currentPageNumber: pageNumber })
		);
	}

	/**
	 * @protected
	 * @param {number} lastPageNumber
	 * @returns {void}
	 */
	_setLastPageNumber(lastPageNumber) {
		this.setState(
			/**
			 * @param {State} prev
			 * @returns {State}
			 */
			(prev) => ({ ...prev, lastPageNumber })
		);
	}

	/**
	 * @returns {Promise<ContactsData>}
	 */
	async #getMyContactsData() {
		let filterQuery = {
			...this.state.filterQuery,
			'filter[office_id]': null,
		};

		if (this.props.auth.agentSubscriptionLevels.includes('agent')) {
			filterQuery = { ...filterQuery, ...this.#subscribedFilterQuery };
		}

		const data = await this.props.agentContactService.getContacts({
			...filterQuery,
			page: this.state.currentPageNumber,
		});

		return data;
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #setMyContactsTable() {
		const myContactsData = await this.#getMyContactsData();

		// The above uses current state
		this.setState((prev) => ({
			...prev,
			activeGroupName: null,
			contactList: myContactsData?.contacts,
			lastPageNumber: myContactsData?.totalPages,
			tableIsGettingData: false,
		}));
	}

	/**
	 * @returns {Promise<ContactsData>}
	 */
	async #getAllContactsData() {
		let filterQuery = { ...this.state.filterQuery };

		if (this.props.auth.agentSubscriptionLevels.includes('agent')) {
			filterQuery = { ...filterQuery, ...this.#subscribedFilterQuery };
		}

		const data = await this.props.agentContactService.getContacts({
			...filterQuery,
			page: this.state.currentPageNumber,
		});

		return data;
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #setAllContactsTable() {
		const allContactsData = await this.#getAllContactsData();

		// The above uses current state
		this.setState(
			/**
			 * @param {State} prev
			 * @returns {State}
			 */
			(prev) => ({
				...prev,
				activeGroupName: null,
				contactList: allContactsData.contacts,
				lastPageNumber: allContactsData?.totalPages,
				tableIsGettingData: false,
			})
		);
	}

	/**
	 * @param {string} id
	 * @returns {Promise<GroupContactsData>}
	 */
	async #getGroupContactsData(id) {
		const isSubscribed = await this.props.auth.getGroupSubscription(id);

		let filterQuery = { ...this.state.filterQuery };

		if (isSubscribed) {
			filterQuery = { ...filterQuery, ...this.#subscribedFilterQuery };
		}

		const data = await this.props.agentContactService.getContactsByGroupId(
			id,
			{
				...filterQuery,
				page: this.state.currentPageNumber,
			}
		);

		return data;
	}

	/**
	 * @param {string} id
	 * @returns {Promise<void>}
	 */
	async #setGroupContactsTable(id) {
		const groupContactsData = await this.#getGroupContactsData(id);

		// The above uses current state
		this.setState(
			/**
			 * @param {State} prev
			 * @returns {State}
			 */
			(prev) => ({
				...prev,
				activeGroupName: groupContactsData.groupName,
				contactList: groupContactsData?.contacts,
				lastPageNumber: groupContactsData?.totalPages,
				tableIsGettingData: false,
			})
		);
	}

	/**
	 * @param {FilterQueryParameters} value
	 * @returns {FilterQueryParameters}
	 */
	static #convertScoreToRange(value) {
		if (value['filter[score]'] === undefined) {
			return value;
		}
		// @ts-ignore
		if (value['filter[score]'].length === 0) {
			delete value['filter[score]'];

			return value;
		}

		/**
		 * @type {string[]}
		 */
		// @ts-ignore
		const valueList = value['filter[score]'] ?? [];
		delete value['filter[score]'];

		// Convert ranges to numbers
		const range = valueList
			.map((numbers) => numbers.split('-'))
			.flat()
			.map((number) => +number);
		/*
		 * If the array has only two or consecutive numbers it means we're within
		 * a range that's either a combination of the two selected ranges (e.g. if
		 * the user selected '80-100' and '40-79' we're within the range of 40 to 100)
		 * or only one selected range.
		 */
		if (range.length === 2 || arrayHasConsecutiveNumbers(range)) {
			// prettier-ignore
			value['range[lead_scores.0.score]'] = `${Math.min(...range)};${Math.max(...range)}`;
			value.compare = 'in';

			return value;
		}

		/*
		 * If the user has selected multiple options that do not have any
		 * consecutive numbers we're dealing with "Likely Movers" and
		 * "Unlikely Movers" having been selected. In that case we're dealing
		 * with the ranges of 80-100 and 0-39. Alternatively, we could say that
		 * we're querying anything that is OUTSIDE of 40-79. In order to convert
		 * these numbers we sort the array, remove the highest and lowest numbers,
		 * and then get the new highest and subtract one and the new lowest and
		 * add one. This will give us 40-79. We are not hardcoding numbers as
		 * these ranges are subject to change. So long as there remain 3 options
		 * this will work with any numbers.
		 */
		range.sort((a, b) => a - b);
		range.shift();
		range.pop();

		const top = Math.max(...range) - 1;
		const bottom = Math.min(...range) + 1;

		value['range[lead_scores.0.score]'] = `${bottom};${top}`;
		value.compare = 'out';

		return value;
	}

	/**
	 * @param {FilterQueryParameters} value
	 * @returns {FilterQueryParameters}
	 */
	static #convertFields(value) {
		return ViewContacts.#convertScoreToRange(value);
	}

	/**
	 * @protected
	 * @param {FilterQueryParameters} value
	 * @returns {FilterQueryParameters}
	 */
	_handleSubmitQuery(value) {
		this.setState(
			/**
			 * @param {State} prev
			 * @returns {State}
			 */
			(prev) => ({
				...prev,
				currentPageNumber: 1,
				filterQuery: ViewContacts.#convertFields({
					...prev.filterQuery,
					...value,
				}),
			}),
			() => {
				this._setTable(this.state.activeTab);
			}
		);

		return value;
	}

	/** @returns {{ 'sort[lead_scores.0.score]': 'desc', 'sort[name.last]': 'asc', 'sort[name.first]': 'asc', }} */
	get #subscribedFilterQuery() {
		return {
			'sort[lead_scores.0.score]': 'desc',
			'sort[name.last]': 'asc',
			// eslint-disable-next-line sort-keys
			'sort[name.first]': 'asc',
		};
	}

	/**
	 * @returns {{ 'sort[lead_scores.0.score]': 'desc', 'sort[name.last]': 'asc', 'sort[name.first]': 'asc', } | {}}
	 */
	get #sort() {
		if (!this?.props?.auth?.agentSubscriptionLevels?.length) {
			return {};
		}

		return this.#subscribedFilterQuery;
	}

	/**
	 * @protected
	 * @returns {Promise<void>}
	 */
	async _handleExport() {
		this.setState({
			hasPendingExport: true,
		});

		try {
			return this.props.exportService.createExport(
				'contacts',
				'agent',
				this.#resolveExportQuery()
			);
		} catch (error) {
			this.setState({
				errorMessage: translate('exports.errors.create'),
				hasPendingExport: false,
			});
		}
	}

	/**
	 * @returns {QueryParameters}
	 */
	#resolveExportQuery() {
		const activeTab = this.state.activeTab;

		if (activeTab !== 'myContacts' && activeTab !== 'allContacts') {
			return {
				'filter[office_id]': activeTab,
				limit: undefined,
				page: undefined,
			};
		}

		return {
			'filter[office_id]':
				activeTab === 'allContacts' ? undefined : 'null',
			limit: undefined,
			page: undefined,
		};
	}

	/**
	 * @returns {boolean}
	 */
	#canExportContacts() {
		if (this.state.contactList.length && !this.state.hasPendingExport) {
			return true;
		}

		return false;
	}

	/**
	 * @returns {JSX.Element}
	 */
	#renderAddContactLinks() {
		const activeTab = this.state.activeTab;
		const groupName = this.state.activeGroupName;

		if (activeTab === 'allContacts') {
			return this.#renderDisabledContactButtons();
		}

		return (
			<section className="button-group h-fit">
				<LinkButton
					icon={images.icons.import}
					to={{
						pathname: paths.app.agent.contacts.import,
						state: {
							// @ts-ignore
							activeTab: activeTab,
							groupName: groupName,
							referrer: `${location.pathname}${location.search}${location.hash}`,
						},
					}}
				>
					{translate('agent.pages.contacts.view.import')}
				</LinkButton>
				<LinkButton
					icon={images.icons.add}
					to={{
						pathname: paths.app.agent.contacts.add,
						state: {
							// @ts-ignore
							activeTab: activeTab,
							groupName: groupName,
						},
					}}
				>
					{translate('agent.pages.contacts.view.new')}
				</LinkButton>
				<Feature fallback={null} name={EXPORT_FLAG_NAME}>
					<BaseButton
						disabled={!this.#canExportContacts()}
						icon={images.icons.export}
						onClick={this._handleExport}
						outline={true}
					>
						{this.state.hasPendingExport
							? translate('exports.export_pending')
							: translate('exports.export')}
					</BaseButton>
				</Feature>
			</section>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	#renderDisabledContactButtons() {
		return (
			<section className="button-group h-fit">
				<LinkButton
					icon={images.icons.import}
					to={{
						pathname: paths.app.agent.contacts.import,
						state: {
							// @ts-ignore
							activeTab: this.state.activeTab,
							groupName: this.state.activeGroupName,
							referrer: `${location.pathname}${location.search}${location.hash}`,
						},
					}}
				>
					{translate('agent.pages.contacts.view.import')}
				</LinkButton>
				<BaseButton
					className="mx-auto"
					disabled={true}
					icon={images.icons.add}
					outline={true}
					type="button"
				>
					{translate('agent.pages.contacts.view.new')}
				</BaseButton>
				<Feature fallback={null} name={EXPORT_FLAG_NAME}>
					<BaseButton
						disabled={!this.#canExportContacts()}
						icon={images.icons.export}
						onClick={this._handleExport}
						outline={true}
					>
						{this.state.hasPendingExport
							? translate('exports.export_pending')
							: translate('exports.export')}
					</BaseButton>
				</Feature>
			</section>
		);
	}

	/**
	 * @returns {boolean}
	 */
	#resolveSubscription() {
		/**
		 * @param {'agent' | 'group'} role
		 * @returns {boolean}
		 */
		const includesSub = (role) =>
			this.props.auth.agentSubscriptionLevels.includes(role);

		const activeTab = this.state.activeTab;

		if (activeTab === 'myContacts') {
			return includesSub('agent');
		}

		if (activeTab === 'allContacts') {
			return includesSub('agent') || includesSub('group');
		}

		const hasSubscription =
			this.state.groupsContactsCounts.find(
				(group) => group.id === activeTab
			)?.hasSubscription ?? false;

		return hasSubscription || includesSub('agent');
	}

	/**
	 * @returns {JSX.Element}
	 */
	/* eslint-disable class-methods-use-this -- Render method must be invokable by `this` */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		if (this.state.errorMessage !== null) {
			return <NoContent message={this.state.errorMessage} />;
		}

		return (
			<React.Fragment>
				<header className="mt-10 mb-6">
					<h1 className="hl-ms-6 mb-6">
						{translate('admin.pages.contacts.view.title')}
					</h1>

					<Feature fallback={null} name={EXPORT_FLAG_NAME}>
						<FormWarningMessages
							heading={translate('exports.started')}
							messages={
								this.state.hasPendingExport
									? translate('exports.pending_description')
									: ''
							}
						/>
					</Feature>

					<div className="md:flex justify-between items-end align-bottom md:mb-0">
						<ContactTabs
							// @ts-ignore
							activeTab={this.state.activeTab}
							allContactsCount={this.state.allContactsCount}
							deleteRan={this.state.deleteRan}
							groupsContactsCounts={
								this.state.groupsContactsCounts
							}
							myContactsCount={this.state.myContactsCount}
							setCurrentPageNumber={this._setCurrentPageNumber}
							setTable={this._setTable}
							setAllTabs={this._setAllTabs}
						/>

						{this.#renderAddContactLinks()}
					</div>
				</header>

				<SuccessOrWarningMessage
					heading={
						this.props.location?.state?.heading ??
						translate('agent.pages.contacts.view.success_header')
					}
					messages={this.state.successOrWarningMessage}
					warning={this.props.location?.state?.warning}
				/>

				<ContactsTable
					// @ts-ignore -- declared
					activeTab={this.state.activeTab}
					contactList={this.state.contactList}
					currentPageNumber={this.state.currentPageNumber}
					filters={
						<div className="flex gap-2 items-center flex-row-reverse">
							<RegisteredFilter
								onSubmit={this._handleSubmitQuery}
								query={this.state.filterQuery}
							/>
							<MoverScoreFilter
								gated={!this.#resolveSubscription()}
								onSubmit={this._handleSubmitQuery}
								query={this.state.filterQuery}
							/>
							<SearchFilter
								onSubmit={this._handleSubmitQuery}
								query={this.state.filterQuery}
								search={[
									'address.street_address_1',
									'email',
									'name.first',
									'name.last',
									'phone_numbers.value',
									'phone.landline',
									'phone.mobile',
								]}
							/>
						</div>
					}
					isGettingData={this.state.tableIsGettingData}
					lastPageNumber={this.state.lastPageNumber}
					resetTableAndTabs={this._resetTableAndTabs}
					setCurrentPageNumber={this._setCurrentPageNumber}
				/>
			</React.Fragment>
		);
	}
}

// @ts-ignore
export default withAuth(
	withAgentContactService(withExportService(withSettings(ViewContacts)))
);
