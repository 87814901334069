/** @typedef {import('../../../Auth/AuthService').default} AuthService  */
/** @typedef {import('./Checklist').default} Checklist */
/** @typedef {import('./ChecklistProgress').default} Progress */
/** @typedef {import('./TaskService').default} TaskService  */
/** @typedef {import('@mooveguru/js-http-client')} HttpClient */

import { getMovingTasks } from 'shared/task-service';
import { translate } from 'App/Internationalization/TranslationService';

export default class ChecklistService {
	/**
	 * Ranked by order of importance
	 * @private
	 * @type {['onboarding', 'moving']}
	 */
	types = ['onboarding', 'moving'];

	/** @param {{authService: AuthService, taskService: TaskService, httpClient: HttpClient }} dependencies */
	constructor(dependencies) {
		this.authService = dependencies.authService;
		this.httpClient = dependencies.httpClient;
		this.taskService = dependencies.taskService;
	}

	/**
	 * @param {'moving'|'onboarding'} type
	 * @returns {Promise<?Checklist>}
	 */
	async getChecklist(type) {
		// prettiter-ignore
		const title = translate(`homeowner.pages.checklists.${type}.title`);

		/* eslint-disable indent */
		switch (type) {
			case 'onboarding':
				return {
					title,
					tasks: await this.taskService.getOnboardingTasks(),
				};

			case 'moving':
				return {
					title,
					tasks: await getMovingTasks(this.authService.accessToken),
				};

			default:
				return null;
		}
		/* eslint-enable indent */
	}

	/**
	 * @param {Checklist} checklist
	 * @returns {Progress}
	 */
	getProgress(checklist) {
		const completedTasks = checklist.tasks.filter(
			(task) => task.isComplete
		).length;

		return {
			completed: completedTasks,
			remaining: Math.max(checklist.tasks.length - completedTasks, 0),
			title: checklist.title,
			total: checklist.tasks.length,
		};
	}

	/**
	 * @returns {Promise<?Checklist>}
	 */
	async getActiveChecklist() {
		for (const type of this.types) {
			const checklist = await this.getChecklist(type);

			if (!checklist) {
				continue;
			}

			const progress = this.getProgress(checklist);

			if (progress.remaining) {
				return checklist;
			}
		}

		return null;
	}

	/**
	 * @returns {Promise<?Progress>}
	 */
	async getActiveChecklistProgress() {
		const checklist = await this.getActiveChecklist();

		return checklist ? this.getProgress(checklist) : null;
	}
}
