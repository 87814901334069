/**
 * @typedef {import('./UpgradedAgentsProps').default} Props
 * @typedef {import('./UpgradedAgentsState').default} State
 */
import { translate } from '../../../../../App/Internationalization';
import { withAgentService } from 'service-container';
import captureError from '../../../../../utils/captureError';
import Loading from '../../../../../shared/Loading';
import ProgressBar from '../../../../../shared/ProgressBar/ProgressBar';
import React, { Component } from 'react';

/** @extends {React.Component<Props, State>} */
export class UpgradedAgents extends Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		this.state = {
			activeAgentCount: 0,
			errorMessage: null,
			isGettingData: false,
			upgradedAgentCount: 0,
		};

		this.setUpgradedAgentCount = this.setUpgradedAgentCount.bind(this);
	}

	async componentDidMount() {
		try {
			await this.setUpgradedAgentCount();
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async setUpgradedAgentCount() {
		const response = await this.props.agentService.getAgentUpgradedCount();

		this.setState({
			activeAgentCount: response.active_agent_count,
			isGettingData: false,
			upgradedAgentCount: response.upgraded_agent_count,
		});
	}

	render() {
		if (this.state.errorMessage !== null) {
			return <p>{this.state.errorMessage}</p>;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<ProgressBar
				max={this.state.activeAgentCount}
				title={translate('admin.nav.agent.upgraded_agents')}
				value={this.state.upgradedAgentCount}
			/>
		);
	}
}

export default withAgentService(UpgradedAgents);
