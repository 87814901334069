/**
 * @typedef {import('./Roles').Admin} Admin
 * @typedef {import('./Roles').Owner} Owner
 * @typedef {import('./Roles').Roles} Roles
 * @typedef {Admin | Owner} AdminOrOwner
 */

import { AuthContext } from '../../../../shared/AuthProvider';
import { translate } from '../../../Internationalization';
import * as validators from '../../../../shared/validators';
import * as yup from 'yup';
import BaseForm from '../../../../shared/Forms/BaseForm';
import capabilities from '@mooveguru/yhh-shared-config/users/capabilities.json';
import FieldEmail from '../../../../shared/Forms/Fields/FieldEmail';
import FieldLandlinePhone from '../../../../shared/Forms/Fields/PhoneNumber/FieldLandlinePhone';
import FieldMobilePhone from '../../../../shared/Forms/Fields/PhoneNumber/FieldMobilePhone';
import FieldRoles from '../../../../shared/Forms/Fields/FieldRoles';
import FieldStatus from '../../../../shared/Forms/Fields/FieldStatus';
import FormErrorMessages from '../../../../shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from '../../../../shared/Forms/Messages/FormSuccessMessages';
import images from '../../../../config/local/images';
import LinkButton from '../../../../shared/LinkButton';
import NameFields from '../../../../shared/Forms/Fields/Name/NameFields';
import GroupPrimaryFieldGroup from '../Agents/GroupPrimaryFieldGroup';
import paths from '../../../../config/local/paths';
import React from 'react';
import SubmitButton from '../../../../shared/Forms/Inputs/SubmitButton';

export default class AdminForm extends React.Component {
	#getInitialValues() {
		return (
			this.props.adminData ?? {
				active: 'active',
				email: '',
				firstName: '',
				groups: [],
				landline: '',
				lastName: '',
				mobile: '',
				primaryGroup: '',
				roles: this.context.roles.includes('manager')
					? ['manager']
					: [],
			}
		);
	}

	/**
	 * @private
	 * @param {AdminOrOwner} type
	 * @returns {boolean}
	 */
	userCanEditAdminType(type) {
		if (!['admin', 'owner'].includes(type)) {
			return false;
		}

		return this.context.roles.some(
			/**
			 * @param {Roles} role
			 * @returns {boolean}
			 */
			(role) => capabilities[`edit_${type}s`].includes(role)
		);
	}

	/**
	 * @private
	 * @returns {Roles[]}
	 */
	getUserEditCapabilities() {
		/** @type {Roles[]} */
		const capableRoles = ['manager'];

		['admin', 'owner'].forEach(
			/**
			 * @param {AdminOrOwner} type
			 * @returns {void}
			 */
			// @ts-ignore type incompatibilities, `admin | owner` are strings
			(type) => {
				if (!this.userCanEditAdminType(type)) {
					return;
				}

				capableRoles.push(type);
			}
		);

		return capableRoles;
	}

	/**
	 * @returns {boolean}
	 */
	#isGroupRequired() {
		let required = true;

		this.context.roles.forEach(
			/** @param {Roles} role */ (role) => {
				if (role === 'owner' || role === 'admin') {
					required = false;
				}
			}
		);

		return required;
	}

	/** @returns {JSX.Element} */
	render() {
		const validationSchema = yup.object().shape({
			active: yup.string().nullable(),
			email: validators.email,
			firstName: validators.firstName,
			groups: this.#isGroupRequired()
				? yup
						.array()
						.min(1, 'Group is required')
						.of(
							yup.object().shape({
								id: yup.string(),
								isPrimary: yup.bool(),
							})
						)
						.required()
				: yup.array().nullable(),
			landline: validators.phoneNumber.nullable(),
			lastName: validators.lastName,
			mobile: validators.phoneNumber.nullable(),
			roles: yup
				.string()
				.nullable()
				.when('active', {
					is: 'disabled',
					otherwise: yup
						.string()
						.nullable()
						.required(
							translate('global.validator.is_required', 'A role')
						),
				}),
		});

		return (
			<React.Fragment>
				<header className="mt-10 mb-6 flex flex-row items-center justify-between">
					<h1 className="hl-ms-6">{this.props.formTitle}</h1>
					<LinkButton
						icon={images.icons.delete}
						to={paths.app.admin.admins.view}
					>
						{translate('global.forms.buttons.cancel')}
					</LinkButton>
				</header>

				<BaseForm
					initialValues={this.#getInitialValues()}
					onSubmit={this.props.onSubmit}
					validationSchema={validationSchema}
				>
					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<NameFields required={true} />

						<GroupPrimaryFieldGroup
							initialGroupValues={{
								groups: this.props.adminData?.groups,
								primaryGroup:
									this.props.adminData?.groups?.find(
										/**
										 * @param {{isPrimary: boolean}} group
										 * @returns {boolean}
										 */
										(group) => group.isPrimary
									) ?? null,
							}}
							required={true}
						/>

						<FieldEmail required={true} />
						<FieldLandlinePhone name="landline" />
						<FieldMobilePhone name="mobile" />

						<FieldRoles
							name="roles"
							roles={this.getUserEditCapabilities()}
						/>

						{this.props.hasStatus ? (
							<FieldStatus name="active" />
						) : null}
					</fieldset>

					<footer className="mx-auto sm:ml-0 button-group w-min">
						<SubmitButton>{this.props.formSubmitText}</SubmitButton>
						<LinkButton
							icon={images.icons.delete}
							size="large"
							to={paths.app.admin.admins.view}
						>
							{translate('global.forms.buttons.cancel')}
						</LinkButton>
					</footer>

					<FormSuccessMessages messages={[this.props.success]} />
					<FormErrorMessages messages={[this.props.error]} />
				</BaseForm>
			</React.Fragment>
		);
	}
}

AdminForm.defaultProps = {
	adminData: {
		active: '',
		email: '',
		firstName: '',
		landline: '',
		lastName: '',
		mobile: '',
		offices: [],
		primaryOffice: '',
		roles: [],
	},
	hasStatus: false,
};

AdminForm.contextType = AuthContext;
