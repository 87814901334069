/**
 * @typedef {import('../../shared/OfferForm/OfferFields').default} OfferFields
 * @typedef {import('../../shared/OfferService/AddsAgentOffer').default} AddsAgentOffer
 * @typedef {import('../../shared/OfferService/AddsOffer').default} AddsOffer
 * @typedef {import('../../shared/OfferService/AddsGroupOffer').default} AddsGroupOffer
 * @typedef {import('../../shared/OfferService/EditsOffer').default} EditsOffer
 * @typedef {import('../../shared/OfferService/GetsAgentOffers').default} GetsAgentOffers
 * @typedef {import('../../shared/OfferService/GetsGroupOffers').default} GetsGroupOffers
 * @typedef {import('./ImageData')} ImageData
 * @typedef {AddsAgentOffer & AddsGroupOffer & GetsAgentOffers & GetsGroupOffers & EditsOffer} OfferService
 */
import { file as validateFile } from 'shared/validators';
import { Redirect } from 'react-router-dom';
import { translate } from 'App/Internationalization';
import { withOfferService } from 'service-container';
import getErrorMessage from 'utils/getErrorMessage';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import Loading from 'shared/Loading';
import OfferForm from 'App/shared/OfferForm/OfferForm';
import paths from 'config/local/paths';
import React from 'react';

export class EditOffer extends React.Component {
	/**
	 * @param {{ offerService: OfferService }} props
	 */
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: null,
			formComplete: false,
			isGettingData: true,
			offerData: {
				category: '',
				description: '',
				endDate: '',
				startDate: '',
				isActive: '',
				postalCode: '',
				title: '',
				vendorEmail: '',
				vendorName: '',
				vendorPhone: '',
				website: '',
			},
			image: null,
		};

		this.handleEditOfferSubmit = this.handleEditOfferSubmit.bind(this);
		this.handleFileUploadChange = this.handleFileUploadChange.bind(this);
		this.submitOfferImage = this.submitOfferImage.bind(this);
		this.submit = this.submit.bind(this);
		this.validateUploadedImage = this.validateUploadedImage.bind(this);
	}

	async componentDidMount() {
		await this.getAndSetInitialValues();
	}

	async getAndSetInitialValues() {
		try {
			const offer = await this.props.offerService.getSingleOffer(
				this.props.location.state.offerId
			);

			this.setState({
				isGettingData: false,
				offerData: {
					...offer,
					isActive: offer.isActive ? 'active' : 'disabled',
				},
			});
		} catch (error) {
			this.setState({
				errorMessage: getErrorMessage(error),
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {OfferFields} values
	 * @returns {Promise<void>}
	 */
	async handleEditOfferSubmit(values) {
		try {
			this.validateUploadedImage();
		} catch (error) {
			this.setState({
				errorMessage:
					error instanceof Error
						? error.message
						: translate('admin.pages.offers.image_upload.error'),

				formComplete: false,
			});

			return;
		}

		try {
			await this.submit(values);
		} catch (error) {
			this.setState({ errorMessage: getErrorMessage(error) });
		}
	}

	/**
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 */
	handleFileUploadChange(event) {
		if (!event.target.files?.length) {
			return;
		}
		this.setState({ image: event.target.files[0] });
	}

	/** @returns {Promise<void>} */
	async submitOfferImage() {
		try {
			await this.props.offerService.addOfferImage(
				this.state.image,
				this.props.location.state.offerId
			);
		} catch (error) {
			this.setState({ errorMessage: getErrorMessage(error) });
		}
	}

	/**
	 * @param {OfferFields} offer
	 * @returns {Promise<void>}
	 */
	async submit(offer) {
		await this.props.offerService.editOffer(
			offer,
			this.props.location.state.offerId
		);

		if (this.state.image) {
			await this.submitOfferImage();
		}

		this.setState({
			errorMessage: null,
			formComplete: true,
		});
	}

	/**
	 * @returns {void}
	 * @throws {Error}
	 */
	validateUploadedImage() {
		if (!this.state.image) {
			return;
		}

		validateFile(this.state.image, mimeList.images, 'image');
	}

	render() {
		const viewRoute = paths.app.admin.offers.view;
		const successMessage = translate('admin.pages.offers.edit_message');

		if (this.state.isGettingData) {
			return <Loading />;
		}

		if (this.state.errorMessage !== null) {
			return <p>{this.state.errorMessage}</p>;
		}

		if (this.state.formComplete) {
			return (
				<Redirect
					to={{
						pathname: viewRoute,
						state: {
							message: successMessage,
						},
					}}
				/>
			);
		}

		return (
			<OfferForm
				cancelPath={viewRoute}
				formSubmitText={translate('global.offers.update')}
				formTitle={translate('global.offers.edit_title')}
				hasImageField={true}
				hasGroupField={false}
				initialValues={this.state.offerData}
				onChange={this.handleFileUploadChange}
				onSubmit={this.handleEditOfferSubmit}
				preview={this.state.offerData?.image?.src}
			/>
		);
	}
}

export default withOfferService(EditOffer);
