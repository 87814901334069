import { translate } from '../../../App/Internationalization';
import assetUrls from '../asset-urls';
import images from '../images';

export default {
	appliances: {
		cinch: {
			buttonText: translate('ads.cinch.button'),
			copy: translate('ads.cinch.cta'),
			logo: `${assetUrls.images.ads.banners.cinch}`,
			textFooter: translate('ads.cinch.disclaimer'),
			textLead: translate('ads.cinch.description'),
			title: translate('ads.cinch.title'),
			url: 'https://www.buycinch.com/mguru?utm_source=mooveguru&utm_medium=link&utm_campaign=MGURUWEB&utm_content=cobrand',
		},
	},
	edgeHomeSid: {
		buttonText: translate('ads.edge_home.button'),
		logo: `${images.ads.edgeHome.logo}`,
		text: translate('ads.edge_home.cta'),
		textFooter: translate('ads.edge_home.disclaimer'),
		textLead: translate('ads.edge_home.description'),
		title: translate('ads.edge_home.cta'),
		url: 'https://edgehomefinance.com/team-member/sidlarson',
	},
	universalTitle: {
		buttonText: translate('ads.universal_title.button'),
		logo: `${images.ads.universalTitle.logo}`,
		text: translate('ads.universal_title.cta'),
		textFooter: translate('ads.universal_title.disclaimer'),
		textLead: translate('ads.universal_title.description'),
		title: translate('ads.cinch.title'),
		url: 'https://universaltitle.com',
	},
};
