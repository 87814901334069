/**
 * @typedef {import('./Data').AddError} AddError
 * @typedef {import('./Agent').default} Agent
 * @typedef {import('./Contact').default} Contact
 * @typedef {import('./CustomTheme').default} CustomTheme
 * @typedef {import('./Data').ErrorLevel} ErrorLevel
 * @typedef {import('./Field').default} Field
 */

import { emailRegExp, urlRegExp } from '../../../shared/validators';
import { translate } from '../../../App/Internationalization';
import stateList from '@mooveguru/yhh-shared-config/states.json';

const mutations = {
	ca: 'CA',
	us: 'US',
};

const validCountryMap = {
	ca: ['CA', 'Canada'],
	us: ['US', 'USA', 'United States', 'U.S.', 'U.S.A.'],
};

/** @type {ErrorLevel} */
const errorLevel = {
	error: 'error',
	info: 'info',
	warning: 'warning',
};

/**
 * @param {string} [value]
 * @returns {boolean}
 */
function isValidPhoneNumber(value) {
	if (!value) {
		return true;
	}

	return (mutatePhoneNumber(value)?.length ?? 0) >= 10;
}

/**
 * @template {string | undefined} PhoneNumber
 * @param {PhoneNumber} phoneNumber
 * @returns {PhoneNumber}
 */
function mutatePhoneNumber(phoneNumber) {
	if (!phoneNumber) {
		return phoneNumber;
	}

	// @ts-ignore
	return phoneNumber.replace(/[^\d]/g, '');
}

/**
 * @param {string} [value]
 * @returns {boolean}
 */
function isValidCountry(value) {
	if (!value) {
		return true;
	}

	const validCountryList = []
		.concat(...Object.values(validCountryMap))
		.map((string) => string.toUpperCase());

	return validCountryList.includes(value.toUpperCase());
}

/**
 * @param {string} [value]
 * @returns {boolean}
 */
function isValidState(value) {
	if (!value) {
		return true;
	}

	const acceptable = []
		.concat(Object.keys(stateList), Object.values(stateList))
		.map(
			/**
			 * @param {string} string
			 * @returns {string}
			 */
			(string) => string.toUpperCase()
		);

	return acceptable.includes(value.toUpperCase());
}

/**
 * @template {string | undefined} Country
 * @param {Country} country
 * @returns {Country | keyof validCountryMap}
 */
function mutateCountry(country) {
	if (!country) {
		return country;
	}

	const upperCaseCountry = country.toUpperCase();

	for (const [key, acceptable] of Object.entries(validCountryMap)) {
		if (
			acceptable
				.map((string) => string.toUpperCase())
				.includes(upperCaseCountry)
		) {
			// @ts-ignore checked above
			return mutations[key];
		}
	}

	return country;
}

/**
 * @template {string | undefined} State
 * @param {State} state
 * @returns {State | keyof stateList}
 */
function mutateState(state) {
	if (!state) {
		return state;
	}

	const upperCaseState = state.toUpperCase();
	const keys = Object.keys(stateList);
	const values = Object.values(stateList).map((string) =>
		string.toUpperCase()
	);
	const index = values.indexOf(upperCaseState);

	// @ts-ignore checked above
	return keys[index] ?? upperCaseState;
}

/**
 * @param {Contact} data
 * @returns {Contact}
 */
function mutateDataIfViable(data) {
	data.country = mutateCountry(data.country);
	data.state = mutateState(data.state);
	data.email = data.email?.trim();
	data.mobilePhone = mutatePhoneNumber(data.mobilePhone);
	data.landline = mutatePhoneNumber(data.landline);

	return data;
}

/**
 * @param {Contact} data
 * @param {AddError} addError
 * @returns {Contact}
 */
export function validateRows(data, addError) {
	if (!isValidCountry(data.country)) {
		addError('country', {
			level: errorLevel.error,
			message: translate('global.validator.invalid_country'),
		});
	}

	if (!isValidState(data.state)) {
		addError('state', {
			level: errorLevel.error,
			message: translate('global.validator.invalid_state'),
		});
	}

	if (!isValidPhoneNumber(data.mobilePhone)) {
		addError('mobilePhone', {
			level: errorLevel.error,
			message: translate('global.validator.minimum_phone_length'),
		});
	}

	if (!isValidPhoneNumber(data.landline)) {
		addError('landline', {
			level: errorLevel.error,
			message: translate('global.validator.minimum_phone_length'),
		});
	}

	return mutateDataIfViable(data);
}

/**
 * @param {string} main
 * @param {string | null} light
 * @returns {CustomTheme}
 */
export function getCustomTheme(main, light) {
	return {
		colors: {
			border: main,
			rsi: {
				50: '',
				100: light ?? '',
				500: main,
				600: light ?? '',
				700: light ?? '',
			},
			textColor: '#000000',
		},
		components: {
			Button: {
				baseStyle: {
					_hover: {
						bg: light ?? '',
					},
				},
			},
		},
		// @ts-ignore
		styles: {
			global: {
				'.rdg': {
					'--rdg-error-cell-background-color': '#FFC5C5',
				},
			},
		},
	};
}

const rules = {
	regex: 'regex',
	required: 'required',
	unique: 'unique',
};

const emailValidations = [
	{
		errorMessage: translate('global.validator.email_is_required'),
		level: errorLevel.error,
		rule: rules.required,
	},
	{
		errorMessage: translate('global.validator.no_duplicates_allowed'),
		level: errorLevel.error,
		rule: rules.unique,
	},
	{
		errorMessage: translate('global.validator.invalid_email'),
		level: errorLevel.error,
		rule: rules.regex,
		value: emailRegExp,
	},
];

const urlValidations = [
	{
		errorMessage: translate('global.validator.invalid_url_format'),
		level: errorLevel.error,
		rule: rules.regex,
		value: urlRegExp,
	},
];

const emailField = generateInputField(
	'fake.address@mooveguru.com',
	'email',
	translate('global.forms.inputs.email.label'),
	['Email', 'Email Address', 'Email ID'],
	// @ts-ignore
	emailValidations
);

const firstNameField = generateInputField(
	'Fake',
	'firstName',
	translate('global.forms.inputs.name.first.label'),
	['First Name', 'FName', 'Given Name', 'Name']
);

const lastNameField = generateInputField(
	'Person',
	'lastName',
	translate('global.forms.inputs.name.last.label'),
	['Family Name', 'Last Name', 'LName', 'Surname']
);

const mobileField = generateInputField(
	'123-456-7890',
	'mobilePhone',
	translate('global.forms.inputs.phone.mobile.label'),
	[
		'Cell',
		'Cell Phone',
		'Main Phone',
		'Mobile',
		'Mobile Phone',
		'Primary Phone',
	]
);

const landlineField = generateInputField(
	'123-456-7890',
	'landline',
	translate('global.forms.inputs.phone.landline.label'),
	['Home Phone', 'Landline', 'Landline Phone', 'Office Phone']
);

const facebookField = generateInputField(
	'https://www.facebook.com/fake-person',
	'facebook',
	translate('global.forms.inputs.url_inputs.facebook.label'),
	['Facebook', 'Facebook Page', 'Facebook URL'],
	// @ts-ignore
	urlValidations
);

const twitterField = generateInputField(
	'https://twitter.com/fake-person',
	'twitter',
	translate('global.forms.inputs.url_inputs.twitter.label'),
	['Twitter', 'Twitter Profile', 'Twitter URL'],
	// @ts-ignore
	urlValidations
);

const urlField = generateInputField(
	'https://www.a-really-cool-website.com',
	'url',
	translate('global.forms.inputs.url_inputs.website.label'),
	['Link', 'Site', 'URL', 'Website'],
	// @ts-ignore
	urlValidations
);

const licenseField = generateInputField(
	'123ABC',
	'license',
	translate('global.forms.inputs.license.label'),
	[
		'Broker License',
		'Broker License Number',
		'License',
		'License Number',
		'NMLS License',
		'NMLS License Number',
		'Real Estate License',
		'Real Estate License Number',
	]
);

const closeDateField = generateInputField(
	'1/31/22',
	'closeDate',
	translate('agent.pages.contacts.form.close_date.label'),
	['Close Date', 'Closing Date']
);

const moveDateField = generateInputField(
	'1/31/22',
	'moveDate',
	translate('agent.pages.contacts.form.move_date.label'),
	['Move Date', 'Moving Date']
);

const cityField = generateInputField(
	'Roswell',
	'city',
	translate('global.forms.inputs.address.city.label'),
	['City', 'Locale', 'Municipality', 'Town']
);

const countryField = generateInputField(
	'US',
	'country',
	translate('global.forms.inputs.address.country.label')
);

const postalCodeField = generateInputField(
	'30075',
	'postalCode',
	translate('global.forms.inputs.address.postal_code.label'),
	['Postal Code', 'Postal/Zip Code', 'Zip', 'Zip Code']
);

const stateField = generateInputField(
	'GA',
	'state',
	translate('global.forms.inputs.address.state.label')
);

const streetAddress1Field = generateInputField(
	'115 South Ave',
	'streetAddress1',
	translate('global.forms.inputs.address.street_address_1.label'),
	['Street Address', 'Street Address 1']
);

const streetAddress2Field = generateInputField(
	'Suite 230',
	'streetAddress2',
	translate('global.forms.inputs.address.street_address_2.label'),
	['Street Address 2']
);

export const agentFields = [
	emailField,
	lastNameField,
	firstNameField,
	mobileField,
	landlineField,
	facebookField,
	twitterField,
	urlField,
	licenseField,
];

export const contactFields = [
	emailField,
	lastNameField,
	firstNameField,
	mobileField,
	landlineField,
	closeDateField,
	moveDateField,
	cityField,
	countryField,
	postalCodeField,
	stateField,
	streetAddress1Field,
	streetAddress2Field,
];

/**
 * @param {string} example
 * @param {string} key
 * @param {string} label
 * @param {string[]} [alternateMatches]
 * @param {Field['validations']} [validations]
 * @returns {Field}
 */
function generateInputField(
	example,
	key,
	label,
	alternateMatches = [],
	validations = []
) {
	return {
		alternateMatches,
		example,
		fieldType: {
			type: 'input',
		},
		key,
		label,
		validations,
	};
}
