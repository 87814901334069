import images from '../config/local/images';
import InlineSVG from 'react-inlinesvg/esm';
import React from 'react';

export default class Loading extends React.Component {
	/* eslint-disable class-methods-use-this -- `render` must be accessible to instance */
	render() {
		return (
			<div className="spinner">
				<InlineSVG className="icon" src={images.icons.spinner} />
			</div>
		);
	}
	/* eslint-enable class-methods-use-this */
}
