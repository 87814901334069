/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */
import { translate } from '../../../Internationalization';
import {
	withApplianceService,
	withPropertyService,
} from '../../../../service-container';
import applianceCategories from '@mooveguru/yhh-shared-config/appliances/categories.json';
import BaseTable from '../../../../shared/BaseTable';
import captureError from '../../../../utils/captureError';
import formatCentsForDisplay from '../../../../utils/formatCentsForDisplay';
import formatDateForDisplay from '../../../../utils/formatDateForDisplay';
import images from '../../../../config/local/images';
import paths from '../../../../config/local/paths';
import React from 'react';
import TableButton from '../../../../shared/TableButton';

/** @extends {React.Component<Props, State>} */
export class ApplianceTable extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		this.headerData = [
			{ title: 'Category' },
			{ title: 'Brand' },
			{ title: 'Model' },
			{ title: 'Purchase Date' },
			{ title: 'Purchase Price' },
			{ title: 'Warranty Expiration Date' },
			{ title: 'Notes' },
			{ title: 'Edit' },
			{ title: 'Delete' },
		];
	}

	/**
	 * @param {string} applianceId
	 */
	async deleteAppliance(applianceId) {
		try {
			await this.props.applianceService.deleteAppliance(applianceId);
			this.props.onDelete(applianceId);
		} catch (error) {
			captureError(error);
		}
	}

	setTableData() {
		return this.props.applianceList.map((appliance, index) => [
			{
				data: applianceCategories[appliance.category],
				index: `appliance-${index}`,
			},
			{ data: appliance.brand, index: `appliance-${index}` },
			{ data: appliance.model, index: `appliance-${index}` },
			{
				data: appliance.purchaseDate
					? formatDateForDisplay(appliance.purchaseDate)
					: '-',
				index: `appliance-${index}`,
			},
			{
				data: appliance.purchasePrice
					? formatCentsForDisplay(appliance.purchasePrice)
					: '-',
				index: `appliance-${index}`,
			},
			{
				data: appliance.warrantyExpirationDate
					? formatDateForDisplay(appliance.warrantyExpirationDate)
					: '-',
				index: `appliance-${index}`,
			},
			{ data: appliance.notes, index: `appliance-${index}` },
			{
				data: (
					<TableButton
						icon={images.icons.edit}
						title="Edit"
						to={`${paths.app.homeowner.applianceCenter.edit}/${appliance.id}`}
					/>
				),
				index: `appliance-${index}`,
			},
			{
				data: (
					<TableButton
						handleClick={() => this.deleteAppliance(appliance.id)}
						icon={images.icons.delete}
						title="Delete"
					/>
				),
				index: `appliance-${index}`,
			},
		]);
	}

	render() {
		const bodyData = this.setTableData();

		return (
			<BaseTable
				bodyData={bodyData}
				errorMessage={translate(
					'homeowner.pages.appliance_center.table.error'
				)}
				headerData={this.headerData}
			/>
		);
	}
}

export default withApplianceService(withPropertyService(ApplianceTable));
