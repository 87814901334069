/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */
import { withSubscriptionService } from '../../../../service-container';
import captureError from '../../../../utils/captureError';
import Checkout from '../Checkout';
import Loading from '../../../../shared/Loading';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class SubscriptionIndex extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			isGettingData: true,
			pricing: { contactPriceInCents: 0, priceInCents: 0 },
			subscriptionCheckoutLink: null,
			subscriptionIsActive: null,
			subscriptionIsRenewing: null,
		};
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		await this.#getAndSetSubscriptionData();

		if (this.#subscriptionExits()) {
			await this.#manageSubscription();
		} else {
			await this.#getAndSetSubscriptionPrice();
			await this.#startCheckoutSession();
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #getAndSetSubscriptionPrice() {
		try {
			const pricing =
				await this.props.subscriptionService.getSubscriptionPrice();

			this.setState({
				pricing,
			});
		} catch (error) {
			captureError(error);
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #manageSubscription() {
		try {
			const managementSession =
				await this.props.subscriptionService.getAgentSubscriptionManagementLink();

			window.location.href = managementSession.url;
		} catch (error) {
			captureError(error);
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #startCheckoutSession() {
		try {
			const checkoutSession =
				await this.props.subscriptionService.getAgentSubscriptionCheckoutLink();

			this.setState({
				subscriptionCheckoutLink: checkoutSession.url,
			});
		} catch (error) {
			captureError(error);
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #getAndSetSubscriptionData() {
		try {
			const subscription =
				await this.props.subscriptionService.getAgentSubscription();

			this.setState({
				isGettingData: false,
				subscriptionIsActive: subscription?.isActive ?? null,
				subscriptionIsRenewing: subscription?.isRenewing ?? null,
			});
		} catch (exception) {
			captureError(exception);
			this.setState({
				isGettingData: false,
			});
		}
	}

	/**
	 * @returns {boolean}
	 */
	#subscriptionExits() {
		if (this.state.subscriptionIsActive) {
			return true;
		}

		if (this.state.subscriptionIsRenewing) {
			return true;
		}

		return false;
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		if (this.#subscriptionExits()) {
			return <Loading />;
		}

		return (
			<Checkout
				paymentLink={this.state.subscriptionCheckoutLink}
				pricing={this.state.pricing}
			/>
		);
	}
}

export default withSubscriptionService(SubscriptionIndex);
