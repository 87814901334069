import React from 'react';

export default class Zapier extends React.Component {
	/*
	 * Loading the script and stylesheets here to isolate
	 * the component from the rest of the app and only
	 * load them when the component is mounted.
	 */
	// eslint-disable-next-line class-methods-use-this
	componentDidMount() {
		Zapier.#loadZapierScript();
		Zapier.#loadZapierCss();
		Zapier.#overrideZapierTheme();
	}

	static #loadZapierScript() {
		const script = document.createElement('script');
		script.type = 'module';
		script.src =
			'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js';
		document.head.appendChild(script);
	}

	static #loadZapierCss() {
		const stylesheet = document.createElement('link');
		stylesheet.rel = 'stylesheet';
		stylesheet.href =
			'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css';
		document.head.appendChild(stylesheet);
	}

	/*
	 * Zapier uses shadow-dom to create web components,
	 * for security reasons, this prevents Javascript
	 * from accessing	the DOM of the web component.
	 * The only method to change the CSS only is by
	 * overriding their CSS variables.
	 */
	static #overrideZapierTheme() {
		const stylesheet = document.createElement('style');
		// `!important` is needed to override their CSS
		stylesheet.textContent = `
		:root {
			--zapier-button-bg: var(--theme-color) !important; 
			--zapier-button-text: var(--theme-color-fg) !important;
		}`;

		document.head.appendChild(stylesheet);
	}

	// eslint-disable-next-line class-methods-use-this
	render() {
		return (
			<main>
				<header className="mb-6">
					<h2 className="hl-ms-6 font-bold">{'Zapier'}</h2>
				</header>

				{/* @ts-ignore external embed */}
				<zapier-zap-templates
					apps="homekeepr"
					create-without-template="hide" // footer for creating new zaps, hide or show
					limit={99}
					link-target="new-tab" // new-tab,	same-tab, or same-frame
					presentation="row" //	row or card
					theme="light" // for OUR apps background, will toggle their cards colors, light or dark
					use-this-zap="show" // CTA button, hide or show
				/>
			</main>
		);
	}
}
