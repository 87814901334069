// TODO Move all validation messages to translation file.
/* eslint-disable func-style -- TODO: Convert arrow functions to use function keyword */
/**
 * @typedef {'document'|'favicon'|'image'|'import'} FileSizeKey
 */

import { translate } from '../App/Internationalization';
import * as yup from 'yup';
import accessibility from '@mooveguru/yhh-shared-config/accesibility.json';
import contrastChecker from '@mooveguru/js-color-contract-checker';
import maxUpload from '@mooveguru/yhh-shared-config/files/max-upload.json';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';

const kilobyteInBytes = 1_024;
const megabyteInBytes = 1_048_576;

const analyticsTagRegExp = /^[A-Z]{2}[-]?[0-9]{1,}[-]?[0-9]{1,}$/;
const oneOrMoreDigitsRegExp = /\d+/;
const oneOrMoreLowercaseCharactersRegExp = /[a-z]+/;
const oneOrMoreSpecialCharactersRegExp =
	/[`~!@#$%^&*()\-_=+[{\]}\\|;:'",<.>/?]+/;
const oneOrMoreUppercaseCharactersRegExp = /[A-Z]+/;
const postalCodeRegex =
	/(^[\d]{5}$)|(^[A-CEGHJ-NPR-TVX]\d[A-CEGHJ-NP-TV-XZ]\s\d[A-CEGHJ-NPR-TV-XZ]\d$)/i;
const slugRegExp = /^[a-z\d](?=.*[a-z\d-])(?:[a-z\d]|-(?!-))+[a-z\d]$/;

export const required = (/** @type {string} */ field) =>
	yup.string().required(translate('global.validator.is_required', field));

export const groups = yup
	.array()
	.min(1, translate('global.validator.group_is_required'))
	.required(translate('global.validator.group_is_required'));
export const onlyString = yup.string().nullable();
export const onlyNumber = yup.number().nullable();
export const onlyDate = yup.date().nullable();

export const city = required(translate('global.validator.city_is_required'));
export const email = yup
	.string()
	.email(translate('global.validator.invalid_email'))
	.required(translate('global.validator.email_is_required'));
export const emailRegExp =
	/^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
export const urlRegExp =
	/^(?:https:\/\/[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)?)?$/;

export const analyticsTagValidator = yup
	.string()
	.matches(analyticsTagRegExp, translate('global.validator.analytics_tag'))
	.required(translate('global.validator.analytics_tag_required'));

/**
 * @param {string} fieldName
 * @param {string} messageName
 * @returns {import('yup/lib/string').RequiredStringSchema<string | undefined>}
 */
export const matchConfirmation = (fieldName, messageName) =>
	yup
		.string()
		.oneOf(
			[yup.ref(fieldName), null],
			translate('global.validator.match_confirmation', messageName)
		)
		.required(
			translate('global.validator.password_confirmation_is_required')
		);

export const password = yup
	.string()
	.matches(
		oneOrMoreLowercaseCharactersRegExp,
		translate('global.validator.password_lowercase_required')
	)
	.matches(
		oneOrMoreUppercaseCharactersRegExp,
		translate('global.validator.password_uppercase_required')
	)
	.matches(
		oneOrMoreSpecialCharactersRegExp,
		translate('global.validator.password_special_character_required')
	)
	.matches(oneOrMoreDigitsRegExp, 'Password must contain at least 1 number')
	.min(7, 'Password must be at least 7 characters')
	.required('Password is required');

export const phoneNumber = yup.string().test(
	'phoneNumber',
	translate('global.validator.minimum_phone_length'),
	/**
	 * @param {string | undefined} value
	 * @returns {boolean}
	 */
	(value) => {
		if (!value?.length || value.replace(/[^\d]/g, '').length >= 10) {
			return true;
		}

		return false;
	}
);

export const atLeastOne = (
	/** @type {string[]} */ fieldNames,
	/** @type {string} */ error = '${path} must have one of the following: ${keys}'
) =>
	yup.mixed().test('atLeastOne', error, (value, context) => {
		if (!value && !fieldNames.some((field) => context.parent[field])) {
			return false;
		}

		return true;
	});

export const propertyTitle = yup
	.string()
	.required(translate('global.validator.property_title_is_required'));

export const state = yup
	.string()
	.length(2, translate('global.validator.invalid_state'))
	.required(translate('global.validator.state_is_required'));

export const country = required(
	translate('global.validator.country_is_required')
);

export const streetAddress = yup.string();

export const postalCode = yup
	.string()
	.matches(postalCodeRegex, translate('global.validator.postal_code'))
	.required(translate('global.validator.postal_code_is_required'));

export const colorContrast = yup
	.string()
	.test(
		'validHex',
		translate('global.validator.color_is_invalid'),
		(value) => contrastChecker.hexToRGB(value || '') !== null
	)
	.test(
		'validContrast',
		translate('global.validator.color_must_meet_accessibility'),
		(value) =>
			contrastChecker.meetsContrastRequirement(
				value || '',
				accessibility.defaultTextColor,
				'normal'
			)
	);

export const firstName = required(
	translate('global.forms.inputs.name.first.label')
);
export const lastName = required(
	translate('global.forms.inputs.name.last.label')
);
export const terms = yup
	.boolean()
	.oneOf([true], translate('global.validator.terms_and_services_required'));
export const url = yup
	.string()
	.matches(urlRegExp, translate('global.validator.invalid_url_format'));
export const slug = yup
	.string()
	.matches(slugRegExp, translate('global.validator.invalid_slug_format'));
export const transfer = yup
	.boolean()
	.oneOf([true], translate('global.validator.transfer_property_required'));

// TODO: Wrap up file sizes into a package to be shared across the application
/**
 * @param {FileSizeKey} type
 * @returns {number}
 */
function maxSize(type) {
	/*
	 *TODO: This should not be hardcoded, this should be done in a package
	 *using the same logic from the API
	 */
	switch (maxUpload[type]) {
		case '5KB':
			return kilobyteInBytes * 5;
		case '5MB':
			return megabyteInBytes * 5;
		case '50MB':
			return megabyteInBytes * 50;
		case '75MB':
			return megabyteInBytes * 75;
		// skip default
	}

	return process.env.REACT_APP_MAX_UPLOAD_SIZE
		? parseInt(process.env.REACT_APP_MAX_UPLOAD_SIZE)
		: megabyteInBytes * 75;
}

/**
 * @param {File} upload
 * @param {object=} typeList
 * @param {FileSizeKey=} type
 * @throws {Error}
 * @returns {boolean}
 */
// TODO: Reconcile with @mooveguru/yhh-shared-config/files.
export function file(
	upload,
	typeList = {
		...mimeList.images,
		...mimeList.icons,
		...mimeList.documents,
	},
	type = 'document'
) {
	if (!Object.values(typeList).includes(upload.type)) {
		throw Error(
			translate(
				'global.validator.transfer_property_required',
				Object.keys(typeList).join(', ')
			)
		);
	}

	const maximum = maxSize(type);

	if (upload.size > maximum) {
		throw Error(
			translate(
				'global.validator.file_too_large',
				maxUpload[type] ?? '50MB'
			)
		);
	}

	return true;
}
