import { BrowserRouter, Redirect, Route, withRouter } from 'react-router-dom';
import { initializeGoogleMaps } from './App/Homeowner/shared/xome-service';
import { withAuth } from './shared/AuthProvider';
import {
	withMarketplaceService,
	withPropertyService,
} from './service-container';
import { withPublicLayout } from './shared/PublicLayout';
import { withTheme } from './shared/ThemeProvider';
import App from './App';
import Auth from './Auth';
import Blog from './Blog';
import DeclineTransferProperty from './Auth/DeclineTransferProperty';
import EmailSubscription from './Mailing/Subscription/Subscription';
import GlobalProviders from './GlobalProviders';
import initErrorMonitor from './utils/initErrorMonitor';
import Marketplace from './Marketplace';
import Page from './shared/Page';
import paths from './config/local/paths';
import ProtectedRoute from './shared/ProtectedRoute';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Registration from './Registration';
import reportWebVitals from './reportWebVitals';
import SkipToMainContent from './shared/SkipToMainContent/SkipToMainContent';
import Switch from './shared/Switch';
import TermsOfUse from './TermsOfUse';
import withWhiteLabeling from './shared/withWhiteLabeling';

initErrorMonitor();
initializeGoogleMaps();

const InjectedApp = withAuth(withTheme(App));
const InjectedAuth = withWhiteLabeling(withAuth(Auth));
const InjectedBlog = withWhiteLabeling(withTheme(withPublicLayout(Blog)));
const InjectedMarketplace = withWhiteLabeling(
	// @ts-ignore fixed with HoCs
	withTheme(withMarketplaceService(withPublicLayout(Marketplace)))
);
const InjectedDeclineTransferProperty = withPropertyService(
	withPublicLayout(DeclineTransferProperty)
);

/** @typedef {{readonly location: {readonly pathname: string}}} Props */

/** @extends {React.Component<Props>} */
class YhhWebApp extends React.Component {
	/* eslint-disable-next-line class-methods-use-this -- `render()` must be callable by instantiated class */
	render() {
		return (
			<GlobalProviders>
				<SkipToMainContent />
				<Switch>
					<Redirect
						exact={true}
						from={paths.subscribe}
						to={{
							pathname: paths.app.subscribe,
							search: location.search,
						}}
					/>
					<Redirect
						exact={true}
						from={paths.root}
						to={paths.auth.root}
					/>
					<Route
						component={InjectedAuth}
						path={`/:whiteLabelSlug?${paths.auth.root}`}
					/>
					<Route
						component={InjectedBlog}
						path={`/:whiteLabelSlug?${paths.blog}`}
					/>
					<Route
						component={InjectedMarketplace}
						path={`/:whiteLabelSlug?${paths.marketplace}`}
					/>
					<Route
						component={Registration}
						path={`/:whiteLabelSlug?${paths.register.root}`}
					/>
					<Route
						component={EmailSubscription}
						path={`/:whiteLabelSlug?${paths.notifications.email.subscription}`}
					/>

					<Page
						component={TermsOfUse}
						path={`/:whiteLabelSlug?${paths.termsOfUse}`}
						title="Terms of Use"
						withContainer={true}
					/>
					<ProtectedRoute
						component={InjectedApp}
						path={paths.app.root}
					/>
					<Page
						component={InjectedDeclineTransferProperty}
						path={`${paths.declineTransferPropertySlug}/:propertyId`}
						title="Decline Property Transfer"
						withContainer={true}
					/>
				</Switch>
			</GlobalProviders>
		);
	}
	/* eslint-enable-next-line class-methods-use-this */
}

// @ts-ignore
const RouterPropsInjectedYhhWebApp = withRouter(YhhWebApp);

/** @type {ReactDOM.Root}  */
// @ts-ignore element will not be null
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<RouterPropsInjectedYhhWebApp />
		</BrowserRouter>
	</React.StrictMode>
);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
