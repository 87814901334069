import apiUrls from 'config/local/api-urls';
import HttpClient from '@mooveguru/js-http-client';
import movingTaskTypes from '@mooveguru/yhh-shared-config/moving-task-types.json';

/** @typedef {import('./MovingTask').default} MovingTask */

const httpClient = new HttpClient();

/**
 * @param {string} taskId
 * @param {string} accessToken
 * @returns {Promise<void>}
 */
export async function completeMovingTask(taskId, accessToken) {
	await updateMovingTask(new Date(), taskId, accessToken);
}

/**
 * @param {string} accessToken
 * @returns {Promise<MovingTask[]>}
 */
export async function getMovingTasks(accessToken) {
	const response = await httpClient.get(
		apiUrls.me.tasks.root,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}

	const results = response.body.results;

	return results.movingTasks

		.map((task) => {
			const taskType = getTaskType(task.type_id);

			return {
				id: task.id,
				isComplete: task.completed_at !== null,

				name: taskType.name.en,
				searchTerm: taskType.search_term ?? '',
				createdDate: results.created_at ?? new Date(),
				step: taskType.step,
			};
		})

		.sort((taskA, taskB) => taskA.step - taskB.step);
}

/**
 * @param {string} accessToken
 * @returns {Promise<MovingTask|null>}
 */
export async function getNextMovingTask(accessToken) {
	const response = await httpClient.get(
		apiUrls.me.tasks.moving.next,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}

	const results = response.body.results;

	if (Object.keys(results).length === 0) {
		return null;
	}

	const taskType = getTaskType(results.type_id);

	return {
		id: results.id,
		isComplete: results.completed_at !== null,
		name: taskType.name.en,
		searchTerm: taskType.search_term || '',
		step: taskType.step,
		createdDate: results.created_at ?? new Date(),
	};
}

/**
 * @param {string} taskId
 * @param {string} accessToken
 * @returns {Promise<void>}
 */
export async function undoCompleteMovingTask(taskId, accessToken) {
	await updateMovingTask(null, taskId, accessToken);
}

/**
 * @param {?Date} completedAt
 * @param {string} taskId
 * @param {string} accessToken
 * @returns {Promise<void>}
 */
async function updateMovingTask(completedAt, taskId, accessToken) {
	const response = await httpClient.put(
		`${apiUrls.tasks.moving}/${taskId}`,
		new Headers({
			'Content-Type': 'application/json',
			'X-Access-Token': accessToken,
		}),
		{ completed_at: completedAt }
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}
}

/**
 * @param {number} typeId
 */
function getTaskType(typeId) {
	const type = movingTaskTypes.find((type) => type.id === typeId);

	if (type === undefined) {
		throw new Error(`Missing type for task with type ID ${typeId}.`);
	}

	return type;
}
