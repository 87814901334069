/**
 * @type {{leadScore : 'unchanged' | 'increase' | 'decrease'}[]}
 */
export default [
	{ leadScore: 'increase' },
	{ leadScore: 'unchanged' },
	{ leadScore: 'decrease' },
	{ leadScore: 'decrease' },
	{ leadScore: 'decrease' },
	{ leadScore: 'decrease' },
	{ leadScore: 'decrease' },
];
