import { translate } from '../App/Internationalization';
import images from '../config/local/images';
const contact = process.env.REACT_APP_REACT_APP_CONTACT_CAP ?? '100';
export const Plan = {
	free: [
		{
			path: images.icons.submit,
			text: translate('registration.plan.agent.free.point'),
		},
		{
			path: images.icons.submit,
			text: translate('registration.plan.agent.free.point_1', contact),
		},
		{
			path: images.icons.closeCircle,
			text: translate('registration.plan.agent.free.point_2'),
		},
	],
	monthly: [
		{
			path: images.icons.submit,
			text: translate('registration.plan.agent.monthly.point'),
		},
		{
			path: images.icons.submit,
			text: translate('registration.plan.agent.monthly.point_1'),
		},
		{
			path: images.icons.submit,
			text: translate('registration.plan.agent.monthly.point_2'),
		},
	],
};
