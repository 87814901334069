import { translate } from '../../../App/Internationalization';
import * as yup from 'yup';
import applianceCategories from '@mooveguru/yhh-shared-config/appliances/categories.json';
import applianceRooms from '@mooveguru/yhh-shared-config/appliances/rooms.json';
import BaseField from '../../../shared/Forms/Fields/BaseField';
import BaseForm from '../../../shared/Forms/BaseForm';
import FieldDate from '../../../shared/Forms/Fields/FieldDate';
import FieldSelect from '../../../shared/Forms/Fields/FieldSelect';
import FieldTextarea from '../../../shared/Forms/Fields/FieldTextarea';
import FormErrorMessages from '../../../shared/Forms/Messages/FormErrorMessages';
import images from '../../../config/local/images';
import LinkButton from '../../../shared/LinkButton';
import paths from '../../../config/local/paths';
import React from 'react';
import SelectOptionList from '../../../shared/Forms/Inputs/SelectOptionList';
import SubmitButton from '../../../shared/Forms/Inputs/SubmitButton';

const validationSchema = yup.object().shape({
	brand: yup.string().required('Brand is required.'),
	category: yup.string().required('Category is required.'),
	model: yup.string().required('Model is required.'),
	notes: yup.string().nullable(),
	purchaseDate: yup.date().nullable(),
	purchasePrice: yup
		.number()
		.nullable()
		.typeError('Purchased price is not a valid number'),
	room: yup.string().required('Room is required.'),
	warrantyExpirationDate: yup.date().nullable(),
});

// TODO: Add Manual Search and Selection

export default class ApplianceForm extends React.Component {
	static defaultProps = {
		initialValues: {
			brand: '',
			category: '',
			model: '',
			notes: '',
			purchaseDate: '',
			purchasePrice: '',
			room: '',
			warrantyExpirationDate: '',
		},
	};

	/** @returns {JSX.Element} */
	render() {
		return (
			<React.Fragment>
				<header className="flex items-center justify-between mt-10 mb-6">
					<h1 className="hl-ms-6 mt-10 mb-6">
						{this.props.formTitle}
					</h1>
					<LinkButton
						icon={images.icons.delete}
						to={paths.app.homeowner.applianceCenter.root}
					>
						{translate(
							'homeowner.pages.appliance_center.form.cancel'
						)}
					</LinkButton>
				</header>

				<BaseForm
					initialValues={this.props.initialValues}
					onSubmit={this.props.onSubmit}
					validationSchema={validationSchema}
				>
					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<FieldSelect
							label={translate(
								'homeowner.pages.appliance_center.form.room.label'
							)}
							name="room"
							noDefaultOption={true}
							required={true}
						>
							<SelectOptionList values={applianceRooms} />
						</FieldSelect>

						<FieldSelect
							label={translate(
								'homeowner.pages.appliance_center.form.category.label'
							)}
							name="category"
							noDefaultOption={true}
							required={true}
						>
							<SelectOptionList values={applianceCategories} />
						</FieldSelect>

						<BaseField
							label={translate(
								'homeowner.pages.appliance_center.form.brand.label'
							)}
							name="brand"
							placeholder={translate(
								'homeowner.pages.appliance_center.form.brand.placeholder'
							)}
							required={true}
						/>

						<BaseField
							label={translate(
								'homeowner.pages.appliance_center.form.model.label'
							)}
							name="model"
							placeholder={translate(
								'homeowner.pages.appliance_center.form.model.placeholder'
							)}
							required={true}
						/>

						<FieldDate
							label={translate(
								'homeowner.pages.appliance_center.form.purchase_date.label'
							)}
							name="purchaseDate"
						/>

						<BaseField
							label={translate(
								'homeowner.pages.appliance_center.form.purchase_price.label'
							)}
							name="purchasePrice"
							onBlur={this.props.onBlur}
							placeholder={translate(
								'homeowner.pages.appliance_center.form.purchase_price.placeholder'
							)}
							type="number"
						/>

						<FieldDate
							label={translate(
								'homeowner.pages.appliance_center.form.warranty_expiration.label'
							)}
							name="warrantyExpirationDate"
						/>

						<FieldTextarea
							label={translate(
								'homeowner.pages.appliance_center.form.notes.label'
							)}
							name="notes"
							placeholder={translate(
								'homeowner.pages.appliance_center.form.notes.placeholder'
							)}
						/>
					</fieldset>
					<FormErrorMessages messages={this.props.errorMessages} />
					<footer className="flex justify-end">
						<SubmitButton className="mr-4">
							{this.props.submitText ?? 'Save'}
						</SubmitButton>

						<LinkButton
							icon={images.icons.delete}
							size="large"
							to={paths.app.homeowner.applianceCenter.root}
						>
							{translate(
								'homeowner.pages.appliance_center.form.cancel'
							)}
						</LinkButton>
					</footer>
				</BaseForm>
			</React.Fragment>
		);
	}
}
