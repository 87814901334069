import React from 'react';

import AppNavigation from 'App/AppNavigation';
import SideDrawer from 'App/SideDrawer/SideDrawer';

export default class AppSideDrawer extends React.Component {
	render() {
		return (
			<SideDrawer>
				<AppNavigation />
			</SideDrawer>
		);
	}
}
