import React from 'react';
import { Route } from 'react-router-dom';

import { RoleGate } from 'App/shared/RoleGate';
import { withAuth } from 'shared/AuthProvider';

export class RoleGatedRoute extends React.Component {
	render() {
		return (
			<RoleGate
				allowedRoles={this.props.allowedRoles}
				auth={this.props.auth}
			>
				<Route
					component={this.props.component}
					exact={this.props.exact}
					path={this.props.path}
				/>
			</RoleGate>
		);
	}
}

export default withAuth(RoleGatedRoute);
