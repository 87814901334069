import { toAbsoluteUrl } from '../utils/toAbsoluteUrl';
import images from '../config/local/images';
import React from 'react';

export default class ProfileImage extends React.Component {
	render() {
		return (
			<figure className="author flex-grow-0 flex-shrink-0">
				<img
					alt={
						this.props.altText
							? this.props.altText
							: 'A default avatar headshot'
					}
					className={this.props.className ?? 'avatar'}
					src={this.props.headshotUrl ?? toAbsoluteUrl(images.avatar)}
				/>
				{this.props.children}
			</figure>
		);
	}
}
