/**
 * @typedef {import('../../../App/Admin/Users/Admins/Roles').Roles} AdminRoles
 * @typedef {import('./OfferFields').default} FormValues
 * @typedef {import('./OfferFormProps').default} Props
 * @typedef {import('./GroupField').default} GroupField
 * @typedef {import('formik').FormikValues} FormikProps
 */

import { AuthContext } from '../../../../src/shared/AuthProvider';
import { translate } from '../../../App/Internationalization';
import * as validators from '../../../shared/validators';
import * as yup from 'yup';
import BaseField from '../../../shared/Forms/Fields/BaseField';
import BaseForm from '../../../shared/Forms/BaseForm';
import categoryList from '@mooveguru/yhh-shared-config/marketplace-categories.json';
import FieldEmail from '../../../shared/Forms/Fields/FieldEmail';
import FieldFile from '../../../shared/Forms/Fields/FieldFile';
import FieldGroupSelect from '../../../shared/Forms/Fields/FieldGroupSelect';
import FieldPhoneNumber from '../../../shared/Forms/Fields/PhoneNumber/FieldPhoneNumber';
import FieldPostalCode from '../../../shared/Forms/Fields/Address/FieldPostalCode';
import FieldSearchableSelect from '../../../shared/Forms/Fields/FieldSearchableSelect';
import FieldStatus from '../../../shared/Forms/Fields/FieldStatus';
import FieldTextarea from '../../../shared/Forms/Fields/FieldTextarea';
import FieldWebsite from '../../../shared/Forms/Fields/FieldWebsite';
import images from '../../../config/local/images';
import LinkButton from '../../../shared/LinkButton';
import mimes from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import React from 'react';
import separateObjectIntoArray from '../../../utils/separateObjectIntoArray';
import SubmitButton from '../../../shared/Forms/Inputs/SubmitButton';
import uploadSize from '@mooveguru/yhh-shared-config/files/max-upload.json';

/** @extends {React.Component<Props>} */
export default class OfferForm extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		OfferForm.handleUpdateGroupList =
			OfferForm.handleUpdateGroupList.bind(this);
	}

	/**
	 * @param {GroupField} group
	 * @returns {{id: string, name: string, isPrimary: boolean}}
	 */
	static #mapToGroup(group) {
		return {
			id: group.value,
			isPrimary: false,
			name: group.label,
		};
	}

	/**
	 * @param {{value: string, label: string}[]} event
	 * @param {FormikProps} formik
	 */
	static async handleUpdateGroupList(event, formik) {
		const groupList = event.map(OfferForm.#mapToGroup);

		if (!groupList.length) {
			return;
		}

		formik.form.setFieldValue('groupList', groupList);
	}

	/**
	 * @returns {boolean}
	 */
	#isGroupRequired() {
		let required = true;

		this.context.roles.forEach(
			/** @param {AdminRoles} role */ (role) => {
				if (role === 'owner' || role === 'admin') {
					required = false;
				}
			}
		);

		return required;
	}

	/**
	 * @param {boolean} isGroupRequired
	 * @returns {object}
	 */
	static getValidationSchema(isGroupRequired) {
		return yup.object().shape({
			description: validators.required('Description is required.'),
			groupList: isGroupRequired
				? yup
						.array()
						.min(1)
						.of(
							yup.object().shape({
								id: yup.string(),
								isPrimary: yup.bool(),
							})
						)
						.required('Group is required')
				: yup.array().nullable(),
			postalCode: validators.postalCode,
			vendorEmail: validators.email,
			vendorName: validators.required('Vendor Name is required.'),
			vendorPhone: validators.phoneNumber.required(
				'Phone Number is required.'
			),
			website: validators.url,
		});
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const isGroupRequired =
			this.props.hasGroupField && this.#isGroupRequired();

		return (
			<React.Fragment>
				<h1 className="hl-ms-6 mt-10 mb-6">{this.props.formTitle}</h1>

				<BaseForm
					className="mb-10"
					initialValues={this.props.initialValues}
					onSubmit={this.props.onSubmit}
					validationSchema={OfferForm.getValidationSchema(
						isGroupRequired
					)}
				>
					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<legend className="h-ms-5 mb-6">
							{translate('global.offers.form.vendor_title')}
						</legend>

						<BaseField
							label={translate(
								'global.offers.form.vendor_name.label'
							)}
							name="vendorName"
							placeholder={translate(
								'global.offers.form.vendor_name.placeholder'
							)}
							required={true}
						/>

						<BaseField
							label={translate(
								'global.offers.form.offer_name.label'
							)}
							name="title"
							placeholder={translate(
								'global.offers.form.offer_name.placeholder'
							)}
						/>

						<FieldSearchableSelect
							/* prettier-ignore */
							defaultValue={this.props.initialValues.category ? {
									 /* 
									  * cannot be typed since categoryList is getting
									  * pulled from Mooveguru package and are subject to changes
									  */
									 // @ts-ignore
									 label: categoryList[this.props.initialValues.category],
									 value: this.props.initialValues.category,
								 } : ''}
							/* prettier-enable */
							inputClass="search-select"
							label={translate(
								'homeowner.pages.appliance_center.form.category.label'
							)}
							name="category"
							options={separateObjectIntoArray(categoryList)}
							placeholder={translate(
								'global.local_pros_search.label'
							)}
							required={true}
						/>
						<FieldEmail
							label={translate(
								'global.offers.form.vendor_email.label'
							)}
							name="vendorEmail"
							placeholder={translate(
								'global.offers.form.vendor_email.placeholder'
							)}
							required={true}
						/>

						<FieldWebsite
							label={translate(
								'global.forms.inputs.url_inputs.website.label'
							)}
							name="website"
						/>

						<FieldPostalCode name="postalCode" required={true} />

						<FieldPhoneNumber
							label={translate(
								'global.offers.form.vendor_phone.label'
							)}
							name="vendorPhone"
							placeholder={translate(
								'global.offers.form.vendor_phone.placeholder'
							)}
							required={true}
						/>

						<FieldTextarea
							className="col-span-full"
							help={translate(
								'global.offers.form.vendor_desc.help_text'
							)}
							label={translate(
								'global.offers.form.vendor_desc.label'
							)}
							name="description"
							placeholder={translate(
								'global.offers.form.vendor_desc.placeholder'
							)}
							required={true}
						/>
					</fieldset>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
						<legend className="h-ms-5 mb-6">
							{translate('global.offers.form.settings_title')}
						</legend>

						{this.props.hasGroupField ? (
							<FieldGroupSelect
								// @ts-ignore -- due to common component it affects multiple pages.
								isMultipleSelect={true}
								label={translate(
									'admin.pages.agents.form.group_select.label'
								)}
								name="groupList"
								onChange={OfferForm.handleUpdateGroupList}
								required={this.#isGroupRequired()}
							/>
						) : (
							''
						)}

						<FieldStatus
							name="isActive"
							required={true}
							status={this.props.initialValues.isActive}
						/>
					</fieldset>

					<fieldset className="mb-8">
						<legend className="h-ms-5 mb-6">
							{translate('global.offers.form.vendor_image.label')}
						</legend>

						<FieldFile
							accept={Object.values(mimes.images)}
							help={translate('global.forms.help.logo')}
							inputClass="mb-4"
							labelClass="label font-bold mb-4 text-gray-300 text-lg"
							maxSize={uploadSize.image}
							name="image"
							// @ts-ignore -- type declared
							onChange={this.props.onChange}
							preview={this.props.preview}
						/>
					</fieldset>

					<footer className="mx-auto sm:ml-0 button-group w-min">
						<SubmitButton>{this.props.formSubmitText}</SubmitButton>

						<LinkButton
							icon={images.icons.delete}
							size="large"
							to={this.props.cancelPath}
						>
							{translate('global.offers.cancel')}
						</LinkButton>
					</footer>
				</BaseForm>
			</React.Fragment>
		);
	}
}

OfferForm.contextType = AuthContext;
