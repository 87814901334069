import * as Sentry from '@sentry/react';

/**
 * @returns {string}
 */
function getEnvironment() {
	return process.env.REACT_APP_ENV || 'local';
}

/**
 * @returns {void}
 */
export default function initErrorMonitor() {
	const environment = getEnvironment();

	if (environment === 'local') {
		return;
	}

	Sentry.init({
		denyUrls: [
			/^chrome:\/\//i,
			/extensions\//i,
			/googletagmanager\.com/i,
			/googleapis\.com/i,
			/xomewidgets\.com/i,
		],
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: environment,
		integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
		release: `${process.env.REACT_APP_SENTRY_REPO}@${process.env.REACT_APP_VERSION}`,
		tracesSampleRate: 0.2,
	});
}
