/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */
import { Redirect } from 'react-router';
import { translate } from '../../Internationalization';
import { withSettings } from '../../../shared/SettingProvider/SettingProvider';
import ActionMessage from '../../../shared/ActionMessages/ActionMessage';
import images from '../../../config/local/images';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class ContactLimitPopup extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/**
		 * @type {State}
		 */
		this.state = {
			upgradeRedirect: false,
		};

		this._handleSubmit = this._handleSubmit.bind(this);
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleSubmit() {
		this.setState({ upgradeRedirect: true });
	}

	/** @returns {JSX.Element | null} */
	render() {
		if (
			!this.props.settings.features.get(
				'agent:limit_contacts_on_free_accounts'
			)
		) {
			return null;
		}

		if (!this.props.showPopup) {
			return null;
		}

		if (this.state.upgradeRedirect) {
			return (
				<Redirect
					to={{
						pathname: this.props.to,
					}}
				/>
			);
		}

		return (
			<ActionMessage
				action="error"
				onClose={this.props.onClose}
				onSubmit={this._handleSubmit}
				submitIcon={images.icons.subscription}
				submitTitle={translate('global.contact_limit.upgrade')}
				title={this.props.title}
			>
				{this.props.children}
			</ActionMessage>
		);
	}
}

export default withSettings(ContactLimitPopup);
