/**
 * @typedef {import('./MortgageInformation').default} MortgageInformation
 * @typedef {import('./MortgageInformationCardProps').default} Props
 * @typedef {import('./MortgageInformationCardState').default} State
 */

import { toAbsoluteUrl } from 'utils/toAbsoluteUrl';
import { translate } from 'App/Internationalization';
import { withProperty } from '../PropertyProvider';
import { withPropertyService } from 'service-container';
import captureError from '../../../utils/captureError';
import images from 'config/local/images';
import LinkButton from 'shared/LinkButton';
import Loading from '../../../shared/Loading';
import mapMortgageInformation from './mapMortgageInformation';
import paths from 'config/local/paths';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class MortgageInformationCard extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		this.state = {
			error: false,
			isGettingData: true,
			mortgageInfo: [],
			propertyImage: null,
		};

		this.getMortgageInfo = this.getMortgageInfo.bind(this);
		this.getPropertyImage = this.getPropertyImage.bind(this);
	}

	componentDidMount() {
		this.getMortgageInfo();
		this.getPropertyImage();
	}

	async getMortgageInfo() {
		try {
			const propertyId = this.props.property?.selectedProperty?.id;

			if (!propertyId) {
				throw new Error(
					translate(
						'homeowner.pages.dashboard.properties.property_info_error'
					)
				);
			}

			const mortgageInfo =
				await this.props.propertyService.getEditablePropertyById(
					propertyId
				);

			this.setState({
				isGettingData: false,
				mortgageInfo: mapMortgageInformation(mortgageInfo),
			});
		} catch (error) {
			captureError(error);

			this.setState({
				error: true,
				isGettingData: false,
			});
		}
	}

	async getPropertyImage() {
		try {
			const propertyId = this.props.property?.selectedProperty?.id;

			if (!propertyId) {
				throw new Error(
					translate(
						'homeowner.pages.dashboard.properties.property_info_error'
					)
				);
			}
			const propertyImage =
				await this.props.propertyService.getDefaultImage(propertyId);

			this.setState({
				isGettingData: false,
				propertyImage,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				error: true,
				isGettingData: false,
			});
		}
	}

	renderMortgageInfo() {
		if (!this.state.mortgageInfo) {
			return (
				<h1>
					{translate('homeowner.pages.finances.view.mortgage.error')}
				</h1>
			);
		}

		return this.state.mortgageInfo.map(
			/**
			 *  @param {{footer: string | number, full?: boolean, header: string, id: number}} info
			 *  @returns {JSX.Element}
			 */ (info) => (
				<div
					className={`property-detail-item ${
						info.full ? 'width-full' : 'width-half'
					}`}
					key={info.id}
				>
					<dt className="property-detail-item-header">
						{info.header}
					</dt>
					<dd className="property-detail-item-footer">
						{info.footer}
					</dd>
				</div>
			)
		);
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	render() {
		if (this.state.error) {
			return null;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		const image = toAbsoluteUrl(
			this.state.propertyImage ?? images.homeowners.properties.streetView
		);

		return (
			<section className="mt-10">
				<header className="h-ms-4">
					{translate('homeowner.pages.finances.view.mortgage.title')}
				</header>
				<section className="card card-property-detail card-border mt-3">
					<figure>
						<img
							alt={translate(
								'homeowner.pages.finances.view.mortgage.property_img_alt'
							)}
							className="card-image"
							src={image}
						/>
					</figure>
					<dl className="card-body property-details text-start">
						{this.renderMortgageInfo()}
					</dl>
					<footer className="card-footer">
						<LinkButton
							className="button button-icon mx-auto"
							icon={images.icons.edit}
							iconClass="icon icon-sm icon-fill-fg"
							to={`${paths.app.homeowner.properties.edit}/${this.props.property?.selectedProperty?.id}`}
						>
							{translate(
								'homeowner.pages.finances.view.mortgage.edit_details'
							)}
						</LinkButton>
					</footer>
				</section>
			</section>
		);
	}
}

export default withPropertyService(withProperty(MortgageInformationCard));
