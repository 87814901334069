// mapper that returns hardcoded objects/no complexity
/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */

/**
 * @typedef {import('./MortgageInformation').default} MortgageInformation
 * @typedef {import('../../shared/PropertyService/EditablePropertyDetails').default} EditablePropertyDetails
 */
import { translate } from 'App/Internationalization';
import utilities from '@mooveguru/js-utilities';

/**
 * @param {EditablePropertyDetails} data
 * @returns {MortgageInformation}
 */
export default function mapMortgageInformation(data) {
	const noContent = translate(
		'homeowner.pages.dashboard.properties.missing_datum'
	);

	return [
		{
			footer: data.details?.amountToLoan
				? utilities.currency.convertNumberToCurrencyString(
						data.details.amountToLoan,
						'en-US'
				  )
				: noContent,
			full: true,
			header: translate(
				'homeowner.pages.finances.view.mortgage.loan_amount'
			),
			id: 0,
		},
		{
			footer: data.details?.originalDateOfContract
				? new Date(
						data.details.originalDateOfContract
				  ).toLocaleDateString('en-US')
				: noContent,
			header: translate(
				'homeowner.pages.finances.view.mortgage.original_date'
			),
			id: 1,
		},
		{
			footer: data.details?.dueDate
				? new Date(data.details.dueDate).toLocaleDateString('en-US')
				: noContent,

			header: translate(
				'homeowner.pages.finances.view.mortgage.due_date'
			),
			id: 2,
		},
		{
			footer: data.details?.lenderName
				? data.details.lenderName
				: noContent,
			full: true,
			header: translate('homeowner.pages.finances.view.mortgage.lender'),
			id: 3,
		},
		{
			footer: data.details?.loanType ? data.details.loanType : noContent,
			full: true,
			header: translate(
				'homeowner.pages.finances.view.mortgage.loan_type'
			),
			id: 4,
		},
		{
			footer: data.details?.rate ? `${data.details.rate}%` : noContent,
			header: translate('homeowner.pages.finances.view.mortgage.rate'),
			id: 5,
		},
		{
			footer: data.details?.maximumInterestRate
				? `${data.details.maximumInterestRate}%`
				: noContent,
			header: translate(
				'homeowner.pages.finances.view.mortgage.max_interest'
			),
			id: 6,
		},
	];
}
