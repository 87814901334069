import React from 'react';
import { Redirect } from 'react-router-dom';

import BaseForm from 'shared/Forms/BaseForm';
import images from 'config/local/images';
import InputSearch from '../../../../shared/Forms/Inputs/InputSearch';
import paths from 'config/local/paths';
import SubmitButton from 'shared/Forms/Inputs/SubmitButton';
import { translate } from 'App/Internationalization';
export default class ManualsCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			manual: null,
			isSubmitted: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(values) {
		this.setState({
			manual: values.manual,
			isSubmitted: true,
		});
	}

	render() {
		if (this.state.isSubmitted) {
			return (
				<Redirect
					to={{
						pathname: paths.app.homeowner.applianceCenter.manuals,
						state: { manual: this.state.manual },
					}}
				/>
			);
		}

		return (
			<section className="mt-6">
				<h2 className="h-ms-4 my-4">
					{translate(
						'homeowner.pages.appliance_center.dashboard.manuals_card.title'
					)}
				</h2>
				<BaseForm
					className="input-group input-inline"
					initialValues={{
						manual: '',
					}}
					noHelpText="false"
					noRequiredText="false"
					onSubmit={this.handleSubmit}
				>
					<InputSearch
						className="input input-search placeholder-start"
						name="manual"
						placeholder={translate(
							'homeowner.pages.appliance_center.dashboard.manuals_card.placeholder'
						)}
					/>
					<SubmitButton
						className="button button-md button-icon"
						icon={images.icons.search}
						iconClass="icon icon-fill-fg icon-sm"
						type="submit"
					>
						{translate(
							'homeowner.pages.appliance_center.dashboard.manuals_card.search'
						)}
					</SubmitButton>
				</BaseForm>
			</section>
		);
	}
}
