import { translate } from 'App/Internationalization';
import { withProperty } from 'App/Homeowner/PropertyProvider';
import AcceptTransferProperty from 'App/Homeowner/Properties/AcceptTransferProperty';
import AddProperty from 'App/Homeowner/Properties/AddProperty';
import EditProperty from 'App/Homeowner/Properties/EditProperty';
import Page from 'shared/Page';
import paths from 'config/local/paths';
import React from 'react';
import Switch from 'shared/Switch';
import TransferProperty from 'App/Homeowner/Properties/TransferProperty';
import ViewProperties from 'App/Homeowner/Properties/ViewProperties';

const InjectedViewProperties = withProperty(ViewProperties);
const InjectedAddProperty = withProperty(AddProperty);

export default class Properties extends React.Component {
	render() {
		return (
			<Switch>
				<Page
					component={InjectedViewProperties}
					exact={true}
					path={paths.app.homeowner.properties.root}
					title={translate('homeowner.pages.properties.root')}
				/>
				<Page
					component={InjectedAddProperty}
					exact={true}
					path={paths.app.homeowner.properties.add}
					title={translate('homeowner.pages.properties.add.title')}
				/>
				<Page
					component={EditProperty}
					path={`${paths.app.homeowner.properties.edit}/:propertyId`}
					title={translate('homeowner.pages.properties.add.title')}
				/>
				<Page
					component={TransferProperty}
					path={paths.app.homeowner.properties.transfer}
					title="Transfer Property"
				/>
				<Page
					component={AcceptTransferProperty}
					path={`${paths.app.homeowner.properties.acceptTransfer}/:propertyId`}
					title="Accept Property Transfer"
				/>
			</Switch>
		);
	}
}
