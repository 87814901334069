import { translate } from '../../../App/Internationalization';
import FormMessages from './FormMessages';
import images from '../../../config/local/images';
import React from 'react';

export default class FormSuccessMessages extends React.Component {
	render() {
		return (
			<FormMessages
				className={`${this.props.className} form-alert-success`}
				heading={this.props.heading ?? translate('global.success')}
				icon={images.icons.submit}
				messages={this.props.messages}
			/>
		);
	}
}
