import InputPhoneNumber from 'shared/Forms/Inputs/PhoneNumber/InputPhoneNumber';
import React from 'react';

export default class InputGroupPhone extends React.Component {
	render() {
		return <InputPhoneNumber name={this.props.name} type="group" />;
	}
}

InputGroupPhone.defaultProps = {
	name: 'groupPhoneNumber',
};
