/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */

import { AuthContext } from '../../../../../shared/AuthProvider';
import { currency } from '@mooveguru/js-utilities';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../utils/toAbsoluteUrl';
import { translate } from '../../../../Internationalization';
import { withAgentService } from '../../../../../service-container';
import BaseTable from '../../../../../shared/BaseTable';
import captureError from '../../../../../utils/captureError';
import ExternalAnchor from '../../../../../shared/ExternalAnchor';
import FormErrorMessages from '../../../../../shared/Forms/Messages/FormErrorMessages';
import formatDateForDisplay from '../../../../../utils/formatDateForDisplay';
import images from '../../../../../config/local/images';
import Loading from '../../../../../shared/Loading';
import NoContent from '../../../../../shared/NoContent';
import Pagination from '../../../../../shared/Pagination';
import paths from '../../../../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class LeadsTable extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/**
		 * @type {State}
		 */
		this.state = {
			currentPageNumber: 1,
			errorMessage: '',
			isGettingData: true,
			lastPageNumber: 1,
			valuations: [],
		};

		this._setTableData = this._setTableData.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			await this._setTableData();

			this.setState({
				isGettingData: false,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	get #getHeaderData() {
		return [
			{
				title: translate(
					'agent.pages.widgets.lead_generation.lead_table.date'
				),
			},
			{
				title: translate(
					'agent.pages.widgets.lead_generation.lead_table.address'
				),
			},
			{
				title: translate(
					'agent.pages.widgets.lead_generation.lead_table.valuation'
				),
			},
			{
				title: translate(
					'agent.pages.widgets.lead_generation.lead_table.name'
				),
			},
			{
				title: translate(
					'agent.pages.widgets.lead_generation.lead_table.email'
				),
			},
			{
				title: translate(
					'agent.pages.widgets.lead_generation.lead_table.phone'
				),
			},
			{
				title: this.context.agentSubscriptionLevels.length ? (
					translate('agent.pages.contacts.view.table.mover_score')
				) : (
					<div className="flex items-center">
						<Link
							className="w-3.5 mr-1"
							title="Click here to upgrade"
							to={paths.app.agent.subscription.root}
						>
							<img
								alt="Feature has been locked and requires a subscription"
								src={toAbsoluteUrl(images.icons.lock)}
							/>
						</Link>
						{translate(
							'agent.pages.contacts.view.table.mover_score'
						)}
					</div>
				),
			},
			{
				title: translate(
					'agent.pages.widgets.lead_generation.lead_table.added_to_contacts'
				),
			},
		];
	}

	/**
	 * @protected
	 * @param {number} pageNumber
	 * @returns {Promise<void>}
	 */
	async _setTableData(pageNumber = 1) {
		const response = await this.props.agentService.getValuations(
			pageNumber
		);

		const valuationList = response.valuations;

		this.setState({
			currentPageNumber: pageNumber,
			isGettingData: false,
			lastPageNumber: response.pages ?? 1,
			valuations: valuationList,
		});
	}

	/**
	 * @param {State['valuations'][0]['address']} address
	 * @returns {string}
	 */
	static #formatAddress(address) {
		const streetAddress2 = address.streetAddress2
			? ` ${address.streetAddress2}`
			: '';

		return `${address.streetAddress1}${streetAddress2}, ${address.city}`.trim();
	}

	/**
	 * @param {NonNullable<State['valuations'][0]['contact']>} contact
	 * @returns {'score negative' | 'score' | ''}
	 */
	static #leadScoreClass(contact) {
		const base = 'score';

		const scoreList = contact.leadScores.sort(
			(a, b) =>
				new Date(b.date.toString()).valueOf() -
				new Date(a.date.toString()).valueOf()
		);

		const first = scoreList[0] ?? null;
		const second = scoreList[1] ?? null;

		if (first === null) {
			return '';
		}

		if (second === null) {
			return base;
		}

		return first.score >= second.score ? base : `${base} negative`;
	}

	/**
	 * @param {State['valuations'][0]} valuation
	 * @param {number} index
	 * @returns {{data : string | JSX.Element, index: string}}
	 */
	static #renderName(valuation, index) {
		if (!valuation.contact) {
			return {
				data: 'N/A',
				index: `${index}`,
			};
		}

		return {
			data: (
				<Link
					className="hover-color-primary no-underline text-black"
					to={{
						pathname: `${paths.app.agent.contacts.edit}/${valuation.contact.id}`,
					}}
				>
					{valuation.contact?.name ?? 'N/A'}
				</Link>
			),
			index: `${index}`,
		};
	}

	/**
	 * @param {State['valuations'][0]['contact']} contact
	 * @param {number} index
	 * @returns {{data : string | JSX.Element, index: string}}
	 */
	#renderMoverScore(contact, index) {
		if (!contact) {
			return {
				data: 'N/A',
				index: `${index}`,
			};
		}

		let hasSubscription = false;
		const validLevels = ['agent', 'domain'];

		this.context.agentSubscriptionLevels.forEach(
			(/** @type {string} */ level) => {
				if (validLevels.includes(level)) {
					hasSubscription = true;
				}
			}
		);

		if (contact.hasGroupSubscription) {
			hasSubscription = true;
		}

		if (hasSubscription) {
			return {
				data: (
					<span className={LeadsTable.#leadScoreClass(contact)}>
						{contact.leadScores.length
							? contact.leadScore
							: translate(
									'agent.pages.dashboard.score_board.no_score'
							  )}
					</span>
				),
				index: `${index}`,
			};
		}

		return {
			data: (
				<Link
					className="no-underline flex items-center hover-lighter text-info"
					to={paths.app.agent.subscription.root}
				>
					{translate('agent.pages.contacts.view.score')}
				</Link>
			),
			index: `${index}`,
		};
	}

	/**
	 * @returns {{data: string | JSX.Element, index: string;}[][]}
	 */
	get #getTableBodyData() {
		// eslint-disable-next-line complexity
		return this.state.valuations.map((valuation, index) => [
			{
				data: formatDateForDisplay(valuation.createdAt),
				index: `${index}`,
			},
			{
				data: LeadsTable.#formatAddress(valuation.address),
				index: `${index}`,
			},
			{
				data: currency.convertNumberToCurrencyString(
					currency.convertCentsToDollars(valuation.value)
				),
				index: `${index}`,
			},
			LeadsTable.#renderName(valuation, index),
			{
				data: valuation.contact?.email ? (
					<ExternalAnchor
						className="hover-color-primary no-underline text-black"
						href={`mailto:${valuation.contact.email}`}
					>
						{valuation.contact.email}
					</ExternalAnchor>
				) : (
					'N/A'
				),
				index: `${index}`,
			},
			{
				data: valuation.contact?.phone ? (
					<ExternalAnchor
						className="hover-color-primary no-underline text-black"
						href={`tel:${valuation.contact.phone}`}
					>
						{valuation.contact.phone}
					</ExternalAnchor>
				) : (
					'N/A'
				),
				index: `${index}`,
			},
			this.#renderMoverScore(valuation.contact, index),
			{
				data: valuation.contact
					? translate(
							'agent.pages.widgets.lead_generation.lead_table.yes'
					  )
					: translate(
							'agent.pages.widgets.lead_generation.lead_table.no'
					  ),
				index: `${index}`,
			},
		]);
	}

	/**
	 * @returns {React.ReactElement}
	 */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		if (this.state.errorMessage) {
			return <FormErrorMessages messages={this.state.errorMessage} />;
		}

		const storageTimeLimit =
			process.env.REACT_APP_VALUATION_STORAGE_TIME_LIMIT ?? '180';

		if (!this.state.valuations.length) {
			return (
				<NoContent
					message={translate(
						'agent.pages.widgets.lead_generation.lead_table.no_leads.message'
					)}
					title={translate(
						'agent.pages.widgets.lead_generation.lead_table.no_leads.title',
						storageTimeLimit
					)}
				/>
			);
		}

		return (
			<React.Fragment>
				<BaseTable
					bodyData={this.#getTableBodyData}
					errorMessage={this.state.errorMessage}
					headerData={this.#getHeaderData}
				/>

				<Pagination
					currentPageNumber={this.state.currentPageNumber}
					lastPageNumber={this.state.lastPageNumber}
					setCurrentPageNumber={this._setTableData}
				/>
			</React.Fragment>
		);
	}
}

LeadsTable.contextType = AuthContext;

export default withAgentService(LeadsTable);
