/**
 * @typedef {import('../../config/local/Import/CustomTheme').default} CustomTheme
 * @typedef {import('../../config/local/Import/Field').default} Field
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */

import {
	agentFields,
	contactFields,
	getCustomTheme,
	validateRows,
} from '../../config/local/Import/import';
import { BaseSVG } from '../BaseSVG';
import { filesize } from '@mooveguru/js-utilities';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { translate } from '../../App/Internationalization';
import { withTheme } from '../../shared/ThemeProvider';
import BaseButton from '../../shared/BaseButton/BaseButton';
import ImportLocation from '../ImportLocation';
import ImportSpreadsheetForm from '../ImportSpreadsheetForm';
import React from 'react';
import uploadSize from '@mooveguru/yhh-shared-config/files/max-upload.json';

/** @extends {React.Component<Props, State>} */
export class Import extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		/**
		 * @type {State}
		 */
		this.state = {
			isOpen: false,
		};

		this._handleOnToggle = this._handleOnToggle.bind(this);
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleOnToggle() {
		this.setState((prevState) => ({
			isOpen: !prevState.isOpen,
		}));
	}

	/**
	 * @returns {Field[]}
	 */
	get #getFields() {
		if (this.props.type === 'agent') {
			return agentFields;
		}

		return contactFields;
	}

	/**
	 * @returns {CustomTheme | null}
	 */
	get #getCustomTheme() {
		if (!this.props.theme.colorMain) {
			return null;
		}

		return getCustomTheme(
			this.props.theme.colorMain,
			this.props.theme.colorLight
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	get #renderImportLocation() {
		if (this.props.userType === 'administrator') {
			return (
				<BaseButton onClick={this._handleOnToggle} type="button">
					{translate('global.import.start_import')}
				</BaseButton>
			);
		}

		return (
			<ImportLocation
				// @ts-ignore service container
				activeTab={this.props.activeTab}
				handleActiveTab={this.props.handleActiveTab}
				handleOnToggle={this._handleOnToggle}
			/>
		);
	}

	/** @returns {JSX.Element} */
	render() {
		const maxRows = process.env.REACT_APP_MAX_IMPORT_ROWS;
		const typeText = this.props.type === 'agent' ? 'agents' : 'contacts';
		return (
			<React.Fragment>
				<h1 className="hl-ms-6 mt-10 mb-6">
					{this.props.formTitle ?? translate('import_contacts.title')}
				</h1>

				<ImportSpreadsheetForm
					disabled={this.props.disabled}
					errorMessages={this.props.errorMessages}
					initialValues={this.props.initialValues}
					successMessages={this.props.successMessages}
				>
					{this.props.children}
				</ImportSpreadsheetForm>

				<ReactSpreadsheetImport
					customTheme={this.#getCustomTheme ?? undefined}
					fields={this.#getFields}
					isOpen={this.state.isOpen}
					maxFileSize={filesize.convertStringToBytes(
						uploadSize.import
					)}
					maxRecords={Number(maxRows)}
					onClose={this._handleOnToggle}
					onSubmit={this.props.onSubmit}
					// @ts-ignore
					rowHook={
						this.props.type === 'contact' ? validateRows : undefined
					}
				/>

				{this.#renderImportLocation}

				<section className="my-6">
					<h3 className="h-ms-1">{this.props.lead}</h3>
					<p className="text rich-text">
						<span>
							{translate(
								'import_spreadsheet_form.download_template',
								typeText
							)}
						</span>
						<a
							className="text-theme"
							download={true}
							href={this.props.template}
						>
							{translate(
								'import_spreadsheet_form.download_template_here'
							)}
						</a>
					</p>
				</section>
			</React.Fragment>
		);
	}
}

export default withTheme(Import);
