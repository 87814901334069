/**
 * @typedef {import('../../../../../App/shared/AgentService/Agent').default} Agent
 * @typedef {import('./AcceptedAgentsProps').default} Props
 * @typedef {import('./AcceptedAgentsState').default} State
 */
import { AuthContext } from '../../../../../shared/AuthProvider';
import { translate } from '../../../../../App/Internationalization';
import { withAgentService } from 'service-container';
import captureError from '../../../../../utils/captureError';
import Loading from '../../../../../shared/Loading';
import ProgressBar from '../../../../../shared/ProgressBar/ProgressBar';
import React, { Component } from 'react';

/** @extends {React.Component<Props, State>} */
export class AcceptedAgents extends Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		this.state = {
			acceptedAgents: 0,
			errorMessage: null,
			isGettingData: true,
			totalAgents: 0,
		};

		this.setAcceptedAgents = this.setAcceptedAgents.bind(this);
	}

	async componentDidMount() {
		try {
			await this.setAcceptedAgents();
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async setAcceptedAgents() {
		const { total } = await this.props.agentService.getAgents(
			this.context.accessToken
		);

		const response = await this.props.agentService.getAgentUpgradedCount();

		this.setState({
			acceptedAgents: response.active_agent_count,
			isGettingData: false,
			totalAgents: total,
		});
	}

	render() {
		if (this.state.errorMessage !== null) {
			return <p>{this.state.errorMessage}</p>;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<ProgressBar
				max={this.state.totalAgents}
				title={translate('admin.nav.agent.accepted_agents')}
				value={this.state.acceptedAgents}
			/>
		);
	}
}

AcceptedAgents.contextType = AuthContext;

export default withAgentService(AcceptedAgents);
