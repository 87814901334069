import React from 'react';

export default class SelectOption extends React.Component {
	render() {
		return (
			<option value={this.props.value} selected={this.props.selected}>
				{this.props.title}
			</option>
		);
	}
}
