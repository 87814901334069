import { BaseSVG } from 'shared/BaseSVG';
import LinkButton from '../../shared/LinkButton';
import React from 'react';

export default class AccountTypeCard extends React.Component {
	render() {
		return (
			<LinkButton
				className="card card-registration mt-6 cursor-pointer"
				to={this.props.link}
			>
				<div className="card-horizontal">
					<BaseSVG
						className="account-type-icon color-primary-active"
						path={this.props.iconRef}
					/>
					<section className="ml-2 card-body">
						<h2 className="card-title text-theme">
							{this.props.titleText}
						</h2>
						<p
							className="card-text"
							dangerouslySetInnerHTML={{
								__html: this.props.descriptionText,
							}}
						/>
					</section>
				</div>
			</LinkButton>
		);
	}
}
