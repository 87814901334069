/**
 * @typedef {import('./Props').default} Props
 */
import { AuthContext } from '../AuthProvider';
import * as yup from 'yup';
import BaseForm from '../Forms/BaseForm';
import FormErrorMessages from '../Forms/Messages/FormErrorMessages';
import FormSuccessMessages from '../Forms/Messages/FormSuccessMessages';
import React from 'react';

const validationSchema = yup.object().shape({
	file: yup.string(),
});

/** @extends {React.Component<Props>} */
export default class ImportSpreadsheetForm extends React.Component {
	static contextType = AuthContext;
	static defaultProps = {
		rules: yup.object().shape({}),
	};

	/** @returns {JSX.Element} */
	render() {
		return (
			<BaseForm
				initialValues={this.props.initialValues}
				noRequiredText={true}
				validationSchema={validationSchema.concat(this.props.rules)} // prettier-ignore
			>
				{this.props.children}

				<FormErrorMessages messages={this.props.errorMessages} />
				<FormSuccessMessages messages={this.props.successMessages} />
			</BaseForm>
		);
	}
}
