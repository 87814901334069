import { translate } from '../../../App/Internationalization';
import { withThemeService } from 'service-container';
import EditTheme from 'App/shared/EditTheme';
import React from 'react';

/** @typedef {import('App/Admin/Theme/EditDomainThemeProps').default} Props */

/** @extends {React.Component<Props>} */
export class EditDomainTheme extends React.Component {
	render() {
		return (
			<EditTheme
				getTheme={this.props.themeService.getDomainTheme.bind(
					this.props.themeService
				)}
				heading={translate('global.header.edit_domain_branding')}
				setFavicon={this.props.themeService.setDomainFavicon.bind(
					this.props.themeService
				)}
				setLogo={this.props.themeService.setDomainLogo.bind(
					this.props.themeService
				)}
				setTheme={this.props.themeService.setDomainTheme.bind(
					this.props.themeService
				)}
				userType="administrator"
			/>
		);
	}
}

export default withThemeService(EditDomainTheme);
