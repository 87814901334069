import { object } from '@mooveguru/js-utilities';
import React from 'react';
import roomConfig from '../../../../config/local/appliance';
import images from '../../../../config/local/images';
import paths from '../../../../config/local/paths';
import LinkButton from '../../../../shared/LinkButton';
import Loading from '../../../../shared/Loading';
import NoContent from '../../../../shared/NoContent';
import captureError from '../../../../utils/captureError';
import { translate } from '../../../Internationalization';
import ApplianceTable from '../ViewAppliances/ApplianceTable';
import ApplianceItem from './ApplianceItem';

/**
 * @typedef {import('../../../../shared/ApplianceService/Appliance').default} Appliance
 * @typedef {import('./ApplianceCardRoom').default} Room
 * @typedef {import('./AppliancesCardProps').default} Props
 * @typedef {import('./AppliancesCardState').default} State
 * @typedef {import('../ViewAppliances/Props').default} ViewAppliancesProps
 * @typedef {keyof typeof roomConfig['roomMapping']} RoomKeys
 */

/** @extends {React.Component<Props, State>}  */
export default class AppliancesCard extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		/**
		 * @type {State}
		 */
		this.state = {
			applianceList: [],
			filteredApplianceList: [],
			isGettingData: true,
			selectedRoomList: [],
		};
	}

	/**
	 * @param {string} applianceId
	 * @returns {void}
	 */
	handleDelete(applianceId) {
		this.setState((prevState) => {
			const filteredApplianceList =
				prevState.filteredApplianceList.filter(
					(appliance) => appliance.id !== applianceId
				);
			return { filteredApplianceList };
		}, this.setAppliances);
	}

	setAppliances() {
		this.setState((prevState) => {
			/**
			 * @type {{[Key in RoomKeys]:ViewAppliancesProps['applianceList']}}
			 */
			// @ts-ignore object.groupby does not return proper type
			const visibleRooms = object.groupBy(
				prevState.filteredApplianceList,
				'room'
			);

			const filteredApplianceList = prevState.selectedRoomList
				.sort()
				.map((room) => (
					<div key={room}>
						<h2 className="h-ms-3 mt-8 mb-4">
							{roomConfig.roomMapping[room]}
						</h2>
						{visibleRooms[room] ? (
							<ApplianceTable
								// @ts-ignore has the correct type
								applianceList={visibleRooms[room]}
								onDelete={
									/**
									 * @param {string} id
									 * @returns {void}
									 */
									(id) => this.handleDelete(id)
								}
							/>
						) : (
							<NoContent
								message={translate(
									'homeowner.pages.appliance_center.table.error'
								)}
							/>
						)}
					</div>
				));

			return {
				applianceList: filteredApplianceList,
				isGettingData: false,
			};
		});
	}

	componentDidMount() {
		/**
		 * @type {(RoomKeys)[]}
		 */
		// @ts-ignore Object.keys return type hinted as a string but has correct keys
		const selectedRoomList = Object.keys(roomConfig.roomMapping);

		const filteredApplianceList = this.props.applianceList.filter(
			/**
			 * @param {(Appliance & { id: string, lifespan: number })} appliance
			 * @returns {boolean}
			 */
			(appliance) => selectedRoomList.includes(appliance.room)
		);

		this.setState({ filteredApplianceList, selectedRoomList }, () =>
			this.setAppliances()
		);
	}

	/**
	 * @param {Props} prevProps
	 * @param {State} prevState
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.state.selectedRoomList !== prevState.selectedRoomList) {
			try {
				this.setAppliances();
			} catch (error) {
				captureError(error);
				this.setState({ isGettingData: false });
			}
		}
	}

	/**
	 * @param {RoomKeys} room
	 * @returns {void}
	 */
	handleRoomSelect(room) {
		let updatedRoomSelection = this.state.selectedRoomList;

		if (this.state.selectedRoomList.includes(room)) {
			updatedRoomSelection = updatedRoomSelection.filter(
				/**
				 * @param {string} selectedRoom
				 * @returns {boolean}
				 */
				(selectedRoom) => selectedRoom !== room
			);
			this.setState({ selectedRoomList: updatedRoomSelection });
			return;
		}

		this.setState({ selectedRoomList: [...updatedRoomSelection, room] });
	}

	render() {
		const buttonList = Object.entries(roomConfig.roomMapping).map(
			/**
			 * @param {[RoomKeys, string]} destructure
			 * @returns {JSX.Element}
			 */
			// @ts-ignore Object.entries return type hinted as a string but has correct keys
			([key, title]) => (
				<ApplianceItem
					disabled={false}
					id={key}
					isComplete={this.state.selectedRoomList.includes(key)}
					key={key}
					label={title}
					name={key}
					onChange={() => this.handleRoomSelect(key)}
				/>
			)
		);

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<React.Fragment>
				<section className="mt-5 w-fit ml-auto mr-0">
					<LinkButton
						icon={images.icons.plus}
						outline={true}
						to={paths.app.homeowner.applianceCenter.add}
					>
						{translate(
							'homeowner.pages.appliance_center.dashboard.appliances_card.add'
						)}
					</LinkButton>
				</section>

				<section className="input-group checkbox-group">
					<ul className="button-group my-5 w-fit">{buttonList}</ul>
				</section>

				<section className="mt-10">
					{this.state.applianceList.length > 1 ? (
						this.state.applianceList
					) : (
						<NoContent
							message={translate(
								'homeowner.pages.appliance_center.table.error'
							)}
						/>
					)}
				</section>
			</React.Fragment>
		);
	}
}
