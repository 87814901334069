import apiUrls from 'config/local/api-urls';
import captureError from 'utils/captureError';
import keywords from '@mooveguru/yhh-shared-config/activity/keywords.json';
import leadscore from '@mooveguru/yhh-shared-config/activity/leadscore.json';

/** @typedef {import ('shared/ActivityService/IdentifiesKeywords').default} IdentifiesKeywords */
/** @typedef {import ('shared/ActivityService/LogsActivity').default} LogsActivity */
/** @typedef {import ('@mooveguru/js-http-client/PostsHttp').default} HttpClient */
/** @typedef {import('shared/ActivityService/Dependencies').default} Dependencies */

const containsKeyword = new RegExp(
	keywords.map((word) => `\\b${word}\\b`).join('|'),
	'i'
);

/**
 *  @implements {IdentifiesKeywords}
 *  @implements {LogsActivity}
 */
export default class ActivityService {
	/** @param {Dependencies} dependencies */
	constructor(dependencies) {
		this.authService = dependencies.authService;
		this.baseHeaders = new Headers({ 'Content-Type': 'application/json' });
		this.httpClient = dependencies.httpClient;
	}

	/**
	 * @public
	 * @param {string} term
	 * @returns {boolean}
	 */
	identifiesKeywords(term) {
		return containsKeyword.test(term);
	}

	/**
	 * @public
	 * @param {string} context
	 * @param {keyof leadscore} activity
	 * @returns {Promise<void>}
	 */
	async logActivity(context, activity) {
		const headers = new Headers(this.baseHeaders);

		headers.set(
			'Authorization',
			`Bearer ${this.authService.getAccessToken()}`
		);

		try {
			await this.httpClient.post(
				apiUrls.homeowner.postActivityPoints,
				headers,
				this.createRequestBody(context, activity)
			);
		} catch (error) {
			captureError(error);
		}
	}

	/**
	 * @private
	 * @param {string} context
	 * @param {keyof leadscore} activity
	 */
	createRequestBody(context, activity) {
		return {
			category: leadscore[activity].category,
			action: leadscore[activity].action,
			label: context,
			url: window.location.href,
			points: leadscore[activity].value,
		};
	}
}
