import { translate } from '../../App/Internationalization';
import React from 'react';

export default class SkipToMainContent extends React.Component {
	/** @returns {JSX.Element} */
	/* eslint-disable-next-line class-methods-use-this -- `render()` must be callable by instantiated class */
	render() {
		return (
			<a
				className="absolute left-2 -top-32 p-4 bg-white z-50 font-bold text-black focus:top-2"
				href="#main-container"
			>
				{translate('global.skip_to_main_content')}
			</a>
		);
		/* eslint-enable-next-line class-methods-use-this */
	}
}
