import React from 'react';

import { withActivityService } from 'service-container';

/** @typedef {import('App/ClickListener/Props').default} Props */

/** @extends {React.Component<Props>} */
export class ClickListener extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	/** @param {React.MouseEvent<HTMLElement, MouseEvent>} event */
	handleClick(event) {
		if (event.target.hasAttribute('data-activity-click')) {
			this.handleActivityClick(event.target);
		}
	}

	// TODO: Fix Prettier Formatting
	/*eslint-disable */
	/** @param {EventTarget} element */
	handleActivityClick(element) {
		const activityType = element.getAttribute('data-activity-click');
		// TODO: Update eslint for switch statements
		/* eslint-disable */
		switch (activityType) {
			case 'agent_call':
				this.handleAgentCall();
				break;
			case 'agent_email':
				this.handleAgentEmail();
				break;
			case 'blog_article':
				this.handleBlogArticle(element);
				break;
			case 'marketplace_click':
				this.handleMarketplaceClick(element);
				break;
			default:
				throw new Error(
					`ClickListener received unknown activity type: ${activityType}.`
				);
		}
		/* eslint-enable */
	}
	/*eslint-enable */

	handleAgentCall() {
		this.props.activityService.logActivity(
			'agent_call',
			'agent_call_agent'
		);
	}

	handleAgentEmail() {
		this.props.activityService.logActivity(
			'agent_email',
			'agent_email_agent'
		);
	}

	/** @param {EventTarget} element */
	handleBlogArticle(element) {
		const context = element.getAttribute('data-activity-id');

		if (this.props.activityService.identifiesKeywords(context)) {
			this.props.activityService.logActivity(
				context,
				'blog_article_clicks_highpoint'
			);
		}
	}

	/** @param {EventTarget} element */
	handleMarketplaceClick(element) {
		const context = element.getAttribute('data-activity-id');

		if (this.props.activityService.identifiesKeywords(context)) {
			this.props.activityService.logActivity(
				context,
				'marketplace_clicks_highpoint'
			);
		}
	}

	render() {
		return <div onClick={this.handleClick}>{this.props.children}</div>;
	}
}

export default withActivityService(ClickListener);
