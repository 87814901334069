/**
 * @template FormValues
 * @typedef {import('formik').FormikProps<FormValues>} FormikProps
 */

/**
 * @typedef {{contactAddressIsPropertyAddress: string | boolean}} State
 * @typedef {import('./Props').default} Props
 * @typedef {import('./Values').default} Values
 */

import { translate } from '../../../App/Internationalization';
import { withTheme } from '../../../shared/ThemeProvider';
import * as validators from '../../../shared/validators';
import * as yup from 'yup';
import AgreesToTermsOfUseLabelContent from '../../../Registration/AgreesToTermsOfUseLabelContent';
import BaseForm from '../../../shared/Forms/BaseForm';
import FieldAddressDesignation from '../../../shared/Forms/Fields/Address/FieldAddressDesignation';
import FieldCheckbox from '../../../shared/Forms/Fields/FieldCheckbox';
import FieldCity from '../../../shared/Forms/Fields/Address/FieldCity';
import FieldCountry from '../../../shared/Forms/Fields/Address/FieldCountry';
import FieldEmail from '../../../shared/Forms/Fields/FieldEmail';
import FieldHomePhone from '../../../shared/Forms/Fields/PhoneNumber/FieldHomePhone';
import FieldMobilePhone from '../../../shared/Forms/Fields/PhoneNumber/FieldMobilePhone';
import FieldPassword from '../../../shared/Forms/Fields/FieldPassword';
import FieldPostalCode from '../../../shared/Forms/Fields/Address/FieldPostalCode';
import FieldState from '../../../shared/Forms/Fields/Address/FieldState';
import FieldStreetAddress from '../../../shared/Forms/Fields/Address/FieldPostalStreetAddress';
import NameFields from '../../../shared/Forms/Fields/Name/NameFields';
import React from 'react';
import SubmitButton from '../../../shared/Forms/Inputs/SubmitButton';

const validationSchema = yup.object().shape({
	agreesToTermsOfUse: validators.terms,
	city: validators.city,
	country: validators.country,
	emailAddress: validators.email,
	firstName: validators.firstName,
	homePhoneNumber: validators.phoneNumber,
	lastName: validators.lastName,
	mobilePhoneNumber: validators.phoneNumber,
	password: validators.password,
	passwordConfirmation: validators.matchConfirmation('password', 'Passwords'),
	phoneAtLeastOne: validators.atLeastOne(
		['homePhoneNumber', 'mobilePhoneNumber'],
		'You must enter at least one phone number.'
	),
	postalCode: validators.postalCode,
	state: validators.state,
	streetAddress1: validators.required('Street address'),
	streetAddress2: validators.streetAddress,
});

/** @extends {React.Component<Props, State>} */
export class HomeownerRegistrationForm extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			contactAddressIsPropertyAddress: this.props?.propertyId ?? false,
		};

		this.handleContactAddressChange =
			this.handleContactAddressChange.bind(this);
		this.handlePropertyAddressIsContactAddressChange =
			this.handlePropertyAddressIsContactAddressChange.bind(this);

		const searchString = new URLSearchParams(window.location.search);
		this.email = (searchString.get('email') || '')?.replace(/ /g, '+');
	}

	/**
	 * @param {React.ChangeEvent<HTMLInputElement|HTMLSelectElement>} event
	 * @param {FormikProps<Values>} formikProps
	 * @returns {void}
	 */
	handleContactAddressChange(event, formikProps) {
		if (this.state.contactAddressIsPropertyAddress) {
			const uppercaseFieldNameFirstCharacter =
				event.target.name[0].toUpperCase();
			const fieldNameRemainder = event.target.name.slice(1);
			const propertyAddressFieldName = `property${uppercaseFieldNameFirstCharacter}${fieldNameRemainder}`;

			formikProps.setValues((values) => ({
				...values,
				[propertyAddressFieldName]: event.target.value,
				[event.target.name]: event.target.value,
			}));
		} else {
			formikProps.handleChange(event);
		}
	}

	/**
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @param {FormikProps<Values>} formikProps
	 * @returns {void}
	 */
	handlePropertyAddressIsContactAddressChange(event, formikProps) {
		if (event.target.checked) {
			formikProps.setValues((values) => ({
				...values,
				propertyAddressIsContactAddress: true,
				propertyCity: values.city,
				propertyCountry: values.country,
				propertyPostalCode: values.postalCode,
				propertyState: values.state,
				propertyStreetAddress1: values.streetAddress1,
				propertyStreetAddress2: values.streetAddress2,
			}));

			this.setState({ contactAddressIsPropertyAddress: true });
		} else {
			formikProps.handleChange(event);
			this.setState({ contactAddressIsPropertyAddress: false });
		}
	}

	/** @returns {JSX.Element} */
	render() {
		return (
			<React.Fragment>
				<h1 className="hl-ms-6 mt-10 mb-2">
					{translate('registration.form.registration')}
				</h1>

				<section className="rich-text mb-6">
					<p>{translate('registration.form.homeowner_lead')}</p>
				</section>

				<BaseForm
					initialValues={this.props.initialValues}
					onSubmit={this.props.onSubmit}
					validationSchema={validationSchema}
				>
					<h2 className="h-ms-5 mb-2">
						{translate('registration.form.personal_details')}
					</h2>

					<section className="rich-text mb-6">
						<p>
							{translate(
								'registration.form.homeowner_personal_details_lead'
							)}
						</p>
					</section>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<NameFields required={true} />

						<FieldHomePhone />
						<FieldMobilePhone required={true} />
						<FieldStreetAddress
							disabled={this.props?.propertyId}
							onChange={this.handleContactAddressChange}
							required={true}
						/>
						<FieldAddressDesignation
							disabled={this.props?.propertyId}
							onChange={this.handleContactAddressChange}
						/>
						<FieldCity
							disabled={this.props?.propertyId}
							onChange={this.handleContactAddressChange}
							required={true}
						/>
						<FieldState
							disabled={this.props?.propertyId}
							onChange={this.handleContactAddressChange}
							required={true}
						/>
						<FieldPostalCode
							disabled={this.props?.propertyId}
							onChange={this.handleContactAddressChange}
							required={true}
						/>
						<FieldCountry
							disabled={this.props?.propertyId}
							onChange={this.handleContactAddressChange}
							required={true}
						/>
					</fieldset>

					<h2 className="h-ms-5 mb-2">
						{translate('registration.form.account_details')}
					</h2>
					<section className="rich-text mb-6">
						<p>
							{translate(
								'registration.form.account_details_lead'
							)}
						</p>
					</section>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<FieldEmail
							className="col-start-1"
							disabled={this.email}
							name="emailAddress"
							required={true}
						/>

						<FieldPassword
							className="col-start-1"
							help={translate('global.forms.help.password')}
						/>

						<FieldPassword
							className="col-start-1"
							label={translate(
								'registration.form.confirm_password'
							)}
							name="passwordConfirmation"
						/>
					</fieldset>
					<fieldset className="mb-8">
						<FieldCheckbox
							className="mb-4"
							label={<AgreesToTermsOfUseLabelContent />}
							name="agreesToTermsOfUse"
							required={true}
							value={this.props.initialValues.agreesToTermsOfUse !== true}
						/>
					</fieldset>

					<footer className="mx-auto sm:ml-0 button-group w-min">
						<SubmitButton className="mb-4">
							{translate('registration.form.submit')}
						</SubmitButton>
					</footer>
				</BaseForm>
			</React.Fragment>
		);
	}
}

export default withTheme(HomeownerRegistrationForm);
