import * as yup from 'yup';
import BaseForm from '../../../shared/Forms/BaseForm';
import categoryList from '@mooveguru/yhh-shared-config/document-categories.json';
import FieldFile from '../../../shared/Forms/Fields/FieldFile';
import FieldSelect from '../../../shared/Forms/Fields/FieldSelect';
import FieldTitle from '../../../shared/Forms/Fields/FieldTitle';
import LinkButton from '../../../shared/LinkButton';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import paths from '../../../config/local/paths';
import React from 'react';
import SelectOptionList from '../../../shared/Forms/Inputs/SelectOptionList';
import SubmitButton from '../../../shared/Forms/Inputs/SubmitButton';
import uploadSize from '@mooveguru/yhh-shared-config/files/max-upload.json';
import images from '../../../config/local/images';
import { translate } from '../../Internationalization';
import FormErrorMessages from '../../../shared/Forms/Messages/FormErrorMessages';

const validationSchema = yup.object().shape({
	title: yup.string().required(' is required.'),
});

export default class DocumentForm extends React.Component {
	/**
	 * @public
	 * @returns {JSX.Element}
	 */
	render() {
		const componentList = {
			form: (
				<BaseForm
					className="document-upload-form"
					initialValues={this.props.initialValues}
					onSubmit={this.props.handleSubmit}
					validationSchema={validationSchema}
				>
					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<FieldTitle
							label={translate(
								'homeowner.nav.documents.document_title'
							)}
							required={true}
						/>

						<FieldSelect
							label={translate(
								'homeowner.nav.documents.select_type'
							)}
							name="category"
						>
							<SelectOptionList values={categoryList} />
						</FieldSelect>
					</fieldset>

					<fieldset className="mb-8">
						<legend className="h-ms-1 mb-2">
							{translate('homeowner.nav.documents.create')}
						</legend>
						<FieldFile
							accept={Object.values(mimeList.documents)}
							fullWidth={true}
							maxSize={uploadSize.document}
							name="document"
							onChange={this.props.handleFileUploadChange}
							required={true}
						/>
					</fieldset>

					<footer className="mx-auto sm:ml-0 button-group w-auto">
						<SubmitButton icon={images.icons.upload}>
							{translate('homeowner.nav.documents.upload')}
						</SubmitButton>
						<LinkButton
							icon={images.icons.delete}
							size="large"
							to={paths.app.homeowner.documents.root}
						>
							{translate('agent.pages.contacts.form.cancel')}
						</LinkButton>
					</footer>
					<FormErrorMessages messages={[this.props.error]} />
				</BaseForm>
			),
			hero: (
				<figure className="float-right">
					<img
						alt={translate('homeowner.pages.checklists.alt')}
						className="illustration"
						src={images.homeowners.documents.header}
					/>
				</figure>
			),
			title: (
				<h1 className="hl-ms-4 my-4">
					{translate('homeowner.nav.documents.title')}
				</h1>
			),
		};

		return (
			<React.Fragment>
				{componentList.title}
				<section className="app-responsive-area app-responsive-sidebar-grid">
					<section className="responsive-main app-block-list">
						{componentList.form}
					</section>

					<section className="responsive-secondary app-block-list">
						{componentList.hero}
					</section>
				</section>
			</React.Fragment>
		);
	}
}
