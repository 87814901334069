import { Switch as BaseSwitch } from 'react-router-dom';
import NotFound from '../shared/NotFound';
import Page from '../shared/Page';
import React from 'react';

export default class Switch extends React.Component {
	// eslint-disable-next-line class-methods-use-this --  `componentDidMount()` must be callable by instantiated class
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	// eslint-disable-next-line class-methods-use-this --  `componentDidUpdate()` must be callable by instantiated class
	componentDidUpdate() {
		window.scrollTo(0, 0);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<BaseSwitch>
				{this.props.children}
				<Page component={NotFound} path="*" title="Not Found" />
			</BaseSwitch>
		);
	}
}
