/**
 * @typedef {import('./Props').PricingCardProps} Props
 */

import formatCentsForDisplay from '../../utils/formatCentsForDisplay';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class PricingCard extends React.Component {
	/**
	 * @returns {JSX.Element}
	 */
	#getPlanDetails() {
		if (!this.props?.price?.value) {
			return (
				<span className="capitalize hl-ms-9">
					{this.props?.price?.text}
				</span>
			);
		}
		return (
			<section className="flex items-end">
				<span className="capitalize hl-ms-9">
					{formatCentsForDisplay(this.props?.price?.value)}
				</span>
				<p className="capitalize mb-2">
					{'/ '}
					{this.props?.price?.text}
				</p>
			</section>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<section className="card-pricing m-4">
				<section className="m-4">
					<header>
						<div className="hl-ms-1 card-theme">
							{this.props.planTitle}
						</div>
						{this.#getPlanDetails()}
					</header>
					<hr className="my-2" />
					<section className="mb-4">{this.props.plan}</section>
					{this.props?.footer}
				</section>
			</section>
		);
	}
}
