/**
 * @typedef {import("./Props").default} Props
 */

import { translate } from '../../../../Internationalization';
import BaseButton from '../../../../../shared/BaseButton/BaseButton';
import BaseForm from '../../../../../shared/Forms/BaseForm';
import FieldRadio from '../../../../../shared/Forms/Fields/FieldRadio';
import FilterGroup from '../Types/FilterGroup';
import React from 'react';

/** @extends	{React.Component<Props>} */
export default class RegisteredFilter extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		this.state = { active: false };

		this._handleSubmit = this._handleSubmit.bind(this);
		this._handleReset = this._handleReset.bind(this);
	}

	/** @param {{registered: 'null' | '!null'}} value */
	_handleSubmit(value) {
		this.props.onSubmit({
			'filter[homeowner_id]': value.registered,
			relation: 'and',
		});

		this.setState({ active: true });
	}

	_handleReset() {
		const query = this.props.query;

		if (query['filter[homeowner_id]']) {
			delete query['filter[homeowner_id]'];

			this.props.onSubmit({
				...query,
			});
		}

		this.setState({ active: false });
	}

	/** @returns {JSX.Element} */
	render() {
		return (
			<FilterGroup
				active={this.state.active}
				locked={this.props.gated}
				title={translate('filters.title.registered')}
			>
				<BaseForm noRequiredText={true} onSubmit={this._handleSubmit}>
					<div className="flex flex-col gap-4">
						<FieldRadio
							id="registered_yes"
							label={translate('filters.radio.yes')}
							name="registered"
							required={false}
							value={'!null'}
						/>
						<FieldRadio
							id="registered_no"
							label={translate('filters.radio.no')}
							name="registered"
							required={false}
							value={'null'}
						/>

						<div className="flex gap-2">
							<BaseButton
								className="w-fit"
								iconSize="small"
								size="xsmall"
								type="submit"
							>
								{translate('filters.submit')}
							</BaseButton>
							<BaseButton
								className="w-fit"
								iconSize="small"
								onClick={this._handleReset}
								outline={true}
								size="xsmall"
								type="reset"
							>
								{translate('filters.reset')}
							</BaseButton>
						</div>
					</div>
				</BaseForm>
			</FilterGroup>
		);
	}
}
