import React from 'react';

import BlogIndex from 'Blog/BlogIndex';
import BlogPost from 'Blog/BlogPost';
import paths from 'config/local/paths';
import { withTheme } from 'shared/ThemeProvider';
import Page from 'shared/Page';
import Switch from 'shared/Switch';
import {
	withBlogService,
	withBlogServiceActivityProxy,
} from 'service-container';
import concatPaths from 'utils/contactPaths';

const ThemedBlogIndex = withTheme(BlogIndex);
const ThemedBlogPost = withTheme(BlogPost);
export default class Blog extends React.Component {
	constructor(props) {
		super(props);
		this.rootPath = this.props.match.url;

		this.BlogIndexComponent = this.props.match.url.startsWith(
			paths.app.root
		)
			? withBlogServiceActivityProxy(ThemedBlogIndex)
			: withBlogService(ThemedBlogIndex);
	}

	render() {
		const blogIndexTitle = this.props.theme?.agentName
			? `${this.props.theme.agentName}’s Resource Library`
			: 'Resource Library';

		return (
			<Switch>
				<Page
					component={this.BlogIndexComponent}
					exact
					path={this.rootPath}
					title={blogIndexTitle}
					withContainer={true}
				/>
				<Page
					component={ThemedBlogPost}
					path={concatPaths(this.rootPath, ':slug')}
					title={blogIndexTitle}
					withContainer={true}
				/>
			</Switch>
		);
	}
}
