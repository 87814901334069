/**
 * @typedef {import('./Roles').Roles} AdminRoles
 * @typedef {import('../../../../shared/AuthProvider/Roles').Roles} AllRoles
 */
import { Redirect } from 'react-router-dom';
import { translate } from 'App/Internationalization';
import { withAdministratorService } from 'service-container';
import AdminForm from 'App/Admin/Users/Admins/AdminForm';
import Loading from '../../../../shared/Loading';
import paths from 'config/local/paths';
import React from 'react';
import roleList from '@mooveguru/yhh-shared-config/users/roles.json';

const successMessage = translate('admin.pages.admins.edit.success_message');

/** @typedef {import('./Admin').default} Admin */
export class EditAdmin extends React.Component {
	/** @param {Admin} props */
	constructor(props) {
		super(props);

		this.state = {
			adminData: {},
			errorMessage: '',
			formComplete: false,
			isGettingData: true,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async componentDidMount() {
		const results = await this.props.administratorService.getAdministrator(
			this.props.match.params.administratorId
		);

		results.active = results.active ? 'active' : 'disabled';

		results.roles = EditAdmin.#getAdminRole(results.roles);

		let primaryGroupId = null;

		if (results.groups?.length) {
			const primaryGroup = results.groups.find(
				/**
				 * @param {{isPrimary: boolean}} group
				 * @returns {boolean}
				 */
				(group) => group.isPrimary
			);

			primaryGroupId = primaryGroup?.id;
		}

		this.setState({
			adminData: {
				isActive: results.active,
				primaryGroup: primaryGroupId,
				...results,
			},
			isGettingData: false,
		});
	}

	/**
	 * @param {AllRoles} usersRoles
	 * @returns {AdminRoles | null}
	 */
	static #getAdminRole(usersRoles) {
		for (const role of usersRoles) {
			if (roleList.admin.includes(role)) {
				// @ts-ignore this will be an admin role
				return role;
			}
		}

		return null;
	}

	/**
	 *  @param {Admin} values
	 * @returns {Promise<void>}
	 */
	async handleSubmit(values) {
		try {
			await this.props.administratorService.editAdmin(
				this.props.match.params.administratorId,
				values
			);
			this.setState({ errorMessage: null, formComplete: true });
		} catch (error) {
			// @ts-ignore TODO, have a proper type for all the API response errors
			this.setState({ errorMessage: error.message, formComplete: false });
		}
	}

	render() {
		if (this.state.formComplete) {
			return (
				<Redirect
					to={{
						pathname: paths.app.admin.admins.view,
						state: { message: successMessage },
					}}
				/>
			);
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}
		return (
			<AdminForm
				adminData={this.state.adminData}
				error={this.state.errorMessage}
				formSubmitText={translate(
					'admin.pages.admins.edit.form.submit'
				)}
				formTitle={translate('admin.pages.admins.edit.form.title')}
				hasStatus={true}
				onSubmit={this.handleSubmit}
				success={this.state.formComplete}
			/>
		);
	}
}

export default withAdministratorService(EditAdmin);
