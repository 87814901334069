/**  @typedef {import('./Props').default} Props */

import { translate } from '../../../../App/Internationalization';
import ActionMessage from '../../../ActionMessages/ActionMessage';
import React from 'react';

/** @extends React.Component<Props> */
export default class AgentBulkInvitePopup extends React.Component {
	/** @returns {JSX.Element} */
	render() {
		return (
			<ActionMessage
				action="warning"
				onClose={this.props.onClose}
				onSubmit={this.props.onSubmit}
				title={translate('agent.pages.contacts.popup.header')}
			>
				<section>
					{translate(
						'admin.pages.agents.invite.popup.bulk_invite',
						this.props.selectedAgents?.length.toString()
					)}
				</section>
			</ActionMessage>
		);
	}
}
