/**
 * @typedef {import('formik').FormikValues} FormikProps
 * @typedef {import('./GroupForm').Props} Props
 * @typedef {import('./GroupForm').State} State
 */

import { translate } from '../../Internationalization';
import * as validators from '../../../shared/validators';
import * as yup from 'yup';
import AddressFields from '../../../shared/Forms/Fields/Address/AddressFields';
import BaseField from '../../../shared/Forms/Fields/BaseField';
import BaseForm from '../../../shared/Forms/BaseForm';
import Feature from '../../../shared/Feature';
import FieldGroupSelect from '../../../shared/Forms/Fields/FieldGroupSelect';
import FieldPhoneNumber from '../../../shared/Forms/Fields/PhoneNumber/FieldPhoneNumber';
import FieldRadioGroup from '../../../shared/Forms/Fields/FieldRadioGroup';
import FieldSlug from '../../../shared/Forms/Fields/Slug/FieldSlug';
import FormMessages from '../../../shared/Forms/Messages/FormMessages';
import images from '../../../config/local/images';
import LinkButton from '../../../shared/LinkButton';
import paths from '../../../config/local/paths';
import React from 'react';
import SubmitButton from '../../../shared/Forms/Inputs/SubmitButton';
import types from '@mooveguru/yhh-shared-config/group-types.json';

/** @extends {React.Component<Props, State>} */
export default class GroupForm extends React.Component {
	static defaultProps = {
		initialValues: {
			city: '',
			cobranding: 'disabled',
			country: '',
			id: '',
			licenseNumber: '',
			name: '',
			parentId: '',
			phone: '',
			postalCode: '',
			slug: '',
			state: '',
			streetAddress1: '',
			streetAddress2: '',
			type: 'office',
		},
	};

	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/**
		 * @type {State}
		 */
		this.state = {
			showSubscriptionSubmit: false,
		};

		this.validationSchema = yup.object().shape({
			city: validators.required(translate('global.validator.city')),
			country: validators.required(translate('global.validator.county')),
			licenseNumber: yup.string().nullable(),
			name: validators.required(translate('global.validator.group_name')),
			phone: validators.phoneNumber.nullable(),
			postalCode: validators.postalCode,
			slug: this.props?.title
				? validators.slug.required()
				: validators.slug.notRequired(),
			state: validators.required(translate('global.validator.state')),
			streetAddress1: validators.required(
				translate('global.validator.street_address')
			),
			streetAddress2: yup.string().nullable(),
		});

		this._handleSubscriptionSubmit =
			this._handleSubscriptionSubmit.bind(this);
	}

	/** @returns {Promise<void>} */
	async componentDidMount() {
		if (this.props.initialValues?.cobranding !== 'enabled') {
			return;
		}

		if (this.props.isSubscribed) {
			return;
		}

		this.setState({ showSubscriptionSubmit: true });
	}

	/**
	 * @protected
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @param {FormikProps} formik
	 */
	_handleSubscriptionSubmit(event, formik) {
		formik.setFieldValue(event.target.name, event.target.value);

		if (event.target.value === 'disabled') {
			this.setState({
				showSubscriptionSubmit: false,
			});
			return;
		}

		if (!this.props.isSubscribed) {
			this.setState({
				showSubscriptionSubmit: true,
			});
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		/** @type {[ string, string ][]}*/
		const groupValues = [];
		const groupTranslationPath = 'admin.groups.form.group_descriptors';
		types.forEach((type) => {
			groupValues.push([
				type,
				translate(`${groupTranslationPath}.${type}`),
			]);
		});

		/** @type {[ string, string ][]}*/
		const cobrandingValues = [];
		const cobrandingList = ['disabled', 'enabled'];
		const cobrandingTranslationPath = 'admin.groups.form.cobranding_list';
		cobrandingList.forEach((status) => {
			cobrandingValues.push([
				status,
				translate(`${cobrandingTranslationPath}.${status}`),
			]);
		});

		return (
			<React.Fragment>
				<header className="flex items-center justify-between mb-6">
					<h2 className="h-ms-5 mb-6">{this.props.title}</h2>

					<LinkButton
						icon={images.icons.delete}
						to={paths.app.admin.groups.view}
					>
						{translate('global.forms.buttons.cancel')}
					</LinkButton>
				</header>

				<BaseForm
					initialValues={this.props.initialValues}
					onSubmit={this.props.onSubmit}
					validationSchema={this.validationSchema}
				>
					<FormMessages
						heading={translate('global.submit_error')}
						messages={[
							this.props.error ?? this.props.success ?? '',
						]}
					/>

					<Feature fallback={null} name="group:show_cobranding">
						<fieldset className="grid grid-cols-1 gap-6 mb-8">
							<legend className="h-ms-4 mb-6">
								{translate('admin.groups.form.settings_title')}
							</legend>

							<FieldRadioGroup
								name="type"
								title={translate(
									'admin.groups.form.type_title'
								)}
								values={groupValues}
							/>

							<FieldRadioGroup
								helpText={translate(
									'admin.groups.form.cobranding.help_text'
								)}
								name="cobranding"
								onChange={this._handleSubscriptionSubmit}
								title={translate(
									'admin.groups.form.cobranding.title'
								)}
								values={cobrandingValues}
							/>
						</fieldset>
					</Feature>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<legend className="h-ms-4 mb-6">
							{translate('admin.groups.form.group_title')}
						</legend>

						<BaseField
							label={translate('admin.groups.form.name.label')}
							name="name"
							placeholder={translate(
								'admin.groups.form.name.placeholder'
							)}
							required={true}
							type="text"
						/>

						<FieldGroupSelect
							// @ts-ignore issue with FieldGroupSelect
							exclude={[this.props.initialValues?.id]}
							initialGroup={this.props.initialValues?.parentGroup}
							label={translate(
								'admin.pages.agents.form.group_select.label'
							)}
							name="parentId"
							required={false}
						/>

						<BaseField
							className="col-span-full"
							label={translate('admin.groups.form.license.label')}
							name="licenseNumber"
							placeholder={translate(
								'admin.groups.form.license.placeholder'
							)}
							type="text"
						/>

						<FieldPhoneNumber name="phone" type="group" />
						{!!this.props.initialValues?.id && (
							<Feature
								fallback={null}
								name="admin:show_field_office_slug"
							>
								<FieldSlug name="slug" required={true} />
							</Feature>
						)}
					</fieldset>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<legend className="h-ms-4 mb-6">
							{translate(
								'global.forms.inputs.address.section_title'
							)}
						</legend>

						<AddressFields required={true} />
					</fieldset>

					<footer className="mx-auto sm:ml-0 button-group w-min">
						{!this.state.showSubscriptionSubmit ? (
							<SubmitButton>
								{translate('global.forms.buttons.save')}
							</SubmitButton>
						) : (
							<SubmitButton>
								{translate('admin.groups.form.checkout')}
							</SubmitButton>
						)}

						<LinkButton
							icon={images.icons.delete}
							size="large"
							to={paths.app.admin.groups.view}
						>
							{translate('global.forms.buttons.cancel')}
						</LinkButton>
					</footer>
				</BaseForm>
			</React.Fragment>
		);
	}
}
