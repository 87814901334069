/**
 * TODO: `create-react-app` does not allow ES6 private class member `#` syntax.
 * Once an override or workaround is implemented, add it to all class properties
 * and methods marked as `@private`
 */
/**
 * @typedef {import('chart.js').Chart} Chart
 * @typedef {import('chart.js').ChartOptions} ChartOptions
 * @typedef {import('chart.js').Plugin} Plugin
 * @typedef {import('./BaseDonutChartProps').default} Props
 */

import { chartColor } from '../utils/chartColor';
import { Doughnut } from 'react-chartjs-2';
import React from 'react';
/** @extends {React.Component<Props>} */
export default class BaseDonutChart extends React.Component {
	/** @returns {React.ReactElement} */
	render() {
		const plugins = [
			{
				beforeDraw: this.beforeDraw.bind(this),
				id: 'customText',
			},
		];
		const options = {
			cutout: '60%',
			maintainAspectRatio: false,
			plugins: {
				legend: {
					align: this.props.legendAlign ?? 'start',
					labels: {
						boxWidth: this.props.legendBoxWidth ?? 14,
						color: this.props.legendTextColor ?? '#000',
						font: {
							weight: 'bold',
						},
					},
					position: this.props.legendPosition ?? 'right',
				},
			},

			responsive: true,
		};
		return (
			<Doughnut
				aria-label={this.props.ariaLabel}
				data={this.props?.data}
				options={options}
				plugins={plugins}
				role="img"
			/>
		);
	}

	/**
	 * @private
	 * @param {Chart} chart
	 * @returns {void}
	 */
	beforeDraw(chart) {
		const height = chart.height;
		const ctx = chart.ctx;
		ctx.restore();
		const fontSize = (height / 114).toFixed(2);
		ctx.font = `bold ${fontSize}rem ${chartColor.font}`;

		/**
		 *	This will return, If `hideInnerText = true`
		 *		For hide inner text from graph
		 */
		if (this.props.hideInnerText) {
			return;
		}

		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.fillText(
			`${this.props.text}%`,
			chart.getDatasetMeta(0).data[0].x,
			chart.getDatasetMeta(0).data[0].y
		);
		ctx.save();
	}
}
