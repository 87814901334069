/** @typedef {import('./WelcomeCardProps').default} Props */
/** @typedef {import('./WelcomeCardState').default} State */

import { AuthContext } from '../../../shared/AuthProvider';
import { translate } from '../../Internationalization';
import { withUserService } from 'service-container';
import captureError from '../../../utils/captureError';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class WelcomeCard extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			userName: null,
		};
	}

	async componentDidMount() {
		try {
			const userName = await this.props.userService.getUserName(
				this.context.accessToken
			);
			this.setState({
				userName: userName ?? null,
			});
		} catch (error) {
			captureError(error);
		}
	}

	/** @returns {JSX.Element} */
	render() {
		return (
			<h1 className="hl-ms-4 my-3">
				{translate(
					'global.header.welcome.message',
					this.state.userName ??
						translate('global.header.welcome.guest')
				)}
			</h1>
		);
	}
}

WelcomeCard.contextType = AuthContext;

// @ts-ignore
export default withUserService(WelcomeCard);
