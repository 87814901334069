/**
 * @typedef {import('../../shared/AdministratorService/AdminPreview').default} Administrator
 * @typedef {import('./AdminPreviewProps').default} Props
 * @typedef {import('./AdminPreviewState').default} State
 */

import { translate } from '../../Internationalization';
import { withAdministratorService } from '../../../service-container';
import ActionButtons from '../../../shared/ActionButtons/ActionButtons';
import captureError from '../../../utils/captureError';
import paths from '../../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class AdminPreview extends React.Component {
	/**
	 * @param {Administrator} admin
	 * @returns {JSX.Element}
	 */
	static renderAdmin(admin) {
		return (
			<li className="list-item" key={admin.id}>
				<h3 className="hl-ms">{admin.name}</h3>
				<div>
					<span className="-h-ms-2">
						{translate('admin.pages.dashboard.admins.group')}
					</span>
					<span className="-text-ms-2 ml-6">{admin.groups}</span>
				</div>
			</li>
		);
	}

	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		/**
		 * @type {State}
		 */
		this.state = {
			adminList: [],
			errorMessage: '',
		};
	}

	async componentDidMount() {
		await this.getAdminList();
	}

	/**
	 * @returns {Promise<void>}
	 */
	async getAdminList() {
		try {
			const result = await this.props.administratorService.getAllAdmins(
				this.props.adminLimit,
				1
			);

			this.setState({
				adminList: result.administrators,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('admin.pages.dashboard.admins.error'),
			});
		}
	}

	render() {
		if (this.state.errorMessage) {
			return <div> {this.state.errorMessage} </div>;
		}

		return (
			<section className="mt-10">
				<section className="card card-border-light">
					<header className="card-header bg-theme-light text-theme-fg-light">
						<header className="h-ms-1">
							{translate('admin.pages.dashboard.admins.title')}
						</header>
					</header>
					<ul className="list-group card-list card-list-tight divided px-3">
						{this.state.adminList.map(
							/**
							 * @param {Administrator} admin
							 * @returns {JSX.Element}
							 */
							(admin) => AdminPreview.renderAdmin(admin)
						)}
					</ul>
				</section>
				<ActionButtons
					addUrl={paths.app.admin.admins.add}
					viewAllUrl={paths.app.admin.admins.view}
				/>
			</section>
		);
	}
}

export default withAdministratorService(AdminPreview);

AdminPreview.defaultProps = {
	adminLimit: 5,
};

/* eslint-disable react/sort-prop-types -- is sorted properly */
/* eslint-enable react/sort-prop-types */
