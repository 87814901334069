import { toAbsoluteUrl } from '../utils/toAbsoluteUrl';
import InlineSVG from 'react-inlinesvg';
import React from 'react';

/** @extends {React.Component<{ readonly path?: string, readonly className?: string, readonly containerClassName?: string }, {}>} */
export class BaseSVG extends React.Component {
	/**
	 * @returns {JSX.Element | null}
	 */
	render() {
		if (!this.props.path) {
			return null;
		}

		return (
			<InlineSVG
				className={this.props.className ?? ''}
				src={toAbsoluteUrl(this.props.path)}
			/>
		);
	}
}
