import Brokermint from './integration-images/Brokermint-Logo.jpg';
import DocuSign from './integration-images/DocuSign-Logo.jpg';
import DotLoop from './integration-images/DotLoop-Logo.jpg';
import Moxiworks from './integration-images/Moxiworks-Logo.jpg';
import Property from './integration-images/PropertybaseGO-Logo.jpg';
import React from 'react';
import Realogy from './integration-images/Realogy-Logo.jpg';
import Tribus from './integration-images/TRIBUS-Logo.jpg';

const imageArray = [
	Brokermint,
	DocuSign,
	DotLoop,
	Moxiworks,
	Property,
	Realogy,
	Tribus,
];

const titleArray = [
	'Brokermint',
	'DocuSign',
	'DotLoop',
	'Moxiworks',
	'PropertybaseGo',
	'Realogy',
	'TRIBUS',
];

const activeStatus = {
	Brokermint: false,
	DocuSign: false,
	DotLoop: false,
	Moxiworks: false,
	Property: false,
	Realogy: false,
	Tribus: false,
};

export default class ViewIntegrations extends React.Component {
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(title) {
		activeStatus[title] = !activeStatus[title];
		this.forceUpdate();
	}

	render() {
		const imagesMapped = imageArray.map((image, index) => (
			<div className="all-items" key={index}>
				<div className="active-status">
					{!activeStatus[titleArray[index]] ? (
						<div>Inactive</div>
					) : (
						<div>Active</div>
					)}
				</div>
				<div className="top-row">
					<h3 className="title-class">{titleArray[index]}</h3>

					<label className="switch">
						<input
							type="checkbox"
							onClick={() => this.handleClick(titleArray[index])}
						/>
						<span className="slider round"></span>
					</label>
				</div>

				<img className="image" src={image} />
			</div>
		));

		return (
			<section className="integrations-layout">{imagesMapped}</section>
		);
	}
}
