import React from 'react';

import Map from 'shared/Map';

export default class PropertyMap extends React.Component {
	render() {
		const address = Object.values(this.props.address).join(' ');

		return <Map address={address} className={this.props.className} />;
	}
}
