/**
 * @typedef {import('../../../shared/BaseMultiLineChartProps').ChartDatum} ChartDatum
 * @typedef {import('../../shared/PropertyService/Valuation').default} Valuation
 * @typedef {import('../../shared/PropertyService/Valuations').ValuationKeys} ValuationKeys
 * @typedef {import('./PropertyValuationGraphComponent').Props} Props
 * @typedef {import('./PropertyValuationGraphComponent').State} State
 * @typedef {import('@mooveguru/js-utilities/types/KeyedObject').default} KeyedObject
 */
import { array, string } from '@mooveguru/js-utilities';
import { chartColor } from '../../../utils/chartColor';
import { translate } from '../../Internationalization';
import BaseMultiLineChart from '../../../shared/BaseMultiLineChart';
import formatCentsForDisplay from '../../../utils/formatCentsForDisplay';
import formatDateForDisplay from '../../../utils/formatDateForDisplay';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export default class PropertyValuationGraph extends React.Component {
	static defaultProps = {
		info: {
			title: translate('homeowner.pages.dashboard.valuation.top_from'),
		},
		title: translate(
			'homeowner.pages.finances.view.estimate.dashboard_title'
		),
	};

	/**
	 * @param {ValuationKeys} set
	 * @returns {string}
	 */
	static #getColor(set) {
		const colorList = chartColor.colors;
		const keyList = chartColor.avms;
		const key = keyList[set] ?? keyList.default;

		// @ts-ignore has fallback
		return colorList[key] ?? '#000';
	}

	/**
	 * @param {string[]} list
	 * @returns {string}
	 */
	static #formatList(list) {
		const formatted = list;
		const and = ` ${translate(
			'homeowner.pages.finances.view.estimate.and'
		)} `;
		formatted.sort((a, b) => (a === b ? 0 : a.localeCompare(b)));
		if (formatted.length <= 2) {
			return formatted.join(and);
		}

		const last = formatted.pop();

		formatted.push(`${and} ${last}`);

		return list.join(', ');
	}

	/**
	 * @returns {{ readonly source: string, readonly value: string }}
	 */
	#getMeanValuation() {
		/** @type {Set<ValuationKeys>} */
		const providerList = new Set();
		/** @type {number[]} */
		const meanList = [];

		this.props.valuations.forEach(
			/**
			 * @param {Valuation[]} valuationList
			 * @param {ValuationKeys} source
			 * @returns {void}
			 */
			(valuationList, source) => {
				providerList.add(source);
				meanList.push(array.last(valuationList).valueMean);
			}
		);

		const sum = meanList.reduce(
			/**
			 * @param {number} value
			 * @param {number} total
			 * @returns {number}
			 */
			(value, total) => value + total
		);

		const mean = sum / meanList.length;

		return {
			source: PropertyValuationGraph.#formatList([...providerList]),
			value: formatCentsForDisplay(mean, 0),
		};
	}

	/**
	 * @param {KeyedObject} map
	 * @returns {string}
	 */
	#defaultLabel(map) {
		const firstKey = array.first(Object.keys(map));
		const labelList = this.#mapLabels();
		const label = array.last(labelList);

		if (firstKey === label) {
			return array.first(labelList);
		}

		return label;
	}

	/**
	 * @param {ValuationKeys} set
	 * @param {Valuation[]} valuationList
	 * @returns {ChartDatum}
	 */
	#mapDataSet(set, valuationList) {
		/** @type {KeyedObject} */
		const valueMap = {};

		valuationList.reverse().forEach((valuation) => {
			valueMap[formatDateForDisplay(valuation.date)] =
				valuation.valueMean / 100;
		});

		if (valuationList.length <= 1) {
			valueMap[this.#defaultLabel(valueMap)] =
				(valuationList.find(Boolean)?.valueMean ?? 0) / 100;
		}

		return {
			borderColor: PropertyValuationGraph.#getColor(set),
			borderWidth: 3,
			// @ts-ignore object.column return type is incorrect
			data: valueMap,
			label: string.convertCamelCaseToTitleCase(set),
			lineTension: 0,
			tension: 0.1,
		};
	}

	/**
	 * @returns {ChartDatum[]}
	 */
	#mapData() {
		/** @type {ChartDatum[]} */
		const data = [];

		this.props.valuations.forEach((valuationList, key) => {
			data.push(this.#mapDataSet(key, valuationList));
		});

		return data;
	}

	/** @returns {string[]} */
	#mapLabels() {
		const list = [...this.props.valuations.values()]
			.flat()
			.sort((a, b) => a.date.valueOf() - b.date.valueOf())
			.map((valuation) => formatDateForDisplay(valuation.date));

		return array.unique(list);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const chartData = {
			datasets: this.#mapData(),
			labels: this.#mapLabels(),
		};

		const highlight = this.#getMeanValuation();
		const title = translate(
			`homeowner.pages.dashboard.valuation.${
				this.props.valuations?.size > 1 ? 'avg_from' : 'top_from'
			}`
		);

		return (
			<section className="card card-border">
				<header className="card-header">
					<h3 className="card-title">{this.props.title}</h3>
					<section className="justified-row mt-4">
						<div className="price h-ms-4">
							{this.props.highlight ?? highlight.value}
						</div>
						<div>
							<div className="-text-ms-3 text-right font-bold uppercase">
								{title}
							</div>
							<div className="-h-ms-1 text-right uppercase">
								{this.props.info.value ?? highlight.source}
							</div>
						</div>{' '}
					</section>
				</header>

				<section className="card-body h-full ">
					<div className="w-full h-full relative">
						<BaseMultiLineChart
							isLegend={true}
							values={chartData}
						/>
					</div>
				</section>
			</section>
		);
	}
}
