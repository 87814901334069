/**
 * @typedef {import("./ChecklistItemProps").default} Props
 * @typedef {import("./ChecklistItemState").default} State
 */

import { array } from '@mooveguru/js-utilities';
import { NavLink } from 'react-router-dom';
import { translate } from '../../../App/Internationalization';
import formatDateForDisplay from '../../../utils/formatDateForDisplay';
import movingTaskTypes from '@mooveguru/yhh-shared-config/moving-task-types.json';
import onboardingTasks from '@mooveguru/yhh-shared-config/onboarding-task-types.json';
import paths from '../../../config/local/paths';
import React from 'react';
import story from '../../../config/local/success-states';

/** @extends {React.Component<Props, State>} */
export default class ChecklistItem extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			successState: ChecklistItem.#getSuccessStory(),
		};
	}

	/**
	 * @returns {string}
	 */
	static #getSuccessStory() {
		const key = array.random(story.successStories);
		return translate(`homeowner.pages.checklists.success_story.${key}`);
	}

	/**
	 * @param {string} taskType
	 * @param {number} step
	 * @returns {{key: string, link: string | {}, title: string}}
	 */
	static #getCtaButton(taskType, step) {
		const isOnboarding = taskType === 'onboarding';
		const tasks = {
			moving: movingTaskTypes,
			onboarding: onboardingTasks,
		};

		try {
			/**
			 * @type {{id: number, name: {en: string}, search_term: string, help: {title: string, link: string}, step: number}}
			 */
			// @ts-ignore -- static list JSON called dynamically, So it can be ignored.
			const checklistItem = array.wrap(tasks[taskType]).find(
				/**
				 * @param {{step: number}} type
				 * @returns {true | false}
				 */
				// @ts-ignore -- type declared.
				(type) => type.step === step
			);

			const ctaButton = isOnboarding
				? {
						key: checklistItem?.help.title,
						link: checklistItem?.help.link,
						title: checklistItem?.help.title,
				  }
				: {
						key: checklistItem?.search_term,
						link: {
							pathname: paths.app.homeowner.marketplace,
							state: {
								category: checklistItem?.search_term ?? '',
							},
						},
						title: translate(
							'homeowner.pages.checklists.marketplace'
						),
				  };
			return ctaButton;
		} catch {
			return {
				key: '',
				link: '',
				title: '',
			};
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	#getSuccessComponent() {
		const { key, link, title } = ChecklistItem.#getCtaButton(
			this.props.taskType,
			this.props.step
		);

		return this.props.isComplete ? (
			<h5 className="status">{this.state.successState}</h5>
		) : (
			<NavLink
				className="button button-xs uppercase"
				key={`service-link-${key}`}
				to={link}
			>
				{title}
			</NavLink>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		// This will return formatted date that passed from parent, If not passed then it will return current date.
		const dateCreated = formatDateForDisplay(this.props.date || new Date());

		return (
			<li className="task">
				<label className="form-check form-check-rounded form-check-lg">
					<input
						checked={this.props.isComplete}
						className="form-check-input"
						disabled={this.props.disabled}
						id={this.props.name}
						name={this.props.name}
						onChange={this.props.onChange}
						type="checkbox"
						value={this.props.value}
					/>
					<div className="task-details">
						<section className="main-info">
							<h4 className="title-light">{this.props.label}</h4>
							{this.#getSuccessComponent()}
						</section>
						{this.props.date ? (
							<aside className="secondary-info">
								{dateCreated}
							</aside>
						) : null}
					</div>
				</label>
			</li>
		);
	}
}
