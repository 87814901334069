/** @typedef {import('./Props').default} Props */

import { formatNumber } from '../../../Properties/PropertyDetailsCard/propertyDetailsMappers';
import { object } from '@mooveguru/js-utilities';
import { translate } from 'App/Internationalization';
import React from 'react';

/** @extends {React.Component<Props>} */
class PropertyInfo extends React.Component {
	/**
	 * @private
	 * @type {string}
	 */
	translatePath = 'homeowner.pages.dashboard.properties';

	/**
	 * @private
	 * @type {string}
	 */
	cardInfo = `${this.translatePath}.property_card_info`;

	/**
	 * @private
	 * @type {{[key : string]: string}[]}
	 */
	headers = [
		{ title: translate(`${this.cardInfo}.beds`), key: 'rooms.bedrooms' }, // prettier-ignore
		{ title: translate(`${this.cardInfo}.full_baths`), key: 'rooms.bathrooms' }, // prettier-ignore
		{ title: translate(`${this.cardInfo}.half_baths`), key: 'rooms.halfBathrooms' }, // prettier-ignore
		{ title: translate(`${this.cardInfo}.square_feet`), key: 'size.land.squareFootage' }, // prettier-ignore
		{ title: translate(`${this.cardInfo}.lot_number`), key: 'legal.legalLotNumber' }, // prettier-ignore
		{ title: translate(`${this.cardInfo}.lot_size`), key: 'size.land.acreage' }, // prettier-ignore
	];

	/**
	 * @private
	 * @param {string} key
	 * @returns {string}
	 */
	getCardValue(key) {
		const unknown = '—';

		if (!this.props.propertyDetails) {
			return unknown;
		}

		const value = formatNumber(
			object.get(this.props.propertyDetails, key, unknown)
		);

		switch (key) {
			case 'size.land.squareFootage':
				return value === unknown ? `${value}` : `${value} ft`;

			case 'size.land.acreage':
				return value === unknown ? `${value}` : `${value} acres`;

			// no default
		}

		return `${value}`;
	}

	render() {
		return (
			<dl className="card-body property-details">
				{this.headers.map((value) => (
					<div className="property-detail-item" key={value.key}>
						<dt className="property-detail-item-header">
							{value.title}
						</dt>

						<dd className="property-detail-item-footer">
							{this.getCardValue(value.key)}
						</dd>
					</div>
				))}
			</dl>
		);
	}
}

export default PropertyInfo;
