import React from 'react';
import { Redirect } from 'react-router-dom';

import EditHomeowners from 'App/Admin/Users/Homeowners/EditHomeowner';
import ViewHomeowners from 'App/Admin/Users/Homeowners/ViewHomeowners';
import paths from 'config/local/paths';
import Page from 'shared/Page';
import Switch from 'shared/Switch';
import { translate } from 'App/Internationalization';
import {
	withAdministratorService,
	withHomeownerService,
} from 'service-container';

const InjectedViewHomeowners = withHomeownerService(ViewHomeowners);
const InjectedEditHomeowner = withAdministratorService(
	withHomeownerService(EditHomeowners)
);

export default class Homeowners extends React.Component {
	render() {
		return (
			<Switch>
				<Redirect
					exact
					from={paths.app.admin.homeowners.root}
					to={paths.app.admin.homeowners.view}
				/>
				<Page
					component={InjectedViewHomeowners}
					path={paths.app.admin.homeowners.view}
					title={translate('admin.nav.homeowners.view')}
				/>
				<Page
					component={InjectedEditHomeowner}
					path={`${paths.app.admin.homeowners.edit}/:homeownerId`}
					title={translate('admin.nav.homeowners.edit')}
				/>
			</Switch>
		);
	}
}
