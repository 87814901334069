/**
 * @typedef {import('./SearchComponent').Props} Props
 * @typedef {import('../Component').SearchQuery} SearchQuery
 */
import React from 'react';
import SearchBox from '../../../../../shared/Forms/Fields/SearchBox';

/**
 * @extends {React.Component<Props>}
 */
export default class Search extends React.Component {
	/**
	 * @param {{ search?: string }} values
	 * @returns {SearchQuery}
	 */
	#handleSearch(values) {
		if (values.search === undefined) {
			return {};
		}

		if (this.props.fields === undefined) {
			return { search: values.search };
		}

		const filterList = {};

		this.props.fields.forEach((field) => {
			// @ts-ignore -- Checked above
			filterList[`search[${field}]`] = values.search;
		});

		return this.props.onSearch
			? this.props.onSearch(filterList)
			: filterList;
	}

	/**
	 * @returns {React.ReactNode}
	 */
	// eslint-disable-next-line class-methods-use-this
	render() {
		return (
			<SearchBox className="w-full sm:order-2 sm:w-fit" name="search" />
		);
	}
}
