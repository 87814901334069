/**
 * @typedef {import('../Component').FilterValue} Value
 * @typedef {import('../Component').SelectValue[]} InputValue
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */

import { object } from '@mooveguru/js-utilities';
import { translate } from '../../../../Internationalization';
import BaseButton from '../../../../../shared/BaseButton/BaseButton';
import BaseForm from '../../../../../shared/Forms/BaseForm';
import FieldSearchableSelect from '../../../../../shared/Forms/Fields/FieldSearchableSelect';
import FilterGroup from '../Types/FilterGroup';
import React from 'react';

/** @extends	{React.Component<Props>} */
export default class AgentsFilter extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = { active: false, values: [] };

		this._handleChange = this._handleChange.bind(this);
		this._handleReset = this._handleReset.bind(this);
		this._handleSubmit = this._handleSubmit.bind(this);
	}

	/**
	 * @param {{ [key: string]: InputValue }[]} input
	 * @param {import('formik').FieldProps<InputValue[]>} props
	 * @returns {void}
	 */
	_handleChange(input, props) {
		const values = object.column(input, 'value').filter(Boolean);

		if (!values?.length) {
			this._handleReset();
			return;
		}

		props.form.setFieldValue(props.field.name, values);

		this.setState({ values });
	}

	/** @returns {void} */
	_handleSubmit() {
		if (this.state?.values?.length === 0) {
			this.setState({ active: false });
			return;
		}

		this.props.onSubmit({
			'filter[select-agents]': this.state?.values,
			relation: 'and',
		});

		this.setState({ active: true });
	}

	/** @returns {void} */
	_handleReset() {
		const query = this.props?.query;

		if (query['filter[agent_id]']) {
			delete query['filter[agent_id]'];

			this.props?.onSubmit({
				...query,
			});
		}

		this.setState({ active: false, values: [] });
	}

	/**
	 * @template {string} Key
	 * @param {{ [key in Key]: Value }} list
	 * @returns {{ label: Key, value: Value }[]}
	 */
	static #mapHashToOptions(list) {
		return Object.entries(list)?.map(([id, name]) =>
			AgentsFilter.#mapOption(id, name)
		);
	}

	/**
	 * @template {string} Key
	 * @template {Value} InputValue
	 * @param {InputValue} value
	 * @param {Key} label
	 * @returns {{ label: Key, value: InputValue }}
	 */
	static #mapOption(value, label) {
		return { label, value };
	}

	/** @returns {JSX.Element | null} */
	render() {
		if (!Object.entries(this.props?.list)?.length) {
			return null;
		}

		return (
			<FilterGroup
				active={this.state?.active}
				locked={this.props?.gated}
				title={translate('filters.title.agents')}
			>
				<BaseForm noRequiredText={true} onSubmit={this._handleSubmit}>
					<div className="flex flex-col gap-4">
						<FieldSearchableSelect
							cacheOptions={true}
							className="my-2"
							id="agents_filter"
							isClearable={true}
							isMultipleSelect={true}
							label={translate('filters.title.agents')}
							name="agents_filter"
							onChange={this._handleChange}
							options={AgentsFilter.#mapHashToOptions(
								this.props.list
							)}
							required={false}
						/>

						<BaseButton
							className="w-fit"
							iconSize="small"
							size="xsmall"
							type="submit"
						>
							{translate('filters.submit')}
						</BaseButton>
					</div>
				</BaseForm>
			</FilterGroup>
		);
	}
}
