import { Redirect } from 'react-router-dom';
import { translate } from 'App/Internationalization';
import captureError from 'utils/captureError';
import FormErrorMessages from '../shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from '../shared/Forms/Messages/FormSuccessMessages';
import LinkButton from 'shared/LinkButton';
import Loading from 'shared/Loading';
import paths from 'config/local/paths';
import React from 'react';

export default class DeclineTransferProperty extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			messages: { error: null, success: null },
		};
	}

	async componentDidMount() {
		try {
			await this.props.propertyService.declineTransferProperty(
				this.props.match.params.propertyId
			);
			this.setState({
				loading: true,
				messages: {
					success:
						'Your request has been submitted and the property transfer has been declined.',
				},
			});
		} catch (error) {
			captureError(error);
			this.setState({
				loading: true,
				messages: { error: translate('global.error') },
			});
		}
	}

	render() {
		if (!this.props.match.params.propertyId) {
			return <Redirect to={paths.auth.signInSlug} />;
		}

		if (!this.state.loading) {
			return <Loading />;
		}

		return (
			<section className="sm:w-fit mx-auto">
				<h1 className="hl-ms-6 mt-10 mb-6">
					{'Decline Property Transfer'}
				</h1>

				<FormErrorMessages messages={[this.state.messages.error]} />
				<FormSuccessMessages messages={[this.state.messages.success]} />

				<LinkButton to={paths.root}>{'Need to Sign In?'}</LinkButton>
			</section>
		);
	}
}
