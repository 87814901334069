import React from 'react';
import mergeClassNames from 'utils/mergeClassNames';

export default class Map extends React.Component {
	render() {
		const params = {
			key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
			q: this.props.address,
			zoom: 10,
		};

		const mapSearchParams = new URLSearchParams(params); // also handles address query in params

		const classNames = mergeClassNames(
			'map-embed aspect-square w-full rounded',
			this.props.className
		);

		return (
			<iframe
				className={classNames}
				frameBorder="0"
				loading="lazy"
				allowFullScreen
				src={`https://www.google.com/maps/embed/v1/place?${mapSearchParams.toString()}`}
			></iframe>
		);
	}
}
