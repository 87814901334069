import { translate } from 'App/Internationalization';
import { withAgentService, withThemeService } from 'service-container';
import EditTheme from 'App/shared/EditTheme';
import Loading from 'shared/Loading';
import React from 'react';

/** @typedef {import('App/Admin/Agents/EditAgentTheme/Props').default} Props */
/** @typedef {import('App/Admin/Agents/EditAgentTheme/State').default} State */
/** @typedef {import('shared/ThemeService/Theme').default} Theme */
/** @typedef {import('shared/ThemeService/ThemeColors').default} ThemeColors */

/** @extends {React.Component<Props, State>} */
export class EditAgentTheme extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			isLoading: true,
			agentName: null,
			agentNotFound: false,
		};

		this.getTheme = this.getTheme.bind(this);
		this.setFavicon = this.setFavicon.bind(this);
		this.setLogo = this.setLogo.bind(this);
		this.setTheme = this.setTheme.bind(this);
	}

	async componentDidMount() {
		await this.getAndSetAgentName(this.props.match.params.agentId);
	}

	/** @param {string} agentId */
	async getAndSetAgentName(agentId) {
		try {
			const agent = await this.props.agentService.getAgent(agentId);

			this.setState({
				isLoading: false,
				agentName: agent?.name.full ?? '',
				agentNotFound: agent === null,
			});
		} catch (exception) {
			this.setState({
				isLoading: false,
			});
		}
	}

	/** @returns {Promise<Theme>} */
	getTheme() {
		return this.props.themeService.getAdminAgentTheme(
			this.props.match.params.agentId
		);
	}

	/**
	 * @param {File} favicon
	 * @returns {Promise<void>}
	 */
	setFavicon(favicon) {
		return this.props.themeService.setAdminAgentFavicon(
			this.props.match.params.agentId,
			favicon
		);
	}

	/**
	 * @param {File} logo
	 * @returns {Promise<void>}
	 */
	setLogo(logo) {
		return this.props.themeService.setAdminAgentLogo(
			this.props.match.params.agentId,
			logo
		);
	}

	/**
	 * @param {ThemeColors} colors
	 * @returns {Promise<void>}
	 */
	setTheme(colors) {
		return this.props.themeService.setAdminAgentTheme(
			this.props.match.params.agentId,
			colors
		);
	}

	render() {
		if (this.state.isLoading) {
			return <Loading />;
		}

		if (this.state.agentNotFound) {
			return (
				<p>{translate('admin.pages.import_agents.agent_not_found')}</p>
			);
		}

		return (
			<EditTheme
				getTheme={this.getTheme}
				heading={translate(
					'global.header.edit_branding',
					this.state.agentName
				)}
				setFavicon={this.setFavicon}
				setLogo={this.setLogo}
				setTheme={this.setTheme}
				userType="administrator"
			/>
		);
	}
}

export default withAgentService(withThemeService(EditAgentTheme));
