import { translate } from 'App/Internationalization';
import BaseInput from 'shared/Forms/Inputs/BaseInput';
import React from 'react';

export default class InputFirstName extends React.Component {
	render() {
		const placeholder = translate(
			'global.forms.inputs.name.first.placeholder'
		);

		return (
			<BaseInput
				autocomplete="given-name"
				className={this.props.className}
				disabled={this.props.disabled}
				name={this.props.name}
				placeholder={placeholder}
				required={this.props.required}
				type="text"
			/>
		);
	}
}

InputFirstName.defaultProps = {
	className: '',
	disabled: false,
	name: 'firstName',
	required: false,
};
