/** @typedef {import('../App/shared/HomeownerService/Address').default} Address */

/**
 * @param {Address} address
 * @returns {string}
 */
export default function formatAddressToString(address) {
	const street = [address.streetAddress1, address.streetAddress2]
		.filter(Boolean)
		.join(', ');
	const locale = [address.city, address.state].filter(Boolean).join(', ');
	const location = `${locale} ${address.postalCode ?? ''}`;

	return `${street}, ${location}`.trim();
}
