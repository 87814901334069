/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */
import { translate } from 'App/Internationalization';
import ExternalAnchorButton from '../../../../shared/ExternalAnchorButton';
import images from '../../../../config/local/images';
import React from 'react';
import SubscriptionDetails from './SubscriptionDetails';

/** @extends {React.Component<Props, State>} */
export class Checkout extends React.Component {
	/**
	 * @returns {JSX.Element}
	 */
	#renderButton() {
		const buttonText = translate('subscription.cta.button');

		return (
			<ExternalAnchorButton
				className="button button-outline button-icon"
				disabled={!this.props.paymentLink}
				href={this.props.paymentLink}
				icon={images.icons.subscription}
				style={
					this.props.paymentLink
						? { pointerEvents: 'none' }
						: undefined
				}
				target="_self"
			>
				{buttonText}
			</ExternalAnchorButton>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<SubscriptionDetails pricing={this.props.pricing}>
				<p>{this.#renderButton()}</p>
			</SubscriptionDetails>
		);
	}
}

export default Checkout;
