import { AuthContext } from '../../shared/AuthProvider';
import { Redirect } from 'react-router-dom';
import { translate } from '../Internationalization';
import { withTheme } from '../../shared/ThemeProvider';
import { withUserService } from '../../service-container';
import Admins from './Users/Admins';
import Agents from './Users/Agents';
import AppLayout from '../../App/AppLayout';
import Contacts from './Users/Contacts';
import Dashboard from './Dashboard';
import Features from './Features';
import Homeowners from './Users/Homeowners';
import LoadingScreen from '../../shared/LoadingScreen';
import Offers from './Offers';
import Groups from './Groups';
import Page from '../../shared/Page';
import paths from '../../config/local/paths';
import React from 'react';
import RoleGate from '../shared/RoleGate';
import roles from '@mooveguru/yhh-shared-config/users/roles.json';
import Switch from '../../shared/Switch';
import Theme from './Theme';

/** @typedef {{}} Props */
/** @typedef {{readonly name: string|null}} State */

const ThemedDashboard = withTheme(Dashboard);

/** @extends {React.Component<Props, State>} */
export class Admin extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			errorMessage: null,
			isGettingData: true,
			name: null,
		};
	}

	async componentDidMount() {
		this.context.setModule('admin');

		try {
			await Promise.all([
				this.getAndSetUserName(),
				this.props.theme.getAndSetActiveTheme('administrator'),
			]);
		} catch (error) {
			this.setState({ errorMessage: error.message });
		}
	}

	async getAndSetUserName() {
		const response = await this.props.userService.getUserName(
			this.context.accessToken
		);

		this.setState({
			isGettingData: false,
			name: response,
		});
	}

	// eslint-disable-next-line max-lines-per-function
	render() {
		if (this.state.errorMessage !== null) {
			return <p>{this.state.errorMessage}</p>;
		}

		if (this.state.isGettingData) {
			return <LoadingScreen />;
		}

		return (
			<AppLayout className="sm" userName={this.state.name}>
				<Switch>
					<Redirect
						exact={true}
						from={paths.app.admin.root}
						to={paths.app.admin.dashboard}
					/>
					<Redirect
						exact={true}
						from={paths.app.admin.legacy.offices.add}
						to={paths.app.admin.groups.add}
					/>
					<Redirect
						exact={true}
						from={`${paths.app.admin.legacy.offices.edit}/:groupId`}
						to={`${paths.app.admin.groups.edit}/:groupId`}
					/>
					<Redirect
						exact={true}
						from={paths.app.admin.legacy.offices.root}
						to={paths.app.admin.groups.root}
					/>
					<Redirect
						exact={true}
						from={paths.app.admin.legacy.offices.view}
						to={paths.app.admin.groups.view}
					/>
					<Redirect
						exact={true}
						from={`${paths.app.admin.legacy.offices.root}/:groupId/agents/import`}
						to={`${paths.app.admin.groups.root}/:groupId/agents/import`}
					/>
					<Redirect
						exact={true}
						from={`${paths.app.admin.legacy.offices.root}/:groupId/subscription`}
						to={`${paths.app.admin.groups.root}/:groupId/subscription`}
					/>
					<Redirect
						exact={true}
						from={`${paths.app.admin.legacy.offices.root}/:groupId/theme`}
						to={`${paths.app.admin.groups.root}/:groupId/theme`}
					/>
					<Page
						component={ThemedDashboard}
						path={paths.app.admin.dashboard}
						title={translate('admin.nav.main.dashboard')}
						withContainer={true}
					/>
					<Page
						component={Features}
						path={paths.app.admin.features.root}
						title={translate('admin.nav.main.features')}
						withContainer={true}
					/>
					<Page
						component={Offers}
						path={paths.app.admin.offers.root}
						title={translate('admin.nav.main.vendors')}
						withContainer={true}
					/>
					<Page
						component={Groups}
						path={paths.app.admin.groups.root}
						title={translate('admin.nav.main.groups')}
						withContainer={true}
					/>
					<RoleGate allowedRoles={roles.admin}>
						<Page
							component={Theme}
							path={paths.app.admin.theme}
							title={translate('admin.nav.main.branding')}
							withContainer={true}
						/>
						<Page
							component={Features}
							path={paths.app.admin.features.root}
							title={translate('admin.nav.main.features')}
							withContainer={true}
						/>
						<Page
							component={Offers}
							path={paths.app.admin.offers.root}
							title={translate('admin.nav.main.vendors')}
							withContainer={true}
						/>
						<Page
							component={Groups}
							path={paths.app.admin.groups.root}
							title={translate('admin.nav.main.groups')}
							withContainer={true}
						/>
						<Page
							component={Admins}
							path={paths.app.admin.admins.root}
							title={translate('admin.nav.main.admins')}
							withContainer={true}
						/>
						<Page
							component={Agents}
							path={paths.app.admin.agents.root}
							title={translate('admin.nav.main.agents')}
							withContainer={true}
						/>
						<Page
							component={Homeowners}
							path={paths.app.admin.homeowners.root}
							title={translate('admin.nav.main.homeowners')}
							withContainer={true}
						/>
						<Page
							component={Contacts}
							path={paths.app.admin.contacts.root}
							title={translate('admin.nav.main.contacts')}
							withContainer={true}
						/>
					</RoleGate>
				</Switch>
			</AppLayout>
		);
	}
}

Admin.contextType = AuthContext;

export default withUserService(Admin);
