import { translate } from 'App/Internationalization';
import * as yup from 'yup';
import BaseField from 'shared/Forms/Fields/BaseField';
import BaseForm from 'shared/Forms/BaseForm';
import React from 'react';
import SubmitButton from 'shared/Forms/Inputs/SubmitButton';
const initialFormValues = { searchQuery: '' };

const validationSchema = yup.object().shape({
	searchQuery: yup.string(),
});

export default class BlogSearchForm extends React.Component {
	render() {
		return (
			<React.Fragment>
				<BaseForm
					className="flex items-end"
					initialValues={initialFormValues}
					noRequiredText={true}
					onSubmit={this.props.onSubmit}
					validationSchema={validationSchema}
				>
					<BaseField
						className="flex-grow mr-2"
						inputClass="h-11"
						label={translate('global.blog.forms.search.label')}
						labelClass="input-label"
						name="searchQuery"
						placeholder={translate(
							'global.blog.forms.search.placeholder'
						)}
						type="search"
					/>

					<SubmitButton>
						{translate('global.forms.buttons.search')}
					</SubmitButton>
				</BaseForm>

				<section className="rich-text mt-4">
					<p>{translate('global.blog.search')}</p>
				</section>
			</React.Fragment>
		);
	}
}
