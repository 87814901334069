/*
 *  this file used in Dashboard and Finances page of HomeOwner
 *  Finances page have next and previous feature between valuations so here is the code for that
 *  and handling touch event in responsive
 */

/**
 * @typedef {import('../../../shared/PropertyService/Valuation').default} Valuation
 * @typedef {import('../../../shared/PropertyService/Valuations').ValuationKeys} ValuationKeys
 * @typedef {import('./CarouselCardProps').default} Props
 * @typedef {import('./CarouselCardState').default} State
 */

import { string } from '@mooveguru/js-utilities';
import { translate } from '../../../Internationalization';
import BaseButton from '../../../../shared/BaseButton/BaseButton';
import formatCentsForDisplay from '../../../../utils/formatCentsForDisplay';
import formatDateForDisplay from '../../../../utils/formatDateForDisplay';
import PropertyValuationGraph from '../../Finances/PropertyValuationGraph';
import PropertyValueChart from '../../Finances/PropertyValueChart';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export default class CarouselCard extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);
		/** @param {State}  state*/
		this.state = {
			activeIndex: 1,
			disabledNext: false,
			disabledPrev: true,
		};
	}

	/**
	 * @param {number} prevIndex
	 * @returns {void}
	 */
	#togglePrev(prevIndex = 0) {
		const index = prevIndex > 0 ? prevIndex : this.state.activeIndex - 1;
		const disabledPrev = index === 1;

		/**
		 * @param {State} prevState
		 * @returns {Promise<void>}
		 */
		this.setState((prevState) => ({
			...prevState,
			activeIndex: index,
			disabledNext: false,
			disabledPrev: disabledPrev,
		}));
	}

	/**
	 * @param {number} nextIndex
	 * @returns {void}
	 */
	#toggleNext(nextIndex = 0) {
		const index = nextIndex > 0 ? nextIndex : this.state.activeIndex + 1;
		const disabledNext = index === this.props.values.size;
		/**
		 * @param {State} prevState
		 * @returns {State}
		 */
		this.setState((prevState) => ({
			...prevState,
			activeIndex: index,
			disabledNext: disabledNext,
			disabledPrev: false,
		}));
	}

	/**
	 * @param {number} index
	 * @returns {void}
	 */

	#handleDotClick(index) {
		if (index < this.state.activeIndex) {
			this.#togglePrev(index);
		}
		if (index > this.state.activeIndex - 1) {
			this.#toggleNext(index);
		}
	}

	/**
	 * @returns {ValuationKeys}
	 */
	#getActiveDataSource() {
		/** @type {ValuationKeys[]} */
		const keyList = [...this.props.values.keys()];
		const active = keyList[this.state.activeIndex - 1];

		return active;
	}

	/**
	 * @returns {Valuation[]}
	 */
	#getActiveDataSet() {
		const source = this.#getActiveDataSource();

		if (this.props.values.has(source)) {
			// @ts-ignore, cannot be undefined as checked above
			return this.props.values.get(source);
		}

		const first = [...this.props.values.keys()].find(Boolean);

		if (first === undefined) {
			return [];
		}

		return this.props.values.get(first) ?? [];
	}

	/**
	 * @param {number} index
	 * @returns {string}
	 */
	#getDotClass(index) {
		return index === this.state.activeIndex - 1
			? 'pagination-item pagination-active'
			: 'pagination-item';
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	#pagination() {
		if (this.props.values.size <= 1) {
			return null;
		}

		return (
			<section className="pagination flex items-center justify-between">
				<BaseButton
					className="button button-outline"
					disabled={this.state.disabledPrev}
					onClick={() => this.#togglePrev()}
				>
					{translate('global.previous')}
				</BaseButton>

				<ul className="pagination-list">{this.#getPaginationDots()}</ul>

				<BaseButton
					className="button button-outline"
					disabled={this.state.disabledNext}
					onClick={() => this.#toggleNext()}
				>
					{translate('global.next')}
				</BaseButton>
			</section>
		);
	}

	/**
	 * @returns {JSX.Element[]}
	 */
	#getPaginationDots() {
		return [...this.props.values.keys()].map(
			/**
			 * @param {ValuationKeys} key
			 * @param {number} index
			 * @returns {JSX.Element}
			 */
			(key, index) => (
				<li className={this.#getDotClass(index)} key={key}>
					<div
						onClick={() => this.#handleDotClick(index + 1)}
						onKeyDown={() => this.#handleDotClick(index + 1)}
						role="presentation"
					/>
				</li>
			)
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const source = this.#getActiveDataSource();
		const active = this.#getActiveDataSet().find(Boolean);

		if (active === undefined) {
			return <PropertyValuationGraph valuations={this.props.values} />;
		}

		return (
			<section className="grid md:grid-cols-2 sm:grid-cols-1 gap-5">
				<PropertyValuationGraph
					highlight={formatCentsForDisplay(active.valueMean, 0)}
					info={{
						title: translate(
							'homeowner.pages.finances.view.estimate.evaluated'
						),
						value: formatDateForDisplay(active.date),
					}}
					title={string.convertCamelCaseToTitleCase(source)}
					valuations={this.props.values}
				/>

				<section>
					<PropertyValueChart valuation={active} />
					<footer className="mt-4">{this.#pagination()}</footer>
				</section>
			</section>
		);
	}
}
