import React from 'react';

import CreateDocument from 'App/Homeowner/Documents/CreateDocument';
import ListDocuments from 'App/Homeowner/Documents/ListDocuments';
import paths from 'config/local/paths';
import Page from 'shared/Page';
import Switch from 'shared/Switch';
import { translate } from 'App/Internationalization';
import { withActivityService } from '../../../service-container';

const InjectedListDocuments = withActivityService(ListDocuments);

export default class Documents extends React.Component {
	render() {
		return (
			<Switch>
				<Page
					component={InjectedListDocuments}
					exact
					path={paths.app.homeowner.documents.root}
					title={translate('homeowner.nav.documents.root')}
				/>
				<Page
					component={CreateDocument}
					path={`${paths.app.homeowner.documents.create}/:category`}
					title={translate('homeowner.nav.documents.create')}
				/>
			</Switch>
		);
	}
}
