/**
 * @typedef {import('../../Registration/RegistrationService/Agent').default} Agent
 * @typedef {import('../../Registration/RegistrationService/Contact').default} Contact
 * @typedef {import('../../Registration/RegistrationService/Dependencies').default} Dependencies
 * @typedef {import('../../Registration/RegistrationService/GetsContact').default} GetsContact
 * @typedef {import('../../Registration/RegistrationService/Homeowner').default} Homeowner
 * @typedef {import('../../Registration/RegistrationService/Manager').default} Manager
 * @typedef {import('../../Registration/RegistrationService/PhoneNumber').default} PhoneNumber
 * @typedef {import('../../Registration/RegistrationService/RegistersAgent').default} RegistersAgent
 * @typedef {import('../../Registration/RegistrationService/RegistersHomeowner').default} RegistersHomeowner
 * @typedef {import('../../Registration/RegistrationService/RequestBodyAgent').default} RequestBodyAgent
 * @typedef {import('../../Registration/RegistrationService/RequestBodyHomeowner').default} RequestBodyHomeowner
 * @typedef {import('../../Registration/RegistrationService/RequestBodyPhoneNumber').default} RequestBodyPhoneNumber
 * @typedef {import('./EventData').default} EventData
 */

import apiUrls from '../../config/local/api-urls';
import DataLayer from '../../DataLayer';
import formatPhoneNumberForDisplay from '../../utils/formatPhoneNumberForDisplay';
import ServiceError from '../../shared/Errors/ServiceError';

/**
 * @implements {GetsContact}
 * @implements {RegistersAgent}
 * @implements {RegistersHomeowner}
 */
export default class RegistrationService {
	/** @param {Dependencies} dependencies */
	constructor(dependencies) {
		this.baseHeaders = new Headers({ 'Content-Type': 'application/json' });
		this.httpClient = dependencies.httpClient;
	}

	/**
	 * @param {EventData} data
	 * @returns {void}
	 */
	static #trackRegistration(data) {
		DataLayer.add({
			event: 'UserRegistration',
			userCity: data.city,
			userCountry: data.country,
			userEmail: data.email,
			userId: data.id,
			userNameFirst: data.nameFirst,
			userNameLast: data.nameLast,
			userPhoneLandline: data.phoneLandline,
			userPhoneMobile: data.phoneMobile,
			userPostalCode: data.postalCode,
			userRole: data.role,
			userSlug: data.slug,
			userState: data.state,
			userStreetAddress1: data.streetAddress1,
			userStreetAddress2: data.streetAddress2,
		});
	}

	/**
	 * @param {string} token
	 * @returns {Promise<Contact>}
	 */
	async getContact(token) {
		const response = await this.httpClient.post(
			apiUrls.register.verifyHomeowner,
			this.baseHeaders,
			{ token }
		);

		if (!response.isOk) {
			throw new ServiceError('Could not register user', response.body);
		}

		return {
			address:
				response.body.address !== null
					? {
							city: response.body.address.city,
							country: response.body.address.country,
							postalCode: response.body.address.postal_code,
							state: response.body.address.state,
							streetAddress1:
								response.body.address.street_address_1,
							streetAddress2:
								response.body.address.street_address_2,
					  }
					: null,
			agentId: response.body.agent_id,
			contactId: response.body.contact_id,
			emailAddress: response.body.email_address,
			name: {
				first: response.body.name.first,
				last: response.body.name.last,
			},
			phoneNumbers: response.body.phone_numbers.map(
				/**
				 * @param {RequestBodyPhoneNumber} phoneNumber
				 * @returns {PhoneNumber}
				 */
				(phoneNumber) => ({
					isPrimary: phoneNumber.is_primary,
					type: phoneNumber.type,
					value: formatPhoneNumberForDisplay(phoneNumber.value),
				})
			),
		};
	}

	/** @param {Agent} agent */
	async registerAgent(agent) {
		const response = await this.httpClient.post(
			apiUrls.register.agent,
			this.baseHeaders,
			RegistrationService.mapAgentToRequestBody(agent)
		);

		if (!response.isOk) {
			throw new Error(response.body.join(' '));
		}

		RegistrationService.#trackRegistration({
			email: agent.emailAddress,
			id: response.body.id,
			nameFirst: agent.name.first,
			nameLast: agent.name.last,
			postalCode: agent.postalCode,
			role: 'Agent',
			slug: response.body.slug,
		});
	}

	/** @param {Manager} manager */
	async registerManager(manager) {
		const response = await this.httpClient.post(
			apiUrls.register.manager,
			this.baseHeaders,
			RegistrationService.mapManagerToRequestBody(manager)
		);

		if (!response.isOk) {
			throw new Error(response.body.join(' '));
		}

		RegistrationService.#trackRegistration({
			email: manager.emailAddress,
			id: response.body.id,
			nameFirst: manager.name.first,
			nameLast: manager.name.last,
			role: 'Manager',
		});
	}

	/**
	 * @param {Homeowner} homeowner
	 * @param {string=} agentId
	 * @param {string=} contactId
	 * @param {string=} agentSlug
	 * @param {string=} propertyId
	 */
	// eslint-disable-next-line complexity
	async registerHomeowner(
		homeowner,
		agentId,
		contactId,
		agentSlug,
		propertyId
	) {
		const requestBody =
			RegistrationService.mapHomeownerToRequestBody(homeowner);
		if (agentId !== undefined) {
			/* eslint-disable-next-line camelcase -- Mapping request body */
			requestBody.agent_id = agentId;
		}

		if (contactId !== undefined) {
			/* eslint-disable-next-line camelcase -- Mapping request body */
			requestBody.contact_id = contactId;
		}

		if (agentSlug !== undefined) {
			/* eslint-disable-next-line camelcase -- Mapping request body */
			requestBody.agent_slug = agentSlug;
		}

		if (propertyId !== undefined) {
			/* eslint-disable-next-line camelcase -- Mapping request body */
			requestBody.propertyId = propertyId;
		}

		const response = await this.httpClient.post(
			apiUrls.register.homeowner,
			this.baseHeaders,
			requestBody
		);

		if (!response.isOk) {
			throw new ServiceError(
				'There was an error signing you up',
				response.body
			);
		}

		RegistrationService.#trackRegistration({
			city: homeowner.address.city,
			email: homeowner.emailAddress,
			id: response.body.id,
			nameFirst: homeowner.name.first,
			nameLast: homeowner.name.last,
			phoneLandline: homeowner.phoneNumbers.find(
				(phoneNumber) => phoneNumber.type === 'home'
			)?.value,
			phoneMobile: homeowner.phoneNumbers.find(
				(phoneNumber) => phoneNumber.type === 'mobile'
			)?.value,
			postalCode: homeowner.address.postalCode,
			role: 'Homeowner',
			state: homeowner.address.state,
			streetAddress1: homeowner.address.streetAddress1,
			streetAddress2: homeowner.address.streetAddress2 ?? undefined,
		});
	}

	/**
	 * @param {Agent} agent
	 * @returns {RequestBodyAgent}
	 */
	static mapAgentToRequestBody(agent) {
		return {
			/* eslint-disable-next-line camelcase -- Mapping request body */
			email_address: agent.emailAddress,
			/* eslint-disable-next-line camelcase -- Mapping request body */
			group_slug: agent.groupSlug ?? undefined,
			name: {
				first: agent.name.first,
				last: agent.name.last,
			},
			occupation: agent.occupation,
			password: agent.password,
			/* eslint-disable-next-line camelcase -- Mapping request body */
			postal_code: agent.postalCode,
		};
	}

	/**
	 * @param {Manager} manager
	 */
	static mapManagerToRequestBody(manager) {
		return {
			/* eslint-disable-next-line camelcase -- Mapping request body */
			email_address: manager.emailAddress,
			group: {
				name: manager.group,
			},
			name: {
				first: manager.name.first,
				last: manager.name.last,
			},
			occupation: manager.occupation,
			password: manager.password,
		};
	}

	/**
	 * @param {Homeowner} homeowner
	 * @returns {RequestBodyHomeowner}
	 */
	static mapHomeownerToRequestBody(homeowner) {
		/* eslint-disable camelcase -- Mapping request body */
		const address = {
			city: homeowner.address.city,
			country: homeowner.address.country,
			postal_code: homeowner.address.postalCode,
			state: homeowner.address.state,
			street_address_1: homeowner.address.streetAddress1,
			street_address_2: homeowner.address.streetAddress2,
		};

		return {
			address,
			email_address: homeowner.emailAddress,
			name: { first: homeowner.name.first, last: homeowner.name.last },
			password: homeowner.password,
			phone_numbers: RegistrationService.mapPhoneNumbers(
				homeowner.phoneNumbers
			),
		};
		/* eslint-enable camelcase */
	}

	/**
	 * @param {Homeowner['phoneNumbers']} phoneNumbers
	 * @returns {{is_primary: boolean, type: "home" | "mobile", value: string}[]}
	 */
	static mapPhoneNumbers(phoneNumbers) {
		return phoneNumbers.map((phoneNumber) => ({
			/* eslint-disable-next-line camelcase -- Mapping request body */
			is_primary: phoneNumber.isPrimary,
			type: phoneNumber.type,
			value: phoneNumber.value,
		}));
	}
}
