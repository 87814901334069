/**
 * @typedef {import('./FileUploadFormComponent').Props} Props
 * @typedef {import('./FileUploadFormComponent').State} State
 */
import FileUploadForm from './FileUploadForm';
import maxUpload from '@mooveguru/yhh-shared-config/files/max-upload.json';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export default class ImageUploadForm extends React.Component {
	/** @type {Pick<Props, 'accept'| 'className' | 'maxSize' | 'messages' | 'preview' | 'submit'>} */
	static defaultProps = {
		accept: [...Object.values(mimeList.images)],
		className: null,
		maxSize: maxUpload.image,
		messages: {
			successes: [],
			warnings: [],
		},
		preview: null,
		submit: 'Submit',
	};

	render() {
		return (
			<FileUploadForm
				accept={this.props.accept}
				className={this.props.className}
				label={this.props.label}
				maxSize={this.props.maxSize}
				messages={this.props.messages}
				name={this.props.name}
				onChange={this.props.onChange}
				onSubmit={this.props.onSubmit}
				preview={this.props.preview}
				submit={this.props.submit}
			/>
		);
	}
}
