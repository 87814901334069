/**
 * @typedef {import('./DashboardProps').default} Props
 * @typedef {import('./DashboardState').default} State
 * @typedef {import('../../shared/PropertyService/PropertyService').default} PropertyService
 */

import { AuthContext } from '../../../shared/AuthProvider';
import { translate } from '../../Internationalization';
import { withBreakpoint } from '../../../shared/BreakpointProvider';
import { withPropertyService } from '../../../service-container';
import AdsContainer from '../../../shared/BannerAds/AdsContainer/AdsContainer';
import ComparableMarketCard from '../shared/ComparableMarketCard';
import DashboardProgress from './DashboardProgress';
import FeaturedBlogs from '../../../Blog/FeaturedBlogs';
import FinanceHeader from './FinanceHeader';
import ProfessionalsCard from './ProfessionalsCard';
import PropertyCard from './PropertyCard/PropertyCard';
import PropertyValuations from '../Finances/PropertyValuations';
import React from 'react';
import WelcomeCard from '../../shared/WelcomeCard/WelcomeCard';

/** @extends {React.Component<Props, State>} */
class Dashboard extends React.Component {
	/**
	 * @returns {JSX.Element}
	 */
	/* eslint-disable max-lines-per-function -- long but not complex */
	render() {
		const components = {
			ads: <AdsContainer userRole="homeowner" />,
			blog: (
				<FeaturedBlogs
					key="homeowner-dashboard-blog"
					// @ts-ignore -- type added into props
					title={translate('agent.pages.dashboard.blog.title')}
					userRole="homeowner"
				/>
			),
			comps: <ComparableMarketCard key="homeowner-dashboard-comps" />,
			finances: <FinanceHeader key="homeowner-dashboard-finances" />,
			progress: <DashboardProgress key="homeowner-dashboard-progress" />,
			property: <PropertyCard key="homeowner-dashboard-property" />,
			pros: (
				<ProfessionalsCard
					buttonClass="mx-auto"
					inputClass="mb-4"
					key="homeowner-dashboard-pros"
				/>
			),
			valuations: (
				<PropertyValuations
					key="homeowner-dashboard-valuations"
					withCarousel={false}
				/>
			),
			welcome: <WelcomeCard key="homeowner-dashboard-welcome" />,
		};

		if (this.props.isBelowBreakpoint) {
			return (
				<React.Fragment>
					{components.welcome}
					<section className="app-block-list">
						{components.property}
						{components.progress}
						{components.finances}
						{components.valuations}
						{components.ads}
						{components.comps}
						{components.pros}
						{components.blog}
					</section>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				{components.welcome}
				<section className="app-responsive-area app-responsive-sidebar-grid">
					<section className="responsive-main app-block-list">
						{components.property}
						{components.finances}
						{components.valuations}
						{components.ads}
						{components.comps}
					</section>

					<aside className="responsive-secondary app-block-list">
						{components.progress}
						{components.pros}
						{components.blog}
					</aside>
				</section>
			</React.Fragment>
		);
	}
}

Dashboard.contextType = AuthContext;
export default withPropertyService(withBreakpoint(Dashboard));
