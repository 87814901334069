/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */

import * as yup from 'yup';
import { analyticsTagValidator } from '../../../../shared/validators';
import { BaseSVG } from '../../../../shared/BaseSVG';
import { toAbsoluteUrl } from '../../../../utils/toAbsoluteUrl';
import { translate } from '../../../Internationalization';
import { withAgentService } from '../../../../service-container';
import BaseForm from '../../../../shared/Forms/BaseForm';
import BaseInput from '../../../../shared/Forms/Inputs/BaseInput';
import captureError from '../../../../utils/captureError';
import CopyToClipboard from '../../../../shared/Links/CopyToClipboard';
import ExternalAnchorButton from '../../../../shared/ExternalAnchorButton';
import FieldRadioGroup from '../../../../shared/Forms/Fields/FieldRadioGroup';
import FormSuccessMessage from '../../../../shared/Forms/Messages/FormSuccessMessages';
import images from '../../../../config/local/images';
import LeadsTable from './LeadsTable';
import Loading from '../../../../shared/Loading';
import NoContent from '../../../../shared/NoContent';
import React from 'react';
import SubmitButton from '../../../../shared/Forms/Inputs/SubmitButton';
import Svg from 'react-inlinesvg';
import valuation from 'config/local/Widgets/valuation';

/** @extends {React.Component<Props, State>} */
class Valuation extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/**
		 * @type {State}
		 */
		this.state = {
			agentId: null,
			analyticsTag: null,
			analyticsTagSuccessMessage: null,
			codeType: 'javascript',
			copySuccessMessage: null,
			element: `<script type="text/javascript" src="${valuation.urls.script}"></script>`,
			errorMessage: null,
			isGettingData: true,
		};

		this._handleOnCopySuccess = this._handleOnCopySuccess.bind(this);
		this._handleOnTypeUpdate = this._handleOnTypeUpdate.bind(this);
		this._handleOnSubmit = this._handleOnSubmit.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			const agent = await this.props.agentService.getAgentPersonalData();

			this.setState({
				agentId: agent.id,
				isGettingData: false,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {Props} prevProps
	 * @param {State} prevState
	 * @returns {Promise<void>}
	 */
	async componentDidUpdate(prevProps, prevState) {
		if (this.state.agentId !== prevState.agentId) {
			this.#setElement();
		}
	}

	/**
	 * @protected
	 * @param {{analyticsTag: string}} values
	 * @returns {Promise<void>}
	 */
	async _handleOnSubmit(values) {
		const { analyticsTag } = values;
		const analyticsTagSuccessMessage = translate(
			'agent.pages.widgets.lead_generation.embed.analytics_tag_success_message',
			analyticsTag
		);

		this.#setElement(undefined, analyticsTag);

		this.setState({
			analyticsTag,
			analyticsTagSuccessMessage,
		});
	}

	/**
	 * @protected
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @returns {void}
	 */
	_handleOnTypeUpdate(event) {
		const codeType = event.target.value;

		if (codeType === this.state.codeType) {
			return;
		}

		this.#setElement(codeType);
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleOnCopySuccess() {
		const copySuccessMessage = translate(
			'agent.pages.widgets.lead_generation.embed.copy_code_success_message'
		);

		this.setState({
			copySuccessMessage,
		});
	}

	/**
	 * @returns {JSX.Element}
	 */
	get #renderCodeTypeSnippet() {
		const element = this.state.element;
		return <p className="text-grey-700 overflow-hidden">{element}</p>;
	}

	/**
	 * @param {string} [type]
	 * @param {string} [tag]
	 * @returns {void}
	 */
	#setElement(type, tag) {
		this.setState((prevState) => {
			const analyticsTag = tag ?? prevState.analyticsTag;
			const codeType = type ?? prevState.codeType;

			if (codeType === 'javascript') {
				const element = this.#getScriptHtmlString(
					analyticsTag ?? undefined
				);

				return {
					codeType,
					element,
				};
			}

			const element = this.#getIframeHtmlString(
				analyticsTag ?? undefined
			);

			return {
				codeType,
				element,
			};
		});
	}

	/**
	 * @param {string} [analyticsTag]
	 * @returns {string}
	 */
	#getScriptHtmlString(analyticsTag) {
		const agentId = this.state.agentId;

		const widgetURL = new URL(valuation.urls.script);

		if (analyticsTag) {
			widgetURL.searchParams.append('analytics_tag', analyticsTag);
		}

		const script = document.createElement('script');
		script.setAttribute('src', widgetURL.toString());
		script.setAttribute('type', valuation.scriptType);

		const div = document.createElement('div');
		// @ts-ignore will not be null
		div.setAttribute('data-agent', agentId);
		div.setAttribute('data-tenant', valuation.dataAttributes.tenant);
		div.setAttribute('id', 'hk-widget-valuation');

		return `${script.outerHTML}\n${div.outerHTML}`;
	}

	/**
	 * @param {string} [analyticsTag]
	 * @returns {string}
	 */
	#getIframeHtmlString(analyticsTag) {
		const agentId = this.state.agentId;
		const widgetURL = new URL(valuation.urls.base);
		const height = '650';

		// @ts-ignore will not null
		widgetURL.searchParams.append('id', agentId);

		widgetURL.searchParams.append(
			'tenant',
			valuation.dataAttributes.tenant
		);

		if (analyticsTag) {
			widgetURL.searchParams.append('analytics_tag', analyticsTag);
		}

		const iframe = document.createElement('iframe');
		iframe.setAttribute('height', height);
		iframe.setAttribute('src', widgetURL.toString());
		iframe.setAttribute('width', '100%');
		iframe.setAttribute(
			'style',
			`border:none; height: ${height}px; width: 100%`
		);

		return iframe.outerHTML.replace(/&amp;/g, '&');
	}

	/**
	 * @returns {JSX.Element}
	 */
	get #renderElementOptions() {
		return (
			<BaseForm
				initialValues={{
					codeType: this.state.codeType,
				}}
				noRequiredText={true}
			>
				<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
					<FieldRadioGroup
						name="codeType"
						onClick={this._handleOnTypeUpdate}
						required={true}
						values={[
							[
								'javascript',
								translate(
									'agent.pages.widgets.lead_generation.embed.recommended'
								),
							],
							[
								'iframe',
								translate(
									'agent.pages.widgets.lead_generation.embed.iframe'
								),
							],
						]}
					/>
				</fieldset>
			</BaseForm>
		);
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	get #renderTagAddedSuccess() {
		const successMessage = this.state.analyticsTagSuccessMessage;
		if (!successMessage) {
			return null;
		}

		return (
			<section className="form-alert w-full my-5 text-grey-900 form-alert-success">
				<h3 className="title">
					<i className="icon icon-xs">
						<BaseSVG path={images.icons.submit} />
					</i>
					{translate('global.success')}
				</h3>
				<div
					className="text rich-text"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: successMessage,
					}}
				/>
			</section>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	/* eslint-disable class-methods-use-this -- Render method must be invokable by `this` */
	render() {
		if (this.state.errorMessage) {
			return <NoContent message={this.state.errorMessage} />;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		const validationSchema = yup.object().shape({
			analyticsTag: analyticsTagValidator,
		});

		return (
			<React.Fragment>
				<section className="my-6">
					<div className="mb-8 inline-flex items-center">
						<h1 className="hl-ms-6 mr-6">
							{translate(
								'agent.pages.widgets.lead_generation.leads'
							)}
						</h1>
						<a
							className="button button-outline button-icon"
							href="#embed"
						>
							<span>
								{translate(
									'agent.pages.widgets.lead_generation.view_embed_code'
								)}
							</span>
							<i className="icon icon-fill icon-sm">
								<Svg src={toAbsoluteUrl(images.icons.eye)} />
							</i>
						</a>
					</div>
					<LeadsTable />
				</section>

				<section className="my-6" id="embed">
					<h1 className="hl-ms-6 mb-4">
						{translate(
							'agent.pages.widgets.lead_generation.embed.embed_code'
						)}
					</h1>
					<div className="grid grid-cols-1 md:grid-cols-2 w-full">
						{/* <div className="mr-6">
							<BaseForm
								className="my-6"
								initialValues={{ analyticsTag: '' }}
								noRequiredText={true}
								onSubmit={this._handleOnSubmit}
								validationSchema={validationSchema}
							>
								<label
									className="input-label"
									// eslint-disable-next-line react/no-danger
									dangerouslySetInnerHTML={{
										__html: translate(
											'agent.pages.widgets.lead_generation.embed.analytics_label'
										),
									}}
									htmlFor="analyticsTag"
								/>
								<BaseInput
									disabled={false}
									id="analyticsTag"
									name="analyticsTag"
									placeholder={translate(
										'agent.pages.widgets.lead_generation.embed.analytics_placeholder'
									)}
									required={true}
									type="text"
								/>
								<SubmitButton
									className="my-4"
									icon={false}
									size="medium"
								>
									{translate(
										'agent.pages.widgets.lead_generation.embed.add_tag'
									)}
								</SubmitButton>
								{this.#renderTagAddedSuccess}
							</BaseForm>
						</div> */}
						<div>
							<div className="outline outline-grey-300 outline-1 p-2 w-full">
								<p className="font-bold w-full">
									{translate(
										'agent.pages.widgets.lead_generation.embed.code_type_description'
									)}
								</p>
								{this.#renderElementOptions}
								<div className="outline outline-grey-700 outline-1 p-2 mb-4">
									{this.#renderCodeTypeSnippet}
								</div>
								<CopyToClipboard
									className="button w-full"
									onSuccess={this._handleOnCopySuccess}
									text={translate(
										'agent.pages.widgets.lead_generation.embed.copy_code'
									)}
									url={this.state.element}
								/>
								<FormSuccessMessage
									messages={this.state.copySuccessMessage}
								/>
							</div>
							<div className="my-6">
								<p
									className="mb-4"
									// eslint-disable-next-line react/no-danger
									dangerouslySetInnerHTML={{
										__html: translate(
											'agent.pages.widgets.lead_generation.embed.help_description'
										),
									}}
								/>
								<ExternalAnchorButton
									className="button"
									href={valuation.urls.help}
								>
									{translate(
										'agent.pages.widgets.lead_generation.embed.read_more'
									)}
								</ExternalAnchorButton>
							</div>
						</div>
					</div>
				</section>
			</React.Fragment>
		);
	}
}

// @ts-ignore
export default withAgentService(Valuation);
