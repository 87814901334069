/**
 * @typedef {import('./Languages').default} Languages
 * @typedef {import('@mooveguru/js-utilities/types/KeyedObject').default} KeyedObject
 */
import { object } from '@mooveguru/js-utilities';
import es from '../../config/translation/es.json';
import en from '../../config/translation/en.json';

// TODO: Add more languages
class TranslationService {
	/**
	 * @readonly
	 * @type {{[key in Languages]: KeyedObject}}
	 */
	#languages = { en, es };

	/**
	 * @type {Languages}
	 */
	#currentLanguage;

	/**
	 * @type {Languages}
	 */
	#defaultLanguage = 'en';

	constructor() {
		this.#currentLanguage = this.#detectLanguage();
	}

	/**
	 * @returns {Languages}
	 */
	#detectLanguage() {
		let currentLanguage = navigator.language;

		if (currentLanguage.includes('-')) {
			currentLanguage =
				currentLanguage.split('-').shift() ?? currentLanguage;
		}

		if (!Object.keys(this.#languages).includes(currentLanguage)) {
			currentLanguage = this.#defaultLanguage;
		}

		// @ts-ignore -- Verified with `includes` above
		return currentLanguage;
	}

	/**
	 * @public
	 * @returns {Languages}
	 */
	get language() {
		return this.#currentLanguage;
	}

	/**
	 * @public
	 * @param {Languages} language
	 * @returns {void}
	 */
	set language(language) {
		this.#currentLanguage = language;
	}

	/**
	 * @public
	 * @returns {Languages}
	 */
	get default() {
		return this.#defaultLanguage;
	}

	/**
	 * @param {unknown} error
	 * @param {string} dots
	 * @param {string[]} args
	 * @returns {void}
	 */
	static #logError(error, dots, args) {
		if ((process.env.REACT_APP_ENV || 'local') !== 'local') {
			return;
		}

		/* eslint-disable-next-line  no-console -- Logs error on local envs only */
		console.error('ERROR: Could not retrieve translation string.\n', {
			args,
			dots,
			error: error,
		});
	}

	/**
	 * @public
	 * @param {string} dots
	 * @param {...string} argList
	 * @returns {string}
	 */
	translate(dots, ...argList) {
		if (!dots) {
			return '';
		}

		try {
			/** @type {string} */
			const value = this.#getMessage(dots);

			if (!argList) {
				return value;
			}

			return TranslationService.#format(value, ...argList);
		} catch (error) {
			TranslationService.#logError(error, dots, argList);
		}

		return '';
	}

	/**
	 * @param {string} dots
	 * @returns {string}
	 */
	#getMessage(dots) {
		const value =
			object.get(this.#content, dots, '') ||
			object.get(this.#defaultContent, dots, '');

		return `${value}`;
	}

	get #content() {
		return (
			this.#languages[this.#currentLanguage] ??
			this.#languages[this.#defaultLanguage]
		);
	}

	get #defaultContent() {
		return this.#languages[this.#defaultLanguage];
	}

	/**
	 * Similar to `sprintf` in other languages, replaces `%s`
	 * in order of the arguments passed.
	 * @param {string} string
	 * @param {...string} replacementList
	 * @returns {string}
	 */
	static #format(string, ...replacementList) {
		let formatted = string;

		replacementList.forEach((replacement) => {
			formatted = formatted.replace('%s', replacement);
		});

		return formatted;
	}
}

const service = new TranslationService();

/**
 * @param {string} dots
 * @param {...string} replacementList
 * @returns {string}
 */
function translate(dots, ...replacementList) {
	return service.translate(dots, ...replacementList);
}

/**
 * @param {Languages} language
 * @returns {void}
 */
function setLanguage(language) {
	service.language = language;
}

/**
 * @returns {Languages}
 */
function getLanguage() {
	return service.language;
}

export { service as TranslationService, translate, getLanguage, setLanguage };
