import { translate } from 'App/Internationalization';
import FormField from 'shared/Forms/Fields/FormField';
import InputPhoneNumber from 'shared/Forms/Inputs/PhoneNumber/InputPhoneNumber';
import React from 'react';

export default class FieldPhoneNumber extends React.Component {
	render() {
		const label = translate(
			`global.forms.inputs.phone.${this.props.type}.label`
		);

		return (
			<FormField
				className={this.props.className}
				inputName={this.props.name}
				labelClass={this.props.labelClass}
				label={this.props.label ?? label}
				name={this.props.name}
				required={this.props.required}
				help={translate('global.forms.help.phone_number')}
			>
				<InputPhoneNumber
					autocomplete={this.props.autocomplete}
					className={this.props.inputClass}
					disabled={this.props.disabled}
					name={this.props.name}
					required={this.props.required}
					type={this.props.type}
				/>
			</FormField>
		);
	}
}

FieldPhoneNumber.defaultProps = {
	autocomplete: InputPhoneNumber.defaultProps.autocomplete,
	className: InputPhoneNumber.defaultProps.className,
	disabled: InputPhoneNumber.defaultProps.disabled,
	inputClass: InputPhoneNumber.defaultProps.className,
	labelClass: FormField.defaultProps.labelClass,
	name: InputPhoneNumber.defaultProps.name,
	required: InputPhoneNumber.defaultProps.required,
	type: InputPhoneNumber.defaultProps.type,
};
