/**
 * @typedef {import('./GroupPrimaryFieldGroupType').default} Props
 * @typedef {import('formik').FormikValues} FormikProps
 */

import { translate } from '../../../Internationalization';
import FieldGroupSelect from '../../../../shared/Forms/Fields/FieldGroupSelect';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class GroupPrimaryFieldGroup extends React.Component {
	static defaultProps = {
		groupFieldName: 'groups',
		primaryGroupFieldName: 'primaryGroup',
	};

	/** @param {Props} props */
	constructor(props) {
		super(props);

		this.handleUpdateGroup =
			GroupPrimaryFieldGroup.handleUpdateGroup.bind(this);
	}

	/**
	 * @param {{value: string}} object
	 * @returns {{id: string; isPrimary: boolean} | null}
	 */
	static #mapToGroup(object) {
		if (!object) {
			return null;
		}

		return {
			id: object.value,
			isPrimary: false,
		};
	}

	/**
	 * @param {{value: string; lablel: string}[]} event
	 * @param {FormikProps} formik
	 * @returns {void}
	 */
	static handleUpdateGroup(event, formik) {
		const groupList = event.map(GroupPrimaryFieldGroup.#mapToGroup);

		if (!groupList.length) {
			return;
		}

		// @ts-ignore method will return in the line above if groupList is null
		groupList[0].isPrimary = true;

		formik.form.setFieldValue(formik.field.name, groupList);
	}

	render() {
		return (
			<FieldGroupSelect
				// @ts-ignore -  FieldGroupSelect doesn't have types
				initialGroup={this.props.initialGroupValues?.groups}
				isMultipleSelect={true}
				label={translate('admin.pages.agents.form.group_select.label')}
				name={this.props.groupFieldName}
				onChange={this.handleUpdateGroup}
				required={this.props.required}
			/>
		);
	}
}
