import { translate } from '../../Internationalization';
import ActionMessage from '../../../shared/ActionMessages/ActionMessage';
import React from 'react';

export class ContactDeletePopup extends React.Component {
	/** @returns {JSX.Element} */
	#renderPopupContent() {
		const deleteText = translate(
			'agent.pages.contacts.popup.delete_info',
			this.props.selectedContacts?.length.toString()
		);

		const deleteSingleContactText = (
			<div>
				<div>
					{translate('agent.pages.contacts.popup.delete_info_single')}
				</div>
				<span className="font-bold">{this.props.contactName}</span>
			</div>
		);

		const deleteSingleContact = this.props.selectedContacts?.length === 1;

		return (
			<section>
				{deleteSingleContact ? deleteSingleContactText : deleteText}
				<div>
					{translate('agent.pages.contacts.popup.delete_warning')}
				</div>
			</section>
		);
	}

	/** @returns {JSX.Element} */
	#renderNoContactsError() {
		return (
			<ActionMessage
				action="error"
				onClose={this.props.onClose}
				title={translate('agent.pages.contacts.popup.header_error')}
			>
				<p
					// eslint-disable-next-line react/no-danger -- translation file uses html tags
					dangerouslySetInnerHTML={{
						__html: translate(
							'agent.pages.contacts.popup.no_contacts_selected'
						),
					}}
				/>
			</ActionMessage>
		);
	}

	/** @returns {JSX.Element} */
	render() {
		if (!this.props.selectedContacts?.length) {
			return this.#renderNoContactsError();
		}

		return (
			<ActionMessage
				action="warning"
				onClose={this.props.onClose}
				onSubmit={this.props.onSubmit}
				title={translate('agent.pages.contacts.popup.header')}
			>
				{this.#renderPopupContent()}
			</ActionMessage>
		);
	}
}
