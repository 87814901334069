import { NavLink } from 'react-router-dom';
import { translate } from '../../Internationalization';
import homeServices from '../../../config/local/home-services';
import LocalProsSearch from '../../shared/LocalProsSearch/LocalProsSearch';
import paths from '../../../config/local/paths';
import React from 'react';

export default class ProfessionalsCard extends React.Component {
	/**
	 * @param {string} service
	 * @returns {JSX.Element}
	 */
	static renderServiceLink(service) {
		const title = translate(
			`homeowner.pages.dashboard.marketplace.services.${service}`
		);

		const link = {
			pathname: paths.app.homeowner.marketplace,
			state: {
				category: service,
			},
		};

		return (
			<NavLink
				className="button button-pill button-lg button-outline"
				key={`service-link-${service}`}
				to={link}
			>
				{title}
			</NavLink>
		);
	}

	/**
	 * @returns {JSX.Element[]}
	 */
	static renderServiceLinks() {
		return homeServices.map((service) =>
			ProfessionalsCard.renderServiceLink(service)
		);
	}

	render() {
		return (
			<section className="mt-10">
				<header className="h-ms-4 mb-4">
					{translate('homeowner.pages.dashboard.marketplace.title')}
				</header>

				<LocalProsSearch
					buttonClass={this.props.buttonClass}
					inputClass={this.props.inputClass}
				/>

				<section className="mt-10">
					<header className="h-ms-1 mb-3 text-center">
						{translate(
							'homeowner.pages.dashboard.marketplace.services.title'
						)}
					</header>
					<nav className="button-category-group">
						{ProfessionalsCard.renderServiceLinks()}
					</nav>
				</section>
			</section>
		);
	}
}
