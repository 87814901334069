import React from 'react';

import { AuthContext } from 'shared/AuthProvider';
import { file as validateFile } from 'shared/validators';
import { Redirect } from 'react-router';
import { translate } from 'App/Internationalization';
import AgentForm from './AgentForm';
import Card from 'shared/Card';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import paths from 'config/local/paths';

const successMessage = translate('admin.pages.agents.add.success_message');

export default class CreateAgent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			file: null,
			success: false,
			error: '',
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleFileUploadChange = this.handleFileUploadChange.bind(this);
	}

	render() {
		if (this.state.success) {
			return (
				<Redirect
					to={{
						pathname: paths.app.admin.agents.view,
						state: { message: successMessage },
					}}
				/>
			);
		}

		return (
			<AgentForm
				onChange={this.handleFileUploadChange}
				onSubmit={this.handleSubmit}
				submitText={translate('admin.pages.agents.add.add')}
				title={translate('admin.pages.agents.add.title')}
				error={this.state.error}
				success={this.state.success}
			/>
		);
	}

	async handleSubmit(data) {
		if (this.state.file) {
			try {
				validateFile(this.state.file, mimeList.images, 'image');
			} catch (error) {
				this.setState({ error: error });

				return;
			}
		}

		try {
			await this.props.agentService.createAgent(
				data,
				this.state.file || undefined
			);
		} catch (error) {
			this.setState({ error: error.message });
			return;
		}
		this.setState({ success: true });
	}

	handleFileUploadChange(event) {
		if (!event.target.files.length) {
			return;
		}

		this.setState({ file: event.target.files[0] });
	}
}

CreateAgent.contextType = AuthContext;
