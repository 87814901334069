/**
 * @typedef {import('../../../shared/AuthProvider/Roles').Roles} AllRoles
 */
import { translate } from '../../../App/Internationalization';
import FieldCheckbox from './FieldCheckbox';
import FieldRadioGroup from './FieldRadioGroup';
import FormField from 'shared/Forms/Fields/FormField';
import React from 'react';
import roles from '@mooveguru/yhh-shared-config/users/roles.json';

export default class FieldRoles extends React.Component {
	/**
	 * @private
	 * @type {AllRoles[]}
	 */
	roleList = this.props.roles ?? roles.admin;

	render() {
		/** @type {[AllRoles, string][]}*/
		const options = [];

		const label = translate('global.forms.inputs.roles.label');
		const translationPath = 'global.forms.inputs.roles';

		this.roleList.forEach((role) => {
			options.push([role, translate(`${translationPath}.${role}`)]);
		});

		return (
			<FieldRadioGroup
				inputClass={this.props.inputClass}
				labelClass={this.props.labelClass}
				name="roles"
				title={label}
				values={options}
			/>
		);
	}
}

FieldRoles.defaultProps = {
	disabled: FieldCheckbox.defaultProps.disabled,
	label: 'Roles',
	labelClass: FormField.defaultProps.labelClass,
	required: FieldCheckbox.defaultProps.required,
};
