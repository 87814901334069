import { BaseSVG } from '../../../shared/BaseSVG';
import { translate } from '../../Internationalization';
import images from '../../../config/local/images';
import LinkButton from '../../../shared/LinkButton';
import paths from '../../../config/local/paths';
import React from 'react';
import { withBreakpoint } from '../../../shared/BreakpointProvider';

class FinanceHeader extends React.Component {
	render() {
		// prettier-ignore
		const image = !this.props.isBelowBreakpoint ? null : (
			<footer>
				<BaseSVG
					className="illustration"
					path={images.homeowners.dashboard.finances.header}
				/>
			</footer>
		);

		return (
			<section className="mt-10">
				<header className="flex justify-between items-center mb-4">
					<h2 className="h-ms-4">
						{translate('homeowner.nav.main.finances')}
					</h2>
					<LinkButton
						className="button button-icon"
						icon={images.icons.financesAlt}
						iconClass="icon icon-xs icon-fill-fg"
						to={paths.app.homeowner.finances}
					>
						{translate('homeowner.pages.finances.internal_link')}
					</LinkButton>
				</header>
				{image}
			</section>
		);
	}
}

export default withBreakpoint(FinanceHeader);
