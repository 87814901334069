/**
 * @typedef {{hasTransparency: boolean}} State
 * @typedef {import('./AppLogoProps').default} Props
 */

import { array } from '@mooveguru/js-utilities';
import { Link } from 'react-router-dom';
import { translate } from '../Internationalization';
import { withTheme } from '../../shared/ThemeProvider';
import imageHasTransparency from '../../utils/imageHasTransparency';
import paths from '../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class AppLogo extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		this.state = {
			hasTransparency: false,
		};
	}

	async componentDidMount() {
		let hasTransparency = false;

		try {
			hasTransparency = await imageHasTransparency(
				this.props.theme.logoUrl
			);
		} catch (error) {
			/*
			 * We don't need to log this. If an
			 * error occurs we just treat is as
			 * if the image is not transparent.
			 */
		}

		this.setState({
			hasTransparency,
		});
	}

	/** @returns {boolean} */
	#hasTransparency() {
		const extension = array.last(this.props.theme.logoUrl.split('.'));

		if (['jpeg', 'jpg'].includes(extension)) {
			return false;
		}

		return this.state.hasTransparency;
	}

	/**  @returns {JSX.Element} */
	render() {
		const filterClass = this.#hasTransparency() ? 'has-filter' : '';

		return (
			<Link className="logo-area" to={paths.root}>
				<figure>
					<img
						alt={translate(
							'global.header.logo.alt',
							this.props.theme.appTitle
						)}
						className={
							this.props.className ??
							`logo-image ${filterClass}`.trim()
						}
						src={this.props.theme.logoUrl}
						title={this.props.theme.appTitle}
					/>
				</figure>
			</Link>
		);
	}
}

export default withTheme(AppLogo);
