/**
 * @typedef {AddsAgentOffer & AddsGroupOffer & GetsAgentOffers & GetsGroupOffers & EditsOffer} OfferService
 * @typedef {import('../../shared/OfferForm/OfferFields').default} OfferFields
 * @typedef {import('../../shared/OfferService/AddsAgentOffer').default} AddsAgentOffer
 * @typedef {import('../../shared/OfferService/AddsOffer').default} AddsOffer
 * @typedef {import('../../shared/OfferService/AddsGroupOffer').default} AddsGroupOffer
 * @typedef {import('../../shared/OfferService/EditsOffer').default} EditsOffer
 * @typedef {import('../../shared/OfferService/GetsAgentOffers').default} GetsAgentOffers
 * @typedef {import('../../shared/OfferService/GetsGroupOffers').default} GetsGroupOffers
 * @typedef {import('./ImageData')} ImageData
 */

import { file as validateFile } from '../../../shared/validators';
import { Redirect } from 'react-router-dom';
import { translate } from 'App/Internationalization';
import { withOfferService } from 'service-container';
import captureError from '../../../utils/captureError';
import FormErrorMessages from '../../../shared/Forms/Messages/FormErrorMessages';
import getErrorMessage from 'utils/getErrorMessage';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import Loading from 'shared/Loading';
import OfferForm from 'App/shared/OfferForm/OfferForm';
import paths from 'config/local/paths';
import React from 'react';

const successMessage = translate('agent.pages.offers.edit_message');

export class EditOffer extends React.Component {
	/**
	 * @param {{ offerService: OfferService }} props
	 */
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: '',
			formComplete: false,
			image: null,
			isGettingData: true,
			offerData: {
				isActive: '',
				offerDescription: '',
				offerImage: '',
				offerName: '',
				offerUrl: '',
				offerZip: '',
				vendorEmail: '',
				vendorName: '',
				vendorPhone: '',
			},
		};

		this.handleEditOfferSubmit = this.handleEditOfferSubmit.bind(this);
		this._handleFileUploadChange = this._handleFileUploadChange.bind(this);
	}

	async componentDidMount() {
		const offer = await this.props.offerService.getSingleOffer(
			this.props.location.state.offerId
		);

		offer.isActive = offer.isActive ? 'active' : 'disabled';

		this.setState({
			isGettingData: false,
			offerData: offer,
		});
	}

	/**
	 * @param {OfferFields} values
	 * @returns {Promise<void>}
	 */
	async handleEditOfferSubmit(values) {
		try {
			await this.submitEditOffer(values);
		} catch (error) {
			this.setState({ errorMessage: getErrorMessage(error) });
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #handleImageSubmit() {
		if (!this.state.image) {
			return;
		}
		validateFile(this.state.image, mimeList.images, 'image');

		try {
			await this.props.offerService.addOfferImage(
				this.state.image,
				this.props.location.state.offerId
			);
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate(
					'admin.pages.offers.image_upload.error'
				),
			});
		}
	}

	/**
	 * @protected
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @returns {void}
	 */
	_handleFileUploadChange(event) {
		if (!event?.target?.files?.length) {
			return;
		}

		this.setState({ image: event.target.files[0] });
	}

	/**
	 * @param {OfferFields} offer
	 * @returns {Promise<void>}
	 */
	async submitEditOffer(offer) {
		await this.props.offerService.editOffer(
			offer,
			this.props.location.state.offerId
		);

		await this.#handleImageSubmit();

		this.setState({ formComplete: true });
	}

	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		const viewRoute = paths.app.agent.offers.view;

		if (this.state.formComplete) {
			return (
				<Redirect
					to={{
						pathname: viewRoute,
						state: { message: successMessage },
					}}
				/>
			);
		}

		return (
			<React.Fragment>
				<OfferForm
					cancelPath={paths.app.agent.offers.view}
					hasGroupField={false}
					onChange={this._handleFileUploadChange}
					onSubmit={this.handleEditOfferSubmit}
					formTitle={translate('global.offers.edit_title')}
					formSubmitText={translate('global.offers.update')}
					initialValues={this.state.offerData}
					preview={this.state.offerData.offerImage}
				/>
				<FormErrorMessages messages={this.state.errorMessage} />
			</React.Fragment>
		);
	}
}

export default withOfferService(EditOffer);
