export default {
	successStories: [
		'complete',
		'nice_job',
		'congratulations',
		'great_work',
		'well_done',
		'way_to_go',
		'fantastic',
	],
};
