import React from 'react';

import { translate } from 'App/Internationalization';
import FieldWebsite from 'shared/Forms/Fields/FieldWebsite';

// prettier-ignore
const facebookLabel = translate('global.forms.inputs.url_inputs.facebook.label');
// prettier-ignore
const facebookPlaceholder = translate('global.forms.inputs.url_inputs.facebook.placeholder');
// prettier-ignore
const twitterLabel = translate('global.forms.inputs.url_inputs.twitter.label');
// prettier-ignore
const twitterPlaceholder = translate('global.forms.inputs.url_inputs.twitter.placeholder');
// prettier-ignore
const websiteLabel = translate('global.forms.inputs.url_inputs.website.label');
// prettier-ignore
const websitePlaceholder = translate('global.forms.inputs.url_inputs.website.placeholder');

export default class SocialMediaFields extends React.Component {
	render() {
		return (
			<React.Fragment>
				<FieldWebsite
					className={this.props.className}
					disabled={this.props.disabled}
					label={this.props.facebookLabel ?? facebookLabel}
					name={this.props.facebookName}
					placeholder={
						this.props.facebookPlaceholder ?? facebookPlaceholder
					}
				/>
				<FieldWebsite
					className={this.props.className}
					disabled={this.props.disabled}
					label={this.props.twitterLabel ?? twitterLabel}
					name={this.props.twitterName}
					placeholder={
						this.props.twitterPlaceholder ?? twitterPlaceholder
					}
				/>
				<FieldWebsite
					className={this.props.className}
					disabled={this.props.disabled}
					label={this.props.websiteLabel ?? websiteLabel}
					name={this.props.websiteName}
					placeholder={
						this.props.websitePlaceholder ?? websitePlaceholder
					}
				/>
			</React.Fragment>
		);
	}
}

SocialMediaFields.defaultProps = {
	disabled: false,
	facebookName: 'facebook',
	twitterName: 'twitter',
	websiteName: 'website',
};
