import { translate } from 'App/Internationalization';
import BaseInput from 'shared/Forms/Inputs/BaseInput';
import React from 'react';

export default class InputPostalCode extends React.Component {
	render() {
		const placeholder = translate(
			'global.forms.inputs.address.postal_code.placeholder'
		);

		return (
			<BaseInput
				autocomplete="postal-code"
				className={this.props.className}
				disabled={this.props.disabled}
				inputmode="text"
				name={this.props.name}
				placeholder={placeholder}
				required={this.props.required}
				type="text"
			/>
		);
	}
}

InputPostalCode.defaultProps = {
	className: '',
	disabled: false,
	name: 'postalCode',
	required: false,
};
