import { withAuth } from 'shared/AuthProvider';
import { withUserService } from 'service-container';
import Card from 'shared/Card';
import ChangeUserEmailForm from 'App/Profile/ChangeUserEmailForm';
import React from 'react';
import { translate } from '../Internationalization';

const InjectedChangeUserEmailForm = withAuth(
	withUserService(ChangeUserEmailForm)
);

export default class ChangeUserEmailCard extends React.Component {
	render() {
		return (
			<React.Fragment>
				<h2 className="h-ms-5 mb-6">
					{translate('settings.sections.email')}
				</h2>
				<InjectedChangeUserEmailForm />
			</React.Fragment>
		);
	}
}
