/** @typedef {import('Registration/Props').default} Props */

import { Switch } from 'react-router-dom';
import { translate } from '../App/Internationalization';
import { withSettings } from '../shared/SettingProvider/SettingProvider';
import { withTheme } from '../shared/ThemeProvider';
import AgentPlans from './RegisterAgent/AgentPlans';
import Page from '../shared/Page';
import paths from '../config/local/paths';
import React from 'react';
import RegisterAgent from '../Registration/RegisterAgent';
import RegisterHomeowner from '../Registration/RegisterHomeowner';
import RegisterManager from '../Registration/RegisterManager';
import RegistrationIndex from '../Registration/RegistrationIndex';
import RegistrationLayout from './RegistrationLayout';

/** @extends {React.Component<Props, {}>} */
class Registration extends React.Component {
	/**
	 * @returns {void}
	 */
	componentDidMount() {
		if (this.props.match.params.whiteLabelSlug !== undefined) {
			this.props.theme.getAndSetThemeBySlug(
				this.props.match.params.whiteLabelSlug
			);
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	/* eslint-disable class-methods-use-this -- this render function does not currently use 'this'*/
	render() {
		return (
			<RegistrationLayout>
				<Switch>
					<Page
						component={RegistrationIndex}
						exact={true}
						path={`/:whiteLabelSlug?${paths.register.root}`}
						title={translate(
							'registration.page_title.registration'
						)}
					/>
					<Page
						component={RegisterAgent}
						exact={true}
						path={`/:whiteLabelSlug?${paths.register.agent}`}
						title={translate('registration.page_title.agent')}
					/>

					{this.props.settings.features.get(
						'agent:pricing_while_registration'
					) && (
						<Page
							component={AgentPlans}
							exact={true}
							path={`/:whiteLabelSlug?${paths.register.agentPricing}`}
							title={translate('registration.page_title.agent')}
						/>
					)}
					<Page
						component={RegisterHomeowner}
						exact={true}
						path={`/:whiteLabelSlug?${paths.register.homeowner}`}
						title={translate('registration.page_title.homeowner')}
					/>
					<Page
						component={RegisterManager}
						exact={true}
						path={`/:whiteLabelSlug?${paths.register.manager}`}
						title={translate('registration.page_title.manager')}
					/>
				</Switch>
			</RegistrationLayout>
		);
	}
	/* eslint-enable class-methods-use-this */
}

export default withTheme(withSettings(Registration));
