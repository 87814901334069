const apiPort = process.env.REACT_APP_API_PORT;
const apiSegment = 'api';
const apiVersion = 'v1';
const { hostname, protocol } = window.location;
const rootUrl = `${protocol}//${hostname}${apiPort ? `:${apiPort}` : ''}`;
const baseApiUrl = `${rootUrl}/${apiSegment}/${apiVersion}`;
const baseAuthUrl = `${baseApiUrl}/auth`;
const baseAgentsUrl = `${baseApiUrl}/agents`;
const baseAppliancesUrl = `${baseApiUrl}/appliances`;
const baseExportsUrl = `${baseApiUrl}/exports`;
const baseMeUrl = `${baseApiUrl}/me`;
/** @deprecated */
const baseMeHomeownerUrl = `${baseMeUrl}/homeowner`;
const baseMeHomeownerPropertiesUrl = `${baseMeHomeownerUrl}/properties`;
const baseMeTasksUrl = `${baseMeUrl}/tasks`;
/** @deprecated */
const baseGroupUrl = `${baseApiUrl}/group`;
const baseGroupsUrl = `${baseApiUrl}/groups`;
/** @deprecated */
const baseOfferUrlLegacy = `${baseApiUrl}/offer`;
const baseOffersUrl = `${baseApiUrl}/offers`;
/** @deprecated */
const basePropertyUrl = `${baseApiUrl}/property`;
const basePropertiesUrl = `${baseApiUrl}/properties`;
const baseUserUrl = `${baseApiUrl}/user`;
/** @deprecated */
const baseBlueHammer = 'https://bluehammer.com/directaccess/setup/';
/** @deprecated */
const baseAdministratorUrl = `${baseApiUrl}/administrator`;
const baseAdministratorsUrl = `${baseApiUrl}/administrators`;
const baseRegisterUrl = `${baseApiUrl}/register`;
const baseAddressUrl = `${baseApiUrl}/address`;
const baseSettingsUrl = `${baseApiUrl}/settings`;

export default {
	address: {
		validate: `${baseAddressUrl}/validate`,
	},
	/** @deprecated */
	administrator: {
		add: `${baseAdministratorUrl}/add`,
		all: `${baseAdministratorUrl}/administrators`,
		// WARNING: Not in API!
		allowedGroups: `${baseAdministratorUrl}/allowed-groups`,
		// WARNING: Not in API!
		childGroups: `${baseAdministratorUrl}/top-groups`,
		edit: `${baseAdministratorUrl}/update`,
		root: baseAdministratorUrl,
		// WARNING: Not in API!
		topGroups: `${baseAdministratorUrl}/top-groups`,
	},
	administrators: {
		add: `${baseAdministratorsUrl}`,
		contacts: `${baseAdministratorsUrl}/contacts`,
		edit: `${baseAdministratorsUrl}`,
	},
	// TODO: Remove, legacy routes
	/** @deprecated */
	agent: {
		getInfo: `${baseApiUrl}/agent/get-info`,
	},
	agents: {
		agentsUpgradedCount: `${baseAgentsUrl}/count/subscribed`,
		checkSubscription: `${baseAgentsUrl}/subscription/:userId/status`,
		contactCount: `${baseAgentsUrl}/:agentId/contacts/count`,
		contactsTabs: `${baseAgentsUrl}/contacts/tabs`,
		getSubscription: `${baseAgentsUrl}/subscription`,
		groupContacts: `${baseAgentsUrl}/groups/:groupId/contacts`,
		import: `${baseAgentsUrl}/import`,
		resendInvite: `${baseAgentsUrl}/resend-invite`,
		root: baseAgentsUrl,
		updateContactEmailCommunicationPreferences: `${baseMeUrl}/settings/communication/contacts/email`,
	},
	// TODO: Remove, legacy routes
	appliance: {
		get: baseAppliancesUrl,
		root: baseAppliancesUrl,
		update: baseAppliancesUrl,
	},
	appliances: {
		add: '/appliances',
		root: `${basePropertiesUrl}`,
		view: '/appliances',
	},
	auth: {
		confirmEmail: `${baseAuthUrl}/confirm-email`,
		refreshSignIn: `${baseAuthUrl}/refresh-session`,
		resetPassword: `${baseUserUrl}/reset-password`,
		signIn: `${baseAuthUrl}/login`,
		signInWithSso: `${baseAuthUrl}/sso`,
		signUp: `${baseAuthUrl}/register`,
	},
	/** @deprecated */
	bluehammer: {
		root: baseBlueHammer,
	},
	// TODO: Do the view and update URLS here work?
	contacts: {
		add: `${baseApiUrl}/contacts`,
		import: `${baseApiUrl}/contacts/import`,
		invite: `${baseApiUrl}/contacts/invite`,
		inviteBulk: `${baseApiUrl}/contacts/bulk/invite`,
		remove: `${baseApiUrl}/contacts/remove`,
		update: `${baseApiUrl}/contacts`,
		view: `${baseApiUrl}/contacts`,
	},
	exports: {
		create: `${baseExportsUrl}`,
		pending: `${baseExportsUrl}/pending`,
	},

	/** @deprecated */
	group: {
		getAgentsGroups: `${baseGroupUrl}/agents/groups`,
		getContacts: `${baseGroupUrl}/contacts`,
		root: baseGroupUrl,
	},
	groups: {
		root: baseGroupsUrl,
	},
	homeowner: {
		getInfo: `${baseApiUrl}/homeowner/get`,
		postActivityPoints: `${baseApiUrl}/homeOwner/activity`,
		root: `${baseApiUrl}/homeowner`,
	},
	homeowners: {
		team: `${baseApiUrl}/homeowners/team`,
	},
	me: {
		administrator: {
			agents: `${baseMeUrl}/administrator/agents`,
			contacts: `${baseMeUrl}/administrator/contacts`,
			homeowners: `${baseMeUrl}/administrator/homeowners`,
			root: `${baseMeUrl}/administrator`,
		},
		administrators: `${baseMeUrl}/administrators`,
		/** @deprecated */
		agent: `${baseMeUrl}/agent`,
		agents: `${baseMeUrl}/agents`,
		contacts: `${baseMeUrl}/contacts`,
		email: `${baseMeUrl}/email`,
		homeowner: {
			/** @deprecated */
			agent: `${baseMeHomeownerUrl}/agent`,
			documents: {
				/** @deprecated */
				download: `${baseMeHomeownerUrl}/document`,
				/** @deprecated */
				save: `${baseMeHomeownerUrl}/documents`,
				/** @deprecated */
				view: `${baseMeHomeownerUrl}/documents`,
			},
			/** @deprecated */
			properties: {
				/** @deprecated */
				primary: `${baseMeHomeownerPropertiesUrl}/primary`,
				/** @deprecated */
				root: baseMeHomeownerPropertiesUrl,
			},
			/** @deprecated */
			root: baseMeHomeownerUrl,
		},
		info: `${baseApiUrl}/user/user-info`,
		offers: {
			add: baseOffersUrl,
			addGroup: baseGroupsUrl,
			/** @deprecated */
			edit: baseOfferUrlLegacy,
			/** @deprecated */
			info: baseOfferUrlLegacy,
			view: baseAgentsUrl,
			viewDomain: baseAdministratorsUrl,
		},
		root: baseMeUrl,
		subscription: {
			/** @deprecated */
			cancel: `${baseMeUrl}/subscription/cancel`,
			checkout: `${baseMeUrl}/subscription/checkout`,
			manage: `${baseMeUrl}/subscription/manage`,
			/** @deprecated */
			renew: `${baseMeUrl}/subscription/renew`,
			root: `${baseMeUrl}/subscription`,
		},
		tasks: {
			moving: {
				next: `${baseMeTasksUrl}/moving/next`,
				root: `${baseMeTasksUrl}/moving`,
			},
			next: `${baseMeTasksUrl}/next`,
			onboarding: {
				root: `${baseMeTasksUrl}/onboarding`,
			},
			root: baseMeTasksUrl,
		},
		tenant: `${baseMeUrl}/tenant`,
		theme: {
			favicon: `${baseMeUrl}/theme/favicon`,
			logo: `${baseMeUrl}/theme/logo`,
			root: `${baseMeUrl}/theme`,
		},
		valuations: `${baseMeUrl}/valuations`,
	},
	offers: {
		root: baseOffersUrl,
	},
	properties: {
		avms: 'avms',
		cancelTransferProperty: 'transfer/cancel',
		comparables: 'comparables',
		confirmTransferProperty: 'transfer/confirm',
		declineTransferProperty: 'transfer/decline',
		mortgage: 'mortgage',
		priceHistory: 'price-history',
		projects: {
			token: `${basePropertiesUrl}/projects/token`,
		},
		root: basePropertiesUrl,
		setProperty: `${basePropertiesUrl}/set-property`,
		taxHistory: 'tax-history',
		transferProperty: 'transfer',
	},
	/** @deprecated */
	property: {
		'get-property': `${basePropertyUrl}/get-property`,
		root: basePropertyUrl,
	},
	register: {
		agent: `${baseRegisterUrl}/agent`,
		homeowner: `${baseRegisterUrl}/homeowner`,
		manager: `${baseRegisterUrl}/manager`,
		verifyHomeowner: `${baseRegisterUrl}/homeowner/verify`,
	},
	root: rootUrl,
	settings: {
		email: `${baseSettingsUrl}/notifications/email`,
		features: `${baseSettingsUrl}/features`,
		root: baseSettingsUrl,
	},
	subscription: {
		/** @deprecated */
		cancel: `${baseApiUrl}/subscription/cancel`,
		manage: `${baseApiUrl}/subscription/manage`,
		price: `${baseApiUrl}/subscription/price`,
		/** @deprecated */
		renew: `${baseApiUrl}/subscription/renew`,
		root: `${baseApiUrl}/subscription`,
	},
	tasks: {
		moving: `${baseApiUrl}/tasks/moving`,
		update: `${baseApiUrl}/tasks/update`,
	},
	theme: {
		favicon: `${baseApiUrl}/theme/favicon`,
		logo: `${baseApiUrl}/theme/logo`,
		root: `${baseApiUrl}/theme`,
	},
	themes: `${baseApiUrl}/themes`,
	user: {
		password: `${baseUserUrl}/password`,
		resetPassword: `${baseUserUrl}/:userId/reset-password`,
		setPassword: `${baseUserUrl}/set-password`,
	},
};
