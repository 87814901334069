/**
 * @typedef {string | Address | JSX.Element | null} DataValues
 * @typedef {{data : DataValues, index: string}} Data
 * @typedef {import('../../../App/shared/AddressService/Address').default} Address
 * @typedef {import('../../../shared/AgentContactService/AgentContact').default} AgentContact
 * @typedef {import('./ContactsTableProps').default} Props
 * @typedef {import('./ContactsTableState').default} State
 */

import { AuthContext } from '../../../shared/AuthProvider';
import { BulkOptions } from '../../shared/BulkOptions/BulkOptions';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../utils/toAbsoluteUrl';
import { translate } from '../../Internationalization';
import {
	withAgentContactService,
	withContactService,
} from '../../../service-container';
import { withSettings } from '../../../shared/SettingProvider/SettingProvider';
import BaseTable from '../../../shared/BaseTable';
import bulkOptions from '../../../config/local/bulk-options';
import BulkSelectButton from '../../../shared/BulkSelectButton/BulkSelectButton';
import captureError from '../../../utils/captureError';
import ContactsBulkOptionsPopup from '../../../shared/Popups/Contacts/ContactsBulkOptionsPopup';
import ExternalAnchor from '../../../shared/ExternalAnchor';
import FormErrorMessages from '../../../shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from '../../../shared/Forms/Messages/FormSuccessMessages';
import FormWarningMessages from '../../../shared/Forms/Messages/FormWarningMessages';
import images from '../../../config/local/images';
import LeadScore from '../../shared/LeadScore/LeadScore';
import LeadScoreModal from '../../shared/LeadScoreModal/LeadScoreModal';
import Loading from '../../../shared/Loading';
import Pagination from '../../../shared/Pagination';
import paths from '../../../config/local/paths';
import React from 'react';
import TableButton from '../../../shared/TableButton';

const flags = {
	leadScoreModal: 'agent:lead_score_history_modal',
	resendInvite: 'agent:resend_invite',
};

const headerData = [
	'name',
	'email',
	'phone',
	'address',
	'move_date',
	'mover_score',
	'groups',
	'last_logged_in',
	'edit',
];

/** @extends {React.Component<Props, State>} */
export class ContactsTable extends React.Component {
	static defaultProps = {
		isPaginated: true,
	};

	static bulkOptionList = [
		{
			label: translate('agent.pages.contacts.view.delete_all'),
			option: bulkOptions.delete,
		},
		{
			label: translate('global.pages.contacts.view.resend_invite'),
			option: bulkOptions.invite,
		},
	];

	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		/**
		 * @type {State}
		 */
		this.state = {
			bulkSelection: '',
			errorMessage: null,
			removeWarningMessage: '',
			resendInviteMessage: '',
			scoreHistory: {
				email: '',
				isOpen: false,
				leadScores: [],
				name: '',
			},
			selectedContacts: [],
			success: false,
		};

		this._handleBulkSubmit = this._handleBulkSubmit.bind(this);
		this._handleClose = this._handleClose.bind(this);
		this._handleModalClose = this._handleModalClose.bind(this);
		this._handleConfirmDelete = this._handleConfirmDelete.bind(this);
		this._handleConfirmInvite = this._handleConfirmInvite.bind(this);
		this._handleDeselectAll = this._handleDeselectAll.bind(this);
		this._handleResendInvite = this._handleResendInvite.bind(this);
		this._handleSelectAll = this._handleSelectAll.bind(this);
		this._onHandleOptionChange = this._onHandleOptionChange.bind(this);
	}

	/**
	 * @param {Props} prevProps
	 * @returns {Promise<void>}
	 */
	async componentDidUpdate(prevProps) {
		if (this.props.activeTab !== prevProps.activeTab) {
			this._handleDeselectAll();
		}
	}

	/**
	 * @param {string} feature
	 * @returns {boolean}
	 */
	#getFeatureFlag(feature) {
		return this.props.settings.features.get(feature) ?? false;
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleSelectAll() {
		const contactIdList = this.props.contactList.map(
			(contact) => contact.id
		);

		this.setState({
			selectedContacts: contactIdList,
		});
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleDeselectAll() {
		this.setState({ selectedContacts: [] });
	}

	/**
	 * @param {string} contactId
	 * @returns {void}
	 */
	#handleCheckBox(contactId) {
		this.setState((prevState) => {
			if (!prevState.selectedContacts.includes(contactId)) {
				return {
					selectedContacts: [
						...prevState.selectedContacts,
						contactId,
					],
				};
			}

			const filteredContacts = prevState.selectedContacts.filter(
				(id) => id !== contactId
			);

			return { selectedContacts: filteredContacts };
		});
	}

	/**
	 * @protected
	 * @param {string} contactId
	 * @returns {void}
	 */
	_handleDelete(contactId) {
		this.setState({
			bulkSelection: bulkOptions.delete,
			selectedContacts: [contactId],
		});
	}

	/**
	 * @protected
	 * @param {string} contactId
	 * @param {string} name
	 * @returns {Promise<void>}
	 */
	async _handleResendInvite(contactId, name) {
		try {
			await this.props.contactService.inviteContact(contactId);

			this.setState({
				resendInviteMessage: translate(
					'agent.pages.contacts.view.resend_invite_message',
					name
				),
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate(
					'agent.pages.contacts.view.resend_error'
				),
			});
		}
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleClose() {
		this.setState({ bulkSelection: '' });
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleModalClose() {
		this.setState({
			scoreHistory: {
				email: '',
				isOpen: false,
				leadScores: [],
				name: '',
			},
		});
	}

	/**
	 * @protected
	 * @returns {Promise<void>}
	 */
	async _handleConfirmDelete() {
		try {
			const response = await this.props.contactService.removeContact(
				this.state.selectedContacts
			);

			const responseMessage = translate(
				'agent.pages.contacts.view.success_message',
				response?.deleted,
				response?.total
			);

			this.setState({
				removeWarningMessage: responseMessage,
				selectedContacts: [],
			});

			this.props.resetTableAndTabs();
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate('global.error'),
				success: false,
			});
		}
		this.setState({ bulkSelection: '' });
	}

	/**
	 * @protected
	 * @returns {Promise<void>}
	 */
	async _handleConfirmInvite() {
		try {
			await this.props.contactService.bulkResendContactInvite(
				this.state.selectedContacts
			);

			const resendInviteMessage = translate(
				'global.pages.contacts.resend_invite_popup.success_message'
			);

			this.setState({
				bulkSelection: '',
				resendInviteMessage,
				selectedContacts: [],
				success: true,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				bulkSelection: '',
				errorMessage: translate('global.error'),
				success: false,
			});
		}
	}

	/**
	 * @protected
	 * @param {{label: string; option: string}} selection
	 * @returns {void}
	 */
	_onHandleOptionChange(selection) {
		this.setState({ bulkSelection: selection.option });
	}

	/**
	 * @returns {string}
	 */
	_findContactName() {
		if (this.state.selectedContacts?.length !== 1) {
			return '';
		}

		const contactInfo = this.props.contactList.find(
			(contact) => contact.id === this.state.selectedContacts[0]
		);

		return contactInfo?.name ?? '';
	}

	#getMoverScoreHeader() {
		if (this.context.agentSubscriptionLevels.length) {
			return translate('agent.pages.contacts.view.table.mover_score');
		}

		return (
			<div className="flex items-center">
				<Link
					className="w-3.5 mr-1"
					title="Click here to upgrade"
					to={paths.app.agent.subscription.root}
				>
					<img
						alt="Feature has been locked and requires a subscription"
						src={toAbsoluteUrl(images.icons.lock)}
					/>
				</Link>
				{translate('agent.pages.contacts.view.table.mover_score')}
			</div>
		);
	}

	/** @returns {{title : string | JSX.Element}[]} */
	#tableHeaderData() {
		/** @type {{title : string | JSX.Element}[]} */
		const headerDataList = [];

		headerData.forEach((header) => {
			if (this.props.activeTab !== 'allContacts' && header === 'groups') {
				return;
			}

			/** @type {string | JSX.Element} */
			let title = translate(`agent.pages.contacts.view.table.${header}`);

			if (header === 'name') {
				title = this.#getNameHeader();
			}

			if (header === 'mover_score') {
				title = this.#getMoverScoreHeader();
			}

			headerDataList.push({ title });
		});

		this.#updateHeaderDataList(headerDataList);

		return headerDataList;
	}

	/**
	 * @param {{title : string | JSX.Element}[]} dataList
	 * @returns {void}
	 */
	#updateHeaderDataList(dataList) {
		if (this.#getFeatureFlag(flags.leadScoreModal)) {
			dataList.push({
				title: translate(
					'agent.pages.contacts.view.table.score_history'
				),
			});
		}

		if (this.#getFeatureFlag(flags.resendInvite)) {
			dataList.push({
				title: translate(
					'agent.pages.contacts.view.table.resend_invite'
				),
			});
		}

		dataList.push({
			title: translate('agent.pages.contacts.view.table.delete'),
		});
	}

	/** @returns {JSX.Element} */
	#getNameHeader() {
		return (
			<BulkSelectButton
				handleDeSelectAll={() => this._handleDeselectAll()}
				handleSelectAll={() => this._handleSelectAll()}
			>
				{translate('agent.pages.contacts.view.table.name')}
			</BulkSelectButton>
		);
	}

	/** @returns {{data : string | Address | JSX.Element | null, index: string}[][]}  */
	#tableBodyData() {
		const bodyData = this.props.contactList.map((contact) => {
			const row = [
				this.#renderName(contact),
				ContactsTable.#tableCell(
					ContactsTable.#getAnchor('mailto', contact.email),
					contact.id
				),
				ContactsTable.#tableCell(
					ContactsTable.#getAnchor(
						'tel',
						contact.mobile || contact.landline
					),
					contact.id
				),
				ContactsTable.#tableCell(contact.address, contact.id),
				ContactsTable.#tableCell(contact.moveDate, contact.id),
				this.#renderSubscribed(contact),
				this.#renderGroupName(contact),
				ContactsTable.#tableCell(
					contact.lastLoggedInAt ? contact.lastLoggedInAt : '—',
					contact.id
				),
				ContactsTable.#tableCell(
					<TableButton
						defaultSize={true}
						icon={images.icons.edit}
						to={{
							pathname: `${paths.app.agent.contacts.edit}/${contact.id}`,
							state: {
								activeTab: this.props.activeTab,
							},
						}}
					/>,
					contact.id
				),
				ContactsTable.#tableCell(
					this.#scoreHistoryButton(contact),
					contact.id
				),
				ContactsTable.#tableCell(
					this.#renderInvite(contact),
					contact.id
				),
				ContactsTable.#tableCell(
					<TableButton
						defaultSize={true}
						disabled={contact.acceptedInvite}
						handleClick={() => this._handleDelete(contact.id)}
						icon={images.icons.trash}
					/>,
					contact.id
				),
			];

			return row.filter((cell) => cell && cell.data);
		});

		// @ts-ignore null values are filtered out above
		return bodyData;
	}

	/**
	 * @param {AgentContact} contact
	 * @returns {Data}
	 */
	#renderName(contact) {
		return {
			data: (
				<span className="datum-value">
					<span className="form-check form-check-sm">
						<input
							checked={this.state.selectedContacts.includes(
								contact.id
							)}
							id={`table-checkbox-${contact.id}`}
							onChange={() => this.#handleCheckBox(contact.id)}
							type="checkbox"
						/>
					</span>
					<label htmlFor={`table-checkbox-${contact.id}`}>
						<Link
							className="hover-color-primary no-underline text-black"
							to={{
								pathname: `${paths.app.agent.contacts.edit}/${contact.id}`,
								state: {
									activeTab: this.props.activeTab,
								},
							}}
						>
							{contact.name}
						</Link>
					</label>
				</span>
			),
			index: `contact-${contact.id}`,
		};
	}

	/**
	 * @param {AgentContact} contact
	 * @returns {boolean}
	 */
	#hasSubscription(contact) {
		let hasSubscription = false;
		const validLevels = ['agent', 'domain'];

		this.context.agentSubscriptionLevels.forEach(
			(/** @type {string} */ level) => {
				if (validLevels.includes(level)) {
					hasSubscription = true;
				}
			}
		);

		if (contact.group.isSubscribed) {
			hasSubscription = true;
		}

		return hasSubscription;
	}

	/**
	 * @param {AgentContact} contact
	 * @returns {Data}
	 */
	#renderSubscribed(contact) {
		if (this.#hasSubscription(contact)) {
			return {
				data: (
					<LeadScore
						className="h-ms-1"
						leadScore={contact.leadScore}
						leadScores={contact.leadScores}
					/>
				),
				index: `contact-${contact.id}`,
			};
		}

		return {
			data: (
				<Link
					className="no-underline flex items-center hover-lighter text-info"
					to={paths.app.agent.subscription.root}
				>
					{translate('agent.pages.contacts.view.score')}
				</Link>
			),
			index: `contact-${contact.id}`,
		};
	}

	/**
	 * @param {AgentContact} contact
	 * @returns {JSX.Element | null}
	 */
	#renderInvite(contact) {
		if (!this.#getFeatureFlag(flags.resendInvite)) {
			return null;
		}

		return (
			<TableButton
				defaultSize={true}
				disabled={contact.acceptedInvite}
				handleClick={() =>
					this._handleResendInvite(contact.id, contact.name)
				}
				icon={images.icons.email}
			/>
		);
	}

	/**
	 * @param {AgentContact} contact
	 * @returns {JSX.Element | null}
	 */
	#scoreHistoryButton(contact) {
		if (!this.#getFeatureFlag(flags.leadScoreModal)) {
			return null;
		}

		return (
			<TableButton
				defaultSize={true}
				disabled={
					!contact?.leadScores?.length ||
					!this.#hasSubscription(contact)
				}
				handleClick={() => this.#toggleScoreHistoryModal(contact)}
				icon={images.icons.scoreHistory}
			/>
		);
	}

	/**
	 * @param {AgentContact} contact
	 * @returns {void}
	 */
	#toggleScoreHistoryModal(contact) {
		this.setState({
			scoreHistory: {
				email: contact?.email ?? '',
				isOpen: true,
				leadScores: contact?.leadScores ?? [],
				name: contact?.name ?? null,
			},
		});
	}

	/** @returns {JSX.Element | null} */
	#renderScoreHistory() {
		if (
			!this.#getFeatureFlag(flags.leadScoreModal) ||
			!this.state?.scoreHistory?.isOpen
		) {
			return null;
		}

		return (
			<LeadScoreModal
				email={this.state?.scoreHistory?.email}
				leadScores={this.state?.scoreHistory?.leadScores}
				name={this.state?.scoreHistory?.name}
				onClose={this._handleModalClose}
			/>
		);
	}

	/**
	 * @param {AgentContact} contact
	 * @returns {Data | null}
	 */
	#renderGroupName(contact) {
		if (this.props.activeTab !== 'allContacts') {
			return null;
		}

		return ContactsTable.#tableCell(
			contact.group.name ??
				translate('agent.pages.contacts.view.table.my_contacts'),
			contact.id
		);
	}

	/**
	 * @param {'tel' | 'mailto'} ref
	 * @param {string | null} data
	 * @returns {JSX.Element}
	 */
	static #getAnchor(ref, data) {
		return (
			<ExternalAnchor
				className="hover-color-primary no-underline text-black"
				href={`${ref}:${data}`}
			>
				{data}
			</ExternalAnchor>
		);
	}

	/**
	 * @param {DataValues} data
	 * @param {string} id
	 * @returns {Data}
	 */
	static #tableCell(data, id) {
		return {
			data,
			index: `contact-${id}`,
		};
	}

	/**
	 * @protected
	 * @returns {Promise<void>}
	 */
	async _handleBulkSubmit() {
		if (this.state.bulkSelection === bulkOptions.delete) {
			await this._handleConfirmDelete();
			this.setState({ bulkSelection: '' });
		}
		if (this.state.bulkSelection === bulkOptions.invite) {
			await this._handleConfirmInvite();
			this.setState({ bulkSelection: '' });
		}
	}

	#renderTable() {
		if (this.props.isGettingData) {
			return <Loading />;
		}

		return (
			<BaseTable
				bodyData={this.#tableBodyData()}
				errorMessage={
					this.state.errorMessage ??
					translate('admin.pages.contacts.view.error')
				}
				headerData={this.#tableHeaderData()}
			/>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<React.Fragment>
				{this.#renderScoreHistory()}
				<ContactsBulkOptionsPopup
					bulkSelection={this.state.bulkSelection}
					contactName={this._findContactName()}
					onClose={this._handleClose}
					onSubmit={this._handleBulkSubmit}
					selectedContacts={this.state.selectedContacts}
				/>

				<FormSuccessMessages
					messages={this.state.resendInviteMessage}
				/>

				{this.state.success ? (
					<FormSuccessMessages
						messages={translate(
							'agent.pages.contacts.view.success'
						)}
					/>
				) : null}

				<FormWarningMessages
					heading={translate(
						'agent.pages.contacts.view.success_header'
					)}
					messages={this.state.removeWarningMessage}
				/>

				<FormErrorMessages messages={this.state.errorMessage} />

				<section className="md:flex justify-between items-center mb-6 sm:mb-0">
					<div>
						<BulkOptions
							bulkOptionList={ContactsTable.bulkOptionList}
							handleOptionChange={this._onHandleOptionChange}
						/>
					</div>

					{this.props.filters}
				</section>

				{this.#renderTable()}

				<BulkOptions
					bulkOptionList={ContactsTable.bulkOptionList}
					className="ml-0 mr-auto w-fit"
					handleOptionChange={this._onHandleOptionChange}
				/>

				{!!this.props.isPaginated && (
					<Pagination
						currentPageNumber={this.props.currentPageNumber}
						lastPageNumber={this.props.lastPageNumber}
						setCurrentPageNumber={this.props.setCurrentPageNumber}
					/>
				)}
			</React.Fragment>
		);
	}
}

ContactsTable.contextType = AuthContext;

export default withContactService(
	// @ts-ignore will be fixed with service-container
	withAgentContactService(withSettings(ContactsTable))
);
