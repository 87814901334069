import InputSelect from 'shared/Forms/Inputs/InputSelect';
import React from 'react';
import SelectOptionList from 'shared/Forms/Inputs/SelectOptionList';
import stateList from '@mooveguru/yhh-shared-config/states.json';

export default class InputState extends React.Component {
	render() {
		return (
			<InputSelect
				autocomplete="country"
				className={this.props.className}
				disabled={this.props.disabled}
				name={this.props.name}
				noDefaultOption={true}
				required={this.props.required}
				type="text"
			>
				<SelectOptionList values={stateList} />
			</InputSelect>
		);
	}
}

InputState.defaultProps = {
	className: '',
	disabled: false,
	name: 'state',
	required: false,
};
