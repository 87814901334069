import FormSuccessMessages from './FormSuccessMessages';
import FormWarningMessages from './FormWarningMessages';
import React from 'react';

export default class SuccessOrWarningMessage extends React.Component {
	render() {
		return this.props.warning ? (
			<FormWarningMessages
				heading={this.props.heading}
				messages={this.props.messages}
			/>
		) : (
			<FormSuccessMessages
				heading={this.props.heading}
				messages={this.props.messages}
			/>
		);
	}
}
