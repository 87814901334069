import FormField from './FormField';
import InputTextarea from '../Inputs/InputTextarea';
import React from 'react';

export default class FieldTextarea extends React.Component {
	render() {
		return (
			<FormField
				className={this.props.className}
				help={this.props.help}
				inputName={this.props.id ?? this.props.name}
				label={this.props.label}
				labelClass={this.props.labelClass}
				required={this.props.required}
			>
				<InputTextarea
					autoComplete={this.props.autocomplete}
					defaultValue={this.props.defaultValue}
					disabled={this.props.disabled}
					id={this.props.id ?? this.props.name}
					inputClass={this.props.inputClass}
					inputPaddingSize={this.props.inputPaddingSize}
					name={this.props.name ?? this.props.id}
					onBlur={this.props.onBlur}
					onChange={this.props.onChange}
					placeholder={this.props.placeholder}
					required={this.props.required}
					value={this.props.value}
				/>
			</FormField>
		);
	}
}

FieldTextarea.defaultProps = {
	disabled: false,
	required: false,
};
