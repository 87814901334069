import { translate } from 'App/Internationalization';
import FormField from 'shared/Forms/Fields/FormField';
import InputWebsite from 'shared/Forms/Inputs/InputWebsite';
import React from 'react';

export default class FieldWebsite extends React.Component {
	render() {
		return (
			<FormField
				className={this.props.className}
				help={this.props.help}
				inputName={this.props.name}
				label={this.props.label}
				labelClass={this.props.labelClass}
				name={this.props.name}
				required={this.props.required}
			>
				<InputWebsite
					className={this.props.inputClass}
					copyToClipboard={this.props.copyToClipboard}
					disabled={this.props.disabled}
					name={this.props.name}
					openInNewTab={this.props.openInNewTab}
					placeholder={this.props.placeholder}
					required={this.props.required}
					type="url"
				/>
			</FormField>
		);
	}
}

FieldWebsite.defaultProps = {
	className: InputWebsite.defaultProps.className,
	disabled: InputWebsite.defaultProps.disabled,
	help: translate('global.forms.help.website'),
	inputClass: InputWebsite.defaultProps.className,
	labelClass: FormField.defaultProps.labelClass,
	name: FormField.defaultProps.name,
	placeholder: FormField.defaultProps.placeholder,
	required: InputWebsite.defaultProps.required,
};
