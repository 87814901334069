import React from 'react';
import BaseChecklistProgress from './BaseChecklistProgress';
import { withChecklistService } from 'service-container';
class DashboardProgress extends React.Component {
	/**
	 * @param {React.ComponentProps<any>} props
	 */
	constructor(props) {
		super(props);

		this.state = {
			completedTasks: 0,
			title: '',
			totalTasks: 0,
		};
	}

	async componentDidMount() {
		const progress =
			await this.props.checklistService.getActiveChecklistProgress();

		if (!progress) {
			return;
		}

		this.setState({
			completedTasks: progress.completed,
			title: progress.title,
			totalTasks: progress.total,
		});
	}

	render() {
		if (this.state.completedTasks === this.state.totalTasks) {
			return null;
		}

		return (
			<BaseChecklistProgress
				className={this.props.className}
				completedTasks={this.state.completedTasks}
				title={this.state.title}
				totalTasks={this.state.totalTasks}
			/>
		);
	}
}

export default withChecklistService(DashboardProgress);
