/**
 * @typedef {import('@mooveguru/js-utilities/types/KeyedObject').default} KeyedObject
 */

/**
 * @template {KeyedObject} Type
 * @param {Type} object
 * @returns {{ label: string, value: string }[]}
 */
export default function separateObjectIntoArray(object) {
	return Object.entries(object).map(([label, value]) => ({
		label: value,
		value: label,
	}));
}
