/**
 * @typedef {import('./AdminContact').default} AdminContact
 * @typedef {import('../../../config/local/Import/Data').default<'contact'>} ImportData
 * @typedef {import('../../Agent/agent-service').Contact} Contact
 * @typedef {import('../../Agent/Contacts/ContactProps').Source} Source
 * @typedef {import('../Query/Parameters').default} QueryParameters
 * @typedef {import('./BulkResendsContactInvite').default} BulkResendsContactInvite
 * @typedef {import('./CreatesContact').default} CreatesContact
 * @typedef {import('./Dependencies').default} Dependencies
 * @typedef {import('./GetContactsResponse').default} GetContactsResponse
 * @typedef {import('./GetsContactCount').default} GetsContactCount
 * @typedef {import('./GetsContacts').default} GetsContacts
 * @typedef {import('./ImportsContacts').default} ImportsContacts
 * @typedef {import('./InvitesContact').default} InvitesContact
 * @typedef {import('./RemoveContactResponse').default} RemoveContactResponse
 * @typedef {import('./RemovesContact').default} RemovesContact
 */

import { mapContactToRequest } from '../../Agent/agent-service';
import { prepareQueryForUrl } from '../Query';
import apiUrls from '../../../config/local/api-urls';
import ServiceError from '../../../shared/Errors/ServiceError';

/**
 * @implements {BulkResendsContactInvite}
 * @implements {CreatesContact}
 * @implements {GetsContactCount}
 * @implements {GetsContacts}
 * @implements {ImportsContacts}
 * @implements {InvitesContact}
 * @implements {RemovesContact}
 */
export default class ContactService {
	/** @param {Dependencies} dependencies */
	constructor(dependencies) {
		this.authService = dependencies.authService;
		this.httpClient = dependencies.httpClient;
	}

	/**
	 * @returns {Promise<number>}
	 */
	async getContactCount() {
		const response = await this.httpClient.get(
			`${apiUrls.me.administrator.contacts}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		return response.body.results.total;
	}

	/**
	 * Query param can accept a page number so as to not break legacy calls
	 * @param {number | QueryParameters} [query]
	 * @returns {Promise<GetContactsResponse>}
	 */
	async getContacts(query = 1) {
		if (typeof query === 'number') {
			/** @type {QueryParameters} */
			query = { limit: 50, page: query };
		}

		if (!query.limit) {
			query = {
				...query,
				limit: 50,
			};
		}

		const params = prepareQueryForUrl(query, true);

		const response = await this.httpClient.get(
			apiUrls.administrators.contacts + params,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const data = response.body.contacts?.map(ContactService.#mapContacts);

		return {
			data,
			enrolledContacts: response.body.enrolled_contacts ?? 0,
			pages: response.body.pages ?? 1,
			total: response.body.total ?? data?.length ?? 0,
		};
	}

	/**
	 * @param {any} contact
	 * @returns {AdminContact}
	 */
	static #mapContacts(contact) {
		return {
			acceptedInvite: contact?.accepted_invite,
			agent: ContactService.#mapAgent(contact?.agent),
			deletedAt: contact?.deleted_at,
			email: contact?.email,
			group: {
				id: contact?.group_id,
				name: contact?.group_name,
			},
			hasSubscription: contact?.has_subscription || false,
			homeownerId: contact?.homeowner_id,
			id: contact.id,
			isOwner: contact?.is_owner,
			languagePreference: contact?.language_preference,
			leadScore: contact?.lead_score,
			leadScores: contact?.lead_scores,
			name: contact?.name,
			phone: {
				landline: contact?.phone_numbers?.find(
					/**
					 * @param {{type: string}} phoneNumber
					 * @returns {boolean}
					 */
					(phoneNumber) =>
						['landline', 'home']?.includes(phoneNumber.type)
				)?.value,
				mobile: contact?.phone_numbers?.find(
					/**
					 * @param {{type:string}} phoneNumber
					 * @returns {boolean}
					 */
					(phoneNumber) => phoneNumber.type === 'mobile'
				)?.value,
			},
			salutation: contact?.agent?.salutation,
			source: contact?.source,
			subscribedEmail: contact?.subscribed_email,
			subscribedPhone: contact?.subscribed_phone,
		};
	}

	/**
	 * @param {any} agent
	 * @returns {AdminContact['agent']}
	 */
	static #mapAgent(agent) {
		return {
			email: agent?.email,
			groups: agent?.groups.length
				? agent?.groups?.map((group) => ({
						id: group?.id,
						isPrimary: group?.is_primary,
						name: group?.name,
				  }))
				: [],
			id: agent?.id,
			name: agent?.name,
			phone: {
				landline: agent?.phone.landline,
				mobile: agent?.phone.mobile,
			},
			slug: agent?.slug,
			socialMedia: {
				facebook: agent?.social_media.facebook,
				twitter: agent?.social_media.twitter,
			},
			website: agent?.website,
		};
	}

	/**
	 * @param {{data: ImportData, file: File}} importData
	 * @param {Source} source
	 * @param {string} [agentId]
	 * @param {string} [groupId]
	 * @param {boolean} sendInvites
	 */
	async importContacts(
		importData,
		source,
		agentId,
		groupId,
		sendInvites = true
	) {
		const endpoint =
			agentId !== undefined
				? `${apiUrls.agents.root}/${agentId}/contacts/import/`
				: apiUrls.contacts.import;

		const formData = new FormData();

		formData.append('file', importData.file);

		const { invalidData: invalid, validData: valid } = importData.data;
		const data = {
			invalid,
			valid,
		};

		formData.append('contacts', JSON.stringify(data));
		formData.append('source', source);

		if (groupId) {
			formData.append('group_id', groupId);
		}

		const response = await this.httpClient.post(
			`${endpoint}?sendInvites=${sendInvites}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			formData
		);

		if (!response.isOk) {
			throw new ServiceError(
				'Something went wrong while trying to import your contacts.',
				response.body
			);
		}
	}

	// TODO Resolve issues with creating contact - likely an API issue
	/**
	 * @param {string} accessToken
	 * @param {Contact} values
	 * @param {Source} source
	 * @param {boolean} [sendInvite]
	 * @returns {Promise<void>}
	 */
	async createContact(accessToken, values, source, sendInvite = true) {
		const response = await this.httpClient.post(
			`${apiUrls.contacts.add}?sendInvite=${sendInvite}`,
			new Headers({
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			}),

			mapContactToRequest(values, source)
		);

		if (!response.isOk) {
			const error = new Error(response.body);
			// @ts-ignore adding statusCode to error object
			error.statusCode = response.statusCode;
			throw error;
		}
	}

	// TODO Resolve issues with creating contact - likely an API issue
	/**
	 * @param {string} id
	 * @param {string} accessToken
	 * @param {Contact} data
	 */
	async updateContact(id, accessToken, data) {
		const response = await this.httpClient.put(
			`${apiUrls.contacts.update}/${id}`,
			new Headers({
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			}),
			mapContactToRequest(data, data.source)
		);

		if (!response.isOk) {
			const error = new Error(response.body);
			// @ts-ignore adding statusCode to error object
			error.statusCode = response.statusCode;
			throw error;
		}
	}

	/**
	 * Invite contact by id
	 * @param {string} id
	 * @returns {Promise<void>}
	 */
	async inviteContact(id) {
		const response = await this.httpClient.post(
			`${apiUrls.contacts.invite}/${id}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.join(''));
		}
	}

	/**
	 * @param {string[]} idList
	 * @returns {Promise<void>}
	 */
	async bulkResendContactInvite(idList) {
		const response = await this.httpClient.post(
			apiUrls.contacts.inviteBulk,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			{ idList }
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}
	}

	/**
	 * @param {string[]} data
	 * @returns {Promise<RemoveContactResponse>}
	 */
	async removeContact(data) {
		const response = await this.httpClient.post(
			apiUrls.contacts.remove,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			{ contactId: data }
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const results = response.body;

		return {
			deleted: results.deleted,
			deletedIds: results.deleted_ids,
			total: results.total,
		};
	}
}
