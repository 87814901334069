import { Field } from 'formik';
import React from 'react';

export default class InputTextarea extends React.Component {
	render() {
		return (
			<Field name={this.props.name}>
				{(renderProps) => (
					<textarea
						autoComplete={this.props.autocomplete}
						className={`${this.props.className} input`.trim()}
						defaultValue={renderProps.field.value ?? null}
						disabled={this.props.disabled}
						id={this.props.id ?? this.props.name}
						name={renderProps.field.name}
						onBlur={
							this.props.onBlur
								? (event) => this.props.onBlur(event, renderProps.form) // prettier-ignore
								: renderProps.field.onBlur
						}
						onChange={
							this.props.onChange
								? (event) => this.props.onChange(event, renderProps.form) // prettier-ignore
								: renderProps.field.onChange
						}
						placeholder={this.props.placeholder}
						required={this.props.required}
					/>
				)}
			</Field>
		);
	}
}

InputTextarea.defaultProps = {
	className: '',
	disabled: false,
	required: false,
};
