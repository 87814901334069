import { translate } from 'App/Internationalization';
import BaseInput from 'shared/Forms/Inputs/BaseInput';
import formatPhoneNumberForDisplay from 'utils/formatPhoneNumberForDisplay';
import React from 'react';

/**
 * @template Values
 * @typedef {import('formik').FormikProps<Values>} FormikProps
 */
/** @typedef {import('shared/Forms/Inputs/PhoneNumber/InputPhoneNumber/Props').default} Props */

/** @extends {React.Component<Props>} */
export default class InputPhoneNumber extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);
		this.handleBlur = this.handleBlur.bind(this);
	}

	/**
	 * @template Values
	 * @param {React.FocusEvent<HTMLInputElement>} event
	 * @param {FormikProps<Values>} formikProps
	 */
	handleBlur(event, formikProps) {
		const { name, value } = event.target;
		formikProps.setFieldValue(name, formatPhoneNumberForDisplay(value));
		formikProps.handleBlur(event);
	}

	render() {
		const placeholder = translate(
			`global.forms.inputs.phone.${this.props.type}.placeholder`
		);

		return (
			<BaseInput
				autocomplete={this.props.autocomplete}
				className={this.props.className}
				disabled={this.props.disabled}
				formatValue={formatPhoneNumberForDisplay}
				name={this.props.name}
				onBlur={this.handleBlur}
				placeholder={placeholder}
				required={this.props.required}
				type="tel"
				value={this.props.value}
			/>
		);
	}
}

InputPhoneNumber.defaultProps = {
	autocomplete: 'tel-national',
	className: '',
	disabled: false,
	name: 'phoneNumber',
	required: false,
	type: 'phone',
	value: '',
};
