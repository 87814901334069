import BaseInput from 'shared/Forms/Inputs/BaseInput';
import React from 'react';

export default class InputSearch extends React.Component {
	render() {
		return (
			<BaseInput
				autocomplete={this.props.autocomplete}
				className={this.props.className}
				name={this.props.name}
				onChange={this.props.onChange}
				placeholder={this.props.placeholder}
				required={this.props.required}
				type="search"
			/>
		);
	}
}

InputSearch.defaultProps = {
	className: '',
	name: 'search',
	required: false,
};
