import { translate } from 'App/Internationalization';
import FormField from 'shared/Forms/Fields/FormField';
import InputAddressDesignation from 'shared/Forms/Inputs/Address/InputAddressDesignation';
import React from 'react';

export default class FieldAddressDesignation extends React.Component {
	render() {
		const label = translate(
			'global.forms.inputs.address.street_address_2.label'
		);

		return (
			<FormField
				className={this.props.className}
				inputName={this.props.name}
				labelClass={this.props.labelClass}
				label={this.props.label ?? label}
				name={this.props.name}
				required={this.props.required}
			>
				<InputAddressDesignation
					className={this.props.inputClass}
					disabled={this.props.disabled}
					name={this.props.name}
					required={this.props.required}
				/>
			</FormField>
		);
	}
}

FieldAddressDesignation.defaultProps = {
	className: InputAddressDesignation.defaultProps.className,
	disabled: InputAddressDesignation.defaultProps.disabled,
	inputClass: InputAddressDesignation.defaultProps.className,
	labelClass: FormField.defaultProps.labelClass,
	name: InputAddressDesignation.defaultProps.name,
	required: InputAddressDesignation.defaultProps.required,
};
