const { hostname, protocol } = window.location;
export const rootUrl = `${protocol}//${hostname}`;

const appRoot = '/app';
export const authRoot = '/auth';
const registerRoot = '/register';

const adminRoleRoot = `${appRoot}/admin`;
const agentRoleRoot = `${appRoot}/agent`;
const homeownerRoleRoot = `${appRoot}/homeowner`;
const profileRoot = `${appRoot}/profile`;

const adminRoot = '/admins';
const contactsRoot = '/contacts';
const offersRoot = '/offers';
const widgetsRoot = '/widgets';
const adminOffersRoot = offersRoot;

const agentRoot = '/agent';
const homeownerRoot = '/homeowner';
const integrationsRoot = '/integrations';
const agentOffersRoot = agentRoot + offersRoot;

const legacyOfficesRoot = `${adminRoleRoot}/offices`;
const groupsRoot = `${adminRoleRoot}/groups`;
const featuresRoot = `${adminRoleRoot}/features`;

const applianceCenterRoot = `${homeownerRoleRoot}/appliances`;
const propertiesRoot = `${homeownerRoleRoot}/properties`;

export const confirmEmailSegment = 'confirm-email';
export const resetPasswordSegment = 'reset-password';
export const setPasswordSegment = 'set-password';
export const signInSegment = 'sign-in';
export const signUpSegment = 'sign-up';
export const transferPropertySegment = 'transfer-property';
export const acceptTransferPropertySegment = 'transfer-property';
const ssoSegment = 'sso';
const subscriptionSegment = 'subscription';
export const termsOfUseSegment = 'terms-of-use';

export default {
	app: {
		admin: {
			admins: {
				add: `${adminRoleRoot}${adminRoot}/add`,
				edit: `${adminRoleRoot}${adminRoot}/edit`,
				root: `${adminRoleRoot}${adminRoot}`,
				view: `${adminRoleRoot}${adminRoot}/view`,
			},
			agents: {
				create: `${adminRoleRoot}${agentRoot}/create`,
				edit: `${adminRoleRoot}${agentRoot}/edit`,
				import: `${adminRoleRoot}${agentRoot}/import`,
				root: `${adminRoleRoot}${agentRoot}`,
				view: `${adminRoleRoot}${agentRoot}/view`,
			},
			contacts: {
				add: `${adminRoleRoot}${contactsRoot}/add`,
				edit: `${adminRoleRoot}${contactsRoot}/edit`,
				root: `${adminRoleRoot}${contactsRoot}`,
				view: `${adminRoleRoot}${contactsRoot}/view`,
			},
			dashboard: `${adminRoleRoot}/dashboard`,
			features: {
				add: `${featuresRoot}/add`,
				edit: `${featuresRoot}/edit`,
				root: `${featuresRoot}`,
				view: `${featuresRoot}/view`,
			},
			groups: {
				add: `${groupsRoot}/add`,
				edit: `${groupsRoot}/edit`,
				root: `${groupsRoot}`,
				view: `${groupsRoot}/view`,
			},
			homeowners: {
				add: `${adminRoleRoot}${homeownerRoot}/add`,
				edit: `${adminRoleRoot}${homeownerRoot}/edit`,
				root: `${adminRoleRoot}${homeownerRoot}`,
				view: `${adminRoleRoot}${homeownerRoot}/view`,
			},
			legacy: {
				offices: {
					add: `${legacyOfficesRoot}/add`,
					edit: `${legacyOfficesRoot}/edit`,
					root: `${legacyOfficesRoot}`,
					view: `${legacyOfficesRoot}/view`,
				},
			},
			offers: {
				add: `${adminRoleRoot}${adminOffersRoot}/add`,
				edit: `${adminRoleRoot}${adminOffersRoot}/edit`,
				groups: `${adminRoleRoot}${adminOffersRoot}/groups`,
				root: adminRoleRoot + adminOffersRoot,
				view: `${adminRoleRoot}${adminOffersRoot}/view`,
			},
			root: adminRoleRoot,
			theme: `${adminRoleRoot}/theme`,
		},
		agent: {
			connections: {
				root: `${agentRoleRoot}/connections`,
			},
			contacts: {
				add: `${agentRoleRoot}${contactsRoot}/add`,
				edit: `${agentRoleRoot}${contactsRoot}/edit`,
				import: `${agentRoleRoot}${contactsRoot}/import`,
				root: `${agentRoleRoot}${contactsRoot}`,
			},
			dashboard: `${agentRoleRoot}/dashboard`,
			integrations: {
				add: `${agentRoleRoot}${integrationsRoot}/add`,
				edit: `${agentRoleRoot}${integrationsRoot}/edit`,
				root: `${agentRoleRoot}${integrationsRoot}`,
				view: `${agentRoleRoot}${integrationsRoot}/view`,
			},
			offers: {
				add: `${agentRoleRoot}${agentOffersRoot}/add`,
				edit: `${agentRoleRoot}${agentOffersRoot}/edit`,
				root: agentRoleRoot + agentOffersRoot,
				view: `${agentRoleRoot}${agentOffersRoot}/view`,
			},
			root: agentRoleRoot,
			subscription: {
				root: `${agentRoleRoot}/${subscriptionSegment}`,
			},
			theme: `${agentRoleRoot}/theme`,
			users: {
				homeowners: {
					add: `${agentRoleRoot}${homeownerRoot}/add`,
					edit: `${agentRoleRoot}${homeownerRoot}/edit`,
					root: `${agentRoleRoot}${homeownerRoot}`,
					view: `${agentRoleRoot}${homeownerRoot}/view`,
				},
			},
			widgets: {
				root: `${agentRoleRoot}${widgetsRoot}`,
			},
		},
		homeowner: {
			applianceCenter: {
				add: `${applianceCenterRoot}/add`,
				edit: `${applianceCenterRoot}/edit`,
				manuals: `${applianceCenterRoot}/manuals`,
				root: applianceCenterRoot,
				view: `${applianceCenterRoot}/view`,
			},
			blog: `${homeownerRoleRoot}/blog`,
			checklists: `${homeownerRoleRoot}/checklists`,
			concierge: `${homeownerRoleRoot}/concierge`,
			dashboard: `${homeownerRoleRoot}/dashboard`,
			documents: {
				create: `${homeownerRoleRoot}/documents/upload`,
				root: `${homeownerRoleRoot}/documents`,
			},
			finances: `${homeownerRoleRoot}/finances`,
			inventory: `${homeownerRoleRoot}/inventory`,
			maintenance: `${homeownerRoleRoot}/maintenance`,
			marketplace: `${homeownerRoleRoot}/marketplace`,
			projects: `${homeownerRoleRoot}/projects`,
			properties: {
				acceptTransfer: `${propertiesRoot}/accept-transfer`,
				add: `${propertiesRoot}/add`,
				edit: `${propertiesRoot}/edit`,
				root: propertiesRoot,
				transfer: `${propertiesRoot}/transfer`,
				// TOOD: Change back to `${propertiesRoot}/validate` when validation is added back
				validate: propertiesRoot,
			},
			root: homeownerRoleRoot,
			upcomingItems: `${homeownerRoleRoot}/upcoming-items`,
			utilities: `${homeownerRoleRoot}/utilities`,
		},
		profile: {
			overview: `${profileRoot}/overview`,
			root: profileRoot,
			settings: `${profileRoot}/settings`,
		},
		root: appRoot,
		subscribe: `${appRoot}/subscribe`,
	},
	auth: {
		confirmEmail: `${authRoot}/${confirmEmailSegment}`,
		confirmEmailSlug: `/${confirmEmailSegment}`,
		resetPassword: `${authRoot}/${resetPasswordSegment}`,
		resetPasswordSlug: `/${resetPasswordSegment}`,
		root: authRoot,
		setPassword: `${authRoot}/${setPasswordSegment}`,
		setPasswordSlug: `/${setPasswordSegment}`,
		signIn: `${authRoot}/${signInSegment}`,
		signInSlug: `/${signInSegment}`,
		signUp: `${authRoot}/${signUpSegment}`,
		signUpSlug: `/${signUpSegment}`,
		ssoSlug: `/${ssoSegment}`,
		transferPropertySlug: `${authRoot}/${transferPropertySegment}`,
	},
	blog: '/blog',
	concierge:
		'https://outlook.office365.com/owa/calendar/MovingConcierge@NETORGFT2231367.onmicrosoft.com/bookings/s/7gD_30-xrEOlWU2SIpeONg2',
	declineTransferPropertySlug: '/decline-transfer-property',
	help: {
		homekeepr: 'https://help.homekeepr.com/homekeepr',
		moverIntel: 'https://help.homekeepr.com/mover-intelligence-score-mis',
	},
	marketplace: '/marketplace',
	notifications: {
		email: {
			subscription: 'notifications/email/subscription/:campaign',
		},
	},
	register: {
		agent: `${registerRoot}/agent`,
		agentPricing: `/agent/pricing`,
		agentSegment: 'agent',
		homeowner: `${registerRoot}/homeowner`,
		homeownerSegment: 'homeowner',
		manager: `${registerRoot}/manager`,
		managerSegment: 'manager',
		root: registerRoot,
	},
	root: '/',
	subscribe: '/subscribe',
	termsOfUse: `/${termsOfUseSegment}`,
};
