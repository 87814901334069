import { translate } from '../../../App/Internationalization';
import * as validators from '../../../shared/validators';
import * as yup from 'yup';
import adminOccupations from '@mooveguru/yhh-shared-config/occupation.json';
import AgreesToTermsOfUseLabelContent from '../../AgreesToTermsOfUseLabelContent';
import BaseField from '../../../shared/Forms/Fields/BaseField';
import BaseForm from '../../../shared/Forms/BaseForm';
import FieldCheckbox from '../../../shared/Forms/Fields/FieldCheckbox';
import FieldEmail from '../../../shared/Forms/Fields/FieldEmail';
import FieldPassword from '../../../shared/Forms/Fields/FieldPassword';
import FieldSelect from '../../../shared/Forms/Fields/FieldSelect';
import NameFields from '../../../shared/Forms/Fields/Name/NameFields';
import React from 'react';
import SelectOptionList from '../../../shared/Forms/Inputs/SelectOptionList';
import SubmitButton from '../../../shared/Forms/Inputs/SubmitButton';

/** @typedef {import('./Values').default} Values */
/** @typedef {import('./Props').default} Props */

/** @type {Values} */
const initialValues = {
	agreesToTermsOfUse: false,
	emailAddress: '',
	firstName: '',
	group: '',
	lastName: '',
	occupation: '',
	password: '',
	passwordConfirmation: '',
};

const validationSchema = yup.object().shape({
	emailAddress: validators.email,
	firstName: validators.firstName,
	group: yup.string().nullable(),
	lastName: validators.lastName,
	password: validators.password,
	passwordConfirmation: validators.matchConfirmation('password', 'Passwords'),
});

/** @extends {React.Component<Props>} */
export default class ManagerRegistrationForm extends React.Component {
	render() {
		return (
			<React.Fragment>
				<h1 className="hl-ms-6 mt-10 mb-2">
					{translate('registration.form.registration')}
				</h1>

				<section className="rich-text mb-6">
					<p> {translate('registration.form.agent_lead')}</p>
				</section>

				<BaseForm
					initialValues={initialValues}
					onSubmit={this.props.onSubmit}
					validationSchema={validationSchema}
				>
					<h2 className="h-ms-5 mb-2">
						{translate('registration.form.personal_details')}
					</h2>

					<section className="rich-text mb-6">
						<p>
							{translate(
								'registration.form.agent_personal_details_lead'
							)}
						</p>
					</section>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<NameFields required={true} />
					</fieldset>

					<h2 className="h-ms-5 mb-6">
						{translate('registration.form.group_details')}
					</h2>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<BaseField
							label={translate('registration.form.group.label')}
							name="group"
							placeholder={translate(
								'registration.form.group.placeholder'
							)}
							required={true}
							type="text"
						/>
						<FieldSelect
							label={translate(
								'homeowner.pages.dashboard.agent.occupation.label'
							)}
							name="occupation"
							noDefaultOption={true}
							required={true}
						>
							<SelectOptionList values={adminOccupations} />
						</FieldSelect>
					</fieldset>

					<h2 className="h-ms-5 mb-2">
						{translate('registration.form.account_details')}
					</h2>
					<section className="rich-text mb-6">
						<p>
							{translate(
								'registration.form.account_details_lead'
							)}
						</p>
					</section>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<FieldEmail
							className="col-start-1"
							name="emailAddress"
							required={true}
						/>

						<FieldPassword
							className="col-start-1"
							help={translate('global.forms.help.password')}
						/>

						<FieldPassword
							className="col-start-1"
							label={translate(
								'registration.form.confirm_password'
							)}
							name="passwordConfirmation"
						/>
					</fieldset>
					<fieldset className="mb-8">
						<FieldCheckbox
							className="mb-4"
							label={<AgreesToTermsOfUseLabelContent />}
							name="agreesToTermsOfUse"
							required={true}
							value={initialValues.agreesToTermsOfUse !== true}
						/>
					</fieldset>
					<footer className="mx-auto sm:ml-0 button-group w-min">
						<SubmitButton>
							{translate('registration.form.submit')}
						</SubmitButton>
					</footer>
				</BaseForm>
			</React.Fragment>
		);
	}
}
