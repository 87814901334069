/**
 * @typedef {import('../../Agent/Contacts/ContactProps').Contact} Contact
 * @typedef {import('./EditContactProps').default} Props
 * @typedef {import('./EditContactState').default} State
 */

import { AuthContext } from '../../../shared/AuthProvider';
import { ContactDeletePopup } from '../shared/ContactDeletePopup';
import {
	getAgentId,
	getContact,
	updateContact,
} from '../../Agent/agent-service';
import { Redirect } from 'react-router';
import { translate } from '../../Internationalization';
import { withContactService } from '../../../service-container';
import BaseButton from '../../../shared/BaseButton/BaseButton';
import captureError from '../../../utils/captureError';
import ContactForm from '../../Agent/Contacts/ContactForm';
import formatDateStringForDatepicker from '../../../utils/formatDateStringForDatepicker';
import images from '../../../config/local/images';
import Loading from '../../../shared/Loading';
import paths from '../../../config/local/paths';
import React from 'react';
import ResendInviteButton from '../../shared/ResendInviteButton';

/** @extends {React.Component<Props, State>} */
class EditContact extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			contactId: '',
			deleteWasSuccessful: false,
			editWasSuccessful: false,
			errorMessage: null,
			initialValues: {
				city: '',
				closeDate: '',
				country: '',
				email: '',
				firstName: '',
				isHomeowner: false,
				landline: '',
				lastName: '',
				mobile: '',
				moveDate: '',
				postalCode: '',
				state: '',
				streetAddress1: '',
				streetAddress2: '',
			},
			isGettingData: true,
			resendInviteMessage: '',
			showPopup: false,
		};

		this.handleClose = this.#handleClose.bind(this);
		this.handleConfirmDeleteContact =
			this.#handleConfirmDeleteContact.bind(this);
		this.handleDeleteContact = this.#handleDeleteContact.bind(this);
		this.handleResendInvite = this.#handleResendInvite.bind(this);
		this.handleSubmit = this.#handleSubmit.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			const contact = await getContact(
				this.props.match.params.contactId,
				this.context.accessToken
			);

			this.setState({
				contactId: contact.id,
				errorMessage: null,
				initialValues: {
					city: contact.city,
					closeDate: formatDateStringForDatepicker(contact.closeDate),
					country: contact.country,
					email: contact.email,
					firstName: contact.firstName,
					groupId: contact.groupId,
					isHomeowner: !!contact.homeownerId.length,
					landline: contact.landline,
					lastName: contact.lastName,
					mobile: contact.mobile,
					moveDate: formatDateStringForDatepicker(contact.moveDate),
					postalCode: contact.postalCode,
					state: contact.state,
					streetAddress1: contact.streetAddress1,
					streetAddress2: contact.streetAddress2,
				},
				isGettingData: false,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {State["initialValues"]} values
	 * @returns {Promise<void>}
	 */
	async #handleSubmit(values) {
		try {
			await updateContact(
				this.props.match.params.contactId,
				{
					...this.state.initialValues,
					agentId: await getAgentId(this.context.accessToken),
					...values,
				},
				this.context.accessToken
			);
			this.setState({
				editWasSuccessful: true,
				errorMessage: null,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {Event & { currentTarget: HTMLInputElement }} event
	 * @returns {Promise<void>}
	 */
	async #handleResendInvite(event) {
		event.preventDefault();
		try {
			await this.props.contactService.inviteContact(this.state.contactId);
			this.setState(
				/**
				 * @param {State} prevState
				 * @returns {{resendInviteMessage: string}}
				 */
				(prevState) => ({
					resendInviteMessage: translate(
						'agent.pages.contacts.edit.success',
						prevState.initialValues.firstName,
						prevState.initialValues.lastName
					),
				})
			);
		} catch (error) {
			captureError(error);
			this.setState({
				resendInviteMessage: translate(
					'agent.pages.contacts.edit.error'
				),
			});
		}
	}

	/**
	 * @returns {void}
	 */
	#handleDeleteContact() {
		this.setState({
			showPopup: true,
		});
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #handleConfirmDeleteContact() {
		try {
			await this.props.contactService.removeContact([
				this.state.contactId,
			]);
			this.setState({
				deleteWasSuccessful: true,
				showPopup: false,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate(
					'agent.pages.contacts.edit.delete_error'
				),
				isGettingData: false,
			});
		}
	}

	/**
	 * @returns {void}
	 */
	#handleClose() {
		this.setState({ showPopup: false });
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.editWasSuccessful) {
			return (
				<Redirect
					to={{
						pathname: paths.app.agent.contacts.root,
						state: {
							message: translate(
								'agent.pages.contacts.add.edit_message'
							),
						},
					}}
				/>
			);
		}

		if (this.state.deleteWasSuccessful) {
			return (
				<Redirect
					to={{
						pathname: paths.app.agent.contacts.root,
						state: {
							message: translate(
								'agent.pages.contacts.view.delete_contact_success'
							),
						},
					}}
				/>
			);
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<React.Fragment>
				{!!this.state.showPopup && (
					<ContactDeletePopup
						contactName={`${this.state.initialValues.firstName} ${this.state.initialValues.lastName}`}
						onClose={this.handleClose}
						onSubmit={this.handleConfirmDeleteContact}
						selectedContacts={[this.state.contactId]}
					/>
				)}

				<ContactForm
					additionalButtons={[
						<ResendInviteButton
							icon={images.icons.plus}
							key={0}
							onResendInvite={this.handleResendInvite}
							outline={true}
							size="large"
						/>,
						!this.state.initialValues.isHomeowner && (
							<BaseButton
								key={1}
								onClick={this.handleDeleteContact}
								outline={true}
								size="large"
							>
								{translate(
									'agent.pages.contacts.view.table.delete_contact'
								)}
							</BaseButton>
						),
					]}
					editText={translate(
						'agent.pages.contacts.edit.invite.update'
					)}
					error={this.state.errorMessage}
					formTitle={translate('agent.pages.contacts.edit.title')}
					initialValues={this.state.initialValues}
					onResendInvite={this.handleResendInvite}
					onSubmit={this.handleSubmit}
					resendInviteMessage={this.state.resendInviteMessage}
				/>
			</React.Fragment>
		);
	}
}

EditContact.contextType = AuthContext;

export default withContactService(EditContact);
