import React from 'react';
import { Redirect } from 'react-router-dom';

import AddOffer from 'App/Agent/Offers/AddOffer';
import EditOffer from 'App/Agent/Offers/EditOffer';
import ViewOffers from 'App/Agent/Offers/ViewOffers';
import paths from 'config/local/paths';
import Page from 'shared/Page';
import Switch from 'shared/Switch';
import { translate } from 'App/Internationalization';

export default class Offers extends React.Component {
	render() {
		return (
			<Switch>
				<Redirect
					exact
					from={paths.app.agent.offers.root}
					to={paths.app.agent.offers.view}
				/>
				<Page
					component={ViewOffers}
					path={paths.app.agent.offers.view}
					title={translate('admin.nav.offers.view')}
				/>
				<Page
					component={AddOffer}
					path={paths.app.agent.offers.add}
					title={translate('admin.nav.offers.add')}
				/>
				<Page
					component={EditOffer}
					path={paths.app.agent.offers.edit}
					title={translate('admin.nav.offers.edit')}
				/>
			</Switch>
		);
	}
}
