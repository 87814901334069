/**
 * @typedef {import('../../App/Agent/Contacts/ContactComponent').ContactsData} ContactsData
 * @typedef {import('../../App/Agent/Contacts/ContactComponent').GroupContactsData} GroupContactsData
 * @typedef {import('../../App/shared/Query/Parameters').default} FilterQueryParameters
 * @typedef {import('../../App/shared/Query/Parameters').default} QueryParameters
 * @typedef {import('../../Auth/AuthService').default} AuthService
 * @typedef {import('./AgentContact').default} AgentContact
 * @typedef {import('./ContactResponse').ContactCount} ContactCount
 * @typedef {import('./GetsContactsTabs').ContactsTabsResponse} ContactsTabsResponse
 * @typedef {import('./ContactResponse').default} Response
 * @typedef {import('./GetsContacts').default} GetsContacts
 * @typedef {import('./GetsContactsByGroupId').default} GetsContactsByGroupId
 * @typedef {import('@mooveguru/js-http-client').BrowserClient} HttpClient
 */
import { prepareQueryForUrl } from '../../App/shared/Query';
import apiUrls from '../../config/local/api-urls';
import ServiceError from '../../shared/Errors/ServiceError';
import utilities from '@mooveguru/js-utilities';

/**
 * @implements {GetsContacts}
 * @implements {GetsContactsByGroupId}
 */
// TODO: See if Service can be consolidated with Admin Contacts Service into one Contacts Component
export default class AgentContactService {
	/** @param {{authService: AuthService, httpClient: HttpClient }} dependencies */
	constructor(dependencies) {
		this.authService = dependencies.authService;
		this.httpClient = dependencies.httpClient;
	}

	/**
	 * @returns {Promise<{accepted: number, percent: number, total: number}>}
	 */
	async getTotalContacts() {
		const response = await this.httpClient.get(
			`${apiUrls.me.contacts}?type=group`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		const total = response.body.total;

		const accepted = response.body.enrolled_contacts;

		return {
			accepted,
			percent:
				total && accepted ? Math.ceil(100 * (accepted / total)) : 0,
			total,
		};
	}

	/**
	 * @param {number | QueryParameters} [query]  -- Accepts number for legacy purposes
	 * @param {number} [page] -- @deprecated, for legacy implementations
	 * @param {'group' | 'unaffiliated'} [type] -- @deprecated, for legacy implementations
	 * @returns {Promise<ContactsData>}
	 */
	async getContacts(query = 50, page = 1, type = 'group') {
		if (typeof query === 'number') {
			/** @type {QueryParameters} */
			query = {
				// @ts-ignore
				'filter[office_id]': type === 'group' ? undefined : 'null',
				limit: query,
				page,
			};
		}

		if (!query.limit) {
			query = {
				...query,
				limit: 50,
			};
		}

		// @ts-ignore -- If is a number, will be converted in the lines above
		const params = prepareQueryForUrl(query, true);

		const response = await this.httpClient.get(
			apiUrls.me.contacts + params,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		return {
			contacts: response.body.contacts.map(
				AgentContactService.#mapContactToResponse
			),
			moverCounts: response.body.mover_counts ?? [],
			totalContacts: response.body.total,
			totalPages: response.body.pages,
		};
	}

	/**
	 * @param {string} groupId
	 * @param {FilterQueryParameters | number} query
	 * @param {number} [limit]
	 * @returns {Promise<GroupContactsData>}
	 */
	async getContactsByGroupId(groupId, query = 1, limit = 100) {
		if (typeof query === 'number') {
			/** @type {QueryParameters} */
			query = {
				// @ts-ignore
				limit: limit,
				page: query,
			};
		}

		const endpoint = apiUrls.agents.groupContacts.replace(
			':groupId',
			groupId
		);

		const params = prepareQueryForUrl(query, true);

		const response = await this.httpClient.get(
			endpoint + params,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		return {
			contacts: response.body.contacts.map(
				AgentContactService.#mapContactToResponse
			),
			groupId: response.body.group.id,
			groupName: response.body.group.name,
			totalContacts: response.body.total,
			totalPages: response.body.pages,
		};
	}

	/**
	 * @param {Response} contact
	 * @returns {Omit<AgentContact, 'address'> & { address: string }}
	 */
	// eslint-disable-next-line complexity
	static #mapContactToResponse(contact) {
		return {
			acceptedInvite: contact.accepted_invite,
			address: AgentContactService.formatAddress(contact) || '—',
			agentId: contact.agent_id,
			email: contact?.email_address,
			firstName: contact?.name?.first ?? null,
			group: {
				id: contact.group?.id ?? null,
				isSubscribed: contact.group?.is_subscribed ?? false,
				name: contact.group?.name ?? null,
			},
			id: contact?.id,
			landline: contact?.landline_phone_number ?? null,
			lastLoggedInAt: contact?.last_logged_in_at
				? utilities.date.convertDateToDateString(
						new Date(contact?.last_logged_in_at)
				  )
				: null,
			leadScore: contact?.lead_score ?? 0,
			leadScores: contact?.lead_scores ?? [],
			mobile: contact?.mobile_phone_number ?? null,
			moveDate: contact?.move_date
				? utilities.date.convertDateToDateString(
						new Date(contact?.move_date)
				  )
				: '—',
			name:
				contact?.name?.first || contact?.name?.last
					? `${contact?.name?.first ?? ''} ${contact?.name?.last ?? ''}`.trim() // prettier-ignore
					: '—',
		};
	}

	/**
	 * @param {string} agentId
	 * @param {string[]} [idList]
	 * @returns {Promise<ContactCount | ContactCount[]>}
	 */
	async getContactsCount(agentId, idList) {
		const endpoint = apiUrls.agents.contactCount.replace(
			':agentId',
			agentId
		);

		const query = new URLSearchParams();

		if (idList) {
			query.append(
				'filter[office_id]',
				idList
					.map((id) => (id === 'unaffiliated' ? 'null' : id))
					?.join(';') ?? ''
			);
		}

		const params = prepareQueryForUrl(query, true);

		const response = await this.httpClient.get(
			endpoint + params,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		return Array.isArray(response.body)
			? response.body.map(AgentContactService.#mapContactCountResponse)
			: AgentContactService.#mapContactCountResponse(response.body);
	}

	/**
	 * @param {ContactCount & {has_subscription: boolean}} contact
	 * @returns {ContactCount}
	 */
	static #mapContactCountResponse(contact) {
		return {
			count: contact.count ?? 0,
			hasSubscription: contact?.has_subscription ?? false,
			id: contact?.id,
			name: contact?.name,
		};
	}

	/**
	 * @returns {Promise<ContactsTabsResponse>}
	 */
	async getContactsTabs() {
		const response = await this.httpClient.get(
			apiUrls.agents.contactsTabs,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const results = response.body;
		const groupCountList = results.group_contacts_counts ?? [];

		return {
			allContactsCount: results.all_contacts_count ?? 0,
			groupsContactsCounts: groupCountList.map(
				/**
				 * @param {{count: number; has_subscription: boolean; id: string; name: string  }} group
				 * @returns {{ count: number, hasSubscription: boolean, id: string, name: string }}
				 */
				(group) => ({
					count: group.count,
					hasSubscription: group.has_subscription,
					id: group.id,
					name: group.name,
				})
			),
			myContactsCount: results.my_contacts_count ?? 0,
		};
	}

	/**
	 * @private
	 * @param {string[]} strings
	 * @returns {string}
	 */
	static formatAddressLine(strings) {
		return strings.filter((string) => string !== '').join(', ');
	}

	/**
	 * @private
	 * @param {Response} contact
	 * @returns {string}
	 */
	static formatAddress(contact) {
		if (!contact.address) {
			return 'N/A';
		}

		return AgentContactService.formatAddressLine([
			AgentContactService.formatAddressLine([
				contact.address.street_address_1 ?? '',
				contact.address.street_address_2 ?? '',
			]),
			this.formatAddressLine([`${contact.address.city ?? ''}`.trim()]),
		]);
	}

	/**
	 * Invite contact by id
	 * @param {string} id
	 */
	async inviteContact(id) {
		if (!id) {
			throw new Error('Contact not found');
		}
		try {
			const response = await this.httpClient.post(
				`${apiUrls.contacts.invite}/${id}`,
				new Headers({
					Authorization: `Bearer ${this.authService.getAccessToken()}`,
				})
			);

			if (!response.isOk) {
				throw new ServiceError(
					'Something went wrong while trying to invite your contact.',
					response.body
				);
			}
		} catch (error) {
			throw new Error(
				'Something went wrong while trying to invite your contact.'
			);
		}
	}
}
