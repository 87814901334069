import { BaseSVG } from 'shared/BaseSVG';
import { translate } from 'App/Internationalization';
import { withApplianceService } from 'service-container';
import BaseField from 'shared/Forms/Fields/BaseField';
import BaseForm from 'shared/Forms/BaseForm';
import images from 'config/local/images';
import LinkButton from 'shared/LinkButton';
import Loading from '../../../shared/Loading';
import paths from 'config/local/paths';
import React from 'react';
import SubmitButton from 'shared/Forms/Inputs/SubmitButton';

export class ViewManuals extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			applianceList: [],
			errorMessage: null,
			isGettingData: true,
			initialFormValues: null,
			previousSearchTerm: '',
			showResetButton: false,
		};

		this.baseForm = React.createRef();
		this.handleSubmit = this.handleSubmit.bind(this);
		this.searchManuals = this.searchManuals.bind(this);

		this.state.initialValues = {
			appliance: this.props.location?.state?.manual ?? '',
		};
	}

	async handleSubmit(values) {
		try {
			await this.searchManuals(values.appliance);

			return {
				previousSearchTerm: values.appliance,
			};
		} catch (error) {
			this.setState({ errorMessage: error.message });
		}
	}

	async searchManuals(searchTerm) {
		if (searchTerm !== this.state.previousSearchTerm) {
			this.setState({
				isGettingData: true,
			});
		}

		try {
			const result = await this.props.applianceService.getManuals(
				searchTerm
			);

			this.setState({
				applianceList: result,
				isGettingData: false,
			});
		} catch (error) {
			this.setState({
				errorMessage: error.message,
				isGettingData: false,
			});
		}
	}

	async componentDidMount() {
		this.setState({
			previousSearchTerm: this.props.location.state.manual,
		});

		await this.searchManuals(this.props.location.state.manual);
	}

	render() {
		const applianceList = this.state.applianceList.map(
			(appliance, index) => (
				<li key={index}>
					<a
						className="no-underline text-default-color"
						rel="noreferrer noopener"
						target="_blank"
						href={appliance?.manual}
					>
						<header className="order-2">
							<h4>
								{appliance.brand} {appliance.model}
							</h4>
							<p>{appliance.category}</p>
							<p>
								{translate(
									'homeowner.pages.appliance_center.manual.view'
								)}
							</p>
						</header>
						<BaseSVG
							path={images.icons.file}
							className="h-16 mr-2 order-1 w-16"
						/>
					</a>
				</li>
			)
		);

		return (
			<section className="flex flex-col flex-1">
				<header className="mb-4 flex flex-row items-center justify-between">
					<h3 className="mr-4 text-xl">
						{translate(
							'homeowner.pages.appliance_center.manuals.title'
						)}
					</h3>
					<LinkButton
						to={paths.app.homeowner.applianceCenter.root}
						hoverColor="primary-active"
					>
						{translate(
							'homeowner.pages.appliance_center.manuals.return'
						)}
					</LinkButton>
				</header>
				<section className="appliance-form">
					<BaseForm
						className="layout flex-1"
						initialValues={this.state.initialValues}
						innerRef={this.baseForm}
						onSubmit={this.handleSubmit}
						noRequiredText="false"
						ref={this.baseForm}
					>
						<BaseField
							className="flex-1"
							labelClass="sr-only"
							name="appliance"
							placeholder={translate(
								'homeowner.pages.appliance_center.search.placeholder'
							)}
							type="search"
						/>
						<SubmitButton hoverColor="primary-active" type="submit">
							{translate(
								'homeowner.pages.appliance_center.search.button'
							)}
						</SubmitButton>
					</BaseForm>
				</section>
				<ul className="appliance-list">
					{this.state.isGettingData ? (
						<li>
							<Loading />
						</li>
					) : (
						applianceList
					)}
				</ul>
			</section>
		);
	}
}

export default withApplianceService(ViewManuals);
