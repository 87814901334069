/**
 * @typedef {import('../Auth/SetPasswordComponent').Props} Props
 * @typedef {import('../shared/AuthProvider/Roles').Roles} Roles
 * @typedef {import('../Auth/SetPasswordComponent').State} State
 */

import { array } from '@mooveguru/js-utilities';
import { Redirect } from 'react-router-dom';
import { resetPasswordSegment, signInSegment } from '../config/local/paths';
import { translate } from '../App/Internationalization';
import { withAuth } from '../shared/AuthProvider';
import { withTheme } from '../shared/ThemeProvider';
import { withAuthService, withUserService } from '../service-container';
import * as validators from '../shared/validators';
import * as yup from 'yup';
import AppLogo from '../App/shared/AppLogo';
import BaseForm from '../shared/Forms/BaseForm';
import captureError from '../utils/captureError';
import FieldPassword from '../shared/Forms/Fields/FieldPassword';
import FormErrorMessages from '../shared/Forms/Messages/FormErrorMessages';
import React from 'react';
import SubmitButton from '../shared/Forms/Inputs/SubmitButton';

const initialFormValues = {
	password: '',
	passwordConfirmation: '',
};

const validationSchema = yup.object().shape({
	password: validators.password,
	passwordConfirmation: validators.matchConfirmation('password', 'Passwords'),
});

/** @extends {React.Component<Props, State>} */
class SetPassword extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			message: null,
			passwordIsSet: false,
		};

		const searchParams = new URLSearchParams(window.location.search);
		this.token = searchParams.get('token');
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	/** @returns {Promise<void>} */
	async componentDidMount() {
		this.#setBranding();
	}

	/**
	 * @param {{password: string}} values
	 * @returns {Promise<void>}
	 */
	async handleSubmit(values) {
		try {
			await this.props.userService.setPassword(
				values.password,
				this.token
			);

			await this.#signIn(values.password);

			this.setState({
				passwordIsSet: true,
			});
		} catch (error) {
			captureError(error);

			// Errors are user readable. Contains useful information
			this.setState({
				message:
					error instanceof Error
						? error.message
						: translate('set_password_page.error_message'),
			});
		}
	}

	/**
	 * @param {string} password
	 * @returns {Promise<void>}
	 */
	async #signIn(password) {
		try {
			const { aud: email } = this.props.authService.extractPayloadData(
				// @ts-ignore method wont run if token is not valid
				this.token
			);

			await this.props.auth.signIn(`${email}`, password);
		} catch (error) {
			captureError(error);

			this.setState({
				message: translate('sign_in.generic_sign_in_error'),
			});
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #setBranding() {
		const roles = await this.#getUserRoles();

		if (!roles.length) {
			return;
		}

		const isAdministrator = SetPassword.#isAdministrator(roles);

		if (isAdministrator) {
			this.props.theme.getAndSetActiveTheme('administrator');
			return;
		}

		this.props.theme.getAndSetActiveTheme('agent');
	}

	/**
	 * @returns {Promise<Roles[]>}
	 */
	async #getUserRoles() {
		if (this.token) {
			return this.props.userService.getUserRoles(this.token);
		}

		return [];
	}

	/**
	 * @param {Roles[]} roles
	 * @returns {boolean}
	 */
	static #isAdministrator(roles) {
		return array.includesAny(roles, 'admin', 'manager', 'owner');
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.token === null) {
			return <Redirect to={resetPasswordSegment} />;
		}

		if (this.state.passwordIsSet) {
			return (
				<Redirect
					to={{
						pathname: signInSegment,
						state: {
							message: translate(
								'set_password_page.success_message'
							),
						},
					}}
				/>
			);
		}

		return (
			<section className="app-login">
				<div className="login-container">
					<AppLogo />
					<BaseForm
						className="mt-12"
						initialValues={initialFormValues}
						noRequiredText={true}
						onSubmit={this.handleSubmit}
						validationSchema={validationSchema}
					>
						<FieldPassword
							className="mt-4"
							label={translate(
								'set_password_page.new_password_label'
							)}
							name="password"
							placeholder={translate(
								'set_password_page.new_password_placeholder'
							)}
							required={true}
						/>
						<FieldPassword
							className="mt-4"
							label={translate(
								'set_password_page.confirm_new_password_label'
							)}
							name="passwordConfirmation"
							placeholder={translate(
								'set_password_page.confirm_new_password_placeholder'
							)}
							required={true}
						/>
						<SubmitButton className="mt-10">
							{translate('set_password_page.submit')}
						</SubmitButton>
						<FormErrorMessages messages={this.state.message} />
					</BaseForm>
				</div>
			</section>
		);
	}
}

// @ts-ignore until the types for service containers are fixed
export default withAuth(
	withAuthService(withUserService(withTheme(SetPassword)))
);
