import { translate } from '../../../../Internationalization';
import ActionMessage from '../../../../../shared/ActionMessages/ActionMessage';
import images from '../../../../../config/local/images';
import LinkButton from '../../../../../shared/LinkButton';
import paths from '../../../../../config/local/paths';
import React from 'react';

export class SubscriptionWarningMessage extends React.Component {
	/* eslint-disable-next-line class-methods-use-this -- `render()` must be callable by instantiated class */
	render() {
		const contactCap = process.env.REACT_APP_CONTACT_CAP ?? '100';
		const path = 'subscription.message';
		return (
			<ActionMessage
				action="error"
				icon={images.icons.alert}
				title={translate(`${path}.title`)}
			>
				<section className="message-themed">
					<div className="my-6 rich-text text-center">
						<div className="text-lg mb-4">
							{translate(`${path}.cancel_warning`, contactCap)}
						</div>
						<div className="text-lg mb-4 font-bold">
							{translate(`${path}.cancel_warning_1`)}
						</div>
						<div className="text-lg mb-4">
							{translate(`${path}.cancel_warning_2`)}
						</div>
						<div className="text-lg mb-4">
							{translate(`${path}.cancel_warning_3`)}
						</div>
						<div className="text-lg mb-4">
							{translate(`${path}.thank_you`)}
						</div>
					</div>
					<footer className="m-8 flex-wrap text-center">
						<LinkButton
							className="button mb-4 mr-4"
							to={paths.app.agent.contacts.root}
						>
							{translate(`${path}.take_me_my_contact`)}
						</LinkButton>

						<LinkButton
							className="button mb-4 mr-4"
							to={paths.app.agent.subscription.root}
						>
							{translate(`${path}.upgrade_account`)}
						</LinkButton>
					</footer>
				</section>
			</ActionMessage>
		);
	}
}

/* eslint-enable-next-line class-methods-use-this */
export default SubscriptionWarningMessage;
