import { captureException } from '@sentry/react';

/**
 * @param {unknown} thrown
 * @returns {Error}
 */
function asError(thrown) {
	if (thrown instanceof Error) {
		return thrown;
	}

	const message = 'An unexpected error occurred';

	if (thrown === null || thrown === undefined) {
		return new Error(message);
	}

	try {
		return new Error(`${message}: ${JSON.stringify(thrown)}`);
	} catch {
		// No catch
	}

	return new Error(message);
}

/**
 * @returns {boolean}
 */
function isLocal() {
	return (process.env.REACT_APP_ENV || 'local') === 'local';
}

/**
 * Logs an error on local, send is to Sentry on other environments.
 * @param {unknown} thrown
 * @returns {void}
 */
export default function captureError(thrown) {
	const error = asError(thrown);

	if (isLocal()) {
		/* eslint-disable-next-line  no-console -- Logs error on local envs only */
		console.error(`ERROR: ${error.message}\n`, thrown);

		return;
	}

	captureException(error);
}

/**
 * Logs an error on local.
 * @param {unknown} thrown
 * @returns {void}
 */
export function logError(thrown) {
	if (!isLocal()) {
		return;
	}

	/* eslint-disable-next-line  no-console -- Logs error on local envs only */
	console.error(`ERROR: ${asError(thrown).message}\n`, thrown);
}
