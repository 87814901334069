/**
 * @typedef {import('./FilterGroupComponent').Props} Props
 * @typedef {import('./FilterGroupComponent').State} State
 */
import { BaseSVG } from '../../../../../shared/BaseSVG';
/*
 * import { translate } from '../../../../Internationalization';
 * import BaseButton from '../../../../../shared/BaseButton/BaseButton';
 */
import images from '../../../../../config/local/images';
import React from 'react';

/**
 * @extends {React.Component<Props, State>}
 */
export default class FilterGroup extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		// this._handleClear = this._handleClear.bind(this);
		this._handleClickOutsideWindow =
			this._handleClickOutsideWindow.bind(this);
		this._handleDropdownClick = this._handleDropdownClick.bind(this);
		this._setWrapperRef = this._setWrapperRef.bind(this);
		this.dropdownRef = React.createRef();
		this.state = { open: false };
	}

	/**
	 * @returns {void}
	 */
	componentDidMount() {
		document.addEventListener('click', this._handleClickOutsideWindow);
	}

	/**
	 * @returns {void}
	 */
	componentWillUnmount() {
		document.removeEventListener('click', this._handleClickOutsideWindow);
	}

	/**
	 * @protected
	 * @param {HTMLElement} element
	 * @returns {void}
	 */
	_setWrapperRef(element) {
		this.wrapperRef = element;
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleDropdownClick() {
		if (this.props.locked === true) {
			return;
		}

		this.setState((prev) => {
			if (prev.open && typeof this.props.onClose === 'function') {
				this.props.onClose();
			}

			return {
				...prev,
				open: !prev.open,
			};
		});
	}

	/**
	 * @protected
	 * @param {Event} event
	 * @returns {void}
	 */
	_handleClickOutsideWindow(event) {
		if (!this.wrapperRef) {
			return;
		}

		if (
			// @ts-ignore
			this.wrapperRef.contains(event?.target) ||
			// @ts-ignore
			event?.target?.id?.includes('react-select')
		) {
			return;
		}

		if (!this.state.open) {
			return;
		}

		this.setState(
			/**
			 * @param {State} prev
			 * @returns {State}
			 */
			(prev) => ({ ...prev, open: false })
		);

		if (typeof this.props.onClose === 'function') {
			this.props.onClose();
		}
	}

	get #icon() {
		return this.props.locked === true
			? images.icons.lock
			: images.icons.filter;
	}

	// TODO: Implement clear functionality
	// /**
	//  * @param {React.MouseEvent<HTMLButtonElement>} event
	//  * @returns {void}
	//  */
	// _handleClear(event) {
	// 	if (typeof this.props.onClear === 'function') {
	// 		this.props.onClear(event);
	// 	}
	//
	// 	this.#close();
	// }
	//
	// /**
	//  * @returns {React.ReactNode | null}
	//  */
	// get #clearButton() {
	// 	if (this.props.active === false) {
	// 		return null;
	// 	}
	//
	// 	return (
	// 		<div className="mt-4 w-fit mr-0 ml-auto">
	// 			<BaseButton
	// 				icon={images.icons.closeCircle}
	// 				iconSize="small"
	// 				onClick={this._handleClear}
	// 				outline={true}
	// 				size="xsmall"
	// 				type="reset"
	// 			>
	// 				{translate('filters.reset')}
	// 			</BaseButton>
	// 		</div>
	// 	);
	// }

	/**
	 * @returns {React.ReactNode}
	 */
	render() {
		return (
			<section
				className="dropdown grow sm:order-1 sm:grow-0 sm:w-fit"
				ref={this._setWrapperRef}
			>
				<button
					className={`button button-icon text-sm px-4 py-1 rounded-sm font-normal w-full ${
						this.props.locked ? 'cursor-not-allowed' : ''
					}
					${this.props.active ? '' : 'bg-grey-300 border-grey-300 text-black'}`.trim()}
					onClick={this._handleDropdownClick}
					ref={this.dropdownRef}
					type="button"
				>
					{this.props.title}

					<i className="ml-2 icon w-3 icon-fill-fg">
						<BaseSVG path={this.#icon} />
					</i>
				</button>

				<div
					className={`px-2 dropdown-menu ${
						this.state.open ? 'active' : ''
					}`.trim()}
					style={{
						left: `${this.dropdownRef?.current?.offsetLeft}px`,
						top: `${this.dropdownRef?.current?.offsetHeight + 8}px`,
					}}
				>
					{this.props.children}
					{/* {this.#clearButton} */}
				</div>
			</section>
		);
	}
}
