import { BaseSVG } from '../../BaseSVG';
import { translate } from '../../../App/Internationalization';
import FormField from './FormField';
import images from '../../../config/local/images';
import React from 'react';
import InputSearchableSelectAsync from '../Inputs/InputSearchableSelectAsync';

export default class FieldSearchableSelectAsync extends React.Component {
	render() {
		const label = translate('global.forms.inputs.select.label');

		return (
			<FormField
				className={this.props.className}
				inputName={this.props.name}
				label={this.props.label ?? label}
				labelClass={this.props.labelClass}
				required={this.props.required}
			>
				<section className="input-stylized">
					<BaseSVG
						className="icon input-icon icon-sm"
						path={images.icons.arrow.dropdown.normal}
					/>
					<InputSearchableSelectAsync
						cacheOptions={this.props.cacheOptions}
						defaultOptions={this.props.defaultOptions}
						defaultValue={this.props.defaultValue}
						id={this.props.id}
						inputClass={this.props.inputClass}
						isClearable={this.props.isClearable}
						isMultipleSelect={this.props.isMultipleSelect}
						loadOptions={this.props.loadOptions}
						name={this.props.name}
						onChange={this.props.onChange}
					/>
				</section>
			</FormField>
		);
	}
}

FieldSearchableSelectAsync.defaultProps = {
	isClearable: InputSearchableSelectAsync.defaultProps.isClearable,
	isMultipleSelect: InputSearchableSelectAsync.defaultProps.isMultipleSelect,
};
