/**
 * @typedef {import('./InputRadioComponent').Props} Props
 * @typedef {import('formik').FieldProps<{ [key: string]: string }>} FieldProps
 */
import { Field } from 'formik';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class InputRadio extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		this._isChecked = this._isChecked.bind(this);
		this._onChange = this._onChange.bind(this);
	}

	/**
	 * @protected
	 * @param {FieldProps} props
	 * @returns {boolean}
	 */
	_isChecked(props) {
		const name = this.props.name.replace('[]', '');
		const value = props.form.values[name];

		return this.props.value === value;
	}

	/**
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @param {FieldProps} props
	 * @returns {void}
	 */
	_onChange(event, props) {
		props.form.setFieldValue(
			event.currentTarget.name,
			event.currentTarget.value
		);

		if (typeof this.props.onChange === 'function') {
			this.props.onChange(event, props.form);
		}

		if (typeof props.field.onChange === 'function') {
			props.field.onChange(event);
		}
	}

	/**
	 * @returns {React.ReactNode}
	 */
	render() {
		return (
			<Field name={this.props.name}>
				{
					// eslint-disable-next-line jsdoc/require-param, jsdoc/check-param-names
					/**
					 * @param {FieldProps} props
					 * @returns {React.ReactNode}
					 */
					(props) => (
						<input
							checked={this._isChecked(props)}
							className="radio"
							disabled={this.props.disabled}
							id={this.props.id ?? this.props.name}
							name={props.field.name}
							onChange={(event) => this._onChange(event, props)}
							onClick={this.props.onClick}
							required={this.props.required}
							type="radio"
							value={this.props.value ?? this.props.name}
						/>
					)
				}
			</Field>
		);
	}
}

InputRadio.defaultProps = {
	disabled: false,
	required: false,
};
