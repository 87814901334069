/**
 * @typedef {AddsAgentOffer & AddsGroupOffer & GetsAgentOffers & GetsGroupOffers & EditsOffer} OfferService
 * @typedef {import('../../shared/OfferForm/OfferFields').default} OfferFields
 * @typedef {import('../../shared/OfferService/AddsAgentOffer').default} AddsAgentOffer
 * @typedef {import('../../shared/OfferService/AddsOffer').default} AddsOffer
 * @typedef {import('../../shared/OfferService/AddsGroupOffer').default} AddsGroupOffer
 * @typedef {import('../../shared/OfferService/EditsOffer').default} EditsOffer
 * @typedef {import('../../shared/OfferService/GetsAgentOffers').default} GetsAgentOffers
 * @typedef {import('../../shared/OfferService/GetsGroupOffers').default} GetsGroupOffers
 * @typedef {import('../../shared/OfferService/OffersProps').default} Props
 * @typedef {import('./AddOfferState').default} State
 * @typedef {import('./ImageData')} ImageData
 */

import { AuthContext } from '../../../shared/AuthProvider';
import { file as validateFile } from '../../../shared/validators';
import { hasCapability } from '../../../utils/capabilities';
import { Redirect } from 'react-router-dom';
import { translate } from '../../../App/Internationalization';
import { withOfferService } from '../../../service-container';
import captureError from '../../../utils/captureError';
import categoryList from '@mooveguru/yhh-shared-config/marketplace-categories.json';
import FormErrorMessages from '../../../shared/Forms/Messages/FormErrorMessages';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import OfferForm from '../../../App/shared/OfferForm/OfferForm';
import paths from '../../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class AddOffer extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/** @param {State} state */
		this.state = {
			errorMessage: null,
			formComplete: false,
			image: null,
			initialValues: {
				category: Object.keys(categoryList)?.shift() ?? '',
				description: '',
				isActive: '',
				postalCode: '',
				title: '',
				vendorEmail: '',
				vendorName: '',
				vendorPhone: '',
				website: '',
			},
		};

		this._createOffer = this._createOffer.bind(this);
		this._handleFileUploadChange = this._handleFileUploadChange.bind(this);
		this._handleImageSubmit = this._handleImageSubmit.bind(this);
		this._handleSubmit = this._handleSubmit.bind(this);
		this._submit = this._submit.bind(this);
		this._validateUploadedImage = this._validateUploadedImage.bind(this);
	}

	/**
	 * @protected
	 * @param {OfferFields} offer
	 * @param {(offer: OfferFields) => Promise<string>} callback
	 * @returns {Promise<boolean>}
	 */
	async _createOffer(offer, callback) {
		let offerId;

		try {
			/*
			 *Callback should create offer, ideally using the OfferService
			 *class and either `addOffer` or `addGroupOffer`
			 */
			offerId = await callback(offer);
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('global.error'),
				formComplete: false,
			});

			return false;
		}

		if (this.state.image) {
			await this._handleImageSubmit(offerId);
		}

		this.setState({
			errorMessage: null,
			formComplete: true,
		});

		return true;
	}

	/**
	 * @protected
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @returns {void}
	 */
	_handleFileUploadChange(event) {
		this.setState({
			image: event.target?.files?.length ? event.target?.files[0] : null,
		});
	}

	/**
	 * @protected
	 * @param {string} offerId
	 * @returns {Promise<void>}
	 */
	async _handleImageSubmit(offerId) {
		try {
			await this.props.offerService.addOfferImage(
				this.state.image,
				offerId
			);
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate(
					'admin.pages.offers.image_upload.error'
				),
			});
		}
	}

	/**
	 * @protected
	 * @param {OfferFields} values
	 * @returns {Promise<void>}
	 */
	async _handleSubmit(values) {
		try {
			this._validateUploadedImage();
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate(
					'admin.pages.offers.image_upload.error'
				),

				formComplete: false,
			});

			return;
		}

		this._submit(values);
	}

	/**
	 * @protected
	 * @param {OfferFields} offer
	 * @returns {Promise<void>}
	 */
	async _submit(offer) {
		if (offer.groupList === undefined) {
			this._createOffer(offer, (data) =>
				this.props.offerService.addOffer(data)
			);

			return;
		}

		for (const group of offer.groupList) {
			const created = this._createOffer(offer, (data) =>
				this.props.offerService.addGroupOffer(data, group.id)
			);

			/*
			 *! TODO: This has the ability to create some Offers for Groups and fail for
			 *! others, leaving the users in a "incomplete" state. There is no way
			 *! to reasonably fix this on the front-end. Instead of the route
			 *! `POST groups/:groupId/offers` we need to create a `POST offers`
			 *! route that takes `group_ids` as a parameter and generates the
			 *! appropriate Offers atomically.
			 */
			if (!created) {
				break;
			}
		}
	}

	/**
	 * @protected
	 * @returns {void}
	 * @throws {Error}
	 */
	_validateUploadedImage() {
		if (!this.state.image) {
			return;
		}

		validateFile(this.state.image, mimeList.images, 'image');
	}

	/**
	 * @returns {string}
	 */
	#determineViewRoute() {
		if (hasCapability(this.context, 'view_domain')) {
			return paths.app.admin.offers.view;
		}

		return paths.app.admin.offers.groups;
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const viewRoute = this.#determineViewRoute();

		if (this.state.formComplete) {
			return (
				<Redirect
					to={{
						pathname: viewRoute,
						state: {
							message: translate(
								'admin.pages.offers.success_message'
							),
						},
					}}
				/>
			);
		}

		return (
			<React.Fragment>
				<OfferForm
					cancelPath={viewRoute}
					formSubmitText={translate('global.offers.add')}
					formTitle={translate('global.offers.add_title')}
					hasGroupField={true}
					hasImageField={true}
					initialValues={this.state.initialValues}
					onChange={this._handleFileUploadChange}
					onSubmit={this._handleSubmit}
				/>
				<FormErrorMessages messages={this.state.errorMessage} />
			</React.Fragment>
		);
	}
}

AddOffer.contextType = AuthContext;

export default withOfferService(AddOffer);
