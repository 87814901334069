/**
 * @typedef {(import("./Props").default)} Props
 * @typedef {(import("./State").default)} State
 */

import { BaseSVG } from '../../../shared/BaseSVG';
import { translate } from '../../Internationalization';
import images from '../../../config/local/images';
import React from 'react';

/**
 * 2xl breakpoint size from tailwind class,
 * also used for enabling mobile navbar in 'app-navigation-fixed' class
 */
const breakpoint = 1450;

/** @type {NodeJS.Timeout | null} */
let debounceTimeout = null;

/** @extends {React.Component<Props>} */
export default class BaseModal extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			isOpen: true,
			modalPosition: {
				left: '20rem',
				maxHeight: '75vh',
				top: '100px',
			},
		};

		this.closeButtonRef = React.createRef();
		this._handleClose = this._handleClose.bind(this);
	}

	/** @returns {void} */
	componentDidMount() {
		this.#mountMethods();
		this.#toggleResizeEvent();
	}

	/** @param {Props} prevProps */
	componentDidUpdate(prevProps) {
		if (prevProps.children !== this.props.children) {
			this.#mountMethods();
		}

		if (this.state.isOpen) {
			this.#toggleResizeEvent();
		} else {
			this.#toggleResizeEvent(false);
		}
	}

	/** @returns {void} */
	#mountMethods() {
		this.setState({ isOpen: true });
		BaseModal.#disableWindowScroll();
		this.#updateModalPosition();
		this.#focusCloseButton();
	}

	/** @returns {void} */
	#focusCloseButton() {
		setTimeout(() => this.closeButtonRef.current?.focus(), 0);
	}

	/** @returns {void} */
	componentWillUnmount() {
		BaseModal.#disableWindowScroll(false);
		this.#toggleResizeEvent(false);
	}

	/**
	 * @param {boolean} add
	 * @returns {void}
	 */
	#toggleResizeEvent(add = true) {
		if (add) {
			return window.addEventListener('resize', () =>
				this.#debounceUpdateModalPosition()
			);
		}

		return window.removeEventListener('resize', () =>
			this.#debounceUpdateModalPosition()
		);
	}

	/** @returns {void} */
	#updateModalPosition() {
		const modalPosition = BaseModal.#getModalPosition();

		this.setState({ modalPosition });
	}

	/** @returns {void} */
	#debounceUpdateModalPosition() {
		if (debounceTimeout) {
			clearTimeout(debounceTimeout);
		}

		debounceTimeout = setTimeout(() => {
			this.#updateModalPosition();
		}, 250);
	}

	/** @returns {State['modalPosition']} */
	static #getModalPosition() {
		const appHeaderHeight = BaseModal.#getAppHeaderHeight();

		const screenWidth =
			window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth;

		const screenHeight =
			window.innerHeight ||
			document.documentElement.clientHeight ||
			document.body.clientHeight;

		const left = (screenWidth / 2) - BaseModal.#getSideBarWidth(); // prettier-ignore
		const top = (screenHeight - appHeaderHeight) / 2;
		const maxHeight = (screenHeight - appHeaderHeight) * 0.75; // 75% of screen height

		if (screenWidth <= breakpoint) {
			return {
				left: '10%',
				maxHeight: `${screenHeight}px`,
				right: '10%',
				top: `${top / 3}px`,
			};
		}

		return {
			left: `${left}px`,
			maxHeight: `${maxHeight}px`,
			top: `${top / 2}px`,
		};
	}

	/** @returns {number} */
	static #getAppHeaderHeight() {
		/** @type {HTMLDivElement | null} */
		const appHeader = document.querySelector('.app-content .app-header');

		return appHeader?.offsetHeight ?? 0;
	}

	/** @returns {number} */
	static #getSideBarWidth() {
		/** @type {HTMLElement | null} */
		const appHeader = document.getElementById('side-menu-options');

		return appHeader?.offsetWidth ?? 0;
	}

	/**
	 * @param {boolean} hidden
	 * @returns {void}
	 */
	static #disableWindowScroll(hidden = true) {
		document.body.style.overflow = hidden ? 'hidden' : 'auto';
	}

	/** @returns {void | null} */
	_handleClose() {
		this.setState({ isOpen: false });
		BaseModal.#disableWindowScroll(false);

		if (this.props?.onClose) {
			return this.props.onClose();
		}

		return null;
	}

	/** @returns {JSX.Element} */
	#renderCloseButton() {
		return (
			<div className="flex justify-end">
				<button
					aria-label={translate('aria_labels.close_modal')}
					className="absolute top-0 right-0 py-1 px-2 m-1 rounded-md hover:bg-grey-500 transition-all focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75"
					onClick={this._handleClose}
					ref={this.closeButtonRef}
					type="button"
				>
					<i aria-hidden="true" className="icon icon-xs icon-fill">
						<BaseSVG path={images.icons.close} />
					</i>
					<span className="sr-only">
						{translate('aria_labels.close_modal')}
					</span>
				</button>
			</div>
		);
	}

	/** @returns {JSX.Element | null} */
	render() {
		if (!this.state.isOpen) {
			return null;
		}

		return (
			<React.Fragment>
				<div
					aria-hidden="true"
					className="fixed top-0 left-0 z-10 h-full w-full bg-black bg-opacity-20"
					onClick={this._handleClose}
					tabIndex={-1}
				/>
				<section
					aria-modal={'true'}
					className="fixed z-10 max-w-4xl w-fit mx-auto p-6 rounded-lg overflow-y-auto bg-white"
					role="dialog"
					style={this.state.modalPosition}
				>
					{this.#renderCloseButton()}
					{this.props.children}
				</section>
			</React.Fragment>
		);
	}
}
