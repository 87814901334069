/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */
import { Link } from 'react-router-dom';
import { translate } from '../../App/Internationalization';
import { withAgentContactService } from '../../service-container';
import { withAuth } from '../../shared/AuthProvider';
import BaseButton from '../../shared/BaseButton/BaseButton';
import BaseForm from '../Forms/BaseForm';
import captureError from '../../utils/captureError';
import ExternalAnchor from '../../shared/ExternalAnchor';
import FieldRadio from '../../shared/Forms/Fields/FieldRadio';
import FormErrorMessages from '../Forms/Messages/FormErrorMessages';
import Loading from '../../shared/Loading';
import paths from '../../config/local/paths';
import React from 'react';

const SUBSCRIBED_TEXT = translate('import_location.subscribed');
const NOT_SUBSCRIBED_TEXT = translate('import_location.not_subscribed');

/** @extends {React.Component<Props, State>} */
export class ImportLocation extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		/**
		 * @type {State}
		 */
		this.state = {
			errorMessage: null,
			groupOptions: [],
			isGettingData: true,
		};

		this._handleOnToggle = this._handleOnToggle.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			const { groupsContactsCounts } =
				await this.props.agentContactService.getContactsTabs();

			this.setState({
				groupOptions: groupsContactsCounts,
				isGettingData: false,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleOnToggle() {
		const activeTab = this.props.activeTab;
		if (activeTab && activeTab !== 'allContacts') {
			this.setState({
				errorMessage: null,
			});

			return this.props.handleOnToggle();
		}

		this.setState({
			errorMessage: translate('import_location.error'),
		});
	}

	/** @returns {JSX.Element} */
	get #myContactsOption() {
		return (
			<li className="my-6">
				<FieldRadio
					label={`${translate('import_location.my_contacts')} - ${
						this.props.auth.resolveSubscription()
							? SUBSCRIBED_TEXT
							: NOT_SUBSCRIBED_TEXT
					}`}
					labelSpanClass="font-bold"
					name="importLocation"
					onClick={this.props.handleActiveTab}
					value="myContacts"
				/>
				<ul className="ml-12 text-sm">
					<li className="list-disc">
						<span className="font-bold">
							{translate('import_location.private')}
						</span>
						{translate('import_location.private_description')}
					</li>
					<li className="list-disc">
						<span className="font-bold">
							{translate('import_location.subscription')}
						</span>
						{translate('import_location.personal_subscription')}
						{this.#getSubscriptionLink()}
					</li>
				</ul>
			</li>
		);
	}

	/** @returns {JSX.Element[]} */
	get #groupOptions() {
		return this.state.groupOptions.map(
			/**
			 * @param {{hasSubscription: boolean, id: string, name: string}} option
			 * @returns {JSX.Element}
			 */
			(option) => (
				<li className="my-8" key={option.id}>
					<FieldRadio
						label={`${option.name} - ${
							this.props.auth.resolveSubscription(option.hasSubscription)
								? SUBSCRIBED_TEXT
								: NOT_SUBSCRIBED_TEXT
						}`}
						labelSpanClass="font-bold"
						name="importLocation"
						onClick={this.props.handleActiveTab}
						value={option.id}
					/>
					<ul className="ml-12 text-sm">
						<li className="list-disc">
							<span className="font-bold">
								{translate('import_location.collaborative')}
							</span>
							{translate(
								'import_location.collaborative_description'
							)}
						</li>
						<li className="list-disc">
							<span className="font-bold">
								{translate('import_location.subscription')}
							</span>
							{translate('import_location.group_subscription')}
							{this.#getSubscriptionLink(option.hasSubscription)}
						</li>
					</ul>
				</li>
			)
		);
	}

	/** @returns {JSX.Element} */
	get #options() {
		if (!this.state.groupOptions.length) {
			return this.#myContactsOption;
		}

		return (
			<React.Fragment>
				{this.#myContactsOption}
				{this.#groupOptions}
			</React.Fragment>
		);
	}

	/**
	 * @param {boolean} [hasSubscription]
	 * @returns {JSX.Element}
	 */
	#getSubscriptionLink(hasSubscription = false) {
		const isSubscribed = this.props.auth.resolveSubscription(hasSubscription);

		if (isSubscribed) {
			return (
				<ExternalAnchor
					className="hover-color-primary no-underline text-theme font-bold"
					href={paths.help.moverIntel}
				>
					{translate('import_location.mover_intel')}
				</ExternalAnchor>
			);
		}

		return (
			<Link
				className="no-underline text-theme font-bold"
				to={paths.app.agent.subscription.root}
			>
				{translate('import_location.mover_intel')}
			</Link>
		);
	}

	/** @returns {JSX.Element} */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			// @ts-ignore
			<BaseForm
				initialValues={{
					importLocation: this.props.activeTab ?? '',
				}}
				noRequiredText={true}
			>
				<fieldset className="mb-8">
					<legend className="h-ms-3 mb-6">
						{translate('import_location.title')}
					</legend>

					<section className="input-group radio-group">
						<ul className="my-6">{this.#options}</ul>
					</section>
				</fieldset>

				<BaseButton onClick={this._handleOnToggle}>
					{translate('global.import.start_import')}
				</BaseButton>

				<FormErrorMessages messages={this.state.errorMessage} />
			</BaseForm>
		);
	}
}

// @ts-ignore
export default withAgentContactService(withAuth(ImportLocation));
