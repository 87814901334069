import { NavLink } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../utils/toAbsoluteUrl';
import { withAuth } from '../../../shared/AuthProvider';
import { withSettings } from '../../../shared/SettingProvider/SettingProvider';
import InlineSVG from 'react-inlinesvg';
import React from 'react';

class NavigationButton extends React.Component {
	/**  @returns {JSX.Element} */
	render() {
		const isDisable =
			this.props?.auth?.isContactLimitExceeded &&
			this.props.settings.features.get(
				'agent:limit_contacts_on_free_accounts'
			) &&
			this.props.isCheckContactLimit &&
			this.props?.mobileDisableList?.includes(
				this.props.menuText?.toLowerCase()
			);

		if (isDisable) {
			return (
				<section className="nav-link opacity-75 ">
					<i className="icon">
						<InlineSVG src={toAbsoluteUrl(this.props.icon)} />
					</i>
					<span className="nav-text">{this.props.menuText}</span>
				</section>
			);
		}
		return (
			<NavLink className="nav-link" to={this.props.to}>
				<i className="icon">
					<InlineSVG src={toAbsoluteUrl(this.props.icon)} />
				</i>
				<span className="nav-text">{this.props.menuText}</span>
			</NavLink>
		);
	}
}

export default withAuth(withSettings(NavigationButton));
