import { translate } from 'App/Internationalization';
import AppLogo from './AppLogo';
import LinkButton from 'shared/LinkButton';
import paths from 'config/local/paths';
import React from 'react';
export default class AlternateAppHeader extends React.Component {
	render() {
		return (
			<header className="app-header">
				<section className="header-container header-layout">
					<figure className="logo-area">
						<AppLogo />
					</figure>
					<section className="contact-area">
						<LinkButton to={paths.auth.signIn}>
							{translate('global.header.login')}
						</LinkButton>
					</section>
				</section>
			</header>
		);
	}
}
