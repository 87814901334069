/**
 * @typedef {import('@mooveguru/js-utilities/types/KeyedObject').default} KeyedObject
 */

/**
 * @param {KeyedObject} values
 * @returns {FormData}
 */
export default function mapInputToFormData(values) {
	const formData = new FormData();

	Object.entries(values).forEach(([key, value]) => {
		if (value !== null) {
			formData.append(key, value);
		}
	});

	return formData;
}
