/**
 * @typedef {{leadScores: LeadScores[]}} Props
 * @typedef {import('../../../../shared/BaseMultiLineChartProps').ChartData} ChartData
 * @typedef {import('../../LeadScore/LeadScore').LeadScores} LeadScores
 */

import { translate } from '../../../Internationalization';
import BaseMultiLineChart from '../../../../shared/BaseMultiLineChart';
import React from 'react';
import utilities from '@mooveguru/js-utilities';

/** @extends {React.Component<Props>} 	*/
export default class LeadScoreChart extends React.Component {
	/** @returns {ChartData} */
	#getChartData() {
		return {
			datasets: [this.#getChartPoints()],
			labels: this.#getChartLabels(),
		};
	}

	/** @returns {ChartData['datasets'][0]} */
	#getChartPoints() {
		return {
			borderColor: 'green',
			borderWidth: 3,
			data: this.props.leadScores?.length
				? this.props.leadScores
						.reverse()
						.map((score) => score?.score ?? 0)
				: [50, 50], // default value to draw line
			label: translate('global.pages.contacts.lead_score.title'),
			lineTension: 0,
			tension: 0.1,
		};
	}

	/** @returns {string[]} */
	#getChartLabels() {
		const leadScores = this.props.leadScores?.length
			? [...this.props.leadScores]
			: [];

		if (!leadScores?.length) {
			return [
				LeadScoreChart.#getChartDate(),
				LeadScoreChart.#getChartDate(),
			];
		}

		if (leadScores.length <= 7) {
			return leadScores.map((score) =>
				LeadScoreChart.#getChartDate(score.date)
			);
		}

		if (leadScores.length <= 30) {
			return LeadScoreChart.#createChartLabels(
				LeadScoreChart.#getFilteredDates(leadScores, 7, 4), // 4 weeks
				leadScores.length
			);
		}

		return LeadScoreChart.#createChartLabels(
			LeadScoreChart.#getFilteredDates(leadScores, 29, 3), // 3 months
			leadScores.length
		);
	}

	/**
	 * In some instances, the divisor will be too large to get the minimum dates
	 * if we don't have the minimum number of lead	scores, so we need to decrease the divisor
	 * if we have 15 dates and we want 4 dates, we have to lower the divisor from 4 to 3
	 * this might produce more than 4 dates, but more is better than less
	 * @param {Props['leadScores']} leadScores
	 * @param {number} divisor
	 * @param {number} minDates
	 * @returns {string[]}
	 */
	static #getFilteredDates(leadScores, divisor, minDates) {
		const filteredDates = leadScores
			.filter((_score, index) => index % divisor === 0)
			.map((score) => LeadScoreChart.#getChartDate(score.date));

		if (filteredDates.length < minDates && divisor > 1) {
			return LeadScoreChart.#getFilteredDates(
				leadScores,
				divisor - 1,
				minDates
			);
		}

		return filteredDates;
	}

	/**
	 * @param {Date | null} date
	 * @returns {string}
	 */
	static #getChartDate(date = new Date()) {
		const dateStr = utilities.date.convertDateToDateString(
			date ? new Date(date) : new Date()
		);

		return dateStr.split(',')[0]; // removes year
	}

	/**
	 * Creates an array of given length with empty	strings
	 * @param {string[]} dates
	 * @param {number} length
	 * @returns {string[]}
	 */
	static #createChartLabels(dates, length) {
		const result = new Array(length).fill('');
		// removes first and last date
		const remainingDates = dates?.slice(1, -1);
		// gets the index values to insert the dates
		const step = Math.round((length - 2) / (remainingDates.length + 1));

		// puts the first and last date in the result array
		result[0] = dates[0];
		result[length - 1] = dates[dates.length - 1];

		remainingDates.forEach((date, index) => {
			result[(index + 1) * step] = date;
		});

		return result;
	}

	/** @returns {JSX.Element} */
	render() {
		return (
			<BaseMultiLineChart
				autoSkip={false}
				axisPadding={20}
				isCurrency={false}
				isLegend={true}
				showLegend={false}
				uniqueLabels={false}
				values={this.#getChartData()}
			/>
		);
	}
}
