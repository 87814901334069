/**
 * @typedef {import('../../../shared/OfferService/OffersProps').default} Props
 * @typedef {import('./VendorsCardState').default} State
 * @typedef {import('../../../shared/OfferService/OfferFields').default} OfferFields
 */

import { translate } from '../../../../App/Internationalization';
import { withOfferService } from '../../../../service-container';
import captureError from '../../../../utils/captureError';
import FormSuccessMessages from '../../../../shared/Forms/Messages/FormSuccessMessages';
import images from '../../../../config/local/images';
import LinkButton from '../../../../shared/LinkButton';
import Loading from '../../../../shared/Loading';
import offerCategories from '@mooveguru/yhh-shared-config/marketplace-categories.json';
import paths from '../../../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class VendorsCard extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			isGettingData: true,
			offers: [],
			total: 0,
		};
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			await this.getTableData();
		} catch (error) {
			captureError(error);

			this.setState({
				isGettingData: false,
			});
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async getTableData() {
		try {
			const response = await this.props.offerService.getAgentOffers();
			this.setState({
				isGettingData: false,
				offers: response.offers,
				total: response.total,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				isGettingData: false,
			});
		}
	}

	/**
	 * @returns {JSX.Element[]}
	 */
	setVendorData() {
		return this.state.offers.slice(0, 5).map(
			/**
			 * @param {OfferFields} offer
			 * @returns {JSX.Element}
			 */
			(offer) => (
				<li className="list-item" key={offer.id}>
					<div className="justified-row">
						<h4 className="hl-ms">{offer.vendorName}</h4>
						<div className="-h-ms-2 text-grey-700">
							{
								// @ts-ignore
								offerCategories[offer.offerCategory]
							}
						</div>
					</div>
				</li>
			)
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<section className="mt-10">
				<section className="card card-border-light">
					<header className="card-header bg-theme-light text-theme-fg-light ">
						<div className="h-ms-1">
							{translate('agent.pages.offers.view.title')}
						</div>
					</header>
					{this.props.location?.state?.message ? (
						<FormSuccessMessages
							messages={this.props.location.state.message}
						/>
					) : null}

					<div className="mx-3">
						<section className="justified-row mt-3 mb-0 border-b border-grey-300 pb-3">
							<div className="h-ms-1">
								{translate('agent.pages.offers.view.total')}
							</div>
							<div className="h-ms-1">{this.state.total}</div>
						</section>
					</div>

					<ul className="list-group card-list divided px-3">
						{this.setVendorData()}
					</ul>
				</section>

				<section className="button-group mt-4">
					<LinkButton
						className="button button-icon"
						icon={images.icons.add}
						iconClass="icon icon-fill-fg icon-sm"
						to={paths.app.agent.offers.add}
					>
						<span>{translate('global.offers.add')}</span>
					</LinkButton>

					<LinkButton
						className="button button-icon"
						icon={images.icons.seeMore}
						iconClass="icon icon-fill-fg icon-sm"
						to={paths.app.agent.offers.view}
					>
						<span>
							{translate('agent.pages.offers.view.view_all')}
						</span>
					</LinkButton>
				</section>
			</section>
		);
	}
}

export default withOfferService(VendorsCard);
