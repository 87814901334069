/**
 * @typedef {import('./AdminResponse').Props} Props
 * @typedef {import('./AdminResponse').State} State
 */

import { translate } from '../../../Internationalization';
import AdminTable from '../../shared/AdminTable';
import FormSuccessMessages from '../../../../shared/Forms/Messages/FormSuccessMessages';
import images from '../../../../config/local/images';
import LinkButton from '../../../../shared/LinkButton';
import paths from '../../../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export default class ViewAdmins extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/**
		 * @type {State}
		 */
		this.state = {
			totalRecord: 0,
		};

		this._setTotalRecords = this._setTotalRecords.bind(this);
	}

	/**
	 * @protected
	 * @param {number} number
	 * @returns {void}
	 */
	_setTotalRecords(number) {
		this.setState({
			totalRecord: number,
		});
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<React.Fragment>
				<header className="mt-10 mb-6 md:flex justify-between items-center">
					<h1 className="hl-ms-6 mb-6 md:mb-0">
						{`${translate('admin.pages.admins.view.title')} (${
							this.state.totalRecord
						})`}
					</h1>
					<LinkButton
						icon={images.icons.plus}
						to={paths.app.admin.admins.add}
					>
						{translate('admin.pages.admins.view.add')}
					</LinkButton>
				</header>
				<FormSuccessMessages
					messages={this.props.location?.state?.message}
				/>
				<AdminTable
					// @ts-ignore -- declared
					setTotalRecord={this._setTotalRecords}
				/>
			</React.Fragment>
		);
	}
}
