const rootIconPath = '/assets/icons';
const rootImagePath = '/assets/images';
const illustrationsPath = `${rootImagePath}/illustrations`;

export default {
	ads: {
		edgeHome: { logo: `${rootImagePath}/ads/edgeHome.png` },
		universalTitle: {
			logo: `${rootImagePath}/ads/universalTitle.svg`,
		},
	},
	// TODO: Convert to SVG
	avatar: `${rootImagePath}/avatar-transparent.png`,
	blog: {
		default: {
			large: `${rootImagePath}/photo-default-property-1360.jpg`,
			medium: `${rootImagePath}/photo-default-property-680.jpg`,
		},
		header: `${illustrationsPath}/header-resources.svg`,
	},
	brand: {
		favicon: `${rootImagePath}/favicon.png`,
		logo: `${rootImagePath}/logo.svg`,
	},
	footer: {
		footerMoving: `${illustrationsPath}/footer-moving.svg`,
	},
	homeowners: {
		applianceCenter: {
			header: `${illustrationsPath}/header-appliance.svg`,
		},
		checklists: {
			header: `${illustrationsPath}/header-checklist.svg`,
		},
		dashboard: {
			checklists: {
				checklistSpot: `${illustrationsPath}/checklist-spot.svg`,
			},
			finances: {
				header: `${illustrationsPath}/header-finances.svg`,
			},
		},
		documents: {
			header: `${illustrationsPath}/header-document.svg`,
		},
		finances: {
			header: `${illustrationsPath}/header-finances.svg`,
		},
		header: {
			arrow: `${rootIconPath}/arrow-dropdown.svg`,
			house: `${rootIconPath}/property-color.svg`,
		},
		professionals: {
			header: `${illustrationsPath}/header-pros.svg`,
		},
		projects: {
			header: `${illustrationsPath}/header-projects.svg`,
		},
		properties: {
			streetView: `${rootImagePath}/default-property.svg`,
		},
		utilities: {
			header: `${illustrationsPath}/header-utility.svg`,
		},
	},
	icons: {
		add: `${rootIconPath}/plus.svg`,
		admins: `${rootIconPath}/admin.svg`,
		agents: `${rootIconPath}/agents.svg`,
		alert: `${rootIconPath}/linktext-alert.svg`,
		applianceCenter: `${rootIconPath}/appliance.svg`,
		arrow: {
			down: `${rootIconPath}/ticker-arrow-down.svg`,
			dropdown: {
				normal: `${rootIconPath}/arrow-dropdown.svg`,
				small: `${rootIconPath}/arrow-dropdown-small.svg`,
			},
			right: `${rootIconPath}/arrow-right.svg`,
			up: `${rootIconPath}/ticker-arrow-up.svg`,
		},
		blog: `${rootIconPath}/resource.svg`,
		calendar: `${rootIconPath}/calendar.svg`,
		checklistColor: `${rootIconPath}/checklist-color.svg`,
		checklists: `${rootIconPath}/checklists.svg`,
		close: `${rootIconPath}/close-small.svg`,
		closeCircle: `${rootIconPath}/close-circle.svg`,
		concierge: `${rootIconPath}/starry.svg`,
		connections: `${rootIconPath}/connections.svg`,
		contactRealtor: `${rootIconPath}/linktext-contactrealtor.svg`,
		contacts: `${rootIconPath}/contacts.svg`,
		copy: `${rootIconPath}/linktext-copy.svg`,
		dashboard: `${rootIconPath}/dashboard.svg`,
		dealColor: `${rootIconPath}/deal-color.svg`,
		delete: `${rootIconPath}/close-small.svg`,
		documents: `${rootIconPath}/document.svg`,
		download: `${rootIconPath}/linktext-download.svg`,
		edit: `${rootIconPath}/linktext-edit.svg`,
		email: `${rootIconPath}/email.svg`,
		export: `${rootIconPath}/export.svg`,
		eye: `${rootIconPath}/eye.svg`,
		file: `${rootIconPath}/document.svg`,
		filter: `${rootIconPath}/filter.svg`,
		finances: `${rootIconPath}/finances.svg`,
		financesAlt: `${rootIconPath}/textlink-finance.svg`,
		folder: `${rootIconPath}/linktext-browse-file.svg`,
		groups: `${rootIconPath}/group.svg`,
		help: `${rootIconPath}/help.svg`,
		homeowners: `${rootIconPath}/linktext-property.svg`,
		import: `${rootIconPath}/linktext-import.svg`,
		info: `${rootIconPath}/linktext-info.svg`,
		infoLink: `${rootIconPath}/InfoIcon.svg`,
		leftArrow: `${rootIconPath}/chevron-left-arrow.svg`,
		link: `${rootIconPath}/linktext-link.svg`,
		lock: `${rootIconPath}/linktext-lock.svg`,
		marketplace: `${rootIconPath}/vendors.svg`,
		navigation: {
			close: `${rootIconPath}/close-small.svg`,
			open: `${rootIconPath}/menu.svg`,
		},
		offers: `${rootIconPath}/vendors.svg`,
		openInNewWindow: `${rootIconPath}/textlink-browser.svg`,
		password: {
			hide: `${rootIconPath}/password-eye-slash.svg`,
			show: `${rootIconPath}/password-eye.svg`,
		},
		phone: `${rootIconPath}/linktext-call.svg`,
		plus: `${rootIconPath}/plus.svg`,
		premium: `${rootIconPath}/linktext-premium.svg`,
		projects: `${rootIconPath}/project.svg`,
		properties: `${rootIconPath}/properties.svg`,
		rightArrow: `${rootIconPath}/chevron-right-arrow.svg`,
		scoreHistory: `${rootIconPath}/score-history.svg`,
		search: `${rootIconPath}/linktext-search.svg`,
		seeMore: `${rootIconPath}/linktext-view.svg`,
		settings: `${rootIconPath}/settings.svg`,
		signOut: `${rootIconPath}/logout.svg`,
		spinner: `${rootIconPath}/spinner.svg`,
		submit: `${rootIconPath}/linktext-submit.svg`,
		subscription: `${rootIconPath}/linktext-subscription.svg`,
		theme: `${rootIconPath}/theme.svg`,
		tickerCircle: `${rootIconPath}/ticker-circle.svg`,
		tickerDown: `${rootIconPath}/ticker-arrow-down.svg`,
		tickerFire: `${rootIconPath}/linktext-fire.svg`,
		tickerUp: `${rootIconPath}/ticker-arrow-up.svg`,
		trash: `${rootIconPath}/delete.svg`,
		upgrade: `${rootIconPath}/upgrade.svg`,
		upload: `${rootIconPath}/linktext-upload.svg`,
		user: `${rootIconPath}/linktext-contactrealtor.svg`,
		utilities: `${rootIconPath}/utility.svg`,
		widgets: `${rootIconPath}/widgets.svg`,
	},
	marketplace: {
		header: `${illustrationsPath}/header-pros.svg`,
		logoPlaceholder: `${rootImagePath}/placeholders/vendor-logo.svg`,
	},
	progressBar: `${rootImagePath}/bg-status-bar.svg`,
	registration: {
		agent: `${illustrationsPath}/profile-agent.svg`,
		homeowner: `${illustrationsPath}/profile-homeowner.svg`,
		manager: `${illustrationsPath}/profile-manager.svg`,
	},
	social: {
		facebook: `${rootIconPath}/social-facebook.svg`,
		// TODO: Replace with icon
		linkedin: `${rootIconPath}/social-linkedin.svg`,
		// TODO: Replace with icon
		pinterest: `${rootIconPath}/social-pinterest.svg`,
		twitter: `${rootIconPath}/social-twitter.svg`,
	},
	subscription: {
		alert: `${illustrationsPath}/upgrade-buyer-alert.svg`,
		exclusivity: `${illustrationsPath}/upgrade-exclusive.svg`,
		marketing: `${illustrationsPath}/upgrade-keep-in-touch.svg`,
	},
};
