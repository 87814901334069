/** @typedef {import('./NavigationListProps').LinkList} Props */

import { NavLink } from 'react-router-dom';
import { withAuth } from '../../shared/AuthProvider';
import { withSettings } from '../../shared/SettingProvider/SettingProvider';
import ExternalAnchor from '../../shared/ExternalAnchor';
import NavigationListItemContent from './NavigationListItemContent';
import React from 'react';

/** @extends {React.Component<Props>} */
class NavigationListLink extends React.Component {
	/**
	 * @param {React.ReactElement} content
	 * @returns {React.ReactElement}
	 */
	#generateLink(content) {
		if (this.props.to.includes('://')) {
			return (
				<ExternalAnchor
					className="nav-link"
					href={this.props.to}
					onClick={this.props.onListItemClick}
					renderSpan={false}
				>
					{content}
				</ExternalAnchor>
			);
		}
		const isDisable =
			this.props?.auth?.isContactLimitExceeded &&
			this.props.disable &&
			this.props.settings.features.get(
				'agent:limit_contacts_on_free_accounts'
			);

		if (isDisable) {
			return (
				<section className="nav-link opacity-50 ">{content}</section>
			);
		}
		return (
			<NavLink
				className="nav-link"
				onClick={this.props.onListItemClick}
				to={this.props.to}
			>
				{content}
			</NavLink>
		);
	}

	/**
	 * @returns {React.ReactElement}
	 */
	render() {
		/** @type {React.ReactElement} */
		const linkContent = (
			<NavigationListItemContent icon={this.props.icon}>
				{this.props.children}
			</NavigationListItemContent>
		);

		return <li className="nav-item">{this.#generateLink(linkContent)}</li>;
	}
}

export default withAuth(withSettings(NavigationListLink));
