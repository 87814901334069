/**
 * @typedef {import('./BaseTableComponent').Props} Props
 * @typedef {import('./BaseTableComponent').State} State
 */
import { translate } from '../App/Internationalization';
import NoContent from './NoContent';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export default class BaseTable extends React.Component {
	/** @returns {JSX.Element} */
	static defaultProps = {
		errorMessage: translate('global.no_content.message'),
	};

	/**
	 * @param {...(string | number)} value
	 * @returns {string}
	 */
	static #key(...value) {
		return btoa(value.join('-'));
	}

	/**
	 * @param {Props['bodyData'][0][0]} cell
	 * @param {number} parentIndex
	 * @param {number} index
	 * @returns {JSX.Element}
	 */
	#renderCell(cell, parentIndex, index) {
		// TODO: This should be in an `action` key or a more consistent way of doing this
		if (
			['Action', 'Actions'].includes(this.props.headerData[index]?.title)
		) {
			return (
				<td
					className="cell-actions"
					key={BaseTable.#key(`table-data`, parentIndex, index)}
				>
					{cell.data}
				</td>
			);
		}

		return (
			<td
				className="cell-datum"
				key={BaseTable.#key(`table-data`, parentIndex, index)}
			>
				<span className="datum-label">
					{this.props.headerData[index]?.title ?? ''}
				</span>
				<span className="datum-value">{cell.data ?? '—'}</span>
			</td>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (
			!this.props?.bodyData ||
			this.props?.bodyData?.filter(Boolean).length < 1
		) {
			return <NoContent message={this.props.errorMessage} />;
		}

		/*
		 * TODO: Should not use array indices for keys!
		 * TODO: Should map JSON arrays to table!
		 */
		const headerList = this.props.headerData.map(
			/**
			 * @param {Props['headerData'][0]} data
			 * @param {number} index
			 * @returns {JSX.Element}
			 */
			(data, index) => (
				<th
					key={BaseTable.#key(`table-head`, index, data.title)}
					scope="col"
				>
					{data.title}
				</th>
			)
		);

		// TODO: Should not use array indices for keys!
		const bodyList = this.props?.bodyData?.filter(Boolean).map(
			/**
			 * @param {Props['bodyData'][0]} row
			 * @param {number} index
			 * @returns {JSX.Element}
			 */
			(row, index) => (
				<tr key={row?.index ?? BaseTable.#key(`table-row`, index)}>
					{row.map(
						/**
						 * @param {Props['bodyData'][0][0]} cell
						 * @param {number} childIndex
						 * @returns {JSX.Element}
						 */
						(cell, childIndex) =>
							this.#renderCell(
								cell,
								row?.index ?? index,
								childIndex
							)
					)}
				</tr>
			)
		);
		return (
			<section className="overflow-x-auto">
				<table className="table-unified">
					<thead>
						<tr>{headerList}</tr>
					</thead>
					<tbody>{bodyList}</tbody>
				</table>
			</section>
		);
	}
}
