/**
 * @typedef {import('../OfferFields').default} OfferFields
 * @typedef {import('./VendorsCardProps').default} Props
 * @typedef {import('../../Users/Admins/Roles').Roles} Roles
 * @typedef {import('./VendorsCardState').default} State
 */

import { AuthContext } from '../../../../shared/AuthProvider';
import { hasCapability } from '../../../../utils/capabilities';
import { translate } from '../../../../App/Internationalization';
import { withOfferService } from '../../../../service-container';
import captureError from '../../../../utils/captureError';
import FormSuccessMessages from '../../../../shared/Forms/Messages/FormSuccessMessages';
import images from '../../../../config/local/images';
import LinkButton from '../../../../shared/LinkButton';
import Loading from '../../../../shared/Loading';
import offerCategories from '@mooveguru/yhh-shared-config/marketplace-categories.json';
import paths from '../../../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class ViewOffers extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			isGettingData: true,
			offers: [],
			total: 0,
		};

		this._setTableData = this._setTableData.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			await this._setTableData();
		} catch (error) {
			captureError(error);

			this.setState({
				isGettingData: false,
			});
		}
	}

	/**
	 * @returns {Promise<OfferFields[]>}
	 */
	setVendorData() {
		return this.state.offers.slice(0, 5).map(
			/**
			 * @param {OfferFields} offer
			 * @returns {JSX.Element}
			 */
			(offer) => (
				<li className="list-item" key={offer.id}>
					<div className="justified-row">
						<p className="hl-ms">{offer.vendorName}</p>
						<div className="-h-ms-2 text-grey-700">
							{
								// @ts-ignore
								offerCategories[offer.offerCategory]
							}
						</div>
					</div>
				</li>
			)
		);
	}

	/**
	 * @returns {boolean}
	 */
	#isDomainOffers() {
		if (hasCapability(this.context, 'view_domain')) {
			return true;
		}

		return false;
	}

	/**
	 * @protected
	 * @param {number} pageNumber
	 * @returns {Promise<void>}
	 */
	async _setTableData(pageNumber = 1) {
		try {
			const response = this.#isDomainOffers()
				? await this.props.offerService.getDomainOffers(pageNumber)
				: await this.props.offerService.getGroupOffers(pageNumber);

			this.setState({
				isGettingData: false,
				offers: response.offers,
				total: response.total,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				isGettingData: false,
			});
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<section className="mt-10">
				<section className="card card-border-light">
					<header className="card-header bg-theme-light text-theme-fg-light ">
						<div className="h-ms-1">
							{translate('agent.pages.offers.view.title')}
						</div>
					</header>
					{this.props.location?.state?.message ? (
						<FormSuccessMessages
							messages={this.props.location.state.message}
						/>
					) : null}

					<div className="mx-3">
						<section className="justified-row mt-3 mb-0 border-b border-grey-300 pb-3">
							<div className="h-ms-1">
								{translate('agent.pages.offers.view.total')}
							</div>
							<div className="h-ms-1">{this.state.total}</div>
						</section>
					</div>

					<ul className="list-group card-list divided px-3">
						{this.setVendorData()}
					</ul>
				</section>

				<section className="button-group mt-4">
					<LinkButton
						icon={images.icons.add}
						outline={false}
						to={paths.app.admin.offers.add}
					>
						<span>{translate('global.offers.add')}</span>
					</LinkButton>

					<LinkButton
						icon={images.icons.seeMore}
						outline={false}
						to={paths.app.admin.offers.view}
					>
						<span>
							{translate('agent.pages.offers.view.view_all')}
						</span>
					</LinkButton>
				</section>
			</section>
		);
	}
}

ViewOffers.contextType = AuthContext;

export default withOfferService(ViewOffers);
