import AlternateAppHeader from '../App/shared/AlternateAppHeader';
import AppFooter from '../App/shared/AppFooter';
import React from 'react';

export default class RegistrationLayout extends React.Component {
	render() {
		return (
			<section className="app-layout app-layout-single-column">
				<div className="app-content">
					<AlternateAppHeader />
					<main className="app-body">
						<article className="app-container">
							{this.props.children}
						</article>
					</main>
					<AppFooter />
				</div>
			</section>
		);
	}
}
