import { array } from '@mooveguru/js-utilities';
import HelpMessage from '../HelpMessage';
import InputLabel from '../Inputs/InputLabel';
import InputText from '../Inputs/InputText';
import React from 'react';

export default class FormField extends React.Component {
	renderHelpMessages() {
		if (!this.props.help) {
			return '';
		}

		const messageList = array.wrap(this.props.help);

		return (
			<ul className="mb-4">
				{messageList.map((message, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<li key={index}>
						<HelpMessage message={message} />
					</li>
				))}
			</ul>
		);
	}

	render() {
		return (
			<section className={this.props.className.trim()}>
				{!!this.props.label && (
					<InputLabel
						className={`input-label ${this.props.labelClass} ${
							this.props.required ? 'input-label-required' : ''
						}`.trim()}
						inputName={this.props.inputName}
						required={this.props.required}
					>
						{this.props.label}
					</InputLabel>
				)}
				{this.props.inputText ? (
					<InputText>{this.props.inputText}</InputText>
				) : null}
				{this.renderHelpMessages()}
				{this.props.children}
			</section>
		);
	}
}

FormField.defaultProps = {
	className: 'input-group',
	labelClass: '',
	required: false,
};
