import { translate } from '../../../App/Internationalization';
import ExternalAnchor from '../../../shared/ExternalAnchor';
import platformMap from '../../../config/local/spam.json';
import React from 'react';
import InlineSVG from 'react-inlinesvg/esm';
import images from '../../../config/local/images';

/**
 * @typedef {import('./SpamMessageProps').default} Props
 */

/** @extends {React.Component<Props>} */
export default class SpamMessage extends React.Component {
	/**
	 * @param {'google'|'outlook'|'yahoo'} key
	 * @returns {JSX.Element}
	 */
	static #renderLink = (key) => {
		const platform = platformMap[key] ?? null;

		return (
			<li key={key}>
				<ExternalAnchor className="text-left" href={platform.link}>
					{translate('global.forms.help.spam.link', platform.name)}
				</ExternalAnchor>
			</li>
		);
	};

	/**
	 * @returns {JSX.Element}
	 */
	static #renderLinks() {
		// @ts-ignore
		return Object.keys(platformMap).map(SpamMessage.#renderLink);
	}

	/**
	 * @returns {JSX.Element}
	 */
	/* eslint-disable class-methods-use-this -- `render()` must be callable from the class instance */
	render() {
		return (
			<section className="my-5 form-alert form-alert-warning w-fit">
				<section className="text rich-text">
					<h2 className="title">
						<i className="icon icon-xs">
							<InlineSVG src={images.icons.info} />
						</i>
						{translate('global.forms.help.spam.title')}
					</h2>
					<p>
						{translate(
							'global.forms.help.spam.message',
							'info@yourhomehub.com',
							'info@yourhomehub.com',
							'info@yourhomehub.com'
						)}
					</p>
					<ul>{SpamMessage.#renderLinks()}</ul>
				</section>
			</section>
		);
	}
	/* eslint-enable class-methods-use-this */
}
