import { Redirect } from 'react-router-dom';
import { translate } from 'App/Internationalization';
import { withAdministratorService } from 'service-container';
import AdminForm from 'App/Admin/Users/Admins/AdminForm';
import paths from 'config/local/paths';
import React from 'react';

const successMessage = translate('admin.pages.admins.add.success_message');

export class AddAdmin extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: '',
			formComplete: false,
		};

		this.handleAddAdminSubmit = this.handleAddAdminSubmit.bind(this);
	}

	async handleAddAdminSubmit(values) {
		try {
			await this.props.administratorService.createAdmin(values);

			this.setState({ errorMessage: null, formComplete: true });
		} catch (error) {
			this.setState({ errorMessage: error.message, formComplete: false });
		}
	}

	render() {
		if (this.state.formComplete) {
			return (
				<Redirect
					to={{
						pathname: paths.app.admin.admins.view,
						state: { message: successMessage },
					}}
				/>
			);
		}

		return (
			<AdminForm
				formTitle={translate('admin.pages.admins.add.form.title')}
				formSubmitText={translate('admin.pages.admins.add.form.submit')}
				onSubmit={this.handleAddAdminSubmit}
				error={this.state.errorMessage}
				success={this.state.formComplete}
			/>
		);
	}
}

export default withAdministratorService(AddAdmin);
