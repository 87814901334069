import InputPhoneNumber from 'shared/Forms/Inputs/PhoneNumber/InputPhoneNumber';
import React from 'react';

export default class InputMobilePhone extends React.Component {
	render() {
		return <InputPhoneNumber name={this.props.name} type="mobile" />;
	}
}

InputMobilePhone.defaultProps = {
	name: 'mobilePhoneNumber',
};
