/**
 * @typedef {import('./PageComponent').Props} Props
 * @typedef {import('./PageComponent').State} State
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 */
import { Route } from 'react-router-dom';
import Metadata from './Metadata';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export default class Page extends React.Component {
	static defaultProps = {
		withContainer: false,
	};

	/**
	 * @param {RouteComponentProps} renderProps
	 * @returns {JSX.Element}
	 */
	#renderComponent(renderProps) {
		const Component = this.props.component;

		/* eslint-disable-next-line react/jsx-props-no-spreading -- Necessary for HOC */
		const html = <Component {...renderProps} />;

		if (!this.props.withContainer) {
			return html;
		}

		return (
			// the id is used for skipping navigation
			<section className="app-container" id="main-container">
				{html}
			</section>
		);
	}

	render() {
		return (
			<Route
				exact={this.props.exact}
				path={this.props.path}
				render={
					/**
					 * @param {RouteComponentProps} renderProps
					 * @returns {JSX.Element}
					 */
					(renderProps) => (
						<React.Fragment>
							<Metadata title={this.props.title} />
							{this.#renderComponent(renderProps)}
						</React.Fragment>
					)
				}
			/>
		);
	}
}
