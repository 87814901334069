/**
 * @typedef {import('./MarketplaceSearchFormProps').default} Props
 */

import { translate } from '../App/Internationalization';
import BaseForm from '../shared/Forms/BaseForm';
import FieldPostalCode from '../shared/Forms/Fields/Address/FieldPostalCode';
import FieldSearchableSelect from '../shared/Forms/Fields/FieldSearchableSelect';
import images from '../config/local/images';
import marketplaceCategories from '@mooveguru/yhh-shared-config/marketplace-categories.json';
import React from 'react';
import separateObjectIntoArray from '../utils/separateObjectIntoArray';
import SubmitButton from '../shared/Forms/Inputs/SubmitButton';

/** @extends {React.Component<Props>} */
export default class MarketplaceSearchForm extends React.Component {
	/** @returns {JSX.Element} */
	render() {
		const searchCategories = separateObjectIntoArray(marketplaceCategories);

		if (this.props.agentOccupation) {
			searchCategories.filter(
				(category) => category.label !== this.props.agentOccupation
			);
		}

		const initialCategory = this.props.initialCategory
			? searchCategories.find(
					(category) => category.value === this.props.initialCategory
			  )
			: '';

		const initialFormValues = {
			category: initialCategory,
			postalCode: this.props.propertyZipCode ?? '',
		};

		const fieldPostalCode = (
			<FieldPostalCode
				className="mb-6"
				name="postalCode"
				required={true}
			/>
		);

		return (
			<BaseForm
				initialValues={initialFormValues}
				noRequiredText={true}
				onSubmit={this.props.onSubmit}
			>
				{this.props.postalCodeDisabled ? '' : fieldPostalCode}
				<section className="input-group input-inline">
					<FieldSearchableSelect
						className="h-full flex-1 my-0 mr-3"
						/* prettier-ignore */
						defaultValue={initialCategory}
						/* prettier-enable */
						isClearable={true}
						label={translate('global.local_pros_search.title')}
						labelClass="sr-only"
						name="category"
						onChange={this.props.onChange}
						options={searchCategories}
						placeholder={translate(
							'global.local_pros_search.label'
						)}
						required={true}
					/>
					<SubmitButton icon={images.icons.search} type="submit">
						{translate('global.forms.buttons.search')}
					</SubmitButton>
				</section>
			</BaseForm>
		);
	}
}
