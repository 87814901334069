/** @typedef {import('./ProfileNavigationProps').default} Props */

import { AuthContext } from '../../shared/AuthProvider';
import { translate } from '../Internationalization';
import images from '../../config/local/images';
import NavigationList from '../shared/NavigationList';
import NavigationListSignOut from './NavigationListSignOut';
import paths from '../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class ProfileNavigationList extends React.Component {
	static defaultProps = {
		color: 'info-contrasted',
		upgradeLink: false,
	};

	/**
	 * @param {string} userRole
	 * @returns {number | boolean}
	 */
	#notSubscribed(userRole) {
		return (
			this.props.upgradeLink &&
			!this.context.isSubscribed &&
			this.context.roles.some(
				/**
				 * @param {string} role
				 * @returns {boolean}
				 */
				(role) => [userRole].includes(role)
			)
		);
	}

	/** @returns {JSX.Element} */
	render() {
		const links = [
			{
				icon: images.icons.settings,
				key: 'global-settings',
				label: translate('global.nav.settings'),
				to: paths.app.profile.settings,
			},
			{
				icon: images.icons.help,
				key: 'global-help',
				label: translate('global.nav.help'),
				to: paths.help.homekeepr,
			},
		];

		if (this.#notSubscribed('agent')) {
			links.push({
				icon: images.icons.subscription,
				key: 'agent-subscription',
				label: translate('admin.nav.main.subscription'),
				to: paths.app.agent.subscription.root,
			});
		}

		if (this.#notSubscribed('owner')) {
			links.push({
				icon: images.icons.subscription,
				key: 'owner-subscription',
				label: translate('admin.nav.main.subscription'),
				to: paths.app.admin.subscription.root,
			});
		}

		return (
			<NavigationList
				appendItem={<NavigationListSignOut color={this.props.color} />}
				color={this.props.color}
				heading={translate('global.nav.account')}
				links={links}
				onListItemClick={this.props.onListItemClick}
			/>
		);
	}
}

ProfileNavigationList.contextType = AuthContext;
