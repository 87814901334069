export default class ServiceError extends Error {
	/**
	 * @type {unknown[] | undefined}
	 */
	_errors;

	/**
	 * @param {string} message
	 * @param {unknown} errors
	 */
	constructor(message, errors) {
		super(message);

		this.name = ServiceError.name;
		this.errors = errors;
	}

	/**
	 * @public
	 * @returns {unknown[]}
	 */
	get errors() {
		return this._errors || [];
	}

	/**
	 * @public
	 * @param {unknown} errorList
	 * @returns {void}
	 */
	set errors(errorList) {
		if (errorList === null || errorList === undefined) {
			this._errors = [];

			return;
		}

		if (Array.isArray(errorList)) {
			this._errors = errorList;

			return;
		}

		this._errors = [errorList];
	}
}
