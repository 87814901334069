/**
 * @typedef {import('./Auth').Props} Props
 * @typedef {import('./Auth').State} State
 */
import { AuthContext, withAuth } from '../shared/AuthProvider';
import { Redirect } from 'react-router-dom';
import { translate } from '../App/Internationalization';
import {
	withAuthService,
	withPropertyService,
	withUserService,
} from 'service-container';
import { withTheme } from '../shared/ThemeProvider';
import concatPaths from '../utils/contactPaths';
import ConfirmEmail from '../Auth/ConfirmEmail';
import Page from '../shared/Page';
import paths from '../config/local/paths';
import PublicLayout from '../shared/PublicLayout';
import React from 'react';
import ResetPassword from '../Auth/ResetPassword';
import SetPassword from '../Auth/SetPassword';
import SignIn from '../Auth/SignIn';
import Sso from '../Auth/Sso';
import Switch from '../shared/Switch';
import TransferProperty from '../Auth/TransferProperty';

/** @ts-ignore */
// fixed when HOC to pass down these props correctly
const InjectedConfirmEmail = withAuthService(ConfirmEmail);
const InjectedResetPassword = withAuth(ResetPassword);
/** @ts-ignore */
// fixed when HOC to pass down these props correctly
const InjectedSetPassword = withUserService(SetPassword);
const InjectedSignIn = withAuth(withTheme(SignIn));
const InjectedSso = withAuth(withTheme(Sso));
const InjectedTransferProperty = withPropertyService(TransferProperty);

/** @extends {React.Component<Props, State>} */
export default class Auth extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		this.rootPath = this.props.match.url;

		/** @type {State} */
		this.state = {
			redirectPath: paths.app.root,
		};
	}

	componentDidMount() {
		if (!this.context.justSignedOut) {
			this.setState({
				redirectPath:
					this.props.location?.state?.referrer ?? paths.app.root,
			});
		}
	}

	render() {
		return (
			<PublicLayout withoutHeader={true}>
				<main>
					<Switch>
						<Page
							component={InjectedConfirmEmail}
							path={this.rootPath + paths.auth.confirmEmailSlug}
							title={translate('sign_in.title_confirm_email')}
						/>
						{this.props.auth.accessToken ? (
							<Redirect
								from={this.rootPath}
								to={this.state.redirectPath}
							/>
						) : null}
						<Redirect
							exact={true}
							from={this.rootPath}
							to={concatPaths(
								this.rootPath,
								paths.auth.signInSlug
							)}
						/>
						<Page
							// eslint-disable-next-line react/no-unstable-nested-components -- TODO: Needs to be addressed
							component={(
								/** @type {any} -- TODO: Needs a type */ routeProps
							) => (
								<InjectedSignIn
									redirectPath={this.state.redirectPath}
									// eslint-disable-next-line react/jsx-props-no-spreading -- TODO: Needs to be addressed
									{...routeProps}
								/>
							)}
							path={concatPaths(
								this.rootPath,
								paths.auth.signInSlug
							)}
							title={translate('sign_in.title_sign_in')}
						/>
						<Page
							component={InjectedSso}
							path={concatPaths(
								this.rootPath,
								paths.auth.ssoSlug,
								':type',
								':issuer?'
							)}
							title={translate('sign_in.title_sso')}
						/>
						<Page
							component={InjectedResetPassword}
							path={concatPaths(
								this.rootPath,
								paths.auth.resetPasswordSlug
							)}
							title={translate(
								'sign_in.title_reset_password_email'
							)}
						/>
						<Page
							component={InjectedSetPassword}
							path={concatPaths(
								this.rootPath,
								paths.auth.setPasswordSlug
							)}
							title={translate('sign_in.title_set_password')}
						/>
						<Page
							component={InjectedTransferProperty}
							path={concatPaths(
								this.rootPath,
								paths.auth.transferPropertySlug
							)}
							title={translate(
								'sign_in.title_transfer_property_email'
							)}
						/>
					</Switch>
				</main>
			</PublicLayout>
		);
	}
}

Auth.contextType = AuthContext;
