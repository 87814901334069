import { toAbsoluteUrl } from 'utils/toAbsoluteUrl';
import React from 'react';

export default class InputImagePreview extends React.Component {
	render() {
		const filename = this.props.src.startsWith('data:')
			? 'image'
			: this.props.src.split('/').pop();

		return (
			<figure className="file-preview image-preview">
				<img
					alt={`Preview of '${filename}'`}
					className="image"
					src={toAbsoluteUrl(this.props.src)}
				/>
			</figure>
		);
	}
}
