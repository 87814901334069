/**
 * @typedef {import('../../../Auth/AuthService').default} AuthService
 * @typedef {import('./AddsAgentOffer').default} AddsAgentOffer
 * @typedef {import('./AddsOffer').default} AddsOffer
 * @typedef {import('./AddsOfferImage').default} AddsOfferImage
 * @typedef {import('./AddsGroupOffer').default} AddsGroupOffer
 * @typedef {import('./Dependencies').default} Dependencies
 * @typedef {import('./EditsOffer').default} EditsOffer
 * @typedef {import('./GetsAgentOffers').default} GetsAgentOffers
 * @typedef {import('./GetsDomainOffers').default} GetsDomainOffers
 * @typedef {import('./GetsGroupOffers').default} GetsGroupOffers
 * @typedef {import('./Offer').default} Offer
 * @typedef {import('./OfferFields').default} OfferFields
 * @typedef {import('./OfferResponse').default} OfferResponse
 * @typedef {import('./PaginatedOfferResponse').default} PaginatedOfferResponse
 * @typedef {import('./UpdatesNetworkVendors').default} UpdatesNetworkVendors
 */

import { translate } from '../../Internationalization';
import apiUrls from '../../../config/local/api-urls';
import ServiceError from '../../../shared/Errors/ServiceError';

/**
 * @implements {AddsAgentOffer}
 * @implements {AddsOffer}
 * @implements {AddsOfferImage}
 * @implements {AddsGroupOffer}
 * @implements {EditsOffer}
 * @implements {GetsAgentOffers}
 * @implements {GetsDomainOffers}
 * @implements {GetsGroupOffers}
 * @implements {UpdatesNetworkVendors}
 */
export default class OfferService {
	/**
	 * @param {Dependencies} dependencies
	 */
	constructor(dependencies) {
		this.authService = dependencies.authService;
		this.httpClient = dependencies.httpClient;
	}

	/**
	 * @returns {Promise<string>}
	 */
	async getAgentId() {
		const response = await this.httpClient.get(
			apiUrls.me.info,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);
		if (!response.isOk) {
			throw new ServiceError(
				'Unable to get agent details.',
				response?.body
			);
		}

		return response?.body?.results?.meta?.agent?._id;
	}

	/**
	 * @returns {Promise<string>}
	 */
	async getAdministratorId() {
		const response = await this.httpClient.get(
			apiUrls.me.info,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		return response.body.results.meta.administrator._id;
	}

	/**
	 * @param {number} page
	 * @returns {Promise<PaginatedOfferResponse>}
	 */
	async getAgentOffers(page = 1) {
		const params = new URLSearchParams({
			page: page.toString(),
		});

		// prettier-ignore
		const url = `${apiUrls.me.offers.view}/${await this.getAgentId()}/offers/?${params.toString()}`;

		const response = await this.httpClient.get(
			url,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new ServiceError('Unable to get offers.', response.body);
		}

		const totalCount = response.body.total;

		const offers = response.body.offers.map(
			/**
			 * @param {OfferResponse} offer
			 */
			(offer) => ({
				id: offer.id,
				offerCategory: offer.category,
				offerDescription: offer.description,
				offerName: offer.title,
				offerZip: offer.postal_code,
				status: offer.is_active,
				total: totalCount,
				vendorName: offer.vendor.name,
			})
		);

		const categoryList = response.body.category_list;
		const pages = response.body.pages;
		const total = response.body.total;

		return { categoryList, offers, pages, total };
	}

	/**
	 * @param {string} category
	 * @param {number} page
	 * @returns {Promise<PaginatedOfferResponse>}
	 */
	async getAgentOffersByCategory(category, page = 1) {
		const params = new URLSearchParams({
			page: Number(page).toString(),
		});

		// prettier-ignore
		const url = `${apiUrls.me.offers.view}/${await this.getAgentId()}/offers/categories/${category}/?${params.toString()}`;

		const response = await this.httpClient.get(
			url,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new ServiceError('Unable to get offers.', response.body);
		}

		const offers = response.body.offers.map(
			/**
			 * @param {OfferResponse} offer
			 */
			(offer) => {
				const isActive = offer.is_active;
				return {
					id: offer.id,
					offerCategory: offer.category,
					offerDescription: offer.description,
					offerName: offer.title,
					offerZip: offer.postal_code,
					status: isActive,
					vendorName: offer.vendor.name,
				};
			}
		);

		return {
			offers,
			pages: response.body.pages,
			total: response.body.total,
		};
	}

	/**
	 * @param {number} page
	 * @returns {Promise<PaginatedOfferResponse>}
	 */
	async getDomainOffers(page = 1) {
		const params = new URLSearchParams({
			page: Number(page).toString(),
		});

		const response = await this.httpClient.get(
			`${
				apiUrls.me.offers.viewDomain
			}/${await this.getAdministratorId()}/offers/?${params.toString()}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new ServiceError('Unable to get offers.', response.body);
		}

		const totalVendors = response.body.total;
		const offers = response.body.offers.map(
			/**
			 * @param {OfferResponse} offer
			 */
			(offer) => {
				const isActive = offer.is_active ? 'Active' : 'Inactive';

				return {
					id: offer.id,
					offerCategory: offer.category,
					offerDescription: offer.description,
					offerName: offer.title,
					offerZip: offer.postal_code,
					status: isActive,
					total: totalVendors,
					vendorName: offer.vendor.name,
				};
			}
		);

		const pages = response.body.pages;
		const total = response.body.total;

		return { offers, pages, total };
	}

	/**
	 * @returns {Promise<PaginatedOfferResponse>}
	 */
	async getGroupOffers(page = 1) {
		const params = new URLSearchParams({
			page: Number(page).toString(),
		});

		const response = await this.httpClient.get(
			`${
				apiUrls.me.offers.viewDomain
			}/${await this.getAdministratorId()}/offers/groups/?${params.toString()}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new ServiceError('Unable to get offers.', response.body);
		}

		const offers = response.body.offers.map(
			/**
			 * @param {OfferResponse} offer
			 */
			(offer) => {
				const isActive = offer.is_active ? 'Active' : 'Inactive';

				return {
					id: offer.id,
					offerCategory: offer.category,
					offerDescription: offer.description,
					offerName: offer.title,
					offerZip: offer.postal_code,
					status: isActive,
					vendorName: offer.vendor.name,
				};
			}
		);

		const pages = response.body.pages;
		const total = response.body.total;

		return { offers, pages, total };
	}

	/** @returns {Promise<number>} */
	async getTotalOffers() {
		const response = await this.httpClient.get(
			`${apiUrls.me.offers.view}/${await this.getAgentId()}/offers`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new ServiceError('Unable to get offers.', response.body);
		}

		return response.body.total;
	}

	/**
	 * @param {Offer} data
	 * @returns {Promise<string>}
	 */
	async addOffer(data) {
		const isActive = data.isActive === 'active';
		const response = await this.httpClient.post(
			apiUrls.me.offers.add,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			{
				/* eslint-disable camelcase -- Mapping request body */

				category: data.category,
				description: data.description,
				end_date: data.endDate,
				is_active: isActive,
				postal_code: data.postalCode,
				start_date: data.startDate,
				title: data.title ?? null,
				vendor: {
					email_address: data.vendorEmail,
					name: data.vendorName,
					phone_number: data.vendorPhone,
				},
				website_url: data.website,
				/* eslint-enable camelcase */
			}
		);

		if (!response.isOk) {
			throw new ServiceError('Unable to add offer.', response.body);
		}

		return response.body.id;
	}

	/**
	 * @param {string} offerId
	 * @returns {Promise<Offer>}
	 */
	async getSingleOffer(offerId) {
		const response = await this.httpClient.get(
			`${apiUrls.offers.root}/${offerId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new ServiceError('Unable to get offer.', response.body);
		}

		return {
			category: response.body.category,
			description: response.body.description,
			endDate: response.body.end_date,
			id: response.body.id,
			isActive: response.body.is_active,
			offerImage: response.body.image?.path ?? '',
			postalCode: response.body.postal_code,
			startDate: response.body.start_date,
			title: response.body.title,
			vendorEmail: response.body.vendor.email_address,
			vendorName: response.body.vendor.name,
			vendorPhone: response.body.vendor.phone_number,
			website: response.body.website_url,
		};
	}

	/**
	 * @param {Offer} data
	 * @param {string} offerId
	 */
	async editOffer(data, offerId) {
		const isActive = data.isActive === 'active';

		const response = await this.httpClient.put(
			`${apiUrls.offers.root}/${offerId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			{
				/* eslint-disable camelcase -- Mapping request body */

				category: data.category,
				description: data.description,
				end_date: data.endDate,
				is_active: isActive,
				postal_code: data.postalCode,
				start_date: data.startDate,
				title: data.title,
				vendor: {
					email_address: data.vendorEmail,
					name: data.vendorName,
					phone_number: data.vendorPhone,
				},
				website_url: data.website,
				/* eslint-enable camelcase */
			}
		);

		if (!response.isOk) {
			throw new ServiceError('Unable to edit offer.', response.body);
		}
	}

	/**
	 * @param {File} image
	 * @param {string} offerId
	 */
	async addOfferImage(image, offerId) {
		const formData = new FormData();
		formData.append('file', image);

		const response = await this.httpClient.put(
			`${apiUrls.offers.root}/${offerId}/image`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			formData
		);

		if (!response.isOk) {
			throw new ServiceError('Unable to add offer image.', response.body);
		}
	}

	/**
	 * @param {Offer} data
	 * @returns {Promise<string>}
	 */
	async addAgentOffer(data) {
		const isActive = data.isActive === 'active';

		const response = await this.httpClient.post(
			`${apiUrls.agents.root}/${await this.getAgentId()}/offers`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			{
				/* eslint-disable camelcase -- Mapping request body */

				category: data.category,
				description: data.description,
				end_date: data.endDate,
				is_active: isActive,
				postal_code: data.postalCode,
				start_date: data.startDate,
				title: data.title,
				vendor: {
					email_address: data.vendorEmail,
					name: data.vendorName,
					phone_number: data.vendorPhone,
				},
				website_url: data.website,
				/* eslint-enable camelcase */
			}
		);

		if (!response.isOk) {
			throw new ServiceError('Unable to add agent offer.', response.body);
		}

		return response.body.id;
	}

	/**
	 * @param {Offer} data
	 * @param {string} groupId
	 */
	async addGroupOffer(data, groupId) {
		const isActive = data.isActive === 'active';

		const response = await this.httpClient.post(
			`${apiUrls.me.offers.addGroup}/${groupId}/offers`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			{
				/* eslint-disable camelcase -- Mapping request body */

				category: data.category,
				description: data.description,
				end_date: data.endDate,
				is_active: isActive,
				postal_code: data.postalCode,
				start_date: data.startDate,
				title: data.title,
				vendor: {
					email_address: data.vendorEmail,
					name: data.vendorName,
					phone_number: data.vendorPhone,
				},
				website_url: data.website,
				/* eslint-enable camelcase */
			}
		);

		if (!response.isOk) {
			throw new ServiceError(
				translate('admin.pages.offers.error_message'),
				response.body
			);
		}
	}

	/**
	 * @param {boolean} value
	 * @param {'administrator' | 'agent'} type
	 * @returns {Promise<void>}
	 */
	async updateNetworkVendors(value, type) {
		const response = await this.httpClient.put(
			`${apiUrls.me.root}/${type}/network-vendors`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			{
				// eslint-disable-next-line camelcase
				suppress_external_vendors: value,
			}
		);

		if (!response.isOk) {
			throw new ServiceError(
				translate('api_error.update'),
				response.body
			);
		}
	}
}
