import React from 'react';
import mergeClassNames from '../utils/mergeClassNames';

export default class Card extends React.Component {
	// TODO: Remove size prop and remove from component calls
	render() {
		const baseClassName = `card card-${this.props.size} rounded bg-${this.props.color} text-${this.props.color}-contrasted p-${this.props.padding}`;

		return (
			<article
				className={mergeClassNames(baseClassName, this.props.className)}
				data-activity-click={this.props.dataActivityClick}
				id={this.props.id}
				style={this.props.style}
			>
				{this.props.children}
			</article>
		);
	}
}
