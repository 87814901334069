import { BaseSVG } from '../../BaseSVG';
import { translate } from '../../../App/Internationalization';
import FormField from './FormField';
import images from '../../../config/local/images';
import InputSelect from '../Inputs/InputSelect';
import React from 'react';

export default class FieldSelect extends React.Component {
	render() {
		const label = translate('global.forms.inputs.select.label');

		return (
			<FormField
				className={this.props.className}
				inputName={this.props.name}
				label={this.props.label ?? label}
				labelClass={this.props.labelClass}
				name={this.props.name}
				required={this.props.required}
			>
				<section className="input-stylized">
					<BaseSVG
						className="icon input-icon icon-sm"
						path={images.icons.arrow.dropdown.normal}
					/>
					<InputSelect
						className={this.props.inputClass}
						defaultValue={this.props.defaultValue}
						disabled={this.props.disabled}
						name={this.props.name}
						noDefaultOption={this.props.noDefaultOption}
						onBlur={this.props.onBlur}
						onChange={this.props.onChange}
						required={this.props.required}
						size={this.props.size}
					>
						{this.props.children}
					</InputSelect>
				</section>
			</FormField>
		);
	}
}

FieldSelect.defaultProps = {
	className: InputSelect.defaultProps.className,
	disabled: InputSelect.defaultProps.disabled,
	inputClass: InputSelect.defaultProps.className,
	labelClass: FormField.defaultProps.labelClass,
	required: InputSelect.defaultProps.required,
	size: InputSelect.defaultProps.size,
};
