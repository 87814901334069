/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */

import { translate } from '../../App/Internationalization';
import { withMailingService } from '../../service-container';
import BaseButton from '../../shared/BaseButton/BaseButton';
import captureError from '../../utils/captureError';
import Loading from '../../shared/Loading';
import NoContent from '../../shared/NoContent';
import React from 'react';
import RegistrationLayout from '../../Registration/RegistrationLayout';
import SuccessOrWarningMessage from '../../shared/Forms/Messages/SucessOrWarningMessage';
import NotFound from '../../shared/NotFound';

/** @extends {React.Component<Props, State>} */
class Subscription extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			errorMessage: null,
			isLoading: false,
			isSubscribed: true,
			recipient: null,
			sender: null,
		};

		this._handleOnResubscribe = this._handleOnResubscribe.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		const searchParams = new URLSearchParams(location.search);

		this.setState(
			{
				recipient:
					searchParams.get('recipient')?.replace(/\s/g, '+') ?? null,
				sender: searchParams.get('sender')?.replace(/\s/g, '+') ?? null,
			},
			() => {
				this.#updateSubscription(!this.state.isSubscribed);
			}
		);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #subscribe() {
		if (this.state.sender === null) {
			return;
		}

		if (this.state.recipient === null) {
			return;
		}

		await this.props.mailingService.subscribe(
			this.props.match.params.campaign,
			this.state.recipient,
			this.state.sender
		);
	}

	/**
	 * @param {boolean} subscribe
	 * @returns {Promise<void>}
	 */
	async #toggleSubscription(subscribe) {
		if (subscribe) {
			await this.#subscribe();
		} else {
			await this.#unsubscribe();
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #unsubscribe() {
		if (this.state.sender === null) {
			return;
		}

		if (this.state.recipient === null) {
			return;
		}

		await this.props.mailingService.unsubscribe(
			this.props.match.params.campaign,
			this.state.recipient,
			this.state.sender
		);
	}

	/**
	 * @param {boolean} subscribe
	 * @returns {Promise<void>}
	 */
	async #updateSubscription(subscribe) {
		this.setState({
			isLoading: true,
		});

		try {
			await this.#toggleSubscription(subscribe);

			this.setState({
				isLoading: false,
				isSubscribed: subscribe,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: subscribe
					? translate('email_notifications.subscribe.error')
					: translate('email_notifications.unsubscribe.error'),
				isLoading: false,
			});
		}
	}

	/**
	 * @protected
	 * @returns {Promise<void>}
	 */
	async _handleOnResubscribe() {
		this.#updateSubscription(true);
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	get #renderMessage() {
		if (!this.state.recipient || !this.state.sender) {
			return <NotFound />;
		}

		if (!this.state.isSubscribed) {
			return (
				<React.Fragment>
					<header className="grid items-center justify-center">
						<h1 className="h-ms-4">
							{translate('email_notifications.unsubscribe.title')}
						</h1>
					</header>
					<SuccessOrWarningMessage
						messages={translate(
							'email_notifications.unsubscribe.message',
							this.state.sender
						)}
					/>
					<p>
						{translate('email_notifications.unsubscribe.footer')}
						<BaseButton
							className="underline text-theme"
							isLinkButton={true}
							onClick={this._handleOnResubscribe}
						>
							{translate(
								'email_notifications.unsubscribe.resubscribe'
							)}
						</BaseButton>
					</p>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<header className="grid items-center justify-center">
					<h1 className="h-ms-4">
						{translate('email_notifications.subscribe.title')}
					</h1>
				</header>
				<SuccessOrWarningMessage
					messages={translate(
						'email_notifications.subscribe.message',
						this.state.sender
					)}
				/>
			</React.Fragment>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	// eslint-disable-next-line class-methods-use-this
	render() {
		if (this.state.isLoading) {
			return <Loading />;
		}

		if (this.state.errorMessage !== null) {
			return (
				<RegistrationLayout>
					<NoContent message={this.state.errorMessage} />
				</RegistrationLayout>
			);
		}

		return (
			<RegistrationLayout>
				<section className="grid items-center justify-center mt-10">
					{this.#renderMessage}
				</section>
			</RegistrationLayout>
		);
	}
}

export default withMailingService(Subscription);
