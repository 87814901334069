import { Helmet } from 'react-helmet-async';
import { ThemeContext } from '../shared/ThemeProvider';
import app from '../config/local/app';
import assetUrls from '../config/local/asset-urls';
import React from 'react';

export default class Metadata extends React.Component {
	render() {
		return (
			<Helmet
				defaultTitle={this.context.appTitle}
				title={this.props.title}
				titleTemplate={`%s | ${this.context.appTitle}`}
			>
				<link href={this.context.faviconUrl} rel="icon" />
				<link href="https://fonts.googleapis.com" rel="preconnect" />
				<link
					crossOrigin="true"
					href="https://fonts.gstatic.com"
					rel="preconnect"
				/>
				<link href={assetUrls.fonts} rel="stylesheet" />
				<meta content={this.props.description} name="description" />
				<meta content={this.context.logoUrl} name="image" />
				<meta
					content="text/html; charset=utf-8"
					httpEquiv="Content-Type"
				/>
				<meta content={this.props.author} property="author" />
				<meta
					content={this.props.date}
					property="article:published_time"
				/>
				<meta
					content={this.props.date}
					property="article:modified_time"
				/>
				<link href={this.props.canonical} rel="canonical" />

				<meta
					content={this.props.description}
					property="og:description"
				/>
				<meta content={app.meta.facebook.appId} property="fb:app_id" />
				<meta content={this.props.imageUrl} property="og:image" />
				<meta
					content={this.props.imageWidth}
					property="og:image:width"
				/>
				<meta
					content={this.props.imageHeight}
					property="og:image:height"
				/>
				<meta content={this.props.alt} property="og:image:alt" />
				<meta content={this.props.siteName} property="og:site_name" />
				<meta content={this.props.title} property="og:title" />
				<meta content={this.props.type} property="og:type" />
				<meta content={this.props.url} property="og:url" />

				<meta content="summary_large_image" name="twitter:card" />
				<meta content={this.props.url} name="twitter:url" />
				<meta content={this.props.title} name="twitter:title" />
				<meta
					content={this.props.description}
					name="twitter:description"
				/>
				<meta content={this.props.imageUrl} name="twitter:image" />
				<meta content={this.props.alt} name="twitter:image:alt" />
			</Helmet>
		);
	}
}

Metadata.contextType = ThemeContext;
