/**
 * @typedef {import('./OfferCardProps').default} Props
 */
import { BaseSVG } from '../shared/BaseSVG';
import { occupation } from '../shared/ThemeProvider/theme-utilities';
import { string } from '@mooveguru/js-utilities';
import { toAbsoluteUrl } from '../../src/utils/toAbsoluteUrl';
import { translate } from '../App/Internationalization';
import ExternalAnchorButton from '../shared/ExternalAnchorButton';
import images from '../config/local/images';
import InlineSVG from 'react-inlinesvg/esm';
import React from 'react';
/** @extends {React.Component<Props>} */
export default class OfferCard extends React.Component {
	/**
	 * @returns {string}
	 */
	#getLogoUrl() {
		if (!this.props.logoUrl) {
			return toAbsoluteUrl(images.marketplace.logoPlaceholder);
		}

		return this.props.logoUrl;
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	#renderRecommendedHeader() {
		if (!this.props.isRecommended) {
			return null;
		}

		return (
			<header className="card-header bg-theme text-theme-fg flex gap-4 justify-center">
				<i className="icon icon-fill-fg">
					<BaseSVG path={images.icons.subscription} />
				</i>
				<h2 className="card-title">
					{translate(
						'global.marketplace.offers.recommended',
						occupation(this.props.occupation)
					)}
				</h2>
			</header>
		);
	}

	/** @returns {JSX.Element | null} */
	#footer() {
		let websiteButton = null;

		if (this.props.websiteUrl) {
			websiteButton = (
				<ExternalAnchorButton
					className="button button-icon button-outline"
					dataActivityClick="marketplace_click"
					dataActivityId={this.props.name}
					href={this.props.websiteUrl}
				>
					<span>
						{translate('global.marketplace.offers.view_details')}
					</span>
					<i aria-hidden="true" className="icon icon-fill icon-sm">
						<InlineSVG src={images.icons.password.show} />
					</i>
				</ExternalAnchorButton>
			);
		}

		return (
			<footer className="card-footer">
				<section className="button-group">
					<ExternalAnchorButton
						className="button button-icon"
						data-activity-click="marketplace_click"
						data-activity-id={this.props.name}
						href={`tel:${this.props.phoneNumber}`}
					>
						<span>
							{translate('global.marketplace.offers.call_now')}
						</span>
						<i
							aria-hidden="true"
							className="icon icon-fill-fg icon-sm"
						>
							<InlineSVG src={images.icons.phone} />
						</i>
					</ExternalAnchorButton>
					{websiteButton}
				</section>
			</footer>
		);
	}

	render() {
		const descriptionText = string.truncate(
			this.props.description ?? '',
			305
		);

		const logoUrl = this.#getLogoUrl();

		return (
			<article
				className={`card card-border-light mt-auto ${
					this.props.isRecommended ? 'card-recommended' : ''
				}`}
			>
				{this.#renderRecommendedHeader()}

				<section className="card-horizontal-cta">
					<figure className="p-3">
						<img
							alt={`The ${this.props.name} logo`}
							className={
								this.props.logoUrl
									? 'card-logo'
									: 'bg-transparent w-40 mx-auto rounded'
							}
							src={logoUrl}
						/>
					</figure>

					<header className="card-header">
						<h2 className="card-title">{this.props.name}</h2>
					</header>

					<section className="card-body">
						<div className="-text-ms-1">
							<p>{descriptionText}</p>
						</div>
					</section>

					{this.#footer()}
				</section>
			</article>
		);
	}
}
