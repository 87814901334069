/**
 * @typedef {import('../Component').FilterValue} BaseFilterValue
 * @typedef {import('../Component').InputValue} BaseInputValue
 * @typedef {import('./ToggleListComponent').State} State
 */

/**
 * @template {BaseFilterValue} FilterValue
 * @template {BaseInputValue} InputValue
 * @typedef {import('./ToggleListComponent').Props<FilterValue, InputValue>} Props
 */

import { translate } from '../../../../Internationalization';
import FilterGroup from './FilterGroup';
import images from '../../../../../config/local/images';
import LinkButton from '../../../../../shared/LinkButton';
import React from 'react';

/**
 * @abstract
 * @template {BaseFilterValue} [FilterValue = BaseFilterValue]
 * @template {BaseInputValue} [InputValue = BaseInputValue]
 * @extends {React.Component<Props<FilterValue, InputValue>, State>}
 */
export default class ToggleList extends React.Component {
	_className = '';

	/**
	 * @param {Props<FilterValue, InputValue>} props
	 */
	constructor(props) {
		super(props);

		this._handleChange = this._handleChange.bind(this);
		this.state = { active: false, fields: {} };
	}

	/**
	 * @param {string} string
	 * @returns {string}
	 */
	static cleanName(string) {
		return string.toLowerCase().replace(/\s/g, '-');
	}

	/**
	 * @protected
	 * @param {string} name
	 * @returns {string}
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods, class-methods-use-this -- used by children
	_inputName(name) {
		return ToggleList.cleanName(`filters-${name}`);
	}

	/**
	 * @protected
	 * @param {string} name
	 * @param {string} label
	 * @returns {string}
	 */
	// eslint-disable-next-line react/no-unused-class-component-methods -- used by children
	_inputId(name, label) {
		return ToggleList.cleanName(
			`filters-${this._className}-${name}-${label}`
		);
	}

	/**
	 * @abstract
	 * @protected
	 * @param {string} name
	 * @param {string} label
	 * @param {FilterValue} value
	 * @returns {React.ReactNode}
	 */
	// eslint-disable-next-line class-methods-use-this, no-unused-vars
	_renderInput(name, label, value) {
		throw new Error('ToggleList._renderInput not defined!');
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_setActive() {
		this.setState((prev) => {
			let isActive = false;

			for (const value of Object.values(prev.fields)) {
				if (value === true) {
					isActive = true;
					break;
				}
			}

			return { ...prev, active: isActive };
		});
	}

	/**
	 * @protected
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @param {import('formik').FormikProps<{ [key: string]: InputValue }>} props
	 * @returns {void}
	 */
	_handleChange(event, props) {
		this.setState((prev) => {
			prev.fields[event.target.id] = event.target.checked;

			return prev;
		}, this._setActive);

		if (typeof this.props.onChange === 'function') {
			this.props.onChange(event, props);
		}
	}

	/**
	 * @protected
	 * @param {string} name
	 * @param {{ [key:string]: FilterValue }} list
	 * @returns {React.ReactNode | React.ReactNode[]}
	 */
	_renderFieldset(name, list) {
		return Object.entries(list).map(([label, value]) =>
			this._renderInput(name, label, value)
		);
	}

	/**
	 * @protected
	 * @returns {React.ReactNode | null}
	 */
	get _gatedButton() {
		if (!this.props.subscriptionLink) {
			return null;
		}

		return (
			<p className="mt-2 font-bold">
				<LinkButton
					outline={false}
					size="small"
					to={this.props.subscriptionLink}
				>
					{translate('filters.button')}
				</LinkButton>
			</p>
		);
	}

	/**
	 * @protected
	 * @param {string} title
	 * @returns {React.ReactNode}
	 */
	_renderGatedGroup(title) {
		return (
			<FilterGroup
				active={this.state.active}
				key={`filter-${this._className}-group-${title}`}
				locked={true}
				title={title}
			>
				<p>{this.props.gatedText || translate('filters.gated')}</p>
				{this._gatedButton}
			</FilterGroup>
		);
	}

	/**
	 * @protected
	 * @param {string} title
	 * @param {{ [key: string]: { [key:string]: FilterValue }}} list
	 * @param {boolean} [gated]
	 * @returns {React.ReactNode}
	 */
	_renderGroup(title, list, gated) {
		if (gated === true) {
			return this._renderGatedGroup(title);
		}

		const group = Object.entries(list).map(([name, fieldList]) =>
			this._renderFieldset(name, fieldList)
		);

		return (
			<FilterGroup
				active={this.state.active}
				key={`filter-${this._className}-group-${title}`}
				onClose={this.props.onClose}
				title={title}
			>
				{group}
			</FilterGroup>
		);
	}

	/**
	 * @returns {React.ReactNode}
	 */
	render() {
		return Object.entries(this.props.fields).map(([label, fieldList]) =>
			this._renderGroup(label, fieldList.values, fieldList.gated === true)
		);
	}
}
