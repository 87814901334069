/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 * @typedef {import('../../../../shared/SubscriptionService/Subscription').default} Subscription
 */
import { withSubscriptionService } from '../../../../../service-container';
import captureError from '../../../../../utils/captureError';
import Checkout from '../Checkout/Checkout';
import Loading from '../../../../../shared/Loading';
import React from 'react';
import NoContent from '../../../../../shared/NoContent';

/** @extends {React.Component<Props, State>} */
export class SubscriptionIndex extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			isGettingData: true,
			subscription: null,
		};
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		const subscription = await this.getAndSetSubscriptionData();

		if (this.#subscriptionExists(subscription)) {
			await this.#manageSubscription();
		}

		this.setState({ isGettingData: false });
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #manageSubscription() {
		try {
			const managementSession =
				await this.props.subscriptionService.getGroupSubscriptionManagementLink(
					this.props.match.params.groupId
				);

			window.location.href = managementSession.url;
		} catch (error) {
			captureError(error);
		}
	}

	/**
	 * @returns {Promise<Subscription | null>}
	 */
	// eslint-disable-next-line complexity -- due to lots of bindings it's exceed limit.
	async getAndSetSubscriptionData() {
		try {
			const subscription =
				await this.props.subscriptionService.getGroupSubscription(
					this.props.match.params.groupId
				);

			this.setState({
				subscription,
			})

			return subscription;
		} catch (exception) {
			this.handleException(exception);
			return null
		}
	}

	/**
	 * @param {unknown} exception
	 * @returns {void}
	 */
	handleException(exception) {
		captureError(exception);

		this.setState({
			isGettingData: false,
		});
	}

	/**
	 * @param {Subscription | null} subscription
	 * @returns {boolean}
	 */
	#subscriptionExists(subscription) {
		if (!subscription) {
			return false;
		}

		if (subscription.isActive) {
			return true;
		}

		if (subscription.isRenewing) {
			return true;
		}

		return false;
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		if (this.#subscriptionExists(this.state.subscription)) {
			return <NoContent />;
		}

		return (
			<Checkout
				// @ts-ignore -- type declared
				cobranding={this.props.location?.state?.cobranding ?? false}
				groupId={this.props.match.params.groupId}
			/>
		);
	}
}

export default withSubscriptionService(SubscriptionIndex);
