import BaseInput from 'shared/Forms/Inputs/BaseInput';
import React from 'react';

export default class InputDate extends React.Component {
	render() {
		return (
			<BaseInput
				className={this.props.className}
				inputPaddingSize="2"
				name={this.props.name}
				required={this.props.required}
				type="date"
			/>
		);
	}
}
