/** @typedef {import('./Props').default} Props */

import { toAbsoluteUrl } from '../../../utils/toAbsoluteUrl';
import { translate } from '../../../App/Internationalization';
import images from '../../../config/local/images';
import InlineSVG from 'react-inlinesvg/esm';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class FieldRequiredMessage extends React.Component {
	render() {
		return (
			<section className="form-alert my-6 w-full sm:w-fit">
				<h2 className="title">
					<i className={this.props.iconClass ?? 'icon icon-sm'}>
						<InlineSVG
							src={toAbsoluteUrl(
								this.props.icon ?? images.icons.info
							)}
						/>
					</i>
					{translate('global.forms.required_title')}
				</h2>
				<div className="text rich-text">
					{translate('global.forms.required')}
				</div>
			</section>
		);
	}
}
