/**
 * @typedef {import('./ProjectProps').default} Props
 * @typedef {import('./ProjectsState').default} State
 */

import { AuthContext } from '../../shared/AuthProvider';
import { withHomeownerService } from '../../service-container';
import apiUrls from '../../config/local/api-urls';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class Projects extends React.Component {
	/*
	 * Placing the Verisk plugin in an iframe with a giant template string is Verisk's
	 * solution for solving a11y dependency conflicts between their plugin and React
	 */
	#templateString() {
		const address = JSON.stringify({
			city: this.props.property.selectedProperty.address.city,
			country: this.props.property.selectedProperty.address.country,
			postalCode: this.props.property.selectedProperty.address.postalCode,
			stateProvince: this.props.property.selectedProperty.address.state,
			street: this.props.property.selectedProperty.address.streetAddress1,
		});

		return `
			<link rel='stylesheet' href='https://plugin.estimateon.com/styles.bundle.css' />
			<script src='https://plugin.estimateon.com/repairwise-embed.bundle.js'></script>

			<rw-main></rw-main>

			<script>
				function startRepairWise() {
					try {
						let repairWise = window.RepairWise({
							getToken: getVeriskToken,
							onCloseRepairWise: () => {
								console.log(
									'Closing RepairWise, please save any information'
								);
							},
							onCloseUrl: 'www.repairwise.com',
							saveProjectInfo: (reportInfo) => {
								console.log(reportInfo.projectId);
							},
						})
						repairWise.start({startingAddress: ${address}});
					} catch (error) {
						console.debug(error);
					}
				}

				async function getVeriskToken() {
					const myHeaders = new Headers({
						Authorization : 'Bearer ${this.context.accessToken}'
					});

					const requestOptions = {
						method: "GET",
						headers: myHeaders,
					};

					const response = await fetch(
						"${apiUrls.properties.projects.token}",
						requestOptions
					);

					const responseBodyJson = await response.json();
					return responseBodyJson.token;
				}

				startRepairWise();
			</script>

			<link
					rel='stylesheet'
					href='https://plugin.estimateon.com/assets/repairwise-style.css'
			/>
		`;
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<section className="projects-page">
				{/* eslint-disable-next-line react/iframe-missing-sandbox */}
				<iframe
					srcDoc={this.#templateString()}
					style={{ height: '46rem', width: '100%' }}
					title="EstimateON"
				/>
			</section>
		);
	}
}

// @ts-ignore until service container types are fixed
export default withHomeownerService(Projects);

Projects.contextType = AuthContext;
