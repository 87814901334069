import React from 'react';

export default class NavigationListItem extends React.Component {
	/**  @returns {JSX.Element} */
	render() {
		return (
			// TODO: Can we put a button in here and have the onclick be used in the button instead to get rid of this linter error?
			<li className="nav-item" onClick={this.props.onClick}>
				{this.props.children}
			</li>
		);
	}
}
