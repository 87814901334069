import { translate } from '../../../Internationalization';
import * as yup from 'yup';
import AddressFields from '../../../../shared/Forms/Fields/Address/AddressFields';
import BaseForm from '../../../../shared/Forms/BaseForm';
import FieldEmail from '../../../../shared/Forms/Fields/FieldEmail';
import FieldLandlinePhone from '../../../../shared/Forms/Fields/PhoneNumber/FieldLandlinePhone';
import FieldMobilePhone from '../../../../shared/Forms/Fields/PhoneNumber/FieldMobilePhone';
import FieldSearchableSelect from '../../../../shared/Forms/Fields/FieldSearchableSelect';
import FormErrorMessages from '../../../../shared/Forms/Messages/FormErrorMessages';
import LinkButton from '../../../../shared/LinkButton';
import NameFields from '../../../../shared/Forms/Fields/Name/NameFields';
import paths from '../../../../config/local/paths';
import React from 'react';
import SubmitButton from '../../../../shared/Forms/Inputs/SubmitButton';
import images from '../../../../config/local/images';

const validationSchema = yup.object().shape({
	agent: yup.string().nullable(),
	city: yup.string().nullable(),
	country: yup.string().nullable(),
	email: yup.string().nullable(),
	firstName: yup.string().nullable(),
	landline: yup.string().nullable(),
	lastName: yup.string().nullable(),
	mobile: yup.string().nullable(),
	postalCode: yup.string().nullable(),
	salutation: yup.string().nullable(),
	state: yup.string().nullable(),
	streetAddress1: yup.string().nullable(),
	streetAddress2: yup.string().nullable(),
});

export default class HomeownerForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			initialValues: {
				agent: this.props.initialValues?.agent?.[0] ?? '',
				agentName:
					this.props.initialValues?.agentName ?? '',
				city: this.props.initialValues?.city ?? '',
				country: this.props.initialValues?.country ?? '',
				email: this.props.initialValues?.email ?? '',
				firstName: this.props.initialValues?.firstName ?? '',
				landline: this.props.initialValues?.landline ?? '',
				lastName: this.props.initialValues?.lastName ?? '',
				mobile: this.props.initialValues?.mobile ?? '',
				postalCode: this.props.initialValues?.postalCode ?? '',
				state: this.props.initialValues?.state ?? '',
				streetAddress1: this.props.initialValues?.streetAddress1 ?? '',
				streetAddress2: this.props.initialValues?.streetAddress2 ?? '',
			},
		};
	}

	render() {
		return (
			<React.Fragment>
				<header className="flex items-center justify-between mt-10 mb-6">
					<h1 className="hl-ms-6">{this.props.title}</h1>

					<LinkButton
						icon={images.icons.delete}
						to={paths.app.admin.homeowners.view}
					>
						{translate('admin.pages.homeowners.form.cancel')}
					</LinkButton>
				</header>

				<BaseForm
					initialValues={this.state.initialValues}
					onSubmit={this.props.onSubmit}
					validationSchema={validationSchema}
				>
					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<legend className="h-ms-5 mb-6">
							{translate(
								'admin.pages.homeowners.form.homeowner_title'
							)}
						</legend>

						<NameFields required={true} />

						<FieldSearchableSelect
							defaultValue={{
								label: `${this.state.initialValues.agentName}`,
								value: this.state.initialValues.agent,
							}}
							label={translate(
								'admin.pages.contacts.form.agent.label'
							)}
							name="agent"
							onChange={this.props.selectOnChange}
							options={this.props.agents}
							required={true}
						/>

						<FieldEmail required={true} />
						<FieldLandlinePhone name="landline" />
						<FieldMobilePhone name="mobile" />
					</fieldset>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<legend className="h-ms-5 mb-6">
							{translate(
								'global.forms.inputs.address.section_title'
							)}
						</legend>

						<AddressFields required={true} />
					</fieldset>

					<footer className="mx-auto sm:ml-0 button-group w-min">
						<SubmitButton>{this.props.submitText}</SubmitButton>

						<LinkButton
							icon={images.icons.delete}
							size="large"
							to={paths.app.admin.homeowners.view}
						>
							{translate('admin.pages.homeowners.form.cancel')}
						</LinkButton>
					</footer>

					<FormErrorMessages messages={this.props.messages} />
				</BaseForm>
			</React.Fragment>
		);
	}
}
