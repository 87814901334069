/**
 * @typedef {'moving' | 'onboarding'} ChecklistOptions
 * @typedef {import("./ChecklistsComponent").Props} Props
 * @typedef {import("./ChecklistsComponent").State} State
 * @typedef {import("../Checklists/Task").default} Task
 */

import { AuthContext } from 'shared/AuthProvider';
import { BaseSVG } from '../../../shared/BaseSVG';
import { translate } from '../../../App/Internationalization';
import { withBreakpoint } from '../../../shared/BreakpointProvider';
import { withSettings } from '../../../shared/SettingProvider/SettingProvider';
import { withTaskService } from 'service-container';
import * as movingService from 'shared/task-service';
import captureError from '../../../utils/captureError';
import categories from '../../../config/local/checklists';
import images from '../../../config/local/images';
import Loading from '../../../shared/Loading';
import MovingChecklist from '../../../App/Homeowner/Checklists/MovingChecklist';
import OnboardingChecklist from './OnboardingChecklist';
import React from 'react';

/** @type {ChecklistOptions[]} */
// @ts-ignore type drived from this array
const checklistOptionsList = categories.checkLists;

/** @extends {React.Component<Props, State>} */
class Checklists extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);
		this.checklistSelector = React.createRef();
		this.state = {
			currentChecklist:
				checklistOptionsList.find(Boolean) ?? 'onboarding',
			isActive: false,
			isLoading: true,
			moving: [],
			onboarding: [],
		};

		this._handleDropdown = this._handleDropdown.bind(this);
		this._handleClick = this._handleClick.bind(this);
		this._getChecklists = this._getChecklists.bind(this);
		this._handleChecklist = this._handleChecklist.bind(this);
	}

	/** @type {Map<ChecklistOptions, JSX.Element>} */
	#checklists = new Map([]);

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		document.addEventListener('click', this._handleClick);
		try {
			// calling together, for hide option from dropdown, if there is no value.
			const [onboarding, moving] = await Promise.all([
				this.props.taskService.getOnboardingTasks(),
				movingService.getMovingTasks(this.context.accessToken),
			]);

			if (onboarding.length > 0) {
				this.#checklists.set(
					'onboarding',
					<OnboardingChecklist
						key="onboarding"
						// @ts-ignore - type declared in props
						taskList={this.#filterOnboardingTasks(onboarding)}
					/>
				);
				this.setState({ onboarding: onboarding });
			}

			if (moving.length > 0) {
				this.#checklists.set(
					'moving',
					<MovingChecklist key="moving" taskList={moving} />
				);
				this.setState({ moving: moving });
			}

			this.setState({
				currentChecklist:
					onboarding.length > 0 ? 'onboarding' : 'moving',
				isLoading: false,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				isLoading: false,
			});
		}
	}

	/**
	 * @returns {void}
	 */
	componentWillUnmount() {
		document.removeEventListener('click', this._handleClick);
	}

	/**
	 * @param {Task[]} taskList
	 * @returns {Task[]}
	 */
	#filterOnboardingTasks(taskList) {
		if (
			!this.props.settings.features.get(
				'homeowner:checklist_repair_estimate'
			)
		) {
			return taskList.filter((task) => task.step !== 6);
		}

		return taskList;
	}

	/**
	 * @protected
	 * @param {ChecklistOptions} key
	 * @returns {void}
	 */
	_handleChecklist(key) {
		this.setState({
			currentChecklist: key,
		});
	}

	/**
	 * @protected
	 * @param {Event} event
	 * @returns {void}
	 */
	_handleClick(event) {
		// @ts-ignore this will have an event
		const hasClickedElement = this.checklistSelector.current?.contains(
			event.target
		);

		if (hasClickedElement) {
			return;
		}

		this.setState({
			isActive: false,
		});
	}

	/**
	 * @protected
	 * @returns {(false | JSX.Element)[]}
	 */
	_getChecklists() {
		return checklistOptionsList.map((key) => {
			if (this.state[key].length === 0) {
				return false;
			}
			return (
				<li className="menu-item" key={key}>
					<button
						className="link w-full"
						onClick={() => this._handleChecklist(key)}
						type="button"
					>
						{translate(`homeowner.pages.checklists.${key}.title`)}
					</button>
				</li>
			);
		});
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleDropdown() {
		this.setState(
			/**
			 * @param {State} prevState
			 * @returns {{isActive: boolean}}
			 */
			(prevState) => ({
				isActive: !prevState.isActive,
			})
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const currentComponent = this.#checklists.get(
			this.state.currentChecklist
		);

		const componentList = {
			hero: (
				<figure key="homeowner-checklist-hero">
					<img
						alt={translate('homeowner.pages.checklists.alt')}
						className="illustration"
						src={images.homeowners.checklists.header}
					/>
				</figure>
			),
			list: (
				<section className="fancy-dropdown mt-6">
					<button
						className={`selector ${
							this.state.isActive ? 'active' : null
						}`}
						// eslint-disable-next-line react/jsx-handler-names -- this is protected function.
						onClick={this._handleDropdown}
						ref={this.checklistSelector}
						type="button"
					>
						<span className="label uppercase">
							<img
								alt={translate(
									'homeowner.pages.checklists.select_alt'
								)}
								className="icon"
								src={images.icons.checklistColor}
							/>
							{translate('homeowner.pages.checklists.listTitle')}
						</span>
						<span className="current-item">
							<span className="value">
								{translate(
									`homeowner.pages.checklists.${this.state.currentChecklist}.title`
								)}
							</span>
							<i className="icon icon-xs">
								<BaseSVG
									className="svg-icon-4"
									path={images.icons.arrow.dropdown.small}
								/>
							</i>
						</span>
					</button>
					<ul className="menu">{this._getChecklists()}</ul>
				</section>
			),
			loading: <Loading />,
			title: (
				<h1 className="hl-ms-4 my-3" key="homeowner-checklist-title">
					{translate('homeowner.pages.checklists.title')}
				</h1>
			),
		};

		if (this.props.isBelowBreakpoint) {
			return (
				<React.Fragment>
					<header>
						{componentList.title}
						{componentList.hero}
					</header>
					<section>
						{componentList.list}
						{this.state.isLoading
							? componentList.loading
							: currentComponent}
					</section>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				{componentList.title}
				<section className="app-responsive-area app-responsive-sidebar-grid">
					<section className="responsive-main app-block-list">
						{componentList.list}
						{this.state.isLoading
							? componentList.loading
							: currentComponent}
					</section>

					<section className="responsive-secondary app-block-list">
						{componentList.hero}
					</section>
				</section>
			</React.Fragment>
		);
	}
}

Checklists.contextType = AuthContext;
// @ts-ignore until service container types are fixed
export default withBreakpoint(withTaskService(withSettings(Checklists)));
