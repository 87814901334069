/**
 * @typedef {import('./HomeownerDetailsProps').default} Props
 * @typedef {import('./HomeownerDetailsState').default} State
 * @typedef {import('./HomeownerDetailsState').Value} Value
 */

import { translate } from '../../App/Internationalization';
import * as validators from '../../shared/validators';
import * as yup from 'yup';
import BaseForm from '../../shared/Forms/BaseForm';
import captureError from '../../utils/captureError';
import FieldEmail from '../../shared/Forms/Fields/FieldEmail';
import FieldFirstName from '../../shared/Forms/Fields/Name/FieldFirstName';
import FieldHomePhone from '../../shared/Forms/Fields/PhoneNumber/FieldHomePhone';
import FieldLastName from '../../shared/Forms/Fields/Name/FieldLastName';
import FieldMobilePhone from '../../shared/Forms/Fields/PhoneNumber/FieldMobilePhone';
import formatPhoneNumberForDisplay from '../../utils/formatPhoneNumberForDisplay';
import FormErrorMessages from '../../shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from '../../shared/Forms/Messages/FormSuccessMessages';
import Loading from '../../shared/Loading';
import React from 'react';
import SubmitButton from '../../shared/Forms/Inputs/SubmitButton';

const validationSchema = yup.object().shape({
	email: validators.email,
	firstName: validators.firstName,
	homePhoneNumber: validators.phoneNumber,
	lastName: validators.lastName,
	mobilePhoneNumber: validators.phoneNumber,
});

/** @extends {React.Component<Props, State>} */
export default class EditHomeownerDetailsForm extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/**
		 * @param {State} state
		 */
		this.state = {
			errorMessage: null,
			initialFormValues: {
				email: '',
				firstName: '',
				homePhoneNumber: '',
				lastName: '',
				mobilePhoneNumber: '',
			},
			isGettingData: true,
			message: null,
		};

		this.handleSubmit = this.#handleSubmit.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			const homeownerData =
				await this.props.homeownerService.getHomeownerPersonalData();
			this.setState({
				initialFormValues: {
					email: homeownerData.email,
					firstName: homeownerData.name.first,
					homePhoneNumber: homeownerData.phone.landline
						? formatPhoneNumberForDisplay(
								homeownerData.phone.landline
						  )
						: '',
					lastName: homeownerData.name.last,
					mobilePhoneNumber: homeownerData.phone.mobile
						? formatPhoneNumberForDisplay(
								homeownerData.phone.mobile
						  )
						: '',
				},
				isGettingData: false,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate(
					'admin.pages.homeowners.edit.submit_error'
				),
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {Value} values
	 * @returns {Promise<void>}
	 */
	async #handleSubmit(values) {
		try {
			await this.props.homeownerService.updateHomeownerPersonalData(
				values
			);
			this.setState({
				message: translate('settings.sections.password'),
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate('admin.pages.homeowners.edit.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 *
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<BaseForm
				className="search-form-field"
				initialValues={this.state.initialFormValues}
				onSubmit={this.handleSubmit}
				validationSchema={validationSchema}
			>
				<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
					<FieldFirstName />
					<FieldLastName />
					<FieldEmail className="col-span-full" />
					<FieldHomePhone />
					<FieldMobilePhone />
				</fieldset>

				<footer className="button-area">
					<SubmitButton>
						{translate('global.forms.buttons.save')}
					</SubmitButton>
				</footer>

				<FormErrorMessages messages={[this.state.errorMessage]} />
				<FormSuccessMessages messages={[this.state.message]} />
			</BaseForm>
		);
	}
}
