/** @typedef {import('../../shared/AuthProvider/Roles').Roles} Roles */

import { AuthContext } from '../../shared/AuthProvider';
import { getNavLinkList } from '../../config/local/navigation';
import { translate } from '../Internationalization';
import NavigationList from '../shared/NavigationList';
import React from 'react';
import RoleGate from '../shared/RoleGate';
import roles from '@mooveguru/yhh-shared-config/users/roles.json';

export default class AdminNavigationList extends React.Component {
	/**
	 * @returns {string}
	 */
	determineHeading() {
		return translate(
			`global.forms.inputs.roles.${this.getsRole() ?? 'admin'}`
		);
	}

	/**
	 * @returns {Roles}
	 */
	getsRole() {
		return this.context.roles.find(
			/**
			 * @param {Roles} role
			 * @returns {boolean}
			 */
			(role) => roles.admin.includes(role)
		);
	}

	render() {
		return (
			<RoleGate allowedRoles={roles.admin}>
				<NavigationList
					className="mb-8"
					heading={this.determineHeading()}
					links={getNavLinkList(
						this.getsRole(),
						this.context.isSubscribed,
						this.determineHeading()
					)}
					onListItemClick={this.props.onListItemClick}
				/>
			</RoleGate>
		);
	}
}

AdminNavigationList.contextType = AuthContext;
