import categoryList from '@mooveguru/yhh-shared-config/marketplace-categories.json';

/**
 * @typedef {keyof typeof categoryList} Key
 * @typedef {typeof categoryList[Key]} Label
 */

/**
 * @template {Key} Category
 * @param {Category} key
 * @returns {Label}
 */
export function getCategoryLabel(key) {
	return categoryList[key];
}

/**
 * @param {Label} label
 * @returns {Key | null}
 */
export function getCategoryValue(label) {
	for (const [key, value] of Object.entries(categoryList)) {
		if (value === label) {
			// @ts-ignore
			return key;
		}
	}

	return null;
}
