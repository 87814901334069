/**
 * @typedef {import('./InputLabelComponent').Props} Props
 * @typedef {import('./InputLabelComponent').State} State
 */

import React from 'react';

/** @extends {React.Component<Props, State>} */
export default class InputLabel extends React.Component {
	/**
	 * @returns {JSX.Element|string}
	 */
	render() {
		return (
			<label
				className={`input-label ${this.props.className ?? ''}`.trim()}
				htmlFor={this.props.inputName}
			>
				{this.props.children}
			</label>
		);
	}
}

InputLabel.defaultProps = {
	className: '',
	required: false,
};
