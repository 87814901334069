/**
 * @typedef {import('./AppLayoutComponent').Props} Props
 * @typedef {import('./AppLayoutComponent').State} State
 */
import AppFooter from './shared/AppFooter';
import AppHeader from './shared/AppHeader';
import AppMain from './shared/AppMain';
import AppSideDrawer from './AppSideDrawer';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export default class AppLayout extends React.Component {
	static defaultProps = {
		className: '',
		show: {
			agent: false,
			property: false,
		},
	};

	render() {
		return (
			<section className="app-layout">
				<AppSideDrawer />

				<section className="app-content">
					<AppHeader show={this.props.show} />
					<AppMain>{this.props.children}</AppMain>
					<AppFooter location={this.props.location} />
				</section>
			</section>
		);
	}
}
