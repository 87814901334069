/**
 * @param {Blob} blob
 * @param {string} title
 */
export default async function downloadBlob(blob, title) {
	const file = new File([blob], title);
	const url = URL.createObjectURL(file);
	const link = document.createElement('a');
	link.href = url;
	link.download = title;
	link.click();
	URL.revokeObjectURL(url);
	link.remove();
}
