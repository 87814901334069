/**
 * @typedef {import('./PropertyDetails').default} PropertyDetails
 * @typedef {import('../../../shared/PropertyService/EditablePropertyDetails').default} EditablePropertyDetails
 */
import { number, object } from '@mooveguru/js-utilities';
import { translate } from '../../../Internationalization';

const noContent = translate(
	'homeowner.pages.dashboard.properties.missing_datum'
);
/**
 * @param {EditablePropertyDetails} data
 * @param {string} dots
 * @param {(value: any) => string | number} [callback]
 * @returns {string | number}
 */
function mapDatum(data, dots, callback) {
	/** @type {unknown} */
	const value = object.get(data, dots, noContent);

	if (typeof callback === 'function') {
		return callback(value) ?? noContent;
	}

	return `${value ?? noContent}`;
}

/**
 * @param {unknown} value
 * @returns {string}
 */
export function formatNumber(value) {
	const numberValue = Number(value) ?? noContent;

	if (Number.isNaN(numberValue)) {
		return `${value ?? noContent}`;
	}

	return number.convertNumberToFormattedString(numberValue, 'en-US');
}

/**
 * @param {EditablePropertyDetails} data
 * @returns {PropertyDetails}
 */
export function mapAmenities(data) {
	return [
		{
			footer: mapDatum(data, 'details.fireplace'),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.fireplace.label'
			),
			id: 0,
		},
		{
			footer: mapDatum(data, 'details.garageCars'),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.garage_cars.label'
			),
			id: 1,
		},
		{
			footer: mapDatum(data, 'details.garageType'),
			header: translate(
				'global.forms.inputs.property_details.garage_type.label'
			),
			id: 2,
		},
		{
			footer: mapDatum(data, 'details.pool'),
			header: translate(
				'global.forms.inputs.property_details.pool.label'
			),
			id: 3,
		},
	];
}

/**
 * @param {EditablePropertyDetails} data
 * @returns {PropertyDetails}
 */
export function mapConstruction(data) {
	return [
		{
			footer: mapDatum(data, 'details.constructionType'),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.construction_type.label'
			),
			id: 0,
		},
		{
			footer: mapDatum(data, 'details.floorCover'),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.floor_cover.label'
			),
			id: 1,
		},
		{
			footer: mapDatum(data, 'details.foundationType'),
			header: translate(
				'global.forms.inputs.property_details.foundation_type.label'
			),
			id: 2,
		},
		{
			footer: mapDatum(data, 'details.interiorWalls'),
			header: translate(
				'global.forms.inputs.property_details.interior_walls.label'
			),
			id: 3,
		},
		{
			footer: mapDatum(data, 'details.plumbingFixtures'),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.plumbing_fixtures.label'
			),
			id: 4,
		},
		{
			footer: mapDatum(data, 'details.roofCover'),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.roof_cover.label'
			),
			id: 5,
		},
		{
			footer: mapDatum(data, 'details.roofType'),
			header: translate(
				'global.forms.inputs.property_details.roof_type.label'
			),
			id: 6,
		},
		{
			footer: mapDatum(data, 'details.stories'),
			header: translate(
				'global.forms.inputs.property_details.stories.label'
			),
			id: 7,
		},
		{
			footer: mapDatum(data, 'details.style'),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.style.label'
			),
			id: 8,
		},
		{
			footer: mapDatum(data, 'details.buildYear'),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.build_year.label'
			),
			id: 9,
		},
	];
}

/**
 * @param {EditablePropertyDetails} data
 * @returns {PropertyDetails}
 */
export function mapHvac(data) {
	return [
		{
			footer: mapDatum(data, 'details.heating'),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.heating.label'
			),
			id: 0,
		},
		{
			footer: mapDatum(data, 'details.heatingFuelType'),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.heating_fuel_type.label'
			),
			id: 1,
		},
	];
}

/**
 * @param {EditablePropertyDetails} data
 * @returns {PropertyDetails}
 */
export function mapRooms(data) {
	return [
		{
			footer: mapDatum(data, 'details.basement'),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.basement.label'
			),
			id: 0,
		},
		{
			footer: mapDatum(data, 'details.numberOfBaths', formatNumber),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.number_of_baths.label'
			),
			id: 1,
		},
		{
			footer: mapDatum(
				data,
				'details.numberOfPartialBaths',
				formatNumber
			),
			header: translate(
				'global.forms.inputs.property_details.number_of_partial_baths.label'
			),
			id: 2,
		},
		{
			footer: mapDatum(data, 'details.bedrooms', formatNumber),
			header: translate(
				'global.forms.inputs.property_details.bedrooms.label'
			),
			id: 3,
		},
		{
			footer: mapDatum(data, 'details.otherRooms', formatNumber),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.other_rooms.label'
			),
			id: 4,
		},
		{
			footer: mapDatum(data, 'details.totalNumberOfRooms', formatNumber),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.total_number_of_rooms.label'
			),
			id: 5,
		},
	];
}

/**
 * @param {EditablePropertyDetails} data
 * @returns {PropertyDetails}
 */
export function mapSize(data) {
	return [
		{
			footer: mapDatum(
				data,
				'details.acreage',
				number.convertNumberToFormattedString
			),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.acreage.label'
			),
			id: 0,
		},
		{
			footer: mapDatum(
				data,
				'details.lotSizeSquareFootage',
				number.convertNumberToFormattedString
			),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.lot_size_square_footage.label'
			),
			id: 1,
		},
	];
}

/**
 * @param {EditablePropertyDetails} data
 * @returns {PropertyDetails}
 */
export function mapUtilities(data) {
	return [
		{
			footer: mapDatum(data, 'details.sewer'),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.sewer.label'
			),
			id: 0,
		},
		{
			footer: mapDatum(data, 'details.water'),
			full: true,
			header: translate(
				'global.forms.inputs.property_details.water.label'
			),
			id: 1,
		},
	];
}
