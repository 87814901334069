import { withAdministratorService } from 'service-container';
import { withAuth } from 'shared/AuthProvider';
import EditAdministratorDetailsForm from './EditAdministratorDetailsForm';
import React from 'react';
import { translate } from '../Internationalization';

const InjectedEditAdministratorDetailsForm = withAuth(
	withAdministratorService(EditAdministratorDetailsForm)
);

export default class EditAdministratorDetailsCard extends React.Component {
	render() {
		return (
			<React.Fragment>
				<h2 className="h-ms-5 mb-6">
					{translate('settings.sections.admin')}
				</h2>

				<InjectedEditAdministratorDetailsForm />
			</React.Fragment>
		);
	}
}
