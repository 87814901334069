/** @typedef {import ('./MovingChecklistProps').default} Props */
/** @typedef {import ('./MovingChecklistState').default} State */

import { object } from '@mooveguru/js-utilities';
import { translate } from '../../../App/Internationalization';
import { withProperty } from '../PropertyProvider';
import { withTaskService } from '../../../service-container';
import BaseChecklist from './BaseChecklist';
import captureError from '../../../utils/captureError';
import Loading from '../../../shared/Loading';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class MovingChecklist extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			errorMessage: null,
			isGettingData: true,
			taskList: object.clone(this.props.taskList),
		};

		this.handleChecklistItemChange =
			this.#handleChecklistItemChange.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			const propertyId = this.props.property?.selectedProperty?.id;

			if (!propertyId) {
				throw new Error(
					translate(
						'homeowner.pages.dashboard.properties.property_info_error'
					)
				);
			}

			const [result] = await Promise.all([
				this.props.taskService.getMovingTasks(propertyId),
			]);

			this.setState({
				isGettingData: false,
				taskList: result,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate('homeowner.pages.checklists.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {Event & { currentTarget: HTMLInputElement }} event
	 * @param {string} taskId
	 * @returns {Promise<void>}
	 */
	async #handleChecklistItemChange(event, taskId) {
		event.preventDefault();

		try {
			await this.#toggleTask(taskId);
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate('homeowner.pages.checklists.error'),
			});
		}
	}

	/**
	 * @param {string} taskId
	 * @returns {Promise<void>}
	 */
	async #toggleTask(taskId) {
		const index = this.state.taskList.findIndex(
			(item) => item.id === taskId
		);

		if (index === -1) {
			return;
		}

		const task = this.state.taskList[index];
		const previousTaskList = this.state.taskList.slice(0, index);
		const nextTaskList = this.state.taskList.slice(index + 1);

		if (task.isComplete) {
			await this.props.taskService.undoTask(task.id);

			task.isComplete = false;

			this.setState({
				errorMessage: null,
				taskList: [...previousTaskList, task, ...nextTaskList],
			});
		} else {
			await this.props.taskService.completeTask(task.id);

			task.isComplete = true;

			this.setState({
				errorMessage: null,
				taskList: [...previousTaskList, task, ...nextTaskList],
			});
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<BaseChecklist
				dealMessage={translate(
					'homeowner.pages.checklists.moving.deal'
				)}
				description={translate(
					'homeowner.pages.checklists.moving.description'
				)}
				disabled={false}
				errorMessage={this.state.errorMessage}
				onHandleChange={this.handleChecklistItemChange}
				taskList={this.state.taskList}
				// @ts-ignore -- type declared in props.
				taskType="moving"
				title={translate('homeowner.pages.checklists.moving.title')}
			/>
		);
	}
}

// @ts-ignore
export default withTaskService(withProperty(MovingChecklist));
