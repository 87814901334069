/**
 * @typedef {import('../../shared/PropertyService/PriceHistory').default} PriceHistory
 * @typedef {import('../../shared/PropertyService/PriceHistory').default} Property
 * @typedef {import('../../shared/PropertyService/PriceHistoryResponse').default} PriceHistoryResponse
 * @typedef {import('../../shared/PropertyService/PropertyService').default} PropertyService
 * @typedef {import('./PriceHistoryProps').default} Props
 * @typedef {import('./PriceHistoryState').default} State
 */

import { translate } from '../../../App/Internationalization';
import { withProperty } from '../PropertyProvider';
import { withPropertyService } from '../../../service-container';
import BaseButton from '../../../shared/BaseButton/BaseButton';
import BaseLineChart from '../../../shared/BaseLineChart';
import BaseTable from '../../../shared/BaseTable';
import convertDateToMonthDayYear from '../../../utils/convertDateToMonthDayYear';
import formatCentsForDisplay from '../../../utils/formatCentsForDisplay';
import images from '../../../config/local/images';
import React from 'react';
import utilities from '@mooveguru/js-utilities';
import captureError from '../../../utils/captureError';
import NoContent from '../../../shared/NoContent';

/** @extends {React.Component<Props, State>} */
class PriceHistoryCard extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @param {Props} props */
		this.headerData = [
			{ title: translate('homeowner.pages.finances.table.date') },
			{ title: translate('homeowner.pages.finances.table.event') },
			{ title: translate('homeowner.pages.finances.table.price') },
		];

		/**
		 * @type {State}
		 */
		this.state = {
			bodyData: [],
			errorMessage: null,
			isShowingMore: false,
			values: [],
			xAxisValues: [],
		};

		this.setTableData = this.setTableData.bind(this);
		this.setChartData = this.setChartData.bind(this);
		this.handleShowMore = this.handleShowMore.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		await this.getPriceHistoryData();
	}

	/**
	 * @returns {Promise<void>}
	 */
	async getPriceHistoryData() {
		try {
			const propertyId = this.props?.property?.selectedProperty?.id;

			if (!propertyId) {
				return;
			}
			const priceHistory =
				await this.props.propertyService.getPriceHistory(propertyId);

			if (!priceHistory) {
				return;
			}
			await this.setTableData(priceHistory);
			await this.setChartData(priceHistory);
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('homeowner.pages.finances.view.error'),
			});
		}
	}

	/**
	 * @param {PriceHistory[]} priceHistory
	 * @returns {Promise<void>}
	 */
	async setTableData(priceHistory) {
		/** @type {[{ data: string }, { data: string }, { data: string }][]} */
		let bodyData = [];

		priceHistory.forEach(
			/**
			 * @param {Property} property
			 * @returns {void}
			 */
			(property) => {
				bodyData.push([
					{ data: convertDateToMonthDayYear(property.date) },
					{
						data: utilities.string.convertToTitleCase(
							property.listingStatus
						),
					},
					{ data: formatCentsForDisplay(property.price, 0) },
				]);
			}
		);

		bodyData = bodyData.reverse();

		/**
		 *  @param {State} prevState
		 *  @returns {Promise<void>}
		 */
		this.setState((prevState) => ({
			...prevState,
			bodyData,
		}));
	}

	/**
	 * @param {PriceHistory[]} priceHistoryResponse
	 * @returns {Promise<void>}
	 */
	async setChartData(priceHistoryResponse) {
		const xAxisValues = priceHistoryResponse.map(
			/**
			 * @param {Property} property
			 * @returns {number}
			 */
			(property) => property.date.getFullYear()
		);

		const values = priceHistoryResponse.map(
			/**
			 * @param {Property} property
			 * @returns {number}
			 */
			(property) => property.price / 100
		);

		/**
		 *  @param {State} prevState
		 *  @returns {Promise<void>}
		 */
		this.setState((prevState) => ({
			...prevState,
			values: values,
			xAxisValues: xAxisValues,
		}));
	}

	/**
	 * @returns {void}
	 */
	handleShowMore() {
		/**
		 *  @param {State} prevState
		 *  @returns {Promise<void>}
		 */
		this.setState((prevState) => ({
			...prevState,
			isShowingMore: !prevState.isShowingMore,
		}));
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	seeMoreButton() {
		const buttonTextKey = this.state.isShowingMore
			? 'see_less'
			: 'see_more';
		const buttonText = translate(
			`homeowner.pages.finances.view.${buttonTextKey}`
		);

		if ((this.state.bodyData.length ?? 0) < 3) {
			return null;
		}

		return (
			<footer className="mt-3">
				<BaseButton
					className="mx-auto"
					icon={images.icons.eye}
					onClick={this.handleShowMore}
					outline={true}
					type="button"
				>
					{buttonText}
				</BaseButton>
			</footer>
		);
	}

	/** @returns {JSX.Element | null} */
	render() {
		if (this.state.errorMessage || this.state.bodyData.length < 1) {
			return (
				<section>
					<header className="h-ms-4 mb-4">
						{translate('homeowner.pages.finances.view.price.title')}
					</header>

					<NoContent />
				</section>
			);
		}

		const values = this.state.values ? [...this.state.values] : null;

		const xaxisValues = this.state.xAxisValues
			? [...this.state.xAxisValues]
			: null;

		return (
			<section>
				<header className="h-ms-4 mb-4">
					{translate('homeowner.pages.finances.view.price.title')}
				</header>

				<section className="grid md:grid-cols-2 sm:grid-cols-1 gap-5">
					<section className="card-body h-full">
						<div className="w-full h-full relative">
							<BaseLineChart
								values={values}
								xAxisValues={xaxisValues}
							/>
						</div>
					</section>

					<section>
						<BaseTable
							// @ts-ignore issue with BaseTable Props
							bodyData={
								this.state.isShowingMore
									? this.state.bodyData
									: this.state.bodyData.slice(0, 4)
							}
							className="table-bordered mt-5"
							errorMessage={translate(
								'homeowner.pages.finances.view.error'
							)}
							headerData={this.headerData}
						/>
						{this.seeMoreButton()}
					</section>
				</section>
			</section>
		);
	}
}

export default withPropertyService(withProperty(PriceHistoryCard));
