/**
 * @typedef {import('./AgentRegistrationForm/Values').default} FormValues
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */

import { Redirect } from 'react-router-dom';
import { translate } from '../../App/Internationalization';
import { withAuth } from '../../shared/AuthProvider';
import { withRegistrationService } from '../../service-container';
import AgentRegistrationForm from './AgentRegistrationForm';
import captureError from '../../utils/captureError';
import FormErrorMessages from '../../shared/Forms/Messages/FormErrorMessages';
import paths from '../../config/local/paths';
import React from 'react';
import SpamMessage from '../../shared/Forms/Messages/SpamMessage';

/** @extends {React.Component<Props, State>} */
export class RegisterAgent extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			message: null,
			registrationWasSuccessful: false,
		};

		this._handleSubmit = this._handleSubmit.bind(this);
	}

	/**
	 * @protected
	 * @param {FormValues} values
	 * @returns {Promise<void>}
	 */
	async _handleSubmit(values) {
		try {
			await this.props.registrationService.registerAgent({
				emailAddress: values.emailAddress,
				groupSlug: this.props.match.params.whiteLabelSlug || undefined,
				name: { first: values.firstName, last: values.lastName },
				occupation: values.occupation,
				password: values.password,
				postalCode: values.postalCode,
			});

			await this.#signIn(values.emailAddress, values.password);

			this.setState({ registrationWasSuccessful: true });
		} catch (error) {
			captureError(error);

			// Message from backend contains useful information about user data
			this.setState({
				message:
					error instanceof Error
						? error.message
						: translate('registration.getting_contact_information'),
			});
		}
	}

	/**
	 * @param {string} emailAddress
	 * @param {string} password
	 * @returns {Promise<void>}
	 */
	async #signIn(emailAddress, password) {
		try {
			await this.props.auth.signIn(emailAddress, password);
		} catch (error) {
			this.setState({
				message: translate('sign_in.generic_sign_in_error'),
			});
		}
	}

	// TODO: will update the logic for redirect to dashboard/subscription page after this task is merged YHH-5444
	/**
	 * @returns {string}
	 */
	#getRedirectPath() {
		if (this.props.match.params.whiteLabelSlug !== undefined) {
			return `/${this.props.match.params.whiteLabelSlug}${paths.auth.signIn}`;
		}
		return paths.auth.signIn;
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.registrationWasSuccessful) {
			return (
				<Redirect
					to={{
						pathname: this.#getRedirectPath(),
						state: {
							message: translate('registration.success'),
							referrer: this.props.referrer,
						},
					}}
				/>
			);
		}

		return (
			<React.Fragment>
				<AgentRegistrationForm onSubmit={this._handleSubmit} />
				<section className="form-message-area">
					<FormErrorMessages messages={this.state.message} />
					<SpamMessage />
				</section>
			</React.Fragment>
		);
	}
}

export default withAuth(withRegistrationService(RegisterAgent));
