import { translate } from 'App/Internationalization';
import FieldRadioGroup from './FieldRadioGroup';
import FormField from 'shared/Forms/Fields/FormField';
import InputRadio from 'shared/Forms/Inputs/InputRadio';
import React from 'react';

export default class FieldStatus extends React.Component {
	/**
	 * @private
	 * @type {['active', 'disabled']}
	 */
	statusList = ['active', 'disabled'];

	render() {
		/** @type {[ 'active'|'disabled', string ][]}*/
		const options = [];
		const translationPath = 'global.forms.inputs.status';
		const label = translate('global.forms.inputs.status.label');

		this.statusList.forEach((status) => {
			options.push([status, translate(`${translationPath}.${status}`)]);
		});

		return (
			<FieldRadioGroup
				inputClass={this.props.inputClass}
				labelClass={this.props.labelClass}
				name={this.props.name}
				title={label}
				values={options}
			/>
		);
	}
}

FieldStatus.defaultProps = {
	className: InputRadio.defaultProps.className,
	disabled: InputRadio.defaultProps.disabled,
	inputClass: InputRadio.defaultProps.className,
	label: 'Status',
	labelClass: FormField.defaultProps.labelClass,
	required: InputRadio.defaultProps.required,
};
