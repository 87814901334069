import { translate } from '../../../../App/Internationalization';
import concatPaths from '../../../../utils/contactPaths';
import Index from '../../../../App/Admin/Groups/Subscription/SubscriptionIndex';
import Page from '../../../../shared/Page';
import paths from '../../../../config/local/paths';
import React from 'react';

export default class Subscription extends React.Component {
	/**
	 * @returns {JSX.Element}
	 */
	// eslint-disable-next-line class-methods-use-this -- render() must be callable
	render() {
		return (
			<section>
				<Page
					component={Index}
					exact={true}
					path={concatPaths(
						paths.app.admin.groups.root,
						'/:groupId/subscription'
					)}
					title={translate('subscription.title')}
				/>
			</section>
		);
	}
}
