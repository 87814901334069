import { toAbsoluteUrl } from '../utils/toAbsoluteUrl';
import InlineSVG from 'react-inlinesvg';
import React from 'react';

/** @extends {React.Component<{ readonly defaultSize: boolean, readonly icon: string }>} */
export default class BaseIcon extends React.Component {
	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const defaultSize = this.props.defaultSize ? '' : 'icon-sm ';

		return (
			<i className={`${defaultSize}icon-fill icon`}>
				<InlineSVG src={toAbsoluteUrl(this.props.icon)} />
			</i>
		);
	}
}
