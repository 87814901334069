/**
 * @typedef {import('./BaseHeroProps').default} Props
 */
import { toAbsoluteUrl } from '../utils/toAbsoluteUrl';
import React from 'react';
/** @extends {React.Component<Props, {}>} */
export default class BaseHero extends React.Component {
	/**
	 * @returns {JSX.Element | null}
	 */
	#renderImage() {
		if (!this.props.heroImageUrl) {
			return null;
		}

		return (
			<figure>
				<img
					alt={this.props.heroImageAlt ?? ''}
					className="illustration"
					src={toAbsoluteUrl(this.props.heroImageUrl)}
				/>
			</figure>
		);
	}

	render() {
		return (
			<header className="mt-10">
				<h1 className="hl-ms-6 mb-4">{this.props.title}</h1>
				{this.#renderImage()}
			</header>
		);
	}
}
