import { BaseSVG } from '../../BaseSVG';
import { Field } from 'formik';
import { translate } from '../../../App/Internationalization';
import images from '../../../config/local/images';
import React from 'react';

export default class InputSelect extends React.Component {
	render() {
		return (
			<Field name={this.props.name}>
				{(renderProps) => {
					const initialValues = renderProps.form.initialValues;

					if (
						this.props.defaultValue &&
						!initialValues?.[this.props.name]
					) {
						// Set initialValue to defaultValue prop passed
						initialValues[this.props.name] =
							this.props.defaultValue;
					}

					return (
						<section className="input-stylized">
							<BaseSVG
								className="input-icon"
								path={images.icons.arrow.dropdown.normal}
							/>
							<select
								className={`${this.props.className} input`}
								disabled={this.props.disabled}
								id={this.props.id ?? this.props.name}
								name={renderProps.field.name}
								onBlur={renderProps.field.onBlur}
								onChange={
									this.props.onChange
										? (event) => this.props.onChange(event, renderProps) // prettier-ignore
										: renderProps.field.onChange
								}
								required={this.props.required}
								size={this.props.size}
								value={renderProps.field.value}
							>
								<React.Fragment>
									{this.props.noDefaultOption && (
										<NoDefaultOption />
									)}
									{this.props.children}
								</React.Fragment>
							</select>
						</section>
					);
				}}
			</Field>
		);
	}
}

InputSelect.defaultProps = {
	className: '',
	defaultValue: '',
	disabled: false,
	required: false,
	size: 1,
};

// This ensures that the first viable option is not selected by default
// while still allowing state to be controlled by another component
function NoDefaultOption() {
	return (
		<option value="">
			{translate('global.actions.select')}
			{'...'}
		</option>
	);
}
