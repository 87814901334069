/**
 * @typedef {import('../../../shared/ApplianceService/ApplianceService').default} ApplianceService
 * @typedef {import('../../shared/HomeownerService/Address').default} Address
 * @typedef {import('./DashboardProps').default} Props
 * @typedef {import('./DashboardState').default} State
 */
import { translate } from '../../Internationalization';
import {
	withApplianceService,
	withPropertyService,
} from '../../../service-container';
import adList from '../../../config/local/homeowner/ads';
import AppliancesCard from '../../Homeowner/ApplianceCenter/shared/AppliancesCard';
import BannerAd from '../../../shared/BannerAds/BannerAds';
import BaseHero from '../../../shared/BaseHero';
import captureError from '../../../utils/captureError';
import Feature from '../../../shared/Feature';
import images from '../../../config/local/images';
import Loading from '../../../shared/Loading';
import ManualsCard from '../../Homeowner/ApplianceCenter/shared/ManualsCard';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class Dashboard extends React.Component {
	#advertisement = adList.appliances.cinch;

	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		this.state = {
			applianceList: [],
			isGettingData: true,
		};
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			await this.getApplianceStatistics();

			this.setState({
				isGettingData: false,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				isGettingData: false,
			});
		}
	}

	async getApplianceStatistics() {
		const propertyId = this.props.property.selectedProperty.id;

		const applianceList = await this.props.applianceService.getAppliances(
			propertyId
		);

		this.setState({
			applianceList: applianceList,
		});
	}

	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<React.Fragment>
				<BaseHero
					heroImageUrl={images.homeowners.applianceCenter.header}
					title={translate('homeowner.pages.appliance_center.title')}
				/>

				<ManualsCard />

				<AppliancesCard applianceList={this.state.applianceList} />

				<Feature fallback={null} name="homeowner:appliances_ad">
					<BannerAd
						buttonText={this.#advertisement.buttonText}
						logo={this.#advertisement.logo}
						text={this.#advertisement.copy}
						textFooter={this.#advertisement.textFooter}
						textLead={this.#advertisement.textLead}
						title={this.#advertisement.title}
						url={this.#advertisement.url}
					/>
				</Feature>
			</React.Fragment>
		);
	}
}

// @ts-ignore -- will fixed with service container
export default withApplianceService(withPropertyService(Dashboard));
