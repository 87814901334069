import { translate } from '../../../App/Internationalization';
import Index from '../../../App/Agent/Subscription/SubscriptionIndex';
import Page from '../../../shared/Page';
import paths from '../../../config/local/paths';
import React from 'react';

export default class Subscription extends React.Component {
	/**
	 * @returns {JSX.Element}
	 */
	// eslint-disable-next-line class-methods-use-this -- render() must be callable
	render() {
		return (
			<section>
				<Page
					component={Index}
					exact={true}
					path={paths.app.agent.subscription.root}
					title={translate('subscription.title')}
				/>
			</section>
		);
	}
}
