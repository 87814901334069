/**
 * @typedef {import('./State').AgentContact} Contact
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */

import { translate } from '../../../Internationalization';
import { withAgentContactService } from '../../../../service-container';
import { withAuth } from '../../../../shared/AuthProvider';
import captureError from '../../../../utils/captureError';
import GenericContactsTable from './Generic/GenericContactsTable';
import GenericMoversOverlay from './Generic/GenericMoversOverlay';
import LeadScoreBoard from './LeadScoreBoard/LeadScoreBoard';
import Loading from '../../../../shared/Loading';
import Movers from './Movers';
import NoContent from '../../../../shared/NoContent';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class ContactAnalysis extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		this.state = {
			contactList: [],
			errorMessage: null,
			isGettingData: true,
			moverCounts: [],
		};
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		if (!this.props.auth?.agentSubscriptionLevels?.length) {
			this.setState({
				isGettingData: false,
			});
			return;
		}

		await this.#setContactData();
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #setContactData() {
		try {
			const response = await this.props.agentContactService.getContacts({
				limit: 5,
				'sort[lead_scores.0.score]': 'desc',
				'sort[name.last]': 'asc',
				// eslint-disable-next-line sort-keys
				'sort[name.first]': 'asc',
			});

			this.setState({
				contactList: response.contacts,
				isGettingData: false,
				moverCounts: response.moverCounts,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	get #renderAnalysis() {
		if (!this.props.auth?.agentSubscriptionLevels?.length) {
			return (
				<React.Fragment>
					<GenericMoversOverlay />
					<GenericContactsTable />
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<Movers moverCounts={this.state.moverCounts} />
				<LeadScoreBoard contactList={this.state.contactList} />
			</React.Fragment>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		if (this.state.errorMessage !== null) {
			return <NoContent message={this.state.errorMessage} />;
		}

		return <section className="my-5">{this.#renderAnalysis}</section>;
	}
}

// @ts-ignore
export default withAgentContactService(withAuth(ContactAnalysis));
