/**
 * @typedef {import ('../Documents/Document').default} Document
 * @typedef {import ('./ListDocumentsProps').default} Props
 * @typedef {import ('./ListDocumentsState').default} State
 */
import { AuthContext } from 'shared/AuthProvider';
import { object } from '@mooveguru/js-utilities';
import { translate } from '../../Internationalization';
import {
	withDocumentsService,
	withPropertyService,
} from '../../../service-container';
import { withProperty } from '../PropertyProvider';
import BaseHero from '../../../shared/BaseHero';
import captureError from '../../../utils/captureError';
import categoryList from '@mooveguru/yhh-shared-config/document-categories.json';
import DocumentCategoryList from './DocumentCategoryList';
import downloadBlob from '../../../utils/downloadBlob';
import fileTypes from '../../../config/local/file-types';
import FormErrorMessages from '../../../shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from '../../../shared/Forms/Messages/FormSuccessMessages';
import images from '../../../config/local/images';
import Loading from '../../../shared/Loading';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class ListDocuments extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			documentErrorMessage: null,
			documents: {},
			errorMessage: null,
			isGettingData: true,
		};

		this.deleteDocument = this.deleteDocument.bind(this);
		this.downloadDocument = this.downloadDocument.bind(this);
	}

	/**
	 * @param {unknown} error
	 * @returns {void}
	 */
	#error(error) {
		captureError(error);

		this.setState({ errorMessage: translate('global.error') });
	}

	/**
	 * @param {string} documentId
	 */
	async deleteDocument(documentId) {
		try {
			await this.props.propertyService.deletePropertyDocument(
				this.props.property.selectedProperty?.id,
				documentId
			);

			this.setState((prevState) => {
				const documents = { ...prevState.documents };

				Object.entries(documents).forEach(
					([category, categoryDocuments]) => {
						if (Array.isArray(categoryDocuments)) {
							documents[category] = categoryDocuments.filter(
								(document) => document.id !== documentId
							);
						}
					}
				);

				return { documents };
			});
		} catch (error) {
			this.#error(error);
		}
	}

	/**
	 *  @param {Props} prevProps
	 *  @returns {void}
	 */
	componentDidUpdate(prevProps) {
		/*
		 * This checks if the previous property data from PropertyProvider is the same as current property data.
		 * If they are not equal then update local state with new property data.
		 */
		if (
			JSON.stringify(this.props.property?.selectedProperty) ===
			JSON.stringify(prevProps.property?.selectedProperty)
		) {
			return;
		}

		const documentList = ListDocuments.#sortDocuments(
			this.props.property?.selectedProperty?.documents
		);

		this.setState({
			documents: documentList,
			errorMessage: null,
			isGettingData: false,
		});
	}

	/** @returns {Promise<void>} */
	async componentDidMount() {
		const property = this.props.property.propertyList.find(
			(listing) => listing.id === this.props.property.selectedProperty?.id
		);

		if (property === undefined) {
			this.setState({
				documents: [],
				errorMessage: null,
				isGettingData: false,
			});

			return;
		}

		const documentList = ListDocuments.#sortDocuments(property.documents);

		this.setState({
			documents: documentList,
			errorMessage: null,
			isGettingData: false,
		});
	}

	/**
	 * @param {string} documentId
	 * @param {string} title
	 */
	async downloadDocument(documentId, title) {
		const response =
			await this.props.documentsService.getDocumentDownloadLink(
				documentId
			);

		if (!response) {
			return;
		}

		downloadBlob(response, title);
	}

	/**
	 * @template {(Document & { id: string }) } Type
	 * @param {Type[] | undefined} documents
	 * @returns {{ [key: Type['category']]: Document[] }}
	 */
	static #sortDocuments(documents) {
		if (!documents) {
			return [];
		}

		const unassignedKey = Object.keys(categoryList)[0];
		const unassignedValue = Object.values(categoryList)[0];

		const documentList = documents.map((document) => ({
			category:
				// @ts-ignore Falls back to default if key doesn't exist
				categoryList[document.category ?? unassignedKey] ??
				unassignedValue,
			createdAt: document.createdAt,
			id: document.id,
			src: document.src,
			title: document.title,
			// @ts-ignore Falls back to default if key doesn't exist
			type: fileTypes[document.src?.split('.').pop() ?? ''] || null,
		}));

		documentList.sort(
			/**
			 *
			 * @param {Document} a
			 * @param {Document} b
			 * @returns {-1|0|1}
			 */
			(a, b) => {
				const comparison =
					a.category?.localeCompare(b?.category ?? '') ?? 0;

				if (comparison === 0) {
					return 0;
				}

				return comparison >= 1 ? 1 : -1;
			}
		);

		return object.groupBy(documentList, 'category');
	}

	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<React.Fragment>
				<div className="pb-6">
					<BaseHero
						heroImageUrl={images.homeowners.documents.header}
						title={translate('homeowner.pages.documents.title')}
					/>
				</div>

				{this.state.errorMessage ? (
					<FormErrorMessages messages={[this.state.errorMessage]} />
				) : null}
				{this.props.location?.state?.message ? (
					<FormSuccessMessages
						messages={this.props.location.state.message}
					/>
				) : null}

				<section className="listing listing-col-size-2">
					<DocumentCategoryList
						categories={this.state.documents}
						deleteDocument={this.deleteDocument}
						downloadOnClick={this.downloadDocument}
					/>
				</section>

				<FormErrorMessages messages={this.state.documentErrorMessage} />
			</React.Fragment>
		);
	}
}

ListDocuments.contextType = AuthContext;

// @ts-ignore, HOC type needs to be fixed
export default withDocumentsService(
	withPropertyService(withProperty(ListDocuments))
);
