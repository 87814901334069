/**
 * @typedef {{ isGettingData: boolean; valuations: Map<string, Valuation[]> | null}} State
 * @typedef {{ propertyService: PropertyService;  property: Readonly<Property> }} Props
 * @typedef {import('../../../App/Homeowner/PropertyProvider/PropertyContext').default} Property
 * @typedef {import('../../shared/PropertyService/PropertyService').default} PropertyService
 * @typedef {import('../PropertyService/Valuation').default} Valuation
 */

import { translate } from '../../../App/Internationalization';
import { withProperty } from '../../Homeowner/PropertyProvider';
import { withPropertyService } from '../../../service-container';
import captureError from '../../../utils/captureError';
import formatCentsForDisplay from '../../../utils/formatCentsForDisplay';
import Loading from '../../../shared/Loading';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class SeeWhatYouCanGet extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/**
		 * @type {State}
		 */
		this.state = {
			isGettingData: true,
			valuations: null,
		};
	}

	async componentDidMount() {
		try {
			const propertyId = this.props.property?.selectedProperty?.id;

			if (!propertyId) {
				throw new Error(
					translate(
						'homeowner.pages.dashboard.properties.property_info_error'
					)
				);
			}

			const valuations = await this.props.propertyService.getValuations(
				propertyId
			);
			this.setState({
				isGettingData: false,
				valuations,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				isGettingData: false,
			});
		}
	}

	/**
	 * @returns {{ max: number, min: number }}
	 */
	getAVMValuationsRange() {
		/** @type {number[]} */
		const range = [];

		// @ts-ignore function wont be called if valuation is null
		[...this.state.valuations.values()].flat().forEach((valuation) => {
			if (valuation.valueLow) {
				range.push(valuation.valueLow);
			}

			if (valuation.valueHigh) {
				range.push(valuation.valueHigh);
			}
		});

		if (range.length === 0) {
			range.push(0);
		}

		return {
			max: Math.max(...range),
			min: Math.min(...range),
		};
	}

	/** @returns {JSX.Element | null} */
	render() {
		if (!this.state.valuations) {
			return null;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		const range = this.getAVMValuationsRange();

		return (
			<section>
				<h2 className="h-ms-4 text-center">
					{translate('global.finance_property_value_card.header')}
				</h2>
				<div className="message bordered flex justify-around text-center mt-5">
					<section>
						<h3 className="h-ms text-grey-700 uppercase">
							{translate(
								'global.finance_property_value_card.low'
							)}
						</h3>
						<p className="h-ms-3">
							{formatCentsForDisplay(range.min, 0)}
						</p>
					</section>
					<section>
						<h3 className="h-ms text-grey-700 uppercase">
							{translate(
								'global.finance_property_value_card.high'
							)}
						</h3>
						<p className="h-ms-3">
							{formatCentsForDisplay(range.max, 0)}
						</p>
					</section>
				</div>
				<p>{translate('global.finance_property_value_card.footer')}</p>
			</section>
		);
	}
}

export default withPropertyService(withProperty(SeeWhatYouCanGet));
