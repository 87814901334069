/**
 * @typedef {import('./FileUploadFormComponent').Props} Props
 * @typedef {import('./FileUploadFormComponent').State} State
 */
import BaseForm from './BaseForm';
import FieldFile from './Fields/FieldFile';
import FormSuccessMessages from './Messages/FormSuccessMessages';
import FormWarningMessages from './Messages/FormWarningMessages';
import React from 'react';
import SubmitButton from './Inputs/SubmitButton';

// TODO: This should not be hardcoded
const defaultMax = '50MB';

/** @extends {React.Component<Props, State>} */
export default class FileUploadForm extends React.Component {
	/** @type {Partial<Props>} */
	static defaultProps = {
		className: null,
		maxSize: `${defaultMax}MB`,
		messages: {
			successes: [],
			warnings: [],
		},
		preview: null,
		submit: 'Submit',
	};

	render() {
		return (
			<BaseForm
				className={this.props.className}
				noRequiredText={true}
				onSubmit={this.props.onSubmit}
			>
				<label className="block h-ms-5 mb-4 input-label">
					{this.props.label}
				</label>

				<FieldFile
					accept={this.props.accept}
					maxSize={this.props.maxSize}
					name={this.props.name}
					onChange={this.props.onChange}
					preview={this.props.preview}
					required={true}
				/>

				<section className="mt-4">
					<SubmitButton>{this.props.submit}</SubmitButton>
				</section>

				<FormWarningMessages messages={this.props.messages.warnings} />
				<FormSuccessMessages messages={this.props.messages.successes} />
			</BaseForm>
		);
	}
}
