import { AdminNavigationList } from './Admin';
import { AgentNavigationList } from './Agent';
import { HomeownerNavigationList } from './Homeowner';
import { ProfileNavigationList } from './Profile';
import React from 'react';

export default class AppNavigation extends React.Component {
	render() {
		return (
			<React.Fragment>
				<AdminNavigationList
					onListItemClick={this.props.handleCloseSideDrawer}
				/>
				<AgentNavigationList
					onListItemClick={this.props.handleCloseSideDrawer}
				/>
				<HomeownerNavigationList
					onListItemClick={this.props.handleCloseSideDrawer}
				/>
				<ProfileNavigationList
					onListItemClick={this.props.handleCloseSideDrawer}
				/>
			</React.Fragment>
		);
	}
}
