import ExternalAnchor from './ExternalAnchor';
import React from 'react';
export default class SocialMediaLink extends React.Component {
	render() {
		return (
			<ExternalAnchor
				className={`card-social-icon ${this.props.className}`.trim()}
				href={this.props.url}
			>
				<img
					alt={`share to ${this.props.type} icon`}
					src={this.props.src}
				/>
			</ExternalAnchor>
		);
	}
}
