import FieldToggle from './FieldToggle';
import InputRadio from '../Inputs/InputRadio';
import React from 'react';
export default class FieldRadio extends React.Component {
	static defaultProps = {
		className: '',
		disabled: false,
		labelSpanClass: '',
		required: false,
	};

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<FieldToggle
				className={`form-radio ${this.props.className}`.trim()}
				inputName={this.props.id ?? this.props.name}
				label={this.props.label}
				labelClass="input-label"
				labelSpanClass={this.props.labelSpanClass}
				required={this.props.required}
			>
				<InputRadio
					disabled={this.props.disabled}
					id={this.props.id ?? this.props.name}
					name={this.props.name ?? this.props.id}
					onChange={this.props.onChange}
					onClick={this.props.onClick}
					required={this.props.required}
					value={this.props.value}
				/>
			</FieldToggle>
		);
	}
}
