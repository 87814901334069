import { translate } from '../../../App/Internationalization';
import FormField from './FormField';
import React from 'react';
import InputSearchableSelect from '../Inputs/InputSearchableSelect';

export default class FieldSearchableSelect extends React.Component {
	render() {
		const label = translate('global.forms.inputs.select.label');

		return (
			<FormField
				className={this.props.className}
				inputName={this.props.name}
				label={this.props.label ?? label}
				labelClass={this.props.labelClass}
				required={this.props.required}
			>
				<InputSearchableSelect
					className={this.props.inputClass}
					defaultValue={this.props.defaultValue}
					isMulti={this.props.isMultipleSelect}
					name={this.props.name}
					onChange={this.props.onChange}
					options={this.props.options}
					placeholder={this.props.placeholder}
				/>
			</FormField>
		);
	}
}

FieldSearchableSelect.defaultProps = {
	isMultipleSelect: false,
};
