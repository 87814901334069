/**
 * @typedef {import('Registration/RegisterHomeowner/HomeownerRegistrationForm/Values').default} FormValues
 * @typedef {import('Registration/RegisterHomeowner/Props').default} Props
 * @typedef {import('Registration/RegisterHomeowner/State').default} State
 * @typedef {import('Registration/RegistrationService/Homeowner').default} Homeowner
 * @typedef {import('Registration/RegistrationService/PhoneNumber').default} PhoneNumber
 */

import { Redirect } from 'react-router';
import { translate } from '../../App/Internationalization';
import { withAuth } from '../../shared/AuthProvider';
import {
	withAgentService,
	withRegistrationService,
} from '../../service-container';
import { withTheme } from '../../shared/ThemeProvider';
import captureError from '../../utils/captureError';
import FormErrorMessages from '../../shared/Forms/Messages/FormErrorMessages';
import HomeownerRegistrationForm from './HomeownerRegistrationForm';
import Loading from '../../shared/Loading';
import paths from '../../config/local/paths';
import React from 'react';
import ServiceError from '../../shared/Errors/ServiceError';
import SpamMessage from '../../shared/Forms/Messages/SpamMessage';

/** @extends {React.Component<Props, State>} */
export class RegisterHomeowner extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			agentId: null,
			contactId: null,
			initialFormValues: {
				agreesToTermsOfUse: false,
				city: '',
				country: 'US',
				emailAddress: '',
				firstName: '',
				homePhoneNumber: '',
				lastName: '',
				mobilePhoneNumber: '',
				password: '',
				passwordConfirmation: '',
				postalCode: '',
				state: '',
				streetAddress1: '',
				streetAddress2: '',
			},
			isGettingContactData: false,
			message: null,
			registrationWasSuccessful: false,
		};

		const searchParams = new URLSearchParams(this.props.location.search);
		this.token = searchParams.get('token');
		this.propertyId = searchParams.get('property');
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	/** @returns {void} */
	componentDidMount() {
		this.#setQueryParametersInForm();
		this.setState(
			{
				isGettingContactData: true,
				message: translate('registration.getting_contact_information'),
			},
			this.#getAndSetInitialFormValues
		);

		this.#setBranding();
	}

	/**
	 * @param {Props} prevProps
	 * @param {State} prevState
	 * @returns {Promise<void>}
	 */
	async componentDidUpdate(prevProps, prevState) {
		if (this.state.agentId !== prevState.agentId) {
			this.#setBranding();
		}
	}

	/** @returns {void} */
	#setQueryParametersInForm() {
		const query = new URLSearchParams(this.props.location.search);
		const address = (query.get('address') ?? '')
			.split(',')
			.map((string) => string.trim());
		const city = query.get('city');
		const email = query.get('email');
		const postalCode = query.get('postal_code');
		const state = query.get('state');
		const country = query.get('country');

		/* eslint-disable-next-line complexity */
		this.setState((previous) => ({
			initialFormValues: {
				...previous.initialFormValues,
				city: city ?? previous.initialFormValues.city, // prettier-ignore
				country: country ?? previous.initialFormValues.country, // prettier-ignore
				emailAddress: email ?? previous.initialFormValues.emailAddress, // prettier-ignore
				postalCode: postalCode ?? previous.initialFormValues.postalCode, // prettier-ignore
				state: state ?? previous.initialFormValues.state, // prettier-ignore
				streetAddress1: address[0] ?? previous.initialFormValues.streetAddress1, // prettier-ignore
				streetAddress2: address[1] ?? previous.initialFormValues.streetAddress2, // prettier-ignore
			},
			isGettingContactData: false,
			message: null,
		}));
	}

	/** @returns {Promise<void>} */
	async #getAndSetInitialFormValues() {
		if (this.token === null) {
			this.setState({
				isGettingContactData: false,
				message: null,
			});

			return;
		}

		try {
			const contact = await this.props.registrationService.getContact(
				this.token
			);

			/* eslint-disable-next-line complexity */
			this.setState((state) => {
				const homePhoneNumber =
					contact.phoneNumbers.find(
						(phoneNumber) => phoneNumber.type === 'home'
					)?.value ?? state.initialFormValues.homePhoneNumber;

				const mobilePhoneNumber =
					contact.phoneNumbers.find(
						(phoneNumber) => phoneNumber.type === 'mobile'
					)?.value ?? state.initialFormValues.mobilePhoneNumber;

				return {
					agentId: contact.agentId,
					contactId: contact.contactId,
					initialFormValues: {
						...state.initialFormValues,
						city:
							contact.address?.city ??
							state.initialFormValues.city,
						country:
							contact.address?.country ??
							state.initialFormValues.country,
						emailAddress: contact.emailAddress,
						firstName:
							contact.name.first ??
							state.initialFormValues.firstName,
						homePhoneNumber,
						lastName:
							contact.name.last ??
							state.initialFormValues.lastName,
						mobilePhoneNumber,
						postalCode:
							contact.address?.postalCode ??
							state.initialFormValues.postalCode,
						state:
							contact.address?.state ??
							state.initialFormValues.state,
						streetAddress1:
							contact.address?.streetAddress1 ??
							state.initialFormValues.streetAddress1,
						streetAddress2:
							contact.address?.streetAddress2 ??
							state.initialFormValues.streetAddress2,
					},
					isGettingContactData: false,
					message: null,
				};
			});
		} catch (exception) {
			captureError(exception);

			this.setState({
				message: translate('global.error'),
			});
		}
	}

	/**
	 * @param {FormValues} values
	 * @returns {Promise<void>}
	 */
	async handleSubmit(values) {
		try {
			const homeowner = RegisterHomeowner.mapValuesToHomeowner(values);

			// TODO: Verify that agent exists on whitelabel

			await this.props.registrationService.registerHomeowner(
				homeowner,
				this.state.agentId ?? undefined,
				this.state.contactId ?? undefined,
				this.props.match.params.whiteLabelSlug,
				this.propertyId ?? undefined
			);

			await this.#signIn(values.emailAddress, values.password);

			this.setState({
				message: translate('registration.success'),
				registrationWasSuccessful: true,
			});
		} catch (exception) {
			this.handleException(exception);
		}
	}

	/**
	 * @param {string} emailAddress
	 * @param {string} password
	 * @returns {Promise<void>}
	 */
	async #signIn(emailAddress, password) {
		try {
			await this.props.auth.signIn(emailAddress, password);
		} catch (error) {
			this.setState({
				message: translate('sign_in.generic_sign_in_error'),
			});
		}
	}

	/**
	 * @param {FormValues} values
	 * @returns {Homeowner}
	 */
	static mapValuesToHomeowner(values) {
		/** @type {PhoneNumber[]} */
		const phoneNumbers = [];

		if (values.mobilePhoneNumber) {
			phoneNumbers.push({
				isPrimary: true,
				type: 'mobile',
				value: values.mobilePhoneNumber,
			});
		}

		if (values.homePhoneNumber) {
			phoneNumbers.push({
				isPrimary: phoneNumbers.length === 0,
				type: 'home',
				value: values.homePhoneNumber,
			});
		}

		const address = {
			city: values.city,
			country: values.country,
			postalCode: values.postalCode,
			state: values.state,
			streetAddress1: values.streetAddress1,
			streetAddress2: values.streetAddress2 || null,
		};

		return {
			address,
			emailAddress: values.emailAddress,
			name: { first: values.firstName, last: values.lastName },
			password: values.password,
			phoneNumbers,
		};
	}

	/** @param {unknown} exception */
	handleException(exception) {
		captureError(exception);

		// Message contains useful information
		this.setState({
			message:
				exception instanceof ServiceError
					? exception.errors ?? [exception.message]
					: translate('global.unexpected_error_occurred'),
		});
	}

	/**
	 * @param {string} agentId
	 * @returns {Promise<string | null>}
	 */
	async #getAgentSlugById(agentId) {
		const agent = await this.props.agentService.getAgent(agentId);

		return agent?.slug ?? null;
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #setBranding() {
		const agentId = this.state.agentId;

		if (!agentId) {
			return;
		}

		const slug = await this.#getAgentSlugById(agentId);

		if (slug) {
			this.props.theme.getAndSetThemeBySlug(slug);
		}
	}

	/** @returns {JSX.Element} */
	render() {
		if (this.state.registrationWasSuccessful) {
			return (
				<Redirect
					to={{
						pathname:
							this.props.match.params.whiteLabelSlug !== undefined
								? `/${this.props.match.params.whiteLabelSlug}${paths.auth.signIn}`
								: paths.auth.signIn,
						state: {
							message: translate('registration.sign_in_prompt'),
						},
					}}
				/>
			);
		}

		if (this.state.isGettingContactData) {
			return <Loading />;
		}

		return (
			<React.Fragment>
				<HomeownerRegistrationForm
					initialValues={this.state.initialFormValues}
					onSubmit={this.handleSubmit}
					propertyId={this.propertyId}
				/>
				<section className="form-message-area">
					<FormErrorMessages messages={this.state.message} />
					<SpamMessage />
				</section>
			</React.Fragment>
		);
	}
}

export default withAuth(
	withRegistrationService(withAgentService(withTheme(RegisterHomeowner)))
);
