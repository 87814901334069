import { translate } from 'App/Internationalization';
import BaseHero from 'shared/BaseHero';
import BlogPostExcerptCard from 'shared/BlogPostExcerptCard';
import BlogSearchForm from 'Blog/BlogSearchForm';
import images from 'config/local/images';
import Pagination from 'shared/Pagination';
import React from 'react';
import Loading from '../shared/Loading';

/** @typedef {import ('./BlogIndexState').default} State */
/** @typedef {import ('./BlogIndexProps').default} Props */
/** @typedef {import ('./PostExcerpt').default} PostExcerpt */

export default class BlogIndex extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			currentPageNumber: 1,
			errorMessage: '',
			isGettingData: true,
			lastPageNumber: null,
			lastSearchQuery: '',
			postExcerptList: [],
		};

		this.handleSearchFormSubmit = this.handleSearchFormSubmit.bind(this);
		this.setCurrentPageNumber = this.setCurrentPageNumber.bind(this);
	}

	/** @returns {Promise<void>} */
	async componentDidMount() {
		await this.getAndSetPostExcerptList();
	}

	/**
	 * @param {Props} prevProps
	 * @param {State} prevState
	 * @returns {Promise<void>}
	 */
	async componentDidUpdate(prevProps, prevState) {
		if (this.state.currentPageNumber !== prevState.currentPageNumber) {
			await this.getAndSetPostExcerptList();
		}
	}

	/**
	 *
	 * @param {?string} searchQuery
	 * @param {number} pageNumber
	 * @returns {Promise<void>}
	 */
	async getAndSetPostExcerptList(
		searchQuery = this.state.lastSearchQuery,
		pageNumber = this.state.currentPageNumber
	) {
		try {
			const result = await this.props.blogService.getPostExcerpts(
				searchQuery,
				pageNumber
			);

			this.setState({
				currentPageNumber: pageNumber,
				errorMessage: null,
				isGettingData: false,
				lastPageNumber: result.totalPages,
				lastSearchQuery: searchQuery,
				postExcerptList: result.posts,
			});
		} catch (error) {
			this.setState({
				errorMessage:
					error instanceof Error
						? error.message
						: translate('homeowner.dashboard.marketplace.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {{searchQuery: string}} values
	 * @returns {Promise<void>}
	 */
	async handleSearchFormSubmit(values) {
		if (!values.searchQuery) {
			return;
		}

		await this.getAndSetPostExcerptList(values.searchQuery, 1);
	}

	/**
	 * @param {number} pageNumber
	 */
	setCurrentPageNumber(pageNumber) {
		this.setState({ currentPageNumber: pageNumber });
	}

	render() {
		if (this.state.errorMessage) {
			return <p>{this.state.errorMessage}</p>;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		const postExcerptCardList = this.state.postExcerptList.map(
			(postExcerpt) => (
				<BlogPostExcerptCard
					agentSlug={this.props.theme.agentSlug ?? ''}
					authorName={
						this.props.theme.agentName ?? postExcerpt.author.name
					}
					content={postExcerpt.content}
					date={postExcerpt.date}
					imageUrl={postExcerpt.imageUrl}
					key={postExcerpt.title}
					slug={postExcerpt.slug}
					title={postExcerpt.title}
				/>
			)
		);

		return (
			<React.Fragment>
				<BaseHero
					heroImageUrl={images.blog.header}
					title={translate('global.blog.title')}
				/>
				<BlogSearchForm onSubmit={this.handleSearchFormSubmit} />

				<section className="listing listing-col-size-3 mt-12">
					{postExcerptCardList}
				</section>
				<Pagination
					currentPageNumber={this.state.currentPageNumber}
					lastPageNumber={this.state.lastPageNumber}
					setCurrentPageNumber={this.setCurrentPageNumber}
				/>
			</React.Fragment>
		);
	}
}
