/**
 * @typedef {import('../../Admin/Users/Admins/Admin').default} Admin
 * @typedef {import('../../shared/AgentService/Agent').default} Agent
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */
import { AuthContext } from '../../../shared/AuthProvider';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../utils/toAbsoluteUrl';
import { translate } from '../../Internationalization';
import {
	withAdministratorService,
	withAgentService,
	withOfferService,
} from '../../../service-container';
import { withSettings } from '../../../shared/SettingProvider/SettingProvider';
import BaseForm from '../../../shared/Forms/BaseForm';
import captureError from '../../../utils/captureError';
import FieldRadioGroup from '../../../shared/Forms/Fields/FieldRadioGroup';
import FormSuccessMessages from '../../../shared/Forms/Messages/FormSuccessMessages';
import images from '../../../config/local/images';
import Loading from '../../../shared/Loading';
import NoContent from '../../../shared/NoContent';
import paths from '../../../config/local/paths';
import React from 'react';

const successMessage = translate(
	'global.offers.vendor_exclusivity.success_message'
);

/** @extends {React.Component<Props>} */
class VendorExclusivity extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			errorMessage: null,
			exclusivity: '',
			isGettingData: true,
			successMessage: null,
			user: null,
		};

		this._handleOnSubmit = this._handleOnSubmit.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			await this.#resolveInitialExclusivity();
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #resolveInitialExclusivity() {
		let user;

		if (this.props.type === 'administrator') {
			user =
				await this.props.administratorService.getAdministratorPersonalData();
		} else {
			user = await this.props.agentService.getAgentPersonalData();
		}

		const exclusivity = user.networkVendors ? 'yes' : 'no';

		this.setState({
			exclusivity,
			isGettingData: false,
			user,
		});
	}

	/**
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @returns {Promise<void>}
	 * @protected
	 */
	async _handleOnSubmit(event) {
		this.setState({
			successMessage: null,
		});

		try {
			const exclusivity = event.target.value;
			if (this.state.exclusivity === exclusivity) {
				return;
			}

			await this.props.offerService.updateNetworkVendors(
				exclusivity === 'yes',
				this.props.type
			);

			this.setState({
				exclusivity,
				successMessage: [successMessage],
			});
		} catch (exception) {
			captureError(exception);

			this.setState({
				errorMessage: translate('global.error'),
			});
		}
	}

	/**
	 * @returns {boolean}
	 */
	get #isEnabled() {
		const isPaidFeature =
			this.props.settings.features.get('vendor:premium_exclusivity') ??
			true;

		if (!isPaidFeature) {
			return true;
		}

		if (this.props.type === 'administrator') {
			return this.context.managerIsSubscribed;
		}

		return this.context.isSubscribed;
	}

	/**
	 * @returns {string}
	 */
	get #getSubscriptionPath() {
		if (this.props.type === 'administrator') {
			const groupId = this.#getPrimaryGroupId;

			return `${paths.app.admin.groups.root}/${groupId}/subscription`;
		}

		return paths.app.agent.subscription.root;
	}

	/**
	 * @returns {string}
	 * @throws {Error}
	 */
	get #getPrimaryGroupId() {
		const primaryGroup = this.state.user?.groups?.find(
			/**
			 * @param {{id: string, isPrimary: boolean}} group
			 * @returns {boolean}
			 */
			(group) => group.isPrimary
		)?.id;

		if (primaryGroup) {
			return primaryGroup;
		}

		throw new Error(
			`Admin ${this.state.user?.email} has no primary groups.`
		);
	}

	/**
	 * @returns {JSX.Element | string}
	 */
	get #renderExclusivityLabel() {
		const text = translate(
			'global.offers.vendor_exclusivity.vendor_exclusivity'
		);

		if (this.#isEnabled) {
			return text;
		}

		return (
			<div className="flex items-center">
				{text}

				<Link
					className="w-3.5 ml-1"
					title="Click here to upgrade"
					to={this.#getSubscriptionPath}
				>
					<img
						alt="Feature has been locked and requires a subscription"
						src={toAbsoluteUrl(images.icons.lock)}
					/>
				</Link>
			</div>
		);
	}

	/** @returns {JSX.Element} */
	render() {
		if (this.state.errorMessage) {
			return <NoContent message={this.state.errorMessage} />;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<React.Fragment>
				<BaseForm
					initialValues={{ exclusivity: this.state.exclusivity }}
					noRequiredText={true}
				>
					<fieldset className="mb-6">
						<legend className="h-ms-3 mb-6">
							{this.#renderExclusivityLabel}
						</legend>

						<FieldRadioGroup
							disabled={!this.#isEnabled}
							name="exclusivity"
							onClick={this._handleOnSubmit}
							title={translate(
								'global.offers.vendor_exclusivity.label'
							)}
							values={[
								[
									'yes',
									translate(
										'global.offers.vendor_exclusivity.radio_labels.yes'
									),
								],
								[
									'no',
									translate(
										'global.offers.vendor_exclusivity.radio_labels.no'
									),
								],
							]}
						/>

						<button className="hidden" type="submit">
							{translate('global.forms.buttons.save_text')}
						</button>

						<small>
							<span className="font-bold">
								{translate(
									'global.offers.vendor_exclusivity.note'
								)}
							</span>
							<span>
								{' '}
								{translate(
									'global.offers.vendor_exclusivity.note_text'
								)}
							</span>
						</small>
					</fieldset>
				</BaseForm>

				<FormSuccessMessages
					heading={translate(
						'global.offers.vendor_exclusivity.success_heading'
					)}
					messages={[this.state.successMessage]}
				/>

				<div className="border-b-2 border-grey-300 h-ms width-full height-full mb-8" />
			</React.Fragment>
		);
	}
}

VendorExclusivity.contextType = AuthContext;

export default withAdministratorService(
	// @ts-ignore
	withAgentService(withOfferService(withSettings(VendorExclusivity)))
);
