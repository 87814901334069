/** @typedef {import('./AdminTableProps').Admin} Admin */
/** @typedef {import('./AdminTableProps').Props} Props */
/** @typedef {import('./AdminTableProps').State} State */

import { translate } from '../../../App/Internationalization';
import { withAdministratorService } from 'service-container';
import BaseTable from '../../../shared/BaseTable';
import captureError from '../../../utils/captureError';
import images from '../../../config/local/images';
import Loading from '../../../shared/Loading';
import Pagination from '../../../shared/Pagination';
import paths from '../../../config/local/paths';
import React from 'react';
import TableButton from '../../../shared/TableButton';

/** @extends {React.Component<Props, State>} */
export class AdminTable extends React.Component {
	static defaultProps = {
		isPaginated: true,
	};

	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			adminList: [],
			currentPageNumber: 1,
			isGettingData: true,
			lastPageNumber: 0,
		};

		this._setCurrentPageNumber = this._setCurrentPageNumber.bind(this);
	}

	/**
	 * @protected
	 * @param {number} pageNumber
	 * @returns {void}
	 */
	_setCurrentPageNumber(pageNumber) {
		this.setState({ currentPageNumber: pageNumber });
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #getAdminList() {
		try {
			const currentPageNumber = this.state.currentPageNumber;
			const result = await this.props.administratorService.getAllAdmins(
				this.props.adminLimit,
				currentPageNumber
			);
			this.props?.setTotalRecord(result.total);
			this.setState({
				adminList: result.administrators,
				isGettingData: false,
				lastPageNumber: result.totalPages,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				isGettingData: false,
			});
		}
	}

	/** @returns {Promise<void>} */
	async componentDidMount() {
		await this.#getAdminList();
	}

	/**
	 * @param {Props} prevProps
	 * @param {State} prevState
	 * @returns {Promise<void>}
	 */
	async componentDidUpdate(prevProps, prevState) {
		if (this.state.currentPageNumber !== prevState.currentPageNumber) {
			await this.#getAdminList();
		}
	}

	/**
	 * @param {string} roles
	 * @returns {string}
	 */
	static #getRolesText(roles) {
		const rolesArray = roles
			.split(',')
			.filter((role) => role.trim().length)
			.map((role) =>
				translate(
					`global.forms.inputs.roles.${role.trim().toLowerCase()}`
				)
			);

		return rolesArray.length > 1
			? rolesArray.join(', ')
			: rolesArray.toString();
	}
	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const headerKeyList = [
			'name',
			'email',
			'roles',
			'group',
			'landline',
			'mobile',
			'status',
			'edit',
		];

		const tableHeaderData = headerKeyList.map(
			/**
			 * @param {string} key
			 * @returns {{title: string}}
			 */
			(key) => ({
				title: translate(`admin.pages.admins.view.table.${key}`),
			})
		);

		const tableBodyData = this.state.adminList.map(
			/**
			 * @param {Admin} admin
			 * @param {number} index
			 * @returns {{data: string | JSX.Element | number | null | string[] | any; index?: string;}[]}
			 */
			(admin, index) => [
				{
					data: admin.name,
					index: `admin-${index}`,
				},
				{
					data: admin.email,
					index: `admin-${index}`,
				},
				{
					data: AdminTable.#getRolesText(admin.roles),
					index: `admin-${index}`,
				},
				{
					data: admin.groups,
					index: `admin-${index}`,
				},
				{
					data: admin.landline,
					index: `admin-${index}`,
				},
				{
					data: admin.mobile,
					index: `admin-${index}`,
				},
				{
					data: admin.active ? (
						<p>{translate('global.forms.inputs.status.active')}</p>
					) : (
						<p>
							{translate('global.forms.inputs.status.disabled')}
						</p>
					),
					index: `admin-${index}`,
				},
				{
					data: (
						<TableButton
							icon={images.icons.edit}
							to={{
								pathname: `${paths.app.admin.admins.edit}/${admin.id}`,
								state: { adminId: admin.id },
							}}
						/>
					),
					index: `admin-${index}`,
				},
			]
		);

		if (this.state.isGettingData) {
			return <Loading />;
		}
		if (this.props.isPaginated) {
			return (
				<React.Fragment>
					<BaseTable
						// @ts-ignore -- Issue with parent component
						bodyData={tableBodyData}
						errorMessage={translate(
							'admin.pages.admins.view.error'
						)}
						headerData={tableHeaderData}
					/>

					<Pagination
						currentPageNumber={this.state.currentPageNumber}
						lastPageNumber={this.state.lastPageNumber ?? 1}
						setCurrentPageNumber={this._setCurrentPageNumber}
					/>
				</React.Fragment>
			);
		}
		return (
			<BaseTable
				// @ts-ignore -- Issue with parent component
				bodyData={tableBodyData}
				errorMessage={translate('admin.pages.admins.view.error')}
				headerData={tableHeaderData}
			/>
		);
	}
}

// @ts-ignore will be fix with service container
export default withAdministratorService(AdminTable);
