/**
 * @typedef {import('./Props').default} Props
 */

import { BaseSVG } from '../../../../../shared/BaseSVG';
import { translate } from '../../../../Internationalization';
import images from '../../../../../config/local/images';
import LeadScore from '../../../../shared/LeadScore/LeadScore';
import React from 'react';

const tickerFire = images.icons.tickerFire;

const fireScore =
	process.env.REACT_APP_TOP_LEAD_SCORE_INDICATOR_THRESHOLD || 80;

/** @extends {React.Component<Props>} */
export default class LeadScoreBoard extends React.Component {
	/** @returns {JSX.Element[]} */
	get #leadScores() {
		return this.props.contactList?.map(
			/**
			 * @param {{ name: string, leadScores: { date: string; score: number}} | any} contact
			 * @returns {JSX.Element}
			 */
			(contact) => {
				const fireIcon =
					// prettier-ignore
					contact.leadScore < fireScore ? '' : (
						<i className="icon">
							<BaseSVG className="pl-1 icon" path={tickerFire} />
						</i>
					);

				return (
					<li className="list-item" key={contact.id}>
						<header className="justified-row items-center">
							<section className="flex items-center justify-between hl-ms mr-2">
								{contact?.name} {fireIcon}
							</section>
							<section className="flex items-center">
								<LeadScore
									className="h-ms-1"
									leadScore={contact.leadScore}
									leadScores={contact.leadScores}
								/>
							</section>
						</header>
					</li>
				);
			}
		);
	}

	/** @returns {JSX.Element} */
	render() {
		return (
			<section className="mt-10">
				<section className="card card-border-light">
					<header className="card-header bg-theme-light text-theme-fg-light">
						<div className="h-ms-1">
							{translate(
								'agent.pages.dashboard.score_board.title'
							)}
						</div>
					</header>
					<ul className="list-group card-list divided px-3 relative">
						{this.#leadScores}
					</ul>
				</section>
			</section>
		);
	}
}
