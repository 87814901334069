import FieldToggle from './FieldToggle';
import InputCheckbox from '../Inputs/InputCheckbox';
import React from 'react';

export default class FieldCheckbox extends React.Component {
	render() {
		return (
			<FieldToggle
				className={`${this.props.className ?? ''} form-check`.trim()}
				inputName={this.props.id ?? this.props.name}
				label={this.props.label}
				labelClass={`${this.props.labelClass ?? ''} input-label`.trim()}
				required={this.props.required}
			>
				<InputCheckbox
					className={this.props.inputClass}
					disabled={this.props.disabled}
					id={this.props.id ?? this.props.name}
					name={this.props.name ?? this.props.id}
					onChange={this.props.onChange}
					required={this.props.required}
					value={this.props.value}
				/>
			</FieldToggle>
		);
	}
}

FieldCheckbox.defaultProps = {
	disabled: false,
	required: false,
};
