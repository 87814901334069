/**
 * @typedef {import('../types/PhoneNumber').PhoneNumberRequest} PhoneNumberRequest
 */
import formatPhoneNumberForData from './formatPhoneNumberForData';

/**
 * @param {string | null} mobile
 * @param {string | null} landline
 * @returns {PhoneNumberRequest[] | null}
 */
export function resolvePhoneNumberRequest(mobile, landline) {
	/** @type {PhoneNumberRequest[]} */
	const phoneNumbers = [];

	if (mobile) {
		phoneNumbers.push({
			// eslint-disable-next-line camelcase
			is_primary: true,
			type: 'mobile',
			value: formatPhoneNumberForData(mobile),
		});
	}

	if (landline) {
		phoneNumbers.push({
			// eslint-disable-next-line camelcase
			is_primary: !phoneNumbers.length,
			type: 'landline',
			value: formatPhoneNumberForData(landline),
		});
	}

	if (phoneNumbers.length) {
		return phoneNumbers;
	}

	return null;
}
