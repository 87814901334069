/**
 * @typedef {import('./InputFilePreviewState').default} State
 * @typedef {import('./InputFilePreviewProps').default} Props
 */
import { withThemeService } from 'service-container';
import InputImagePreview from 'shared/Forms/Inputs/Previews/InputImagePreview';
import mimes from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import React from 'react';

class InputFilePreview extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			isImage: false,
		};

		this.isImage = this.isImage.bind(this);
	}

	async componentDidMount() {
		await this.isImage();
	}

	/**
	 * @param {{src:string}} prevProps
	 */
	async componentDidUpdate(prevProps) {
		if (this.props.src !== prevProps.src) {
			await this.isImage();
		}
	}

	/**
	 * @private
	 * @returns {Promise<void>}
	 */
	async isImage() {
		const contentType = await this.props.themeService.getContentType(
			this.props.src
		);

		const mimeTypeList = [
			...Object.values(mimes.images),
			...mimes.icons.ico,
		];

		this.setState({
			isImage: mimeTypeList.includes(contentType),
		});
	}

	render() {
		if (!this.props.src) {
			return null;
		}

		if (this.state.isImage) {
			return <InputImagePreview src={this.props.src} />;
		}

		return null;
	}
}

export default withThemeService(InputFilePreview);
