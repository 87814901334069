/**
 * @typedef {import('./PaginationProps').default} Props
 */
import BaseButton from '../shared/BaseButton/BaseButton';
import images from '../config/local/images';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class Pagination extends React.Component {
	/** @param {Props} props  */
	constructor(props) {
		super(props);

		this._handleFirstPageClick = this._handleFirstPageClick.bind(this);
		this._handleLastPageClick = this._handleLastPageClick.bind(this);
		this._handleRelativePageClick =
			this._handleRelativePageClick.bind(this);
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleFirstPageClick() {
		this.props.setCurrentPageNumber(1);
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleLastPageClick() {
		this.props.setCurrentPageNumber(this.props.lastPageNumber);
	}

	/**
	 * @protected
	 * @param {number} pageOffset
	 * @returns {void}
	 */
	_handleRelativePageClick(pageOffset) {
		this.props.setCurrentPageNumber(
			this.props.currentPageNumber + pageOffset
		);
	}

	/**  @returns {JSX.Element} */
	static #renderEllipsis() {
		return (
			<div className="border border-theme font-bold inline-block leading-tight px-4 py-2 rounded-lg whitespace-nowrap">
				{'…'}
			</div>
		);
	}

	/**  @returns {JSX.Element} */
	#renderPreCurrentButtons() {
		return (
			<React.Fragment>
				{this.props.currentPageNumber > 1 && (
					<BaseButton
						disabled={this.props.currentPageNumber === 1}
						onClick={() => this._handleFirstPageClick()}
						outline={true}
					>
						{'1'}
					</BaseButton>
				)}
				{this.props.currentPageNumber - 3 > 1 &&
					Pagination.#renderEllipsis()}
				{this.props.currentPageNumber - 2 > 1 && (
					<BaseButton
						onClick={() => this._handleRelativePageClick(-2)}
						outline={true}
					>
						{this.props.currentPageNumber - 2}
					</BaseButton>
				)}
				{this.props.currentPageNumber - 1 > 1 && (
					<BaseButton
						onClick={() => this._handleRelativePageClick(-1)}
						outline={true}
					>
						{this.props.currentPageNumber - 1}
					</BaseButton>
				)}
			</React.Fragment>
		);
	}

	/**  @returns {JSX.Element} */
	#renderPostCurrentButtons() {
		return (
			<React.Fragment>
				{this.props.currentPageNumber + 1 <
					this.props.lastPageNumber && (
					<BaseButton
						onClick={() => this._handleRelativePageClick(1)}
						outline={true}
					>
						{this.props.currentPageNumber + 1}
					</BaseButton>
				)}
				{this.props.currentPageNumber + 2 <
					this.props.lastPageNumber && (
					<BaseButton
						onClick={() => this._handleRelativePageClick(2)}
						outline={true}
					>
						{this.props.currentPageNumber + 2}
					</BaseButton>
				)}
				{this.props.currentPageNumber + 3 < this.props.lastPageNumber &&
					Pagination.#renderEllipsis()}
				{this.props.currentPageNumber < this.props.lastPageNumber && (
					<BaseButton
						onClick={() => this._handleLastPageClick()}
						outline={true}
					>
						{this.props.lastPageNumber}
					</BaseButton>
				)}
			</React.Fragment>
		);
	}

	/**  @returns {JSX.Element | null} */
	render() {
		if (this.props.lastPageNumber <= 1) {
			return null;
		}

		return (
			<div className="flex flex-row justify-around mt-8">
				<BaseButton
					disabled={this.props.currentPageNumber === 1}
					icon={images.icons.leftArrow}
					iconClass="icon icon-fill"
					onClick={() => this._handleRelativePageClick(-1)}
				/>
				{this.#renderPreCurrentButtons()}
				<BaseButton disabled={true}>
					{this.props.currentPageNumber}
				</BaseButton>
				{this.#renderPostCurrentButtons()}
				<BaseButton
					disabled={
						this.props.currentPageNumber ===
						this.props.lastPageNumber
					}
					icon={images.icons.rightArrow}
					iconClass="icon icon-fill"
					onClick={() => this._handleRelativePageClick(1)}
				/>
			</div>
		);
	}
}
