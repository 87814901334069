import { authRoot, signInSegment } from 'config/local/paths';
import { object } from '@mooveguru/js-utilities';
import { Redirect } from 'react-router-dom';
import { translate } from 'App/Internationalization';
import app from '../../config/local/app';
import Loading from '../../shared/Loading';
import React from 'react';

/** @typedef {import('Auth/Sso/Props').default} Props */
/** @typedef {import('Auth/Sso/State').default} State */

/** @extends {React.Component<Props, State>} */
export default class Sso extends React.Component {
	#ssoIssuers = {
		anywhere: 'Greenhouse/Leverage',
		carolinaone: 'Carolina One',
		reecenichols: 'ReeceNichols',
	};

	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			errorHeading: null,
			errorMessage: null,
			isGettingData: true,
		};

		const searchParams = new URLSearchParams(location.search);
		this.errorMessage = searchParams.get('errorMessage');
		this.tenantName = searchParams.get('tenantName');
		this.token = searchParams.get('token');
	}

	/**
	 * @returns {boolean}
	 */
	get #isAnywhere() {
		return this.props.match.params.type === 'anywhere';
	}

	/**
	 * @returns {boolean}
	 */
	get #is404() {
		// sso/anywhere is a legacy route that does not conform to the sso/saml/<company> format
		if (this.#isAnywhere) {
			return false;
		}

		if (this.props.match.params.type !== 'saml') {
			return true;
		}

		if (this.props.match.params.issuer === undefined) {
			return true;
		}

		const issuers = object.without(this.#ssoIssuers, 'anywhere');

		return !Object.keys(issuers).includes(this.props.match.params.issuer);
	}

	/**
	 * @returns {string | undefined}
	 */
	get #issuer() {
		if (this.#isAnywhere) {
			return this.#ssoIssuers.anywhere;
		}

		if (this.props.match.params.issuer === undefined) {
			return undefined;
		}

		// @ts-ignore
		return this.#ssoIssuers[this.props.match.issuer] ?? undefined;
	}

	async componentDidMount() {
		if (this.token !== null) {
			this.tryToSignIn(this.token);
		}

		const errorMessage = this.#getErrorMessage();

		this.setState({
			errorHeading: translate('sign_in.authentication_failed'),
			errorMessage,
			isGettingData: false,
		});
	}

	/** @param {string} token */
	tryToSignIn(token) {
		try {
			this.props.auth.signInWithSAML(token, this.#issuer);

			this.setState({
				isGettingData: false,
			});
		} catch (exception) {
			this.setState({
				errorMessage:
					exception instanceof Error
						? exception.message
						: translate('sign_in.sso_unexpected_error'), // prettier-ignore
				isGettingData: false,
			});
		}
	}

	/** @returns {string} */
	#getErrorMessage() {
		let tenantName = this.tenantName;

		if (!tenantName) {
			tenantName = app.title;
		}

		if (!this.errorMessage) {
			return translate('sign_in.sso_no_token_error', tenantName);
		}

		return translate(
			`api_errors.sso_anywhere.${this.errorMessage}`,
			tenantName
		);
	}

	render() {
		const signInPath = `${authRoot}/${signInSegment}`;

		if (this.#is404) {
			<Redirect to={{ pathname: signInPath }} />;
		}

		if (this.state.errorMessage !== null) {
			return (
				<Redirect
					to={{
						pathname: signInPath,
						state: {
							error: this.state.errorMessage,
							errorHeading: this.state.errorHeading,
						},
					}}
				/>
			);
		}

		return (
			<section className="mx-8 auth-card sign-in-card">
				<img
					alt={`Logo for ${app.title}`}
					className="illustration my-8"
					src={app.logo}
				/>
				<h1 className="text-center my-8 text-3xl">
					{translate('sign_in.sso')}
				</h1>
				{this.state.isGettingData ? <Loading /> : null}
			</section>
		);
	}
}
