import { translate } from 'App/Internationalization';
import FormField from 'shared/Forms/Fields/FormField';
import InputStreetAddress from 'shared/Forms/Inputs/Address/InputStreetAddress';
import React from 'react';

export default class FieldStreetAddress extends React.Component {
	render() {
		const label = translate(
			'global.forms.inputs.address.street_address_1.label'
		);

		return (
			<FormField
				className={this.props.className}
				inputName={this.props.name}
				labelClass={this.props.labelClass}
				label={this.props.label ?? label}
				name={this.props.name}
				required={this.props.required}
			>
				<InputStreetAddress
					className={this.props.inputClass}
					disabled={this.props.disabled}
					name={this.props.name}
					required={this.props.required}
				/>
			</FormField>
		);
	}
}

FieldStreetAddress.defaultProps = {
	className: InputStreetAddress.defaultProps.className,
	disabled: InputStreetAddress.defaultProps.disabled,
	inputClass: InputStreetAddress.defaultProps.className,
	labelClass: FormField.defaultProps.labelClass,
	name: InputStreetAddress.defaultProps.name,
	required: InputStreetAddress.defaultProps.required,
};
