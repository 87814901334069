/**
 * @typedef {import('../../../../shared/ApplianceService/Appliance').default} Appliance
 * @typedef {import('../ViewAppliances/Props').default} Props
 * @typedef {import('./State').default} State
 * @typedef {import('formik').FormikValues} Formik
 */

import { Redirect } from 'react-router-dom';
import { translate } from '../../../Internationalization';
import { withApplianceService } from '../../../../service-container';
import ApplianceForm from '../ApplianceForm';
import captureError from '../../../../utils/captureError';
import convertDateToYearMonthDay from '../../../../utils/convertDateToYearMonthDay';
import Loading from '../../../../shared/Loading';
import paths from '../../../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class EditAppliance extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);
		/** @type {State} */
		this.state = {
			errorMessage: null,
			formComplete: false,
			initialValues: {
				brand: '',
				category: '',
				model: '',
				notes: '',
				purchaseDate: '',
				purchasePrice: '',
				room: '',
				warrantyExpirationDate: '',
			},
			isGettingData: true,
		};

		EditAppliance.handleBlur = EditAppliance.handleBlur.bind(this);
		this.handleSubmit = this.#handleSubmit.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			const appliance = await this.props.applianceService.getAppliance(
				this.props.match.params.applianceId
			);

			this.setState({
				errorMessage: null,
				initialValues: {
					brand: appliance.brand,
					category: appliance.category,
					model: appliance.model,
					notes: appliance.notes ?? null,

					purchaseDate: appliance.purchaseDate
						? convertDateToYearMonthDay(appliance.purchaseDate)
						: null,
					purchasePrice: appliance.purchasePrice
						? parseFloat(
								`${appliance.purchasePrice / 100}`
						  ).toFixed(2)
						: null,
					room: appliance.room,
					warrantyExpirationDate: appliance.warrantyExpirationDate
						? convertDateToYearMonthDay(
								appliance.warrantyExpirationDate
						  )
						: null,
				},
				isGettingData: false,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate(
					'homeowner.pages.appliance_center.edit.error'
				),
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {React.FocusEvent<HTMLInputElement>} event
	 * @param {Formik} formik
	 * @returns {void}
	 */
	static handleBlur(event, formik) {
		const value = parseFloat(event.target.value).toFixed(2);
		formik.setFieldValue('purchasePrice', value);
	}

	/**
	 * @param {Appliance} values
	 * @returns {Promise<void>}
	 */
	async #handleSubmit(values) {
		try {
			const appliance = {
				...values,
				purchasePrice: values.purchasePrice * 100,
			};

			await this.props.applianceService.updateAppliance(
				this.props.match.params.applianceId,
				appliance
			);

			this.setState({
				errorMessage: null,
				formComplete: true,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate(
					'homeowner.pages.appliance_center.edit.error'
				),
			});
		}
	}

	/** @returns {JSX.Element} */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		if (this.state.formComplete) {
			return <Redirect to={paths.app.homeowner.applianceCenter.root} />;
		}

		return (
			<ApplianceForm
				errorMessages={this.state.errorMessage}
				formTitle={translate(
					'homeowner.pages.appliance_center.edit.title'
				)}
				initialValues={this.state.initialValues}
				onSubmit={this.handleSubmit}
				onBlur={EditAppliance.handleBlur}
			/>
		);
	}
}

// @ts-ignore until service container types are fixed
export default withApplianceService(EditAppliance);
