/**
 * @typedef {Omit<import('./Address').default, 'country'>} Address
 */
import utilities from '@mooveguru/js-utilities';
import formatDateStringForDatepicker from '../../../utils/formatDateStringForDatepicker';

/**
 * Dot notated mapping of keys to set from returned property
 * @type {Map<string, string>}
 */
const fieldMap = new Map([
	['amenities.fireplace', 'fireplace'],
	['amenities.garageCars', 'garage_cars'],
	['amenities.garageType', 'garage_type'],
	['amenities.pool', 'pool'],
	['construction.construction', 'type_construction'],
	['construction.floorCover', 'floor_cover'],
	['construction.foundation', 'foundation'],
	['construction.interiorWalls', 'interior_walls'],
	['construction.plumbingFixtures', 'n_of_plumbing_fixtures'],
	['construction.roofCover', 'roof_cover'],
	['construction.roofType', 'roof_type'],
	['construction.stories', 'no_of_stories'],
	['construction.style', 'style'],
	['construction.yearBuilt', 'year_built'],
	['hvac.heating', 'heating.heating'],
	['hvac.heatingFuelType', 'heating.heating_fuel_type'],
	['legal.legalBlock', 'legal.legal_block'],
	[
		'legal.legalCityTownshipMunicipality',
		'legal.legal_city_township_municipality',
	],
	['legal.legalDistrict', 'legal.legal_district'],
	['legal.legalLandLot', 'legal.legal_land_lot'],
	['legal.legalLotCode', 'legal.legal_lot_code'],
	['legal.legalLotNumber', 'legal.legal_lot_number'],
	['legal.legalPhaseNumber', 'legal.legal_phase_number'],
	['legal.legalSection', 'legal.legal_section'],
	['legal.legalSubdivisionName', 'legal.legal_subdivision_name'],
	['legal.legalTractNumber', 'legal.legal_tract_number'],
	['legal.legalUnit', 'legal.legal_unit'],
	['location.latitude', 'latitude'],
	['location.longitude', 'longitude'],
	['mortgage.adjustableRateIndex', 'mortgage.mtg_adjustable_rate_index'],
	['mortgage.adjustableRateRider', 'mortgage.mtg_adjustable_rate_rider'],
	['mortgage.amountToLoan', 'mortgage.mtg_loan_amount'],
	['mortgage.changeIndex', 'mortgage.mtg_change_index'],
	['mortgage.dueDate', 'mortgage.mtg_due_date'],
	['mortgage.firstChangeDate', 'mortgage.mtg_first_change_date'],
	[
		'mortgage.firstChangeDateMonthDayConversionRider',
		'mortgage.mtg_first_change_date_month_day_conversion_rider',
	],
	[
		'mortgage.firstChangeDateYearConversionRider',
		'mortgage.mtg_first_change_date_year_conversion_rider',
	],
	['mortgage.firstChangePeriod', 'mortgage.mtg_first_change_period'],
	[
		'mortgage.fixedStepConversionRateRider',
		'mortgage.mtg_fixed_step_conversion_rate_rider',
	],
	['mortgage.interestOnlyPeriod', 'mortgage.mtg_interest_only_period'],
	['mortgage.interestRate', 'mortgage.mtg_interest_rate'],
	[
		'mortgage.interestRateNotGreaterThan',
		'mortgage.mtg_interest_rate_not_greater_than',
	],
	[
		'mortgage.interestRateNotLessThan',
		'mortgage.mtg_interest_rate_not_less_than',
	],
	['mortgage.lenderName', 'mortgage.mtg_lender_name'],
	['mortgage.loanAmount', 'mortgage.mtg_loan_amount'],
	['mortgage.loanType', 'mortgage.mtg_loan_type'],
	['mortgage.marketValueImprovement', 'market_value_improvement'],
	['mortgage.marketValueLand', 'market_value_land'],
	['mortgage.marketValueYear', 'market_value_year'],
	['mortgage.maximumInterestRate', 'mortgage.mtg_maximum_interest_rate'],
	[
		'mortgage.originalDateOfContract',
		'mortgage.mtg_original_date_of_contract',
	],
	[
		'mortgage.originalDateOfContract',
		'mortgage.mtg_original_date_of_contract',
	],
	['mortgage.prepaymentRider', 'mortgage.mtg_prepayment_rider'],
	[
		'mortgage.prepaymentTermPenaltyRider',
		'mortgage.mtg_prepayment_term_penalty_rider',
	],
	['mortgage.rate', 'mortgage.mtg_interest_rate'],
	['mortgage.rateChangeFrequency', 'mortgage.mtg_rate_change_frequency'],
	['mortgage.recordingDate', 'mortgage.mtg_recording_date'],
	['mortgage.titleCompanyName', 'mortgage.mtg_title_company_name'],
	['mortgage.totalAssessedValue', 'total_assessed_value'],
	['mortgage.totalMarketValue', 'total_market_value'],
	['mortgage.typeFinancing', 'mortgage.mtg_type_financing'],
	['rooms.basement', 'basement'],
	['rooms.bathrooms', 'number_of_baths'],
	['rooms.halfBathrooms', 'number_of_partial_baths'],
	['rooms.bedrooms', 'number_of_bedrooms'],
	['rooms.otherRooms', 'other_rooms'],
	['rooms.totalRooms', 'total_number_of_rooms'],
	['size.land.acreage', 'lot_size_acres'],
	['size.land.squareFootage', 'lot_size_square_feet'],
	['utilities.sewer', 'sewer'],
	['utilities.water', 'water'],
]);

/**
 * @param {unknown} value
 * @returns {any}
 */
export function formatPropertyValue(value) {
	if (value === undefined) {
		return null;
	}

	if (typeof value !== 'string') {
		return value;
	}

	return utilities.string.isNumeric(value)
		? utilities.string.convertToTitleCase(value.toString())
		: utilities.number.convertToNumberIfCoercible(value);
}

/**
 * @param {any} property
 * @param {Map<string, string>} map
 * @returns {{ [key: string] : any }}
 */
export function mapRawPropertyDetails(property, map) {
	const details = utilities.object.transform(
		property,
		map,
		formatPropertyValue
	);

	['dueDate', 'originalDateOfContract'].forEach((key) => {
		details[key] = details[key]
			? formatDateStringForDatepicker(details[key])
			: null;
	});

	return details;
}

/**
 * @param {{ property: any; }} data
 * @returns {{ address: Address, details: { [key: string]: any }, id: string | null, image: string | null, isPrimary: boolean, initiatedTransfer: boolean, title: string }}
 */
export default function mapPropertyDetails(data) {
	const { property } = data;
	const details = mapRawPropertyDetails(property?.details, fieldMap);

	return {
		address: {
			city: utilities.string.convertToTitleCase(
				property.address.city,
				' '
			),
			postalCode: property.address.postal_code,
			state: property.address.state,
			streetAddress1: utilities.string.convertToTitleCase(
				property.address.street_address_1,
				' '
			),
			streetAddress2: utilities.string.convertToTitleCase(
				property.address.street_address_2,
				' '
			),
		},
		details,
		id: property.id ?? null,
		image: property?.image?.path ?? null,
		initiatedTransfer: property.initiated_transfer,
		isPrimary: true,
		title: property.title ?? '',
	};
}
