import { translate } from '../../App/Internationalization';
import images from '../../config/local/images';
import LinkButton from '../../shared/LinkButton';
import React from 'react';

/** @extends {React.Component<>} */
export default class ActionButtons extends React.Component {
	render() {
		return (
			<section className="button-group mt-4 flex">
				{!!this.props.addUrl && (
					<LinkButton
						icon={images.icons.add}
						outline={false}
						to={this.props.addUrl}
					>
						{translate('global.actions.add')}
					</LinkButton>
				)}
				{!!this.props.importUrl && (
					<LinkButton
						icon={images.icons.import}
						outline={false}
						to={this.props.importUrl}
					>
						{translate('global.actions.import')}
					</LinkButton>
				)}
				{!!this.props.viewAllUrl && (
					<LinkButton
						icon={images.icons.seeMore}
						outline={false}
						to={this.props.viewAllUrl}
					>
						{translate('global.actions.view')}
					</LinkButton>
				)}
			</section>
		);
	}
}
