import apiUrls from 'config/local/api-urls';
import ServiceError from 'shared/Errors/ServiceError';

/** @typedef {import('./ApplianceRequest').default} ApplianceRequest */
/** @typedef {import('./Appliance').default} Appliance */
/** @typedef {import('./DeletesAppliance').default} DeletesAppliance */

/** @implements {DeletesAppliance} */
export default class ApplianceService {
	constructor(dependencies) {
		this.httpClient = dependencies.httpClient;
		this.authService = dependencies.authService;
	}

	/**
	 * @param {string} id
	 * @returns {Promise<void>}
	 */
	async deleteAppliance(id) {
		const response = await this.httpClient.delete(
			`${apiUrls.appliance.root}/${id}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new ServiceError(
				'There was a problem deleting your appliance.',
				response.body
			);
		}
	}

	async getManuals(keyword) {
		const response = await this.httpClient.get(
			`${process.env.REACT_APP_HOMEKEEPR_URL}?keyword=${keyword}`,
			new Headers({
				'x-api-key': `${process.env.REACT_APP_HOMEKEEPR_API_KEY}`,
			})
		);

		if (!response.isOk) {
			throw new Error('Unable to get appliances.');
		}

		const result = JSON.parse(response.body);

		return result.appliances.map((appliance) => ({
			brand: appliance.brand,
			category: appliance.categories.name,
			manual: appliance?.manual,
			model: appliance.model,
		}));
	}

	/**
	 * @private
	 * @param {?Date} expiration
	 * @returns {number}
	 */
	getLifespan(expiration) {
		if (!expiration) {
			return 0;
		}

		const expires = expiration.getFullYear();
		const current = new Date().getFullYear();

		return Math.max(expires - current, 0);
	}

	/**
	 * @private
	 * @param {ApplianceRequest} data
	 * @returns {Appliance & { lifespan: number }}
	 */
	mapResponse(data) {
		const expiration = data.warranty_expiration_date
			? new Date(data.warranty_expiration_date)
			: null;

		return {
			brand: data.brand,
			category: data.category,
			lifespan: this.getLifespan(expiration),
			manual: data.manual,
			model: data.model,
			notes: data.notes,
			purchaseDate: new Date(data.purchase_date),
			purchasePrice: data.purchase_price,
			room: data.room,
			warrantyExpirationDate: expiration,
		};
	}

	/**
	 * @param {string} applianceId
	 * @returns {Appliance & { lifespan: number }}
	 */
	async getAppliance(applianceId) {
		const response = await this.httpClient.get(
			`${apiUrls.appliance.get}/${applianceId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		return this.mapResponse(response.body);
	}

	/**
	 * @param {string} propertyId
	 * @returns {(Appliance & { id: string, lifespan: number })[]}
	 */
	async getAppliances(propertyId) {
		const response = await this.httpClient.get(
			`${apiUrls.appliances.root}/${propertyId}${apiUrls.appliances.view}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		return response.body.map(
			/**
			 * @param {ApplianceRequest} appliance
			 * @returns {Appliance & { id: string, lifespan: number }}
			 */
			(appliance) => ({ id: appliance.id, ...this.mapResponse(appliance) }) // prettier-ignore
		);
	}

	/**
	 * @private
	 * @param {Appliance} data
	 * @returns {ApplianceRequest}
	 */
	mapRequest(data) {
		return {
			room: data.room,
			category: data.category,
			brand: data.brand,
			model: data.model,
			purchase_date: data.purchaseDate,
			purchase_price: data.purchasePrice,
			warranty_expiration_date: data.warrantyExpirationDate,
			notes: data.notes,
		};
	}

	/**
	 * @param {string} propertyId
	 * @param {Appliance} values
	 * @returns {void}
	 */
	async createAppliance(propertyId, values) {
		const response = await this.httpClient.post(
			`${apiUrls.appliances.root}/${propertyId}${apiUrls.appliances.add}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			this.mapRequest(values)
		);

		if (!response.isOk) {
			throw new Error(response.body.join(''));
		}
	}

	/**
	 * @param {string} applianceId
	 * @param {Appliance} values
	 * @returns {void}
	 */
	async updateAppliance(applianceId, values) {
		const response = await this.httpClient.put(
			`${apiUrls.appliance.update}/${applianceId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			this.mapRequest(values)
		);

		if (!response.isOk) {
			throw new Error(response.body.join(''));
		}
	}
}
