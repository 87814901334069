import { AuthContext } from '../../../../shared/AuthProvider';
import { translate } from '../../../Internationalization';
import * as validators from '../../../../shared/validators';
import * as yup from 'yup';
import BaseField from '../../../../shared/Forms/Fields/BaseField';
import BaseForm from '../../../../shared/Forms/BaseForm';
import FieldEmail from '../../../../shared/Forms/Fields/FieldEmail';
import FieldMobilePhone from '../../../../shared/Forms/Fields/PhoneNumber/FieldMobilePhone';
import FieldStatus from '../../../../shared/Forms/Fields/FieldStatus';
import FormErrorMessages from '../../../../shared/Forms/Messages/FormErrorMessages';
import GroupPrimaryFieldGroup from './GroupPrimaryFieldGroup';
import images from '../../../../config/local/images';
import LinkButton from '../../../../shared/LinkButton';
import NameFields from '../../../../shared/Forms/Fields/Name/NameFields';
import paths from '../../../../config/local/paths';
import React from 'react';
import FieldLandlinePhone from '../../../../shared/Forms/Fields/PhoneNumber/FieldLandlinePhone';
import SocialMediaFields from '../../../../shared/Forms/Fields/SocialMedia/SocialMediaFields';
import SubmitButton from '../../../../shared/Forms/Inputs/SubmitButton';
export default class AgentForm extends React.Component {
	static defaultProps = {
		hasStatus: false,
		initialValues: {
			active: '',
			email: '',
			facebook: '',
			firstName: '',
			groups: [],
			landlinePhoneNumber: '',
			lastName: '',
			mobilePhoneNumber: '',
			primaryGroup: '',
			realEstateLicense: '',
			twitter: '',
			website: '',
		},
	};

	/** @returns {JSX.Element} */
	render() {
		const validationSchema = yup.object().shape({
			email: validators.email,
			facebook: yup.string().nullable(),
			firstName: validators.required('First Name'),
			groups: this.context.roles.includes('manager')
				? validators.groups
				: yup.array().nullable(),
			landlinePhoneNumber: validators.phoneNumber,
			lastName: validators.required('Last Name'),
			mobilePhoneNumber: validators.phoneNumber,
			realEstateLicense: yup.string().nullable(),
			twitter: yup.string().nullable(),
			website: yup.string().nullable(),
		});

		return (
			<React.Fragment>
				<h1 className="hl-ms-6 mt-10 mb-6">{this.props.title}</h1>

				<BaseForm
					initialValues={this.props.initialValues}
					onSubmit={this.props.onSubmit}
					validationSchema={validationSchema}
				>
					<FormErrorMessages
						heading="There was a problem with your submission"
						messages={[
							this.props.error ?? this.props.success ?? '',
						]}
					/>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<NameFields required={true} />
						<GroupPrimaryFieldGroup
							initialGroupValues={{
								groups: this.props.initialValues?.groups,
								primaryGroup:
									this.props.initialValues.groups?.find(
										/**
										 * @param {{isPrimary: boolean}} group
										 * @returns {boolean}
										 */
										(group) => group.isPrimary
									) ?? null,
							}}
							required={this.context.roles.includes('manager')}
						/>

						<FieldEmail required={true} />

						<FieldLandlinePhone name="landlinePhoneNumber" />
						<FieldMobilePhone
							label="Mobile Phone Number"
							name="mobilePhoneNumber"
						/>

						<BaseField
							className="col-span-full"
							label="License Number"
							name="realEstateLicense"
							placeholder={translate(
								'admin.pages.agents.form.license.placeholder'
							)}
							type="text"
						/>
						<SocialMediaFields />
						{this.props.hasStatus ? (
							<FieldStatus name="active" />
						) : null}
					</fieldset>

					<footer className="mx-auto sm:ml-0 button-group w-min">
						<SubmitButton>{this.props.submitText}</SubmitButton>
						<LinkButton
							icon={images.icons.delete}
							size="large"
							to={paths.app.admin.agents.view}
						>
							{translate('admin.pages.agents.form.cancel')}
						</LinkButton>
					</footer>
					<FormErrorMessages
						heading={translate(
							'admin.pages.agents.add.error_message'
						)}
						messages={[this.props.error]}
					/>
				</BaseForm>
			</React.Fragment>
		);
	}
}

AgentForm.contextType = AuthContext;
