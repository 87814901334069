/**
 * @param {string} string
 * @returns {string}
 */
function stripWrappingSlashes(string) {
	return string.replace(/^\//, '').replace(/\/$/, '');
}

/**
 * @param  {...string} pathList
 * @returns {string}
 */
export default function concatPaths(...pathList) {
	const stripped = pathList.map(stripWrappingSlashes);
	const opening = pathList.shift().slice(0, 1) === '/' ? '/' : '';
	const ending = pathList.pop().slice(-1) === '/' ? '/' : '';

	return opening + stripped.join('/') + ending;
}
