/**
 * @typedef {import('../../../Marketplace/Property').default} PropertyResponse
 * @typedef {import('../../Homeowner/Documents/Document').default} Document
 * @typedef {import('../AddressService/Address').default} Address
 * @typedef {import('./Comparables').default} Comparables
 * @typedef {import('./CreatesHomeownerProperty').default} CreatesHomeownerProperty
 * @typedef {import('./DeletesPropertyDocument').default} DeletesPropertyDocument
 * @typedef {import('./DeletesPropertyImage').default} DeletesPropertyImage
 * @typedef {import('./Dependencies').default} Dependencies
 * @typedef {import('./EditablePropertyDetails').default} EditablePropertyDetails
 * @typedef {import('./FormPropertyFields').default} FormOfferFields
 * @typedef {import('./GetsComparables').default} GetsComparables
 * @typedef {import('./GetsDefaultImage').default} GetsDefaultImage
 * @typedef {import('./GetsEditablePropertyDetailsById').default} GetsEditablePropertyDetailsById
 * @typedef {import('./GetsPriceHistory').default} GetsPriceHistory
 * @typedef {import('./GetsTaxHistory').default} GetsTaxHistory
 * @typedef {import('./GetsValuations').default} GetsValuations
 * @typedef {import('./Listing').default} Listing
 * @typedef {import('./Mortgage').default} Mortgage
 * @typedef {import('./PriceHistory').default} PriceHistory
 * @typedef {import('./PriceHistoryResponse').default} PriceHistoryResponse
 * @typedef {import('./Property').default} Property
 * @typedef {import('./TaxHistory').default} TaxHistory
 * @typedef {import('./Valuation').default} Valuation
 * @typedef {import('./Valuations').default} Valuations
 * @typedef {import('./ValuationResponse').default} ValuationResponse
 */
import apiUrls from '../../../config/local/api-urls';
import mapEditablePropertyDetails from './mapEditablePropertyDetails';
import ServiceError from '../../../shared/Errors/ServiceError';
import utilities, { string } from '@mooveguru/js-utilities';

/**
 * @implements {CreatesHomeownerProperty}
 * @implements {DeletesPropertyDocument}
 * @implements {DeletesPropertyImage}
 * @implements {GetsComparables}
 * @implements {GetsDefaultImage}
 * @implements {GetsEditablePropertyDetailsById}
 * @implements {GetsPriceHistory}
 * @implements {GetsTaxHistory}
 * @implements {GetsValuations}
 */

export default class PropertyService {
	/** @param {Dependencies} dependencies */
	constructor(dependencies) {
		this.httpClient = dependencies.httpClient;
		this.authService = dependencies.authService;
		this.baseHeaders = new Headers({ 'Content-Type': 'application/json' });
	}

	/**
	 * @param {string} propertyId
	 * @param {string} documentId
	 */
	async deletePropertyDocument(propertyId, documentId) {
		const response = await this.httpClient.delete(
			`${apiUrls.properties.root}/${propertyId}/documents/${documentId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new ServiceError(
				'There was a problem deleting your document.',
				response.body
			);
		}
	}

	/**
	 * @param {string} propertyId
	 * @param {string} imageId
	 */
	async deletePropertyImage(propertyId, imageId) {
		const response = await this.httpClient.delete(
			`${apiUrls.properties.root}/${propertyId}/images/${imageId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new ServiceError(
				'There was a problem deleting your property image.',
				response.body
			);
		}
	}

	/** @returns {Promise<Property[]>} */
	async getHomeownerProperties() {
		const response = await this.httpClient.get(
			apiUrls.me.homeowner.properties.root,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const results = response.body.results;

		return results.map(
			/**
			 * @todo Correct `result` type, it's not "Property"
			 * @param {PropertyResponse} result
			 * @returns {Property}
			 */
			(result) => ({
				address: {
					city: utilities.string.convertToTitleCase(
						result.address.city,
						' '
					),
					country: result.address.country,
					postalCode: result.address.postal_code,
					state: result.address.state,
					streetAddress1: utilities.string.convertToTitleCase(
						result.address.street_address_1,
						' '
					),
					streetAddress2: utilities.string.convertToTitleCase(
						result.address.street_address_2,
						' '
					),
				},
				documents: (result.documents ?? []).map((document) => ({
					category: document.category ?? '',
					createdAt: new Date(document.created_at),
					id: document.id ?? document._id,
					src: document.src,
					title: document.title,
					type: document.type,
				})),
				id: result.id,
				isPrimary: result.is_primary,
				title: result.title,
			})
		);
	}

	/**
	 * @param {string} propertyId
	 * @returns {Promise<TaxHistory>}
	 */
	async getTaxHistory(propertyId) {
		const response = await this.httpClient.get(
			`${apiUrls.properties.root}/${propertyId}/${apiUrls.properties.taxHistory}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		return response.body
			.map(
				/**
				 * @todo Correct `property` type, it's not `TaxHistory`
				 * @param {TaxHistory} property
				 * @returns {TaxHistory}
				 */
				(property) => ({
					propertyTaxes: property.property_taxes,
					taxAssessment: property.tax_assessment,
					year: property.year,
				})
			)
			.sort(
				/**
				 * @param {TaxHistory} a
				 * @param {TaxHistory} b
				 * @returns {number}
				 */
				(a, b) => Number(b.year) - Number(a.year)
			);
	}

	/**
	 * @param {string} propertyId
	 * @returns {Promise<PriceHistory[]>}
	 */
	async getPriceHistory(propertyId) {
		const response = await this.httpClient.get(
			`${apiUrls.properties.root}/${propertyId}/${apiUrls.properties.priceHistory}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		return response.body
			.filter(
				/**
				 * @param {PriceHistoryResponse} property
				 * @returns {boolean}
				 */
				(property) => !!(property.price && property.date)
			)
			.map(
				/**
				 * @todo Correct `property` type, it's not `PriceHistory`
				 * @param {PriceHistory} property
				 * @returns {PriceHistory}
				 */
				(property) => ({
					date: new Date(property.date),
					listingStatus: property.listing_status,
					price: property.price,
				})
			)
			.sort(
				/**
				 * @param {{date: Date}} a
				 * @param {{date: Date}} b
				 * @returns {number}
				 */
				(a, b) => a.date.valueOf() - b.date.valueOf()
			);
	}

	/* eslint-disable class-methods-use-this -- TODO: refactor */
	/**
	 * @todo Refactor to private syntax: `#mapValuation`
	 * @private
	 * @param {ValuationResponse} valuation
	 * @returns {Valuation}
	 */
	mapValuation(valuation) {
		/* eslint-disable sort-keys -- Do not re-order; this order matches listing table.*/
		return {
			valueMean: valuation.value.mean ?? 0,
			valueHigh: valuation.value.high ?? 0,
			valueLow: valuation.value.low ?? 0,
			confidence: valuation.confidence ?? 0,
			date: valuation.date ? new Date(valuation.date) : new Date(),
		};
		/* eslint-enable sort-keys */
	}
	/* eslint-enable class-methods-use-this */

	/**
	 * @todo Refactor to private syntax: `#mapValuations`
	 * @private
	 * @param {ValuationResponse[]} valuationHistory
	 * @returns {Valuation[]}
	 */
	mapValuations(valuationHistory) {
		return valuationHistory.map(this.mapValuation).sort(
			/**
			 * @param {Valuation} a
			 * @param {Valuation} b
			 * @returns {number}
			 */
			(a, b) => b.date.valueOf() - a.date.valueOf()
		);
	}

	/**
	 * @todo Refactor to private syntax: `#mapValuationResponse`
	 * @private
	 * @param {{[key: string]: ValuationResponse[]}} response
	 * @returns {Valuations}
	 */
	mapValuationResponse(response) {
		const valuationMap = new Map();

		Object.entries(response).forEach(
			/**
			 * @param {[string, ValuationResponse[]]} destructured
			 * @returns {void}
			 */
			([key, valuation]) => {
				valuationMap.set(
					string.convertSnakeCaseToCamelCase(key.replace(/_+/g, '_')),
					this.mapValuations(valuation)
				);
			}
		);

		return valuationMap;
	}

	/**
	 * @param {string} propertyId
	 * @returns {Promise<Valuations>}
	 */
	async getValuations(propertyId) {
		const response = await this.httpClient.get(
			`${apiUrls.properties.root}/${propertyId}/${apiUrls.properties.avms}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		return this.mapValuationResponse(response.body);
	}

	/**
	 * @param {string} propertyId
	 */
	async getPropertyById(propertyId) {
		const response = await this.httpClient.get(
			`${apiUrls.property.root}/${propertyId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		return response.body?.results;
	}

	/**
	 * @param {string} propertyId
	 * @returns {Promise<{[key: string] : Mortgage}>}
	 */

	/**
	 * @param {Mortgage} mortgage
	 * @returns {{ [key: string] : Mortgage }}
	 */
	mapMortgage(mortgage) {
		const mortgageMap = new Map();

		Object.keys(mortgage).forEach((snakeCaseKey) => {
			const camelCaseKey = utilities.string.convertSnakeCaseToCamelCase(
				snakeCaseKey.replace(/^mtg_/i, '')
			);

			mortgageMap.set(camelCaseKey, snakeCaseKey);
		});

		return utilities.object.transform(
			mortgage,
			mortgageMap,
			this.formatObjectValue
		);
	}

	/* eslint-disable class-methods-use-this -- TODO: refactor */
	/**
	 * @param {unknown} value
	 * @returns {any}
	 */
	formatObjectValue(value) {
		if (value === undefined) {
			return null;
		}

		if (typeof value !== 'string') {
			return value;
		}

		return utilities.string.isNumeric(value)
			? utilities.string.convertToTitleCase(value.toString())
			: utilities.number.convertToNumberIfCoercible(value);
	}
	/* eslint-enable class-methods-use-this */

	/**
	 * @param {string} propertyId
	 * @returns {Promise<Comparables>}
	 */
	async getComparables(propertyId) {
		const response = await this.httpClient.get(
			`${apiUrls.properties.root}/${propertyId}/${apiUrls.properties.comparables}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			return {
				listings: [],
				median: {
					listed: null,
					sold: null,
				},
			};
		}

		return {
			listings: response.body.listings
				.map(
					/**
					 * @param {Listing} property
					 * @returns {Readonly<Omit<Listing, 'property'> & { property: { bathroomCount: Listing['property']['bathroom_count'], bedroomCount: Listing['property']['bedroom_count'], squareFootage: Listing['property']['square_footage'] } }>}
					 */
					(property) => ({
						date: property.date ? new Date(property.date) : null,
						location: {
							address: property.location.address,
							latitude: property.location.latitude,
							longitude: property.location.longitude,
						},
						price: property.price,
						property: {
							bathroomCount: property.property.bathroom_count,
							bedroomCount: property.property.bedroom_count,
							squareFootage: property.property.square_footage,
						},
						status: property.status,
					})
				)
				// TODO remove once the back-end is merged, already sorted in the back-end
				.sort(
					/**
					 * @param {{date: Date}} a
					 * @param {{date: Date}} b
					 * @returns {number}
					 */
					(a, b) => b.date.valueOf() - a.date.valueOf()
				),
			median: {
				listed: response.body.median.listed,
				sold: response.body.median.sold,
			},
		};
	}

	/**
	 * @param {string} propertyId
	 * @returns {Promise<string>}
	 */
	async getDefaultImage(propertyId) {
		const response = await this.httpClient.get(
			`${apiUrls.properties.root}/${propertyId}/${apiUrls.properties.comparables}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		return response?.body?.image ?? undefined;
	}

	/**
	 * @param {FormOfferFields} values
	 * @param {string} userId
	 * @returns {Promise<void>}
	 */
	async addHomeownerProperty(values, userId) {
		const response = await this.httpClient.post(
			`${apiUrls.properties.root}?userId=${userId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			/* eslint-disable camelcase -- formatted for request */
			{
				address: {
					city: values.city,
					country: values.country,
					postal_code: values.postalCode,
					state: values.state,
					street_address_1: values.streetAddress1,
					street_address_2: values?.streetAddress2,
				},
				title: values.title,
			}
			/* eslint-enable camelcase */
		);

		if (!response.isOk) {
			throw new Error(response.body?.message ?? response.body?.error);
		}

		return response.body.id;
	}

	/**
	 * @param {string} propertyId
	 * @returns {Promise<EditablePropertyDetails>}
	 */
	async getEditablePropertyById(propertyId) {
		const response = await this.httpClient.get(
			`${apiUrls.properties.root}/${propertyId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body[0]);
		}

		return mapEditablePropertyDetails(response.body);
	}

	/**
	 * @param {{email: string, firstName: string}} values
	 * @param {string} propertyId
	 * @param {string} token
	 * @param {string | null} contactId
	 * @returns {Promise<void>}
	 */
	async transferProperty(values, propertyId, token, contactId) {
		const response = await this.httpClient.post(
			`${apiUrls.properties.root}/${propertyId}/${apiUrls.properties.transferProperty}`,
			new Headers({
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			}),
			/* eslint-disable camelcase -- formatted for request */
			{
				contactId: contactId ?? null,
				recipient_email: values.email,
				recipient_name: values.firstName,
			}
			/* eslint-enable camelcase */
		);

		if (!response.isOk) {
			throw new ServiceError(
				'Something went wrong while trying to transfer your property.',
				response.body
			);
		}
	}

	/**
	 * @todo `property` type is not `any`
	 * @param {any} property
	 * @param {string} token
	 * @returns {Promise<void>}
	 */
	async cancelTransferProperty(property, token) {
		const response = await this.httpClient.post(
			`${apiUrls.properties.root}/${property.id}/${apiUrls.properties.cancelTransferProperty}`,
			new Headers({
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			})
		);

		if (!response.isOk) {
			throw new ServiceError(
				'Something went wrong while trying to cancel your property transfer.',
				response.body
			);
		}
	}

	/**
	 * @param {{title: string}} values
	 * @param {string} propertyId
	 * @param {string} userId
	 * @param {string} token
	 * @returns {Promise<void>}
	 */
	async confirmTransferProperty(values, propertyId, userId, token) {
		const response = await this.httpClient.post(
			`${apiUrls.properties.root}/${propertyId}/${apiUrls.properties.confirmTransferProperty}`,
			new Headers({
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			}),
			/* eslint-disable camelcase -- formatted for request */
			{
				title: values.title,
				user_id: userId,
			}
			/* eslint-enable camelcase */
		);

		if (!response.isOk && response.statusCode === 403) {
			throw new ServiceError(
				'This property is not eligible for transfer.',
				response.body
			);
		}
		if (!response.isOk) {
			throw new ServiceError(
				'Something went wrong while trying to accept your property transfer.',
				response.body
			);
		}
	}

	/**
	 * @param {string} propertyId
	 * @returns {Promise<void>}
	 */
	async declineTransferProperty(propertyId) {
		const response = await this.httpClient.post(
			`${apiUrls.properties.root}/${propertyId}/${apiUrls.properties.declineTransferProperty}`,
			new Headers(this.baseHeaders)
		);

		if (!response.isOk) {
			throw new ServiceError(
				'Something went wrong while trying to cancel your property transfer.',
				response.body
			);
		}
	}
}
