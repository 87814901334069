/** @typedef {import('./ProgressBarProps').default} Props */

import React from 'react';

/** @extends {React.Component<Props>} */
export default class ProgressBar extends React.Component {
	static defaultProps = {
		max: 0,
		showHeader: true,
		value: 0,
	};

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const width =
			this.props.max === 0
				? 0
				: (this.props.value / this.props.max) * 100;

		return (
			<section className="mt-4">
				{this.props.showHeader ? (
					<header className="justified-row">
						<div className="h-ms-1">{this.props.title}</div>
						<div className="h-ms-3">{`${this.props.value}/${this.props.max}`}</div>
					</header>
				) : null}
				<div
					aria-valuemax={this.props.max}
					aria-valuemin={0}
					aria-valuenow={this.props.value}
					className="progress"
					role="progressbar"
				>
					<div
						className="progress-bar"
						style={{
							width: width > 100 ? '100%' : `${width}%`,
						}}
					/>
				</div>
			</section>
		);
	}
}
