import { translate } from 'App/Internationalization';
import BaseInput from 'shared/Forms/Inputs/BaseInput';
import React from 'react';

export default class InputLastName extends React.Component {
	render() {
		const translationPath = 'global.forms.inputs.name.last';
		const label = translate(`${translationPath}.label`);
		const placeholder = translate(`${translationPath}.placeholder`);

		return (
			<BaseInput
				autocomplete="family-name"
				className={this.props.className}
				label={this.props.label ?? label}
				name={this.props.name}
				placeholder={placeholder}
				required={this.props.required}
				disabled={this.props.disabled}
				type="text"
			/>
		);
	}
}

InputLastName.defaultProps = {
	className: '',
	disabled: false,
	name: 'lastName',
	required: false,
};
