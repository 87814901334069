/**
 * @typedef {import('./EditGroupTypes').GroupValues} GroupValues
 * @typedef {import('./EditGroupTypes').Props} Props
 * @typedef {import('./EditGroupTypes').State} State
 */
import { Redirect } from 'react-router';
import { translate } from '../../Internationalization';
import captureError, { logError } from '../../../utils/captureError';
import concatPaths from '../../../utils/contactPaths';
import FormErrorMessages from '../../../shared/Forms/Messages/FormErrorMessages';
import Loading from '../../../shared/Loading';
import GroupForm from './GroupForm';
import paths from '../../../config/local/paths';
import React from 'react';
import ShareableLinks from '../../../shared/ShareableLinks';

/** @extends {React.Component<Props, State>} */
export default class EditGroup extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			error: '',
			groupData: {
				city: '',
				country: '',
				licenseNumber: '',
				name: '',
				parentGroup: '',
				parentId: '',
				phone: '',
				postalCode: '',
				slug: '',
				state: '',
				streetAddress1: '',
				streetAddress2: '',
			},
			isGettingData: true,
			isSubscribed: false,
			redirectToSubscription: false,
			success: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	/** @returns {JSX.Element} */
	render() {
		if (this.state.success) {
			return this.#resolveRedirect();
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		const registrationBase = `${window.location.protocol}//${window.location.hostname}/${this.state.groupData.slug}/register`;

		return (
			<React.Fragment>
				<h1 className="hl-ms-6 mt-10 mb-6">
					{translate('admin.groups.edit.title')}
				</h1>

				<section className="mb-10">
					<ShareableLinks
						links={[
							{
								label: translate('registration.links.agent'),
								url: `${registrationBase}/agent`,
							},
						]}
						title={translate('registration.links.title')}
					/>
				</section>
				{/* @ts-ignore -- declared */}
				<GroupForm
					error={this.state.error}
					groupLabel={translate(
						'admin.pages.agents.form.group_select.label'
					)}
					initialValues={this.state.groupData}
					isSubscribed={this.state.isSubscribed}
					onSubmit={this.handleSubmit}
					submitText={translate('admin.groups.edit.submit')}
					success={this.state.success}
					title={translate('admin.groups.edit.form_title')}
				/>
				<FormErrorMessages messages={this.state.error} />
			</React.Fragment>
		);
	}

	/** @returns {Promise<void>} */
	async componentDidMount() {
		if (this.props.match.params.groupId) {
			const groupData = await this.props.groupService.getGroup(
				this.props.match.params.groupId
			);

			let initialParentGroup;
			if (groupData.parentId) {
				initialParentGroup = await this.props.groupService.getGroup(
					groupData.parentId
				);
			}

			const isSubscribed = await this.#isSubscribed();

			this.setState({
				groupData: {
					...groupData,
					parentGroup: initialParentGroup,
				},
				isGettingData: false,
				isSubscribed,
			});
		}
	}

	/**
	 * @param {GroupValues} values
	 * @returns {Promise<void>}
	 */
	async handleSubmit(values) {
		const updatedCobranding = this.#resolveCobranding(values.cobranding);
		values.cobranding = updatedCobranding;

		try {
			await this.props.groupService.updateGroup(
				this.props.match.params.groupId,
				values
			);

			this.setState({ success: true });
		} catch (error) {
			captureError(error);

			// Errors are human readable
			this.setState({
				error:
					error instanceof Error
						? error.message ?? [error.message]
						: translate('global.error'),
			});
		}
	}

	/**
	 * @returns {Promise<boolean>}
	 */
	async #isSubscribed() {
		let subscription;

		try {
			subscription =
				await this.props.subscriptionService.getGroupSubscription(
					this.props.match.params.groupId
				);
		} catch (error) {
			logError(error);
		}

		if (subscription) {
			return subscription.isActive;
		}

		return false;
	}

	/**
	 * @param {'enabled' | 'disabled'} cobranding
	 * @returns {'enabled' | 'disabled'}
	 */
	#resolveCobranding(cobranding) {
		if (this.state.isSubscribed) {
			return cobranding;
		}

		if (cobranding !== 'enabled') {
			return cobranding;
		}

		this.setState({
			redirectToSubscription: true,
		});

		return 'disabled';
	}

	/**
	 * @returns {JSX.Element}
	 */
	#resolveRedirect() {
		if (!this.state.redirectToSubscription) {
			return <Redirect to={paths.app.admin.groups.view} />;
		}

		const groupId = this.props.match.params.groupId;

		return (
			<Redirect
				to={{
					pathname: concatPaths(
						paths.app.admin.groups.root,
						`/${groupId}/subscription`
					),
					state: {
						cobranding: true,
					},
				}}
			/>
		);
	}
}
