/**
 * @typedef {{ readonly property: { comparables: { listings: Property[] } } }} Props
 * @typedef {{ readonly seeMore: boolean }} State
 * @typedef {import('../../shared/PropertyService/Comparables').default} Comparables
 * @typedef {import('../../shared/PropertyService/Listing').default} Listing
 * @typedef {import('../../shared/PropertyService/PropertyService').default} PropertyService
 * @typedef {import('./ComparableMarketPropertyProps').default} Property
 */
import { translate } from '../../../App/Internationalization';
import { withProperty } from '../PropertyProvider';
import BaseButton from '../../../shared/BaseButton/BaseButton';
import ComparableMarketProperty from './ComparableMarketProperty';
import images from '../../../config/local/images';
import Loading from '../../../shared/Loading';
import NoContent from '../../../shared/NoContent';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class ComparableMarketCard extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			seeMore: false,
		};

		this.handleToggle = this.handleToggle.bind(this);
	}

	/**
	 * @param {Property} property
	 * @param {number} index
	 * @returns {React.ReactElement | null}
	 */
	static seeLess(property, index) {
		if (index >= 3) {
			return null;
		}

		return <ComparableMarketProperty comparable={property} key={index} />;
	}

	/**
	 * @param {Property} property
	 * @param {number} index
	 * @returns {React.ReactElement}
	 */
	static seeMore(property, index) {
		return <ComparableMarketProperty comparable={property} key={index} />;
	}

	/**
	 * @returns {void}
	 */
	handleToggle() {
		this.setState((prevState) => ({
			seeMore: !prevState.seeMore,
		}));
	}

	setData() {
		return this.props.property.comparables.listings.map(
			/**
			 * @param {Property} property
			 * @param {number} index
			 * @returns {React.ReactElement | null}
			 */
			(property, index) => {
				if (this.state.seeMore) {
					return ComparableMarketCard.seeMore(property, index);
				}

				return ComparableMarketCard.seeLess(property, index);
			}
		);
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	render() {
		if (this.props.property.comparables.listings === undefined) {
			return <Loading />;
		}

		if (this.props.property.comparables.listings?.length < 1) {
			return (
				<section className="mt-10">
					<header className="h-ms-4 mb-2">
						{translate(
							'homeowner.pages.finances.view.market.title'
						)}
					</header>
					<NoContent />
				</section>
			);
		}

		return (
			<section className="mt-10">
				<header className="h-ms-4 mb-2">
					{translate('homeowner.pages.finances.view.market.title')}
				</header>
				<ul className="list-group divided bulleted-none mb-4">
					{this.setData()}
				</ul>
				<footer>
					<BaseButton
						className="mx-auto"
						icon={images.icons.seeMore}
						onClick={this.handleToggle}
						outline={true}
					>
						{this.state.seeMore
							? translate(
									'homeowner.pages.finances.view.see_less'
							  )
							: translate(
									'homeowner.pages.finances.view.see_more'
							  )}
					</BaseButton>
				</footer>
			</section>
		);
	}
}

export default withProperty(ComparableMarketCard);
