/**
 * @typedef {{ [key in 'accent' | 'background' | 'primary' | 'secondary' ] ?: string | null }} ColorResponse
 * @typedef {import('./Dependencies').default} Dependencies
 * @typedef {import('./GetsActiveTheme').default} GetsActiveTheme
 * @typedef {import('./GetsAgentTheme').default} GetsAgentTheme
 * @typedef {import('./GetsDomainTheme').default} GetsDomainTheme
 * @typedef {import('./GetsGroupTheme').default} GetsGroupTheme
 * @typedef {import('./GetsThemeBySlug').default} GetsThemeBySlug
 * @typedef {import('./RawThemeResults').default} RawThemeResults
 * @typedef {import('./SetsAgentFavicon').default} SetsAgentFavicon
 * @typedef {import('./SetsAgentLogo').default} SetsAgentLogo
 * @typedef {import('./SetsAgentTheme').default} SetsAgentTheme
 * @typedef {import('./SetsDomainFavicon').default} SetsDomainFavicon
 * @typedef {import('./SetsDomainLogo').default} SetsDomainLogo
 * @typedef {import('./SetsDomainTheme').default} SetsDomainTheme
 * @typedef {import('./SetsGroupFavicon').default} SetsGroupFavicon
 * @typedef {import('./SetsGroupLogo').default} SetsGroupLogo
 * @typedef {import('./SetsGroupTheme').default} SetsGroupTheme
 * @typedef {import('./Theme').default} Theme
 * @typedef {import('./ThemeColors').default} ThemeColors
 * @typedef {import('./ThemeImage').default} ThemeImage
 * @typedef {import('./ThemeResponse').default} ThemeResponse
 * @typedef {import('./ThemeResults').default} ThemeResults
 * @typedef {import('@mooveguru/js-http-client/HttpResponse').default<ThemeResponse>} HttpResponse
 */
import { object } from '@mooveguru/js-utilities';
import apiUrls from '../../config/local/api-urls';
import captureError from '../../utils/captureError';

/**
 * @implements {GetsActiveTheme}
 * @implements {GetsAgentTheme}
 * @implements {GetsDomainTheme}
 * @implements {GetsGroupTheme}
 * @implements {GetsThemeBySlug}
 * @implements {SetsAgentFavicon}
 * @implements {SetsAgentLogo}
 * @implements {SetsAgentTheme}
 * @implements {SetsDomainFavicon}
 * @implements {SetsDomainLogo}
 * @implements {SetsDomainTheme}
 * @implements {SetsGroupFavicon}
 * @implements {SetsGroupLogo}
 * @implements {SetsGroupTheme}
 */
export default class ThemeService {
	/** @param {Dependencies} dependencies */
	constructor(dependencies) {
		this.authService = dependencies.authService;
		this.httpClient = dependencies.httpClient;
	}

	/**
	 * @param {'administrator'|'agent'|'homeowner'} userType
	 * @returns {Promise<Theme>}
	 */
	async getActiveTheme(userType) {
		const response = await this.httpClient.get(
			`${apiUrls.me.root}/${userType}/active-theme`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error('Unable to get your theme.');
		}

		return ThemeService.#mapResponseToTheme(response);
	}

	/** @returns {Promise<Theme>} */
	async getAgentTheme() {
		const response = await this.httpClient.get(
			apiUrls.me.theme.root,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error('Unable to get your theme.');
		}

		return ThemeService.#mapResponseToTheme(response);
	}

	/** @returns {Promise<Theme>} */
	async getDomainTheme() {
		const response = await this.httpClient.get(apiUrls.theme.root);

		if (!response.isOk) {
			throw new Error('Unable to get domain theme.');
		}

		return ThemeService.#mapResponseToTheme(response);
	}

	/**
	 * @param {string} groupId
	 * @returns {Promise<Theme>}
	 */
	async getGroupTheme(groupId) {
		const response = await this.httpClient.get(
			`${apiUrls.groups.root}/${groupId}/theme`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error('Unable to get group theme.');
		}

		return ThemeService.#mapResponseToTheme(response);
	}

	/**
	 * @param {string} agentId
	 * @returns {Promise<Theme>}
	 */
	async getAdminAgentTheme(agentId) {
		const response = await this.httpClient.get(
			`${apiUrls.agents.root}/${agentId}/theme`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error('Unable to get agent theme.');
		}

		return ThemeService.#mapResponseToTheme(response);
	}

	async getTenantTheme() {
		const response = await this.httpClient.get(
			`${apiUrls.themes}/will-return-tenant-theme`
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		return ThemeService.#mapResultsToTheme(response.body.results);
	}

	/**
	 * @param {string} slug
	 * @returns {Promise<ThemeResults>}
	 */
	async getThemeBySlug(slug) {
		const response = await this.httpClient.get(`${apiUrls.themes}/${slug}`);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		return ThemeService.#mapResultsToTheme(response.body.results);
	}

	/**
	 * @param {string} path
	 * @returns {Promise<string>}
	 */
	async getContentType(path) {
		let response;

		try {
			response = await this.httpClient.get(path);
		} catch (error) {
			captureError(error);

			return 'application/json';
		}
		const contentType = response.headers.get('Content-Type');

		return contentType ?? 'application/json';
	}

	/**
	 * @param {File} favicon
	 * @returns {Promise<void>}
	 */
	async setAgentFavicon(favicon) {
		const formData = new FormData();
		formData.append('file', favicon);

		const response = await this.httpClient.put(
			apiUrls.me.theme.favicon,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			formData
		);

		if (!response.isOk) {
			throw new Error('Unable to set your favicon.');
		}
	}

	/**
	 * @param {File} logo
	 * @returns {Promise<void>}
	 */
	async setAgentLogo(logo) {
		const formData = new FormData();
		formData.append('file', logo);

		const response = await this.httpClient.put(
			apiUrls.me.theme.logo,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			formData
		);

		if (!response.isOk) {
			throw new Error('Unable to set your logo.');
		}
	}

	/**
	 * @param {ThemeColors} colors
	 * @returns {Promise<void>}
	 */
	async setAgentTheme(colors) {
		const response = await this.httpClient.put(
			apiUrls.me.theme.root,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			{ color: ThemeService.#mapColorsToRequest(colors) }
		);

		if (!response.isOk) {
			throw new Error('Unable to set your theme.');
		}
	}

	/**
	 * @param {ThemeColors} colors
	 * @returns {Promise<void>}
	 */
	async setDomainTheme(colors) {
		const response = await this.httpClient.put(
			apiUrls.theme.root,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			{ color: ThemeService.#mapColorsToRequest(colors) }
		);

		if (!response.isOk) {
			throw new Error('Unable to set domain theme.');
		}
	}

	/**
	 * @param {File} favicon
	 * @returns {Promise<void>}
	 */
	async setDomainFavicon(favicon) {
		const formData = new FormData();
		formData.append('file', favicon);

		const response = await this.httpClient.put(
			apiUrls.theme.favicon,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			formData
		);

		if (!response.isOk) {
			throw new Error('Unable to set domain favicon.');
		}
	}

	/**
	 * @param {File} logo
	 * @returns {Promise<void>}
	 */
	async setDomainLogo(logo) {
		const formData = new FormData();
		formData.append('file', logo);

		const response = await this.httpClient.put(
			apiUrls.theme.logo,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			formData
		);

		if (!response.isOk) {
			throw new Error('Unable to set domain logo.');
		}
	}

	/**
	 * @param {string} groupId
	 * @param {File} favicon
	 * @returns {Promise<void>}
	 */
	async setGroupFavicon(groupId, favicon) {
		const formData = new FormData();
		formData.append('file', favicon);

		const response = await this.httpClient.put(
			`${apiUrls.groups.root}/${groupId}/theme/favicon`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			formData
		);

		if (!response.isOk) {
			throw new Error('Unable to set group favicon.');
		}
	}

	/**
	 * @param {string} groupId
	 * @param {File} logo
	 * @returns {Promise<void>}
	 */
	async setGroupLogo(groupId, logo) {
		const formData = new FormData();
		formData.append('file', logo);

		const response = await this.httpClient.put(
			`${apiUrls.groups.root}/${groupId}/theme/logo`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			formData
		);

		if (!response.isOk) {
			throw new Error('Unable to set group logo.');
		}
	}

	/**
	 * @param {string} groupId
	 * @param {ThemeColors} colors
	 * @returns {Promise<void>}
	 */
	async setGroupTheme(groupId, colors) {
		const response = await this.httpClient.put(
			`${apiUrls.groups.root}/${groupId}/theme`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			{ color: ThemeService.#mapColorsToRequest(colors) }
		);

		if (!response.isOk) {
			throw new Error('Unable to set group theme.');
		}
	}

	/**
	 * @param {string} agentId
	 * @param {File} favicon
	 * @returns {Promise<void>}
	 */
	async setAdminAgentFavicon(agentId, favicon) {
		const formData = new FormData();
		formData.append('file', favicon);

		const response = await this.httpClient.put(
			`${apiUrls.agents.root}/${agentId}/theme/favicon`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			formData
		);

		if (!response.isOk) {
			throw new Error('Unable to set your favicon.');
		}
	}

	/**
	 * @param {string} agentId
	 * @param {File} logo
	 * @returns {Promise<void>}
	 */
	async setAdminAgentLogo(agentId, logo) {
		const formData = new FormData();
		formData.append('file', logo);

		const response = await this.httpClient.put(
			`${apiUrls.agents.root}/${agentId}/theme/logo`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			formData
		);

		if (!response.isOk) {
			throw new Error('Unable to set your logo.');
		}
	}

	/**
	 * @param {string} agentId
	 * @param {ThemeColors} colors
	 * @returns {Promise<void>}
	 */
	async setAdminAgentTheme(agentId, colors) {
		const response = await this.httpClient.put(
			`${apiUrls.agents.root}/${agentId}/theme`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			{ color: ThemeService.#mapColorsToRequest(colors) }
		);

		if (!response.isOk) {
			throw new Error('Unable to set your theme.');
		}
	}

	/**
	 * @param {keyof ColorResponse} key
	 * @returns {string}
	 */
	static #defaultColor(key) {
		const cssPropertyMap = new Map([
			['accent', 'accent'],
			['primary', 'dark'],
			['secondary', 'light'],
			['background', 'main'],
		]);

		const resolvedKey = cssPropertyMap.has(key)
			? cssPropertyMap.get(key)
			: key;

		return getComputedStyle(document.documentElement).getPropertyValue(
			`--color-theme-${resolvedKey}`
		);
	}

	/**
	 * @param {{ [Key in keyof ColorResponse]?: string | null}} colorMap
	 * @param {...(keyof colorMap)} keyList
	 * @returns {string}
	 */
	static #getColorValue(colorMap, ...keyList) {
		for (const key of keyList) {
			if (colorMap[key] !== undefined && colorMap[key] !== null) {
				// @ts-ignore undefined and null checked above
				return colorMap[key];
			}
		}

		const first = keyList.find(Boolean);

		return first ? ThemeService.#defaultColor(first) : '';
	}

	/**
	 * @param {ColorResponse} colorMap
	 * @return {ThemeColors}
	 */
	static #getColorValues(colorMap) {
		/** @type {[ keyof ThemeColors, (keyof colorMap)[]][] } */
		const responseMap = [
			['accent', ['accent']],
			['dark', ['primary', 'background']],
			['light', ['accent', 'secondary']],
			['main', ['background', 'primary']],
		];

		/** @type {{ -readonly [key in keyof ThemeColors] : ThemeColors[key] }} */
		const response = {
			accent: '',
			dark: '',
			light: '',
			main: '',
		};

		responseMap.forEach(
			/**
			 * @param {[keyof ThemeColors, (keyof colorMap)[]]} destructured
			 * @returns {void}
			 */
			([to, fromList]) => {
				response[to] = ThemeService.#getColorValue(
					colorMap,
					...fromList
				);
			}
		);

		return response;
	}

	/**
	 * @param {ThemeColors} colorList
	 * @return {ColorResponse}
	 */
	static #mapColorsToRequest(colorList) {
		/** @type {Map<keyof ColorResponse, keyof ThemeColors> } */
		const responseMap = new Map([
			['accent', 'light'],
			['background', 'main'],
			['primary', 'dark'],
			['secondary', 'light'],
		]);

		return object.transform(colorList, responseMap);
	}

	/**
	 * @param {HttpResponse} response
	 * @returns {Theme}
	 */
	static #mapResponseToTheme(response) {
		return {
			color: ThemeService.#getColorValues(response.body.color),
			favicon: response.body.image.favicon
				? // prettier-ignore
				  {
					eTag: response.body.image.favicon.e_tag ?? '',
					url: `${apiUrls.root}/${response.body.image.favicon.path}`,
				  }
				: null,
			logo: response.body.image.logo
				? // prettier-ignore
				  {
					eTag: response.body.image.logo.e_tag ?? '',
					url: `${apiUrls.root}/${response.body.image.logo.path}`,
				  }
				: null,
		};
	}

	/**
	 * @param {RawThemeResults} results
	 * @returns {ThemeResults}
	 */
	static #mapResultsToTheme(results) {
		const { agent, colors, favicon, fonts, logo, title } = results;
		return {
			agent: {
				headshotUrl: agent.headshot.src
					? `${apiUrls.root}/${agent.headshot.src}`
					: null,
				name: agent.name,
				slug: agent.slug,
			},
			appTitle: title,
			color: {
				...ThemeService.#getColorValues(colors),
				/** @deprecated */
				text: colors.text,
			},
			faviconUrl: favicon.src ? `${apiUrls.root}/${favicon.src}` : null,
			/** @deprecated */
			font: {
				body: fonts.body,
				headline: fonts.headlines,
			},
			logoUrl: logo.src ? `${apiUrls.root}/${logo.src}` : null,
		};
	}
}
