/**
 * @param {string} src
 * @returns {Promise<boolean>}
 */
export default async function imageHasTransparency(src) {
	if (!src) {
		return false;
	}

	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');

	const img = new Image();
	img.crossOrigin = 'anonymous';

	await loadImage(src, img);

	const height = img.height ?? 0;
	const width = img.width ?? 0;

	canvas.width = width;
	canvas.height = height;

	if (!context) {
		return false;
	}

	context.drawImage(img, 0, 0, width, height);

	img.remove();

	return getTransparency(context, canvas);
}

/**
 * @param {string} url
 * @param {HTMLImageElement} image
 * @returns {Promise<void | HTMLImageElement>}
 */
async function loadImage(url, image) {
	return new Promise((resolve, reject) => {
		image.onload = () => resolve(image);
		image.onerror = reject;
		image.src = url;
	});
}

/**
 * @param {CanvasRenderingContext2D} context
 * @param {HTMLCanvasElement} canvas
 * @returns {boolean}
 */
function getTransparency(context, canvas) {
	const imgData = context.getImageData(
		0,
		0,
		canvas.width,
		canvas.height
	).data;

	let result = false;

	for (let index = 0; index < imgData.length; index += 4) {
		if (imgData[index + 3] < 255) {
			result = true;
			break;
		}
	}

	return result;
}
