import { date } from '@mooveguru/js-utilities';
import { ThemeContext } from '../shared/ThemeProvider';
import * as blogService from 'shared/blog-service';
import captureError from 'utils/captureError';
import config from 'config/local/blog';
import LinkButton from 'shared/LinkButton';
import Loading from 'shared/Loading';
import Metadata from 'shared/Metadata';
import NotFound from 'shared/NotFound';
import ProfileImage from 'shared/ProfileImage';
import React from 'react';
import removeUrlParameterPlaceholder from 'utils/removeUrlParameterPlaceholder';
import SocialMediaLinks from 'shared/SocialMediaLinks';
import AppFooter from '../App/shared/AppFooter';

/** @typedef {import ('./BlogPostProps').default} Props */
/** @typedef {import ('./BlogPostState').default} State */

export default class BlogPost extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			authorHeadshotUrl: null,
			authorName: null,
			canonical: null,
			content: null,
			date: null,
			errorMessage: null,
			excerpt: null,
			imageHeight: null,
			imageUrl: null,
			imageWidth: null,
			isGettingData: true,
			postNotFound: false,
			title: null,
			type: null,
		};
	}

	/** @returns {Promise<void>} */
	async componentDidMount() {
		try {
			await this.getAndSetPost();
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: 'There was an error fetching blog post content.',
				isGettingData: false,
			});
		}
	}

	/** @returns {Promise<void>} */
	async getAndSetPost() {
		const post = await blogService.getPost(this.props.match.params.slug);

		if (post === null) {
			this.setState({
				isGettingData: false,
				postNotFound: true,
			});
			return;
		}

		this.setState({
			authorHeadshotUrl: post.author.headshotUrl,
			authorName: post.author.name,
			canonical: post.canonical,
			content: post.content,
			date: post.date,
			excerpt: post.excerpt,
			imageUrl: post.imageUrl,
			imageWidth: post.imageWidth,
			imageHeight: post.imageHeight,
			isGettingData: false,
			postNotFound: false,
			title: post.title,
			type: post.type,
		});
	}

	getByLine() {
		const author = this.context.agentName ?? this.state.authorName;
		const published = this.state.date
			? date.convertDateToDateString(new Date(this.state.date))
			: '';

		return `by ${author}, ${published}`.trim();
	}

	/**
	 * @private
	 * @returns {string}
	 */
	getExternalUrl() {
		const query = new URLSearchParams({
			app: window.location.host,
			sharedby: this.context.agentSlug,
		});

		const url = new URL(this.props.match.params.slug, config.url);
		url.search = query.toString();

		return url.toString();
	}

	render() {
		if (this.state.errorMessage) {
			return <p>{this.state.errorMessage}</p>;
		}

		if (this.state.isGettingData || this.context.isGettingDomainTheme) {
			return <Loading />;
		}

		if (this.state.postNotFound) {
			return <NotFound />;
		}

		const returnPath =
			this.props.location?.state?.referrer ??
			removeUrlParameterPlaceholder(this.props.match.path);

		/**
		 * TODO: This should be changed to reflect the "in app" URL when
		 * we have the ability to properly render blog posts server side
		 * in order to capture link previews:
		 *
		 * 	`${window.location.origin}/${this.context.agentSlug}/blog/${this.props.match.params.slug}`
		 */
		const currentUrl = this.getExternalUrl();

		// TODO: Find a way to lift metadata setting out of this component.
		return (
			<React.Fragment>
				<Metadata
					alt={this.state.title}
					author={this.state.authorName}
					canonical={this.state.canonical}
					date={
						this.state.date
							? new Date(this.state.date).toISOString()
							: null
					}
					description={this.state.excerpt}
					imageHeight={this.state.imageHeight}
					imageUrl={this.state.imageUrl}
					imageWidth={this.state.imageWidth}
					siteName={config.siteName}
					title={this.state.title}
					type={this.state.type}
					url={config.url}
				/>
				<article className="app-container">
					<header className="flex flex-col mb-10">
						<h1 className="hl-ms-6 mt-8 mb-6 order-2">
							{this.state.title}
						</h1>

						<img
							alt={this.state.title ?? ''}
							className="w-full order-1"
							src={this.state.imageUrl ?? ''}
							title={this.state.title ?? ''}
						/>

						<div className="md:flex order-3 items-center justify-between">
							<div className="flex items-center mb-6 md:mb-0">
								<ProfileImage
									altText={`Headshot of ${
										this.props.theme?.agentName ??
										this.state.authorName
									}`}
									headshotUrl={
										this.context.agentName
											? this.context.agentHeadshotUrl
											: this.state.authorHeadshotUrl
									}
								/>
								<section className="ml-2 title-area">
									<address className="text-gray-500">
										{this.getByLine()}
									</address>
								</section>
							</div>

							<div className="flex justify-around w-fit">
								<SocialMediaLinks
									className="inline-block mr-2 md:mr-0 md:ml-2"
									imageUrl={this.state.imageUrl}
									url={currentUrl}
								/>
							</div>
						</div>
					</header>

					<section
						className="rich-text"
						dangerouslySetInnerHTML={{
							__html: this.state.content ?? '',
						}}
					/>

					<footer className="mt-10">
						<LinkButton size="large" to={returnPath}>
							Go Back
						</LinkButton>
					</footer>
				</article>

				<AppFooter />
			</React.Fragment>
		);
	}
}

BlogPost.contextType = ThemeContext;
