/**
 * @typedef {import('../../Auth/AuthService/AuthService').default} AuthService
 * @typedef {import('@mooveguru/js-http-client/GetsHttp').default} HttpClient
 * @typedef {import('shared/MarketplaceService/GetsSuggestedVendorCategories').default} GetsSuggestedVendorCategories
 * @typedef {import('shared/MarketplaceService/GetsVendors').default} GetsVendors
 * @typedef {import('shared/MarketplaceService/Vendor').default} Vendor
 * @typedef {import('shared/MarketplaceService/VendorResponse').default} VendorResponse
 * @typedef {Readonly<{ authService: AuthService, httpClient: HttpClient }>} Dependencies
 */
import { toAbsoluteUrl } from '../../../src/utils/toAbsoluteUrl';
import { url } from '@mooveguru/js-utilities';
import apiUrls from '../../config/local/api-urls';

const baseUrl = 'https://search.homekeepr.com';

/**
 * @implements {GetsSuggestedVendorCategories}
 * @implements {GetsVendors}
 */
export default class MarketplaceService {
	/** @type {Dependencies['authService']} */
	#authService;
	/** @type {Dependencies['httpClient']} */
	#httpClient;

	/**
	 * @param {Dependencies} dependencies
	 */
	constructor(dependencies) {
		this.#authService = dependencies.authService;
		this.#httpClient = dependencies.httpClient;
	}

	/**
	 * @param {string} query
	 * @returns {Promise<string[]>}
	 */
	async getSuggestedVendorCategories(query) {
		const response = await this.#httpClient.get(
			`${baseUrl}/categories?q=${query}`
		);

		if (!response.isOk) {
			throw new Error(
				'Something went wrong trying to get suggested categories.'
			);
		}

		const records = response.body.results.records;

		return records.map(
			/** @param {{ name: string; }} record */ (record) => record.name
		);
	}

	/**
	 * @param {string} query
	 * @param {string} postalCode
	 * @param {number} [page]
	 * @param {string} [slug]
	 * @returns {Promise<Readonly<{ pages: number, restricted: boolean, vendors:  Vendor[] }>>}
	 */
	async getVendors(query, postalCode, page = 1, slug = '') {
		const limit = 6;
		const endpoint = `${apiUrls.offers.root}/${slug}?category=${query}&limit=${limit}&page=${page}&postal_code=${postalCode}`;

		const headers = new Headers();
		let token;

		try {
			token = this.#authService.getAccessToken();
		} catch (error) {
			/*
			 * Do nothing, error is that the user is not logged in,
			 * since this route can be public, this can be allowed
			 * to fail.
			 */
		}

		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}

		const response = await this.#httpClient.get(endpoint, headers);

		if (!response.isOk) {
			throw new Error('Something went wrong trying to get your vendors.');
		}

		return {
			pages: response.body.pages,
			restricted: response.body.restricted ?? false,
			vendors: response.body.offers.map(
				/** @param {VendorResponse} record */
				(record) => ({
					description: record?.description || null,
					id: record.id,
					logoUrl: MarketplaceService.#resolveLogoUrl(record.image),
					name: record.vendor.name,
					phoneNumber: record.vendor?.phone_number || null,
					related: record.related ?? null,
					source: record.source,
					websiteUrl: record.website_url,
				})
			),
		};
	}

	/**
	 * @param {{e_tag:string, path:string} | string | null} image
	 * @returns {string | null}
	 */
	static #resolveLogoUrl(image) {
		if (!image) {
			return null;
		}

		if (typeof image === 'string') {
			return image;
		}

		return toAbsoluteUrl(image.path);
	}
}
