import { Link } from 'react-router-dom';
import InlineSVG from 'react-inlinesvg/esm';
import React from 'react';

export default class TableButton extends React.Component {
	/** @returns {JSX.Element} */
	render() {
		const icon = (
			<i
				className={`${
					this.props.defaultSize === false ? 'icon-sm' : ''
				} icon-fill icon`}
			>
				<InlineSVG src={this.props.icon} />
			</i>
		);

		const className =
			`no-underline button-icon block mt--1 mx-auto w-fit border-b-2 border-transparent ${
				this.props.disabled
					? 'brightness-200 grayscale'
					: 'hover:brightness-150 hover:saturate-150'
			}`.trim();

		if (this.props.to) {
			return (
				<Link className={className} to={this.props.to}>
					{icon}
				</Link>
			);
		}

		return (
			<button
				className={className}
				disabled={this.props.disabled ?? false}
				onClick={this.props.handleClick}
				title={this.props.title}
				type="button"
			>
				{icon}
			</button>
		);
	}
}
