/**
 * @param {string?} string
 * @returns {string}
 */
export default function formatDateStringForDatepicker(string) {
	if (!string) {
		return '';
	}
	const date = new Date(string.toUpperCase());

	return [
		date.getFullYear(),
		new String(date.getMonth() + 1).padStart(2, '0'),
		new String(date.getDate()).padStart(2, '0'),
	].join('-');
}
