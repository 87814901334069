/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./Props').LeadScores} LeadScores
 */

import { BaseSVG } from '../../../shared/BaseSVG';
import { translate } from '../../Internationalization';
import images from '../../../config/local/images';
import React from 'react';

const tickerUp = images.icons.tickerUp;
const tickerDown = images.icons.tickerDown;
const tickerCircle = images.icons.tickerCircle;

/** @extends {React.Component<Props>} */
export default class LeadScore extends React.Component {
	/**
	 * @param {Props['leadScores']} leadScores
	 * @returns {leadScores}
	 */
	static #sortLeadScores(leadScores) {
		// Sort all scores date wise
		return (
			leadScores &&
			leadScores
				.filter((score) => score.date)
				.sort(
					/**
					 * @param {LeadScores} a
					 * @param {LeadScores} b
					 * @returns {-1 | 0 | 1}
					 */
					(a, b) => {
						const diff =
							// @ts-ignore null values checked already
							new Date(b.date).getTime() -
							// @ts-ignore null values checked already
							new Date(a.date).getTime();

						if (diff === 0) {
							return diff;
						}

						return diff >= 1 ? 1 : -1;
					}
				)
		);
	}

	/**
	 * @param {string} scoreIcon
	 * @returns {string}
	 */
	static #getScoreColor(scoreIcon) {
		switch (scoreIcon) {
			case tickerUp:
				return 'text-accent-green';
			case tickerDown:
				return 'text-accent-red';
			case tickerCircle:
				return 'text-[#7EA6E4]';
			default:
				return 'text-[#7EA6E4]';
		}
	}

	/**
	 * @param {LeadScores[]} scoreList
	 * @returns {string}
	 */
	static #getIconForScore(scoreList) {
		if (scoreList.length === 1) {
			return tickerUp;
		}

		const currentScore = scoreList[0]?.score || 0;
		const previousScore = scoreList[1]?.score || 0;

		if (currentScore === previousScore) {
			return tickerCircle;
		}

		return currentScore > previousScore ? tickerUp : tickerDown;
	}

	/**
	 * @param {LeadScores[] | null} leadScores
	 * @returns {JSX.Element}
	 */
	#renderLeadScore(leadScores) {
		const scoreColor =
			//	@ts-ignore null values checked already
			LeadScore.#getScoreColor(LeadScore.#getIconForScore(leadScores)) ??
			'';

		return (
			<span
				className={`${scoreColor} ${this.props.className} ${
					this.props.iconOrder === 'left' ? '' : 'w-6'
				}`}
			>
				{this.props.leadScore ?? 0}
			</span>
		);
	}

	render() {
		if (!this.props?.leadScores?.length) {
			return (
				<span>
					{translate('agent.pages.dashboard.score_board.no_score')}
				</span>
			);
		}

		const leadScores = LeadScore.#sortLeadScores(this.props?.leadScores);

		const iconOrderLeft = this.props.iconOrder === 'left';

		return (
			<div
				className={`flex items-center align-middle ${
					iconOrderLeft ? 'flex-row-reverse' : 'flex-row'
				}`}
			>
				{this.#renderLeadScore(leadScores)}

				<i className="icon icon-md">
					<BaseSVG
						className={`${iconOrderLeft ? 'pr-1' : 'pl-1'} icon`}
						//	@ts-ignore null values checked already
						path={LeadScore.#getIconForScore(leadScores)}
					/>
				</i>
			</div>
		);
	}
}
