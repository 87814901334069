import { string } from '@mooveguru/js-utilities';
import adList from '../../../config/local/homeowner/ads';
import Ads from '../Ads/Ads';
import BannerAds from '../BannerAds';
import React from 'react';

/** @type {("edgeHomeSid" | "universalTitle")[]} */
const identifiers = ['edgeHomeSid', 'universalTitle'];

/**
 * @param {{userRole: "agent" | "homeowner"}} props
 * @returns {JSX.Element[]}
 */
function AdsContainer(props) {
	return identifiers.map((ad) => (
		// @ts-ignore issue with service containers
		<Ads
			identifier={string.convertCamelCaseToSnakeCase(ad)}
			key={ad}
			userRole={props.userRole}
		>
			<BannerAds
				buttonText={adList[ad].buttonText}
				logo={adList[ad].logo}
				text={adList[ad].text}
				textFooter={adList[ad].textFooter}
				textLead={adList[ad].textLead}
				url={adList[ad].url}
			/>
		</Ads>
	));
}

export default React.memo(AdsContainer);
