/**
 * @param {Date|string} date
 * @returns {string}
 */
export default function formatDateForDisplay(date) {
	if (!(date instanceof Date)) {
		date = new Date(date.toUpperCase());
	}

	return date.toLocaleDateString('en-us', {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
	});
}
