/**
 * @typedef {import('../../shared/PropertyService/PropertyService').default} PropertyService
 * @typedef {import('../../shared/PropertyService/TaxHistory').default} TaxHistory
 * @typedef {import('./TaxHistoryCardProps').default} Props
 * @typedef {import('./TaxHistoryCardState').default} State
 */

import { translate } from '../../../App/Internationalization';
import { withProperty } from '../PropertyProvider';
import { withPropertyService } from '../../../service-container';
import BaseButton from '../../../shared/BaseButton/BaseButton';
import BaseLineChart from '../../../shared/BaseLineChart';
import BaseTable from '../../../shared/BaseTable';
import captureError from '../../../utils/captureError';
import formatCentsForDisplay from '../../../utils/formatCentsForDisplay';
import images from '../../../config/local/images';
import NoContent from '../../../shared/NoContent';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class TaxHistoryCard extends React.Component {
	/** @param {Props} props */

	constructor(props) {
		super(props);

		this.headerData = [
			{ title: translate('homeowner.pages.finances.table.year') },
			{
				title: translate(
					'homeowner.pages.finances.table.property_taxes'
				),
			},
			{
				title: translate(
					'homeowner.pages.finances.table.tax_assessment'
				),
			},
		];

		/** @param {State} state */
		this.state = {
			bodyData: [],
			error: false,
			isGettingData: true,
			isShowingMore: false,
			values: [],
			xAxisValues: [],
		};

		this._setTableData = this._setTableData.bind(this);
		this._setChartData = this._setChartData.bind(this);
		this.handleShowMore = this.handleShowMore.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		const propertyId = this.props?.property?.selectedProperty?.id;

		if (!propertyId) {
			this.setState({
				error: true,
				isGettingData: false,
			});

			return;
		}

		try {
			const taxHistory = (
				await this.props.propertyService.getTaxHistory(propertyId)
			).filter(
				(history) => history.taxAssessment && history.propertyTaxes
			);

			this._setTableData(taxHistory);
			this._setChartData(taxHistory);

			this.setState({
				isGettingData: false,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				error: true,
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {TaxHistory} property
	 * @param {number} index
	 * @returns {Array<{ className: string ; data: string | number; index: number;}>}
	 */
	static #getBodyData(property, index) {
		const propertyTax = [];

		if (property.year) {
			propertyTax.push({ className: '', data: property.year, index });
		}

		if (property.propertyTaxes) {
			propertyTax.push({
				className: 'price price-light',
				data: formatCentsForDisplay(property.propertyTaxes, 0),
				index,
			});
		}

		if (property.taxAssessment) {
			propertyTax.push({
				className: 'price price-light',
				data: formatCentsForDisplay(property.taxAssessment, 0),
				index,
			});
		}

		return propertyTax;
	}

	/**
	 * @param {TaxHistory[]} [taxHistory]
	 * @returns {Promise<void>}
	 */
	async _setTableData(taxHistory) {
		/**
		 * @param {TaxHistory} property
		 * @param {number} index
		 */
		const bodyData = taxHistory?.map((property, index) =>
			TaxHistoryCard.#getBodyData(property, index)
		);

		/**
		 *  @param {State} prevState
		 *  @returns {Promise<void>}
		 */
		this.setState((prevState) => ({
			...prevState,
			bodyData: bodyData,
		}));
	}

	/**
	 * @param {TaxHistory[]} taxHistory
	 * @returns {Promise<void>}
	 */
	async _setChartData(taxHistory) {
		const xAxisValues = taxHistory?.map((property) => property.year);

		const values = taxHistory?.map(
			(property) =>
				property?.propertyTaxes && property?.propertyTaxes / 100
		);

		/**
		 *  @param {State} prevState
		 *  @returns {Promise<void>}
		 */
		this.setState((prevState) => ({
			...prevState,
			values: values,
			xAxisValues: xAxisValues,
		}));
	}

	/**
	 * @returns {void}
	 */
	handleShowMore() {
		/**
		 *  @param {State} prevState
		 *  @returns {Promise<void>}
		 */
		this.setState((prevState) => ({
			...prevState,
			isShowingMore: !prevState.isShowingMore,
		}));
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	seeMoreButton() {
		const buttonTextKey = this.state.isShowingMore
			? 'see_less'
			: 'see_more';
		const buttonText = translate(
			`homeowner.pages.finances.view.${buttonTextKey}`
		);

		if (this.state.values?.length < 3) {
			return null;
		}

		return (
			<footer className="mt-3">
				<BaseButton
					className="mx-auto"
					icon={images.icons.eye}
					onClick={this.handleShowMore}
					outline={true}
					type="button"
				>
					{buttonText}
				</BaseButton>
			</footer>
		);
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	render() {
		if (this.state.bodyData.length < 1 && this.state.error) {
			return (
				<section>
					<header className="h-ms-4 mb-4">
						{translate('homeowner.pages.finances.view.tax.title')}
					</header>

					<NoContent />
				</section>
			);
		}

		/**
		 * @type {Array<{className:string,index: number, data:string|number }>}
		 */
		const bodyData = this.state.isShowingMore
			? this.state.bodyData
			: this.state.bodyData?.slice(0, 4);

		/**
		 * @type {Array<string | number>}
		 */
		const values = [...this.state.values]?.reverse();
		/**
		 * @type {Array<string | number>}
		 */
		const xAxisValues = [...this.state.xAxisValues]?.reverse();

		return (
			<section>
				<header className="h-ms-4 mb-4">
					{translate('homeowner.pages.finances.view.tax.title')}
				</header>

				<section className="grid md:grid-cols-2 sm:grid-cols-1 gap-5">
					{values.length > 0 ? (
						<section className="card-body h-full">
							<div className="w-full h-full relative">
								<BaseLineChart
									axisPadding={200}
									values={values}
									xAxisValues={xAxisValues}
								/>
							</div>
						</section>
					) : (
						''
					)}

					<section>
						<BaseTable
							// @ts-ignore issue with BaseTable Props
							bodyData={bodyData}
							className="table-bordered mt-5"
							errorMessage={translate(
								'homeowner.pages.finances.view.error'
							)}
							headerData={this.headerData}
						/>
						{this.seeMoreButton()}
					</section>
				</section>
			</section>
		);
	}
}

export default withPropertyService(withProperty(TaxHistoryCard));
