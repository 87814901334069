/**
 * @typedef {import('App/shared/EditTheme/EditThemeProps').default} Props
 * @typedef {import('App/shared/EditTheme/EditThemeState').default} State
 * @typedef {import('shared/ThemeService/Theme').default} Theme
 * @typedef {import('shared/ThemeService/ThemeColors').default} ThemeColors
 */
import { object } from '@mooveguru/js-utilities';
import { translate } from '../../Internationalization';
import { withTheme } from 'shared/ThemeProvider';
import captureError from '../../../utils/captureError';
import Loading from '../../../shared/Loading';
import React from 'react';
import ThemeForm from './ThemeForm';

/** @extends {React.Component<Props, State>} */
export class EditTheme extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			colorsMessage: { message: null, success: false },
			favicon: null,
			faviconMessage: { message: null, success: false },
			initialColorValues: {
				dark: '#000000',
				light: '#000000',
				main: '#000000',
			},
			isGettingData: true,
			logo: null,
			logoMessage: { message: null, success: false },
		};

		this.handleColorsSubmit = this.handleColorsSubmit.bind(this);
		this.handleFaviconChange = this.handleFaviconChange.bind(this);
		this.handleFaviconSubmit = this.handleFaviconSubmit.bind(this);
		this.handleLogoChange = this.handleLogoChange.bind(this);
		this.handleLogoSubmit = this.handleLogoSubmit.bind(this);
	}

	/**
	 * @param {unknown} error
	 * @returns {string}
	 */
	static #error(error) {
		captureError(error);

		return translate('global.error');
	}

	async componentDidMount() {
		try {
			const theme = await this.props.getTheme();
			/** @type {Omit<typeof theme['color'], 'accent'>} */
			// @ts-ignore object.without type incorrect in index.d.ts
			const colorList = object.without(theme.color, 'accent');

			this.setState({
				faviconPreview: theme.favicon ? theme.favicon.url : null,
				initialColorValues: colorList,
				isGettingData: false,
				logoPreview: theme.logo ? theme.logo.url : null,
			});
		} catch (error) {
			this.setState({
				colorsMessage: {
					message: EditTheme.#error(error),
					success: false,
				},
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {ThemeColors} values
	 * @returns {Promise<void>}
	 */
	async handleColorsSubmit(values) {
		try {
			await new Promise((resolve, reject) => {
				this.setState(
					{
						colorsMessage: {
							message: 'Saving colors…',
							success: false,
						},
					},
					() => this.submitColors(values, resolve, reject)
				);
			});
		} catch (error) {
			this.setState({
				colorsMessage: {
					message: EditTheme.#error(error),
					success: false,
				},
			});
		}
	}

	/**
	 * @param {ThemeColors} colors
	 * @param {(value?: any) => void} resolve
	 * @param {(reason?: any) => void} reject
	 */
	async submitColors(colors, resolve, reject) {
		try {
			await this.props.setTheme(colors);

			this.setState(
				{
					colorsMessage: {
						message: 'Colors set successfully.',
						success: true,
					},
				},
				resolve
			);

			await this.props.theme.getAndSetActiveTheme(this.props.userType);
		} catch (error) {
			reject(error);
		}
	}

	/**
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @returns {void}
	 */
	handleFaviconChange(event) {
		if (!event.target.files) {
			return;
		}

		if (!event.target.files[0]) {
			return;
		}

		this.setState({
			favicon: event.target.files ? event.target.files[0] : null,
		});
	}

	/**
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @returns {void}
	 */
	handleLogoChange(event) {
		if (!event.target.files) {
			return;
		}

		if (!event.target.files[0]) {
			return;
		}

		this.setState({
			logo: event.target.files?.length ? event.target.files[0] : null,
		});
	}

	/** @returns {Promise<void>} */
	async handleFaviconSubmit() {
		try {
			await new Promise((resolve, reject) => {
				this.setState(
					{
						faviconMessage: {
							message: 'Saving favicon…',
							success: false,
						},
					},
					() => this.submitFavicon(resolve, reject)
				);
			});
		} catch (error) {
			this.setState({
				faviconMessage: {
					message: EditTheme.#error(error),
					success: false,
				},
			});
		}
	}

	/** @returns {Promise<void>} */
	async handleLogoSubmit() {
		try {
			await new Promise((resolve, reject) => {
				this.setState(
					{
						logoMessage: {
							message: 'Saving logo…',
							success: false,
						},
					},
					() => this.submitLogo(resolve, reject)
				);
			});
		} catch (error) {
			this.setState({
				logoMessage: {
					message: EditTheme.#error(error),
					success: false,
				},
			});
		}
	}

	/**
	 * @param {(value?: any) => void} resolve
	 * @param {(reason?: any) => void} reject
	 */
	async submitFavicon(resolve, reject) {
		try {
			if (this.state.favicon === null) {
				throw new Error('No favicon selected.');
			}

			await this.props.setFavicon(this.state.favicon);

			this.setState(
				{
					faviconMessage: {
						message: 'Favicon set successfully.',
						success: true,
					},
				},
				resolve
			);

			this.props.theme.getAndSetActiveTheme(this.props.userType);
		} catch (error) {
			reject(error);
		}
	}

	/**
	 * @param {(value?: any) => void} resolve
	 * @param {(reason?: any) => void} reject
	 */
	async submitLogo(resolve, reject) {
		try {
			if (this.state.logo === null) {
				throw reject(new Error('No logo selected.'));
			}

			await this.props.setLogo(this.state.logo);
			this.setState(
				{
					logoMessage: {
						message: 'Logo set successfully.',
						success: true,
					},
				},
				resolve
			);
			this.props.theme.getAndSetActiveTheme(this.props.userType);
		} catch (error) {
			reject(error);
		}
	}

	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		/* eslint-disable react/jsx-handler-names -- handlers passed down to `on` handlers */
		return (
			<section className="mt-10">
				<h1 className="hl-ms-6 mb-6">{this.props.heading}</h1>
				<ThemeForm
					colorsMessage={this.state.colorsMessage}
					faviconMessage={this.state.faviconMessage}
					faviconPreview={this.state.faviconPreview}
					handleColorsSubmit={this.handleColorsSubmit}
					handleFaviconChange={this.handleFaviconChange}
					handleFaviconSubmit={this.handleFaviconSubmit}
					handleLogoChange={this.handleLogoChange}
					handleLogoSubmit={this.handleLogoSubmit}
					initialColorValues={this.state.initialColorValues}
					logoMessage={this.state.logoMessage}
					logoPreview={this.state.logoPreview}
				/>
			</section>
		);
	}
}

EditTheme.defaultProps = {
	heading: 'Edit Theme',
};

export default withTheme(EditTheme);
