const centsPerDollar = 100;

/**
 * @param {number} value
 * @param {number} [fractionDigits]
 * @returns {string}
 */
function formatter(value, fractionDigits = 0) {
	/* eslint-disable-next-line new-cap -- Defined by language */
	const formatted = Intl.NumberFormat('en-US', {
		currency: 'USD',
		minimumFractionDigits: fractionDigits,
		style: 'currency',
	});

	return formatted.format(value);
}

/**
 * @param {number} cents
 * @param {number} [fractionDigits]
 * @returns {string}
 */
export default function formatCentsForDisplay(cents, fractionDigits = 2) {
	return formatter(cents / centsPerDollar, fractionDigits);
}

/**
 * @param {number} dollars
 * @param {number} [fractionDigits]
 * @returns {string}
 */
export function formatDollarsForDisplay(dollars, fractionDigits = 0) {
	return formatter(dollars, fractionDigits);
}

/**
 * @param {number} cents
 * @returns {string}
 */
export function formatCentsForStylizedDisplay(cents) {
	return `${formatter(cents / (centsPerDollar * 1000))}K`;
}
