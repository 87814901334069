import { AuthContext } from '../../../../shared/AuthProvider';
import { translate } from '../../../Internationalization';
import * as validators from '../../../../shared/validators';
import * as yup from 'yup';
import AddressFields from '../../../../shared/Forms/Fields/Address/AddressFields';
import BaseField from '../../../../shared/Forms/Fields/BaseField';
import BaseForm from '../../../../shared/Forms/BaseForm';
import Feature from '../../../../shared/Feature';
import FieldRadioGroup from '../../../../shared/Forms/Fields/FieldRadioGroup';
import FieldEmail from '../../../../shared/Forms/Fields/FieldEmail';
import FieldLandlinePhone from '../../../../shared/Forms/Fields/PhoneNumber/FieldLandlinePhone';
import FieldMobilePhone from '../../../../shared/Forms/Fields/PhoneNumber/FieldMobilePhone';
import FormErrorMessages from '../../../../shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from '../../../../shared/Forms/Messages/FormSuccessMessages';
import images from '../../../../config/local/images';
import LinkButton from '../../../../shared/LinkButton';
import NameFields from '../../../../shared/Forms/Fields/Name/NameFields';
import GroupAgentFieldGroup from './GroupAgentFieldGroup';
import paths from '../../../../config/local/paths';
import React from 'react';
import SubmitButton from '../../../../shared/Forms/Inputs/SubmitButton';

const validationSchema = yup.object().shape({
	agentId: validators.required('Agent'),
	city: yup.string().nullable(),
	country: yup.string().nullable(),
	email: validators.email,
	firstName: yup.string().nullable(),
	groupId: validators.required('Group'),
	landlinePhoneNumber: yup.string().nullable(),
	lastName: yup.string().nullable(),
	mobilePhoneNumber: yup.string().nullable(),
	postalCode: yup.string().nullable(),
	state: yup.string().nullable(),
	streetAddress1: yup.string().nullable(),
	streetAddress2: yup.string().nullable(),
});

export default class ContactForm extends React.Component {
	static context = AuthContext;

	/** @returns {JSX.Element} */
	render() {
		return (
			<React.Fragment>
				<header className="flex items-center justify-between mt-10 mb-6">
					<h1 className="hl-ms-6">{this.props.formTitle}</h1>
					<LinkButton
						icon={images.icons.delete}
						to={paths.app.admin.contacts.view}
					>
						{translate('admin.pages.contacts.form.cancel')}
					</LinkButton>
				</header>

				<BaseForm
					initialValues={this.props.contactData}
					onSubmit={this.props.onSubmit}
					validationSchema={validationSchema}
				>
					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<legend className="h-ms-5 mb-6">
							{translate(
								'admin.pages.contacts.form.contact_title'
							)}
						</legend>
						<GroupAgentFieldGroup
							/* @ts-ignore - service container issue on the component */
							initialGroupValues={this.props.initialGroupValues}
						/>

						<NameFields />
						<FieldEmail className="col-span-full" required={true} />
						<FieldLandlinePhone name="landline" />
						<FieldMobilePhone name="mobile" />
					</fieldset>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<legend className="h-ms-5 mb-6">
							{translate(
								'global.forms.inputs.address.section_title'
							)}
						</legend>

						<AddressFields
							addressName="streetAddress1"
							cityName="city"
							countryName="country"
							designationName="streetAddress2"
							postalCodeName="postalCode"
							stateName="state"
						/>
					</fieldset>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<legend className="h-ms-5 mb-6">
							{translate(
								'admin.pages.contacts.form.moving_title'
							)}
						</legend>

						<BaseField
							label={translate(
								'agent.pages.contacts.form.move_date.label'
							)}
							name="moveDate"
							placeholder={translate(
								'agent.pages.contacts.form.move_date.placeholder'
							)}
							type="date"
						/>

						<BaseField
							label={translate(
								'agent.pages.contacts.form.close_date.label'
							)}
							name="closeDate"
							placeholder={translate(
								'agent.pages.contacts.form.close_date.placeholder'
							)}
							type="date"
						/>
					</fieldset>

					{this.props.create ? (
						<Feature
							fallback={null}
							name="admin:disable_contact_invite"
						>
							<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
								<legend className="h-ms-5 mb-6">
									{translate(
										'admin.pages.contacts.form.invitations'
									)}
								</legend>

								<FieldRadioGroup
									disabled={false}
									name="sendInvite"
									required={true}
									title={translate(
										'agent.pages.contacts.add.invitation'
									)}
									values={[
										[
											'yes',
											translate(
												'import_contacts.invitations_radio_labels.yes'
											),
										],
										[
											'no',
											translate(
												'import_contacts.invitations_radio_labels.no'
											),
										],
									]}
								/>
							</fieldset>
						</Feature>
					) : null}

					<footer className="mx-auto sm:ml-0 button-group w-min">
						<SubmitButton>
							{translate('global.forms.buttons.save')}
						</SubmitButton>
						{this.props.additionalButtons}
						<LinkButton
							icon={images.icons.delete}
							size="large"
							to={paths.app.admin.contacts.view}
						>
							{translate('global.forms.buttons.cancel')}
						</LinkButton>
					</footer>

					<FormSuccessMessages
						messages={this.props.resendInviteMessage}
					/>
					<FormErrorMessages messages={this.props.errors} />
				</BaseForm>
			</React.Fragment>
		);
	}
}

ContactForm.defaultProps = {
	contactData: {
		agentId: null,
		city: null,
		closeDate: null,
		country: null,
		email: null,
		firstName: null,
		groupId: null,
		landline: null,
		lastName: null,
		mobile: null,
		moveDate: null,
		postalCode: null,
		sendInvite: true,
		state: null,
		streetAddress1: null,
		streetAddress2: null,
		title: null,
	},
};
