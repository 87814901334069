/**
 * @typedef {import('./EditAdministratorDetailsFormComponent').FormValues} FormValues
 * @typedef {import('./EditAdministratorDetailsFormComponent').Props} Props
 * @typedef {import('./EditAdministratorDetailsFormComponent').State} State
 */

import { AuthContext } from '../../shared/AuthProvider';
import { file as validateFile } from '../../shared/validators';
import { translate } from '../Internationalization';
import * as validators from '../../shared/validators';
import * as yup from 'yup';
import adminOccupations from '@mooveguru/yhh-shared-config/occupation.json';
import BaseForm from '../../shared/Forms/BaseForm';
import captureError from '../../utils/captureError';
import Feature from '../../shared/Feature';
import FieldEmail from '../../shared/Forms/Fields/FieldEmail';
import FieldFile from '../../shared/Forms/Fields/FieldFile';
import FieldMobilePhone from '../../shared/Forms/Fields/PhoneNumber/FieldMobilePhone';
import FieldPhoneNumber from '../../shared/Forms/Fields/PhoneNumber/FieldPhoneNumber';
import FieldSelect from '../../shared/Forms/Fields/FieldSelect';
import FormErrorMessages from '../../shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from '../../shared/Forms/Messages/FormSuccessMessages';
import Loading from '../../shared/Loading';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import NameFields from '../../shared/Forms/Fields/Name/NameFields';
import React from 'react';
import SelectOptionList from '../../shared/Forms/Inputs/SelectOptionList';
import SubmitButton from '../../shared/Forms/Inputs/SubmitButton';
import uploadSize from '@mooveguru/yhh-shared-config/files/max-upload.json';

/** @extends {React.Component<Props, State>} */
export default class EditAdministratorDetailsForm extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State}  */
		this.state = {
			errorMessage: null,
			file: undefined,
			headshot: undefined,
			initialFormValues: {
				email: '',
				firstName: '',
				lastName: '',
				mobilePhoneNumber: '',
				occupation: '',
				phoneNumber: '',
			},
			isGettingData: true,
			isManager: false,
			successMessage: null,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this._handleFileUploadChange = this._handleFileUploadChange.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		this.setState({
			isManager: this.context.roles.includes('manager'),
		});

		try {
			const adminData =
				await this.props.administratorService.getAdministratorPersonalData();
			this.setState({
				headshot: adminData?.headshot ?? '',
				initialFormValues: {
					email: adminData.email,
					firstName: adminData?.name?.first,
					lastName: adminData?.name?.last,
					mobilePhoneNumber: adminData?.phone?.mobile,
					occupation: adminData?.occupation,
					phoneNumber: adminData.phone?.landline,
				},
				isGettingData: false,
			});
		} catch (error) {
			captureError(error);
			this.#handleException(error);
		}
	}

	/**
	 * @protected
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @returns {void}
	 */
	_handleFileUploadChange(event) {
		if (!event?.target?.files?.length) {
			return;
		}

		this.setState({ file: event.target.files[0] });
	}

	/**
	 * @param {FormValues} values
	 * @returns {Promise<void>}
	 */
	async handleSubmit(values) {
		if (this.state.file) {
			try {
				validateFile(this.state.file, mimeList.images, 'image');
			} catch (error) {
				this.setState({
					errorMessage: translate(
						'homeowner.pages.documents.upload_error'
					),
				});

				return;
			}
		}

		try {
			await this.props.administratorService.updateAdministratorPersonalData(
				{
					email: values.email,
					name: {
						first: values.firstName,
						last: values.lastName,
					},
					occupation: values.occupation,
					phone: {
						landline: values.phoneNumber,
						mobile: values.mobilePhoneNumber,
					},
				},
				this.state.file || undefined
			);

			this.setState({
				errorMessage: null,
				successMessage: translate(
					'settings.sections.profile_update_success_message'
				),
			});
		} catch (error) {
			captureError(error);
			this.#handleException(error);
		}
	}

	/**
	 * @param {unknown} error
	 * @returns {void}
	 */
	#handleException(error) {
		this.setState({
			errorMessage:
				error instanceof Error
					? error.message
					: translate(
							'settings.sections.profile_update_error_message'
					  ),
			isGettingData: false,
			successMessage: null,
		});
	}

	/**
	 * @param {boolean} isManager
	 * @returns {object}
	 */
	static getValidations(isManager) {
		return yup.object().shape({
			email: validators.email,
			firstName: validators.firstName,
			lastName: validators.lastName,
			mobilePhoneNumber: isManager
				? yup.string().nullable()
				: validators.phoneNumber.required(
						translate('global.validator.mobile_is_required')
				  ),
			phoneNumber: isManager
				? yup.string().nullable()
				: validators.phoneNumber.required(
						translate('global.validator.landline_is_required')
				  ),
		});
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	getFileUploader() {
		if (!this.state.isManager) {
			return null;
		}
		return (
			<fieldset className="mb-8">
				<label
					className="block h-ms-3 mb-4 input-label"
					htmlFor="headshot"
				>
					{translate('global.forms.inputs.image.title')}
				</label>

				<FieldFile
					accept={Object.values(mimeList.images)}
					// @ts-ignore -- Issue with file component
					label={translate('global.forms.inputs.image.label')}
					maxSize={uploadSize.image}
					name="headshot"
					onChange={this._handleFileUploadChange}
					preview={this.state.headshot ?? ''}
					type="file"
				/>
			</fieldset>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			// @ts-ignore -- Issue with `<BaseForm />`
			<BaseForm
				className="search-form-field"
				initialValues={this.state.initialFormValues}
				onSubmit={this.handleSubmit}
				// @ts-ignore -- issue with BaseForm
				validationSchema={EditAdministratorDetailsForm.getValidations(
					this.state.isManager
				)}
			>
				<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
					<NameFields required={true} />
					<FieldEmail className="col-span-full" required={true} />
					<FieldPhoneNumber
						required={!this.state.isManager}
						type={this.state.isManager ? 'landline' : 'phone'}
					/>
					<FieldMobilePhone required={!this.state.isManager} />
					<FieldSelect
						className="col-span-full"
						defaultValue={this.state.initialFormValues.occupation}
						label="Occupation"
						name="occupation"
					>
						<SelectOptionList values={adminOccupations} />
					</FieldSelect>
				</fieldset>
				<Feature fallback={null} name="manager:upload_profile">
					{this.getFileUploader()}
				</Feature>

				<footer className="mx-auto sm:ml-0 button-group w-min">
					<SubmitButton>
						{translate('global.forms.buttons.save')}
					</SubmitButton>
				</footer>

				<FormSuccessMessages messages={[this.state.successMessage]} />
				<FormErrorMessages messages={[this.state.errorMessage]} />
			</BaseForm>
		);
	}
}

EditAdministratorDetailsForm.contextType = AuthContext;
