/**
 * @typedef {import('./CreateDocumentProps').default} Props
 * @typedef {import('./CreateDocumentState').default} State
 */
import { AuthContext } from '../../../shared/AuthProvider';
import { file as validateFile } from '../../../shared/validators';
import { Redirect } from 'react-router';
import { saveDocument } from './documents-service';
import { translate } from '../../Internationalization';
import { withProperty } from '../PropertyProvider';
import captureError from '../../../utils/captureError';
import DocumentForm from './DocumentForm';
import FormErrorMessages from '../../../shared/Forms/Messages/FormErrorMessages';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import paths from '../../../config/local/paths';
import React from 'react';

const successMessage = translate('homeowner.pages.documents.success_message');

/** @extends {React.Component<Props, State>} */
class CreateDocument extends React.Component {
	/**
	 * @readonly
	 * @type {('handleFileUploadChange'|'handleSubmit')[]}
	 */
	#bindMethods = ['handleFileUploadChange', 'handleSubmit'];

	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		this.state = {
			/*
			 * !! Do not put `document` in initial values, it will break
			 * !! functionality with the file selector. File inputs cannot
			 * !! have a "default" value.
			 */
			error: null,
			file: null,
			initialValues: {
				category: this.props.match.params.category
					? this.props.match.params.category.replace(/-/g, '_')
					: '',
				title: '',
			},
			success: false,
		};

		for (const method of this.#bindMethods) {
			// @ts-ignore
			this[method] = this[method].bind(this);
		}
	}

	/**
	 * @param {unknown} error
	 * @returns {void}
	 */
	#error(error) {
		captureError(error);

		this.setState({ error: translate('global.error') });
	}

	/**
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @returns {void}
	 */
	handleFileUploadChange(event) {
		if (!event.target?.files?.length) {
			return;
		}

		this.setState({ file: event.target.files[0] });
	}

	/**
	 * @param {import('formik').FormikValues} input
	 * @returns {Promise<void>}
	 */
	async handleSubmit(input) {
		// TODO: Push to API
		if (!this.state.file) {
			this.setState({ error: 'No file selected.' });

			return;
		}

		let response;

		try {
			validateFile(this.state.file, mimeList.documents, 'document');
		} catch (error) {
			this.setState({
				error:
					error instanceof Error
						? error.message
						: translate('homeowner.pages.documents.upload_error'),
			});

			return;
		}

		const formData = new FormData();
		formData.append('files', this.state.file);
		formData.append('category', input.category);
		formData.append('title', input.title);

		formData.append(
			'propertyId',
			this.props.property?.selectedProperty?.id
		);

		try {
			response = await saveDocument(formData, this.context.accessToken);
		} catch (error) {
			this.#error(error);

			return;
		}

		if (response) {
			this.setState({ success: true });

			return;
		}

		this.#error(
			'There was an error uploading your file, please try again.'
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.success) {
			return (
				<Redirect
					to={{
						pathname: paths.app.homeowner.documents.root,
						state: { message: successMessage },
					}}
				/>
			);
		}
		return (
			<React.Fragment>
				{/* eslint-disable react/jsx-handler-names -- TODO: refactor handler names */}
				<DocumentForm
					error={this.state.error}
					handleFileUploadChange={this.handleFileUploadChange}
					handleSubmit={this.handleSubmit}
					initialValues={this.state.initialValues}
				/>
				{/* eslint-enable react/jsx-handler-names */}
				<FormErrorMessages messages={[this.state.error]} />
			</React.Fragment>
		);
	}
}

CreateDocument.contextType = AuthContext;
export default withProperty(CreateDocument);
