import PublicLayout from 'shared/PublicLayout';
import React from 'react';

export default class TermsOfUse extends React.Component {
	render() {
		return (
			<PublicLayout>
				<main className="mx-5 main-content">
					<nav className="navigation-container mx-4">
						<ul className="navigation-list">
							<li className="navigation-item">
								<b> Table Of Contents </b>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#acceptance-of-terms-of-use"
								>
									Acceptance Of Terms Of Use
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#terms-of-use"
								>
									TERMS OF USE
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#no-advice"
								>
									No Advice
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#description-of-the-service"
								>
									Description of the Service
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#restrictions"
								>
									Restrictions
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#unique-identifiers"
								>
									Sign-in Name; Password; Unique Identifiers
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#information-supplied-and-using-our-websites"
								>
									Information Supplied and Using Our Websites
								</a>
							</li>
							<li className="navigation-item">
								<a className="navigation-link" href="#payment">
									Payment
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#credit-card-terms"
								>
									Credit Card Terms
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#intellectual-property"
								>
									Intellectual Property
								</a>
							</li>
							<li>
								<a href="#communications">
									Communications To HomeKeepr; Participation
									Disclaimer
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#representations-and-warranties"
								>
									Representations and Warranties
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#external-websites"
								>
									External Websites
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#indemnification"
								>
									Indemnification
								</a>
							</li>
							<li className="navigation-item">
								<a className="navigation-link" href="#dmca">
									Digital Millennium Copyright Act
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#compliance-with-applicable-laws"
								>
									Compliance With Applicable Laws
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#termination-of-the-agreement"
								>
									Termination of the Agreement
								</a>
							</li>
							<li className="navigation-item">
								<a className="navigation-link" href="#privacy">
									Privacy
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#ada-compliance"
								>
									ADA COMPLIANCE
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#miscellaneous"
								>
									Miscellaneous
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#collect-information"
								>
									1. WHAT INFORMATION DO WE COLLECT?
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#use-information"
								>
									2. HOW DO WE USE YOUR INFORMATION?
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#shared-information"
								>
									3. WILL YOUR INFORMATION BE SHARED WITH
									ANYONE?
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#who-shared-information"
								>
									4. WHO WILL YOUR INFORMATION BE SHARED WITH?
								</a>
							</li>
							<li className="navigation-item">
								<a className="navigation-link" href="#cookies">
									5. DO WE USE COOKIES AND OTHER TRACKING
									TECHNOLOGIES?
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#keep-information"
								>
									6. HOW LONG DO WE KEEP YOUR INFORMATION?
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#safe-information"
								>
									7. HOW DO WE KEEP YOUR INFORMATION SAFE?
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#minor-information"
								>
									8. DO WE COLLECT INFORMATION FROM MINORS?
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#privacy-rights"
								>
									9. WHAT ARE YOUR PRIVACY RIGHTS?
								</a>
							</li>
							<li className="navigation-item">
								<a className="navigation-link" href="#controls">
									10. CONTROLS FOR DO-NOT-TRACK FEATURES
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#california-residents"
								>
									11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC
									PRIVACY RIGHTS?
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#notice-updates"
								>
									12. DO WE MAKE UPDATES TO THIS NOTICE?
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#contact-notice"
								>
									13. HOW CAN YOU CONTACT US ABOUT THIS
									NOTICE?
								</a>
							</li>
							<li className="navigation-item">
								<a
									className="navigation-link"
									href="#review-update-delete"
								>
									14. HOW CAN YOU REVIEW, UPDATE OR DELETE THE
									DATA WE COLLECT FROM YOU?
								</a>
							</li>
						</ul>
					</nav>
					<article
						className="terms-of-service-container"
						id="acceptance-of-terms-of-use"
					>
						<h1>Acceptance Of Terms Of Use</h1>
						<p>
							We’re excited that you have decided to access
							subject to the terms and conditions (the “Terms of
							Use”) set forth herein the HomeKeepr.com website
							(the “User Website”), homeowners.HomeKeepr.com (the
							“Homeowner Website”), pros.HomeKeepr.com (the “Real
							Estate Professional Website”), brokers.HomeKeepr.com
							(the “Broker Website”) (collectively the
							“Websites”), our Facebook and/or our other social
							media plugins (the “Social Media Plugins”), and/or
							our mobile applications (the “Mobile Apps,” and
							collectively with the Social Media Plugins and the
							Websites, the “Service”). This document constitutes
							a legally binding agreement governing the terms and
							conditions of providing you with our Service. The
							terms “we,” “us,” “our,” and “HomeKeepr” refer to
							MooveGuru, Inc, the company that owns and operates
							the Websites and the Mobile Apps, and any of its
							affiliates, grouprs, employees, agents, insurers,
							joint ventures, partners, successor, transferees and
							assigns (hereinafter “Company”).
						</p>
						<p>
							HomeKeepr provides its services to you subject to
							the following Terms of Use, which may be updated by
							us from time to time without notice to you. By
							browsing the public areas or by accessing and using
							the Service, including all content available through
							the Websites, you acknowledge that you have read,
							understood, and agree to be legally bound by the
							terms and conditions of these Terms of Use and the
							terms and conditions of HomeKeepr’s Privacy Policy,
							which is hereby incorporated by reference
							(collectively, this “Agreement.”). If you do not
							agree to any of these terms, then please do not use
							our Service.
						</p>
						<section id="terms-of-use">
							<h2 className="my-2">TERMS OF USE</h2>
							<section id="no-advice">
								<h3>No Advice</h3>
								<p>
									<strong>
										THIS WEBSITE DOES NOT PROVIDE ADVICE.
										The Website and the Service are provided
										for informational purposes only.
									</strong>{' '}
									Other Registered Users may refer a Service
									Provider to you through the Service, but
									HomeKeepr is not a referral service, and
									neither HomeKeepr nor Registered Users, Real
									Estate Professionals or other users
									recommend or endorse in any way any specific
									Service Providers, nor do they guaranty or
									warranty the Services in any way. Reliance
									on the Websites and the Service, and any
									data or content, is solely at your own risk.
									Any suggestion by a Real Estate Professional
									is in no way endorsed or suggested by the
									Real Estate Professional’s applicable real
									estate broker. Nothing in the Service is
									intended to provide Legal advice and such is
									expressly disclaimed hereby.
								</p>
								<p>
									Berkshire Hathaway HomeServices does not
									endorse any of the products or vendors
									referenced on this material. Any mention of
									vendors, products or services is for
									informational purposes only.
								</p>
							</section>
							<section id="description-of-the-service">
								<h3>Description of the Service</h3>
								<p>
									Among other things, HomeKeepr is a search
									engine that permits consumers, real estate
									professionals (“Real Estate Professionals”),
									and service professionals (“Service
									Providers”) to connect with each other. It
									also allows for Real Estate Professionals to
									provide a list of Service Providers for
									customers’ and clients’ use.
								</p>
								<p>
									If you are a Registered User or Visitor, you
									can search for what you need using our
									Service, and we will display professionals
									who can provide the necessary service.
									Nothing in the Service is providing a
									referral or recommendation to use a
									particular Real Estate Professional or
									Service Provider.
								</p>
								<p>
									If you are a Service Professional, we will
									try to do our best to provide you with
									advertising and marketing tools.
								</p>
								<p>
									If you are a Real Estate Professional, we
									will provide a mechanism to connect your
									customers and clients who become Registered
									Users or Visitors with you and your
									preferred Service Providers provided you
									complete all of the relevant forms.
								</p>
								<p>
									In addition, the Service is dedicated to
									enabling Registered Users to share
									information about Service Providers with
									each other. All of the products and services
									that we offer on this Service are referred
									to in these Terms of Use collectively as the
									“Service.” The Company is no and shall not
									be responsible or liable for any information
									about Service Providers or Real Estate
									Professional published or shared.
								</p>
								<p>
									HomeKeepr provides Visitors and Registered
									Users with access to the Service as
									described in this Agreement.
								</p>
								<p>
									Visitors. Visitors, as the term implies, are
									people who don’t register with us, but want
									to see what the Service is all about. No
									login is required for Visitors. Visitors can
									(a) view all publicly-accessible content and
									(b) e-mail us. Visitors are subject to the
									Terms of Use and this Agreement regardless
									of registration.
								</p>
								<p>
									Registered Users. Login is required for all
									Registered User services. Registered Users
									can do all the things that Visitors can do,
									and also (a) search for Service Providers,
									(b) review information about Service
									Providers, such as contact information,
									professional affiliations, education, etc.,
									(c) access comments about Service Providers
									from other Registered Users, (d) create
									feedback and comments, (e) send emails, text
									messages, or push notifications to other
									Registered Users, (f) request friends to
									register for the Service, (g) sign up for
									promotions and sweepstakes and (h) blog
									about their experiences with the Service.
								</p>
								<p>
									Real Estate Professionals. Real Estate
									Professionals include, but are not limited
									to, real estate agents, real estate brokers,
									and mortgage professionals. Real Estate
									Professionals can do all the things that
									Visitors and Registered Users can do and
									they can also (a) enhance their profiles,
									(b) invite Visitors and Registered Users to
									join the Service, and (c) provide a list of
									Service Providers for customers’ and
									clients’ use.
								</p>
								<p>
									Service Providers. Service Providers are
									service providing businesses. Service
									Providers can do all the things that
									Visitors and Registered Users can do and
									they can also submit requests to update and
									enhance their profiles.
								</p>
								<p>
									HomeKeepr is under no obligation to accept
									any individual as a Visitor, Registered
									User, a Real Estate Professional or a
									Service Provider, and may accept or reject
									any registration in its sole and complete
									discretion. Company may block any Visitor at
									any time for any or no reason.
								</p>
								<p>
									Also, in order to use the Service via our
									Mobile Apps, Registered Users, Real Estate
									Professionals and Service Providers hereby
									consent to: (a) the use of their Mobile
									Device’s Geolocational Information to
									provide the Service; (b) receive text
									messages; and (c) pay carrier data,
									messaging, and other fees resulting from
									their usage of the Service.
								</p>
							</section>
							<section id="restrictions">
								<h3>Restrictions</h3>
								<p>
									The Service is available only to individuals
									and entities that can form legally binding
									contracts under applicable law. By
									accessing, downloading, and/or otherwise
									using the Websites and/or the Service, you
									(a) certify that you are at least 18 years
									of age and (b) have read and agree to be
									legally bound by these Terms of Use. In
									accordance with Federal Children’s Online
									Privacy Protection Act of 1998 (COPPA), we
									will never knowingly solicit, nor will we
									accept, personally identifiable information
									from users known to be under thirteen (13)
									years of age. Please see our Privacy Policy,
									which is hereby incorporated herein by
									reference, for additional information or for
									how to notify us of any concerns you may
									have in this regard.
								</p>
							</section>
							<section id="unique-identifiers">
								<h3>
									Sign-in Name; Password; Unique Identifiers
								</h3>
								<p>
									During the registration process for
									Registered Users, we will ask you to create
									an account, which includes a unique sign-in
									name (“Sign-In Name”), password
									(“Password”), and perhaps certain additional
									information that will assist in
									authenticating your identity when you log-in
									in the future (“Unique Identifiers”). When
									creating your account, you must provide
									true, accurate, current, and complete
									information. Each Sign-In Name and
									corresponding Password can be used by only
									one Registered User. You are solely
									responsible for the confidentiality and use
									of your Sign- In Name, Password, and Unique
									Identifiers, as well as for any use, misuse,
									or communications entered through the
									Websites and/or Mobile Apps using one or
									more of them. You will promptly inform
									HomeKeepr of any need to deactivate a
									Password or Sign-In Name, or change any
									Unique Identifier. HomeKeepr and company
									reserves the right to delete or change your
									Password, Sign-In Name, or Unique Identifier
									at any time and for any or no reason.
									HomeKeepr and Company will not be liable for
									any loss or damage caused by any
									unauthorized use of your account, malware,
									corrupted data, computer viruses, defective
									or invasive software.
								</p>
								<p>
									You may also register for and use the
									Service through Facebook and/or other third-
									party Social Media sites, in which case we
									will use the information provided by such
									Social Media sites to complete your
									registration. For more information about the
									Personal Information we may receive from
									third-party Social Media sites, please refer
									to our Privacy Policy. Company shall have no
									responsibility or liability for loss or
									damages related to, arising from, or
									involving any Social Media websites or
									applications.
								</p>
							</section>
							<section id="information-supplied-and-using-our-websites">
								<h3>
									Information Supplied and Using Our Websites
								</h3>
								<p>
									In order for you to sign up with us, you may
									be required to provide your name, e-mail
									address, and zip code. We will also ask you
									general questions about the services you are
									requesting.
								</p>
								<p>
									In addition to providing us with the above
									information, you must be at least eighteen
									years old to sign up for our service, or, if
									a higher age of contractual capacity exists
									in your jurisdiction, then you must be at
									least that age.
								</p>
								<p>
									In addition, when interacting with our
									Websites or the Service or the Company you
									agree that:
								</p>
								<ul>
									<li className="navigation-item">
										You will not deceive us or any of the
										Service Providers or Real Estate
										Professionals on our platform;
									</li>
									<li className="navigation-item">
										You will not use the site or the
										HomeKeepr platform to engage in any
										illegal conduct, allude to or request
										any illegal services;
									</li>
									<li className="navigation-item">
										You will not request Services for anyone
										with whom you do not have authority to
										conduct business on their behalf;
									</li>
									<li className="navigation-item">
										You will not harass and/or stalk any
										employee, member, the Company, Real
										Estate Professional, or Service Provider
										or behave in lewd, disparaging,
										political, or inappropriate conduct or
										communications;
									</li>
									<li className="navigation-item">
										You will not post, submit, or convey any
										false, misleading, unlawful, defamatory,
										untruthful, inaccurate, obscene,
										invasive, threatening, harassing,
										inflammatory, or fraudulent content,
										hate speech or information;
									</li>
									<li className="navigation-item">
										You will not impersonate any person,
										entity, or company;
									</li>
									<li className="navigation-item">
										You will not use our Service to send
										“spam”, texts, phone calls, facsimiles,
										or other unsolicited commercial or
										non-commercial material to any of our
										other members or other legal persons;
									</li>
									<li className="navigation-item">
										You will not use us to “secret shop”
										other businesses;
									</li>
									<li className="navigation-item">
										You will not hack, crack, phish, SQL
										inject, install any code that is
										malicious, invasive or manipulates the
										site, data or content in any way, or
										otherwise compromise the security,
										safety, or privacy of our Service or our
										network, servers, member accounts,
										mobile numbers or access to the numbers,
										or the computers of our users;
									</li>
									<li className="navigation-item">
										You will not engage or solicit any other
										users to buy or sell products or
										services unless expressly authorized by
										the Service;
									</li>
									<li className="navigation-item">
										You will not share your login and
										password with any legal person;
									</li>
									<li className="navigation-item">
										You will not run any script or other
										program which is designed to search,
										index, or aggregate the User Content or
										other information on our Service, unless
										we have given you our express written
										consent to do so, which we may revoke at
										any time;
									</li>
									<li className="navigation-item">
										You will not post any reviews that are
										false, defamatory, disparaging,
										inappropriate, political or invade
										someone’s privacy (if we give you the
										option of providing a review)
									</li>
								</ul>
							</section>
							<section id="payment">
								<h3>Payment</h3>
								<p>
									Our Service is currently free to use for
									Registered Users and temporarily for
									Visitors.
								</p>
								<p>
									If you are a Real Estate Professional you
									may be charged a monthly or annual
									subscription fee for the Service only if you
									have selected a paid plan. If you are a
									Service Provider, you will have the option,
									but not the obligation, to purchase various
									enhanced advertising opportunities. For
									example, if you choose to participate in a
									paid plan we may charge a fee for Service
									Providers to obtain preferred placement in
									the list of Service Providers, provision of
									additional information in your profile,
									promotional endeavors and other preferred
									provider items that we may offer from
									time-to-time. Although HomeKeepr or Company
									may offer payment by receipt of a check in
									certain instances, the main method of
									payment will be through the use of a credit
									card that Service Providers and Real Estate
									Professionals would include as part of their
									profile.
								</p>
							</section>
							<section id="credit-card-terms">
								<h3>Credit Card Terms</h3>
								<p>
									As a Service Provider or Real Estate
									Professional, you will be required to pay
									almost all fees via a credit card. As such,
									you hereby agree to the following terms and
									conditions related to credit card use:
								</p>
								<p>By entering your credit card information:</p>
								<ol>
									<li className="navigation-item">
										You are stating that you are an
										authorized user of the credit card and
										that the associated information entered
										(account holder name, account number,
										billing address, etc.) is accurate and
										complete.
									</li>
									<li>
										You authorize HomeKeepr or the Company
										to charge the amount you have requested
										to your credit card.
									</li>
									<li className="navigation-item">
										If you set up automatic payments, then
										you authorize HomeKeepr or the Company
										to charge the amount due for the invoice
										being paid to the credit card.
									</li>
									<li>
										You also authorize HomeKeepr or the
										Company to return to your credit card
										any funds due to you by HomeKeepr
										resulting from use of this Service.
									</li>
									<li className="navigation-item">
										For each transaction, in addition to the
										charge you have authorized, your credit
										card issuer and network may assess their
										customary transaction or handling
										charge, if any.
									</li>
									<li className="navigation-item">
										If a charge is declined or reversed by
										the credit card issuer or network, you
										agree to pay us a service charge and to
										reimburse us for all reasonable costs of
										collection. Your credit card issuer may
										also assess its customary charge for
										such transactions. You agree that such
										service charge of $__50_ is fair and
										reasonable.
									</li>
									<li className="navigation-item">
										If your credit card issuer or network
										does not honor an online payment
										transaction, then we have the right to
										charge the amount of any such
										transaction to your account or to
										collect the amount from you.
									</li>
									<li className="navigation-item">
										If your credit card issuer or network
										does not honor an online payment
										transaction, we may terminate any or all
										Service, and we may cancel your right to
										participate in the Service altogether in
										our sole discretion.
									</li>
									<li className="navigation-item">
										By clicking “Submit,” you are consenting
										to receive a one-time confirmation of
										this payment electronically to the email
										address you have provided to us.
									</li>
									<li className="navigation-item">
										If you set up automatic payments, then
										you are consenting to receive a one-time
										confirmation of each payment
										electronically to the email address you
										have provided to us.
									</li>
									<li className="navigation-item">
										In order to make credit card payments
										online or receive electronic
										communications, you must have access to
										a personal computer with a 128 bit
										JavaScript enabled browser, internet
										access and a valid email account
										supported by software that enables you
										to receive electronic communications. In
										order to store electronic communications
										on paper, you will need a printer
										connected to your computer. You also may
										store them in your offline files or in
										an electronic storage device. HomeKeepr
										and/or Company is not required to
										provide paper copies of any electronic
										Communication you have previously
										authorized.
									</li>
									<li className="navigation-item">
										You agree to provide us, and keep
										current, a valid email address. If any
										electronic communication is returned to
										us as undeliverable, you agree that we
										may (but are not required to) deliver
										such communication in paper form to the
										most recent mailing address you have
										provided for your account. Failure to
										comply with the restriction may result
										in termination of the Service.
									</li>
									<li className="navigation-item">
										By authorizing a credit card payment
										online, or by requesting electronic
										communications, you confirm that your
										system meets these requirements, that
										you have the capability to access and
										download or print electronic
										disclosures, and that your email address
										is current and valid.
									</li>
								</ol>
							</section>
							<section id="intellectual-property">
								<h3>Intellectual Property</h3>
								<p>
									The Service contains material, such as
									software, text, data, graphics, images,
									sound recordings, know how, audiovisual
									works, intellectual property and other
									material provided by or on behalf of
									HomeKeepr and/or the Company (collectively
									referred to as the “HomeKeepr Content”). The
									HomeKeepr Content may be owned by us or by
									third parties. The HomeKeepr Content is
									protected under both United States,
									international law, and foreign laws,
									including customary international law
									including jus cugens. Unauthorized use of
									the HomeKeepr Content may violate copyright,
									trademark, patent, treaty, and other laws.
									We give you permission, which may be deemed
									an exclusive, worldwide, royalty free
									revocable license for any reason or no
									reason at anytime, to use the aforementioned
									content for personal, non-commercial
									purposes only and do not transfer any
									intellectual property rights to you by
									virtue of permitting your use of the
									Websites or the Service. You may print,
									download, and store information from the
									Service for your own convenience, but you
									may not copy, distribute, republish (except
									as permitted in this paragraph), sell, or
									exploit any of the content, or exploit the
									Service in whole or in part, for any
									commercial gain or purpose whatsoever except
									as expressly provided herein. You must
									retain all copyright, trademark, patent and
									other proprietary notices contained in the
									original HomeKeepr Content on any copy you
									make of the HomeKeepr Content.
								</p>
								<p>
									The trademarks, service marks, and logos of
									HomeKeepr and/or the Company (the HomeKeepr
									Trademarks”) used and displayed on the
									Service are registered and unregistered
									trademarks and/or service marks of
									MooveGuru, Inc. Other company, product, and
									service names located on the Service may be
									trademarks or service marks owned by others
									(the “Third-Party Trademarks”, and,
									collectively with HomeKeepr Trademarks, the
									“Trademarks”). Nothing on the Service should
									be construed as granting, by implication,
									estoppel, or otherwise, any license or right
									to use the Trademarks, without our prior
									written permission specific for each such
									use. Use of the Trademarks as part of a link
									to or from any site is prohibited unless
									establishment of such a link is approved in
									advance by us in writing. All goodwill
									generated from the use of HomeKeepr
									Trademarks and other intellectual property
									of whatever kind and nature inures to
									HomeKeepr’s benefit.
								</p>
								<p>
									Elements of the Service are protected by
									trade dress, trademark, unfair competition,
									and other state and federal and
									international laws and may not be copied or
									imitated in whole or in part, by any means,
									including but not limited to the use of
									framing or mirrors. None of the HomeKeepr
									Content for this Service may be
									retransmitted decompiled, reverse
									engineered, or otherwise manipulated without
									our express, written consent for each and
									every instance.
								</p>
							</section>
							<section id="communications">
								<h3>
									Communications To HomeKeepr; Participation
									Disclaimer
								</h3>
								<p>
									Although we encourage you to e-mail us, we
									do not want you to, and you should not, e-
									mail us any content that contains
									confidential or private information. With
									respect to all e-mails you send to us,
									including but not limited to, feedback,
									questions, comments, suggestions, and the
									like, we shall be free to use any ideas,
									concepts, know-how, or techniques contained
									in your communications for any purpose
									whatsoever, including but not limited to,
									the development, production and marketing of
									products and services that incorporate such
									information.
								</p>
								<p>
									HomeKeepr and/or the Company does not and
									cannot review all communications and
									materials posted to or created by users
									accessing the Service (hereinafter, “User
									Generated Content”), and is not in any
									manner responsible for the content of the
									User Generated Content. Remember that the
									User Generated Content is simply opinion and
									should not be relied on. You acknowledge
									that by providing you with the ability to
									view and distribute User Generated Content
									on the Service, HomeKeepr and/or the Company
									is merely acting as a passive conduit for
									such distribution and is not undertaking any
									obligation or liability relating to any
									contents or activities on the Service.
									However, HomeKeepr reserves the right to
									block or remove communications or materials
									that it determines to be (a) abusive,
									defamatory, disparaging, or obscene, (b)
									fraudulent, deceptive, or misleading, (c) in
									violation of a copyright, trademark or;
									other intellectual property right of
									another, (d) political or offensive or
									otherwise unacceptable to HomeKeepr and/or
									the Company in its sole discretion.
								</p>
								<p>
									You retain all copyrights and other
									intellectual property rights in and to
									anything you post to the Service, including
									User Generated Content, text and
									photographs. You do, however, grant us an
									irrevocable, non-exclusive, worldwide,
									perpetual, royalty-free license to use,
									modify, copy, distribute, publish, perform,
									sublicense, and create derivative works from
									all submissions or compilations of
									submissions or data you provide to us in any
									media now known or hereafter to advertise
									and promote the Service and/or HomeKeepr.
								</p>
							</section>
							<section id="representations-and-warranties">
								<h3>Representations and Warranties</h3>
								<p>
									As mentioned before, we are an intermediary
									between you and potential Service Providers.
									Any issues that may arise concerning the
									Service Provider and you, are between you
									and them. While we may have certain
									responsibilities connected with our
									platform, those responsibilities are
									limited. We may terminate any relationship
									we have with either you or the Service
									Provider in performing our services, the
									service without notice, and without any
									compensation for any loss, harm or injury.
								</p>
								<p>
									REGISTERED USERS, VISITORS, REAL ESTATE
									PROFESSIONALS, SERVICE PROVIDERS, AND OTHER
									USERS OF THE SERVICE AND THE WEBSITES ASSUME
									TOTAL RESPONSIBILITY AND RISK FOR THEIR OWN
									USE OF THE WEBSITES AND THE USE OF ANY
									SERVICE PROVIDERS SERVICE, OR PARTICIPATION
									IN ANY OF OUR RELATED SERVICES. HOMEKEEPR
									PROVIDES THE SERVICE AND WEBSITES AND
									RELATED INFORMATION “AS IS WHERE IS” AND ON
									AN “AS AVAILABLE” BASIS, AND DOES NOT MAKE
									ANY EXPRESS OR IMPLIED WARRANTIES,
									REPRESENTATIONS OR ENDORSEMENTS WHATSOEVER
									(INCLUDING WITHOUT LIMITATION WARRANTIES OF
									TITLE OR NON INFRINGEMENT, OR THE IMPLIED
									WARRANTIES OF MERCHANTABILITY OR FITNESS FOR
									A PARTICULAR PURPOSE) WITH REGARD TO THE
									WEBSITES, SERVICE, ANY PRODUCT OR SERVICE
									PROVIDER INFORMATION PROVIDED THROUGH THE
									WEBSITES, OR VIA THIRD PARTY LINKS, OR VIA
									ANY BROADCAST TO PROVIDERS, AND HOMEKEEPR
									AND/OR THE COMPANY SHALL NOT BE LIABLE FOR
									ANY COST OR DAMAGE ARISING EITHER DIRECTLY
									OR INDIRECTLY FROM ANY SUCH USE. IT IS
									SOLELY YOUR RESPONSIBILITY TO EVALUATE THE
									ACCURACY, COMPLETENESS AND USEFULNESS OF ALL
									SERVICE PROVIDERS, ADVICE, OR ANY SERVICE OR
									OTHER INFORMATION PROVIDED THROUGH THE SITE
									OR VIA THE SERVICE. WE DO NOT WARRANT THAT
									THE WEBSITES WILL BE UNINTERRUPTED OR
									ERROR-FREE OR THAT DEFECTS IN THE WEBSITES
									WILL BE CORRECTED.
								</p>
								<p>
									IF YOU, OR THE ENTITY YOU REPRESENT, ARE
									LOCATED IN A JURISDICTION THAT REQUIRES A
									SPECIFIC STATEMENT REGARDING RELEASE THEN
									THE FOLLOWING APPLIES. FOR EXAMPLE,
									CALIFORNIA RESIDENTS MUST, AS A CONDITION OF
									THIS AGREEMENT, WAIVE THE APPLICABILITY OF
									CALIFORNIA CIVIL CODE SECTION 1542, WHICH
									STATES, “A GENERAL RELEASE DOES NOT EXTEND
									TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW
									OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT
									THE TIME OF EXECUTING THE RELEASE, WHICH IF
									KNOWN BY HIM OR HER MUST HAVE MATERIALLY
									AFFECTED HIS OR HER SETTLEMENT WITH THE
									DEBTOR.” YOU HEREBY WAIVE THIS SECTION OF
									THE CALIFORNIA CIVIL CODE. YOU HEREBY WAIVE
									ANY SIMILAR PROVISION IN LAW, REGULATION, OR
									CODE THAT HAS THE SAME INTENT OR EFFECT AS
									THE AFOREMENTIONED RELEASE IN OTHER
									JURISDICTION.
								</p>
								<p>
									WE DISCLAIM ALL LIABILITY TO THE FULLEST
									EXTENT PERMITTED BY APPLICATION. WE WILL NOT
									BE RESPONSIBLE FOR ANY DAMAGES, WHETHER
									DIRECT OR INDIRECT, SPECIAL, CONSEQUENTIAL,
									EXEMPLARY, PUNITIVE, OR OTHER, EVEN IF WE
									KNEW OR SHOULD HAVE KNOWN. OUR LIABILITY IN
									ANY JURISDICTION THAT DOES NOT ALLOW FOR THE
									EXCLUSION OR LIMITATION OF LIABILITY, THEN
									TO THE EXTENT PERMITTED BY LAW BUT IN NO
									EVENT GREATER THAN THE AMOUNT YOU HAVE PAID
									UNDER THIS CONTRACT FOR THE PAST YEAR.
								</p>
							</section>
							<section id="external-websites">
								<h3>External Websites</h3>
								<p>
									The Service may contain links to third-party
									websites (“External Websites”). These links
									are provided solely as a convenience to you
									and not as an endorsement by us of the
									content on such External Websites. The
									content of such External Websites is
									developed and provided by others. You should
									contact the site administrator or webmaster
									for those External Websites if you have any
									concerns regarding such links or any content
									located on such External Websites. We are
									not responsible for the content of or
									interfacing of any linked External Websites
									and do not make any representations
									regarding the content or accuracy of
									materials on such External Websites. You
									should take precautions when downloading
									files from all websites to protect your
									computer and related devices from viruses
									and other destructive programs. If you
									decide to access linked External Websites,
									you do so at your own risk.
								</p>
							</section>
							<section id="indemnification">
								<h3>Indemnification</h3>
								<p>
									You agree to defend, indemnify, and hold us
									the Company and our grouprs, directors,
									employees, successors, transferees,
									licensees, and assigns forever harmless from
									and against any claims, actions, or demands,
									including, without limitation, reasonable
									legal and accounting fees, arising or
									resulting from your breach of this Agreement
									or your access to, use, or misuse of the
									HomeKeepr Content, the Websites, or the
									Service. We shall provide notice to you of
									any such claim, suit, or proceeding and
									shall assist you, at your expense, in
									defending any such claim, suit, or
									proceeding. We reserve the right to assume
									the exclusive defense and control of any
									matter that is subject to indemnification
									under this section. In such case, you agree
									to cooperate with any reasonable requests
									assisting our defense of such matter.
								</p>
							</section>
							<section id="dmca">
								<h3>Digital Millennium Copyright Act</h3>
								<p>
									HomeKeepr respects the intellectual property
									rights of others and attempts to comply with
									all relevant laws. We will review all claims
									of copyright infringement received and
									remove User Generated Content deemed to have
									been posted or distributed in violation of
									any such laws.
								</p>
								<p>
									Our designated agent under the Digital
									Millennium Copyright Act (the “Act”) for the
									receipt of any Notification of Claimed
									Infringement which may be given under that
									Act is as follows:
								</p>
								<address className="navigation-link">
									Name: MooveGuru, Inc
									<br />
									Address: 885 Woodstock Rd, Suite 430-206,
									Roswell, GA 30075
									<br />
									Tel:{' '}
									<a
										className="navigation-link"
										href="tel:917-688-4482"
									>
										(917) 688-4482
									</a>
									<br />
									Email:{' '}
									<a href="mailto:info@HomeKeepr.com">
										info@HomeKeepr.com
									</a>
								</address>
								<p>
									If you believe that your work has been
									copied on the Service in a way that
									constitutes copyright infringement, please
									provide our agent with notice in accordance
									with the requirements of the Act, including
									(i) a description of the copyrighted work
									that has been infringed and the specific
									location on the Service where such work is
									located; (ii) a description of the location
									of the original or an authorized copy of the
									copyrighted work; (iii) your address,
									telephone number and e-mail address; (iv) a
									statement by you that you have a good faith
									belief that the disputed use is not
									authorized by the copyright owner, its agent
									or the law; (v) a statement by you, made
									under penalty of perjury, that the
									information in your notice is accurate and
									that you are the copyright owner or
									authorized to act on the copyright owner’s
									behalf; and (vi) an electronic or physical
									signature of the owner of the copyright or
									the person authorized to act on behalf of
									the owner of the copyright interest.
								</p>
							</section>
							<section id="compliance-with-applicable-laws">
								<h3>Compliance With Applicable Laws</h3>
								<p>
									The Service is based in the United States.
									We make no claims concerning whether the
									Service content may be downloaded, viewed,
									or be appropriate for use outside of the
									United States. If you access the Service or
									the content from outside of the United
									States, you do so at your own risk. Whether
									inside or outside of the United States, you
									are solely responsible for ensuring
									compliance with the laws of your specific
									jurisdiction. You may not use our site for
									any unlawful purpose, or for any other
									purpose that is prohibited by these Terms of
									Use. Also, you may not interfere or attempt
									to interfere with the proper operation of
									our Service, including through the use of
									any device, software or routine, or access
									or attempt to gain access to any data, files
									or passwords related to our Service through
									hacking, password mining or any other means.
								</p>
							</section>
							<section id="termination-of-the-agreement">
								<h3>Termination of the Agreement</h3>
								<p>
									We reserve the right, in our sole
									discretion, to restrict, suspend, or
									terminate this Agreement and your access to
									all or any part of the Service, at any time
									and for any reason without prior notice or
									liability. We reserve the right to change,
									suspend, or discontinue all or any part of
									the Service at any time without prior notice
									or liability. You may terminate your
									HomeKeepr account at any time by contacting
									privacy@HomeKeepr.com, if you are a
									Registered User. If you are a Real Estate
									Professional or Service Provider you may
									terminate your account through the “Account
									Settings” tab on your account and in
									accordance with the Service Provider
									Agreement.
								</p>
							</section>
							<section id="privacy">
								<h3>Privacy</h3>
								<p>
									To ensure the best provision of our Service
									to customers, Real Estate Professionals and
									Service Providers, we may record any
									telephone calls or online chats or other
									communications that may take place through
									our service. You authorize us to make these
									recordings and to store and distribute them
									as required.
								</p>
							</section>
							<section id="ada-compliance">
								<h3>ADA COMPLIANCE</h3>
								<p>
									As an alternative to use and enjoy the
									website and the Services, you may also
									contact a staffed telephone information line
									T: ( ) , to gain access to the Service if a
									registered user or visitor.
								</p>
							</section>
							<section id="miscellaneous">
								<h3>Miscellaneous</h3>
								<p>
									This Agreement is governed by the internal
									substantive laws of the State of Georgia,
									without respect to its conflict of laws
									provisions. You expressly agree to submit to
									the exclusive personal jurisdiction of the
									state and federal courts sitting in the
									State of Georgia. If any provision of this
									Agreement is found to be invalid by any
									court having competent jurisdiction or
									terminated in accordance with the
									Termination provision above, the invalidity
									or termination of such provision shall not
									affect the validity of the following
									provisions of this Agreement, which shall
									remain in full force and effect:
									“Intellectual Property,” “Communications to
									HomeKeepr,” “No Warranties/Limitation of
									Liability,” “Indemnification,” “Termination
									of the Agreement,” and “Miscellaneous.”
								</p>
								<p>
									Our failure to act on or enforce any
									provision of the Agreement shall not be
									construed as a waiver of that provision or
									any other provision in this Agreement. No
									waiver shall be effective against us unless
									made in writing, and no such waiver shall be
									construed as a waiver in any other or
									subsequent instance. Except as expressly
									agreed by us and you in writing, this
									Agreement constitutes the entire Agreement
									between you and us with respect to the
									subject matter, and supersedes all previous
									or contemporaneous agreements, whether
									written or oral, between the parties with
									respect to the subject matter. The section
									headings are provided merely for convenience
									and shall not be given any legal import.
									This Agreement will inure to the benefit of
									our successors, assigns, licensees, and
									sublicensees. There shall be no amendment or
									departure from this Agreement absent a
									signed document by all applicable parties.
								</p>
							</section>
						</section>
						<header className="mb-8 terms-section">
							<h1 className="font-bold mb-4">PRIVACY NOTICE</h1>
							<p className="italic text-sm">
								Last updated January 17, 2022
							</p>
						</header>
						<section className="mb-8 terms-section">
							<p>
								Thank you for choosing to be part of our
								community at MooveGuru, LLC (
								<strong>
									&quot;Company,&quot; &quot;we,&quot;
									&quot;us,&quot; or &quot;our&quot;
								</strong>
								). We are committed to protecting your personal
								information and your right to privacy. If you
								have any questions or concerns about this
								privacy notice or our practices with regard to
								your personal information, please contact us at
								admin@homekeepr.com.
							</p>
							<p>
								This privacy notice describes how we might use
								your information if you:
							</p>
							<ul>
								<li className="navigation-item">
									Visit our website at{' '}
									<a
										className="navigation-link"
										href="http://www.homekeepr.com"
										target="_blank"
										rel="noreferrer"
									>
										http://www.homekeepr.com
									</a>
								</li>
								<li className="navigation-item">
									Download and use our mobile application —
									HomeKeepr
								</li>
								<li className="navigation-item">
									Engage with us in other related ways ―
									including any sales, marketing, or events
								</li>
							</ul>
							<p>In this privacy notice, if we refer to:</p>
							<ul>
								<li className="navigation-item">
									<strong>&quot;Website,&quot;</strong> we are
									referring to any website of ours that
									references or links to this policy
								</li>
								<li className="navigation-item">
									<strong>&quot;App,&quot;</strong> we are
									referring to any application of ours that
									references or links to this policy,
									including any listed above
								</li>
								<li className="navigation-item">
									<strong>&quot;Services,&quot;</strong> we
									are referring to our Website, App, and other
									related services, including any sales,
									marketing, or events
								</li>
							</ul>
							<p>
								The purpose of this privacy notice is to explain
								to you in the clearest way possible what
								information we collect, how we use it, and what
								rights you have in relation to it. If there are
								any terms in this privacy notice that you do not
								agree with, please discontinue use of our
								Services immediately.
							</p>
							<p className="font-bold mb-8">
								Please read this privacy notice carefully, as it
								will help you understand what we do with the
								information that we collect.
							</p>
						</section>
						<section
							className="mb-8 terms-section"
							id="collect-information"
						>
							<h3 className="font-bold mb-4">
								1. WHAT INFORMATION DO WE COLLECT?
							</h3>
							<p className="font-bold">
								Personal information you disclose to us
							</p>
							<p className="italic mb-4">
								<strong>In Short:</strong> We collect personal
								information that you provide to us.
							</p>
							<p>
								We collect personal information that you
								voluntarily provide to us when you register on
								the Services, express an interest in obtaining
								information about us or our products and
								Services, when you participate in activities on
								the Services (such as by posting messages in our
								online forums or entering competitions, contests
								or giveaways) or otherwise when you contact us.
							</p>
							<p>
								The personal information that we collect depends
								on the context of your interactions with us and
								the Services, the choices you make and the
								products and features you use. The personal
								information we collect may include the
								following:
							</p>
							<p>
								<strong>
									Personal Information Provided by You.
								</strong>{' '}
								We collect passwords; email addresses; names;
								phone numbers; and other similar information.
							</p>
							<p>
								<strong>Payment Data.</strong>We may collect
								data necessary to process your payment if you
								make purchases, such as your payment instrument
								number (such as a credit card number), and the
								security code associated with your payment
								instrument. All payment data is stored by
								Stripe. You may find their privacy notice
								link(s) here:{' '}
								<a
									className="navigation-link"
									href="https://stripe.com/privacy."
									target="_blank"
									rel="noreferrer"
								>
									https://stripe.com/privacy.
								</a>
							</p>
							<p>
								All personal information that you provide to us
								must be true, complete and accurate, and you
								must notify us of any changes to such personal
								information.
							</p>
							<h4>Information automatically collected</h4>
							<p className="italic mb-4">
								<strong>In Short:</strong> Some information —
								such as your Internet Protocol (IP) address
								and/or browser and device characteristics — is
								collected automatically when you visit our
								Services.
							</p>
							<p>
								We automatically collect certain information
								when you visit, use or navigate the Services.
								This information does not reveal your specific
								identity (like your name or contact information)
								but may include device and usage information,
								such as your IP address, browser and device
								characteristics, operating system, language
								preferences, referring URLs, device name,
								country, location, information about how and
								when you use our Services and other technical
								information. This information is primarily
								needed to maintain the security and operation of
								our Services, and for our internal analytics and
								reporting purposes.
							</p>
							<p>
								Like many businesses, we also collect
								information through cookies and similar
								technologies.
							</p>
							<p>The information we collect includes:</p>
							<ul>
								<li className="navigation-item">
									<em>Log and Usage Data.</em> Log and usage
									data is service-related, diagnostic, usage
									and performance information our servers
									automatically collect when you access or use
									our Services and which we record in log
									files. Depending on how you interact with
									us, this log data may include your IP
									address, device information, browser type
									and settings and information about your
									activity in the Services (such as the
									date/time stamps associated with your usage,
									pages and files viewed, searches and other
									actions you take such as which features you
									use), device event information (such as
									system activity, error reports (sometimes
									called &lsquo;crash dumps&lsquo;) and
									hardware settings).
								</li>
								<li className="navigation-item">
									<em>Device Data.</em> We collect device data
									such as information about your computer,
									phone, tablet or other device you use to
									access the Services. Depending on the device
									used, this device data may include
									information such as your IP address (or
									proxy server), device and application
									identification numbers, location, browser
									type, hardware model Internet service
									provider and/or mobile carrier, operating
									system and system configuration information.
								</li>
								<li className="navigation-item">
									<em>Location Data.</em> We collect location
									data such as information about your
									device&lsquo;s location, which can be either
									precise or imprecise. How much information
									we collect depends on the type and settings
									of the device you use to access the
									Services. For example, we may use GPS and
									other technologies to collect geolocation
									data that tells us your current location
									(based on your IP address). You can opt out
									of allowing us to collect this information
									either by refusing access to the information
									or by disabling your Location setting on
									your device. Note however, if you choose to
									opt out, you may not be able to use certain
									aspects of the Services.
								</li>
							</ul>
							<h4>Information collected through our App</h4>
							<p className="italic mb-4">
								<strong>In Short:</strong> We collect
								information regarding your push notifications,
								when you use our App.
							</p>
							<p>
								If you use our App, we also collect the
								following information:
							</p>
							<ul>
								<li className="navigation-item">
									<em>Push Notifications.</em> We may request
									to send you push notifications regarding
									your account or certain features of the App.
									If you wish to opt-out from receiving these
									types of communications, you may turn them
									off in your device&lsquo;s settings.
								</li>
							</ul>
							<p>
								This information is primarily needed to maintain
								the security and operation of our App, for
								troubleshooting and for our internal analytics
								and reporting purposes.
							</p>
							<h4>Information collected from other sources</h4>
							<p className="italic mb-4">
								<strong>In Short:</strong> We may collect
								limited data from public databases, marketing
								partners, and other outside sources.
							</p>
							<p>
								In order to enhance our ability to provide
								relevant marketing, offers and services to you
								and update our records, we may obtain
								information about you from other sources, such
								as public databases, joint marketing partners,
								affiliate programs, data providers, as well as
								from other third parties. This information
								includes mailing addresses, job titles, email
								addresses, phone numbers, intent data (or user
								behavior data), Internet Protocol (IP)
								addresses, social media profiles, social media
								URLs and custom profiles, for purposes of
								targeted advertising and event promotion.
							</p>
						</section>
						<section
							id="use-information"
							className="mb-8 terms-section"
						>
							<h3 className="font-bold mb-4">
								2. HOW DO WE USE YOUR INFORMATION?
							</h3>
							<p className="italic mb-4">
								<strong>In Short:</strong> We process your
								information for purposes based on legitimate
								business interests, the fulfillment of our
								contract with you, compliance with our legal
								obligations, and/or your consent.
							</p>
							<p>
								We use personal information collected via our
								Services for a variety of business purposes
								described below. We process your personal
								information for these purposes in reliance on
								our legitimate business interests, in order to
								enter into or perform a contract with you, with
								your consent, and/or for compliance with our
								legal obligations. We indicate the specific
								processing grounds we rely on next to each
								purpose listed below.
							</p>
							<p>We use the information we collect or receive:</p>
							<ul>
								<li className="navigation-item">
									<strong>
										To facilitate account creation and logon
										process.
									</strong>{' '}
									If you choose to link your account with us
									to a third-party account (such as your
									Google or Facebook account), we use the
									information you allowed us to collect from
									those third parties to facilitate account
									creation and logon process for the
									performance of the contract.
								</li>
								<li className="navigation-item">
									<strong>To post testimonials.</strong> We
									post testimonials on our Services that may
									contain personal information. Prior to
									posting a testimonial, we will obtain your
									consent to use your name and the content of
									the testimonial. If you wish to update, or
									delete your testimonial, please contact us
									at admin@homekeepr.com and be sure to
									include your name, testimonial location, and
									contact information.
								</li>
								<li className="navigation-item">
									<strong>Request feedback.</strong> We may
									use your information to request feedback and
									to contact you about your use of our
									Services.
								</li>
								<li className="navigation-item">
									<strong>
										To enable user-to-user communications.
									</strong>{' '}
									We may use your information in order to
									enable user-to-user communications with each
									user&lsquo;s consent.
								</li>
								<li className="navigation-item">
									<strong>To manage user accounts.</strong> We
									may use your information for the purposes of
									managing our account and keeping it in
									working order.
								</li>
								<li className="navigation-item">
									<strong>
										To send administrative information to
										you.
									</strong>{' '}
									We may use your personal information to send
									you product, service and new feature
									information and/or information about changes
									to our terms, conditions, and policies.
								</li>
								<li className="navigation-item">
									<strong>To protect our Services.</strong> We
									may use your information as part of our
									efforts to keep our Services safe and secure
									(for example, for fraud monitoring and
									prevention).
								</li>
								<li className="navigation-item">
									<strong>
										To enforce our terms, conditions and
										policies for business purposes, to
										comply with legal and regulatory
										requirements or in connection with our
										contract.
									</strong>
								</li>
								<li className="navigation-item">
									<strong>
										To respond to legal requests and prevent
										harm.
									</strong>{' '}
									If we receive a subpoena or other legal
									request, we may need to inspect the data we
									hold to determine how to respond.
								</li>
								<li className="navigation-item">
									<strong>
										Fulfill and manage your orders.
									</strong>{' '}
									We may use your information to fulfill and
									manage your orders, payments, returns, and
									exchanges made through the Services.
								</li>
								<li className="navigation-item">
									<strong>
										Administer prize draws and competitions.
									</strong>{' '}
									We may use your information to administer
									prize draws and competitions when you elect
									to participate in our competitions.
								</li>
								<li className="navigation-item">
									<strong>
										To deliver and facilitate delivery of
										services to the user.
									</strong>{' '}
									We may use your information to provide you
									with the requested service.
								</li>
								<li className="navigation-item">
									<strong>
										To respond to user inquiries/offer
										support to users.
									</strong>{' '}
									We may use your information to respond to
									your inquiries and solve any potential
									issues you might have with the use of our
									Services.
								</li>
							</ul>
						</section>
						<section
							id="shared-information"
							className="mb-8 terms-section"
						>
							<h3 className="font-bold mb-4">
								3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
							</h3>
							<p className="italic mb-4">
								<strong>In Short:</strong> We only share
								information with your consent, to comply with
								laws, to provide you with services, to protect
								your rights, or to fulfill business obligations.
							</p>
							<p>
								We may process or share your data that we hold
								based on the following legal basis:
							</p>
							<ul>
								<li className="navigation-item">
									<strong>Consent:</strong> We may process
									your data if you have given us specific
									consent to use your personal information for
									a specific purpose.
								</li>
								<li className="navigation-item">
									<strong>Legitimate Interests:</strong> We
									may process your data when it is reasonably
									necessary to achieve our legitimate business
									interests.
								</li>
								<li className="navigation-item">
									<strong>Performance of a Contract:</strong>{' '}
									Where we have entered into a contract with
									you, we may process your personal
									information to fulfill the terms of our
									contract.
								</li>
							</ul>
							<p>
								More specifically, we may need to process your
								data or share your personal information in the
								following situations:
							</p>
							<ul>
								<li className="navigation-item">
									<strong>Business Transfers.</strong> We may
									share or transfer your information in
									connection with, or during negotiations of,
									any merger, sale of company assets,
									financing, or acquisition of all or a
									portion of our business to another company.
								</li>
								<li className="navigation-item">
									<strong>
										Vendors, Consultants and Other
										Third-Party Service Providers.
									</strong>{' '}
									We may share your data with third-party
									vendors, service providers, contractors or
									agents who perform services for us or on our
									behalf and require access to such
									information to do that work. Examples
									include: payment processing, data analysis,
									email delivery, hosting services, customer
									service and marketing efforts. We may allow
									selected third parties to use tracking
									technology on the Services, which will
									enable them to collect data on our behalf
									about how you interact with our Services
									over time. This information may be used to,
									among other things, analyze and track data,
									determine the popularity of certain content,
									pages or features, and better understand
									online activity. Unless described in this
									notice, we do not share, sell, rent or trade
									any of your information with third parties
									for their promotional purposes.
								</li>
								<li className="navigation-item">
									<strong>Third-Party Advertisers.</strong> We
									may use third-party advertising companies to
									serve ads when you visit or use the
									Services. These companies may use
									information about your visits to our
									Website(s) and other websites that are
									contained in web cookies and other tracking
									technologies in order to provide
									advertisements about goods and services of
									interest to you.
								</li>
								<li className="navigation-item">
									<strong>Affiliates.</strong> We may share
									your information with our affiliates, in
									which case we will require those affiliates
									to honor this privacy notice. Affiliates
									include our parent company and any
									subsidiaries, joint venture partners or
									other companies that we control or that are
									under common control with us.
								</li>
								<li className="navigation-item">
									<strong>Business Partners.</strong> We may
									share your information with our business
									partners to offer you certain products,
									services or promotions.
								</li>
								<li className="navigation-item">
									<strong>Offer Wall.</strong> Our App may
									display a third-party hosted &quot;offer
									wall.&quot; Such an offer wall allows
									third-party advertisers to offer virtual
									currency, gifts, or other items to users in
									return for the acceptance and completion of
									an advertisement offer. Such an offer wall
									may appear in our App and be displayed to
									you based on certain data, such as your
									geographic area or demographic information.
									When you click on an offer wall, you will be
									brought to an external website belonging to
									other persons and will leave our App. A
									unique identifier, such as your user ID,
									will be shared with the offer wall provider
									in order to prevent fraud and properly
									credit your account with the relevant
									reward. Please note that we do not control
									third-party websites and have no
									responsibility in relation to the content of
									such websites. The inclusion of a link
									towards a third-party website does not imply
									an endorsement by us of such website.
									Accordingly, we do not make any warranty
									regarding such third-party websites and we
									will not be liable for any loss or damage
									caused by the use of such websites. In
									addition, when you access any third-party
									website, please understand that your rights
									while accessing and using those websites
									will be governed by the privacy notice and
									terms of service relating to the use of
									those websites.
								</li>
							</ul>
						</section>
						<section
							id="who-shared-information"
							className="mb-8 terms-section"
						>
							<h3 className="font-bold mb-4">
								4. WHO WILL YOUR INFORMATION BE SHARED WITH?
							</h3>
							<p className="italic mb-4">
								<strong>In Short:</strong> We only share
								information with the following third parties.
							</p>
							<p>
								We only share and disclose your information with
								the following third parties. If we have
								processed your data based on your consent and
								you wish to revoke your consent, please contact
								us using the contact details provided in the
								section below titled &quot;HOW CAN YOU CONTACT
								US ABOUT THIS NOTICE?&quot;.
							</p>
						</section>
						<section id="cookies" className="mb-8 terms-section">
							<h3 className="font-bold mb-4">
								5. DO WE USE COOKIES AND OTHER TRACKING
								TECHNOLOGIES?
							</h3>
							<p className="italic mb-4">
								<strong>In Short:</strong> We may use cookies
								and other tracking technologies to collect and
								store your information.
							</p>
							<p>
								We may use cookies and similar tracking
								technologies (like web beacons and pixels) to
								access or store information. Specific
								information about how we use such technologies
								and how you can refuse certain cookies is set
								out in our Cookie Notice.
							</p>
						</section>
						<section
							id="keep-information"
							className="mb-8 terms-section"
						>
							<h3 className="font-bold mb-4">
								6. HOW LONG DO WE KEEP YOUR INFORMATION?
							</h3>
							<p className="italic mb-4">
								<strong>In Short:</strong> We keep your
								information for as long as necessary to fulfill
								the purposes outlined in this privacy notice
								unless otherwise required by law.
							</p>
							<p>
								We will only keep your personal information for
								as long as it is necessary for the purposes set
								out in this privacy notice, unless a longer
								retention period is required or permitted by law
								(such as tax, accounting or other legal
								requirements). No purpose in this notice will
								require us keeping your personal information for
								longer than the period of time in which users
								have an account with us.
							</p>
							<p>
								When we have no ongoing legitimate business need
								to process your personal information, we will
								either delete or anonymize such information, or,
								if this is not possible (for example, because
								your personal information has been stored in
								backup archives), then we will securely store
								your personal information and isolate it from
								any further processing until deletion is
								possible.
							</p>
						</section>
						<section
							id="safe-information"
							className="mb-8 terms-section"
						>
							<h3 className="font-bold mb-4">
								7. HOW DO WE KEEP YOUR INFORMATION SAFE?
							</h3>
							<p className="italic mb-4">
								<strong>In Short:</strong> We aim to protect
								your personal information through a system of
								organizational and technical security measures.
							</p>
							<p>
								We have implemented appropriate technical and
								organizational security measures designed to
								protect the security of any personal information
								we process. However, despite our safeguards and
								efforts to secure your information, no
								electronic transmission over the Internet or
								information storage technology can be guaranteed
								to be 100% secure, so we cannot promise or
								guarantee that hackers, cybercriminals, or other
								unauthorized third parties will not be able to
								defeat our security, and improperly collect,
								access, steal, or modify your information.
								Although we will do our best to protect your
								personal information, transmission of personal
								information to and from our Services is at your
								own risk. You should only access the Services
								within a secure environment.
							</p>
						</section>
						<section
							id="minor-information"
							className="mb-8 terms-section"
						>
							<h3 className="font-bold mb-4">
								8. DO WE COLLECT INFORMATION FROM MINORS?
							</h3>
							<p className="italic mb-4">
								<strong>In Short:</strong> We do not knowingly
								collect data from or market to children under 18
								years of age.
							</p>
							<p>
								We do not knowingly solicit data from or market
								to children under 18 years of age. By using the
								Services, you represent that you are at least 18
								or that you are the parent or guardian of such a
								minor and consent to such minor dependent’s use
								of the Services. If we learn that personal
								information from users less than 18 years of age
								has been collected, we will deactivate the
								account and take reasonable measures to promptly
								delete such data from our records. If you become
								aware of any data we may have collected from
								children under age 18, please contact us at
								admin@homekeepr.com.
							</p>
						</section>
						<section
							id="privacy-rights"
							className="mb-8 terms-section"
						>
							<h3 className="font-bold mb-4">
								9. WHAT ARE YOUR PRIVACY RIGHTS?
							</h3>
							<p className="italic mb-4">
								<strong>In Short:</strong> You may review,
								change, or terminate your account at any time.
							</p>
							<p>
								If you are a resident in the EEA or UK and you
								believe we are unlawfully processing your
								personal information, you also have the right to
								complain to your local data protection
								supervisory authority. You can find their
								contact details here:{' '}
								<a
									className="navigation-link"
									href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm."
									target="_blank"
									rel="noreferrer"
								>
									https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
								</a>
							</p>
							<p>
								If you are a resident in Switzerland, the
								contact details for the data protection
								authorities are available here:{' '}
								<a
									className="navigation-link"
									href="https://www.edoeb.admin.ch/edoeb/en/home.html."
									target="_blank"
									rel="noreferrer"
								>
									https://www.edoeb.admin.ch/edoeb/en/home.html.
								</a>
							</p>
							<p>
								If you have questions or comments about your
								privacy rights, you may email us at{' '}
								<a
									className="navigation-link"
									href="mailto:admin@homekeepr.com"
								>
									admin@homekeepr.com
								</a>
								.
							</p>
							<h4>Account Information</h4>
							<p>
								If you would at any time like to review or
								change the information in your account or
								terminate your account, you can:
							</p>
							<ul>
								<li className="navigation-item">
									Contact us using the contact information
									provided.
								</li>
							</ul>
							<p>
								Upon your request to terminate your account, we
								will deactivate or delete your account and
								information from our active databases. However,
								we may retain some information in our files to
								prevent fraud, troubleshoot problems, assist
								with any investigations, enforce our Terms of
								Use and/or comply with applicable legal
								requirements.
							</p>
							<p>
								<strong>
									Cookies and similar technologies:
								</strong>{' '}
								Most Web browsers are set to accept cookies by
								default. If you prefer, you can usually choose
								to set your browser to remove cookies and to
								reject cookies. If you choose to remove cookies
								or reject cookies, this could affect certain
								features or services of our Services. To opt-out
								of interest-based advertising by advertisers on
								our Services visit{' '}
								<a
									className="navigation-link"
									href="http://www.aboutads.info/choices/"
									target="blank"
									rel="noreferrer"
								>
									http://www.aboutads.info/choices/
								</a>
								.
							</p>
							<p>
								<strong>Opting out of email marketing:</strong>{' '}
								You can unsubscribe from our marketing email
								list at any time by clicking on the unsubscribe
								link in the emails that we send or by contacting
								us using the details provided below. You will
								then be removed from the marketing email list —
								however, we may still communicate with you, for
								example to send you service-related emails that
								are necessary for the administration and use of
								your account, to respond to service requests, or
								for other non-marketing purposes. To otherwise
								opt-out, you may:
							</p>
							<ul>
								<li className="navigation-item">
									Contact us using the contact information
									provided.
								</li>
							</ul>
						</section>
						<section id="controls" className="mb-8 terms-section">
							<h3 className="font-bold mb-4">
								10. CONTROLS FOR DO-NOT-TRACK FEATURES
							</h3>
							<p>
								Most web browsers and some mobile operating
								systems and mobile applications include a
								Do-Not-Track (&quot;DNT&quot;) feature or
								setting you can activate to signal your privacy
								preference not to have data about your online
								browsing activities monitored and collected. At
								this stage no uniform technology standard for
								recognizing and implementing DNT signals has
								been finalized. As such, we do not currently
								respond to DNT browser signals or any other
								mechanism that automatically communicates your
								choice not to be tracked online. If a standard
								for online tracking is adopted that we must
								follow in the future, we will inform you about
								that practice in a revised version of this
								privacy notice.
							</p>
						</section>
						<section
							id="california-residents"
							className="mb-8 terms-section"
						>
							<h3 className="font-bold mb-4">
								11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC
								PRIVACY RIGHTS?
							</h3>
							<p className="italic mb-4">
								<strong>In Short:</strong> Yes, if you are a
								resident of California, you are granted specific
								rights regarding access to your personal
								information.
							</p>
							<p>
								California Civil Code Section 1798.83, also
								known as the &quot;Shine The Light&quot; law,
								permits our users who are California residents
								to request and obtain from us, once a year and
								free of charge, information about categories of
								personal information (if any) we disclosed to
								third parties for direct marketing purposes and
								the names and addresses of all third parties
								with which we shared personal information in the
								immediately preceding calendar year. If you are
								a California resident and would like to make
								such a request, please submit your request in
								writing to us using the contact information
								provided below.
							</p>
							<p>
								If you are under 18 years of age, reside in
								California, and have a registered account with a
								Service, you have the right to request removal
								of unwanted data that you publicly post on the
								Services. To request removal of such data,
								please contact us using the contact information
								provided below, and include the email address
								associated with your account and a statement
								that you reside in California. We will make sure
								the data is not publicly displayed on the
								Services, but please be aware that the data may
								not be completely or comprehensively removed
								from all our systems (e.g. backups, etc.).
							</p>
							<h4>CCPA Privacy Notice</h4>
							<p>
								The California Code of Regulations defines a
								&quot;resident&quot; as:
							</p>
							<ol>
								<li className="navigation-item">
									(1) every individual who is in the State of
									California for other than a temporary or
									transitory purpose and
								</li>
								<li className="navigation-item">
									(2) every individual who is domiciled in the
									State of California who is outside the State
									of California for a temporary or transitory
									purpose
								</li>
							</ol>
							<p>
								All other individuals are defined as
								&quot;non-residents.&quot;
							</p>
							<p>
								If this definition of &quot;resident&quot;
								applies to you, we must adhere to certain rights
								and obligations regarding your personal
								information.
							</p>
							<h4>
								What categories of personal information do we
								collect?
							</h4>
							<p>
								We have collected the following categories of
								personal information in the past twelve (12)
								months:
							</p>
							<table className="terms-table">
								<thead>
									<tr className="table-row">
										<th className="table-header font-bold">
											Category
										</th>
										<th className="table-header font-bold">
											Examples
										</th>
										<th className="table-header font-bold">
											Collected
										</th>
									</tr>
								</thead>
								<tbody>
									<tr className="table-row">
										<td className="table-data">
											A. Identifiers
										</td>
										<td className="table-data">
											Contact details, such as real name,
											alias, postal address, telephone or
											mobile contact number, unique
											personal identifier, online
											identifier, Internet Protocol
											address, email address and account
											name
										</td>
										<td className="table-data">YES</td>
									</tr>
									<tr className="table-row">
										<td className="table-data">
											B. Personal information categories
											listed in the California Customer
											Records statute
										</td>
										<td className="table-data">
											Name, contact information,
											education, employment, employment
											history and financial information
										</td>
										<td className="table-data">YES</td>
									</tr>
									<tr className="table-row">
										<td className="table-data">
											C. Protected classification
											characteristics under California or
											federal law
										</td>
										<td className="table-data">
											Gender and date of birth
										</td>
										<td className="table-data">NO</td>
									</tr>
									<tr className="table-row">
										<td className="table-data">
											D. Commercial information
										</td>
										<td className="table-data">
											Transaction information, purchase
											history, financial details and
											payment information
										</td>
										<td className="table-data">NO</td>
									</tr>
									<tr className="table-row">
										<td className="table-data">
											E. Biometric information
										</td>
										<td className="table-data">
											Fingerprints and voiceprints
										</td>
										<td className="table-data">NO</td>
									</tr>
									<tr className="table-row">
										<td className="table-data">
											F. Internet or other similar network
											activity
										</td>
										<td className="table-data">
											Browsing history, search history,
											online behavior, interest data, and
											interactions with our and other
											websites, applications, systems and
											advertisements
										</td>
										<td className="table-data">YES</td>
									</tr>
									<tr className="table-row">
										<td className="table-data">
											G. Geolocation data
										</td>
										<td className="table-data">
											Device location
										</td>
										<td className="table-data">YES</td>
									</tr>
									<tr className="table-row">
										<td className="table-data">
											H. Audio, electronic, visual,
											thermal, olfactory, or similar
											information
										</td>
										<td className="table-data">
											Images and audio, video or call
											recordings created in connection
											with our business activities
										</td>
										<td className="table-data">NO</td>
									</tr>
									<tr className="table-row">
										<td className="table-data">
											I. Professional or
											employment-related information
										</td>
										<td className="table-data">
											Business contact details in order to
											provide you our services at a
											business level, job title as well as
											work history and professional
											qualifications if you apply for a
											job with us
										</td>
										<td className="table-data">NO</td>
									</tr>
									<tr className="table-row">
										<td className="table-data">
											J. Education Information
										</td>
										<td className="table-data">
											Student records and directory
											information
										</td>
										<td className="table-data">NO</td>
									</tr>
									<tr className="table-row">
										<td className="table-data">
											K. Inferences drawn from other
											personal information
										</td>
										<td className="table-data">
											Inferences drawn from any of the
											collected personal information
											listed above to create a profile or
											summary about, for example, an
											individual’s preferences and
											characteristics
										</td>
										<td className="table-data">YES</td>
									</tr>
								</tbody>
							</table>
							<p>
								We may also collect other personal information
								outside of these categories instances where you
								interact with us in-person, online, or by phone
								or mail in the context of:
							</p>
							<ul>
								<li className="navigation-item">
									Receiving help through our customer support
									channels;
								</li>
								<li className="navigation-item">
									Participation in customer surveys or
									contests; and
								</li>
								<li className="navigation-item">
									Facilitation in the delivery of our Services
									and to respond to your inquiries.
								</li>
							</ul>
							<h4>
								How do we use and share your personal
								information?
							</h4>
							<p>
								MooveGuru, LLC collects and shares your personal
								information through:
							</p>
							<ul>
								<li className="navigation-item">
									Targeting cookies/Marketing cookies
								</li>
							</ul>
							<p>
								More information about our data collection and
								sharing practices can be found in this privacy
								notice.
							</p>
							<p>
								You can opt out from the selling of your
								personal information by disabling cookies in
								Cookie Preference Settings and clicking on the
								Do Not Sell My Personal Information link on our
								homepage.
							</p>
							<p>
								You may contact us by email at
								admin@homekeepr.com, or by referring to the
								contact details at the bottom of this document.
							</p>
							<p>
								If you are using an authorized agent to exercise
								your right to opt-out we may deny a request if
								the authorized agent does not submit proof that
								they have been validly authorized to act on your
								behalf.
							</p>
							<h4>
								Will your information be shared with anyone
								else?
							</h4>
							<p>
								We may disclose your personal information with
								our service providers pursuant to a written
								contract between us and each service provider.
								Each service provider is a for-profit entity
								that processes the information on our behalf.
							</p>
							<p>
								We may use your personal information for our own
								business purposes, such as for undertaking
								internal research for technological development
								and demonstration. This is not considered to be
								&quot;selling&quot; of your personal data.
							</p>
							<p>
								MooveGuru, LLC has disclosed the following
								categories of personal information to third
								parties for a business or commercial purpose in
								the preceding twelve (12) months:
							</p>
							<ul>
								<li className="navigation-item">
									Category B. Personal information, as defined
									in the California Customer Records law, such
									as your name, contact information,
									education, employment, employment history
									and financial information.
								</li>
								<li className="navigation-item">
									Category K. Inferences drawn from any of the
									personal information listed above to create
									a profile or summary about, for example, an
									individual&lsquo;s preferences and
									characteristics.
								</li>
							</ul>
							<p>
								The categories of third parties to whom we
								disclosed personal information for a business or
								commercial purpose can be found under &quot;
								<a
									className="navigation-link"
									href="#who-shared-information"
								>
									WHO WILL YOUR INFORMATION BE SHARED WITH?
								</a>
								&quot;.
							</p>
							<p>
								MooveGuru, LLC has sold the following categories
								of personal information to third parties in the
								preceding twelve (12) months:
							</p>
							<ul>
								<li className="navigation-item">
									Category B. Personal information, as defined
									in the California Customer Records law, such
									as your name, contact information,
									education, employment, employment history
									and financial information.
								</li>
							</ul>
							<p>
								The categories of third parties to whom we sold
								personal information are:
							</p>
							<p className="font-bold mb-4">
								Your rights with respect to your personal data
							</p>
							<p className="mb-4 underline">
								Right to request deletion of the data - Request
								to delete
							</p>
							<p>
								You can ask for the deletion of your personal
								information. If you ask us to delete your
								personal information, we will respect your
								request and delete your personal information,
								subject to certain exceptions provided by law,
								such as (but not limited to) the exercise by
								another consumer of his or her right to free
								speech, our compliance requirements resulting
								from a legal obligation or any processing that
								may be required to protect against illegal
								activities.
							</p>
							<p className="mb-4 underline">
								Right to be informed - Request to know
							</p>
							<p>
								Depending on the circumstances, you have a right
								to know:
							</p>
							<ul>
								<li className="navigation-item">
									whether we collect and use your personal
									information;
								</li>
								<li className="navigation-item">
									the categories of personal information that
									we collect;
								</li>
								<li className="navigation-item">
									the purposes for which the collected
									personal information is used;
								</li>
								<li className="navigation-item">
									whether we sell your personal information to
									third parties;
								</li>
								<li className="navigation-item">
									the categories of personal information that
									we sold or disclosed for a business purpose;
								</li>
								<li className="navigation-item">
									the categories of third parties to whom the
									personal information was sold or disclosed
									for a business purpose; and
								</li>
								<li className="navigation-item">
									the business or commercial purpose for
									collecting or selling personal information.
								</li>
							</ul>
							<p>
								In accordance with applicable law, we are not
								obligated to provide or delete consumer
								information that is de-identified in response to
								a consumer request or to re-identify individual
								data to verify a consumer request.
							</p>
							<p className="mb-4 underline">
								Right to Non-Discrimination for the Exercise of
								a Consumer’s Privacy Rights
							</p>
							<p>
								We will not discriminate against you if you
								exercise your privacy rights.
							</p>
							<p className="mb-4 underline">
								Verification process
							</p>
							<p>
								Upon receiving your request, we will need to
								verify your identity to determine you are the
								same person about whom we have the information
								in our system. These verification efforts
								require us to ask you to provide information so
								that we can match it with information you have
								previously provided us. For instance, depending
								on the type of request you submit, we may ask
								you to provide certain information so that we
								can match the information you provide with the
								information we already have on file, or we may
								contact you through a communication method (e.g.
								phone or email) that you have previously
								provided to us. We may also use other
								verification methods as the circumstances
								dictate.
							</p>
							<p>
								We will only use personal information provided
								in your request to verify your identity or
								authority to make the request. To the extent
								possible, we will avoid requesting additional
								information from you for the purposes of
								verification. If, however, we cannot verify your
								identity from the information already maintained
								by us, we may request that you provide
								additional information for the purposes of
								verifying your identity, and for security or
								fraud-prevention purposes. We will delete such
								additionally provided information as soon as we
								finish verifying you.
							</p>
							<p className="mb-4 underline">
								Other privacy rights
							</p>
							<ul>
								<li className="navigation-item">
									you may object to the processing of your
									personal data.
								</li>
								<li className="navigation-item">
									you may request correction of your personal
									data if it is incorrect or no longer
									relevant, or ask to restrict the processing
									of the data.
								</li>
								<li className="navigation-item">
									you can designate an authorized agent to
									make a request under the CCPA on your
									behalf. We may deny a request from an
									authorized agent that does not submit proof
									that they have been validly authorized to
									act on your behalf in accordance with the
									CCPA.
								</li>
							</ul>
							<p>
								To exercise these rights, you can contact us by
								email at admin@homekeepr.com, or by referring to
								the contact details at the bottom of this
								document. If you have a complaint about how we
								handle your data, we would like to hear from
								you.{' '}
							</p>
						</section>
						<section
							id="notice-updates"
							className="mb-8 terms-section"
						>
							<h3 className="font-bold mb-4">
								12. DO WE MAKE UPDATES TO THIS NOTICE?
							</h3>
							<p className="italic mb-4">
								<strong>In Short:</strong> Yes, we will update
								this notice as necessary to stay compliant with
								relevant laws.
							</p>
							<p>
								We may update this privacy notice from time to
								time. The updated version will be indicated by
								an updated &quot;Revised&quot; date and the
								updated version will be effective as soon as it
								is accessible. If we make material changes to
								this privacy notice, we may notify you either by
								prominently posting a notice of such changes or
								by directly sending you a notification. We
								encourage you to review this privacy notice
								frequently to be informed of how we are
								protecting your information.
							</p>
						</section>
						<section
							id="contact-notice"
							className="mb-8 terms-section"
						>
							<h3 className="font-bold mb-4">
								13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
							</h3>
							<p>
								If you have questions or comments about this
								notice, you may email us at{' '}
								<a
									className="navigation-link"
									href="mailto:admin@homekeepr.com"
								>
									admin@homekeepr.com
								</a>{' '}
								or by post to:
							</p>
							<p>MooveGuru, LLC</p>
							<p>885 Woodstock Rd 30075</p>
							<p>Suite 430-206</p>
							<p>Roswell, GA 30075</p>
							<p>United States</p>
						</section>
						<section
							id="review-update-delete"
							className="mb-8 terms-section"
						>
							<h3 className="font-bold mb-4">
								14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE
								DATA WE COLLECT FROM YOU?
							</h3>
							<p>
								Based on the applicable laws of your country,
								you may have the right to request access to the
								personal information we collect from you, change
								that information, or delete it in some
								circumstances. To request to review, update, or
								delete your personal information, please visit:{' '}
								<a className="navigation-link" href="/contact">
									https://homekeepr.com/contact.
								</a>
							</p>
						</section>
					</article>
				</main>
			</PublicLayout>
		);
	}
}
