/**
 * @typedef {import('./InputPasswordComponent').Props} Props
 * @typedef {import('./InputPasswordComponent').State} State
 */
import BaseInput from './BaseInput';
import React from 'react';
import { translate } from 'App/Internationalization';

/** @extends {React.Component<Props, State>} */
export default class InputPassword extends React.Component {
	render() {
		return (
			<BaseInput
				autocomplete={this.props.autocomplete}
				className={this.props.className}
				disabled={this.props.disabled}
				name={this.props.name}
				placeholder={this.props.placeholder}
				required={this.props.required}
				type={this.props.type}
			/>
		);
	}
}

InputPassword.defaultProps = {
	autocomplete: 'new-password',
	className: '',
	disabled: false,
	name: 'password',
	placeholder: translate('global.forms.inputs.password.placeholder'),
	required: true,
	type: 'password',
};
