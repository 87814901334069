/**
 * @param {string} html
 * @param {string} key
 * @returns {string}
 */
export default function extractHtmlAttributeValue(html, key) {
	const segments = html.split(/\s(?=\w+=')|\s(?=\/>$)/);
	const attributeList = segments.slice(1, segments.length - 1);
	const keyAssignment = `${key}=`;
	/**
	 * @param {string} attribute
	 */
	const attribute = attributeList.find((attribute) =>
		attribute.startsWith(keyAssignment)
	);

	if (attribute === undefined) {
		throw new Error(`Could not find attribute "${key}" in ${html}.`);
	}

	const quotedValue = attribute.replace(keyAssignment, '');
	return quotedValue.replace(/^('|")|('|")$/g, '');
}
