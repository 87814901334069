/**
 * @typedef {import('../../../../shared/ApplianceService/Appliance').default} Appliance
 * @typedef {import('../ViewAppliances/Props').default} Props
 * @typedef {import('./State').default} State
 * @typedef {import('formik').FormikValues} Formik
 */

import { Redirect } from 'react-router-dom';
import { translate } from '../../../Internationalization';
import { withApplianceService } from '../../../../service-container';
import ApplianceForm from '../ApplianceForm';
import captureError from '../../../../utils/captureError';
import paths from '../../../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class AddAppliance extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			errorMessage: '',
			formComplete: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	/**
	 * @param {React.FocusEvent<HTMLInputElement>} event
	 * @param {Formik} formik
	 * @returns {void}
	 */
	static handleBlur(event, formik) {
		const value = parseFloat(event.target.value).toFixed(2);
		formik.setFieldValue('purchasePrice', value);
	}

	/**
	 * @param {Appliance} values
	 * @returns {Promise<void>}
	 */
	async handleSubmit(values) {
		try {
			const appliance = {
				...values,
				purchasePrice: values.purchasePrice * 100,
			};

			const propertyId = this.props.property.selectedProperty?.id;

			await this.props.applianceService.createAppliance(
				propertyId,
				appliance
			);

			this.setState({
				errorMessage: null,
				formComplete: true,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate(
					'homeowner.pages.appliance_center.add.error'
				),
			});
		}
	}

	/** @returns {JSX.Element} */
	render() {
		if (this.state.formComplete) {
			return <Redirect to={paths.app.homeowner.applianceCenter.root} />;
		}

		return (
			<ApplianceForm
				errorMessages={this.state.errorMessage}
				formTitle={translate(
					'homeowner.pages.appliance_center.add.title'
				)}
				onBlur={AddAppliance.handleBlur}
				onSubmit={this.handleSubmit}
			/>
		);
	}
}

// @ts-ignore until service container types are fixed
export default withApplianceService(AddAppliance);
