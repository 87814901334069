/** @typedef {import('./LoadsAllConnectWidget').default} LoadsAllConnectWidget */

/** @implements {LoadsAllConnectWidget} */
export default class AllConnectService {
	constructor() {
		this.isInitialized = false;
	}

	/** @returns {Promise<void>} */
	loadWidget() {
		return new Promise((resolve, reject) => {
			if (!this.isInitialized) {
				this.initialize(resolve, reject);
			} else {
				resolve();
			}
		});
	}

	/**
	 * @param {() => void} resolve
	 * @param {(reason: any) => void} reject
	 * @private
	 */
	initialize(resolve, reject) {
		const script = this.createScript(resolve, reject);
		document.body.append(script);
		this.isInitialized = true;
	}

	/**
	 * @param {() => void} resolve
	 * @param {(reason: any) => void} reject
	 * @private
	 */
	createScript(resolve, reject) {
		// @ts-ignore: Script needs a target to resolve initialization
		window.yhhWebAppResolveAllConnect = resolve;
		const script = document.createElement('script');
		script.onerror = () => this.handleError(reject);
		script.async = true;
		script.id = 'myFinance-widget-script';
		script.type = 'text/javascript';

		script.text = `
		(function () {
			const s = 'myFinance-widget-ad-script';
			const c = 'myFinance-widget-css';
			const t = 'https://static.myfinance.com/';

			if (!document.getElementById(s)) {
				var e = document.createElement('script'),
					n = document.getElementById('myFinance-widget-script'),
					a = t + 'widget/myFinance.js';
					(e.id = s),
					(e.type = 'text/javascript'),
					(e.async = !0),
					(e.src = a),
					n.parentNode.insertBefore(e, n);
			}

			if (!document.getElementById(c)) {
				var d = document.getElementsByTagName('head')[0],
					i = document.createElement('link');
				(i.id = c),
					(i.rel = 'stylesheet'),
					(i.type = 'text/css'),
					(i.href = t + 'widget/myFinance.css'),
					(i.media = 'all'),
					d.appendChild(i);
			}

			window.yhhWebAppResolveAllConnect();
		})()`;

		return script;
	}

	/**
	 * @param {(reason: any) => void} reject
	 * @private
	 */
	handleError(reject) {
		reject(new Error('AllConnect failed to load.'));
	}
}
