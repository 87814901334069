/**
 * @typedef {import('./FieldPasswordComponent').Props} Props
 * @typedef {import('./FieldPasswordComponent').State} State
 */
import { BaseSVG } from '../../BaseSVG';
import { translate } from '../../../App/Internationalization';
import FormField from './FormField';
import images from '../../../config/local/images';
import InputPassword from '../Inputs/InputPassword';
import React from 'react';

/** @type {{ [ Key in 'hide' | 'show'] : State['title'] }} */
const titleDictionary = {
	hide: 'Hide password',
	show: 'Show password',
};

/** @extends {React.Component<Props, State>} */
export default class FieldPassword extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			icon: images.icons.password.show,
			title: titleDictionary.show,
			type: 'password',
		};

		this.handleClick = this.handleClick.bind(this);
	}

	/**
	 * @param {State['type']} type
	 * @returns {State['type']}
	 */
	static #toggleType(type) {
		return type === 'password' ? 'text' : 'password';
	}

	/**
	 * @param {State['type']} type
	 * @returns {State['icon']}
	 */
	static #toggleIcon(type) {
		return type === 'text'
			? images.icons.password.hide
			: images.icons.password.show;
	}

	/**
	 * @param {State['title']} current
	 * @returns {State['title']}
	 */
	static #toggleTitle(current) {
		return current === titleDictionary.hide
			? titleDictionary.show
			: titleDictionary.hide;
	}

	/**
	 * @returns {void}
	 */
	handleClick() {
		this.setState(
			/**
			 * @param {State} prevState
			 * @returns {State}
			 */
			(prevState) => {
				const type = FieldPassword.#toggleType(prevState.type);

				return {
					icon: FieldPassword.#toggleIcon(type),
					title: FieldPassword.#toggleTitle(prevState.title),
					type,
				};
			}
		);
	}

	/** @returns {JSX.Element} */
	render() {
		return (
			<FormField
				className={this.props.className}
				help={this.props.help}
				inputName={this.props.name}
				label={this.props.label}
				labelClass={this.props.labelClass}
				name={this.props.name}
				required={this.props.required}
			>
				<section className="input-stylized">
					<InputPassword
						autocomplete={this.props.autocomplete}
						className={this.props.inputClass}
						disabled={this.props.disabled}
						name={this.props.name}
						placeholder={this.props.placeholder}
						required={this.props.required}
						type={this.state.type}
					/>
					<button
						className="input-icon"
						onClick={this.handleClick}
						title={this.state.title}
						type="button"
					>
						<BaseSVG path={this.state.icon} />
					</button>
				</section>
			</FormField>
		);
	}
}

FieldPassword.defaultProps = {
	autocomplete: InputPassword.defaultProps.autocomplete,
	className: InputPassword.defaultProps.className,
	disabled: InputPassword.defaultProps.disabled,
	inputClass: InputPassword.defaultProps.className,
	label: translate('global.forms.inputs.password.label'),
	labelClass: '',
	name: InputPassword.defaultProps.name,
	placeholder: InputPassword.defaultProps.placeholder,
	required: InputPassword.defaultProps.required,
};
