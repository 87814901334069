import React from 'react';

export default class TitleCard extends React.Component {
	render() {
		return (
			<header className={this.props.className}>
				<h3 className="bg-info text-info-contrasted border-b-15 border-gray-800 rounded-t-lg text-xl px-8 py-4">
					{this.props.title}
				</h3>
				{this.props.children}
			</header>
		);
	}
}
