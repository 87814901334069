import { translate } from 'App/Internationalization';
import FormField from 'shared/Forms/Fields/FormField';
import InputCountry from 'shared/Forms/Inputs/Address/InputCountry';
import React from 'react';

export default class FieldCountry extends React.Component {
	render() {
		const label = translate('global.forms.inputs.address.country.label');

		return (
			<FormField
				className={this.props.className}
				inputName={this.props.name}
				labelClass={this.props.labelClass}
				label={this.props.label ?? label}
				name={this.props.name}
				required={this.props.required}
			>
				<InputCountry
					className={this.props.inputClass}
					disabled={this.props.disabled}
					name={this.props.name}
					required={this.props.required}
				/>
			</FormField>
		);
	}
}

FieldCountry.defaultProps = {
	className: InputCountry.defaultProps.className,
	disabled: InputCountry.defaultProps.disabled,
	inputClass: InputCountry.defaultProps.className,
	labelClass: FormField.defaultProps.labelClass,
	name: InputCountry.defaultProps.name,
	required: InputCountry.defaultProps.required,
};
