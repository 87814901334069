/**
 *  @typedef {import('../../../shared/AgentService/Agent').default} Agent
 *  @typedef {import('./Agent').Props} Props
 *  @typedef {import('./Agent').State} State
 *  @typedef {import('./Agent').Value} Value
 *  @typedef {import('./MappedAgent').default} MappedAgent
 */

import { array } from '@mooveguru/js-utilities';
import { AuthContext } from '../../../../shared/AuthProvider';
import { BaseSVG } from '../../../../shared/BaseSVG';
import { BulkOptions } from '../../../../App/shared/BulkOptions/BulkOptions';
import { translate } from '../../../Internationalization';
import { withSettings } from '../../../../shared/SettingProvider/SettingProvider';
import AgentBulkInvitePopup from '../../../../shared/Popups/Agents/Invite/AgentBulkInvitePopup';
import BaseTable from '../../../../shared/BaseTable';
import bulkOptions from '../../../../config/local/bulk-options';
import BulkSelectButton from '../../../../shared/BulkSelectButton/BulkSelectButton';
import captureError from '../../../../utils/captureError';
import concatPaths from '../../../../utils/contactPaths';
import convertDateToMonthDayYear from '../../../../utils/convertDateToMonthDayYear';
import Feature from '../../../../shared/Feature';
import FormErrorMessages from '../../../../shared/Forms/Messages/FormErrorMessages';
import FormGrayMessages from '../../../../shared/Forms/Messages/FormGrayMessages';
import images from '../../../../config/local/images';
import LinkButton from '../../../../shared/LinkButton';
import Loading from '../../../../shared/Loading';
import NoContent from '../../../../shared/NoContent';
import Pagination from '../../../../shared/Pagination';
import paths from '../../../../config/local/paths';
import React from 'react';
import SuccessOrWarningMessage from '../../../../shared/Forms/Messages/SucessOrWarningMessage';
import TableButton from '../../../../shared/TableButton';

/** @type {Set<string>} */
const groupIdList = new Set();

const headerKeyList = [
	'name',
	'email',
	'phone',
	'primary_group',
	'last_logged_in',
	'status',
	'invite',
	'edit',
	'edit_branding',
	'import_contacts',
];

const bulkOptionList = [
	{
		label: translate('global.pages.contacts.view.resend_invite'),
		option: bulkOptions.invite,
	},
];

const agentInviteFlag = 'admin:resend_agent_invite';

/** @extends {React.Component<Props, State>} */
class ViewAgent extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			agentsList: [],
			currentPageNumber: 1,
			disabledInvites: [],
			errorMessage: null,
			isGettingData: true,
			isSendingInvite: false,
			lastPageNumber: 1,
			mountError: false,
			selectedAgents: [],
			selectedOption: null,
			successMessages: [],
			toggleSelectAll: false,
			totalRecord: 0,
		};

		this._onHandleOptionChange = this._onHandleOptionChange.bind(this);
	}

	/** @returns {Promise<void>} */
	async componentDidMount() {
		try {
			await this._setGroupList();
			await this._getAgentData();

			this.#updateSuccessMessage(
				this.props.location?.state?.message ?? ''
			);
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
				mountError: true,
			});
		}
	}

	/**
	 * @param {Props} _prevProps
	 * @param {State} prevState
	 */
	async componentDidUpdate(_prevProps, prevState) {
		if (prevState.currentPageNumber !== this.state.currentPageNumber) {
			this.setState({ isGettingData: true });

			try {
				await this._getAgentData();
			} catch (error) {
				captureError(error);

				this.setState({ errorMessage: translate('global.error') });
			} finally {
				this.setState({ isGettingData: false });
			}
		}
	}

	/**
	 * @param {string} feature
	 * @returns {boolean}
	 */
	#getFeature(feature) {
		return this.props?.settings?.features.get(feature) ?? false;
	}

	/**
	 * @param {string} message
	 * @returns {void}
	 */
	#updateSuccessMessage(message) {
		if (!message) {
			return;
		}

		this.setState((prevState) => {
			if (prevState.successMessages.includes(message)) {
				return null;
			}

			return {
				successMessages: [...prevState.successMessages, message],
			};
		});
	}

	/**
	 * @param {Agent} agent
	 * @returns {Promise<void>}
	 */
	async sendAgentInvite(agent) {
		this.setState((prevState) => ({
			disabledInvites: [...prevState.disabledInvites, agent.id],
		}));

		const response = await this.sendAgentsInvite([agent.id]);

		if (!response) {
			return;
		}

		this.setState((prevState) => ({
			disabledInvites: prevState.disabledInvites.filter(
				(id) => id !== agent.id
			),
		}));

		this.#updateSuccessMessage(
			translate(
				'admin.pages.agents.invite.success',
				agent?.name?.first ?? '',
				agent?.name?.last ?? ''
			)
		);
	}

	/** @returns {Promise<void>} */
	async handleBulkOption() {
		this.setState({ selectedOption: null });

		const response = await this.sendAgentsInvite(this.state.selectedAgents);

		if (!response) {
			return;
		}

		this.setState({ selectedAgents: [], toggleSelectAll: false });

		this.#updateSuccessMessage(
			translate('admin.pages.agents.invite.bulk.success')
		);
	}

	/**
	 * @param {string[]} agentIds
	 * @returns {Promise<boolean>}
	 */
	async sendAgentsInvite(agentIds) {
		this.setState({ isSendingInvite: true });

		try {
			await this.props.administratorService.resendAgentsInvite(agentIds);

			return true;
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('admin.pages.agents.invite.error'),
			});
		} finally {
			this.setState({ isSendingInvite: false });
		}

		return false;
	}

	/**
	 * @param {string} className
	 * @returns {JSX.Element | null}
	 */
	#bulkOptionSelect(className = '') {
		if (!this.#getFeature(agentInviteFlag)) {
			return null;
		}

		return (
			<BulkOptions
				bulkOptionList={bulkOptionList}
				className={className}
				handleOptionChange={this._onHandleOptionChange}
			/>
		);
	}

	/**
	 * @protected
	 * @param {{label: string; option: string}} selection
	 * @returns {void}
	 */
	_onHandleOptionChange(selection) {
		if (
			selection.option !== 'bulk_invite' &&
			selection.option !== 'bulk_delete'
		) {
			return;
		}

		this.setState({ selectedOption: selection.option });
	}

	/**
	 * @protected
	 * @param {boolean} selectAll
	 * @returns {void}
	 */
	_handleSelectAll(selectAll = true) {
		if (!selectAll) {
			this.setState({ selectedAgents: [], toggleSelectAll: false });
			return;
		}

		const newAgents = this.state.agentsList.filter(
			(agent) => !agent.lastLoggedInAt
		);

		this.setState({
			selectedAgents: newAgents.map((agent) => agent.id),
			toggleSelectAll: true,
		});
	}

	/** @returns {JSX.Element | null} */
	#renderInvitePopup() {
		if (this.state.selectedOption !== 'bulk_invite') {
			return null;
		}

		if (!this.state.selectedAgents.length) {
			this.setState({ selectedOption: null });
			return null;
		}

		return (
			<AgentBulkInvitePopup
				onClose={() => this.setState({ selectedOption: null })}
				onSubmit={() => this.handleBulkOption()}
				selectedAgents={this.state.selectedAgents}
			/>
		);
	}

	/**
	 * @param {Agent} agent
	 * @returns {void}
	 */
	_handleCheckBox(agent) {
		const agentsList = this.state.selectedAgents;
		const agentIndex = agentsList.indexOf(agent.id);

		let agents = [...agentsList];
		if (agentIndex === -1) {
			agents.push(agent.id);
		} else {
			agents = agents.filter((id) => id !== agent.id);
		}

		this.setState({ selectedAgents: agents });
	}

	/**
	 * @protected
	 * @returns {Promise<void>}
	 */
	async _getAgentData() {
		const agents = await this.props.agentService.getAgents(
			this.context.accessToken,
			this.state.currentPageNumber
		);

		this.setState({
			agentsList: agents.data,
			isGettingData: false,
			lastPageNumber: agents.pages,
			totalRecord: agents.total,
		});
	}

	/**
	 * @protected
	 * @returns {Promise<void>}
	 */
	async _setGroupList() {
		const administrator =
			await this.props.administratorService.getAdministratorPersonalData();

		administrator.groups.map(
			/** @param {{id: string}} group*/
			(group) => groupIdList.add(group.id)
		);
	}

	/** @returns {Value[][]} */
	_setTableData() {
		if (!this.state.agentsList.length) {
			return [];
		}

		const dataMap = this._setAgentData();

		// depending on agent, some values may be empty, or n/a (especially with test data)
		const bodyData = dataMap.map(
			/**
			 * @param {MappedAgent} agent
			 * @returns {{data: string | JSX.Element | null | undefined}[]}
			 */
			(agent) => Object.values(agent).map((data) => ({ data }))
		);

		if (this.#getFeature(agentInviteFlag)) {
			this.#addInvite(this.state.agentsList, bodyData);
		}

		ViewAgent.#addEdit(this.state.agentsList, bodyData);
		ViewAgent.#addEditTheme(this.state.agentsList, bodyData);
		ViewAgent.#addImportContacts(this.state.agentsList, bodyData);

		return bodyData;
	}

	/**
	 * @returns {MappedAgent[]}
	 */
	_setAgentData() {
		return this.state.agentsList.map(
			/**
			 * @param {Agent} agent
			 * @returns {MappedAgent}
			 */
			// eslint-disable-next-line complexity
			(agent) => ({
				/* eslint-disable sort-keys -- Properties in order of table headers */
				name: this.#getNameField(agent),
				email: agent?.email,
				phone: agent?.phone?.mobile || agent?.phone?.landline || null,
				primaryGroup: agent.groups?.find(
					(/** @type {{ isPrimary: boolean }} */ group) =>
						group.isPrimary
				)?.name,
				lastLoggedIn: agent?.lastLoggedInAt
					? convertDateToMonthDayYear(agent?.lastLoggedInAt)
					: null,
				status: agent?.active
					? translate('admin.pages.agents.view.table.active')
					: translate('admin.pages.agents.view.table.disabled'),
			})
		);
	}

	/**
	 * @param {Agent} agent
	 * @returns {JSX.Element}
	 */
	#getNameField(agent) {
		const inviteFeature = (
			<Feature fallback={null} name={agentInviteFlag}>
				<span className="form-check form-check-sm">
					<input
						checked={this.state.selectedAgents.includes(agent.id)}
						disabled={!!agent?.lastLoggedInAt}
						onChange={() => this._handleCheckBox(agent)}
						type="checkbox"
					/>
				</span>
			</Feature>
		);

		const subscribedFeature = (
			<Feature fallback={null} name="admin:show_premium_agent_badge">
				{!!agent?.isSubscribed && (
					<span className="badge badge-themed">
						<i className="icon">
							<BaseSVG path={images.icons.premium} />
						</i>
					</span>
				)}
			</Feature>
		);

		return (
			<div className="flex">
				{inviteFeature}
				{subscribedFeature}
				{`${agent?.name?.first} ${agent?.name?.last}`}
			</div>
		);
	}

	/**
	 * @param {Agent['groups']} groupList
	 * @returns {string | null}
	 */
	static #resolveRelatedId(groupList) {
		return groupList.find((group) => groupIdList.has(group.id))?.id ?? null;
	}

	/** @returns {null | JSX.Element} */
	#importButton() {
		if (!array.includesAny(this.context.roles, 'owner', 'admin')) {
			return null;
		}

		return (
			<LinkButton
				icon={images.icons.import}
				to={{
					pathname: paths.app.admin.agents.import,
					state: {
						referrer: `${location.pathname}${location.search}${location.hash}`,
					},
				}}
			>
				{translate('admin.pages.agents.view.import')}
			</LinkButton>
		);
	}

	/** @returns {{title: string | JSX.Element}[]} */
	#getTableHeader() {
		const bulkInvite = (
			<BulkSelectButton
				checked={this.state.toggleSelectAll}
				handleDeSelectAll={() => this._handleSelectAll(false)}
				handleSelectAll={() => this._handleSelectAll()}
			>
				{translate(`admin.pages.agents.view.table.name`)}
			</BulkSelectButton>
		);

		return headerKeyList
			.map((key) => {
				if (key === 'name') {
					return {
						title: this.#getFeature(agentInviteFlag)
							? bulkInvite
							: translate(`admin.pages.agents.view.table.name`),
					};
				}

				if (key === 'invite') {
					return {
						title: this.#getFeature(agentInviteFlag)
							? translate(`admin.pages.agents.view.table.invite`)
							: '',
					};
				}

				return {
					title: translate(`admin.pages.agents.view.table.${key}`),
				};
			})
			.filter((header) => header.title);
	}

	/** @returns {React.ReactElement} */
	render() {
		if (this.state.errorMessage && this.state.mountError) {
			return <NoContent />;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<React.Fragment>
				{this.#renderInvitePopup()}
				<header className="mt-10 mb-6 md:flex justify-between items-center">
					<h1 className="hl-ms-6 mb-6 md:mb-0">
						{`${translate('admin.pages.agents.view.title')} (${
							this.state.totalRecord
						})`}
					</h1>

					<section className="button-group">
						{this.#importButton()}

						<LinkButton
							icon={images.icons.add}
							to={paths.app.admin.agents.create}
						>
							{translate('admin.pages.agents.view.add')}
						</LinkButton>
					</section>
				</header>

				<SuccessOrWarningMessage
					heading={this.props.location?.state?.heading}
					messages={this.state.successMessages}
					warning={this.props.location?.state?.warning}
				/>

				{this.state.isSendingInvite ? (
					<FormGrayMessages
						heading={translate(
							'admin.pages.agents.invite.info.header'
						)}
						messages={translate(
							'admin.pages.agents.invite.info.message'
						)}
					/>
				) : null}

				{this.state.errorMessage ? (
					<FormErrorMessages messages={this.state.errorMessage} />
				) : null}

				{this.#bulkOptionSelect('mb-4')}

				<BaseTable
					bodyData={this._setTableData()}
					errorMessage={translate('admin.pages.agents.view.error')}
					headerData={this.#getTableHeader()}
				/>

				<Pagination
					currentPageNumber={this.state.currentPageNumber}
					lastPageNumber={this.state.lastPageNumber}
					setCurrentPageNumber={(currentPageNumber) =>
						this.setState({ currentPageNumber })
					}
				/>
			</React.Fragment>
		);
	}

	/**
	 * @param {Agent[]} data
	 * @param {Value[][]} bodyData
	 * @returns {void}
	 */
	#addInvite(data, bodyData) {
		data?.forEach((agent, index) => {
			// @ts-ignore -- can be pushed
			bodyData?.[index]?.push({
				data: (
					<TableButton
						disabled={
							this.state.disabledInvites.includes(agent.id) ||
							!!agent?.lastLoggedInAt
						}
						handleClick={() => this.sendAgentInvite(agent)}
						icon={images.icons.email}
					/>
				),
			});
		});
	}

	/**
	 * @param {Agent[]} data
	 * @param {Value[][]} bodyData
	 * @returns {void}
	 */
	static #addEdit(data, bodyData) {
		data?.forEach((agent, index) => {
			// @ts-ignore -- can be pushed
			bodyData?.[index]?.push({
				data: (
					<TableButton
						icon={images.icons.edit}
						to={`${paths.app.admin.agents.edit}/${agent.slug}`}
					/>
				),
			});
		});
	}

	/**
	 * @param {Agent[]} data
	 * @param {Value[][]} bodyData
	 * @returns {void}
	 */
	static #addEditTheme(data, bodyData) {
		data?.forEach((agent, index) => {
			// @ts-ignore -- can be pushed
			bodyData[index]?.push({
				data: (
					<TableButton
						icon={images.icons.theme}
						title={translate(
							'admin.pages.agents.view.table.edit_branding'
						)}
						to={concatPaths(
							paths.app.admin.agents.root,
							agent.id,
							'theme'
						)}
					/>
				),
			});
		});
	}

	/**
	 * @param {Agent[]} data
	 * @param {Value[][]} bodyData
	 * @returns {void}
	 */
	static #addImportContacts(data, bodyData) {
		data?.forEach((agent, index) => {
			// @ts-ignore -- can be pushed
			bodyData[index]?.push({
				data: (
					<TableButton
						icon={images.icons.import}
						title={translate(
							'admin.pages.agents.view.table.import_contacts'
						)}
						to={{
							pathname: concatPaths(
								paths.app.admin.agents.root,
								agent.id,
								'contacts/import'
							),
							state: {
								managerRelatedId: ViewAgent.#resolveRelatedId(
									agent.groups
								),
								referrer: `${location.pathname}${location.search}${location.hash}`,
							},
						}}
					/>
				),
			});
		});
	}
}

ViewAgent.contextType = AuthContext;

export default withSettings(ViewAgent);
