import { translate } from 'App/Internationalization';
import * as validators from 'shared/validators';
import * as yup from 'yup';
import BaseForm from 'shared/Forms/BaseForm';
import Card from 'shared/Card';
import FieldPassword from 'shared/Forms/Fields/FieldPassword';
import FormErrorMessages from 'shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from 'shared/Forms/Messages/FormSuccessMessages';
import React from 'react';
import SubmitButton from 'shared/Forms/Inputs/SubmitButton';

const initialFormValues = {
	currentPassword: '',
	newPassword: '',
	newPasswordConfirmation: '',
};

const validationSchema = yup.object().shape({
	currentPassword: validators.required('Current Password'),
	newPassword: validators.password,
	newPasswordConfirmation: validators.matchConfirmation(
		'newPassword',
		'New passwords'
	),
});

export default class ChangePasswordForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isSubmittedSuccessfully: false,
			message: null,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async handleSubmit(values) {
		try {
			await this.props.userService.changePassword(
				{
					currentPassword: values.currentPassword,
					newPassword: values.newPassword,
				},
				this.props.auth.accessToken
			);

			this.setState({
				isSubmittedSuccessfully: true,
				message: 'Password changed successfully!',
			});
		} catch (error) {
			this.setState({ message: error.message });
		}
	}

	render() {
		return (
			<BaseForm
				initialValues={initialFormValues}
				onSubmit={this.handleSubmit}
				validationSchema={validationSchema}
			>
				<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
					<FieldPassword
						className="col-start-1"
						label="Current Password"
						name="currentPassword"
						required
					/>

					<FieldPassword
						autocomplete="new-password"
						className="col-start-1"
						help={translate('global.forms.help.password')}
						label="New Password"
						name="newPassword"
						required
					/>

					<FieldPassword
						autocomplete="new-password"
						className="col-start-1"
						label="Confirm New Password"
						name="newPasswordConfirmation"
						required
					/>
				</fieldset>

				<footer className="button-area">
					<SubmitButton>
						{translate('global.forms.buttons.save')}
					</SubmitButton>
				</footer>

				<FormSuccessMessages
					messages={
						this.state.isSubmittedSuccessfully
							? [this.state.message]
							: null
					}
				/>

				<FormErrorMessages
					messages={
						this.state.isSubmittedSuccessfully
							? null
							: [this.state.message]
					}
				/>
			</BaseForm>
		);
	}
}
