import { translate } from '../../../App/Internationalization';
import { withProperty } from '../../../App/Homeowner/PropertyProvider';
import AddAppliance from '../../../App/Homeowner/ApplianceCenter/AddAppliance/AddAppliance';
import Dashboard from '../../../App/Homeowner/ApplianceCenter/Dashboard';
import EditAppliance from './EditAppliance/EditAppliance';
import Page from '../../../shared/Page';
import paths from '../../../config/local/paths';
import React from 'react';
import Switch from '../../../shared/Switch';
import ViewAppliances from '../../../App/Homeowner/ApplianceCenter/ViewAppliances';
import ViewManuals from '../../../App/Homeowner/ApplianceCenter/ViewManuals';

const InjectedDashboard = withProperty(Dashboard);
const InjectedAddAppliance = withProperty(AddAppliance);
const InjectedViewAppliances = withProperty(ViewAppliances);

export default class ApplianceCenter extends React.Component {
	/* eslint-disable class-methods-use-this -- `render()` must be callable from the class instance */
	/** @returns {JSX.Element} */
	render() {
		return (
			<Switch>
				<Page
					component={InjectedDashboard}
					exact={true}
					path={paths.app.homeowner.applianceCenter.root}
					title={translate('homeowner.nav.appliance_center.view')}
				/>
				<Page
					component={ViewManuals}
					exact={true}
					path={paths.app.homeowner.applianceCenter.manuals}
					title="Manuals"
				/>
				<Page
					component={InjectedAddAppliance}
					exact={true}
					path={paths.app.homeowner.applianceCenter.add}
					title="Add"
				/>
				<Page
					component={EditAppliance}
					path={`${paths.app.homeowner.applianceCenter.edit}/:applianceId`}
					title="Edit"
				/>
				<Page
					component={InjectedViewAppliances}
					exact={true}
					path={paths.app.homeowner.applianceCenter.view}
					title="View"
				/>
			</Switch>
		);
	}
	/* eslint-enable class-methods-use-this */
}
