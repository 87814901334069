import React from 'react';

import AuthProvider from 'shared/AuthProvider';
import BreakpointProvider from 'shared/BreakpointProvider';
import MetadataProvider from 'shared/MetadataProvider';
import ThemeProvider from 'shared/ThemeProvider';
import SettingProvider from 'shared/SettingProvider/SettingProvider';

export default class GlobalProviders extends React.Component {
	render() {
		return (
			<BreakpointProvider>
				<SettingProvider>
					<ThemeProvider>
						<MetadataProvider>
							<AuthProvider>
								{this.props.children}
							</AuthProvider>
						</MetadataProvider>
					</ThemeProvider>
				</SettingProvider>
			</BreakpointProvider>
		);
	}
}
