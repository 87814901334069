/** @typedef {import('./Props').default} Props */
/** @typedef {import('./State').default} State */

import { translate } from '../../../Internationalization';
import { withAllConnectService } from 'service-container';
import captureError from '../../../../utils/captureError';
import Loading from '../../../../shared/Loading';
import NoContent from '../../../../shared/NoContent';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class AllConnectWidget extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			isInitializing: true,
			message: null,
		};
	}

	async componentDidMount() {
		try {
			await this.props.allConnectService.loadWidget();

			this.setState({ isInitializing: false });
		} catch (error) {
			captureError(error);
			this.setState({
				isInitializing: false,
				message: translate('global.error'),
			});
		}
	}

	/** @returns {JSX.Element} */
	#getWidgetComponent() {
		return (
			<div
				className="myFinance-widget myFinance-ad-unit"
				data-apt-num={this.props.address.streetAddress2 ?? ''}
				data-campaign={process.env.REACT_APP_ALL_CONNECT_CAMPAIGN}
				data-city={this.props.address.city ?? ''}
				data-state={this.props.address.state ?? ''}
				data-street={this.props.address.streetAddress1 ?? ''}
				data-widget-id={process.env.REACT_APP_ALL_CONNECT_WIDGET_ID}
				data-zip={this.props.address.postalCode ?? ''}
			/>
		);
	}

	/** @returns {JSX.Element} */
	render() {
		if (this.state.message) {
			return <NoContent />;
		}

		if (this.state.isInitializing) {
			return <Loading />;
		}

		return this.#getWidgetComponent();
	}
}

// @ts-ignore until service containers are fixed
export default withAllConnectService(AllConnectWidget);
