/**
 * @param {string} value
 * @returns {string}
 */
export default function formatPhoneNumberForData(value) {
	const cleanValue = String(value).replace(/\D/g, '');

	return cleanValue.length > 7
		? formatFullPhoneNumber(cleanValue)
		: formatBasicPhoneNumber(cleanValue);
}

/**
 * @param {string} value
 * @returns {string}
 */
function formatFullPhoneNumber(value) {
	const firstSegment = value.substring(0, 3);
	const secondSegment = value.substring(3, 6);
	const thirdSegment = value.substring(6, 10);
	const extension = value.substring(10);
	let formattedValue = `${firstSegment}-${secondSegment}-${thirdSegment}`;

	if (extension) {
		formattedValue += `,${extension}`;
	}

	return formattedValue;
}

/**
 * @param {string} value
 * @returns {string}
 */
function formatBasicPhoneNumber(value) {
	const firstSegment = value.substring(0, 3);
	const secondSegment = value.substring(3);
	return value.length > 3 ? `${firstSegment}-${secondSegment}` : firstSegment;
}
