/**
 * @typedef {import('../../../../Registration/RegistrationService/PhoneNumber').default} PhoneNumber
 * @typedef {import('../../../shared/ContactService/AdminContact').default} AdminContact
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 * @typedef {import('./State').Value} Value
 * @typedef {import('./TableContact').default} TableContact
 * @typedef {import('../../../shared/Query/Filters/Component').QueryParameters} FilterQueryParameters
 */

import { BulkOptions } from '../../../shared/BulkOptions/BulkOptions';
import { Link } from 'react-router-dom';
import { translate } from '../../../Internationalization';
import { withAuth } from '../../../../shared/AuthProvider';
import {
	withAgentService,
	withContactService,
	withExportService,
} from '../../../../service-container';
import { withSettings } from '../../../../shared/SettingProvider/SettingProvider';
import AgentFilter from '../../../shared/Query/Filters/AgentsFilter/AgentsFilter';
import BaseButton from '../../../../shared/BaseButton/BaseButton';
import BaseTable from '../../../../shared/BaseTable';
import bulkOptions from '../../../../config/local/bulk-options';
import BulkSelectButton from '../../../../shared/BulkSelectButton/BulkSelectButton';
import captureError from '../../../../utils/captureError';
import concatPaths from '../../../../utils/contactPaths';
import ContactsBulkOptionsPopup from '../../../../shared/Popups/Contacts/ContactsBulkOptionsPopup';
import Feature from '../../../../shared/Feature';
import FormErrorMessages from '../../../../shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from '../../../../shared/Forms/Messages/FormSuccessMessages';
import FormWarningMessages from '../../../../shared/Forms/Messages/FormWarningMessages';
import images from '../../../../config/local/images';
import LeadScore from '../../../shared/LeadScore/LeadScore';
import LeadScoreModal from '../../../shared/LeadScoreModal/LeadScoreModal';
import LinkButton from '../../../../shared/LinkButton';
import Loading from '../../../../shared/Loading';
import Pagination from '../../../../shared/Pagination';
import paths from '../../../../config/local/paths';
import React from 'react';
import RegisteredFilter from '../../../shared/Query/Filters/Registered/RegisteredFilter';
import SearchFilter from '../../../shared/Query/Filters/Search/SearchFilter';
import TableButton from '../../../../shared/TableButton';

const flags = {
	leadScoreModal: 'admin:lead_score_history_modal',
	moverScore: 'admin:contacts_lead_score',
	resendInvite: 'admin:resend_invite',
};

/** @extends {React.Component<Props, State>} */
class ViewContacts extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/**
		 * @type {State}
		 */
		this.state = {
			agents: {},
			bulkSelection: '',
			contacts: [],
			currentPageNumber: 1,
			deleteWarningMessage: '',
			errorMessage: '',
			filterQuery: {},
			hasPendingExport: true,
			isGettingData: true,
			lastPageNumber: 1,
			message: null,
			resendInviteMessage: '',
			scoreHistory: {
				email: '',
				isOpen: false,
				leadScores: [],
				name: '',
			},
			selectedContacts: [],
			totalRecord: 0,
		};

		this.headerData = [
			{ title: 'name' },
			{ title: 'email' },
			{ title: 'phone' },
			{ title: 'group' },
			{ title: 'agent' },
			{
				title: this.#getFeatureFlag(flags.moverScore)
					? 'mover_score'
					: '',
			},
			{ title: 'edit' },
			{
				title: this.#getFeatureFlag(flags.leadScoreModal)
					? 'score_history'
					: '',
			},
			{
				title: this.#getFeatureFlag(flags.resendInvite)
					? 'resend_invite'
					: '',
			},
			{ title: 'delete' },
		];

		this.bulkOptionList = [
			{
				label: translate('agent.pages.contacts.view.delete_all'),
				option: 'bulk_delete',
			},
		];

		if (this.#getFeatureFlag(flags.resendInvite)) {
			this.bulkOptionList.push({
				label: translate('global.pages.contacts.view.resend_invite'),
				option: 'bulk_invite',
			});
		}

		this._handleBulkSubmit = this._handleBulkSubmit.bind(this);
		this._handleClose = this._handleClose.bind(this);
		this._handleConfirmDelete = this._handleConfirmDelete.bind(this);
		this._handleDelete = this._handleDelete.bind(this);
		this._handleDeselectAll = this._handleDeselectAll.bind(this);
		this._handleExport = this._handleExport.bind(this);
		this._handleResendInvite = this._handleResendInvite.bind(this);
		this._handleSelectAll = this._handleSelectAll.bind(this);
		this._handleSubmitQuery = this._handleSubmitQuery.bind(this);
		this._onHandleOptionChange = this._onHandleOptionChange.bind(this);
		this._setTableData = this._setTableData.bind(this);
	}

	/**
	 * @param {AdminContact} contact
	 * @returns {?string}
	 */
	static #getPhoneNumber(contact) {
		if (!contact.phoneNumbers?.length) {
			return contact?.phone?.mobile || contact?.phone?.landline || null;
		}

		const mobile = contact.phoneNumbers.find(
			(phone) => phone.type === 'mobile'
		);

		if (mobile) {
			return mobile.value;
		}

		return contact.phoneNumbers[0].value;
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			if (this.#getFeatureFlag('admin:export_contacts')) {
				await this.#setPendingExport();
			}

			await this._setTableData();
		} catch (error) {
			captureError(error);
			this.setState({
				isGettingData: false,
				message: translate('admin.pages.contacts.view.table.error'),
			});
		}

		this.setState({ agents: await this.#getAgents() });
	}

	/**
	 * @param {string} feature
	 * @returns {boolean}
	 */
	#getFeatureFlag(feature) {
		return this.props.settings.features.get(feature) ?? false;
	}

	/**
	 * @param {FilterQueryParameters} value
	 * @returns {FilterQueryParameters}
	 */
	static #convertAgentFilter(value) {
		const key = 'filter[select-agents]';

		if (value[key] === undefined) {
			return value;
		}

		/**
		 * @type {string[]}
		 */
		// @ts-ignore
		const agentIdList = value[key];
		delete value[key];

		if (agentIdList.length === 0) {
			return value;
		}

		value['filter[agent_id]'] = agentIdList.join(';');

		return value;
	}

	/**
	 * @param {FilterQueryParameters} value
	 * @returns {FilterQueryParameters}
	 */
	static #convertFilters(value) {
		return ViewContacts.#convertAgentFilter(value);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #setPendingExport() {
		const hasPendingExport =
			await this.props.exportService.hasPendingExport();

		this.setState({
			hasPendingExport,
		});
	}

	/**
	 * @protected
	 * @param {FilterQueryParameters} value
	 * @returns {FilterQueryParameters}
	 */
	_handleSubmitQuery(value) {
		this.setState(
			(prev) => ({
				...prev,
				currentPageNumber: 1,
				filterQuery: ViewContacts.#convertFilters({
					...prev.filterQuery,
					...value,
				}),
			}),
			() => {
				this._setTableData(1);
			}
		);

		return value;
	}

	/**
	 * @protected
	 * @param {number} pageNumber
	 * @returns {Promise<void>}
	 */
	async _setTableData(pageNumber = 1) {
		const contactData = await this.props.contactService.getContacts({
			...this.state.filterQuery,
			page: pageNumber,
		});

		const noneSubscribedGroups = contactData.data.filter(
			(contact) => !contact?.hasSubscription
		);

		const sortLeadScore = contactData.data
			.filter((contact) => contact?.hasSubscription)
			.sort((a, b) => (b?.leadScore || 0) - (a?.leadScore || 0));

		this.setState((prev) => ({
			...prev,
			contacts: [...sortLeadScore, ...noneSubscribedGroups],
			currentPageNumber: pageNumber,
			isGettingData: false,
			lastPageNumber: contactData.pages ?? 1,
			pageNumber,
			totalRecord: contactData.total,
		}));
	}

	/**
	 * @returns {Promise<State['agents']>}
	 */
	async #getAgents() {
		const agentList = [];
		let total = 0;
		let page = 1;

		do {
			let response;

			try {
				response = await this.props.agentService.getAgents(
					this.props.auth.accessToken,
					page
				);

				agentList.push(...response.data);
				page = page + 1;
				total = response.total;
			} catch (error) {
				captureError(error);

				return {};
			}
		} while (agentList.length < total);

		/**
		 * @type {State['agents']}
		 */
		const agentHash = {};

		agentList.forEach((agent) => {
			agentHash[agent.id] =
				`${agent.name.first} ${agent.name.last}`.trim();
		});

		return agentHash;
	}

	/**
	 * @protected
	 * @param {string} contactId
	 * @returns {void}
	 */
	_handleDelete(contactId) {
		this.setState({
			bulkSelection: bulkOptions.delete,
			selectedContacts: [contactId],
		});
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleClose() {
		this.setState({ bulkSelection: '' });
	}

	/**
	 * @protected
	 * @returns {Promise<void>}
	 */
	async _handleConfirmDelete() {
		try {
			const response = await this.props.contactService.removeContact(
				this.state.selectedContacts
			);

			const responseMessage = translate(
				'agent.pages.contacts.view.success_message',
				response?.deleted,
				response?.total
			);

			await this._setTableData();

			this.setState({ deleteWarningMessage: responseMessage });
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate(
					'admin.pages.contacts.view.delete_error'
				),
			});
		}
		this.setState({ bulkSelection: '' });
	}

	/**
	 * @protected
	 * @param {string} contactId
	 * @param {string} name
	 * @returns {Promise<void>}
	 */
	async _handleResendInvite(contactId, name) {
		try {
			await this.props.contactService.inviteContact(contactId);

			this.setState({
				resendInviteMessage: translate(
					'admin.pages.contacts.view.resend_invite_message',
					name
				),
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate(
					'admin.pages.contacts.view.resend_error'
				),
			});
		}
	}

	/**
	 * @protected
	 * @returns {Promise<void>}
	 */
	async _handleConfirmInvite() {
		try {
			await this.props.contactService.bulkResendContactInvite(
				this.state.selectedContacts
			);

			const resendInviteMessage = translate(
				'global.pages.contacts.resend_invite_popup.success_message'
			);

			this.setState({
				bulkSelection: '',
				resendInviteMessage,
				selectedContacts: [],
			});
		} catch (error) {
			captureError(error);
			this.setState({
				bulkSelection: '',
				errorMessage: translate('global.error'),
			});
		}
	}

	/**
	 * @returns {void}
	 */
	_handleSelectAll() {
		this.setState((prevState) => {
			const contactIdList = prevState.contacts.map(
				(contact) => contact.id
			);
			return {
				selectedContacts: contactIdList,
			};
		});
	}

	/**
	 * @returns {void}
	 */
	_handleDeselectAll() {
		this.setState({ selectedContacts: [] });
	}

	/**
	 * @protected
	 * @returns {Promise<void>}
	 */
	async _handleExport() {
		this.setState({
			hasPendingExport: true,
		});

		try {
			return this.props.exportService.createExport(
				'contacts',
				'administrator'
			);
		} catch (error) {
			this.setState({
				errorMessage: translate('exports.errors.create'),
				hasPendingExport: false,
			});
		}
	}

	/**
	 * @returns {boolean}
	 */
	get #isAdminOrOwner() {
		if (this.props.auth.roles.includes('admin')) {
			return true;
		}

		if (this.props.auth.roles.includes('owner')) {
			return true;
		}

		return false;
	}

	/**
	 * @todo remove owner check once off hold
	 * @returns {boolean}
	 */
	#canExportContacts() {
		if (this.#isAdminOrOwner) {
			return false;
		}

		if (this.state.contacts.length && !this.state.hasPendingExport) {
			return true;
		}

		return false;
	}

	/**
	 * @param {string} [className]
	 * @returns {JSX.Element}
	 */
	#renderSelectionButtons(className = '') {
		return (
			<div className={`text-theme text-xs ${className}`.trim()}>
				<button onClick={this._handleSelectAll} type="button">
					{translate('agent.pages.contacts.view.select_all')}
				</button>
				{' | '}
				<button onClick={this._handleDeselectAll} type="button">
					{translate('agent.pages.contacts.view.deselect_all')}
				</button>
			</div>
		);
	}

	/**
	 * @protected
	 * @param {{label: string; option: string}} selection
	 * @returns {void}
	 */
	_onHandleOptionChange(selection) {
		this.setState({ bulkSelection: selection.option });
	}

	/**
	 * @protected
	 * @returns {string}
	 */
	_findContactName() {
		if (this.state.selectedContacts?.length !== 1) {
			return '';
		}

		const contactInfo = this.state.contacts.find(
			(contact) => contact.id === this.state.selectedContacts[0]
		);

		return `${contactInfo?.name?.first ?? ''} ${
			contactInfo?.name?.last ?? ''
		}`;
	}

	/**
	 * @returns {JSX.Element}
	 */
	_renderMessages() {
		return (
			<React.Fragment>
				<FormSuccessMessages
					messages={
						this.state.resendInviteMessage ||
						this.props.location?.state?.message
					}
				/>

				<FormErrorMessages messages={this.state.errorMessage} />

				<ContactsBulkOptionsPopup
					bulkSelection={this.state.bulkSelection}
					contactName={this._findContactName()}
					onClose={this._handleClose}
					onSubmit={this._handleBulkSubmit}
					selectedContacts={this.state.selectedContacts}
				/>

				<FormWarningMessages
					heading={translate(
						'agent.pages.contacts.view.success_header'
					)}
					messages={this.state.deleteWarningMessage}
				/>

				<Feature fallback={null} name="admin:export_contacts">
					<FormWarningMessages
						heading={translate('exports.started')}
						messages={
							this.state.hasPendingExport
								? translate('exports.pending_description')
								: ''
						}
					/>
				</Feature>
			</React.Fragment>
		);
	}

	/**
	 * @protected
	 * @param {string} contactId
	 * @returns {void}
	 */
	_handleCheckBox(contactId) {
		this.setState((prevState) => {
			if (!prevState.selectedContacts.includes(contactId)) {
				return {
					selectedContacts: [
						...prevState.selectedContacts,
						contactId,
					],
				};
			}

			const filteredContacts = prevState.selectedContacts.filter(
				(id) => id !== contactId
			);

			return { selectedContacts: filteredContacts };
		});
	}

	/**
	 * @param {AdminContact} contact
	 * @returns {{data : JSX.Element | string, index: string}}
	 */
	#scoreHistory(contact) {
		if (!this.#getFeatureFlag(flags.leadScoreModal)) {
			return {
				data: '',
				index: `contact-${contact.id}`,
			};
		}

		return {
			data: (
				<TableButton
					defaultSize={true}
					disabled={
						!contact?.leadScores?.length ||
						!contact?.hasSubscription
					}
					handleClick={() => this.#toggleScoreHistoryModal(contact)}
					icon={images.icons.scoreHistory}
				/>
			),
			index: `contact-${contact.id}`,
		};
	}

	/**
	 * @param {AdminContact} contact
	 * @returns {void}
	 */
	// eslint-disable-next-line complexity
	#toggleScoreHistoryModal(contact) {
		const firstName = contact?.name?.first ?? '';
		const lastName = contact?.name?.last ?? '';

		this.setState({
			scoreHistory: {
				email: contact?.email ?? '',
				isOpen: true,
				leadScores: contact?.leadScores ?? [],
				name:
					firstName || lastName
						? `${firstName} ${lastName}`.trim()
						: null,
			},
		});
	}

	/**
	 * @param {AdminContact} contact
	 * @returns {{data : JSX.Element | string, index: string}}
	 */
	resendInvite(contact) {
		return {
			data: this.#getFeatureFlag(flags.resendInvite) ? (
				<TableButton
					defaultSize={true}
					disabled={!!contact.homeownerId}
					handleClick={() =>
						this._handleResendInvite(
							contact.id,
							`${contact?.name?.first} ${contact?.name?.last}`
						)
					}
					icon={images.icons.email}
				/>
			) : (
				''
			),
			index: `contact-${contact.id}`,
		};
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleBulkSubmit() {
		if (this.state.bulkSelection === bulkOptions.delete) {
			this._handleConfirmDelete();
			this.setState({ bulkSelection: '' });
		}
		if (this.state.bulkSelection === bulkOptions.invite) {
			this._handleConfirmInvite();
			this.setState({ bulkSelection: '' });
		}
	}

	/**
	 * @param {AdminContact} contact
	 * @returns {JSX.Element | string}
	 */
	#getLeadScore(contact) {
		if (!this.#getFeatureFlag(flags.moverScore)) {
			return ' ';
		}

		if (contact?.hasSubscription) {
			return (
				<LeadScore
					className="h-ms-1"
					leadScore={contact.leadScore}
					leadScores={contact.leadScores}
				/>
			);
		}

		return (
			<Link
				className="no-underline flex items-center hover-lighter text-info"
				to={concatPaths(
					paths.app.admin.groups.root,
					`/${contact.group.id}/subscription`
				)}
			>
				{translate('agent.pages.contacts.view.score')}
			</Link>
		);
	}

	#getHeader() {
		return this.headerData.map((header) => {
			if (!header.title) {
				return header;
			}

			if (header.title === 'name') {
				return {
					title: (
						<BulkSelectButton
							handleDeSelectAll={() => this._handleDeselectAll()}
							handleSelectAll={() => this._handleSelectAll()}
						>
							{translate(
								`admin.pages.contacts.table.${header.title}`
							)}
						</BulkSelectButton>
					),
				};
			}

			return {
				title: translate(`admin.pages.contacts.table.${header.title}`),
			};
		});
	}

	/** @returns {JSX.Element | null} */
	#renderScoreHistory() {
		if (
			!this.#getFeatureFlag(flags.leadScoreModal) ||
			!this.state?.scoreHistory?.isOpen
		) {
			return null;
		}

		return (
			<LeadScoreModal
				email={this.state?.scoreHistory?.email}
				leadScores={this.state?.scoreHistory?.leadScores}
				name={this.state?.scoreHistory?.name}
			/>
		);
	}

	/**
	 * @returns {React.ReactElement}
	 */
	render() {
		const tableBodyData = this.state.contacts.map((contact) => [
			{
				data: (
					<span className="datum-value">
						<span className="form-check form-check-sm">
							<input
								checked={this.state.selectedContacts.includes(
									contact.id
								)}
								id={`table-checkbox-${contact.id}`}
								onChange={() =>
									this._handleCheckBox(contact.id)
								}
								type="checkbox"
							/>
						</span>
						<label htmlFor={`table-checkbox-${contact.id}`}>
							{contact?.name?.first ?? ''}{' '}
							{contact?.name?.last ?? ''}
							{!!contact.homeownerId && (
								<React.Fragment>
									{' '}
									<span title="Registered Homeowner">🏠</span>
								</React.Fragment>
							)}
						</label>
					</span>
				),
				index: `contact-${contact.id}`,
			},
			{
				data: contact?.email,
				index: `contact-${contact.id}`,
			},
			{
				data: ViewContacts.#getPhoneNumber(contact),
				index: `contact-${contact.id}`,
			},
			{
				data: contact.group.name,
				index: `contact-${contact.id}`,
			},
			{
				data: `${contact?.agent?.name?.first ?? ''} ${
					contact?.agent?.name?.last ?? ''
				}`,
				index: `contact-${contact.id}`,
			},
			{
				data: this.#getLeadScore(contact),
				index: `contact-${contact.id}`,
			},
			{
				data: (
					<TableButton
						defaultSize={true}
						icon={images.icons.edit}
						to={{
							pathname: `${paths.app.admin.contacts.edit}/${contact.id}`,
							state: { contactId: contact.id },
						}}
					/>
				),
				index: `contact-${contact.id}`,
			},
			this.#scoreHistory(contact),
			this.resendInvite(contact),
			{
				data: (
					<TableButton
						defaultSize={true}
						disabled={!!contact.homeownerId}
						handleClick={() => this._handleDelete(contact.id)}
						icon={images.icons.trash}
					/>
				),
				index: `contact-${contact.id}`,
			},
		]);

		if (this.state.message) {
			return <FormErrorMessages messages={this.state.message} />;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<React.Fragment>
				{this.#renderScoreHistory()}
				<header className="mt-10 mb-6 md:flex justify-between items-center">
					<h1 className="hl-ms-6 mb-6 md:mb-0 mr-4">
						{`${translate('admin.pages.contacts.view.title')} (${
							this.state.totalRecord
						})`}
					</h1>

					<section className="button-group">
						<LinkButton
							icon={images.icons.plus}
							to={paths.app.admin.contacts.add}
						>
							{translate('admin.pages.contacts.view.add')}
						</LinkButton>

						<Feature fallback={null} name="admin:export_contacts">
							<BaseButton
								disabled={!this.#canExportContacts()}
								icon={images.icons.export}
								onClick={this._handleExport}
								outline={true}
							>
								{this.state.hasPendingExport
									? translate('exports.export_pending')
									: translate('exports.export')}
							</BaseButton>
						</Feature>
					</section>
				</header>

				{this._renderMessages()}

				<section className="md:flex justify-between items-center mb-6 sm:mb-0">
					<div>
						<BulkOptions
							bulkOptionList={this.bulkOptionList}
							handleOptionChange={this._onHandleOptionChange}
						/>
					</div>

					<div className="flex gap-2 items-center flex-row-reverse">
						<RegisteredFilter
							onSubmit={this._handleSubmitQuery}
							query={this.state.filterQuery}
						/>
						<AgentFilter
							list={this.state.agents}
							onSubmit={this._handleSubmitQuery}
							query={this.state.filterQuery}
						/>
						<SearchFilter
							onSubmit={this._handleSubmitQuery}
							query={this.state.filterQuery}
							search={[
								'address.street_address_1',
								'email',
								'name.first',
								'name.last',
								'phone_numbers.value',
								'phone.landline',
								'phone.mobile',
							]}
						/>
					</div>
				</section>

				<BaseTable
					bodyData={tableBodyData}
					errorMessage={translate('admin.pages.contacts.view.error')}
					headerData={this.#getHeader()}
				/>

				<BulkOptions
					bulkOptionList={this.bulkOptionList}
					className="ml-0 mr-auto mt-2 w-fit"
					handleOptionChange={this._onHandleOptionChange}
				/>

				<Pagination
					currentPageNumber={this.state.currentPageNumber}
					lastPageNumber={this.state.lastPageNumber}
					setCurrentPageNumber={this._setTableData}
				/>
			</React.Fragment>
		);
	}

	/**
	 * @param {AdminContact} contact
	 * @param {string} type
	 * @returns {?string}
	 */
	static getPhoneNumber(contact, type) {
		if (!contact.phone && !contact.phoneNumbers?.length) {
			return null;
		}

		if (!contact.phoneNumbers?.length) {
			// @ts-ignore declared
			return contact?.phone?.[type];
		}

		const phoneNumber = contact.phoneNumbers.find(
			/**
			 * @param {{type: string}} number
			 * @returns {boolean}
			 */
			(number) => number.type === type
		);
		// @ts-ignore declared
		return phoneNumber?.value ?? contact?.phone?.[type] ?? null;
	}
}

export default withAgentService(
	withContactService(withSettings(withAuth(withExportService(ViewContacts))))
);
