import { BaseSVG } from '../../../../../shared/BaseSVG';
import { translate } from '../../../../Internationalization';
import genericContacts from '../../../../../config/local/generic-contacts';
import images from '../../../../../config/local/images';
import React from 'react';

export default class GenericContactsLeadScores extends React.Component {
	/**
	 * @param {'unchanged' | 'increase' | 'decrease'} contact
	 * @param {number} index
	 * @returns {JSX.Element}
	 */
	static #renderContactType(contact, index) {
		const leadScore = {
			decrease: (
				<React.Fragment>
					<span className="text-theme-red h-ms-1 mr-2">
						{translate(
							`agent.pages.dashboard.contacts.generic.score.${index}`
						)}
					</span>
					<i className="icon icon-sm">
						<BaseSVG path={images.icons.arrow.down} />
					</i>
				</React.Fragment>
			),
			increase: (
				<React.Fragment>
					<span className="text-theme-green h-ms-1 mr-2">
						{translate(
							`agent.pages.dashboard.contacts.generic.score.${index}`
						)}
					</span>
					<i className="icon icon-sm">
						<BaseSVG path={images.icons.arrow.up} />
					</i>
				</React.Fragment>
			),
			unchanged: (
				<span className="-h-ms-2 mr-2 text-grey-700">
					{translate(
						`agent.pages.dashboard.contacts.generic.score.${index}`
					)}
				</span>
			),
		};

		return leadScore[contact];
	}

	/**
	 * @param {'unchanged' | 'increase' | 'decrease'} contact
	 * @param {number} index
	 * @returns {JSX.Element}
	 */
	static #renderContact(contact, index) {
		const contactType = this.#renderContactType(contact, index);

		// first contact has a fire emoji
		const showFireEmoji = index === 1;

		return (
			<li className="list-item" key={index}>
				<header className="justified-row items-center">
					<div className="flex items-center">
						<h4 className="hl-ms mr-2">
							{translate(
								`agent.pages.dashboard.contacts.generic.name.${index}`
							)}
						</h4>

						{!!showFireEmoji && (
							<i className="icon">
								{<BaseSVG path={images.icons.tickerFire} />}
							</i>
						)}
					</div>
					<section className="flex items-center">
						{contactType}
					</section>
				</header>
			</li>
		);
	}
	/* eslint-disable class-methods-use-this -- this render function does not currently use 'this'*/

	render() {
		const genericContactList = genericContacts.map(
			/**
			 * @param {{leadScore : 'unchanged' | 'increase' | 'decrease'}} contact
			 * @param {number} index
			 * @returns {JSX.Element}
			 */
			(contact, index) =>
				GenericContactsLeadScores.#renderContact(
					contact.leadScore,
					index + 1
				)
		);

		return (
			<section className="mt-10">
				<section className="card card-border-light">
					<header className="card-header bg-theme-light text-theme-fg-light">
						<div className="h-ms-1">
							{translate(
								'agent.pages.contacts.view.upgrade_table.title'
							)}
						</div>
					</header>
					<ul className="list-group card-list divided px-3 relative">
						{genericContactList}
						<div className="overlay-faded" />
					</ul>
				</section>
			</section>
		);
	}

	/* eslint-enable */
}
