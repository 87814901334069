/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */

import { AuthContext } from '../../../../shared/AuthProvider';
import { translate } from '../../../Internationalization';
import { withContactService } from '../../../../service-container';
import ActionButtons from '../../../../shared/ActionButtons/ActionButtons';
import captureError from '../../../../utils/captureError';
import Loading from '../../../../shared/Loading';
import paths from '../../../../config/local/paths';
import ProgressBar from '../../../../shared/ProgressBar/ProgressBar';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class AcceptedContacts extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State}  */
		this.state = {
			acceptedContacts: 0,
			error: false,
			isGettingData: true,
			totalContacts: 0,
		};
	}

	async componentDidMount() {
		this.#getsAndSetsContacts();
	}

	/** @returns {Promise<void>} */
	async #getsAndSetsContacts() {
		try {
			const contactData = await this.props.contactService.getContacts();

			this.setState({
				acceptedContacts: contactData.enrolledContacts,
				isGettingData: false,
				totalContacts: contactData.total,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				error: true,
				isGettingData: false,
			});
		}
	}

	/** @returns {JSX.Element | null} */
	render() {
		if (this.state.error) {
			return null;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<React.Fragment>
				<header className="h-ms-4 mt-10">
					{translate('admin.pages.dashboard.contacts')}
				</header>
				<ProgressBar
					max={this.state.totalContacts}
					title={translate('admin.pages.dashboard.contacts_accepted')}
					value={this.state.acceptedContacts}
				/>
				<ActionButtons
					addUrl={paths.app.admin.contacts.add}
					viewAllUrl={paths.app.admin.contacts.view}
				/>
			</React.Fragment>
		);
	}
}

AcceptedContacts.contextType = AuthContext;

export default withContactService(AcceptedContacts);
