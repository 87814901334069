import React from 'react';
import SelectOption from 'shared/Forms/Inputs/SelectOption';

export default class SelectOptionList extends React.Component {
	render() {
		if (!this.props.values) return null;
		return Object.entries(this.props.values).map(([value, title]) => (
			<SelectOption key={value} title={title} value={value} />
		));
	}
}
