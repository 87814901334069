import FormMessages from './FormMessages';
import images from '../../../config/local/images';
import React from 'react';

export default class FormErrorMessages extends React.Component {
	render() {
		return (
			<FormMessages
				className={`${this.props.className} form-alert-error`}
				heading={this.props.heading ?? 'Error'}
				icon={images.icons.delete}
				messages={this.props.messages}
			/>
		);
	}
}
