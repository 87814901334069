/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */
import { translate } from '../../../../Internationalization';
import { withSubscriptionService } from '../../../../../service-container';
import captureError from '../../../../../utils/captureError';
import ExternalAnchorButton from '../../../../../shared/ExternalAnchorButton';
import images from '../../../../../config/local/images';
import Loading from '../../../../../shared/Loading';
import React from 'react';
import SubscriptionDetails from '../../../Subscription/Checkout/SubscriptionDetails';

/** @extends {React.Component<Props, State>} */
export class Checkout extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			isGettingData: true,
			paymentLink: null,
			pricing: { contactPriceInCents: 0, priceInCents: 0 },
		};
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			await this.#getAndSetSubscriptionPrice();
		} catch (exception) {
			captureError(exception);
			this.setState({ isGettingData: false });
		}
		try {
			await this.#startCheckoutSession();
		} catch (exception) {
			captureError(exception);
			this.setState({ isGettingData: false });
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #startCheckoutSession() {
		try {
			const checkoutSession =
				await this.props.subscriptionService.getGroupSubscriptionCheckoutLink(
					this.props.groupId
				);

			this.setState({
				paymentLink: checkoutSession.url,
			});
		} catch (error) {
			captureError(error);
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #getAndSetSubscriptionPrice() {
		try {
			const pricing =
				await this.props.subscriptionService.getSubscriptionPrice();

			this.setState({
				isGettingData: false,
				pricing,
			});
		} catch (error) {
			captureError(error);
			this.setState({ isGettingData: false });
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<SubscriptionDetails pricing={this.state.pricing} userRole="admin">
				{/*
				 * There is condition for redirection, user can only goto promo page
				 * if group has no subscription, If group has already a subscription user
				 * redirected to the payment page
				 */}
				<ExternalAnchorButton
					className="button button-outline button-icon"
					href={this.state.paymentLink}
					icon={images.icons.subscription}
					target="_self"
				>
					{translate('subscription.cta.button')}
				</ExternalAnchorButton>
			</SubscriptionDetails>
		);
	}
}

export default withSubscriptionService(Checkout);
