import { BaseSVG } from '../../../shared/BaseSVG';
import { Link } from 'react-router-dom';
import { translate } from '../../../App/Internationalization';
import ConfidenceChart from '../Finances/ConfidenceChart';
import Feature from '../../../shared/Feature';
import images from '../../../config/local/images';
import paths from '../../../config/local/paths';
import React from 'react';

export default class BaseChecklistProgress extends React.Component {
	/** @returns {React.ReactElement} */
	render() {
		/**
		 * 	completionPercentage : Calculate percentage of completed tasks.
		 * 	completionPercentageText : Round to percentages.
		 */
		const completionPercentage =
			this.props.completedTasks / this.props.totalTasks || 0;
		const completionPercentageText = Math.round(completionPercentage * 100);

		return (
			<section className="checklist-progress mt-8">
				<header className="progress-information">
					<figure className="image">
						<img
							alt={translate(
								'homeowner.pages.dashboard.checklistcard.checklist_spot_alt'
							)}
							className="illustration"
							src={
								images.homeowners.dashboard.checklists
									.checklistSpot
							}
						/>
					</figure>
					<section className="information">
						<h3 className="hl-ms-1">
							{translate(
								'homeowner.pages.dashboard.checklistcard.title'
							)}
						</h3>
						<div className="progress-area h-20">
							<div className="percent font-bold">
								{completionPercentageText}
								{'%'}
							</div>
							<div className="graph">
								<figure className="w-16 image">
									{/* eslint-disable-next-line react/jsx-max-depth -- Necessary here */}
									<ConfidenceChart
										confidence={completionPercentageText}
										hideInnerText={true}
									/>
									{/* eslint-enable-next-line react/jsx-max-depth */}
								</figure>
							</div>
						</div>
						<Link
							className="link-icon"
							to={paths.app.homeowner.checklists}
						>
							<h4 className="-text-ms-1">
								{translate(
									'homeowner.pages.dashboard.checklistcard.view'
								)}
							</h4>
							<BaseSVG
								className="icon icon-fill-dark icon-sm"
								path={images.icons.submit}
							/>
						</Link>
					</section>
				</header>
				<Feature fallback={null} name="homeowner:checklist_banner">
					<section className="message message-grey mb-0 justify-between">
						<span className="font-bold -text-ms-2">
							{translate(
								'homeowner.pages.dashboard.checklistcard.footer'
							)}
						</span>
						<img
							alt={translate(
								'homeowner.pages.dashboard.checklistcard.footer_alt'
							)}
							className="image mr-0 bg-white"
							src={images.icons.dealColor}
						/>
					</section>
				</Feature>
			</section>
		);
	}
}
