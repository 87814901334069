/**
 * @typedef {{}} Props
 * @typedef {import('./State').default} State
 */
import { BaseSVG } from '../../../shared/BaseSVG';
import { translate } from '../../Internationalization';
import images from '../../../config/local/images';
import React from 'react';
import Valuation from './Valuation';

/** @extends {React.Component<Props, State>} */
export class ViewWidgets extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/**
		 * @type {State}
		 */
		this.state = {
			activeTab: 'valuation',
		};
	}

	/**
	 * @returns {JSX.Element}
	 */
	// will return blog as well
	// eslint-disable-next-line consistent-return
	#renderWidget() {
		// eslint-disable-next-line default-case
		switch (this.state.activeTab) {
			case 'valuation':
				return <Valuation />;
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	/* eslint-disable class-methods-use-this -- Render method must be invokable by `this` */
	render() {
		const storageTimeLimit =
			process.env.REACT_APP_VALUATION_STORAGE_TIME_LIMIT ?? '180';

		return (
			<React.Fragment>
				<header className="mt-10 mb-6">
					<section className="form-alert form-alert-warning my-5 text-grey-900">
						<h3 className="title">
							<i className="icon icon-xs">
								<BaseSVG path={images.icons.alert} />
							</i>
							{translate(
								'agent.pages.widgets.lead_generation.storage_warning.heading'
							)}
						</h3>

						<div
							className="text rich-text"
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: translate(
									'agent.pages.widgets.lead_generation.storage_warning.message',
									storageTimeLimit,
									storageTimeLimit
								),
							}}
						/>
					</section>
					<h1 className="hl-ms-6 mb-6">
						{translate('agent.pages.widgets.widgets')}
					</h1>
				</header>
				{this.#renderWidget()}
			</React.Fragment>
		);
	}
}

export default ViewWidgets;
