/**
 * @typedef {import('./Props').default} Props
 */

import React from 'react';
import { ContactDeletePopup } from '../../../App/Agent/shared/ContactDeletePopup';
import bulkOptions from '../../../config/local/bulk-options';
import ContactInvitePopup from './ContactResendInvite';

/** @extends React.Component<Props> */
export default class ContactsBulkOptionsPopup extends React.Component {
	renderPopup() {
		switch (this.props.bulkSelection) {
			case bulkOptions.delete:
				return (
					<ContactDeletePopup
						contactName={this.props.contactName}
						onClose={this.props.onClose}
						onSubmit={this.props.onSubmit}
						selectedContacts={this.props.selectedContacts}
					/>
				);
			case bulkOptions.invite:
				return (
					<ContactInvitePopup
						contactName={this.props.contactName}
						onClose={this.props.onClose}
						onSubmit={this.props.onSubmit}
						selectedContacts={this.props.selectedContacts}
					/>
				);
			default:
				return null;
		}
	}

	/** @returns {JSX.Element} */
	render() {
		return <React.Fragment>{this.renderPopup()}</React.Fragment>;
	}
}
