import { translate } from 'App/Internationalization';
import * as validators from 'shared/validators';
import * as yup from 'yup';
import BaseForm from 'shared/Forms/BaseForm';
import FieldEmail from 'shared/Forms/Fields/FieldEmail';
import FormErrorMessages from 'shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from 'shared/Forms/Messages/FormSuccessMessages';
import React from 'react';
import SubmitButton from 'shared/Forms/Inputs/SubmitButton';

const initialFormValues = {
	email: '',
	emailConfirmation: '',
};

const validationSchema = yup.object().shape({
	email: validators.email,
	emailConfirmation: validators.matchConfirmation('email', 'Emails'),
});

export default class ChangeUserEmailForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isSubmittedSuccessfully: false,
			message: null,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async handleSubmit(values) {
		try {
			await this.props.userService.changeUserEmail(
				values.email,
				this.props.auth.accessToken
			);

			this.setState({
				isSubmittedSuccessfully: true,
				message: 'Request received! Please check your email.',
			});
		} catch (error) {
			this.setState({ message: error.message });
		}
	}

	render() {
		return (
			<BaseForm
				className="search-form-field"
				initialValues={initialFormValues}
				onSubmit={this.handleSubmit}
				validationSchema={validationSchema}
			>
				<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
					<FieldEmail label="New Email" required />
					<FieldEmail
						label="Confirm New Email"
						name="emailConfirmation"
						required
					/>
				</fieldset>

				<footer className="mx-auto sm:ml-0 button-group w-min">
					<SubmitButton disabled={this.state.isSubmittedSuccessfully}>
						{translate('global.forms.buttons.save')}
					</SubmitButton>
				</footer>

				<FormSuccessMessages
					messages={
						this.state.isSubmittedSuccessfully
							? [this.state.message]
							: null
					}
				/>

				<FormErrorMessages
					messages={
						this.state.isSubmittedSuccessfully
							? null
							: [this.state.message]
					}
				/>
			</BaseForm>
		);
	}
}
