import FieldFirstName from 'shared/Forms/Fields/Name/FieldFirstName';
import FieldLastName from 'shared/Forms/Fields/Name/FieldLastName';
import FormField from 'shared/Forms/Fields/FormField';
import React from 'react';

export default class NameFields extends React.Component {
	render() {
		return (
			<React.Fragment>
				<FieldFirstName
					className={this.props.className}
					disabled={this.props.disabled}
					name={this.props.firstNameId}
					required={this.props.required}
				/>
				<FieldLastName
					className={this.props.className}
					disabled={this.props.disabled}
					name={this.props.lastNameId}
					required={this.props.required}
				/>
			</React.Fragment>
		);
	}
}
