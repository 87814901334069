import React from 'react';

export class ApplianceItem extends React.Component {
	render() {
		return (
			<li className="form-check">
				<input
					checked={this.props.isComplete}
					className="form-check-input"
					disabled={this.props.disabled}
					id={this.props.name}
					name={this.props.name}
					onChange={this.props.onChange}
					type="checkbox"
				/>
				<label className="ml-2" htmlFor={this.props.name}>
					{this.props.label}
				</label>
			</li>
		);
	}
}

export default ApplianceItem;
