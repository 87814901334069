import React from 'react';
import { Redirect } from 'react-router-dom';

import AddIntegration from 'App/Agent/Integrations/AddIntegration';
import EditIntegration from 'App/Agent/Integrations/EditIntegration';
import ViewIntegrations from 'App/Agent/Integrations/ViewIntegrations';
import paths from 'config/local/paths';
import Page from 'shared/Page';
import Switch from 'shared/Switch';
import { translate } from 'App/Internationalization';

export default class Integrations extends React.Component {
	render() {
		return (
			<Switch>
				<Redirect
					exact
					from={paths.app.agent.integrations.root}
					to={paths.app.agent.integrations.view}
				/>
				<Page
					component={ViewIntegrations}
					path={paths.app.agent.integrations.view}
					title={translate('admin.nav.integrations.view')}
				/>
				<Page
					component={AddIntegration}
					path={paths.app.agent.integrations.add}
					title={translate('admin.nav.integrations.add')}
				/>
				<Page
					component={EditIntegration}
					path={paths.app.agent.integrations.edit}
					title={translate('admin.nav.integrations.edit')}
				/>
			</Switch>
		);
	}
}
