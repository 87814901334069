/**
 * @param {string} pathname
 * @returns {string}
 */
export function toAbsoluteUrl(pathname) {
	const uri = pathname.replace(/\s/g, '%20');

	if (uri.startsWith('http') || uri.startsWith('data:')) {
		return uri;
	}

	if (!uri.startsWith('/')) {
		return `${process.env.PUBLIC_URL}/${uri}`;
	}

	return process.env.PUBLIC_URL + uri;
}
