/**
 * @typedef {import('../../types/Occupation').default} Occupation
 */
import accessibility from '@mooveguru/yhh-shared-config/accesibility.json';
import agentOccupationList from '@mooveguru/yhh-shared-config/users/agent/occupations.json';
import occupationList from '@mooveguru/yhh-shared-config/occupation.json';
import contrastChecker from '@mooveguru/js-color-contract-checker';

const occupations = { ...agentOccupationList, ...occupationList };

/**
 * @param {string | null} color
 * @returns {string}
 */
export function generateTextColor(color) {
	if (!color) {
		return accessibility.defaultTextColor;
	}

	return contrastChecker.meetsContrastRequirement(accessibility.defaultTextColor, color, 'normal') // prettier-ignore
		? accessibility.defaultTextColor
		: accessibility.alternateTextColor;
}

/**
 * @param {string} key
 * @param {string} value
 * @returns {void}
 */
export function setCssCustomProperty(key, value) {
	document.documentElement.style.setProperty(`--${key}`, value);
}

/**
 * @param {string} key
 * @returns {string}
 */
export function getCssCustomProperty(key) {
	return document.documentElement.style.getPropertyValue(`--${key}`);
}

/**
 * @param {keyof CSSStyleDeclaration} key
 * @returns {string | number | ((property: string, value: string | null, priority?: string | undefined) => void) | ((property: string) => string) | CSSRule | ((property: string) => string) | ((index: number) => string) | ((property: string) => string) | null}
 */
export function getCssProperty(key) {
	return getComputedStyle(document.body)[key];
}

/**
 * @template {Occupation} Key
 * @param {Key} key
 * @returns {typeof occupations[Key]}
 */
export function occupation(key) {
	const value = occupations[key] ?? occupations.agent;

	return value.includes('Agent') ? 'Agent' : value;
}
