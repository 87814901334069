/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */

import { BaseSVG } from '../../../shared/BaseSVG';
import { translate } from '../../../App/Internationalization';
import images from '../../../config/local/images';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class BulkOptions extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		/**
		 * @type {State}
		 */
		this.state = {
			displayDropdown: false,
		};

		this.dropdownRef = React.createRef();
		this._handleDropdown = this._handleDropdown.bind(this);
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleDropdown() {
		this.setState(
			/**
			 * @param {State} prevState
			 * @returns {State}
			 */
			(prevState) => ({
				displayDropdown: !prevState.displayDropdown,
			})
		);
	}

	/**
	 * @protected
	 * @param {{count?: number; label: string; option: string}} selection
	 * @returns {void}
	 */
	_handleClick(selection) {
		this.setState({ displayDropdown: false });
		this.props.handleOptionChange(selection);
	}

	/** @returns {JSX.Element} */
	render() {
		return (
			<div
				className={`dropdown ${
					this.props.className ?? 'mx-auto w-fit'
				}`.trim()}
			>
				<button
					className="button button-icon dropdown-button"
					onClick={this._handleDropdown}
					ref={this.dropdownRef}
					type="button"
				>
					<span>
						{this.props.buttonTitle ??
							translate('agent.pages.contacts.view.bulk_options')}
					</span>
					<i className="icon icon-fill-fg icon-sm">
						<BaseSVG path={images.homeowners.header.arrow} />
					</i>
				</button>

				<ul
					className={`dropdown-menu ${
						this.state.displayDropdown ? 'active' : ''
					}`}
					style={{
						left: `${this.dropdownRef?.current?.offsetLeft}px`,
						top: `${this.dropdownRef?.current?.offsetHeight}px`,
					}}
				>
					<li>
						{this.props.bulkOptionList.map((item) => (
							<button
								className="w-full"
								key={item.option}
								onClick={() => this._handleClick(item)}
								type="button"
							>
								{item.label}
							</button>
						))}
					</li>
				</ul>
			</div>
		);
	}
}
