/**
 * @typedef {import('../../shared/GroupService/Group').GroupBase} GroupBase
 * @typedef {import('./CreateGroup').Props} Props
 * @typedef {import('./CreateGroup').State} State
 */

import { Redirect } from 'react-router';
import { translate } from '../../Internationalization';
import { withGroupService } from '../../../service-container';
import captureError from '../../../utils/captureError';
import concatPaths from '../../../utils/contactPaths';
import GroupForm from './GroupForm';
import paths from '../../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class CreateGroup extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			error: '',
			groupId: null,
			redirectToSubscription: false,
			success: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	/**
	 * @param {GroupBase} values
	 * @returns {Promise<void>}
	 */
	async handleSubmit(values) {
		try {
			const groupId = await this.props.groupService.createGroup(values);

			if (values.cobranding === 'enabled') {
				this.setState({ groupId, redirectToSubscription: true });
			}

			this.setState({ success: true });
		} catch (error) {
			captureError(error);

			this.setState({ error: translate('global.error') });
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	#resolveRedirect() {
		if (!this.state.redirectToSubscription) {
			return <Redirect to={paths.app.admin.groups.view} />;
		}

		const groupId = this.state.groupId;
		return (
			<Redirect
				to={{
					pathname: concatPaths(
						paths.app.admin.groups.root,
						`/${groupId}/subscription`
					),
					state: {
						cobranding: true,
					},
				}}
			/>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.success) {
			return this.#resolveRedirect();
		}

		return (
			<React.Fragment>
				<h1 className="hl-ms-6 mt-3">
					{translate('admin.groups.add.title')}
				</h1>
				{/* @ts-ignore -- declared */}
				<GroupForm
					error={this.state.error}
					groupLabel={translate(
						'admin.pages.agents.form.group_select.label'
					)}
					isSubscribed={false}
					onSubmit={this.handleSubmit}
					submitText={translate('admin.groups.add.submit')}
					success={this.state.success}
				/>
			</React.Fragment>
		);
	}
}

// @ts-ignore
export default withGroupService(CreateGroup);
