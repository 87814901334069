/** @typedef {import('./Props').default} Props */

import { toAbsoluteUrl } from '../../../../../utils/toAbsoluteUrl';
import { translate } from '../../../../../App/Internationalization';
import images from '../../../../../config/local/images';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class PropertyImage extends React.Component {
	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const image = this.props.image;

		return (
			<figure>
				<img
					alt={translate(
						'homeowner.pages.dashboard.properties.property_image_alt'
					)}
					className="image rounded w-full h-full"
					src={toAbsoluteUrl(
						image ?? images.homeowners.properties.streetView
					)}
				/>
			</figure>
		);
	}
}
