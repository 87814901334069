/**
 * @typedef {import('../../../Auth/AuthService').default} AuthService
 * @typedef {import('@mooveguru/js-http-client').BrowserClient} HttpClient
 * @typedef {import('./TaskType').MovingTaskType} MovingTaskType
 * @typedef {import('./TaskType').OnboardingTaskType} OnboardingTaskType
 * @typedef {import('./Task').default} Task
 * @typedef {import('./TaskResponse').default} TaskResponse
 */
import apiUrls from 'config/local/api-urls';
import movingTasks from '@mooveguru/yhh-shared-config/moving-task-types.json';
import onboardingTasks from '@mooveguru/yhh-shared-config/onboarding-task-types.json';
export default class TaskService {
	/** @param {{authService: AuthService, httpClient: HttpClient }} dependencies */
	constructor(dependencies) {
		this.authService = dependencies.authService;
		this.httpClient = dependencies.httpClient;
	}

	/**
	 * @returns {Promise<Task[]>}
	 */
	async getOnboardingTasks() {
		const response = await this.httpClient.get(
			apiUrls.me.tasks.onboarding.root,
			new Headers({
				Authorization: `Bearer ${this.authService.accessToken}`,
				'Content-Type': 'application/json',
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const results = response.body;

		return results
			.map(
				/**
				 * @param {TaskResponse} task
				 * @returns {Task}
				 */
				(task) => {
					const taskType = TaskService.#getOnboardingTask(
						task.type_id
					);

					return {
						id: task.id,
						isComplete: task.completed_at !== null,
						name: taskType.name.en,
						step: taskType.step,
					};
				}
			)
			.sort(
				/**
				 * @param {Task} taskA
				 * @param {Task} taskB
				 * @returns {number}
				 */
				(taskA, taskB) => taskA.step - taskB.step
			);
	}

	/**
	 * @param {string} propertyId
	 * @returns {Promise<Task[]>}
	 */
	async getMovingTasks(propertyId) {
		const response = await this.httpClient.get(
			`${apiUrls.me.tasks.moving.root}/${propertyId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.accessToken}`,
				'Content-Type': 'application/json',
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const results = response.body;

		return results
			.map(
				/**
				 * @param {TaskResponse} task
				 * @returns {Task}
				 */ (task) => {
					const taskType = TaskService.#getMovingTask(task.type_id);

					return {
						id: task.id,
						isComplete: task.completed_at !== null,
						name: taskType.name.en,
						step: taskType.step,
					};
				}
			)
			.sort(
				/**
				 * @param {Task} taskA
				 * @param {Task} taskB
				 * @returns {number}
				 */
				(taskA, taskB) => taskA.step - taskB.step
			);
	}

	/**
	 * @param {?Date} completedAt
	 * @param {string} taskId
	 * @returns {Promise<void>}
	 */
	async updateTask(completedAt, taskId) {
		const response = await this.httpClient.put(
			`${apiUrls.tasks.update}/${taskId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.accessToken}`,
				'Content-Type': 'application/json',
			}),
			// eslint-disable-next-line camelcase
			{ completed_at: completedAt }
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}
	}

	/**
	 * @param {string} taskId
	 * @returns {Promise<void>}
	 */
	async completeTask(taskId) {
		await this.updateTask(new Date(), taskId);
	}

	/**
	 * @param {string} taskId
	 * @returns {Promise<void>}
	 */
	async undoTask(taskId) {
		await this.updateTask(null, taskId);
	}

	/**
	 * @param {number} typeId
	 * @returns {OnboardingTaskType}
	 * @throws {Error}
	 */
	static #getOnboardingTask(typeId) {
		const task = onboardingTasks.find((type) => type.id === typeId);

		if (task === undefined) {
			throw new Error(`Missing type for task with type ID ${typeId}.`);
		}

		return task;
	}

	/**
	 * @param {number} typeId
	 * @returns {MovingTaskType}
	 * @throws {Error}
	 */
	static #getMovingTask(typeId) {
		const task = movingTasks.find((type) => type.id === typeId);

		if (task === undefined) {
			throw new Error(`Missing type for task with type ID ${typeId}.`);
		}

		return task;
	}
}
