/**
 * @typedef {import('shared/MarketplaceServiceActivityProxy/Dependencies').default} Dependencies
 * @typedef {import('../MarketplaceService/Vendor').Filter} Filter
 * @typedef {import('shared/MarketplaceService/GetsSuggestedVendorCategories').default} GetsSuggestedVendorCategories
 * @typedef {import('shared/MarketplaceService/GetsVendors').default} GetsVendors
 * @typedef {import('shared/MarketplaceService/Vendor').default} Vendor
 */
/**
 * @implements {GetsSuggestedVendorCategories}
 * @implements {GetsVendors}
 */
export default class MarketplaceServiceActivityProxy {
	/** @param {Dependencies} dependencies */
	constructor(dependencies) {
		this.activityService = dependencies.activityService;
		this.marketplaceService = dependencies.marketplaceService;
	}

	/**
	 * @param {string} query
	 * @returns {Promise<string[]>}
	 */
	async getSuggestedVendorCategories(query) {
		return this.marketplaceService.getSuggestedVendorCategories(query);
	}

	/**
	 * @param {string} query
	 * @param {string} postalCode
	 * @param {number} [page]
	 * @param {Filter} [filter]
	 * @returns {Promise<{readonly pages: number, readonly vendors: readonly Vendor[]}>}
	 */
	async getVendors(query, postalCode, page, filter) {
		if (this.activityService.identifiesKeywords(query)) {
			this.activityService.logActivity(
				query,
				'marketplace_search_keywords_highpoint'
			);
		}

		return this.marketplaceService.getVendors(
			query,
			postalCode,
			page,
			filter
		);
	}
}
