/**
 * @typedef {import('./PropertyValuationComponent').Props} Props
 * @typedef {import('./PropertyValuationComponent').State} State
 */
import { translate } from '../../Internationalization';
import ConfidenceChart from './ConfidenceChart';
import React from 'react';
import formatCentsForDisplay from '../../../utils/formatCentsForDisplay';

/** @extends {React.Component<Props, State>} */
export default class PropertyValueChart extends React.Component {
	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<React.Fragment>
				<ul className="list-group divided bulleted-none mt-4">
					<li className="list-item">
						<div className="justified-row">
							<h4 className="hl-ms">
								{translate(
									'homeowner.pages.finances.table.low_value'
								)}
							</h4>
							<div className="price price-light">
								{formatCentsForDisplay(
									this.props.valuation.valueLow,
									0
								)}
							</div>
						</div>
					</li>
					<li className="list-item">
						<div className="justified-row">
							<h4 className="hl-ms">
								{translate(
									'homeowner.pages.finances.table.high_value'
								)}
							</h4>
							<div className="price price-light">
								{formatCentsForDisplay(
									this.props.valuation.valueHigh,
									0
								)}
							</div>
						</div>
					</li>
				</ul>

				<div className="justified-row border-t border-grey-100 pt-2">
					<h4 className="h-ms">
						{translate(
							'homeowner.pages.finances.table.current_value'
						)}
					</h4>
					<div className="price">
						{formatCentsForDisplay(
							this.props.valuation.valueMean,
							0
						)}
					</div>
				</div>

				<section className="justified-row mt-6 mb-0">
					<section className="mr-5">
						<h3 className="h-ms-1">
							{translate(
								'homeowner.pages.finances.view.estimate.confidence'
							)}
						</h3>

						<p className="text-sm my-2">
							{translate(
								'homeowner.pages.finances.view.estimate.confidence_description'
							)}
						</p>
					</section>

					<figure className="graph w-25">
						<ConfidenceChart
							confidence={this.props.valuation.confidence}
						/>
					</figure>
				</section>
			</React.Fragment>
		);
	}
}
