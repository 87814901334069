import { translate } from 'App/Internationalization';
import FormField from 'shared/Forms/Fields/FormField';
import InputLastName from 'shared/Forms/Inputs/Name/InputLastName';
import React from 'react';

export default class FieldLastName extends React.Component {
	render() {
		const label = translate('global.forms.inputs.name.last.label');

		return (
			<FormField
				className={this.props.className}
				inputName={this.props.name}
				labelClass={this.props.labelClass}
				label={this.props.label ?? label}
				name={this.props.name}
				required={this.props.required}
			>
				<InputLastName
					className={this.props.inputClass}
					disabled={this.props.disabled}
					name={this.props.name}
					required={this.props.required}
				/>
			</FormField>
		);
	}
}

FieldLastName.defaultProps = {
	className: InputLastName.defaultProps.className,
	disabled: InputLastName.defaultProps.disabled,
	inputClass: InputLastName.defaultProps.className,
	labelClass: FormField.defaultProps.labelClass,
	name: InputLastName.defaultProps.name,
	required: InputLastName.defaultProps.required,
};
