import React from 'react';
import { Field } from 'formik';
import AsyncSelect from 'react-select/async';
import mergeClassNames from 'utils/mergeClassNames';
export default class InputSearchableSelectAsync extends React.Component {
	static defaultProps = {
		defaultValue: {
			initial: true,
			label: 'Select...',
			value: '',
		},
		isClearable: false,
		isMultipleSelect: false,
	};

	constructor(props) {
		super(props);

		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	onChange(event, formik) {
		formik.form.setFieldValue(this.props.name, event?.value ?? null);
	}

	onBlur(formik) {
		formik.form.setFieldTouched(this.props.name, true);
	}

	render() {
		return (
			<Field name={this.props.name}>
				{(renderProps) => (
					<AsyncSelect
						cacheOptions={this.props.cacheOptions}
						className={mergeClassNames(
							'search-select',
							this.props.inputClass
						)}
						classNamePrefix="search-select"
						defaultOptions={this.props.defaultOptions}
						defaultValue={this.props.defaultValue}
						id={this.props.id ?? this.props.name}
						isClearable={this.props.isClearable}
						isMulti={this.props.isMultipleSelect}
						loadOptions={this.props.loadOptions}
						name={this.props.name}
						onBlur={
							this.props.onBlur
								? (event) => this.props.onChange(event, renderProps) // prettier-ignore
								: () => this.onBlur(renderProps)
						}
						onChange={
							this.props.onChange
								? (event) => this.props.onChange(event, renderProps) // prettier-ignore
								: (event) => this.onChange(event, renderProps)
						}
					/>
				)}
			</Field>
		);
	}
}
