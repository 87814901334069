import { translate } from 'App/Internationalization';
import BaseButton from '../../shared/BaseButton/BaseButton';
import React from 'react';

export default class ResendInviteButton extends React.Component {
	render() {
		return (
			<BaseButton
				icon={this.props.icon}
				onClick={this.props.onResendInvite}
				outline={this.props.outline}
				size={this.props.size}
			>
				{translate('admin.pages.admins.invite')}
			</BaseButton>
		);
	}
}
