const baseUrl = 'https://home.homekeepr.com/wp-json/wp/v2/posts';
import config from '../../config/local/blog';
import extractHtmlAttributeValue from 'utils/extractHtmlElementAttributeValue';
import images from '../../config/local/images';

/** @typedef {import('shared/BlogService/BlogPost').default} BlogPost */
/** @typedef {import('shared/BlogService/BlogPostExcerpt').default} BlogPostExcerpt */
/** @typedef {import('shared/BlogService/GetsBlogPost').default} GetsBlogPost */
/** @typedef {import('shared/BlogService/GetsBlogPostExcerpts').default} GetsBlogPostExcerpts */
/** @typedef {import('shared/BlogService/GetsLatestBlogPostExcerpt').default} GetsLatestBlogPostExcerpt */
/** @typedef {import('@mooveguru/js-http-client/GetsHttp').default} GetsHttp */

/**
 * @implements {GetsBlogPostExcerpts}
 * @implements {GetsBlogPost}
 * @implements {GetsLatestBlogPostExcerpt}
 */
export default class BlogService {
	/** @param {GetsHttp} httpClient */
	constructor(httpClient) {
		this.httpClient = httpClient;
	}

	/** @returns {Promise<?BlogPostExcerpt>} */
	async getLatestPostExcerpt() {
		const response = await this.httpClient.get(`${baseUrl}?per_page=1`);

		if (!response.isOk) {
			throw new Error(
				'Something went wrong getting your latest blog post excerpt.'
			);
		}

		const data = response.body[0];

		if (data === undefined) {
			return null;
		}

		return {
			author: {
				headshotUrl: extractHtmlAttributeValue(
					data.author.image,
					'src'
				),
				name: data.author.display_name,
			},
			content: data.excerpt.rendered,
			date: new Date(data.date),
			imageUrl:
				data.featured_media.images?.medium_large?.src ??
				images.blog.default.medium,
			slug: data.slug,
			title: data.title.rendered,
		};
	}

	/**
	 * @param {string} slug
	 * @returns {Promise<?BlogPost>}
	 */
	async getPost(slug) {
		const response = await this.httpClient.get(`${baseUrl}?slug=${slug}`);

		if (!response.isOk) {
			throw new Error('Something went wrong getting your blog post.');
		}

		const data = response.body[0];

		if (data === undefined) {
			return null;
		}

		return {
			author: {
				headshotUrl: extractHtmlAttributeValue(
					data.author.image,
					'src'
				),
				name: data.author.display_name,
			},
			content: data.content.rendered,
			date: new Date(data.date),
			imageUrl:
				data.featured_media.images?.medium_large?.src ??
				images.blog.default.large,
			title: data.title.rendered,
		};
	}

	/**
	 * @param {string} searchQuery
	 * @param {number} page
	 * @param {number} [perPage]
	 * @returns {Promise<{posts: BlogPostExcerpt[], totalPages: number}>}
	 */
	async getPostExcerpts(searchQuery, page, perPage = config.perPage.listing) {
		const searchParamsBuilder = new URLSearchParams({
			/* eslint-disable-next-line camelcase -- Camelcase necessary for query param */
			per_page: `${perPage}`,
		});

		if (searchQuery) {
			searchParamsBuilder.append('search', searchQuery);
		}

		if (page) {
			searchParamsBuilder.append('page', page.toString());
		}

		const searchParams = searchParamsBuilder.toString();
		const url =
			searchParams === '' ? baseUrl : `${baseUrl}?${searchParams}`;
		const response = await this.httpClient.get(url);

		if (!response.isOk) {
			throw new Error(
				'Something went wrong getting your blog post excerpts.'
			);
		}

		return {
			posts: response.body.map((data) => ({
				author: {
					headshotUrl: extractHtmlAttributeValue(
						data.author.image,
						'src'
					),
					name: data.author.display_name,
				},
				content: data.excerpt.rendered,
				date: new Date(data.date),
				imageUrl:
					data.featured_media.images?.medium_large?.src ??
					images.blog.default.medium,
				slug: data.slug,
				title: data.title.rendered,
			})),
			// @ts-ignore: Unary + converts null to 0
			totalPages: +response.headers.get('X-WP-TotalPages'),
		};
	}
}
