/**
 * @typedef {import('./ThemeFormProps').default} Props
 * @typedef {import('./ThemeMessage').default} Message
 * @typedef {import('../../../shared/ThemeService/ThemeColors').default} ThemeColors
 */
import BaseForm from '../../../shared/Forms/BaseForm';
import BaseInput from '../../../shared/Forms/Inputs/BaseInput';
import FileUploadForm from '../../../shared/Forms/FileUploadForm';
import FormSuccessMessages from '../../../shared/Forms/Messages/FormSuccessMessages';
import FormWarningMessages from '../../../shared/Forms/Messages/FormWarningMessages';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import React from 'react';
import SubmitButton from '../../../shared/Forms/Inputs/SubmitButton';
import uploadSize from '@mooveguru/yhh-shared-config/files/max-upload.json';

/** @extends {React.Component<Props>} */
export default class ThemeForm extends React.Component {
	/**
	 * @param {Message} message
	 * @param {boolean} success
	 * @returns {string[]}
	 */
	static #resolveMessage(message, success) {
		// @ts-ignore Filters out falsey values, so it can never return `null` values
		return [message]
			.filter((messageObject) => messageObject.success === success)
			.map((messageObject) => messageObject.message)
			.filter(Boolean);
	}

	render() {
		const buttonInputList = [
			['main', 'Main'],
			['dark', 'Down-Shade'],
			['light', 'Up-Shade'],
		];

		return (
			<React.Fragment>
				<BaseForm
					className="mb-10"
					initialValues={this.props.initialColorValues}
					noRequiredText={true}
					onSubmit={this.props.handleColorsSubmit}
				>
					<fieldset className="mb-4">
						<legend className="h-ms-5 mb-6">{'Colors'}</legend>

						<section className="flex text-center mb-6">
							{buttonInputList.map(([name, label]) => (
								<div className="input-group mr-10" key={name}>
									<div className="input-color mb-2 mx-auto">
										<BaseInput name={name} type="color" />
									</div>

									<label
										className="input-label"
										htmlFor={name}
									>
										{label}
									</label>
								</div>
							))}
						</section>
					</fieldset>

					<SubmitButton>{'Save Colors'}</SubmitButton>

					<FormWarningMessages
						messages={ThemeForm.#resolveMessage(
							this.props.colorsMessage,
							false
						)}
					/>

					<FormSuccessMessages
						messages={ThemeForm.#resolveMessage(
							this.props.colorsMessage,
							true
						)}
					/>
				</BaseForm>

				<FileUploadForm
					accept={[...Object.values(mimeList.images)]}
					className="mb-10"
					label="Logo"
					maxSize={uploadSize.image}
					messages={{
						successes: ThemeForm.#resolveMessage(
							this.props.logoMessage,
							true
						),
						warnings: ThemeForm.#resolveMessage(
							this.props.logoMessage,
							false
						),
					}}
					name="logo"
					onChange={this.props.handleLogoChange}
					onSubmit={this.props.handleLogoSubmit}
					preview={this.props.logoPreview}
					submit="Save Logo"
				/>

				<FileUploadForm
					accept={mimeList.icons.ico}
					className="mb-10"
					label="FavIcon"
					maxSize={uploadSize.favicon}
					messages={{
						successes: ThemeForm.#resolveMessage(
							this.props.faviconMessage,
							true
						),
						warnings: ThemeForm.#resolveMessage(
							this.props.faviconMessage,
							false
						),
					}}
					name="favicon"
					onChange={this.props.handleFaviconChange}
					onSubmit={this.props.handleFaviconSubmit}
					preview={this.props.faviconPreview}
					submit="Save FavIcon"
				/>
			</React.Fragment>
		);
	}
}
