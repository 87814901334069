/**
 * @typedef {import('./SignIn').Props} Props
 * @typedef {import('./SignIn').State} State
 */

import { Link } from 'react-router-dom';
import { resetPasswordSegment } from 'config/local/paths';
import { translate } from 'App/Internationalization';
import * as validators from 'shared/validators';
import * as yup from 'yup';
import AppLogo from 'App/shared/AppLogo';
import BaseForm from 'shared/Forms/BaseForm';
import FieldEmail from 'shared/Forms/Fields/FieldEmail';
import FieldPassword from '../shared/Forms/Fields/FieldPassword';
import FormErrorMessages from 'shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from 'shared/Forms/Messages/FormSuccessMessages';
import paths from 'config/local/paths';
import React from 'react';
import SubmitButton from 'shared/Forms/Inputs/SubmitButton';

const initialFormValues = { email: '', password: '' };

const validationSchema = yup.object().shape({
	email: validators.email,
});

/** @extends {React.Component<Props, State>} */
export default class SignIn extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			error: this.props.location?.state?.error ?? '',
			errorHeading: null,
			message: this.props.location?.state?.message ?? null,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	/**
	 * @param {{email: string, password: string}} values
	 * @returns {Promise<void>}
	 */
	async handleSubmit(values) {
		try {
			await this.props.auth.signIn(values.email, values.password);
			/**
			 * @param {object} error
			 */
		} catch (error) {
			this.setState({
				error: error.errors ?? [error.message],
				errorHeading: translate('sign_in.authentication_failed'),
			});
		}
	}

	render() {
		return (
			<section className="app-login">
				<div className="login-container">
					<AppLogo />

					<BaseForm
						className="mt-12"
						initialValues={initialFormValues}
						noRequiredText="true"
						onSubmit={this.handleSubmit}
						validationSchema={validationSchema}
					>
						<FormSuccessMessages messages={[this.state.message]} />
						<section className="input-group">
							<FieldEmail />
						</section>

						<section className="input-group mt-4">
							<FieldPassword inputName={'password-reset'} />
						</section>

						<div className="mt-10">
							<SubmitButton icon="">
								{translate('sign_in.button')}
							</SubmitButton>
							<FormErrorMessages
								heading={
									this.props.location?.state?.errorHeading ??
									this.state.errorHeading
								}
								messages={this.state.error}
							/>
						</div>
					</BaseForm>

					<section className="mt-18">
						<Link className="link" to={resetPasswordSegment}>
							{translate('sign_in.password')}
						</Link>

						<Link
							className="link mt-6"
							to={{
								pathname: paths.register.root,
								state: {
									referrer: this.props.redirectPath,
								},
							}}
						>
							{translate('sign_in.register')}
						</Link>

						<footer className="text-sm text-center mt-24">
							{translate('global.footer.powered_by', 'MooveGuru')}
						</footer>
					</section>
				</div>
			</section>
		);
	}
}
