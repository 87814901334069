/**
 * @param {string} token
 * @returns {import('jsonwebtoken').JwtPayload}
 */
export default function getJwtPayload(token) {
	const [, base64UrlPayload] = token.split('.');
	const base64Payload = convertBase64UrlToBase64(base64UrlPayload);
	const decodedPayload = atob(base64Payload);
	const jsonPayload = decodeURIComponent(decodedPayload);
	return JSON.parse(jsonPayload);
}

/**
 * @param {string} value
 * @returns {string}
 */
function convertBase64UrlToBase64(value) {
	return value.replace(/-/g, '+').replace(/_/g, '/');
}
