import { toAbsoluteUrl } from '../utils/toAbsoluteUrl';
import InlineSVG from 'react-inlinesvg';
import React from 'react';

export default class ExternalAnchor extends React.Component {
	/** @returns {JSX.Element} */
	render() {
		let anchorClass = 'link';

		if (this.props.icon) {
			anchorClass = 'link-icon';
		}

		return (
			<a
				className={this.props.className ?? anchorClass}
				data-activity-click={this.props.dataActivityClick}
				data-activity-id={this.props.dataActivityId}
				href={this.props.href}
				rel={this.props.rel}
				target={this.props.target}
				title={this.props.title}
			>
				{this.props.renderSpan ? (
					<span>{this.props.children}</span>
				) : (
					this.props.children
				)}
				{this.props.icon ? (
					<i className={this.props.iconClass}>
						<InlineSVG src={toAbsoluteUrl(this.props.icon)} />
					</i>
				) : null}
			</a>
		);
	}
}

ExternalAnchor.defaultProps = {
	iconClass: 'icon icon-fill',
	rel: 'noreferrer noopener',
	renderSpan: true,
	target: '_blank',
};
