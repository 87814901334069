import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import paths from 'config/local/paths';
import { AuthContext } from 'shared/AuthProvider';

export default class ProtectedRoute extends React.Component {
	render() {
		if (this.context.accessToken === null) {
			return (
				<Redirect
					to={{
						pathname: paths.auth.signIn,
						state: {
							referrer: `${location.pathname}${location.search}${location.hash}`,
						},
					}}
				/>
			);
		}

		return (
			<Route component={this.props.component} path={this.props.path} />
		);
	}
}

ProtectedRoute.contextType = AuthContext;
