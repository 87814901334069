/**
 * @typedef {import('./HomeownerComponent').Props} Props
 * @typedef {import('./HomeownerComponent').State} State
 */
import { AuthContext } from 'shared/AuthProvider';
import { Redirect } from 'react-router-dom';
import { translate } from 'App/Internationalization';
import {
	withMarketplaceServiceActivityProxy,
	withPropertyService,
} from '../../service-container';
import { withTheme } from 'shared/ThemeProvider';
import AppLayout from 'App/AppLayout';
import ApplianceCenter from 'App/Homeowner/ApplianceCenter';
import Blog from 'Blog';
import captureError from 'utils/captureError';
import Checklists from 'App/Homeowner/Checklists';
import Dashboard from 'App/Homeowner/Dashboard';
import Documents from 'App/Homeowner/Documents';
import Finances from 'App/Homeowner/Finances';
import Inventory from 'App/Homeowner/Inventory';
import LoadingScreen from '../../shared/LoadingScreen';
import Maintenance from 'App/Homeowner/Maintenance';
import Marketplace from 'Marketplace';
import Page from 'shared/Page';
import paths from 'config/local/paths';
import Projects from 'App/Homeowner/Projects';
import Properties from 'App/Homeowner/Properties';
import PropertyProvider, { withProperty } from 'App/Homeowner/PropertyProvider';
import React from 'react';
import Switch from 'shared/Switch';
import UpcomingItems from 'App/Homeowner/UpcomingItems';
import Utilities from 'App/Homeowner/Utilities';
import Feature from '../../shared/Feature';

const InjectedDashboard = withTheme(withProperty(Dashboard));
const InjectedMarketplace = withMarketplaceServiceActivityProxy(
	// @ts-ignore -- TODO: Fix service container types
	withProperty(Marketplace)
);
const InjectedFinances = withProperty(Finances);
const InjectedProjects = withProperty(Projects);

/** @extends {React.Component<Props, State>} */
class Homeowner extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/**
		 * @type {State}
		 */
		this.state = {
			errorMessage: null,
			isGettingData: true,
			propertyList: [],
		};
	}

	/**
	 * @param {unknown} error
	 * @returns {void}
	 */
	#error(error) {
		captureError(error);

		this.setState({ errorMessage: translate('global.error') });
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		this.context.setModule('homeowner');

		try {
			await Promise.all([
				this.props.theme.getAndSetActiveTheme('homeowner'),
				this.props.theme.getAndSetHomeownerAgent(),
				this.props.theme.getAndSetHomeownerTeam(),
				this.#getPropertyList(),
			]);

			this.setState({
				isGettingData: false,
			});
		} catch (error) {
			this.#error(error);
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #getPropertyList() {
		try {
			const propertyList =
				await this.props.propertyService.getHomeownerProperties();

			this.setState({
				propertyList: propertyList,
			});
		} catch (error) {
			this.#error(error);
		}
	}

	/**
	 * @returns {React.ReactElement}
	 */
	// eslint-disable-next-line max-lines-per-function
	render() {
		if (this.state.errorMessage !== null) {
			return <p>{this.state.errorMessage}</p>;
		}

		if (this.state.isGettingData) {
			return <LoadingScreen />;
		}

		if (
			this.state.propertyList.length === 0 &&
			this.props.location.pathname !== paths.app.homeowner.properties.add
		) {
			return <Redirect to={paths.app.homeowner.properties.add} />;
		}

		return (
			<PropertyProvider>
				<AppLayout
					location={this.props.location}
					show={{
						agent: !!this.props.theme.agentEmail,
						property: true,
					}}
				>
					<Switch>
						<Redirect
							exact={true}
							from={paths.app.homeowner.root}
							to={paths.app.homeowner.dashboard}
						/>
						<Page
							component={InjectedDashboard}
							path={paths.app.homeowner.dashboard}
							title={translate('homeowner.nav.main.dashboard')}
							withContainer={true}
						/>
						<Page
							component={Checklists}
							path={paths.app.homeowner.checklists}
							title={translate('homeowner.nav.main.checklists')}
							withContainer={true}
						/>
						<Page
							component={UpcomingItems}
							path={paths.app.homeowner.upcomingItems}
							title={translate(
								'homeowner.nav.main.upcoming_items'
							)}
							withContainer={true}
						/>
						<Page
							component={InjectedMarketplace}
							path={paths.app.homeowner.marketplace}
							title={translate('homeowner.nav.main.marketplace')}
							withContainer={true}
						/>
						<Page
							component={Properties}
							path={paths.app.homeowner.properties.root}
							title={translate('homeowner.nav.main.properties')}
							withContainer={true}
						/>
						<Page
							component={Documents}
							path={paths.app.homeowner.documents.root}
							title={translate('homeowner.nav.main.documents')}
							withContainer={true}
						/>
						<Page
							component={ApplianceCenter}
							path={paths.app.homeowner.applianceCenter.root}
							title={translate(
								'homeowner.nav.main.appliance_center'
							)}
							withContainer={true}
						/>
						<Page
							component={Maintenance}
							path={paths.app.homeowner.maintenance}
							title={translate('homeowner.nav.main.maintenance')}
							withContainer={true}
						/>
						<Page
							component={Utilities}
							path={paths.app.homeowner.utilities}
							title={translate('homeowner.nav.main.utilities')}
							withContainer={true}
						/>
						<Page
							component={Inventory}
							path={paths.app.homeowner.inventory}
							title={translate('homeowner.nav.main.inventory')}
							withContainer={true}
						/>
						<Page
							component={InjectedFinances}
							path={paths.app.homeowner.finances}
							title={translate('homeowner.nav.main.finances')}
							withContainer={true}
						/>
						<Page
							component={Blog}
							path={paths.app.homeowner.blog}
							title={translate('homeowner.nav.main.blog')}
						/>
						<Feature
							fallback={
								<Redirect
									exact={true}
									from={paths.app.homeowner.root}
									to={paths.app.homeowner.dashboard}
								/>
							}
							name="homeowner:projects"
						>
							<Page
								// @ts-ignore Will not be `undefined` if feature is enabled
								component={InjectedProjects}
								path={paths.app.homeowner.projects}
								title={translate(
									'homeowner.nav.main.marketplace'
								)}
								withContainer={true}
							/>
						</Feature>
					</Switch>
				</AppLayout>
			</PropertyProvider>
		);
	}
}

Homeowner.contextType = AuthContext;

// @ts-ignore -- TODO: Fix service container types
export default withPropertyService(Homeowner);
