/**
 * @typedef {import('./GetsUserEmail').default} GetsEmail
 * @typedef {import('./GetsUserName').default} GetsUserName
 * @typedef {import('@mooveguru/js-http-client').BrowserClient} HttpClient
 * @typedef {import('../../shared/AuthProvider/Roles').Roles} Roles
 * @typedef {import('./SetsPassword').default} SetsPassword
 */

import apiUrls from '../../config/local/api-urls';
import DataLayer from '../../DataLayer';

/**
 * @implements {GetsEmail}
 * @implements {GetsUserName}
 * @implements {SetsPassword}
 */
export default class UserService {
	/** @param {HttpClient} httpClient */
	constructor(httpClient) {
		this.baseHeaders = new Headers({ 'Content-Type': 'application/json' });
		this.httpClient = httpClient;
	}

	/**
	 * @param {Readonly<{currentPassword: string, newPassword: string}>} parameters
	 * @param {string} accessToken
	 * @returns {Promise<void>}
	 * @throws {Error}
	 */
	async changePassword(parameters, accessToken) {
		const headers = new Headers(this.baseHeaders);
		headers.set('X-Access-Token', accessToken);

		const response = await this.httpClient.put(
			apiUrls.user.password,
			headers,
			{
				// eslint-disable-next-line camelcase -- passing into body
				current_password: parameters.currentPassword,
				// eslint-disable-next-line camelcase -- passing into body
				new_password: parameters.newPassword,
				/* eslint-enable camelcase */
			}
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}
	}

	/**
	 * @param {string} email
	 * @param {string} accessToken
	 * @returns {Promise<void>}
	 * @throws {Error}
	 */
	async changeUserEmail(email, accessToken) {
		const headers = new Headers(this.baseHeaders);
		headers.set('X-Access-Token', accessToken);

		const response = await this.httpClient.post(apiUrls.me.email, headers, {
			email,
		});

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		DataLayer.set('userEmail', email);
	}

	/**
	 * @param {string} accessToken
	 * @returns {Promise<string>}
	 * @throws {Error}
	 */
	async getUserEmail(accessToken) {
		const headers = new Headers(this.baseHeaders);
		headers.set('X-Access-Token', accessToken);

		const response = await this.httpClient.get(apiUrls.me.email, headers);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		DataLayer.set('userEmail', response.body.results.email);

		return response.body.results.email;
	}

	/**
	 * @todo Filter user roles on the backend. Modify this endpoint when backend changes are complete.
	 * @param {string} accessToken
	 * @returns {Promise<string>}
	 * @throws {Error}
	 */
	async getUserName(accessToken) {
		const headers = new Headers(this.baseHeaders);
		headers.set('X-Access-Token', accessToken);

		const response = await this.httpClient.get(apiUrls.me.info, headers);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		if (response.body.results.meta.administrator) {
			return response.body.results.meta.administrator.name.first;
		}

		if (response.body.results.meta.agent) {
			return response.body.results.meta.agent.name.first;
		}

		if (response.body.results.meta.homeowner) {
			return response.body.results.meta.homeowner.name.first;
		}

		return '';
	}

	/**
	 * @param {string} accessToken
	 * @returns {Promise<Roles[]>}
	 * @throws {Error}
	 */
	async getUserRoles(accessToken) {
		const headers = new Headers(this.baseHeaders);
		headers.set('X-Access-Token', accessToken);

		const response = await this.httpClient.get(apiUrls.me.info, headers);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		return response.body.results.meta.roles;
	}

	/**
	 * @param {string} password
	 * @param {string | null} token
	 * @returns {Promise<void>}
	 */
	async setPassword(password, token) {
		const response = await this.httpClient.post(
			apiUrls.user.setPassword,
			new Headers(this.baseHeaders),
			{ password, token }
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}
	}
}
