/** @typedef {import('./Dependencies').default} Dependencies */
/** @typedef {import('./Address').default} Address */

import apiUrls from 'config/local/api-urls';

export default class AddressService {
	/** @param {Dependencies} dependencies */
	constructor(dependencies) {
		this.httpClient = dependencies.httpClient;
		this.authService = dependencies.authService;
	}

	/**
	 * @param {Address} values
	 * @returns {Promise<Address>}
	 */
	async getAddressSuggestion(values) {
		const response = await this.httpClient.post(
			apiUrls.address.validate,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			{
				street_address_1: values.streetAddress1,
				street_address_2: values.streetAddress2,
				city: values.city,
				state: values.state,
				country: values.country,
				postal_code: values.postalCode,
			}
		);

		return response.body ?? null;
	}
}
