/**
 * @typedef {import('../../../config/local/Import/Data').default<'agent'>} ImportData
 * @typedef {import('./Agent').AdBanner} AdBanner
 * @typedef {import('./Agent').AdBannerData} AdBannerData
 * @typedef {import('./Agent').AgentPersonalData} AgentPersonalData
 * @typedef {import('./Agent').AgentSettings} AgentSettings
 * @typedef {import('./Agent').default} Agent
 * @typedef {import('./Dependencies').default} Dependencies
 * @typedef {import('./GetsAgent').default} GetsAgent
 * @typedef {import('./GetsAgentBySlug').default} GetsAgentBySlug
 * @typedef {import('./GetsAgentCount').default} GetsAgentCount
 * @typedef {import('./GetsAgents').default} GetsAgents
 * @typedef {import('./GetsAgentSettings').default} GetsAgentSettings
 * @typedef {import('./GetsAgentUpgradedCount').default} GetsAgentUpgradedCount
 * @typedef {import('./GetsValuations').default} GetsValuations
 * @typedef {import('./ImportsAgents').default} ImportsAgents
 * @typedef {import('./UpdatesContactCommunicationPreferences').default} UpdatesContactCommunicationPreferences
 * @typedef {import('./ValuationData').ValuationDataRequest} ValuationDataRequest
 * @typedef {import('./ValuationData').ValuationDataResponse} ValuationDataResponse
 */

import apiUrls from '../../../config/local/api-urls';
import formatPhoneNumberForData from '../../../utils/formatPhoneNumberForData';
import mapInputToFormData from '../../../utils/mapInputToFormData';
import ServiceError from '../../../shared/Errors/ServiceError';

/**
 * @implements {GetsAgent}
 * @implements {GetsAgentBySlug}
 * @implements {GetsAgents}
 * @implements {GetsAgentSettings}
 * @implements {GetsAgentUpgradedCount}
 * @implements {GetsValuations}
 * @implements {ImportsAgents}
 * @implements {UpdatesContactCommunicationPreferences}
 */
export default class AgentService {
	/** @param {Dependencies} dependencies */
	constructor(dependencies) {
		this.authService = dependencies.authService;
		this.httpClient = dependencies.httpClient;
	}

	/**
	 * @param {string} agentId
	 * @returns {Promise<Agent?>}
	 */
	async getAgent(agentId) {
		const response = await this.httpClient.get(
			`${apiUrls.agents.root}/${agentId}`
		);

		if (response.statusCode === 404 || response.statusCode === 444) {
			return null;
		}

		if (!response.isOk) {
			throw new ServiceError(
				'There was a problem getting your agent.',
				response.body
			);
		}

		return {
			emailAddress: response.body.email_address,
			facebookUsername: response.body.facebook_username,
			groups: response.body.groups.map(
				/**
				 * @param {{id: string, is_primary: boolean, name: string }} group
				 */
				(group) => ({
					id: group.id,
					isPrimary: group.is_primary,
					name: group.name,
				})
			),
			id: response.body.id,
			name: {
				...response.body.name,
				full: AgentService.#generateFullName(response.body.name),
			},
			phoneNumbers: response.body.phone_numbers.map(
				/**
				 * @param {{ is_primary: boolean, type: string, value: string }} phoneNumber
				 */
				(phoneNumber) => ({
					isPrimary: phoneNumber.is_primary,
					type: phoneNumber.type,
					value: phoneNumber.value,
				})
			),
			realEstateLicense: response.body.real_estate_license
				? { number: response.body.real_estate_license.number }
				: null,
			slug: response.body.slug,
			twitter: response.body.twitter_handle,
			websiteUrl: response.body.website_url,
		};
	}

	/** @param {string} slug */
	// eslint-disable-next-line complexity
	async getAgentBySlug(slug) {
		const response = await this.httpClient.get(
			`${apiUrls.agent.getInfo}/${slug}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const results = response.body.results;

		return {
			active: results.active,
			email: results?.email ?? '',
			facebook: results?.social_media?.facebook ?? '',
			firstName: results?.name?.first ?? '',
			groups:
				results?.groups?.map(
					/**
					 * @param {{_id: string, is_primary: boolean, name: string}} group
					 */
					(group) => ({
						id: group._id,
						isPrimary: group.is_primary,
						name: group.name,
					})
				) ?? [],
			headshot: results.headshot ?? '',
			id: results?._id,
			landlinePhoneNumber: results?.phone?.landline ?? '',
			lastName: results?.name?.last ?? '',
			mobilePhoneNumber: results?.phone?.mobile ?? '',
			realEstateLicense: results?.license_number ?? '',
			twitter: results?.social_media?.twitter ?? '',
			userId: results.user_id,
			website: results?.website ?? '',
		};
	}
	// eslint-enable-next-line complexity

	/**
	 * @param {string} accessToken
	 * @param {number} [page]
	 * @returns {Promise<{ data: Agent[], pages: number, total: number }>}
	 * @throws {Error}
	 */
	async getAgents(accessToken, page = 1) {
		const response = await this.httpClient.get(
			`${apiUrls.me.administrator.agents}/?page=${page}`,
			new Headers({
				Authorization: `Bearer ${accessToken}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const { data, pages, total } = response.body.results;

		return { data, pages, total };
	}

	/**
	 * @returns {Promise<AgentPersonalData>}
	 */
	// eslint-disable-next-line complexity
	async getAgentPersonalData() {
		const response = await this.httpClient.get(
			`${apiUrls.me.agent}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const results = response.body;

		return {
			email: results.email,
			facebook: results.facebook ?? null,
			groups:
				(results.groups ?? []).map(
					/**
					 * @param {{ad_banner: AdBannerData[],avm_widget_early_access: boolean, id: string, is_primary: boolean}} group
					 * @returns {{adBanner: AdBanner[], avmWidgetEarlyAccess: boolean, id: string, isPrimary: boolean}}
					 */
					(group) => ({
						adBanner: AgentService.#mapAdBanner(group?.ad_banner),
						avmWidgetEarlyAccess: group.avm_widget_early_access,
						id: group.id,
						isPrimary: group.is_primary,
					})
				) ?? [],
			headshot: results.headshot ?? null,
			id: results.id,
			name: {
				first: results.name?.first ?? null,
				last: results.name?.last ?? null,
			},
			networkVendors: results.suppress_external_vendors,
			occupation: results.occupation,
			phone: {
				landline: results.phone?.landline ?? null,
				mobile: results.phone?.mobile ?? null,
			},
			postalCode: results.postal_code,
			slug: results.slug,
			twitter: results.twitter ?? null,
			website: results.website ?? null,
		};
	}

	/**
	 * @param {AdBannerData[]} adBanner
	 * @returns {AdBanner[]}
	 */
	static #mapAdBanner(adBanner) {
		if (!adBanner?.length) {
			return [];
		}

		return adBanner?.map((banner) => ({
			active: banner?.active,
			brand: banner?.brand,
			location: {
				agentDashboard: banner?.location?.agent_dashboard,
				homeownerDashboard: banner?.location?.homeowner_dashboard,
			},
		}));
	}

	/**
	 * @param {ImportData} importData
	 * @param {File} file
	 * @param {string=} groupId
	 */
	async importAgents(importData, file, groupId) {
		const endpoint =
			groupId !== undefined
				? `${apiUrls.groups.root}/${groupId}/agents/import`
				: apiUrls.agents.import;

		const formData = new FormData();
		formData.append('file', file);

		const { invalidData: invalid, validData: valid } = importData;
		const data = {
			invalid,
			valid,
		};

		formData.append('agents', JSON.stringify(data));

		const response = await this.httpClient.post(
			endpoint,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			formData
		);

		if (!response.isOk) {
			throw new ServiceError(
				'Something went wrong while trying to import your agents.',
				response.body
			);
		}
	}

	/**
	 *
	 * @param {any} parameters
	 * @param {string} agentId
	 */
	async updateAgentData(parameters, agentId) {
		const data = {
			// eslint-disable-next-line no-unneeded-ternary
			active: parameters.active === 'active' ? true : false,
			email: parameters?.email,
			/* eslint-disable-next-line camelcase -- Mapping request body */
			facebook_username: parameters?.facebook,
			groups:
				parameters.groups?.map(
					/**
					 * @param {{ id: string, isPrimary?: boolean }} group
					 */
					(group) => ({
						id: group.id,
						/* eslint-disable-next-line camelcase -- Mapping request body */
						is_primary: group?.isPrimary ?? false,
					})
				) ?? [],
			// eslint-disable-next-line camelcase
			landline_phone_number: parameters?.landlinePhoneNumber,
			// eslint-disable-next-line camelcase
			mobile_phone_number: parameters?.mobilePhoneNumber,
			name: {
				first: parameters?.firstName,
				last: parameters?.lastName,
			},
			/* eslint-disable-next-line camelcase -- Mapping request body */
			real_estate_license: parameters?.realEstateLicense,
			// eslint-disable-next-line camelcase
			twitter_handle: parameters?.twitter,
			// eslint-disable-next-line camelcase
			website_url: parameters?.website,
		};

		const response = await this.httpClient.put(
			`${apiUrls.agents.root}/${agentId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			data
		);

		if (!response.isOk) {
			throw new Error(response.body.message ?? response.body[0]);
		}
	}

	/**
	 *
	 * @param {string} agentId
	 * @param {Blob=} file
	 */
	async updateAgentHeadshot(agentId, file) {
		const formData = new FormData();

		if (file) {
			formData.append('files', file);
		}

		const response = await this.httpClient.put(
			`${apiUrls.agents.root}/${agentId}/headshot`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			formData
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}
	}

	/**
	 * @param {any} values
	 */
	async createAgent(values) {
		const data = {
			/* eslint-disable-next-line camelcase -- Mapping request body */
			email_address: values?.email,
			/* eslint-disable-next-line camelcase -- Mapping request body */
			facebook_username: values?.facebook,
			groups:
				values.groups?.map(
					/**
					 * @param {{ id: string, isPrimary?: boolean}} group
					 */
					(group) => ({
						id: group.id,
						/* eslint-disable-next-line camelcase -- Mapping request body */
						is_primary: group?.isPrimary ?? false,
					})
				) ?? [], // TODO: When POST route takes multiple groups, change this to array of groups
			/* eslint-disable-next-line camelcase -- Mapping request body */
			landline_phone_number: formatPhoneNumberForData(
				values.landlinePhoneNumber
			),
			/* eslint-disable-next-line camelcase -- Mapping request body */
			mobile_phone_number: formatPhoneNumberForData(
				values.mobilePhoneNumber
			),
			name: {
				first: values?.firstName,
				last: values?.lastName,
			},
			/* eslint-disable-next-line camelcase -- Mapping request body */
			real_estate_license: {
				number: values?.realEstateLicense,
			},
			/* eslint-disable-next-line camelcase -- Mapping request body */
			twitter_handle: values?.twitter,
			/* eslint-disable-next-line camelcase -- Mapping request body */
			website_url: values?.website,
		};

		const response = await this.httpClient.post(
			apiUrls.agents.root,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			data
		);

		if (!response.isOk) {
			throw new Error(response.body.join(''));
		}
	}

	/**
	 * @param {any} values
	 * @param {string} accessToken
	 * @param {File=} file
	 * @returns {Promise<void>}
	 */
	async updateAgentPersonalData(values, accessToken, file) {
		const formData = mapInputToFormData({
			email: values.email,
			facebook: values.facebook,
			/* eslint-disable-next-line camelcase -- Mapping request body */
			first_name: values.firstName,
			landline: formatPhoneNumberForData(values.phoneNumber),
			/* eslint-disable-next-line camelcase -- Mapping request body */
			last_name: values.lastName,
			mobile: formatPhoneNumberForData(values.mobilePhoneNumber),
			occupation: values.occupation,
			/* eslint-disable-next-line camelcase -- Mapping request body */
			postal_code: values.postalCode,
			slug: values.slug,
			twitter: values.twitter,
			website: values.website,
		});

		if (file) {
			formData.append('files', file);
		}

		const response = await this.httpClient.put(
			apiUrls.me.agent,
			new Headers({
				Authorization: `Bearer ${accessToken}`,
			}),
			formData
		);

		if (!response.isOk) {
			throw new ServiceError(
				'Could not save your information',
				response.body.message
			);
		}
	}

	/**
	 *
	 * @param {Readonly<{  invitations?: boolean; market_activity: boolean }>} values
	 * @param {string} token
	 * @returns {Promise<void>}
	 * @throws {Error}
	 */
	async updateEmailCommunicationPreferences(values, token) {
		const response = await this.httpClient.put(
			apiUrls.agents.updateContactEmailCommunicationPreferences,
			new Headers({
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			}),
			values
		);

		if (response.isOk) {
			return;
		}

		throw new Error('Could not update communication preferences');
	}

	/**
	 * @returns {Promise<{active_agent_count: number, upgraded_agent_count: number}>}
	 * @throws {Error}
	 */
	async getAgentUpgradedCount() {
		const response = await this.httpClient.get(
			apiUrls.agents.agentsUpgradedCount,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		return response.body;
	}

	/**
	 * @returns {Promise<AgentSettings>}
	 * @throws {Error}
	 */
	async getAgentSettings() {
		const response = await this.httpClient.get(
			apiUrls.me.info,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const settings = response?.body?.results?.meta?.agent?.settings;

		return {
			campaigns: {
				invitations:
					settings?.campaigns?.contacts?.email?.invitations ?? false,
				marketActivity:
					settings?.campaigns?.contacts?.email?.market_activity ??
					false,
			},
			suppressExternalVendors:
				settings?.suppress_external_vendors ?? false,
		};
	}

	/**
	 * @param {number} page
	 * @returns {Promise<ValuationDataResponse>}
	 */
	async getValuations(page = 1) {
		const response = await this.httpClient.get(
			`${apiUrls.me.valuations}/?page=${page}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const { pages, total, valuations } = response.body;

		return {
			pages,
			total,
			valuations: AgentService.#mapValuationsToResponse(valuations),
		};
	}

	/**
	 * @param {ValuationDataRequest['valuations']} valuations
	 * @returns {ValuationDataResponse['valuations']}
	 */
	static #mapValuationsToResponse(valuations) {
		return valuations.map((valuation) => ({
			address: {
				city: valuation.address.city,
				country: valuation.address.country,
				postalCode: valuation.address.postal_code,
				state: valuation.address.state,
				streetAddress1: valuation.address.street_address_1,
				streetAddress2: valuation.address.street_address_2 ?? null,
			},
			contact: valuation.contact
				? {
						email: valuation.contact.email,
						hasGroupSubscription:
							valuation.contact.has_group_subscription,
						id: valuation.contact.id,
						leadScore: valuation.contact.lead_score,
						leadScores: valuation.contact.lead_scores.map(
							(leadScore) => ({
								date: leadScore.date,
								score: leadScore.score,
							})
						),
						name: valuation.contact.name ?? null,
						phone: valuation.contact.phone ?? null,
				  }
				: null,
			createdAt: valuation.created_at,
			value: valuation.value,
		}));
	}

	/**
	 * @param {{readonly first: string?, readonly last: string?}} name
	 * @returns {string}
	 */
	static #generateFullName(name) {
		return `${name?.first} ${name?.last}`.trim();
	}
}
