/**
 * @param {Date|string} date
 * @returns {string}
 */
export default function convertDateToMonthDayYear(date) {
	if (!(date instanceof Date)) {
		date = new Date(date.toUpperCase());
	}

	return date.toLocaleDateString('en-us', {
		month: 'numeric',
		day: 'numeric',
		year: 'numeric',
	});
}
