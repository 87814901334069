/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('../../LeadScore/Props').LeadScores} LeadScores
 */

import { translate } from '../../../Internationalization';
import LeadScore from '../../LeadScore/LeadScore';
import React from 'react';
import utilities from '@mooveguru/js-utilities';

/** @extends {React.Component<Props>} */
export default class LeadScoreModalHeader extends React.Component {
	/** @returns {string} */
	#getOldestScoreDate() {
		if (!this.props?.leadScores?.length) {
			return LeadScoreModalHeader.#getDate(new Date());
		}

		const lastIndex = this.props?.leadScores.length - 1;
		const oldestDate = this.props?.leadScores[lastIndex]?.date;

		return LeadScoreModalHeader.#getDate(oldestDate);
	}

	/**
	 * @param {Date | null} date
	 * @returns {string}
	 */
	static #getDate(date = new Date()) {
		return utilities.date.convertDateToDateString(
			date ? new Date(date) : new Date()
		);
	}

	/** @returns {JSX.Element | null} */
	#renderLeadScore() {
		if (!this.props?.leadScores?.length) {
			return null;
		}

		return (
			<LeadScore
				iconOrder="left"
				leadScore={this.#getScoreDifference()}
				leadScores={this.#getFirstAndLastScore()}
			/>
		);
	}

	/** @returns {number} */
	#getScoreDifference() {
		if (!this.#getFirstAndLastScore().length) {
			return 0;
		}

		const currentScore = this.#getFirstAndLastScore()[0]?.score || 0;
		const previousScore = this.#getFirstAndLastScore()[1]?.score || 0;

		return Math.abs(currentScore - previousScore);
	}

	/** @returns {LeadScores[]} */
	#getFirstAndLastScore() {
		if (!this.props?.leadScores?.length) {
			return [];
		}

		const lastIndex = this.props?.leadScores.length - 1;
		// if	there is only one score, will return the same score twice
		return [this.props?.leadScores[0], this.props?.leadScores[lastIndex]];
	}

	/** @returns {JSX.Element} */
	render() {
		return (
			<header className="flex justify-between items-center mx-4">
				<div>
					<h2 className="font-bold text-ms-4">
						{this.props?.name ?? '—'}
					</h2>
					<p>{this.props?.email}</p>
				</div>

				<div className="flex flex-col items-end">
					<p className="font-bold text-ms-4 w-fit text-end">
						{this.props?.leadScores[0]?.score ?? 0}
					</p>

					<div className="flex gap-2">
						{this.#renderLeadScore()}

						<p className="text-grey-700">
							{translate(
								'global.pages.contacts.lead_score.since',
								this.#getOldestScoreDate()
							)}
						</p>
					</div>
				</div>
			</header>
		);
	}
}
