/**
 * @typedef {import('./AppHeaderComponent').Props} Props
 * @typedef {import('./AppHeaderComponent').State} State
 */

import { BaseSVG } from '../../shared/BaseSVG';
import { occupation } from '../../shared/ThemeProvider/theme-utilities';
import { toAbsoluteUrl } from '../../utils/toAbsoluteUrl';
import { translate } from '../Internationalization';
import { withProperty } from '../Homeowner/PropertyProvider';
import { withTheme } from '../../shared/ThemeProvider';
import AppLogo from './AppLogo';
import ExternalAnchorButton from '../../shared/ExternalAnchorButton';
import Feature from '../../shared/Feature';
import images from '../../config/local/images';
import occupations from '@mooveguru/yhh-shared-config/users/agent/occupations.json';
import ProfileImage from '../../shared/ProfileImage';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class AppHeader extends React.Component {
	#bindings = [
		'_handlePropertySelectorClick',
		'_renderPropertyOption',
		'handleDropdownToggleClick',
		'handlePropertyDropdownToggleClick',
		'handlePropertySelect',
	];

	static defaultProps = {
		displayPropertyDropdown: true,
	};

	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		this.propertySelector = React.createRef();

		/**
		 * @type {State}
		 */
		this.state = {
			isDropdownActive: false,
			isPropertyDropdownActive: false,
		};

		this.#bindings.forEach((binding) => {
			// @ts-ignore
			this[binding] = this[binding].bind(this);
		});
	}

	/**
	 * @param {Event} event
	 * @returns {void}
	 */
	_handlePropertySelectorClick(event) {
		// @ts-ignore this will have an event
		const isClickInside = this.propertySelector.current?.contains(
			event.target
		);

		if (isClickInside) {
			return;
		}

		this.setState({
			isPropertyDropdownActive: false,
		});
	}

	/**
	 * @returns {void}
	 */
	componentDidMount() {
		document.addEventListener('click', this._handlePropertySelectorClick);
	}

	/**
	 * @returns {void}
	 */
	componentWillUnmount() {
		document.removeEventListener(
			'click',
			this._handlePropertySelectorClick
		);
	}

	/**
	 * @returns {void}
	 */
	handleDropdownToggleClick() {
		this.setState(
			/**
			 * @param {State} prevState
			 * @returns {{isDropdownActive: boolean}}
			 */
			(prevState) => ({
				isDropdownActive: !prevState.isDropdownActive,
			})
		);
	}

	/**
	 * @returns {void}
	 */
	handlePropertyDropdownToggleClick() {
		this.setState((prevState) => ({
			isPropertyDropdownActive: !prevState.isPropertyDropdownActive,
		}));
	}

	/**
	 * @param {string} id
	 * @returns {void}
	 */
	handlePropertySelect(id) {
		this.props.property.setSelectedProperty(id);

		// TODO: This should not be necessary, remove once all components are setup to use context correctly
		setTimeout(() => window.location.reload(), 1000);
	}

	/**
	 * @param {string} email
	 * @returns {JSX.Element | null}
	 */
	static #renderAgentEmailButton(email) {
		if (!email) {
			return null;
		}

		return (
			<ExternalAnchorButton
				className="button button-outline"
				href={`mailto:${email}`}
			>
				{translate('homeowner.pages.dashboard.agent.email')}
			</ExternalAnchorButton>
		);
	}

	/**
	 * @param {string | null} phoneNumber
	 * @returns {JSX.Element | null}
	 */
	static #renderAgentPhoneButton(phoneNumber) {
		if (!phoneNumber) {
			return null;
		}

		return (
			<ExternalAnchorButton
				className="button button-outline"
				href={`tel:${phoneNumber}`}
			>
				{translate('homeowner.pages.dashboard.agent.call')}
			</ExternalAnchorButton>
		);
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	#renderAgentFlyout() {
		if (!this.props.show.agent) {
			return null;
		}

		// prettier-ignore
		const classes = `flyout-area top-full ${this.state.isDropdownActive ? 'active' : ''}`.trim();

		return (
			<section
				aria-labelledby="contact-your-agent-button"
				className={classes}
				id="contact-your-agent-fly-out"
			>
				<section className="flyout-layout">
					<header className="flyout-header">
						<button
							aria-label={translate(
								'aria_labels.close_agent_dropdown'
							)}
							className="icon icon-sm"
							onClick={this.handleDropdownToggleClick}
							type="button"
						>
							<i className="icon icon-fill-fg">
								<BaseSVG path={images.icons.navigation.close} />
							</i>
						</button>
					</header>
					{this.props.theme.teamList?.map((member, index) => (
						<section
							className={`${index > 0 ? 'mt-10' : ''}`}
							key={member.email}
						>
							<section className="flyout-body">
								<img
									alt={translate(
										'aria_labels.user_headshot',
										`${this.props.theme.agentName}`
									)}
									className="avatar avatar-xl border-theme-dark"
									src={member.headshotUrl ?? undefined}
								/>

								<section>
									<h2 className="h-ms-4 mb-1">
										{member.name}
									</h2>
									<Feature
										fallback={null}
										name="group:show_cobranding"
									>
										<p className="mb-1">
											{
												// @ts-ignore
												occupations[
													member.occupation
												] ?? 'Loan Officer'
											}
										</p>
									</Feature>
									<p className="h-ms-1">{member.groupName}</p>
								</section>
							</section>

							<footer className="button-group">
								{AppHeader.#renderAgentPhoneButton(
									member.phoneNumber
								)}
								{AppHeader.#renderAgentEmailButton(
									member.email
								)}
							</footer>
						</section>
					))}
				</section>
			</section>
		);
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	#renderAgent() {
		if (!this.props.show.agent) {
			return null;
		}

		const contactText = translate(
			'global.header.contact',
			this.props.theme.teamList.length > 1
				? translate('global.header.contact_team')
				: // @ts-ignore
				  occupation(this.props.theme.agentOccupation)
		);

		return (
			<React.Fragment>
				<button
					aria-controls="contact-your-agent-fly-out"
					aria-expanded={
						this.state.isDropdownActive ? 'true' : 'false'
					}
					aria-label={translate(
						'aria_labels.contact_your_agent',
						this.props.theme.agentName ?? ''
					)}
					className="contact-area"
					id="contact-your-agent-button"
					onClick={this.handleDropdownToggleClick}
					type="button"
				>
					<span className="text uppercase flex-grow-1 flex-shrink-1">
						{contactText}
					</span>

					<ProfileImage
						altText={translate(
							'aria_labels.user_headshot',
							`${this.props.theme.agentName}`
						)}
						className="avatar md:avatar-md"
						headshotUrl={this.props.theme.agentHeadshotUrl}
					/>
				</button>
				{this.#renderAgentFlyout()}
			</React.Fragment>
		);
	}

	/**
	 * @protected
	 * @param {Props['property']['propertyList'][0]} property
	 * @returns {JSX.Element}
	 */
	_renderPropertyOption(property) {
		return (
			<li className="menu-item" key={property.id}>
				<button
					className="link w-full"
					onClick={() => this.handlePropertySelect(property.id)}
					type="button"
				>
					<span>{property.address.streetAddress1}</span>
					<span className="action">
						{translate('global.actions.select')}
						<i className="icon icon-sm">
							<BaseSVG path={images.icons.homeowners} />
						</i>
					</span>
				</button>
			</li>
		);
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	#renderPropertySelector() {
		if (!this.props.show.property) {
			return null;
		}

		if (!this.props.property.propertyList.length) {
			return null;
		}

		if (!this.props.property.selectedProperty?.id) {
			return null;
		}

		const selectedPropertyAddress =
			this.props.property.selectedProperty.address.streetAddress1;

		return (
			<section className="fancy-dropdown">
				{/* @ts-ignore - method returns early if null*/}
				{this.#renderPropertySelectorButton(selectedPropertyAddress)}
				{this.#renderMenuList()}
			</section>
		);
	}

	/**
	 * @param {string} selectedPropertyAddress
	 * @returns {JSX.Element}
	 */
	#renderPropertySelectorButton(selectedPropertyAddress) {
		return (
			<button
				aria-controls="property-selector-drop-down"
				aria-expanded={
					this.state.isPropertyDropdownActive ? 'true' : 'false'
				}
				aria-label={translate(
					'aria_labels.property_drop_down_selector',
					selectedPropertyAddress
				)}
				className={`selector ${
					this.state.isPropertyDropdownActive ? 'active' : null
				}`}
				id="property-selector-button"
				onClick={this.handlePropertyDropdownToggleClick}
				ref={this.propertySelector}
				type="button"
			>
				<span className="label">
					<img
						alt=""
						aria-hidden="true"
						className="icon"
						src={toAbsoluteUrl(images.homeowners.header.house)}
					/>
					<span className="uppercase">
						{translate('global.header.property')}
					</span>
				</span>
				<span className="current-item">
					<span className="value">{selectedPropertyAddress}</span>
					<i aria-hidden="true" className="icon icon-xs">
						<BaseSVG path={images.homeowners.header.arrow} />
					</i>
				</span>
			</button>
		);
	}

	/** @returns {JSX.Element | null}  */
	#renderMenuList() {
		const menuList = this.props.property.propertyList
			.filter(
				(property) =>
					property.id !== this.props.property.selectedProperty?.id
			)
			.map(this._renderPropertyOption);

		return menuList.length ? (
			<ul
				aria-labelledby="property-selector-button"
				className="menu"
				id="property-selector-drop-down"
				role="region"
			>
				{menuList}
			</ul>
		) : null;
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	render() {
		return (
			<header className="app-header">
				<div className="header-container">
					<section className="header-layout">
						<figure>
							<AppLogo className="logo-image logo-img" />
						</figure>

						{this.#renderAgent()}

						<div className="dropdown-area">
							{this.#renderPropertySelector()}
						</div>
					</section>
				</div>
			</header>
		);
	}
}

export default withProperty(withTheme(AppHeader));
