/**
 * @typedef {import("./BaseChecklistProps").default} Props
 * @typedef {import("./BaseChecklistState").default} State
 * @typedef {import("./Task").default} Task
 */

import { AuthContext } from '../../../shared/AuthProvider';
import { translate } from '../../../App/Internationalization';
import ChecklistItem from './ChecklistItem';
import ConfidenceChart from '../Finances/ConfidenceChart';
import Feature from '../../../shared/Feature';
import FormErrorMessages from '../../../shared/Forms/Messages/FormErrorMessages';
import images from '../../../config/local/images';
import React from 'react';
import TitleCard from '../shared/TitleCard';

/** @extends {React.Component<Props, State>} */
export default class BaseChecklist extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			taskList: BaseChecklist.#sortChecklist(this.props.taskList),
		};
		this._handleItemChange = this._handleItemChange.bind(this);
		this.completionPercentage = this.#completionPercentage.bind(this);
	}

	/**
	 * @param {Task[]} checklist
	 * @returns {Task[]}
	 */
	static #sortChecklist(checklist) {
		checklist.sort(
			/**
			 * @param {Task} taskA
			 * @param {Task} taskB
			 * @returns {-1 | 0 | 1}
			 */
			(taskA, taskB) => {
				const diff = Number(taskB.step) - Number(taskA.step);

				if (diff === 0) {
					return diff;
				}

				return diff >= 1 ? -1 : 1;
			}
		);

		const complete = checklist.filter((task) => task.isComplete);
		const incomplete = checklist.filter((task) => !task.isComplete);

		return [...incomplete, ...complete];
	}

	/**
	 * @protected
	 * @param {Event & { currentTarget: HTMLInputElement }} event
	 * @returns {Promise<void>}
	 */
	async _handleItemChange(event) {
		event.preventDefault();

		await this.props.onHandleChange(event, event.currentTarget.value);
		this.setState((previousState) => {
			const taskList = BaseChecklist.#sortChecklist(
				previousState.taskList
			);

			return { taskList };
		});
	}

	/**
	 * @returns {JSX.Element[]}
	 */
	#renderItemList() {
		return this.state.taskList.map(
			/**
			 * @param {Task} task
			 * @returns {JSX.Element}
			 */
			(task) => (
				<ChecklistItem
					className="mb-4"
					date={task.createdDate}
					disabled={this.props.disabled}
					isComplete={task.isComplete}
					key={task.id}
					label={task.name}
					name={task.name}
					// @ts-ignore -- Type added in props
					onChange={this._handleItemChange}
					step={task.step}
					taskType={this.props.taskType}
					value={task.id}
				/>
			)
		);
	}

	/**
	 * @returns {number}
	 */
	#calculateCompletedTasks() {
		return this.props.taskList.reduce(
			/**
			 * @param {number} sum
			 * @param {{isComplete: boolean}} task
			 * @returns {number}
			 */
			(sum, task) => (task.isComplete ? sum + 1 : sum),
			0
		);
	}

	/**
	 * @returns {string}
	 */
	#completionPercentage() {
		/**
		 * completedTasks: Contains list of all completed tasks.
		 * totalTasks: Contains total number of tasks are in list.
		 * completionPercentage: Calculate total percentage from total tasks & completed tasks.
		 */
		const completedTasks = this.#calculateCompletedTasks();
		const totalTasks = this.props.taskList.length;
		const completionPercentage = completedTasks / totalTasks || 0;

		/** @type {string} */
		return `${Math.round(completionPercentage * 100)}`;
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.props.taskList.length === 0) {
			return (
				<TitleCard title={this.props.title}>
					<p className="p-8 mb-4">
						{translate('homeowner.pages.checklists.missing')}
					</p>
				</TitleCard>
			);
		}
		const completedTasks = this.#calculateCompletedTasks();
		const pendingTasks = this.props.taskList.length - completedTasks;

		/**
		 * 	completionPercentage : Calculate percentage of completed tasks.
		 * 	completionPercentageText : Round to percentages.
		 */
		const completionPercentage =
			completedTasks / this.props.taskList.length || 0;
		const completionPercentageText = Math.round(completionPercentage * 100);

		return (
			<section className="checklist mt-10">
				<header className="flex justify-between items-center">
					<aside>
						<h1 className="h-ms-4">{`${this.props.title}!`}</h1>
						<p className="-hl-ms-1">{`${completedTasks} ${translate(
							'homeowner.pages.checklists.completed'
						)}, ${pendingTasks} ${translate(
							'homeowner.pages.checklists.pending'
						)}`}</p>
					</aside>
					<aside className="flex items-center h-20">
						<div className="text-ms-6 font-header mr-2">
							<span className="font-bold">
								{this.completionPercentage()}
							</span>
							{'%'}
						</div>
						<div className="graph w-11">
							<ConfidenceChart
								confidence={completionPercentageText}
								hideInnerText={true}
							/>
						</div>
					</aside>
				</header>
				<Feature fallback={null} name="homeowner:checklist_banner">
					<section className="message message-grey">
						<img
							alt={translate(
								'homeowner.pages.checklists.dollar_icon'
							)}
							className="image bg-white"
							src={images.icons.dealColor}
						/>
						<span className="text">{this.props.dealMessage}</span>
					</section>
				</Feature>
				<FormErrorMessages messages={[this.props.errorMessage]} />
				<p className="mb-8">{this.props.description}</p>
				<ul className="task-list">{this.#renderItemList()}</ul>
			</section>
		);
	}
}

BaseChecklist.contextType = AuthContext;
