import React from 'react';

import { ThemeContext } from 'shared/ThemeProvider';

export default function withWhiteLabeling(Component) {
	class WhiteLabeledComponent extends React.Component {
		async componentDidMount() {
			if (this.props.match?.params?.whiteLabelSlug) {
				await this.context.getAndSetThemeBySlug(
					this.props.match.params.whiteLabelSlug
				);
			}
		}

		render() {
			return <Component {...this.props} />;
		}
	}

	WhiteLabeledComponent.contextType = ThemeContext;

	return WhiteLabeledComponent;
}
