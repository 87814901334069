/** @typedef {import('./InputSlugType').default} Props */

import { translate } from '../../../../App/Internationalization';
import BaseInput from '../BaseInput';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class InputSlug extends React.Component {
	static defaultProps = {
		className: '',
		disabled: false,
		name: 'slug',
		required: false,
	};

	/** @returns {JSX.Element} */
	render() {
		const translationPath = 'global.forms.inputs.slug';
		const label = translate(`${translationPath}.label`);
		const placeholder = translate(`${translationPath}.placeholder`);

		return (
			<BaseInput
				autocomplete="slug"
				className={this.props.className}
				disabled={this.props.disabled}
				label={this.props.label ?? label}
				name={this.props.name}
				placeholder={placeholder}
				required={this.props.required}
				type="text"
			/>
		);
	}
}
