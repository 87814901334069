import React from 'react';
// import { Redirect } from 'react-router-dom';

import AddContact from 'App/Agent/Contacts/AddContact';
import EditContact from 'App/Agent/Contacts/EditContact';
import ViewContacts from 'App/Agent/Contacts/ViewContacts';
import ImportContacts from 'App/shared/ImportContacts';
import paths from 'config/local/paths';
import Page from 'shared/Page';
import Switch from 'shared/Switch';
import { translate } from 'App/Internationalization';

export default class Contacts extends React.Component {
	render() {
		return (
			<Switch>
				<Page
					component={ViewContacts}
					exact
					path={paths.app.agent.contacts.root}
					title={translate('admin.nav.contacts.view')}
				/>
				<Page
					component={AddContact}
					exact
					path={paths.app.agent.contacts.add}
					title={translate('admin.nav.contacts.add')}
				/>
				<Page
					component={EditContact}
					path={`${paths.app.agent.contacts.edit}/:contactId`}
					title={translate('admin.nav.contacts.edit')}
				/>
				<Page
					component={ImportContacts}
					path={paths.app.agent.contacts.import}
					title={translate('admin.nav.contacts.import')}
				/>
			</Switch>
		);
	}
}
