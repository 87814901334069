import { Route } from 'react-router-dom';
import { withProperty } from 'App/Homeowner/PropertyProvider';
import { withPropertyService, withUserService } from 'service-container';
import { withTheme } from 'shared/ThemeProvider';
import Admin from 'App/Admin';
import Agent from 'App/Agent';
import ClickListener from 'App/ClickListener';
import Homeowner from 'App/Homeowner';
import Profile from 'App/Profile';
import React from 'react';
import RoleGatedRedirect from 'App/shared/RoleGatedRedirect';
import RoleGatedRoute from 'App/shared/RoleGatedRoute';
import Subscribe from 'App/Subscribe';
import paths from 'config/local/paths';

const InjectedProfile = withTheme(withUserService(Profile));
const InjectedAdmin = withTheme(Admin);
const InjectedAgent = withTheme(Agent);
const InjectedHomeowner = withTheme(
	withProperty(withPropertyService(Homeowner))
);

export default class App extends React.Component {
	render() {
		return (
			<ClickListener>
				<RoleGatedRedirect
					allowedRoles={['admin', 'manager', 'owner']}
					exact
					from={paths.app.root}
					to={paths.app.admin.root}
				/>
				<RoleGatedRedirect
					allowedRoles={['homeowner']}
					exact
					from={paths.app.root}
					to={paths.app.homeowner.root}
				/>
				<RoleGatedRedirect
					allowedRoles={['agent']}
					exact
					from={paths.app.root}
					to={paths.app.agent.root}
				/>
				<RoleGatedRoute
					allowedRoles={['admin', 'manager', 'owner']}
					component={InjectedAdmin}
					path={paths.app.admin.root}
				/>
				<RoleGatedRoute
					allowedRoles={['agent']}
					component={InjectedAgent}
					path={paths.app.agent.root}
				/>
				<RoleGatedRoute
					allowedRoles={['homeowner']}
					component={InjectedHomeowner}
					path={paths.app.homeowner.root}
				/>
				<Route
					component={InjectedProfile}
					path={paths.app.profile.root}
				/>
				<Route component={Subscribe} path={paths.app.subscribe} />
			</ClickListener>
		);
	}
}
