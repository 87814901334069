/**
 * @typedef {import('./PropertyFormValues').default} PropertyState
 * @typedef {import('./PropertyState').default} State
 * @typedef {{propertyService: import('../../shared/PropertyService/PropertyService').default, readonly location?: {readonly state?: {readonly referrer?: string;};}, readonly match: {readonly params: {propertyId: string}}}} Props
 * @typedef {import('./PropertyFormValues').default} PropertyFormValues
 */
import { AuthContext } from 'shared/AuthProvider';
import { file as validateFile } from 'shared/validators';
import { Redirect } from 'react-router-dom';
import { translate } from 'App/Internationalization';
import { withPropertyService } from 'service-container';
import * as propertyService from 'App/Homeowner/Properties/property-service';
import captureError from 'utils/captureError';
import Loading from 'shared/Loading';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import paths from 'config/local/paths';
import PropertyForm from 'App/Homeowner/Properties/PropertyForm';
import React from 'react';
import InlineSVG from 'react-inlinesvg/esm';
import images from '../../../config/local/images';

const successMessage = translate('homeowner.pages.properties.success_message');

/** @extends {React.Component<Props, State>} */
class EditProperty extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			editWasSuccessful: false,
			errorMessage: null,
			file: null,
			image: null,
			property: {
				city: '',
				country: '',
				id: '',
				postalCode: '',
				state: '',
				streetAddress1: '',
				streetAddress2: '',
			},
			initialValues: {
				acreage: null,
				amountToLoan: null,
				basement: null,
				bedrooms: null,
				buildYear: null,
				city: null,
				constructionType: null,
				country: null,
				dueDate: null,
				fireplace: null,
				floorCover: null,
				foundationType: null,
				garageCars: null,
				garageType: null,
				heating: null,
				heatingFuelType: null,
				interiorWalls: null,
				lenderName: null,
				loanType: null,
				lotSizeSquareFootage: null,
				maximumInterestRate: null,
				numberOfBaths: null,
				numberOfPartialBaths: null,
				originalDateOfContract: null,
				otherRooms: null,
				plumbingFixtures: null,
				pool: null,
				postalCode: null,
				rate: null,
				roofCover: null,
				roofType: null,
				sewer: null,
				state: null,
				stories: null,
				streetAddress1: null,
				streetAddress2: null,
				style: null,
				title: null,
				totalNumberOfRooms: null,
				water: null,
			},
			isGettingData: true,
		};

		this.handleDeleteImage = this.handleDeleteImage.bind(this);
		this.handleFileUploadChange = this.handleFileUploadChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async componentDidMount() {
		await this.getAndSetInitialValues();
	}

	async getAndSetInitialValues() {
		try {
			const property =
				await this.props.propertyService.getEditablePropertyById(
					this.props.match.params.propertyId
				);

			this.setState({
				errorMessage: null,
				image: property.image?.id ? property.image : null,
				initialValues: {
					acreage: property.details.acreage,
					amountToLoan: property.details.amountToLoan,
					basement: property.details.basement,
					bedrooms: property.details.bedrooms,
					buildYear: property.details.buildYear,
					city: property.address.city,
					constructionType: property.details.constructionType,
					country: property.address.country,
					dueDate: property.details.dueDate,
					fireplace: property.details.fireplace,
					floorCover: property.details.floorCover,
					foundationType: property.details.foundationType,
					garageCars: property.details.garageCars,
					garageType: property.details.garageType,
					heating: property.details.heating,
					heatingFuelType: property.details.heatingFuelType,
					interiorWalls: property.details.interiorWalls,
					lenderName: property.details.lenderName,
					loanType: property.details.loanType,
					lotSizeSquareFootage: property.details.lotSizeSquareFootage,
					maximumInterestRate: property.details.maximumInterestRate,
					numberOfBaths: property.details.numberOfBaths,
					numberOfPartialBaths: property.details.numberOfPartialBaths,
					originalDateOfContract:
						property.details.originalDateOfContract,
					otherRooms: property.details.otherRooms,
					plumbingFixtures: property.details.plumbingFixtures,
					pool: property.details.pool,
					postalCode: property.address.postalCode,
					rate: property.details.rate,
					roofCover: property.details.roofCover,
					roofType: property.details.roofType,
					sewer: property.details.sewer,
					state: property.address.state,
					stories: property.details.stories,
					streetAddress1: property.address.streetAddress1,
					streetAddress2: property.address.streetAddress2,
					style: property.details.style,
					title: property.title,
					totalNumberOfRooms: property.details.totalNumberOfRooms,
					water: property.details.water,
				},
				isGettingData: false,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 */
	handleFileUploadChange(event) {
		if (!event.target.files?.length) {
			return;
		}
		this.setState({ file: event.target.files[0] });
	}

	/**
	 * @param {PropertyFormValues} values
	 */
	async handleSubmit(values) {
		if (this.state.file) {
			try {
				validateFile(this.state.file, mimeList.images, 'image');
			} catch (error) {
				if (error instanceof Error) {
					captureError(error);

					this.setState({
						errorMessage: translate('global.error'),
					});
				}

				return;
			}
		}

		try {
			const property = {
				address: {
					city: values.city || null,
					postalCode: values.postalCode || null,
					state: values.state || null,
					country: values.country || null,
					streetAddress1: values.streetAddress1 || null,
					streetAddress2: values.streetAddress2 || null,
				},
				details: {
					acreage: values.acreage || null,
					amountToLoan: values.amountToLoan ?? null,
					basement: values.basement || null,
					bedrooms: values.bedrooms ?? null,
					buildYear: values.buildYear || null,
					constructionType: values.constructionType || null,
					dueDate: values.dueDate || null,
					fireplace: values.fireplace || null,
					floorCover: values.floorCover || null,
					foundationType: values.foundationType || null,
					garageCars: values.garageCars ?? null,
					garageType: values.garageType || null,
					heating: values.heating || null,
					heatingFuelType: values.heatingFuelType || null,
					interiorWalls: values.interiorWalls || null,
					lenderName: values.lenderName || null,
					loanType: values.loanType || null,
					lotSizeSquareFootage: values.lotSizeSquareFootage ?? null,
					maximumInterestRate: values.maximumInterestRate ?? null,
					numberOfBaths: values.numberOfBaths ?? null,
					numberOfPartialBaths: values.numberOfPartialBaths ?? null,
					originalDateOfContract:
						values.originalDateOfContract || null,
					otherRooms: values.otherRooms ?? null,
					plumbingFixtures: values.plumbingFixtures ?? null,
					pool: values.pool || null,
					rate: values.rate ?? null,
					roofCover: values.roofCover || null,
					roofType: values.roofType || null,
					sewer: values.sewer || null,
					stories: values.stories ?? null,
					style: values.style || null,
					totalNumberOfRooms: values.totalNumberOfRooms ?? null,
					water: values.water || null,
				},
				isPrimary: values.isPrimary,
				title: values.title,
			};

			await propertyService.updateProperty(
				this.props.match.params.propertyId,
				property,
				this.context.accessToken
			);
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});

			return;
		}

		if (!this.state.file) {
			this.setState({
				editWasSuccessful: true,
				errorMessage: null,
				property: {
					city: values.city,
					country: values.country,
					id: this.props.match.params.propertyId,
					postalCode: values.postalCode,
					state: values.state,
					streetAddress1: values.streetAddress1,
					streetAddress2: values.streetAddress2,
				},
			});
		}

		try {
			await propertyService.updatePropertyImage(
				this.props.match.params.propertyId,
				'street-view',
				// @ts-ignore, checked abo
				this.state.file,
				this.context.accessToken
			);

			this.setState({
				editWasSuccessful: true,
				errorMessage: null,
				property: {
					city: values.city,
					country: values.country,
					id: this.props.match.params.propertyId,
					postalCode: values.postalCode,
					state: values.state,
					streetAddress1: values.streetAddress1,
					streetAddress2: values.streetAddress2,
				},
			});
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	async handleDeleteImage() {
		try {
			if (this.state.image)
				await this.props.propertyService.deletePropertyImage(
					this.props.match.params.propertyId,
					this.state?.image?.id
				);

			this.setState({
				errorMessage: null,
				image: null,
			});
		} catch (error) {
			if (error instanceof Error) {
				captureError(error);

				this.setState({
					errorMessage: translate('global.error'),
					isGettingData: false,
				});
			}
		}
	}

	render() {
		if (this.state.editWasSuccessful) {
			return (
				<Redirect
					to={{
						pathname: paths.app.homeowner.properties.validate,
						state: {
							message: successMessage,
							property: this.state.property,
						},
					}}
				/>
			);
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<React.Fragment>
				<PropertyForm
					cancelButtonPropertyId={this.props.match.params.propertyId}
					cancelButtonReferrerPath={
						this.props.location?.state?.referrer
					}
					errorMessage={this.state.errorMessage}
					handleDeleteImage={this.handleDeleteImage}
					headingContent={translate(
						'homeowner.pages.properties.form.heading'
					)}
					image={this.state.image?.path}
					initialValues={this.state.initialValues}
					onChange={this.handleFileUploadChange}
					onSubmit={this.handleSubmit}
					submitButtonContent={translate(
						'homeowner.pages.properties.form.submit'
					)}
				/>

				<section className="mt-6  rich-text form-alert form-alert-grey w-fit">
					<p className="title">
						<i className="icon icon-xs">
							<InlineSVG src={images.icons.info} />
						</i>
						{translate('global.placeholder_form')}
					</p>
				</section>
			</React.Fragment>
		);
	}
}

EditProperty.contextType = AuthContext;

export default withPropertyService(EditProperty);
