import { translate } from '../../Internationalization';
import { withAgentService } from '../../../service-container';
import AcceptedContacts from '../AcceptedContacts/AcceptedContacts';
import AdsContainer from '../../../shared/BannerAds/AdsContainer/AdsContainer';
import ContactAnalysis from '../shared/ContactAnalysis/ContactAnalysis';
import Feature from '../../../shared/Feature';
import FeaturedBlogs from '../../../Blog/FeaturedBlogs';
import React from 'react';
import ShareableLinksCard from '../../../shared/ShareableLinksCard';
import VendorsCard from '../Offers/VendorsCard/VendorsCard';
import WelcomeCard from '../../shared/WelcomeCard/WelcomeCard';

/** @extends {React.Component<>} */
export class Dashboard extends React.Component {
	// eslint-disable-next-line class-methods-use-this
	render() {
		return (
			<React.Fragment>
				<WelcomeCard />
				<section className="app-responsive-area app-responsive-sidebar-grid">
					<section className="responsive-main app-block-list">
						<AdsContainer userRole="agent" />
						<Feature fallback={null} name="agent:contact_analysis">
							<ContactAnalysis />
						</Feature>
						<AcceptedContacts />
						<VendorsCard />
					</section>
					<section className="responsive-secondary app-block-list">
						<ShareableLinksCard />
						<FeaturedBlogs
							showFeatureImage={false}
							title={translate(
								'agent.pages.dashboard.blog.title'
							)}
							userRole="agent"
						/>
					</section>
				</section>
			</React.Fragment>
		);
	}
}

// @ts-ignore until service container types are fixed
export default withAgentService(Dashboard);
