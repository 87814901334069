/**
 * @typedef {import('../../../shared/OfferService/OffersProps').default} Props
 * @typedef {import('../OfferFields').default} OfferFields
 * @typedef {import('./ViewOffersState').default} State
 * @typedef {import('./ViewOffersState').Value} BodyData
 */
import { string } from '@mooveguru/js-utilities';
import { translate } from '../../../Internationalization';
import { withOfferService } from '../../../../service-container';
import BaseTable from '../../../../shared/BaseTable';
import captureError from '../../../../utils/captureError';
import categoryList from '@mooveguru/yhh-shared-config/marketplace-categories.json';
import FormSuccessMessages from '../../../../shared/Forms/Messages/FormSuccessMessages';
import images from '../../../../config/local/images';
import LinkButton from '../../../../shared/LinkButton';
import Loading from '../../../../shared/Loading';
import Pagination from '../../../../shared/Pagination';
import paths from '../../../../config/local/paths';
import React from 'react';
import TableButton from '../../../../shared/TableButton';

/** @extends {React.Component<Props, State>} */
export class ViewOffers extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			currentPageNumber: 1,
			isGettingData: true,
			lastPageNumber: 1,
			offers: [],
			totalRecord: 0,
		};

		this._setTableData = this._setTableData.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			await this._setTableData();
		} catch (error) {
			captureError(error);

			this.setState({
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {string} category
	 * @returns {string}
	 */
	static #resolveCategory(category) {
		// @ts-ignore category can potentially not be in JSON category list
		return categoryList[category] ?? category;
	}

	/**
	 * @param {number} pageNumber
	 * @returns {Promise<void>}
	 */
	async _setTableData(pageNumber = 1) {
		try {
			const response = await this.props.offerService.getDomainOffers(
				pageNumber
			);

			this.setState({
				currentPageNumber: pageNumber,
				isGettingData: false,
				lastPageNumber: response.pages ?? 1,
				offers: response.offers,
				totalRecord: response.total ?? 0,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				isGettingData: false,
			});
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		const headerKeyList = [
			'vendor_name',
			'vendor_category',
			'vendor_desc',
			'vendor_zip',
			'status',
			'edit',
		];
		const tableHeaderData = headerKeyList.map((key) => ({
			title: translate(`agent.pages.offers.view.table.${key}`),
		}));

		/**
		 * @type {{data: string | JSX.Element | number | null | string[] | boolean; index?: string;}[][]}
		 */
		const tableBodyData = this.state.offers.map((offer, index) => [
			{
				data: offer.vendorName,
				index: `contact-${index}`,
			},
			{
				data: ViewOffers.#resolveCategory(offer.offerCategory),
				index: `contact-${index}`,
			},
			{
				data: string.truncate(offer.offerDescription, 50),
				index: `contact-${index}`,
			},
			{
				data: offer.offerZip,
				index: `contact-${index}`,
			},
			{
				data: offer.status,
				index: `contact-${index}`,
			},
			{
				data: (
					<TableButton
						icon={images.icons.edit}
						to={{
							pathname: paths.app.admin.offers.edit,
							state: { offerId: offer.id },
						}}
					/>
				),
			},
		]);

		return (
			<React.Fragment>
				<header className="mt-10 mb-6 md:flex justify-between items-center">
					<h1 className="hl-ms-6 mb-6 md:mb-0">
						{`${translate('admin.pages.offers.view.title')} (${
							this.state.totalRecord
						})`}
					</h1>

					<section className="button-group">
						<LinkButton
							icon={images.icons.eye}
							to={paths.app.admin.offers.groups}
						>
							{translate('admin.pages.offers.view.view_groups')}
						</LinkButton>

						<LinkButton
							icon={images.icons.plus}
							to={paths.app.admin.offers.add}
						>
							{translate('global.offers.add_title')}
						</LinkButton>
					</section>
				</header>

				{this.props.location?.state?.message ? (
					<FormSuccessMessages
						messages={this.props.location.state.message}
					/>
				) : null}

				<BaseTable
					// @ts-ignore -- will be fixed with BaseTable
					bodyData={tableBodyData}
					className="w-full"
					errorMessage={translate('admin.pages.offers.view.no_data')}
					headerData={tableHeaderData}
				/>

				<Pagination
					currentPageNumber={this.state.currentPageNumber}
					lastPageNumber={this.state.lastPageNumber}
					setCurrentPageNumber={this._setTableData}
				/>
			</React.Fragment>
		);
	}
}

export default withOfferService(ViewOffers);
