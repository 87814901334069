/**
 * @typedef {import('./ImageData').default} ImageData
 * @typedef {import('../PropertyData').default} PropertyData
 * @typedef {import('../../shared/PropertyService/EditablePropertyDetails').default} EditablePropertyDetails
 */
import apiUrls from '../../../config/local/api-urls';
import HttpClient from '@mooveguru/js-http-client';
// @ts-ignore
const httpClient = new HttpClient();

/**
 * @param {PropertyData} property
 * @param {string} accessToken
 * @returns {Promise<void>}
 * @throws {Error}
 */
export async function addProperty(property, accessToken) {
	const homeownerResponse = await httpClient.get(
		apiUrls.me.homeowner.root,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!homeownerResponse.isOk) {
		throw new Error(homeownerResponse.body.message);
	}

	const response = await httpClient.post(
		`${apiUrls.property.root}/${homeownerResponse.body.results.id}`,
		new Headers({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
		}),
		{
			title: property.title,
			is_primary: property.isPrimary,
			street_address_1: property.address.streetAddress1,
			street_address_2: property.address.streetAddress2,
			city: property.address.city,
			state: property.address.state,
			postal_code: property.address.postalCode,
			country: 'US',
		}
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}

	return response.body.results.property_id;
}

/**
 * @param {string} propertyId
 * @param {string} accessToken
 * @throws {Error}
 */
export async function getProperty(propertyId, accessToken) {
	const response = await httpClient.get(
		`${apiUrls.property.root}/${propertyId}`,
		new Headers({ Authorization: `Bearer ${accessToken}` })
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}

	const results = response.body.results;

	return {
		address: {
			streetAddress1: results.address.street_address_1,
			streetAddress2: results.address.street_address_2,
			city: results.address.city,
			state: results.address.state,
			postalCode: results.address.postal_code,
			country: results.address.country,
		},
		details: {
			fireplace: results.propertyDetails.fireplace,
			garage: results.propertyDetails.garageType,
			pool: results.propertyDetails.pool,
			buildYear: results.propertyDetails.yearBuilt,
			constructionType: results.propertyDetails.typeConstruction,
			foundationType: results.propertyDetails.foundation,
			roof: results.propertyDetails.roofType,
			stories: results.propertyDetails.noOfStories,
			heating: results.propertyDetails.heating,
			heatingFuelType: results.propertyDetails.heatingFuelType,
			amountToLoan: results.propertyDetails.loanAmount,
			loanType: results.propertyDetails.loanType,
			dueDate: results.propertyDetails.dueDate,
			lenderName: results.propertyDetails.lenderName,
			rate: results.propertyDetails.interestRate,
			maximumInterestRate: results.propertyDetails.maximumInterestRate,
			originalDateOfContract:
				results.propertyDetails.originalDateOfContract,
			numberOfBaths: results.propertyDetails.numberOfBaths,
			numberOfPartialBaths: results.propertyDetails.numberOfPartialBaths,
			bedrooms: results.propertyDetails.numberOfBedrooms,
			otherRooms: results.propertyDetails.otherRooms,
			totalNumberOfRooms: results.propertyDetails.totalNumberOfRooms,
			acreage: results.propertyDetails.lotsizeAcres,
			lotSizeSquareFootage: results.propertyDetails.lotsizeSquareFeet,
			sewer: results.propertyDetails.sewer,
			water: results.propertyDetails.water,
		},
		id: results.id,
		image:
			results.images?.find(
				/**
				 * @param {ImageData} image
				 */
				(image) => image.type === 'street_view'
			) ?? '',
		isPrimary: results.is_primary,
		title: results.title,
	};
}

/**
 * @param {string} id
 * @param {EditablePropertyDetails} data
 * @param {string} accessToken
 * @returns {Promise<void>}
 * @throws {Error}
 */
export async function updateProperty(id, data, accessToken) {
	const mappedData = {
		acreage: data?.details?.acreage,
		amount_to_loan: data?.details?.amountToLoan,
		basement: data?.details?.basement,
		bedrooms: data?.details?.bedrooms,
		build_year: data?.details?.buildYear,
		city: data?.address?.city,
		construction_type: data?.details?.constructionType,
		country: data?.address?.country,
		due_date: data?.details?.dueDate,
		fireplace: data?.details?.fireplace,
		floor_cover: data?.details?.floorCover,
		foundation_type: data?.details?.foundationType,
		garage_cars: data?.details?.garageCars,
		garage_type: data?.details?.garageType,
		heating: data?.details?.heating,
		heating_fuel_type: data?.details?.heatingFuelType,
		interior_walls: data?.details?.interiorWalls,
		lender_name: data?.details?.lenderName,
		loan_type: data?.details?.loanType,
		lot_size_square_footage: data?.details?.lotSizeSquareFootage,
		maximum_interest_rate: data?.details?.maximumInterestRate,
		number_of_baths: data?.details?.numberOfBaths,
		number_of_partial_baths: data?.details?.numberOfPartialBaths,
		original_date_of_contract: data?.details?.originalDateOfContract,
		other_rooms: data?.details?.otherRooms,
		plumbing_fixtures: data?.details?.plumbingFixtures,
		pool: data?.details?.pool,
		postal_code: data?.address?.postalCode?.toString(),
		rate: data?.details?.rate,
		roof_cover: data?.details?.roofCover,
		roof_type: data?.details?.roofType,
		sewer: data?.details?.sewer,
		state: data?.address?.state,
		stories: data?.details?.stories,
		street_address_1: data?.address?.streetAddress1,
		street_address_2: data?.address?.streetAddress2,
		style: data?.details?.style,
		title: data?.title,
		total_number_of_rooms: data?.details?.totalNumberOfRooms,
		water: data?.details?.water,
	};

	const response = await httpClient.put(
		`${apiUrls.properties.root}/${id}`,
		new Headers({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
		}),
		mappedData
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}
}

/**
 * @param {string} id
 * @param {string} imageType
 * @param {File} file
 * @param {string} accessToken
 * @returns {Promise<void>}
 * @throws {Error}
 */
export async function updatePropertyImage(id, imageType, file, accessToken) {
	if (!file) {
		return;
	}
	const formData = new FormData();

	formData.append('files', file);

	const response = await httpClient.put(
		`${apiUrls.properties.root}/${id}/images/${imageType}`,
		new Headers({
			Authorization: `Bearer ${accessToken}`,
		}),
		formData
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}
}
