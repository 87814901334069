/**
 * @typedef {import('./Group').default} Group
 * @typedef {import('./ViewGroupProps').default} Props
 * @typedef {import('./ViewGroupState').default} State
 * @typedef {import('../../shared/SubscriptionService/Subscription').default} Subscription
 */

import { AuthContext } from '../../../shared/AuthProvider';
import { Redirect } from 'react-router-dom';
import { translate } from '../../Internationalization';
import BaseIcon from '../../../shared/BaseIcon';
import BaseTable from '../../../shared/BaseTable';
import captureError from '../../../utils/captureError';
import concatPaths from '../../../utils/contactPaths';
import formatPhoneNumberForDisplay from 'utils/formatPhoneNumberForDisplay';
import FormWarningMessages from '../../../shared/Forms/Messages/FormWarningMessages';
import images from '../../../config/local/images';
import LinkButton from '../../../shared/LinkButton';
import Loading from '../../../shared/Loading';
import Pagination from '../../../shared/Pagination';
import paths from '../../../config/local/paths';
import React from 'react';
import TableButton from '../../../shared/TableButton';

/** @extends {React.Component<Props, State>} */
export default class ViewGroups extends React.Component {
	/**  @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State}  */
		this.state = {
			bodyData: [],
			currentPageNumber: 1,
			isGettingData: true,
			isSubscribed: false,
			lastPageNumber: 1,
			message: null,
			path: '',
			redirect: false,
			totalRecord: 0,
		};

		this.headerData = [
			{ title: 'Name' },
			{ title: 'Phone' },
			{ title: 'Address' },
			{ title: 'Parent Group' },
			{ title: 'Type' },
			{ title: 'Co-brand' },
			{ title: 'Edit' },
			{ title: 'Branding' },
			{ title: 'Import Agents' },
		];

		this._setTopGroupData = this._setTopGroupData.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		const headerContainsSubscription = this.headerData.find(
			(header) => header.title === 'Subscribe'
		);

		if (
			this.context.roles.includes('manager') &&
			!headerContainsSubscription
		) {
			this.headerData.push({ title: 'Subscribe' });
		}

		try {
			await this._setTopGroupData();
		} catch (error) {
			captureError(error);
			this.setState({
				isGettingData: false,
				message: translate('global.no_content.message'),
			});
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.message) {
			return <p>{this.state.message}</p>;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		if (this.state.redirect) {
			return this.#renderRedirect();
		}

		return (
			<React.Fragment>
				<header className="mt-10 mb-6 md:flex justify-between items-center">
					<h1 className="hl-ms-6 mb-6 md:mb-0">
						{`${translate('global.forms.inputs.groups.label')} (${
							this.state.totalRecord
						})`}
					</h1>

					<LinkButton
						icon={images.icons.plus}
						to={paths.app.admin.groups.add}
					>
						{translate('global.forms.inputs.groups.add')}
					</LinkButton>
				</header>

				{!!this.props.location?.state?.message && (
					<FormWarningMessages
						heading={this.props.location.state.heading}
						messages={this.props.location.state.message}
					/>
				)}
				{/* @ts-ignore issue with BaseTable props */}
				<BaseTable
					bodyData={this.state.bodyData}
					headerData={this.headerData}
				/>

				<Pagination
					currentPageNumber={this.state.currentPageNumber}
					lastPageNumber={this.state.lastPageNumber}
					setCurrentPageNumber={this._setTopGroupData}
				/>
			</React.Fragment>
		);
	}

	/**
	 * @param {string} address
	 * @returns {string}
	 */
	static #formatAddress(address) {
		const streetAndCity = address.split(',')[0].replaceAll(' ', '').length;
		const stateAndPostal = address.split(',')[1].replaceAll(' ', '').length;

		if (streetAndCity && stateAndPostal) {
			return address;
		}

		let result = '';
		if (streetAndCity) {
			result = `${address.split(',')[0].trim()}`;
		}
		if (stateAndPostal) {
			result = `${address.split(',')[1].trim()}`;
		}

		return result;
	}

	/**
	 * @protected
	 * @param {number} page
	 * @returns {Promise<void>}
	 */
	async _setTopGroupData(page = 1) {
		const currentGroups = await this.props.groupService.searchGroup(
			'',
			page
		);

		const dataMap = currentGroups.data
			.filter(
				/**
				 * @param {Group | null} group
				 * @returns {boolean}
				 */
				(group) => group !== null
			)
			.map(
				/**
				 * @param {Group} group
				 * @returns {{[key: string]: string | JSX.Element}}
				 */
				(group) => {
					const address = `${group.streetAddress2} ${group.streetAddress1} ${group.city}, ${group.state} ${group.postalCode}`;
					return {
						// Properties in order of table headers
						groupName: this.#getGroupName(group),
						phoneNumber:
							formatPhoneNumberForDisplay(group?.phone) || '—',
						/* eslint-disable-next-line sort-keys  -- sorted based on table header*/
						address: ViewGroups.#formatAddress(address),
						parentName: group.parentName || '—',
						type: translate(
							`admin.groups.form.group_descriptors.${group.type}`
						),
					};
				}
			);

		const bodyData = dataMap.map(
			/**
			 * @param {Group} group
			 * @returns {{data: string | null}[]}
			 */
			(group) => Object.values(group).map((data) => ({ data }))
		);

		this.#addCoBrand(currentGroups.data, bodyData);
		ViewGroups.#addEdit(currentGroups.data, bodyData);
		ViewGroups.#addEditTheme(currentGroups.data, bodyData);
		ViewGroups.#addImport(currentGroups.data, bodyData);

		if (this.context.roles.includes('manager')) {
			ViewGroups.#addSubscription(currentGroups.data, bodyData);
		}

		this.setState({
			bodyData,
			currentPageNumber: page,
			isGettingData: false,
			lastPageNumber: currentGroups.pages,
			totalRecord: currentGroups.total ?? 0,
		});
	}

	/**
	 * @param {Group} group
	 * @returns {string | JSX.Element}
	 */
	#getGroupName(group) {
		if (
			!group.isSubscribed ||
			!this.props.settings.features.get('admin:groups_subscription_badge')
		) {
			return group.name;
		}

		return (
			<React.Fragment>
				<BaseIcon defaultSize={true} icon={images.icons.subscription} />
				<span className="mx-3">{group.name}</span>
			</React.Fragment>
		);
	}

	/**
	 * @param {string} groupId
	 * @param {boolean} cobranding
	 * @returns {string}
	 */
	static #getSubscribedPath(groupId, cobranding) {
		if (cobranding) {
			return paths.app.admin.agents.view;
		}

		return `${paths.app.admin.groups.edit}/${groupId}`;
	}

	/**
	 * @param {string} groupId
	 * @param {boolean} cobranding
	 * @param {boolean} isSubscribed
	 * @returns {Promise<Void>}
	 */
	async _handleClick(groupId, cobranding, isSubscribed) {
		let path = concatPaths(
			paths.app.admin.groups.root,
			`/${groupId}/subscription`
		);

		if (isSubscribed) {
			path = ViewGroups.#getSubscribedPath(groupId, cobranding);
		}

		this.setState({
			isSubscribed,
			path,
			redirect: true,
		});
	}

	/**
	 * @returns {JSX.Element}
	 */
	#renderRedirect() {
		const path = this.state.path;

		if (this.state.isSubscribed) {
			return <Redirect to={path} />;
		}

		return (
			<Redirect
				to={{
					pathname: path,
					state: {
						cobranding: true,
					},
				}}
			/>
		);
	}

	/**
	 * @param {Group[]} data
	 * @param {{data: string | null | JSX.Element}[][]} bodyData
	 * @returns {void}
	 */
	#addCoBrand(data, bodyData) {
		data?.forEach((group, index) => {
			bodyData[index]?.push({
				data: (
					<button
						className="text-theme-dark font-bold"
						onClick={() =>
							this._handleClick(
								group.id,
								group.cobranding,
								group.isSubscribed ?? false
							)
						}
						type="button"
					>
						{translate(
							`admin.groups.view.table.${group.cobranding}`
						)}
					</button>
				),
			});
		});
	}

	/**
	 * @param {Group[]} data
	 * @param {{data: string | null | JSX.Element}[][]} bodyData
	 * @returns {void}
	 */
	static #addEdit(data, bodyData) {
		data?.forEach((group, index) => {
			bodyData[index]?.push({
				data: (
					<TableButton
						icon={images.icons.edit}
						title={translate('admin.groups.view.table.edit')}
						to={`${paths.app.admin.groups.edit}/${group.id}`}
					/>
				),
			});
		});
	}

	/**
	 * @param {Group[]} data
	 * @param {{data: string | null | JSX.Element}[][]} bodyData
	 * @returns {void}
	 */
	static #addEditTheme(data, bodyData) {
		data?.forEach((group, index) => {
			bodyData[index]?.push({
				data: (
					<TableButton
						icon={images.icons.theme}
						title={translate(
							'admin.groups.view.table.edit_branding'
						)}
						to={concatPaths(
							paths.app.admin.groups.root,
							group.id,
							'theme'
						)}
					/>
				),
			});
		});
	}

	/**
	 * @param {Group[]} data
	 * @param {{data: string | null | JSX.Element}[][]} bodyData
	 * @returns {void}
	 */
	static #addImport(data, bodyData) {
		data?.forEach((group, index) => {
			bodyData[index]?.push({
				data: (
					<TableButton
						icon={images.icons.import}
						title={translate(
							'admin.groups.view.table.import_agents'
						)}
						to={{
							pathname: concatPaths(
								paths.app.admin.groups.root,
								group.id,
								'agents/import'
							),
							state: {
								referrer: `${location.pathname}${location.search}${location.hash}`,
							},
						}}
					/>
				),
			});
		});
	}

	/**
	 * @param {Group[]} data
	 * @param {{data: string | null | JSX.Element}[][]} bodyData
	 * @returns {void}
	 */
	static #addSubscription(data, bodyData) {
		data?.forEach((group, index) => {
			bodyData[index]?.push({
				data: (
					<TableButton
						icon={images.icons.subscription}
						title={translate(
							'admin.groups.view.table.subscribe_group'
						)}
						to={concatPaths(
							paths.app.admin.groups.root,
							`/${group.id}/subscription`
						)}
					/>
				),
			});
		});
	}
}

ViewGroups.contextType = AuthContext;
