/**
 * @typedef {import('../AgentService/Agent').AdBanner} AdBanner
 * @typedef {import('../AgentService/Agent').AdBannerData} AdBannerData
 * @typedef {import('./Address').default} Address
 * @typedef {import('./Agent').default} Agent
 * @typedef {import('./Dependencies').default} Dependencies
 * @typedef {import('./GetsHomeownerAgent').default} GetsHomeownerAgent
 * @typedef {import('./GetsPrimaryPropertyAddress').default} GetsPrimaryPropertyAddress
 * @typedef {import('./Homeowner').GetHomeownerPersonalData} GetHomeownerPersonalData
 * @typedef {import('./Homeowner').Homeowner} Homeowner
 * @typedef {import('./Homeowner').HomeownerAgent} HomeownerAgent
 * @typedef {import('./Homeowner').HomeownerPersonalData} HomeownerPersonalData
 * @typedef {import('./Homeowner').HomeownerTeamMember} HomeownerTeamMember
 * @typedef {import('./Homeowner').HomeownerTeamMemberResponse} HomeownerTeamMemberResponse
 * @typedef {import('./Homeowner').UpdateHomeowner} UpdateHomeowner
 */

import { toAbsoluteUrl } from '../../../utils/toAbsoluteUrl';
import apiUrls from '../../../config/local/api-urls';
import formatPhoneNumberForData from '../../../utils/formatPhoneNumberForData';
import images from '../../../config/local/images';
import mapPropertyDetails from './mapPropertyDetails';

/**
 * @implements {GetsHomeownerAgent}
 * @implements {GetsPrimaryPropertyAddress}
 */
export default class HomeownerService {
	/** @param {Dependencies} dependencies */
	constructor(dependencies) {
		this.authService = dependencies.authService;
		this.httpClient = dependencies.httpClient;
	}

	/**
	 * @returns {Promise<HomeownerAgent>}
	 * @throws {Error}
	 */
	// eslint-disable-next-line complexity
	async getHomeownerAgent() {
		const response = await this.httpClient.get(
			apiUrls.me.homeowner.agent,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const results = response.body.agent;

		return {
			email: results.email,
			headshotUrl: results.headshot
				? `${apiUrls.root}/${results.headshot}`
				: null,
			id: results.id,
			name: HomeownerService.parseNameData(results.name),
			networkVendors: results.network_vendors,
			networkVendorsIdList: results.network_vendors_id_list,
			occupation: results.occupation ?? null,
			phone: {
				landline: results.phone?.landline ?? null,
				mobile: results.phone?.mobile ?? null,
			},
			primaryGroup: results.primaryGroup
				? {
						id: results.primaryGroup.id,
						name: results.primaryGroup.name,
				  }
				: null,
			slug: results.slug,
			websiteUrl: results?.website_url ?? null,
		};
	}

	/**
	 * @returns {Promise<HomeownerTeamMember[]>}
	 * @throws {Error}
	 */
	async getHomeownerTeam() {
		const response = await this.httpClient.get(
			apiUrls.homeowners.team,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const results = response.body.team_members;

		if (!results) {
			return [];
		}

		return results.map(
			/**
			 *  @param {HomeownerTeamMemberResponse} teamMember
			 */
			(teamMember) => ({
				email: teamMember.email,
				groupName: teamMember.group_name,
				groups: HomeownerService.#mapGroups(teamMember?.groups),
				headshotUrl: toAbsoluteUrl(
					teamMember.headshot_url ?? images.avatar
				),
				id: teamMember.id,
				isManager: teamMember.is_manager,
				name: teamMember.name,
				occupation: teamMember.occupation ?? 'agent',
				phoneNumber: teamMember.phone_number,
				primaryGroup:
					HomeownerService.#mapGroups(teamMember?.groups).find(
						(group) => group.isPrimary
					) ?? null,
			})
		);
	}

	/**
	 *
	 * @param {HomeownerTeamMemberResponse['groups']} groups
	 * @returns {HomeownerTeamMember['groups']}
	 */
	static #mapGroups(groups) {
		if (!groups) {
			return [];
		}

		return groups?.map((group) => ({
			adBanner: HomeownerService.#mapAdBanner(group?.ad_banner),
			cobranding: group?.cobranding,
			id: group?.id,
			isPrimary: group?.is_primary,
		}));
	}

	/**
	 * @param {AdBannerData[]} adBanner
	 * @returns {AdBanner[]}
	 */
	static #mapAdBanner(adBanner) {
		if (!adBanner?.length) {
			return [];
		}

		return adBanner?.map((banner) => ({
			active: banner?.active,
			brand: banner?.brand,
			location: {
				agentDashboard: banner?.location?.agent_dashboard,
				homeownerDashboard: banner?.location?.homeowner_dashboard,
			},
		}));
	}

	/**
	 * @param {string} homeownerId
	 * @returns {Promise<Homeowner>}
	 * @throws {Error}
	 */
	async getHomeownerById(homeownerId) {
		const response = await this.httpClient.get(
			`${apiUrls.homeowner.getInfo}/${homeownerId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const results = response.body.results[0];

		const homeownersAgent = results.agents.find(
			/**
			 * @param {Agent} agent
			 * @returns {boolean}
			 */
			(agent) => results?.agent_id === agent._id
		);

		return {
			agent: results.agents.map(
				/**
				 * @param {Agent} agent
				 * @returns {any}
				 */
				(agent) => agent._id
			),
			agentName:
				HomeownerService.parseNameData(homeownersAgent?.name) ?? '',
			city: results?.address?.city,
			country: results?.address?.country,
			email: results?.email,
			firstName: results?.name?.first,
			landline: results?.phone?.landline,
			lastName: results?.name?.last,
			mobile: results?.phone?.mobile,
			postalCode: results?.address?.postal_code,
			salutation: results?.salutation,
			state: results?.address?.state,
			streetAddress1: results?.address?.street_address_1,
			streetAddress2: results?.address?.street_address_2,
		};
	}

	/**
	 *  @returns {Promise<GetHomeownerPersonalData>}
	 *  @throws {Error}
	 */
	async getHomeownerPersonalData() {
		const response = await this.httpClient.get(
			`${apiUrls.me.homeowner.root}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		return response.body.results;
	}

	/**
	 * @returns {Promise<Address>}
	 * @throws {Error}
	 */
	async getHomeownerPrimaryPropertyAddress() {
		const response = await this.httpClient.get(
			apiUrls.me.homeowner.properties.primary,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const results = response.body.results;

		return {
			city: results.address.city,
			country: results.address.country,
			postalCode: results.address.postal_code,
			state: results.address.state,
			streetAddress1: results.address.street_address_1,
			streetAddress2: results.address.street_address_2,
		};
	}

	/**
	 * @param {string} [propertyId]
	 * @returns {Promise<any>}
	 *  @throws {Error}
	 */
	async getHomeownerPrimaryProperty(propertyId) {
		const response = await this.httpClient.get(
			`${apiUrls.properties.root}/${propertyId ?? ''}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body[0]);
		}

		return mapPropertyDetails(response.body);
	}

	/**
	 * @returns {Promise<string>}
	 * @throws {Error}
	 */
	async getTenant() {
		const response = await this.httpClient.get(
			`${apiUrls.me.tenant}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		return response.body.tenant_id;
	}

	/**
	 * @param {UpdateHomeowner} parameters
	 * @param {string} homeownerId
	 * @returns {Promise<void>}
	 * @throws {Error}
	 */
	/* eslint-disable camelcase -- Mapping request body */
	async updateHomeownerData(parameters, homeownerId) {
		const homeowner = {
			_id: homeownerId,
			address: {
				city: parameters.city,
				country: parameters.country,
				postal_code: parameters.postalCode,
				state: parameters.state,
				street_address_1: parameters.streetAddress1,
				street_address_2: parameters.streetAddress2,
			},
			agent_id: parameters.agent,
			email: parameters.email,
			name: { first: parameters.firstName, last: parameters.lastName },
			phone: {
				landline: formatPhoneNumberForData(parameters.landline),
				mobile: formatPhoneNumberForData(parameters.mobile),
			},
			salutation: parameters.salutation,
		};
		/* eslint-enable camelcase */

		const response = await this.httpClient.put(
			`${apiUrls.homeowner.root}/${homeownerId}`,
			new Headers({
				'Content-Type': 'application/json',
				// eslint-disable-next-line sort-keys -- no need to sort
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			homeowner
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}
	}

	/**
	 * @param {HomeownerPersonalData} values
	 * @returns {Promise<void>}
	 * @throws {Error}
	 */
	async updateHomeownerPersonalData(values) {
		const response = await this.httpClient.put(
			`${apiUrls.me.homeowner.root}`,
			new Headers({
				'Content-Type': 'application/json',
				// eslint-disable-next-line sort-keys -- no need to sort
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			{
				email: values.email,
				name: {
					first: values.firstName,
					last: values.lastName,
				},
				phone: {
					landline: formatPhoneNumberForData(values.homePhoneNumber),
					mobile: formatPhoneNumberForData(values.mobilePhoneNumber),
				},
			}
		);
		if (!response.isOk) {
			throw new Error(response.body.message);
		}
	}

	/**
	 * @param {number=} page
	 * @returns {Promise<any>}
	 * @throws {Error}
	 */
	async getHomeowners(page = 1) {
		const response = await this.httpClient.get(
			`${apiUrls.me.administrator.homeowners}/?page=${page}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const { data, pages, total } = response.body.results;

		return { data, pages, total };
	}

	/**
	 * @param {{readonly first: string|null, readonly last: string|null}} name
	 * @returns {string | null}
	 */
	static parseNameData(name) {
		if (name.first && name.last) {
			return `${name.first} ${name.last}`;
		}
		if (name.first) {
			return name.first;
		}
		if (name.last) {
			return name.last;
		}
		return null;
	}
}
