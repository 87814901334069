import React from 'react';
import { Redirect } from 'react-router-dom';

import EditContact from 'App/Admin/Users/Contacts/EditContact';
import ViewContacts from 'App/Admin/Users/Contacts/ViewContacts';
import paths from 'config/local/paths';
import Page from 'shared/Page';
import Switch from 'shared/Switch';
import { translate } from 'App/Internationalization';
import {
	withContactService,
	withAdministratorService,
} from 'service-container';
import CreateContact from './CreateContact';

const InjectedViewContacts = withContactService(ViewContacts);
const InjectedEditContact = withAdministratorService(
	withContactService(EditContact)
);
const InjectedCreateContact = withAdministratorService(
	withContactService(CreateContact)
);

export default class Contacts extends React.Component {
	render() {
		return (
			<Switch>
				<Redirect
					exact
					from={paths.app.admin.contacts.root}
					to={paths.app.admin.contacts.view}
				/>
				<Page
					component={InjectedViewContacts}
					path={paths.app.admin.contacts.view}
					title={translate('admin.nav.contacts.view')}
				/>
				<Page
					component={InjectedEditContact}
					path={`${paths.app.admin.contacts.edit}/:contactId`}
					title={translate('admin.nav.contacts.edit')}
				/>
				<Page
					component={InjectedCreateContact}
					path={paths.app.admin.contacts.add}
					title={translate('admin.nav.contacts.add')}
				/>
			</Switch>
		);
	}
}
