/**
 * @typedef {import('./Props').default} Props
 */
import FieldRadioGroup from '../../../../shared/Forms/Fields/FieldRadioGroup';
import React from 'react';
import { translate } from '../../../Internationalization';

/** @extends {React.Component<Props>} */
export default class GroupSelection extends React.Component {
	/**
	 * @returns {[ string, string ][]}
	 */
	#getOptions() {
		/** @type {[string, string][]} */
		const options = [
			['', translate('admin.groups.group_selection.no_group')],
		];

		this.props.groups.forEach((group) => {
			options.push([group.id, group.name]);
		});

		return options.sort(GroupSelection.#sortByName);
	}

	/**
	 * @param {string[]} a
	 * @param {string[]} b
	 * @returns {number}
	 */
	static #sortByName(a, b) {
		return a[1] < b[1] ? -1 : 1;
	}

	/** @returns {JSX.Element} */
	render() {
		return (
			<fieldset className="mb-8">
				<legend className="h-ms-3 mb-6">
					{translate('admin.groups.group_selection.label')}
				</legend>

				<FieldRadioGroup
					name={'groupId'}
					onClick={this.props.onClick}
					required={true}
					values={this.#getOptions()}
				/>
			</fieldset>
		);
	}
}
