/** @typedef {import('./Props').default} Props */
/** @typedef {import('./State').default} State */

import { ThemeContext, withTheme } from '../../../../shared/ThemeProvider';
import { translate } from '../../../Internationalization';
import ExternalAnchorButton from '../../../../shared/ExternalAnchorButton';
import occupations from '@mooveguru/yhh-shared-config/users/agent/occupations.json';
import ProfileImage from '../../../../shared/ProfileImage';
import React from 'react';
import Feature from '../../../../shared/Feature';

// TODO: Remove Old AgentCard and Add This Card to Applicable Pages

/** @extends React.Component<Props>*/
export class AgentCard extends React.Component {
	/** @returns {JSX.Element | null} */
	render() {
		if (!this.props.theme.agentId) {
			return null;
		}

		const getInContactButton = (
			<ExternalAnchorButton
				className="button button-outline"
				href={
					this.props.theme.agentPhoneNumber
						? `tel:${this.props.theme.agentPhoneNumber}`
						: `mailto:${this.props.theme.agentEmail}`
				}
			>
				{translate('homeowner.components.cta.contact')}
			</ExternalAnchorButton>
		);

		const phoneAndEmailButtons = (
			<React.Fragment>
				<ExternalAnchorButton
					className="button button-outline"
					href={`tel:${this.props.theme.agentPhoneNumber}`}
				>
					{translate('homeowner.pages.dashboard.agent.call')}
				</ExternalAnchorButton>
				<ExternalAnchorButton
					className="button button-outline"
					href={`mailto:${this.props.theme.agentEmail}`}
				>
					{translate('homeowner.pages.dashboard.agent.email')}
				</ExternalAnchorButton>
			</React.Fragment>
		);

		const contactText =
			this.props.theme.teamList.length >= 1 &&
			this.props.theme.teamList[0]?.primaryGroup?.cobranding
				? translate(
						'homeowner.pages.dashboard.agent.occupation.contact_team'
				  )
				: translate(
						`homeowner.pages.dashboard.agent.occupation.${this.props.theme.agentOccupation}`
				  );

		return (
			<section className="card card-contact">
				<header className="card-header">
					<h3 className="card-title">
						{translate(
							'homeowner.pages.dashboard.agent.occupation.contact',
							contactText
						)}
					</h3>
				</header>
				<section className="px-10 text-lg">
					{this.props.theme.teamList?.map((member, index) => (
						<section key={member.email}>
							<hr
								className={` bg-black border-black h-1 w-50 ${
									index > 0 ? 'my-6' : 'mb-6'
								} `}
							/>
							<section
								className="flex justify-center"
								key={member.email}
							>
								<div className="rich-text rich-text-tight">
									<h2>{member.name}</h2>
									<Feature
										fallback={null}
										name="group:show_cobranding"
									>
										<p className="mb-1">
											{
												// @ts-ignore
												occupations[
													member.occupation
												] ?? 'Loan Officer'
											}
										</p>
									</Feature>
									<h3>{member.groupName}</h3>
								</div>
								<ProfileImage
									altText={`${member.name}'s headshot`}
									className="avatar avatar-lg border-theme-dark mr-3"
									headshotUrl={
										member.headshotUrl ?? undefined
									}
								/>
							</section>
							<footer className="button-group my-4">
								{!member.phoneNumber || !member.email
									? getInContactButton
									: phoneAndEmailButtons}
							</footer>
						</section>
					))}
				</section>
			</section>
		);
	}
}

// @ts-ignore until service container types are fixed
export default withTheme(AgentCard);

AgentCard.contextType = ThemeContext;
