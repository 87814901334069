import { translate } from '../../../App/Internationalization';
import FormMessages from './FormMessages';
import images from '../../../config/local/images';
import React from 'react';

export default class FormWarningMessages extends React.Component {
	render() {
		return (
			<FormMessages
				className={`${this.props.className} form-alert-warning`}
				heading={
					this.props.heading ?? translate('global.messages.warning')
				}
				icon={this.props.icon ?? images.icons.alert}
				messages={this.props.messages}
			/>
		);
	}
}
