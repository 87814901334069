const apiUrl = 'https://home.homekeepr.com/wp-json/wp/v2';

export default {
	api: {
		posts: `${apiUrl}/posts`,
	},
	perPage: {
		listing: 12,
		recent: 3,
	},
	siteName: 'homekeepr',
	url: 'https://blog.homekeepr.com',
};
