/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */

import { AuthContext } from '../../../../../shared/AuthProvider';
import { chartColor } from '../../../../../utils/chartColor';
import { translate } from '../../../../Internationalization';
import BaseDonutChart from '../../../../../shared/BaseDonutChart';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export default class Movers extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		this.state = {
			chartText: 0,
			likelyPercentage: 0,
			potentialPercentage: 0,
			unlikelyPercentage: 0,
		};
	}

	/**
	 * @returns {void}
	 */
	componentDidMount() {
		this.#setPercentages();
	}

	static get #labels() {
		return [
			translate('agent.pages.dashboard.movers.labels.likely'),
			translate('agent.pages.dashboard.movers.labels.potential'),
			translate('agent.pages.dashboard.movers.labels.unlikely'),
		];
	}

	get #data() {
		return {
			datasets: [
				{
					backgroundColor: [
						chartColor.movers.green,
						chartColor.movers.yellow,
						chartColor.movers.grey,
					],
					data: [
						this.state.likelyPercentage,
						this.state.potentialPercentage,
						this.state.unlikelyPercentage,
					],
				},
			],
			labels: Movers.#labels,
		};
	}

	/**
	 * @returns {void}
	 */
	#setPercentages() {
		if(!this.props.moverCounts){
			return
		}
	
		const total = this.props.moverCounts.reduce((sum, current) => {
			const value = Object.values(current)[0];
			return sum + value;
		}, 0);

		if (total === 0) {
			return;
		}

		/** @type {{ [key in 'likelyPercentage' | 'potentialPercentage' | 'unlikelyPercentage']: number }} */
		const percentages = {
			likelyPercentage: 0,
			potentialPercentage: 0,
			unlikelyPercentage: 0,
		};

		this.props.moverCounts.forEach((obj) => {
			/** @type {'likely' | 'potential' | 'unlikely'} */
			// @ts-ignore
			const key = Object.keys(obj)[0];
			/** @type {number} */
			const value = obj[key];
			percentages[`${key}Percentage`] = Movers.#calculatePercentage(
				value,
				total
			);
		});

		const chartText = 100 - percentages.unlikelyPercentage;

		this.setState({
			chartText,
			...percentages,
		});
	}

	/**
	 * @param {number} value
	 * @param {number} total
	 * @returns {number}
	 */
	static #calculatePercentage(value, total) {
		const percentage = (value / total) * 100;
		return Math.round(percentage);
	}

	/**
	 * @returns {JSX.Element}
	 */
	/* eslint-disable class-methods-use-this -- Render method must be invokable by `this` */
	render() {
		const likely = this.state.likelyPercentage.toString();
		const potential = this.state.potentialPercentage.toString();

		return (
			<section className="md:flex justify-between mt-2">
				<article className="mr-4">
					<div className="h-ms-3">
						{translate(
							'agent.pages.dashboard.movers.labels.likely'
						)}
					</div>
					<div
						className="text-sm my-4"
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: translate(
								'agent.pages.dashboard.movers.upgraded.likely_description',
								likely
							),
						}}
					/>
					<div className="h-ms-3">
						{translate(
							'agent.pages.dashboard.movers.labels.potential'
						)}
					</div>
					<div
						className="text-sm mt-2"
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: translate(
								'agent.pages.dashboard.movers.upgraded.potential_description',
								potential
							),
						}}
					/>
				</article>
				<figure className="graph">
					<BaseDonutChart
						data={this.#data}
						hideInnerText={false}
						text={this.state.chartText}
					/>
				</figure>
			</section>
		);
	}
}

Movers.contextType = AuthContext;
