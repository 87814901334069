import formatWebsiteForDisplay from 'utils/formatWebsiteForDisplay';
import BaseInput from 'shared/Forms/Inputs/BaseInput';
import React from 'react';
import CopyToClipboard from 'shared/Links/CopyToClipboard';
import OpenInNewTab from 'shared/Links/OpenInNewTab';

/**
 * @template Values
 * @typedef {import('formik').FormikProps<Values>} FormikProps
 */

export default class InputWebsite extends React.Component {
	constructor(props) {
		super(props);

		this.state = { url: null };
		this.handleBlur = this.handleBlur.bind(this);
		this.formatValue = this.formatValue.bind(this);
	}

	/**
	 * TODO: This is a hacky way to update state on child component load
	 * 		 is there a better way to do this?
	 * @param {string} value
	 * @returns {?string}
	 */
	formatValue(value) {
		if (!value) {
			return null;
		}

		const url = formatWebsiteForDisplay(value);
		this.setState({ url });

		return url;
	}

	/**
	 * @template Values
	 * @param {React.FocusEvent<HTMLInputElement>} event
	 * @param {FormikProps<Values>} formikProps
	 */
	handleBlur(event, formikProps) {
		const { name, value } = event.target;
		const url = formatWebsiteForDisplay(value);

		formikProps.setFieldValue(name, url);
		formikProps.handleBlur(event);

		this.setState({ url });
	}

	render() {
		return (
			<div className="input-website-area">
				<BaseInput
					autocomplete="url"
					className={this.props.className}
					disabled={this.props.disabled}
					formatValue={this.formatValue}
					name={this.props.name}
					onBlur={this.handleBlur}
					placeholder={this.props.placeholder}
					required={this.props.required}
					type="url"
				/>

				<div className="button-group mt-2">
					{this.props.copyToClipboard ? (
						<CopyToClipboard url={this.state.url} />
					) : (
						''
					)}
					{this.props.openInNewTab ? (
						<OpenInNewTab url={this.state.url} />
					) : (
						''
					)}
				</div>
			</div>
		);
	}
}

InputWebsite.defaultProps = {
	className: '',
	disabled: false,
	name: '',
	placeholder: '',
	copyToClipboard: true,
	openInNewTab: true,
	required: false,
};
