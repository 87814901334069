/**
 * @typedef {import('../../../shared/AgentContactService/ContactResponse').ContactCount} ContactCount
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */
import { BulkOptions } from '../../shared/BulkOptions/BulkOptions';
import { translate } from '../../Internationalization';
import { withAgentContactService } from '../../../service-container';
import ContactTab from './ContactTab/ContactTab';
import React from 'react';
import { withAuth } from '../../../shared/AuthProvider';

const ALL_CONTACTS_TEXT = translate(
	'agent.pages.contacts.view.contact_tabs.all_contacts'
);
const MY_CONTACTS_TEXT = translate(
	'agent.pages.contacts.view.contact_tabs.my_contacts'
);

/** @extends {React.Component<Props, State>} */
export class ContactTabs extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		/**
		 * @type {State}
		 */
		this.state = {
			bulkOptionsTitle: ALL_CONTACTS_TEXT,
		};

		this._handleOptionsChange = this._handleOptionsChange.bind(this);
	}

	/**
	 * @param {Props} prevProps
	 * @returns {Promise<void>}
	 */
	async componentDidUpdate(prevProps) {
		if (this.props.deleteRan !== prevProps.deleteRan) {
			await this.props.setAllTabs();
		}
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	#renderTabs() {
		if (!this.props.groupsContactsCounts?.length) {
			return this.#renderMyContactsTab();
		}

		return (
			<React.Fragment>
				{this.#renderAllContactsTab()}
				{this.#renderMyContactsTab()}
				{this.#renderGroupContactsTab()}
			</React.Fragment>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	#renderAllContactsTab() {
		return (
			<ContactTab
				activeTab={this.props.activeTab}
				// @ts-ignore checked above
				contactCount={this.props.allContactsCount}
				isSubscribed={this.props.auth.resolveSubscription()}
				setCurrentPageNumber={this.props.setCurrentPageNumber}
				setTable={this.props.setTable}
				tab="allContacts"
				title={ALL_CONTACTS_TEXT}
			/>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	#renderMyContactsTab() {
		return (
			<ContactTab
				activeTab={this.props.activeTab}
				// @ts-ignore checked above
				contactCount={this.props.myContactsCount}
				isSubscribed={this.props.auth.resolveSubscription()}
				setCurrentPageNumber={this.props.setCurrentPageNumber}
				setTable={this.props.setTable}
				tab="myContacts"
				title={MY_CONTACTS_TEXT}
			/>
		);
	}

	/**
	 * @returns {JSX.Element[]}
	 */
	#renderGroupContactsTab() {
		// @ts-ignore checked above
		return this.props.groupsContactsCounts.map((group) => (
			<ContactTab
				activeTab={this.props.activeTab}
				contactCount={group.count}
				isSubscribed={this.props.auth.resolveSubscription(group.hasSubscription)}
				key={group.id}
				setCurrentPageNumber={this.props.setCurrentPageNumber}
				setTable={this.props.setTable}
				tab={group.id}
				title={group.name}
			/>
		));
	}

	/**
	 * @returns {{count: number; label: string; option: string;}[]}
	 */
	get #mapToBulkOptions() {
		const groupList = this.props.groupsContactsCounts.map((group) => ({
			count: group.count,
			label: group.name,
			option: group.id,
		}));

		return [
			{
				count: this.props.allContactsCount,
				label: ALL_CONTACTS_TEXT,
				option: 'allContacts',
			},
			{
				count: this.props.myContactsCount,
				label: MY_CONTACTS_TEXT,
				option: 'myContacts',
			},
			...groupList,
		];
	}

	/**
	 * @param {{count?: number; label: string; option: string}} selection
	 * @protected
	 * @returns {void}
	 */
	_handleOptionsChange(selection) {
		if (this.props.activeTab === selection.option) {
			return;
		}

		this.props.setCurrentPageNumber(1);
		this.props.setTable(selection.option);

		this.setState({
			bulkOptionsTitle: `${selection.label} (${selection.count})`,
		});
	}

	/** @returns {JSX.Element | null} */
	#renderCta() {
		if (!this.props?.groupsContactsCounts?.length) {
			return null;
		}

		return (
			<p className="mb-6">
				{translate('admin.pages.contacts.view.tabs_cta')}
			</p>
		);
	}

	/** @returns {JSX.Element} */
	#getTabs() {
		if (this.props?.groupsContactsCounts?.length > 3) {
			return (
				<div className="tabs overflow-visible">
					<BulkOptions
						bulkOptionList={this.#mapToBulkOptions}
						buttonTitle={this.state.bulkOptionsTitle}
						handleOptionChange={this._handleOptionsChange}
					/>
				</div>
			);
		}

		return <ul className="tabs">{this.#renderTabs()}</ul>;
	}

	/** @returns {JSX.Element} */
	render() {
		return (
			<section>
				{this.#renderCta()}

				<div className="w-fit">{this.#getTabs()}</div>
			</section>
		);
	}
}

// @ts-ignore
export default withAuth(withAgentContactService(ContactTabs));
