/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */

import {
	withAgentService,
	withHomeownerService,
} from '../../../service-container';
import { withSettings } from '../../SettingProvider/SettingProvider';
import captureError from '../../../utils/captureError';
import Loading from '../../Loading';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class Ads extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			adsHash: null,
			isGettingData: true,
		};
	}

	/** @returns {Promise<void>} */
	async componentDidMount() {
		const role = this.props.userRole;

		if (!this.#getRoleFeature()) {
			this.setState({ isGettingData: false });
			return;
		}

		try {
			const adsHash =
				role === 'agent'
					? await this.#getAgentAdBanners()
					: await this.#getHomeownerAdBanners();

			this.setState({ adsHash, isGettingData: false });
		} catch (error) {
			captureError(error);
			this.setState({ isGettingData: false });
		}
	}

	/** @returns {Promise<State['adsHash']>} */
	async #getAgentAdBanners() {
		const agent = await this.props.agentService.getAgentPersonalData();

		if (!agent?.groups?.length) {
			return {};
		}

		return agent.groups.reduce((acc, group) => {
			group?.adBanner?.forEach((banner) => {
				const agentActive = banner?.location?.agentDashboard;

				/**
				 * Multiple groups can have the same ad banner,
				 * but the ad should show even if one of the
				 * groups have it active.
				 */
				//	@ts-ignore - no type any for acc
				if (!acc[banner?.brand] || !agentActive) {
					//	@ts-ignore - no type any
					acc[banner.brand] = banner;
				}
			});

			return acc;
		}, {});
	}

	/** @returns {Promise<State['adsHash']>} */
	async #getHomeownerAdBanners() {
		const homeowner = await this.props.homeownerService.getHomeownerTeam();

		if (!homeowner?.length) {
			return {};
		}

		const banners = homeowner?.flatMap((group) =>
			group?.groups?.flatMap((banner) => banner?.adBanner)
		);

		return banners.reduce((acc, banner) => {
			const homeownerActive = banner?.location?.homeownerDashboard;
			if (!acc[banner?.brand] || !homeownerActive) {
				acc[banner.brand] = banner;
			}
			return acc;
		}, {});
	}

	/** @returns {boolean} */
	// eslint-disable-next-line complexity
	#showAd() {
		if (
			!this.state.adsHash ||
			!this.props.identifier ||
			!this.#getRoleFeature()
		) {
			return false;
		}

		const banner = this.state.adsHash[this.props.identifier];
		const role = this.props.userRole;
		const bannerActive = banner && banner?.active;
		const roleActive =
			role === 'agent'
				? banner?.location?.agentDashboard
				: banner?.location?.homeownerDashboard;

		return bannerActive ? roleActive : false;
	}

	#getRoleFeature() {
		const role = this.props.userRole;

		if (!['agent', 'homeowner'].includes(role)) {
			return false;
		}

		return this.props.settings.features.get(`${role}:ads`);
	}

	/** @returns {JSX.Element | null} */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		if (!this.#showAd()) {
			return null;
		}

		return this.props.children;
	}
}

//	@ts-ignore - issue with service container
export default withSettings(withHomeownerService(withAgentService(Ads)));
