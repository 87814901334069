/**
 * @typedef {import('../shared/AgentService/Agent').AgentPersonalData} Agent
 * @typedef {import('./AgentNavigationListComponent').Props} Props
 * @typedef {import('./AgentNavigationListComponent').State} State
 */
import { getNavLinkList } from 'config/local/navigation';
import { translate } from 'App/Internationalization';
import { withAgentService } from '../../service-container';
import { withAuth } from '../../shared/AuthProvider';
import { withSettings } from '../../shared/SettingProvider/SettingProvider';
import captureError from '../../utils/captureError';
import NavigationList from 'App/shared/NavigationList';
import React from 'react';
import RoleGate from 'App/shared/RoleGate';
import utilities from '@mooveguru/js-utilities';

/**
 * @extends {React.Component<Props, State>}
 */
export class AgentNavigationList extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/**
		 * @param {State} state
		 */
		this.state = {
			agent: null,
			hideWidget: true,
		};
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		if (!this.props.auth?.roles?.includes('agent')) {
			return;
		}

		try {
			const agent = await this.props.agentService.getAgentPersonalData();
			const hideWidget = this.#resolveWidget(agent);

			this.setState({
				agent,
				hideWidget,
			});
		} catch (error) {
			captureError(error);
		}
	}

	/**
	 * @param {Agent} agent
	 * @returns {boolean}
	 */
	#resolveWidget(agent) {
		//	@ts-ignore agent is of correct type but ts is inferring it as `never`
		const hasValidGroup = agent.groups.find(
			/**
			 * @param {Agent['groups'][0]} group
			 * @returns {boolean}
			 */
			(group) => group.avmWidgetEarlyAccess
		);

		if (
			this.props.settings.features.get('agent:show_valuation_widget') &&
			hasValidGroup
		) {
			return false;
		}

		return true;
	}

	render() {
		const hasAgentLevelSubscription =
			this.props.auth?.agentSubscriptionLevels?.includes('agent');

		const occupation = translate(
			`homeowner.pages.dashboard.agent.occupation.${
				//	@ts-ignore agent is of correct type but ts is inferring it as `never`
				this.state.agent?.occupation ?? 'agent'
			}`
		);

		return (
			<RoleGate allowedRoles={['agent']}>
				<NavigationList
					// @ts-ignore
					className="mb-8"
					heading={utilities.string.convertToTitleCase(
						occupation,
						' '
					)}
					links={getNavLinkList(
						'agent',
						hasAgentLevelSubscription,
						this.state.hideWidget
					)}
					onListItemClick={this.props.onListItemClick}
				/>
			</RoleGate>
		);
	}
}

export default withSettings(withAgentService(withAuth(AgentNavigationList)));
