/**
 * @typedef {import('../types/AuthContext').default} AuthContext
 * @typedef {import('../shared/AuthProvider/Roles').Roles} Role
 * @typedef {import('@mooveguru/js-utilities/types/KeyedObject').default} KeyedObject
 */
import { array, object } from '@mooveguru/js-utilities';
import capabilityDictionary from '@mooveguru/yhh-shared-config/users/capabilities.json';

/**
 * @param {AuthContext} user
 * @param {keyof capabilityDictionary} capability
 * @returns {boolean}
 */
export function hasCapability(user, capability) {
	return getCapabilities(user.roles).includes(capability);
}

/**
 * @param {Role[]} roles
 * @returns {(keyof capabilityDictionary)[]}
 */
function getCapabilities(roles) {
	return object.getKeys(capabilityDictionary).filter(
		/**
		 * @param {keyof capabilityDictionary} capability
		 * @returns {boolean}
		 */
		(capability) =>
			array.includesAny(capabilityDictionary[capability], ...roles)
	);
}
