/**
 * @typedef {import('./GeneratePasswordResetProps').default} Props
 */
import { translate } from '../../App/Internationalization';
import { withAuthService } from '../../service-container';
import captureError from '../../utils/captureError';
import CopyToClipboard from './CopyToClipboard';
import React from 'react';

/** @extends {React.Component<Props>} */
class GeneratePasswordReset extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {{url: string}} */
		this.state = {
			url: '',
		};
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			const url = await this.props.authService.generatePasswordResetUrl(
				this.props.userId
			);

			this.setState({
				url,
			});
		} catch (error) {
			captureError(error);
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<React.Fragment>
				<h2 className="h-ms-5 mb-4">
					{translate('global.links.password_reset_url.title')}
				</h2>

				<section className="rich-text mb-4">
					<p>
						{translate('global.links.password_reset_url.explainer')}
					</p>
				</section>

				<CopyToClipboard
					text={translate('global.links.password_reset_url.button')}
					url={this.state.url}
				/>
			</React.Fragment>
		);
	}
}

// @ts-ignore, HOC type needs to be fixed
export default withAuthService(GeneratePasswordReset);
