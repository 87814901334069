import countryList from '@mooveguru/yhh-shared-config/countries.json';
import InputSelect from 'shared/Forms/Inputs/InputSelect';
import React from 'react';
import SelectOptionList from 'shared/Forms/Inputs/SelectOptionList';

export default class InputCountry extends React.Component {
	render() {
		return (
			<InputSelect
				autocomplete="country"
				className={this.props.className}
				defaultValue="US"
				disabled={this.props.disabled}
				name={this.props.name}
				required={this.props.required}
				type="text"
			>
				<SelectOptionList values={countryList} />
			</InputSelect>
		);
	}
}

InputCountry.defaultProps = {
	className: '',
	disabled: false,
	name: 'country',
	required: false,
};
