/**
 * @typedef {import('./FeatureComponent').Props} Props
 * @typedef {import('./FeatureComponent').State} State
 * @typedef {import('./FeatureFlags').default} FeatureSetting
 */
import React from 'react';
import { withSettings } from './SettingProvider/SettingProvider';

/** @extends {React.Component<Props, State, {}>} */
class Feature extends React.Component {
	/**
	 * @returns {Props['fallback'] | Props['children']}
	 */
	render() {
		if (this.props.settings.features.get(this.props.name)) {
			return this.props.children;
		}

		return this.props.fallback ?? null;
	}
}

export default withSettings(Feature);
