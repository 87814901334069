/** @typedef {import('./BaseButtonProps').default} Props */

import { toAbsoluteUrl } from '../../utils/toAbsoluteUrl';
import React from 'react';
import Svg from 'react-inlinesvg';

export const buttonSize = {
	large: 'button-lg',
	medium: 'button-md',
	small: 'button-sm',
	xsmall: 'button-xs',
};

export const iconSize = {
	large: 'icon-lg',
	medium: 'icon-md',
	small: 'icon-sm',
};

/** @extends {React.Component<Props>} */
export default class BaseButton extends React.Component {
	static defaultProps = {
		iconSize: 'small',
		type: 'button',
	};

	/** @returns {string} */
	#getDefaultBaseClass() {
		if (this.props.isLinkButton) {
			return '';
		}
		return 'button';
	}

	/** @returns {string} */
	#getDefaultClass() {
		let defaultClass = this.#getDefaultBaseClass();

		if (this.props.border === 'rounded') {
			defaultClass = `${defaultClass} button-pill`;
		}

		if (this.props.border === 'square') {
			defaultClass = `${defaultClass} button-datum`;
		}

		if (this.props.outline) {
			defaultClass = `${defaultClass} button-outline`;
		}

		if (this.props.size) {
			defaultClass = `${defaultClass} ${buttonSize[this.props.size]}`;
		}

		if (this.props.icon) {
			defaultClass = `${defaultClass} button-icon`;
		}

		return defaultClass;
	}

	/** @returns {string} */
	#getIconClass() {
		// @ts-ignore - iconSize has a default type
		let iconClass = `icon ${iconSize[this.props.iconSize]}`;

		if (this.props.icon) {
			if (this.props.outline) {
				iconClass = `${iconClass} icon-fill`;
			} else {
				iconClass = `${iconClass} icon-fill-fg`;
			}
		}

		return iconClass;
	}

	/** @returns {JSX.Element} */
	render() {
		return (
			<button
				className={`${
					this.props.className ?? ''
				} ${this.#getDefaultClass()}`.trim()}
				disabled={this.props.disabled}
				id={this.props.id}
				onClick={(event) =>
					this.props.onClick && this.props.onClick(event)
				}
				/* eslint-disable react/button-has-type */
				type={this.props.type}
				/* eslint-enable*/
			>
				{this.props.children ? (
					<span>{this.props.children}</span>
				) : null}
				{this.props.icon ? (
					<i className={this.props.iconClass ?? this.#getIconClass()}>
						<Svg src={toAbsoluteUrl(this.props.icon)} />
					</i>
				) : null}
			</button>
		);
	}
}
