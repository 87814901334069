/**
 * @typedef {import('../LeadScore/Props').LeadScores} LeadScores
 * @typedef {import('./Props').default} Props
 */

import { translate } from '../../Internationalization';
import BaseModal from '../BaseModal/BaseModal';
import LeadScoreChart from './LeadScoreChart/LeadScoreChart';
import LeadScoreModalHeader from './Header/LeadScoreModalHeader';
import React from 'react';

const maxLeadScoreHistory = 90;

/** @extends {React.Component<Props>} */
export default class LeadScoreModal extends React.Component {
	/** @returns {LeadScores[] | []} */
	#sortLeadScores() {
		if (!this.props?.leadScores?.length) {
			return [];
		}

		// Sort all scores date wise
		return this.props.leadScores
			.filter((score) => score.date)
			.sort(
				/**
				 * @param {LeadScores} a
				 * @param {LeadScores} b
				 * @returns {-1 | 0 | 1}
				 */
				(a, b) => {
					const diff =
						// @ts-ignore null values checked already
						new Date(b.date).getTime() -
						// @ts-ignore null values checked already
						new Date(a.date).getTime();

					if (diff === 0) {
						return diff;
					}

					return diff >= 1 ? 1 : -1;
				}
			);
	}

	/** @returns {JSX.Element} */
	render() {
		const sortedLeadScores = this.#sortLeadScores();

		return (
			<BaseModal onClose={this.props.onClose}>
				<LeadScoreModalHeader
					email={this.props.email}
					leadScores={sortedLeadScores}
					name={this.props.name}
				/>
				<div className="w-full">
					<LeadScoreChart leadScores={sortedLeadScores} />
				</div>

				<footer className="mx-4">
					<p>
						{translate(
							'global.pages.contacts.lead_score.footer',
							`${maxLeadScoreHistory}`
						)}
					</p>
				</footer>
			</BaseModal>
		);
	}
}
