/** @typedef {import('./Dependencies').default} Dependencies */

import apiUrls from 'config/local/api-urls';
import ServiceError from 'shared/Errors/ServiceError';

export default class DocumentsService {
	/** @param {Dependencies} dependencies */
	constructor(dependencies) {
		this.httpClient = dependencies.httpClient;
		this.authService = dependencies.authService;
	}

	/**
	 * @param {string} documentId
	 * @returns {Promise<Blob|null>}
	 */
	async getDocumentDownloadLink(documentId) {
		const response = await this.httpClient.get(
			`${apiUrls.me.homeowner.documents.download}/${documentId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new ServiceError(
				'There was a problem getting your download.',
				response.body
			);
		}

		return response.body;
	}
}
