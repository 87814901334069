import { translate } from 'App/Internationalization';
import ActionButtons from 'shared/ActionButtons/ActionButtons';
import AgentActivity from '../AgentActivity/AgentActivity';
import AcceptedAgents from '../AcceptedAgents/AcceptedAgents';
import paths from 'config/local/paths';
import React, { Component } from 'react';
import UpgradedAgents from '../UpgradedAgents/UpgradedAgents';

export default class AgentsCard extends Component {
	render() {
		return (
			<React.Fragment>
				<h2 className="h-ms-4 my-4">
					{translate('admin.nav.agent.title')}
				</h2>
				<AcceptedAgents />
				<UpgradedAgents />
				<AgentActivity
					// @ts-ignore do not need to pass in all props
					type="recent"
				/>
				<AgentActivity
					// @ts-ignore do not need to pass in all props
					type="inactive"
				/>
				<ActionButtons
					addUrl={paths.app.admin.agents.create}
					importUrl={paths.app.admin.agents.import}
					viewAllUrl={paths.app.admin.agents.view}
				/>
			</React.Fragment>
		);
	}
}
