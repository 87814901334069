import React from 'react';

export const BreakpointContext = React.createContext();

export default class BreakpointProvider extends React.Component {
	constructor(props) {
		super(props);
		this.breakpointObserver = window.matchMedia('(max-width: 992px)');

		this.state = {
			isBelowBreakpoint: this.breakpointObserver.matches,
		};

		this.handleBreakpointChange = this.handleBreakpointChange.bind(this);
	}

	componentDidMount() {
		this.breakpointObserver.addEventListener(
			'change',
			this.handleBreakpointChange
		);
	}

	componentWillUnmount() {
		this.breakpointObserver.removeEventListener(
			'change',
			this.handleBreakpointChange
		);
	}

	handleBreakpointChange(event) {
		this.setState({
			isBelowBreakpoint: event.matches,
		});
	}

	render() {
		return (
			<BreakpointContext.Provider value={this.state.isBelowBreakpoint}>
				{this.props.children}
			</BreakpointContext.Provider>
		);
	}
}

export function withBreakpoint(Component) {
	class BreakpointedComponent extends React.Component {
		render() {
			return (
				<Component isBelowBreakpoint={this.context} {...this.props} />
			);
		}
	}

	BreakpointedComponent.contextType = BreakpointContext;
	return BreakpointedComponent;
}
