import { translate } from 'App/Internationalization';
import FormField from 'shared/Forms/Fields/FormField';
import InputFirstName from 'shared/Forms/Inputs/Name/InputFirstName';
import React from 'react';

export default class FieldFirstName extends React.Component {
	render() {
		const label = translate('global.forms.inputs.name.first.label');

		return (
			<FormField
				className={this.props.className}
				inputName={this.props.name}
				label={this.props.label ?? label}
				labelClass={this.props.labelClass}
				name={this.props.name}
				required={this.props.required}
			>
				<InputFirstName
					className={this.props.inputClass}
					disabled={this.props.disabled}
					name={this.props.name}
					required={this.props.required}
				/>
			</FormField>
		);
	}
}

FieldFirstName.defaultProps = {
	className: InputFirstName.defaultProps.className,
	inputClass: InputFirstName.defaultProps.className,
	labelClass: FormField.defaultProps.labelClass,
	name: InputFirstName.defaultProps.name,
	required: InputFirstName.defaultProps.required,
	disabled: InputFirstName.defaultProps.disabled,
};
