import { translate } from 'App/Internationalization';
import { withAdministratorService } from 'service-container';
import captureError from 'utils/captureError';
import FieldGroupSelect from 'shared/Forms/Fields/FieldGroupSelect';
import FieldSearchableSelect from 'shared/Forms/Fields/FieldSearchableSelect';
import React from 'react';

class GroupAgentFieldGroup extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			agentList: this.props.initialGroupValues?.agentList ?? [],
		};

		this.updateCorrespondingAgents =
			this.updateCorrespondingAgents.bind(this);
		this.handleGroupChange = this.handleGroupChange.bind(this);
	}

	/**
	 * @param {{id: string; name: string} | any} object
	 * @returns {{label: string; value: any} | null}
	 */
	mapToOption(object) {
		if (!object) {
			return null;
		}

		return {
			label: object.name,
			value: object.id,
		};
	}

	/**
	 * @param {string} groupId
	 * @returns {Promise<void>}
	 */
	async updateCorrespondingAgents(groupId) {
		try {
			const agentList =
				await this.props.administratorService.getAgentsByGroupIds(
					groupId
				);

			this.setState({
				agentList,
			});
		} catch (error) {
			captureError(error);
		}
	}

	/**
	 * @param {*} event
	 * @param {*} formik
	 * @returns {Promise<void>}
	 */
	async handleGroupChange(event, formik) {
		formik.form.setFieldValue(formik.field.name, event.value);
		await this.updateCorrespondingAgents(event.value);
	}

	render() {
		return (
			<React.Fragment>
				<FieldGroupSelect
					initialGroup={this.props.initialGroupValues?.group ?? null}
					label={translate('admin.pages.contacts.form.groups.label')}
					name="groupId"
					onChange={this.handleGroupChange}
					required
				/>
				<FieldSearchableSelect
					defaultValue={this.mapToOption(
						this.props.initialGroupValues?.agent ?? null
					)}
					label={translate('admin.pages.contacts.form.agent.label')}
					name="agentId"
					required
					options={this.state.agentList.map((agent) =>
						this.mapToOption(agent)
					)}
				/>
			</React.Fragment>
		);
	}
}

export default withAdministratorService(GroupAgentFieldGroup);
