import { toAbsoluteUrl } from '../../utils/toAbsoluteUrl';
import React from 'react';
import InlineSVG from 'react-inlinesvg';

export default class NavigationListItemContent extends React.Component {
	/**  @returns {JSX.Element} */
	render() {
		return (
			<React.Fragment>
				<i className="icon">
					<InlineSVG src={toAbsoluteUrl(this.props.icon)} />
				</i>
				{this.props.children}
			</React.Fragment>
		);
	}
}
