/**
 * @typedef {import('./LocalProsSearchState').default} State
 */

import { Redirect } from 'react-router-dom';
import { translate } from '../../Internationalization';
import BaseForm from '../../../shared/Forms/BaseForm';
import FieldSearchableSelect from '../../../shared/Forms/Fields/FieldSearchableSelect';
import React from 'react';
import SubmitButton from '../../../shared/Forms/Inputs/SubmitButton';
import images from '../../../config/local/images';
import marketplaceCategories from '@mooveguru/yhh-shared-config/marketplace-categories.json';
import paths from '../../../config/local/paths';
import separateObjectIntoArray from '../../../utils/separateObjectIntoArray';

export default class LocalProsSearch extends React.Component {
	/**
	 * @param {{}} props
	 */
	constructor(props) {
		super(props);
		/**
		 *  @type {State}
		 */
		this.state = {
			category: null,
			isSubmitted: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCategoryChange = this.handleCategoryChange.bind(this);
	}

	handleSubmit() {
		this.setState({
			isSubmitted: true,
		});
	}

	/**
	 * @param {React.ChangeEvent<HTMLSelectElement>} event
	 * @returns {void}
	 */
	handleCategoryChange(event) {
		this.setState({
			category: event.value,
		});
	}

	render() {
		if (this.state.isSubmitted) {
			return (
				<Redirect
					to={{
						pathname: paths.app.homeowner.marketplace,
						state: { category: this.state.category },
					}}
				/>
			);
		}

		return (
			<BaseForm
				className="input-group"
				initialValues={{ category: '' }}
				noRequiredText={true}
				onSubmit={this.handleSubmit}
			>
				<FieldSearchableSelect
					className={`${this.props.inputClass} col-span-2 h-full text-center`}
					/* prettier-ignore */
					defaultValue={this.props.initialCategory ? {
							label: this.props.initialCategory,
							value: this.props.initialCategoryValue,
						} : ''}
					/* prettier-enable */
					label={translate('global.local_pros_search.label')}
					labelClass="sr-only"
					name="category"
					onChange={this.handleCategoryChange}
					options={separateObjectIntoArray(marketplaceCategories)}
					placeholder={translate('global.local_pros_search.label')}
				/>
				<SubmitButton
					className={`${this.props.buttonClass} button button-icon`}
					icon={images.icons.search}
				>
					{translate('global.local_pros_search.submit')}
				</SubmitButton>
			</BaseForm>
		);
	}
}
