/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */

import { translate } from '../../Internationalization';
import { withAgentContactService } from '../../../service-container';
import ActionButtons from '../../../shared/ActionButtons/ActionButtons';
import captureError from '../../../utils/captureError';
import Loading from '../../../shared/Loading';
import paths from '../../../config/local/paths';
import ProgressBar from '../../../shared/ProgressBar/ProgressBar';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class AcceptedContacts extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State}  */
		this.state = {
			acceptedContacts: 0,
			error: false,
			isGettingData: true,
			totalContacts: 0,
		};
	}

	async componentDidMount() {
		this.#getsAndSetsContacts();
	}

	/** @returns {Promise<void>} */
	async #getsAndSetsContacts() {
		try {
			const contactData =
				await this.props.agentContactService.getTotalContacts();

			this.setState({
				acceptedContacts: contactData.accepted ?? 0,
				isGettingData: false,
				totalContacts: contactData.total ?? 0,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				error: true,
				isGettingData: false,
			});
		}
	}

	/** @returns {JSX.Element | null} */
	render() {
		if (this.state.error) {
			return null;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<React.Fragment>
				<ProgressBar
					max={this.state.totalContacts}
					title={translate('admin.pages.dashboard.contacts_accepted')}
					value={this.state.acceptedContacts}
				/>
				<ActionButtons
					addUrl={paths.app.agent.contacts.add}
					importUrl={paths.app.agent.contacts.import}
					viewAllUrl={paths.app.agent.contacts.root}
				/>
			</React.Fragment>
		);
	}
}

// @ts-ignore until service container types are fixed
export default withAgentContactService(AcceptedContacts);
