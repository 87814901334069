import FieldPhoneNumber from 'shared/Forms/Fields/PhoneNumber/FieldPhoneNumber';
import InputPhoneNumber from 'shared/Forms/Inputs/PhoneNumber/InputPhoneNumber';
import React from 'react';
import InputMobilePhone from 'shared/Forms/Inputs/PhoneNumber/InputMobilePhone';

export default class FieldMobilePhone extends React.Component {
	render() {
		return (
			<FieldPhoneNumber
				className={this.props.className}
				disabled={this.props.disabled}
				inputClass={this.props.inputClass}
				labelClass={this.props.labelClass}
				name={this.props.name}
				required={this.props.required}
				type="mobile"
			/>
		);
	}
}

FieldMobilePhone.defaultProps = {
	autocomplete: InputPhoneNumber.defaultProps.autocomplete,
	className: InputPhoneNumber.defaultProps.className,
	disabled: InputPhoneNumber.defaultProps.disabled,
	inputClass: InputPhoneNumber.defaultProps.className,
	labelClass: FieldPhoneNumber.defaultProps.labelClass,
	name: InputMobilePhone.defaultProps.name,
	required: InputPhoneNumber.defaultProps.required,
};
