import { Redirect } from 'react-router';
import { translate } from 'App/Internationalization';
import HomeownerForm from 'App/Admin/Users/Homeowners/HomeownerForm';
import Loading from '../../../../shared/Loading';
import paths from 'config/local/paths';
import React from 'react';

export default class EditHomeowner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			agents: [],
			errorMessage: null,
			homeownerData: {},
			isGettingData: true,
			selectedAgent: null,
			success: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.getAndSetInitialValues = this.getAndSetInitialValues.bind(this);
		this.updateAgent = this.updateAgent.bind(this);
	}

	async componentDidMount() {
		await this.getAndSetInitialValues();
	}

	async getAndSetInitialValues() {
		try {
			const [homeownerData, agents] = await Promise.all([
				await this.props.homeownerService.getHomeownerById(
					this.props.match.params.homeownerId
				),
				await this.props.administratorService.getAllAgentNames(),
			]);

			this.setState({
				agents: agents,
				errorMessage: null,
				homeownerData,
				isGettingData: false,
			});
		} catch (error) {
			this.setState({
				errorMessage: error.message,
				isGettingData: false,
			});
		}
	}

	async updateAgent(event) {
		if (!event.value) {
			return;
		}

		this.setState({
			selectedAgent: event.value,
		});
	}

	async handleSubmit(values) {
		const selectedAgentId = this.state.selectedAgent;
		if (selectedAgentId) {
			values.agent = selectedAgentId;
		}

		try {
			await this.props.homeownerService.updateHomeownerData(
				values,
				this.props.match.params.homeownerId
			);

			this.setState({ success: true, errorMessage: null });
		} catch (error) {
			this.setState({
				errorMessage: error.message,
				isGettingData: false,
				success: false,
			});
		}
	}

	render() {
		if (this.state.success) {
			return <Redirect to={paths.app.admin.homeowners.view} />;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<HomeownerForm
				agents={this.state.agents}
				initialValues={this.state.homeownerData}
				messages={this.state.errorMessage}
				onSubmit={this.handleSubmit}
				selectOnChange={this.updateAgent}
				submitText={translate('admin.pages.homeowners.edit.update')}
				title={translate('admin.pages.homeowners.edit.title')}
			/>
		);
	}
}
