/**
 * @typedef {{ label: string, name: string, placeholder: string, type: string }} FieldDefinition
 */
import { translate } from '../../../../App/Internationalization';
import BaseInput from '../../Inputs/BaseInput';
import FormField from '../FormField';
import images from '../../../../config/local/images';
import InlineSVG from 'react-inlinesvg/esm';
import React from 'react';

export default class PropertyDetailFields extends React.Component {
	/**
	 * @private
	 * @type {Map<string, FieldDefinition[]>}
	 */
	detailList = new Map([
		[
			translate(
				'homeowner.pages.dashboard.properties.titles.my_amenities'
			),
			[
				{
					label: translate('global.forms.inputs.property_details.fireplace.label'), // prettier-ignore
					name: 'fireplace',
					placeholder: translate('global.forms.inputs.property_details.fireplace.placeholder'), // prettier-ignore
					type: 'text',
				},
				{
					label: translate('global.forms.inputs.property_details.garage_cars.label'), // prettier-ignore
					name: 'garageCars',
					placeholder: translate('global.forms.inputs.property_details.garage_cars.placeholder'), // prettier-ignore
					type: 'number',
				},
				{
					label: translate('global.forms.inputs.property_details.garage_type.label'), // prettier-ignore
					name: 'garageType',
					placeholder: translate('global.forms.inputs.property_details.garage_type.placeholder'), // prettier-ignore
					type: 'text',
				},
				{
					label: translate('global.forms.inputs.property_details.pool.label'), // prettier-ignore
					name: 'pool',
					placeholder: translate('global.forms.inputs.property_details.pool.placeholder'), // prettier-ignore
					type: 'text',
				},
			],
		],
		[
			translate(
				'homeowner.pages.dashboard.properties.titles.construction'
			),
			[
				{
					label: translate('global.forms.inputs.property_details.build_year.label'), // prettier-ignore
					name: 'buildYear',
					placeholder: translate('global.forms.inputs.property_details.build_year.placeholder'), // prettier-ignore
					type: 'number',
				},
				{
					label: translate('global.forms.inputs.property_details.construction_type.label'), // prettier-ignore
					name: 'constructionType',
					placeholder: translate('global.forms.inputs.property_details.construction_type.placeholder'), // prettier-ignore
					type: 'text',
				},
				{
					label: translate('global.forms.inputs.property_details.foundation_type.label'), // prettier-ignore
					name: 'foundationType',
					placeholder: translate('global.forms.inputs.property_details.foundation_type.placeholder'), // prettier-ignore
					type: 'text',
				},
				{
					label: translate('global.forms.inputs.property_details.floor_cover.label'), // prettier-ignore
					name: 'floorCover',
					placeholder: translate('global.forms.inputs.property_details.floor_cover.placeholder'), // prettier-ignore
					type: 'text',
				},
				{
					label: translate('global.forms.inputs.property_details.interior_walls.label'), // prettier-ignore
					name: 'interiorWalls',
					placeholder: translate('global.forms.inputs.property_details.interior_walls.placeholder'), // prettier-ignore
					type: 'text',
				},
				{
					label: translate('global.forms.inputs.property_details.plumbing_fixtures.label'), // prettier-ignore
					name: 'plumbingFixtures',
					placeholder: translate('global.forms.inputs.property_details.plumbing_fixtures.placeholder'), // prettier-ignore
					type: 'text',
				},
				{
					label: translate('global.forms.inputs.property_details.roof_cover.label'), // prettier-ignore
					name: 'roofCover',
					placeholder: translate('global.forms.inputs.property_details.roof_cover.placeholder'), // prettier-ignore
					type: 'text',
				},
				{
					label: translate('global.forms.inputs.property_details.roof_type.label'), // prettier-ignore
					name: 'roofType',
					placeholder: translate('global.forms.inputs.property_details.roof_type.placeholder'), // prettier-ignore
					type: 'text',
				},
				{
					label: translate('global.forms.inputs.property_details.stories.label'), // prettier-ignore
					name: 'stories',
					placeholder: translate('global.forms.inputs.property_details.stories.placeholder'), // prettier-ignore
					type: 'number',
				},
				{
					label: translate('global.forms.inputs.property_details.style.label'), // prettier-ignore
					name: 'style',
					placeholder: translate('global.forms.inputs.property_details.style.placeholder'), // prettier-ignore
					type: 'text',
				},
			],
		],
		[
			translate('homeowner.pages.dashboard.properties.titles.hvac'),
			[
				{
					label: translate('global.forms.inputs.property_details.heating.label'), // prettier-ignore
					name: 'heating',
					placeholder: translate('global.forms.inputs.property_details.heating.placeholder'), // prettier-ignore
					type: 'text',
				},
				{
					label: translate('global.forms.inputs.property_details.heating_fuel_type.label'), // prettier-ignore
					name: 'heatingFuelType',
					placeholder: translate('global.forms.inputs.property_details.heating_fuel_type.placeholder'), // prettier-ignore
					type: 'text',
				},
			],
		],
		[
			translate('homeowner.pages.dashboard.properties.titles.mortgage'),
			[
				{
					label: translate('global.forms.inputs.property_details.amount_to_loan.label'), // prettier-ignore
					name: 'amountToLoan',
					placeholder: translate('global.forms.inputs.property_details.amount_to_loan.placeholder'), // prettier-ignore
					type: 'number',
				},
				{
					label: translate('global.forms.inputs.property_details.loan_type.label'), // prettier-ignore
					name: 'loanType',
					placeholder: translate('global.forms.inputs.property_details.loan_type.placeholder'), // prettier-ignore
					type: 'text',
				},
				{
					label: translate('global.forms.inputs.property_details.due_date.label'), // prettier-ignore
					name: 'dueDate',
					placeholder: translate('global.forms.inputs.property_details.due_date.placeholder'), // prettier-ignore
					type: 'date',
				},
				{
					label: translate('global.forms.inputs.property_details.lender_name.label'), // prettier-ignore
					name: 'lenderName',
					placeholder: translate('global.forms.inputs.property_details.lender_name.placeholder'), // prettier-ignore
					type: 'text',
				},
				{
					label: translate('global.forms.inputs.property_details.rate.label'), // prettier-ignore
					name: 'rate',
					placeholder: translate('global.forms.inputs.property_details.rate.placeholder'), // prettier-ignore
					type: 'number',
				},
				{
					label: translate('global.forms.inputs.property_details.maximum_interest_rate.label'), // prettier-ignore
					name: 'maximumInterestRate',
					placeholder: translate('global.forms.inputs.property_details.maximum_interest_rate.placeholder'), // prettier-ignore
					type: 'number',
				},
				{
					label: translate('global.forms.inputs.property_details.original_date_of_contract.label'), // prettier-ignore
					name: 'originalDateOfContract',
					placeholder: translate('global.forms.inputs.property_details.original_date_of_contract.placeholder'), // prettier-ignore
					type: 'date',
				},
			],
		],
		[
			translate('homeowner.pages.dashboard.properties.titles.rooms'),
			[
				{
					label: translate('global.forms.inputs.property_details.basement.label'), // prettier-ignore
					name: 'basement',
					placeholder: translate('global.forms.inputs.property_details.basement.placeholder'), // prettier-ignore
					type: 'text',
				},
				{
					label: translate('global.forms.inputs.property_details.number_of_baths.label'), // prettier-ignore
					name: 'numberOfBaths',
					placeholder: translate('global.forms.inputs.property_details.number_of_baths.placeholder'), // prettier-ignore
					type: 'number',
				},
				{
					label: translate('global.forms.inputs.property_details.number_of_partial_baths.label'), // prettier-ignore
					name: 'numberOfPartialBaths',
					placeholder: translate('global.forms.inputs.property_details.number_of_partial_baths.placeholder'), // prettier-ignore
					type: 'number',
				},
				{
					label: translate('global.forms.inputs.property_details.bedrooms.label'), // prettier-ignore
					name: 'bedrooms',
					placeholder: translate('global.forms.inputs.property_details.bedrooms.placeholder'), // prettier-ignore
					type: 'number',
				},
				{
					label: translate('global.forms.inputs.property_details.other_rooms.label'), // prettier-ignore
					name: 'otherRooms',
					placeholder: translate('global.forms.inputs.property_details.other_rooms.placeholder'), // prettier-ignore
					type: 'number',
				},
				{
					label: translate('global.forms.inputs.property_details.total_number_of_rooms.label'), // prettier-ignore
					name: 'totalNumberOfRooms',
					placeholder: translate('global.forms.inputs.property_details.total_number_of_rooms.placeholder'), // prettier-ignore
					type: 'number',
				},
			],
		],
		[
			translate('homeowner.pages.dashboard.properties.titles.size'),
			[
				{
					label: translate('global.forms.inputs.property_details.acreage.label'), // prettier-ignore
					name: 'acreage',
					placeholder: translate('global.forms.inputs.property_details.acreage.placeholder'), // prettier-ignore
					type: 'number',
				},
				{
					label: translate('global.forms.inputs.property_details.lot_size_square_footage.label'), // prettier-ignore
					name: 'lotSizeSquareFootage',
					placeholder: translate('global.forms.inputs.property_details.lot_size_square_footage.placeholder'), // prettier-ignore
					type: 'number',
				},
			],
		],
		[
			translate('homeowner.pages.dashboard.properties.titles.utilities'),
			[
				{
					label: translate('global.forms.inputs.property_details.sewer.label'), // prettier-ignore
					name: 'sewer',
					placeholder: translate('global.forms.inputs.property_details.sewer.placeholder'), // prettier-ignore
					type: 'text',
				},
				{
					label: translate('global.forms.inputs.property_details.water.label'), // prettier-ignore
					name: 'water',
					placeholder: translate('global.forms.inputs.property_details.water.placeholder'), // prettier-ignore
					type: 'text',
				},
			],
		],
	]);

	/** @param {{ baseInputClassName: string, disabled: boolean, formFieldClassName: string, labelClass: string, required: boolean }} props */
	constructor(props) {
		super(props);

		this.renderDetailField = this.renderDetailField.bind(this);
		this.renderDetailSection = this.renderDetailSection.bind(this);
	}

	/**
	 * @private
	 * @param {FieldDefinition} details
	 * @param {number=} index
	 * @returns {JSX.Element}
	 */
	renderDetailField(details, index = 0) {
		return (
			<FormField
				className={this.props.formFieldClassName}
				inputName={details.name}
				key={index}
				label={details.label}
				labelClass={this.props.labelClass}
				name={details.name}
				required={this.props.required}
			>
				<BaseInput
					className={this.props.baseInputClassName}
					disabled={this.props.disabled}
					name={details.name}
					placeholder={details.placeholder}
					required={this.props.required}
					type={details.type}
				/>
			</FormField>
		);
	}

	/**
	 * @private
	 * @param {string} title
	 * @param {FieldDefinition[]} fieldList
	 * @returns {JSX.Element}
	 */
	renderDetailSection(title, fieldList) {
		return (
			<details className="mt-6 toggle-disclosure" open={true}>
				<summary className="justified-row mb-4">
					<legend className="h-ms-5 mb-6">{title}</legend>
					<i className="icon input-icon toggle-disclosure-indicator">
						<InlineSVG src={images.icons.arrow.dropdown.small} />
					</i>
				</summary>
				<fieldset
					className="grid lg:grid-cols-3 sm:grid-cols-2 gap-6 mb-8"
					key={title}
				>
					{fieldList.map(this.renderDetailField)}
				</fieldset>
			</details>
		);
	}

	render() {
		/** @type {JSX.Element[]} */
		const sections = [];

		this.detailList.forEach((fieldList, title) => {
			sections.push(this.renderDetailSection(title, fieldList));
		});

		return sections;
	}
}

PropertyDetailFields.defaultProps = {
	baseInputClassName: '',
	disabled: false,
	formFieldClassName: '',
	labelClass: FormField.defaultProps.labelClass,
	required: false,
};
