/**
 * @typedef {import('../../shared/OfferService/OffersProps').default} Props
 * @typedef {import('../../shared/OfferService/OffersState').default} State
 * @typedef {import('../../shared/OfferService/OfferFields').default} OfferFields
 */
import { translate } from '../../Internationalization';
import { withOfferService } from '../../../service-container';
import BaseTable from '../../../shared/BaseTable';
import captureError from '../../../utils/captureError';
import categoryList from '@mooveguru/yhh-shared-config/marketplace-categories.json';
import FormSuccessMessages from '../../../shared/Forms/Messages/FormSuccessMessages';
import images from '../../../config/local/images';
import InlineSVG from 'react-inlinesvg/esm';
import LinkButton from '../../../shared/LinkButton';
import Loading from '../../../shared/Loading';
import Pagination from '../../../shared/Pagination';
import paths from '../../../config/local/paths';
import React from 'react';
import SelectOptionList from '../../../shared/Forms/Inputs/SelectOptionList';
import TableButton from '../../../shared/TableButton';
import VendorExclusivity from '../../shared/VendorExclusivity';

/** @extends {React.Component<Props, State>} */
export class ViewOffers extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			currentPageNumber: 1,
			isGettingData: true,
			lastPageNumber: 1,
			offers: [],
			selectedCategory: 'all',
			visibleCategoryList: { all: 'All Categories' },
		};

		this._handleSetCurrentPageNumber =
			this._handleSetCurrentPageNumber.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			await this.#getTableData();
		} catch (error) {
			captureError(error);

			this.setState({
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {number} pageNumber
	 * @param {{pages:number, offers:OfferFields[]}} response
	 * @returns {void}
	 */
	#setTableData(pageNumber, response) {
		this.setState({
			currentPageNumber: pageNumber,
			isGettingData: false,
			lastPageNumber: response.pages ?? 1,
			offers: response.offers,
		});
	}

	/**
	 * @param {string} categoryId
	 * @returns {Promise<void>}
	 */
	async handleCategoryLink(categoryId) {
		this.setState({
			selectedCategory: categoryId,
		});

		await this.#getTableDataByCategory(categoryId);
	}

	/**
	 * @param {string} categoryId
	 * @returns {Promise<void>}
	 */
	async handleSelectCategory(categoryId) {
		this.setState({
			selectedCategory: categoryId,
		});

		await this.#determineTableData(categoryId);
	}

	/**
	 * @param {string} categoryId
	 * @param {number} [pageNumber]
	 * @returns {Promise<void>}
	 */
	async #determineTableData(categoryId, pageNumber = 1) {
		if (categoryId === 'all') {
			return this.#getTableData(pageNumber);
		}

		return this.#getTableDataByCategory(categoryId, pageNumber);
	}

	/**
	 * @protected
	 * @param {number} pageNumber
	 * @returns {Promise<void>}
	 */
	async _handleSetCurrentPageNumber(pageNumber) {
		return this.#determineTableData(
			this.state.selectedCategory,
			pageNumber
		);
	}

	/**
	 * @param {string[]} assignedCategoryList
	 * @returns {void}
	 */
	#setCategoryList(assignedCategoryList) {
		/**
		 * @type {{all: string, [key:string]:string}}
		 */
		const assignedCategoryListObject = { all: 'All Categories' };

		assignedCategoryList.sort().forEach(
			/**
			 * @param {string} category
			 * @returns {void}
			 */
			(category) => {
				assignedCategoryListObject[category] =
					ViewOffers.#resolveCategory(category);
			}
		);

		this.setState({ visibleCategoryList: assignedCategoryListObject });
	}

	/**
	 * @param {number} pageNumber
	 * @returns {Promise<void>}
	 */
	async #getTableData(pageNumber = 1) {
		try {
			const response = await this.props.offerService.getAgentOffers(
				pageNumber
			);
			this.setState({ total: response?.total });
			this.#setTableData(pageNumber, response);
			this.#setCategoryList(response.categoryList);
		} catch (error) {
			captureError(error);

			this.setState({
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {string } category
	 * @param {number} pageNumber
	 * @returns {Promise<void>}
	 */
	async #getTableDataByCategory(category, pageNumber = 1) {
		try {
			const response =
				await this.props.offerService.getAgentOffersByCategory(
					category,
					pageNumber
				);
			this.#setTableData(pageNumber, response);
		} catch (error) {
			captureError(error);
			this.setState({
				isGettingData: false,
			});
		}
	}

	/**
	 * @todo Categories are saved in the database that are not in the JSON categoryList file, so type errors are happening.
	 * @param {string} category
	 * @returns {string}
	 */
	static #resolveCategory(category) {
		// @ts-ignore category can potentially not be in JSON category list
		return categoryList[category] ?? category;
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		const headerKeyList = [
			'vendor_name',
			'vendor_category',
			'vendor_desc',
			'vendor_zip',
			'status',
			'edit',
		];

		const tableHeaderData = headerKeyList.map((key) => ({
			title: translate(`agent.pages.offers.view.table.${key}`),
		}));

		const tableBodyData = this.state.offers.map(
			/**
			 *
			 * @param {OfferFields} value
			 * @param {number} index
			 * @returns {{data: string | JSX.Element | number | null | string[] | boolean, index?: string}[]}
			 */
			(value, index) => [
				{
					data: value.vendorName,
					index: `contact-${index}`,
				},
				{
					data: (
						<button
							className="category-link"
							onClick={() => {
								this.handleCategoryLink(value.offerCategory);
							}}
							type="button"
						>
							{ViewOffers.#resolveCategory(value.offerCategory)}
						</button>
					),
					index: `contact-${index}`,
				},
				{
					data: value.offerDescription,
					index: `contact-${index}`,
				},
				{
					data: value.offerZip,
					index: `contact-${index}`,
				},
				{
					data: translate(
						`admin.pages.agents.view.table.${
							value.status ? 'active' : 'disabled'
						}`
					),
					index: `contact-${index}`,
				},
				{
					data: (
						<TableButton
							icon={images.icons.edit}
							to={{
								pathname: paths.app.agent.offers.edit,
								state: { offerId: value.id },
							}}
						/>
					),
				},
			]
		);

		return (
			<React.Fragment>
				<header className="mt-10 mb-6 md:flex justify-between items-center">
					<h1 className="hl-ms-6 mb-6 md:mb-0">
						{`${translate('agent.pages.offers.view.title')} (${
							this.state.total
						})`}
					</h1>
					<LinkButton
						icon={images.icons.plus}
						to={paths.app.agent.offers.add}
					>
						{translate('global.offers.add_title')}
					</LinkButton>
				</header>
				<FormSuccessMessages
					messages={this.props.location?.state?.message}
				/>

				<VendorExclusivity type="agent" />

				<fieldset className="input-group max-w-xs mb-6">
					<label className="input-label" htmlFor="category">
						{'Select Category'}
					</label>
					<div className="input-stylized">
						<i className="icon input-icon icon-sm">
							<InlineSVG
								src={images.icons.arrow.dropdown.normal}
							/>
						</i>

						<select
							className="input"
							name="category"
							onChange={
								/**
								 * @param {React.ChangeEvent<HTMLSelectElement>} event
								 * @returns
								 */
								(event) => {
									this.handleSelectCategory(
										event.target.value
									);
								}
							}
						>
							<SelectOptionList
								values={this.state.visibleCategoryList}
							/>
						</select>
					</div>
				</fieldset>

				<BaseTable
					bodyData={tableBodyData}
					className="w-full mt-3"
					errorMessage={translate('agent.pages.offers.view.no_data')}
					headerData={tableHeaderData}
				/>

				<Pagination
					currentPageNumber={this.state.currentPageNumber}
					lastPageNumber={this.state.lastPageNumber}
					// eslint-disable-next-line react/jsx-handler-names -- need to fix this global component type
					setCurrentPageNumber={this._handleSetCurrentPageNumber}
				/>
			</React.Fragment>
		);
	}
}

export default withOfferService(ViewOffers);
