import CopyToClipboard from './Links/CopyToClipboard';
import OpenInNewTab from './Links/OpenInNewTab';
import React from 'react';

export default class ShareableLink extends React.Component {
	/** @returns {JSX.Element} */
	render() {
		return (
			<li className="list-item">
				<label className="h-ms-1" htmlFor={this.props.label}>
					{this.props.label}
				</label>

				<input
					className="input mt-3 bg-theme-light text-theme-fg-light"
					disabled={true}
					id={this.props.label}
					name={this.props.label}
					type="url"
					value={this.props.url}
				/>

				<footer className="button-group mt-2">
					<CopyToClipboard url={this.props.url} />
					<OpenInNewTab url={this.props.url} />
				</footer>
			</li>
		);
	}
}
