import React from 'react';
import FieldAddressDesignation from 'shared/Forms/Fields/Address/FieldAddressDesignation';
import FieldCity from 'shared/Forms/Fields/Address/FieldCity';
import FieldCountry from 'shared/Forms/Fields/Address/FieldCountry';
import FieldPostalCode from 'shared/Forms/Fields/Address/FieldPostalCode';
import FieldState from 'shared/Forms/Fields/Address/FieldState';
import FieldStreetAddress from 'shared/Forms/Fields/Address/FieldPostalStreetAddress';

export default class AddressFields extends React.Component {
	render() {
		return (
			<React.Fragment>
				<FieldStreetAddress
					className={this.props.className}
					disabled={this.props.disabled}
					name={this.props.addressName}
					required={this.props.required}
				/>
				<FieldAddressDesignation
					className={this.props.className}
					disabled={this.props.disabled}
					name={this.props.designationName}
				/>
				<FieldCity
					className={this.props.className}
					disabled={this.props.disabled}
					name={this.props.cityName}
					required={this.props.required}
				/>
				<FieldState
					className={this.props.className}
					disabled={this.props.disabled}
					name={this.props.stateName}
					required={this.props.required}
				/>
				<FieldPostalCode
					className={this.props.className}
					disabled={this.props.disabled}
					name={this.props.postalCodeName}
					required={this.props.required}
				/>
				<FieldCountry
					className={this.props.className}
					disabled={this.props.disabled}
					name={this.props.countryName}
					required={this.props.required}
				/>
			</React.Fragment>
		);
	}
}

AddressFields.defaultProps = {
	addressName: FieldStreetAddress.defaultProps.name,
	cityName: FieldCity.defaultProps.name,
	className: '',
	countryName: FieldCountry.defaultProps.name,
	designationName: FieldAddressDesignation.defaultProps.name,
	disabled: false,
	postalCodeName: FieldPostalCode.defaultProps.name,
	required: FieldStreetAddress.defaultProps.required,
	stateName: FieldState.defaultProps.name,
};
