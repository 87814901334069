import { Redirect } from 'react-router-dom';
import BaseForm from 'shared/Forms/BaseForm';
import captureError from 'utils/captureError';
import paths from 'config/local/paths';
import React from 'react';
import SubmitButton from 'shared/Forms/Inputs/SubmitButton';

export default class TransferProperty extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			formComplete: false,
		};

		const searchParams = new URLSearchParams(window.location.search);
		this.token = searchParams.get('token');
		this.propertyId = searchParams.get('propertyId');
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async handleSubmit() {
		try {
			await this.props.propertyService.setProperty(
				this.propertyId,
				this.token
			);

			this.setState({ formComplete: true });
		} catch (error) {
			captureError(error);
		}
	}

	render() {
		if (this.token === null) {
			return <Redirect to={paths.app.homeowner.root} />;
		}

		return (
			<BaseForm>
				<p>Confirm the transferring of property:</p>
				<SubmitButton>Confirm</SubmitButton>
			</BaseForm>
		);
	}
}
