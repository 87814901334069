/**
 * @typedef {import('./Component').Props} Props
 */
import { toAbsoluteUrl } from '../../utils/toAbsoluteUrl';
import { translate } from '../../App/Internationalization';
import { withBreakpoint } from '../BreakpointProvider';
import BaseButton from '../BaseButton/BaseButton';
import ExternalAnchor from '../../shared/ExternalAnchor';
import React from 'react';

/** @extends {React.Component<Props, {}>} */
class BannerAd extends React.Component {
	/** @returns {JSX.Element} */
	render() {
		return (
			<section>
				<section className="card mt-10 card-border-theme-light-solid">
					<ExternalAnchor
						href={toAbsoluteUrl(this.props.url)}
						target="_blank"
					>
						<header
							className={`${
								!this.props?.isBelowBreakpoint ? 'flex' : ''
							} card-header gap-2 grid-cols-12`}
						>
							<section
								className={
									!this.props?.isBelowBreakpoint
										? 'max-w-[25%] pr-2'
										: ''
								}
							>
								<img
									alt={this.props.title}
									className="card-logo"
									src={this.props.logo}
								/>
							</section>

							<section className="col-span-10">
								<h2 className="card-subtitle">
									{this.props.title}
								</h2>
								<p className="h-ms-3 text-theme">
									{this.props.text}
								</p>
								<p className="my-1 text-theme hl-ms">
									{this.props.textLead}
								</p>
								<BaseButton className="my-2 whitespace-pre-wrap">
									{this.props.buttonText}
								</BaseButton>
								<p className="italic text-theme -hl-ms-2">
									{this.props.textFooter}
								</p>
							</section>
						</header>
					</ExternalAnchor>
				</section>
				<p className="text-sm text-right italic">
					<small>{translate('ads.cinch.advertisement')}</small>
				</p>
			</section>
		);
	}
}

export default withBreakpoint(BannerAd);
