/**
 * @typedef {import('./NoContentComponent').Props} Props
 * @typedef {import('./NoContentComponent').State} State
 */
import React from 'react';
import InlineSVG from 'react-inlinesvg/esm';
import { translate } from '../App/Internationalization';
import images from '../config/local/images';

/** @extends {React.Component<Props, State, {}>} */
export default class NoContent extends React.Component {
	/** @type {Props} */
	static defaultProps = {
		message: translate('global.no_content.message'),
		title: translate('global.no_content.title'),
	};

	render() {
		return (
			<section className="mt-6 form-alert form-alert-grey w-fit">
				<h3 className="title">
					<i className="icon icon-xs">
						<InlineSVG src={images.icons.info} />
					</i>

					{this.props.title}
				</h3>

				<section className="rich-text text mt-1">
					<p>{this.props.message}</p>
				</section>
			</section>
		);
	}
}
