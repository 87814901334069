import InputLabel from '../Inputs/InputLabel';
import React from 'react';

export default class FieldToggle extends React.Component {
	render() {
		return (
			<div className={this.props.className.trim()}>
				{this.props.children}
				<InputLabel
					className={this.props.labelClass ?? ''}
					inputName={this.props.inputName}
					required={this.props.required}
				>
					<span className={this.props.labelSpanClass}>
						{this.props.label}
					</span>
				</InputLabel>
			</div>
		);
	}
}

FieldToggle.defaultProps = {
	className: '',
	labelClass: '',
	labelSpanClass: '',
	required: false,
};
