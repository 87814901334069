/**
 * @typedef {import('../Component').FilterValue} Value
 * @typedef {import('../Component').SelectValue[]} InputValue
 * @typedef {import('./ToggleListComponent').Props<Value, InputValue>} Props
 * @typedef {import('./ToggleListComponent').State} State
 */
import { object } from '@mooveguru/js-utilities';
import FieldSearchableSelect from '../../../../../shared/Forms/Fields/FieldSearchableSelect';
import React from 'react';
import ToggleList from './ToggleList';

/**
 * @extends {ToggleList<Value, InputValue>}
 */
export default class Multiselect extends ToggleList {
	_className = 'multiselect';
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		this._handleSelection = this._handleSelection.bind(this);
	}

	/**
	 * @protected
	 * @param {{ [key: string]: InputValue }[]} input
	 * @param {import('formik').FieldProps<InputValue[]>} props
	 * @returns {void}
	 */
	_handleSelection(input, props) {
		const value = object.column(input, 'value').filter(Boolean);

		props.form.setFieldValue(props.field.name, value);

		this.setState((prev) => {
			prev.fields[props.field.name] = value.length > 0;

			return prev;
		}, this._setActive);
	}

	/**
	 * @protected
	 * @param {string} name
	 * @param {string} label
	 * @param {Value} value
	 * @returns {React.ReactNode}
	 */
	// eslint-disable-next-line class-methods-use-this, no-unused-vars
	_renderInput(name, label, value) {
		return null;
	}

	/**
	 * @template {string} Key
	 * @template {Value} InputValue
	 * @param {InputValue} value
	 * @param {Key} label
	 * @returns {{ label: Key, value: InputValue }}
	 */
	static #mapOption(value, label) {
		return { label, value };
	}

	/**
	 * @template {string} Key
	 * @param {{ [key in Key]: Value }} list
	 * @returns {{ label: Key, value: Value }[]}
	 */
	static #mapHashToOptions(list) {
		// @ts-ignore
		return Object.entries(list).map(([id, name]) =>
			Multiselect.#mapOption(id, name)
		);
	}

	/**
	 * @protected
	 * @param {string} name
	 * @param {{ [key:string]: Value }} list
	 * @returns {React.ReactNode}
	 */
	_renderFieldset(name, list) {
		const inputName = this._inputName(name);
		const id = this._inputId(name, '');

		return (
			<FieldSearchableSelect
				cacheOptions={true}
				className="my-2"
				id={id}
				isClearable={true}
				isMultipleSelect={true}
				key={name}
				label={name}
				name={inputName}
				onChange={this._handleSelection}
				options={Multiselect.#mapHashToOptions(list)}
				required={false}
			/>
		);
	}
}
