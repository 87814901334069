/** @typedef {import('./Props').default} Props */

import { BaseSVG } from '../../../../shared/BaseSVG';
import images from '../../../../config/local/images';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class ContactTab extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		this._handleOnClick = this._handleOnClick.bind(this);
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleOnClick() {
		if (this.props.activeTab === this.props.tab) {
			return;
		}

		this.props.setCurrentPageNumber(1);
		this.props.setTable(this.props.tab);
	}

	/**  @returns {JSX.Element | null} */
	#getSubscribedIcon() {
		if (!this.props.isSubscribed) {
			return null;
		}

		return (
			<i className="icon icon-fill-fg">
				<BaseSVG path={images.icons.premium} />
			</i>
		);
	}

	/** @returns {JSX.Element} */
	render() {
		let className = 'tab';

		if (this.props.activeTab === this.props.tab) {
			className = `${className} tab-active`;
		}

		return (
			<li className="">
				<button
					className={className}
					onClick={this._handleOnClick}
					type="button"
				>
					<span className="flex items-center gap-2">
						{this.#getSubscribedIcon()}
						{`${this.props.title} (${this.props.contactCount})`}
					</span>
				</button>
			</li>
		);
	}
}
