/**
 * @typedef {import('./InputCheckboxComponent').Props} Props
 * @typedef {import('formik').FieldProps<{ [key: string]: string }>} FieldProps
 */
import { Field } from 'formik';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class InputCheckbox extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		this._onChange = this._onChange.bind(this);
		this._isChecked = this._isChecked.bind(this);
	}

	/**
	 * @protected
	 * @param {FieldProps} props
	 * @returns {boolean}
	 */
	_isChecked(props) {
		const name = this.props.name.replace('[]', '');

		const value = props.form.values[name];

		return Array.isArray(value)
			? value.includes(this.props.value)
			: this.props.value === value;
	}

	/**
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @param {FieldProps} props
	 * @returns {void}
	 */
	_onChange(event, props) {
		if (Array.isArray(props.field.value)) {
			/**
			 * @type {string | string[]}
			 */
			let value = props.field.value || [];

			if (value.includes(event.target.value)) {
				value = value.filter(
					(checked) => checked !== event.target.value
				);
			} else {
				value.push(event.target.value);
			}

			props.form.setFieldValue(event.target.name, value);
		} else {
			props.form.setFieldValue(event.target.name, !props.field.value);
		}

		if (typeof this.props.onChange === 'function') {
			this.props.onChange(event, props.form);
		}
	}

	/**
	 * @returns {React.ReactNode}
	 */
	render() {
		return (
			<Field name={this.props.name}>
				{
					// eslint-disable-next-line jsdoc/require-param, jsdoc/check-param-names
					/**
					 * @param {FieldProps} props
					 * @returns {React.ReactNode}
					 */
					(props) => (
						<input
							checked={this._isChecked(props)}
							className={this.props.className}
							disabled={this.props.disabled}
							id={this.props.id ?? this.props.name}
							name={props.field.name}
							onChange={(event) => this._onChange(event, props)}
							onClick={this.props.onClick}
							required={this.props.required}
							type="checkbox"
							value={this.props.value}
						/>
					)
				}
			</Field>
		);
	}
}
