import React from 'react';
import InputPhoneNumber from 'shared/Forms/Inputs/PhoneNumber/InputPhoneNumber';

export default class InputHomePhone extends React.Component {
	render() {
		return <InputPhoneNumber name={this.props.name} type="home" />;
	}
}

InputHomePhone.defaultProps = {
	name: 'homePhoneNumber',
};
