/**
 * @typedef {import('../../../Registration/RegistrationService/Contact').default} Contact
 * @typedef {import('./AddContactsProps').default} Props
 * @typedef {import('./AddContactsState').default} State
 */

import { addContact } from '../../Agent/agent-service';
import { AuthContext } from '../../../shared/AuthProvider';
import { getAgentId } from '../agent-service';
import { Redirect } from 'react-router-dom';
import { translate } from '../../Internationalization';
import { withSubscriptionService } from '../../../service-container';
import captureError from '../../../utils/captureError';
import ContactForm from '../../Agent/Contacts/ContactForm';
import ContactLimitPopup from '../../shared/ContactLimitPopup/ContactLimitPopup';
import errors from '../../../config/local/errors';
import Feature from '../../../shared/Feature';
import FieldRadioGroup from '../../../shared/Forms/Fields/FieldRadioGroup';
import paths from '../../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class AddContact extends React.Component {
	static contextType = AuthContext;

	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/**
		 * @type {State}
		 */
		this.state = {
			errorMessage: '',
			formComplete: false,
			showLimitContactPopup: false,
		};

		this._handleLimitContactClose =
			this._handleLimitContactClose.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	// TODO: Move Agent ID into API??
	/**
	 * @param {Omit<Contact,"agentId"> & { readonly sendInvite?: 'yes' | 'no' }} values
	 * @returns {Promise<void>}
	 */
	async handleSubmit(values) {
		const updatedValues = this.#addGroupIdIfViable(values);

		try {
			const contact = {
				agentId: await getAgentId(this.context.accessToken),
				...updatedValues,
			};

			await addContact(
				contact,
				this.context.accessToken,
				'Agent added',
				values.sendInvite !== 'no'
			);

			this.setState({
				errorMessage: '',
				formComplete: true,
			});
		} catch (error) {
			// @ts-ignore statusCode is added to error in agent-service.js
			if (error?.statusCode === errors.overContactLimitError) {
				this.setState({ showLimitContactPopup: true });
				return;
			}
			captureError(error);

			this.setState({
				errorMessage: translate('global.error'),
			});
		}
	}

	/**
	 * @param {Omit<Contact,"agentId">} values
	 * @returns {Omit<Contact,"agentId">}
	 */
	#addGroupIdIfViable(values) {
		const activeTab = this.props.location?.state?.activeTab;

		if (!activeTab) {
			return values;
		}

		if (activeTab !== 'myContacts') {
			values['groupId'] = activeTab;
		}

		return values;
	}

	/**
	 * @returns {string}
	 */
	#getFormTitle() {
		const groupName = this.props.location?.state?.groupName;

		if (!groupName) {
			return translate('agent.pages.contacts.add.title');
		}

		return translate('agent.pages.contacts.add.group_title', groupName);
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleLimitContactClose() {
		this.setState({ showLimitContactPopup: false });
	}

	/**
	 * @returns {JSX.Element}
	 */
	static #renderContactPopupContent() {
		const contactCap = process.env.REACT_APP_CONTACT_CAP ?? '100';

		return (
			<React.Fragment>
				<p>
					{translate(`global.contact_limit.add.header`, contactCap)}{' '}
					<span className="font-bold">
						{translate('global.contact_limit.add.body')}
					</span>
				</p>

				<p>
					{translate(`global.contact_limit.add.footer`, contactCap)}
				</p>
			</React.Fragment>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const activeTab = this.props.location?.state?.activeTab;

		if (this.state.formComplete) {
			return (
				<Redirect
					to={{
						pathname: paths.app.agent.contacts.root,
						state: {
							activeTab,
							message: translate(
								'agent.pages.contacts.add.success_message'
							),
						},
					}}
				/>
			);
		}

		return (
			<React.Fragment>
				<ContactLimitPopup
					onClose={this._handleLimitContactClose}
					showPopup={this.state.showLimitContactPopup}
					title={translate(`global.contact_limit.add.title`)}
					to={paths.app.agent.subscription.root}
				>
					{AddContact.#renderContactPopupContent()}
				</ContactLimitPopup>

				<ContactForm
					error={this.state.errorMessage}
					formTitle={this.#getFormTitle()}
					initialValues={{
						...ContactForm.defaultProps.initialValues,
						sendInvite: 'yes',
					}}
					onSubmit={this.handleSubmit}
					submitText={translate('agent.pages.contacts.view.new')}
				>
					<Feature
						fallback={null}
						name="agent:disable_contact_invite"
					>
						<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
							<legend className="h-ms-5 mb-6">
								{translate(
									'admin.pages.contacts.form.invitations'
								)}
							</legend>

							<FieldRadioGroup
								disabled={false}
								name="sendInvite"
								required={true}
								title={translate(
									'agent.pages.contacts.add.invitation'
								)}
								values={[
									[
										'yes',
										translate(
											'import_contacts.invitations_radio_labels.yes'
										),
									],
									[
										'no',
										translate(
											'import_contacts.invitations_radio_labels.no'
										),
									],
								]}
							/>
						</fieldset>
					</Feature>
				</ContactForm>
			</React.Fragment>
		);
	}
}

export default withSubscriptionService(AddContact);
