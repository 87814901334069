import { translate } from '../../Internationalization';
import BaseTable from '../../../shared/BaseTable';
import DocumentActions from './DocumentActions';
import LinkButton from '../../../shared/LinkButton';
import paths from '../../../config/local/paths';
import React from 'react';
import images from '../../../config/local/images';

/** @typedef {import('./Document').default} Document */

const headerColumns = ['title', 'type', 'uploaded', 'actions'].map((key) => ({
	title: translate(`homeowner.pages.documents.table.${key}`),
}));

export default class DocumentTable extends React.Component {
	/**
	 * @private
	 * @returns {{[key: string]: string}[][]}
	 */
	buildTable() {
		return this.props.documents.map((/** @type {Document} */ document) =>
			this.buildRow(document)
		);
	}

	/**
	 * @private
	 * @param {Document} document
	 * @returns {{data: any, index: string}[]}
	 */
	buildRow(document) {
		const contentList = [
			document.title,
			document.type ?? 'N/A',
			document.createdAt?.toLocaleDateString() ?? 'N/A',
			<DocumentActions
				deleteDocument={() => this.props.deleteDocument(document.id)}
				document={document}
				downloadOnClick={this.props.downloadOnClick}
				key={document.id}
			/>,
		];

		return contentList.map((value) => ({
			data: value,
			index: `document-${document.id}`,
		}));
	}

	render() {
		let path = paths.app.homeowner.documents.create;

		if (this.props.slug) {
			path = `${path}/${this.props.slug}`;
		}

		return (
			<section>
				<header className="flex flex-row items-baseline justify-between mb-6">
					<h2 className="h-ms-3">{this.props.title}</h2>

					<LinkButton
						icon={images.icons.plus}
						outline={true}
						to={path}
					>
						{translate('homeowner.pages.documents.create')}
					</LinkButton>
				</header>

				<BaseTable
					bodyData={this.buildTable()}
					className="mb-8 w-full"
					errorMessage="No documents have been uploaded."
					headerData={headerColumns}
				/>
			</section>
		);
	}
}
