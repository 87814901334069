import images from 'config/local/images.js';
import React from 'react';
import SocialMediaLink from './SocialMediaLink';

export default class SocialMediaLinks extends React.Component {
	render() {
		const uriEncodedUrl = encodeURIComponent(this.props.url);

		let socialMediaTypes = [
			{
				src: images.social.facebook,
				type: 'facebook',
				url: `http://www.facebook.com/sharer/sharer.php?u=${uriEncodedUrl}&display=popup`,
			},
			{
				src: images.social.twitter,
				type: 'twitter',
				url: `https://twitter.com/intent/tweet?text=${uriEncodedUrl}`,
			},
			{
				src: images.social.linkedin,
				type: 'linkedin',
				url: `https://www.linkedin.com/sharing/share-offsite/?url=${uriEncodedUrl}`,
			},
			{
				src: images.social.pinterest,
				type: 'pinterest',
				url: `https://www.pinterest.com/pin/create/link/?url=${uriEncodedUrl}&media=${this.props.imageUrl}`,
			},
		];

		if (this.props.blogPreview) {
			socialMediaTypes = socialMediaTypes.slice(0, 2);
		}

		return socialMediaTypes.map((socialMedia) => (
			<SocialMediaLink
				className={this.props.className}
				key={socialMedia.type}
				src={socialMedia.src}
				type={socialMedia.type}
				url={socialMedia.url}
			/>
		));
	}
}
