/**
 * @typedef {import('./NavigationListComponent').Props} Props
 * @typedef {import('./NavigationListComponent').State} State
 */
import { withSettings } from '../../shared/SettingProvider/SettingProvider';
import NavigationListLink from './NavigationListLink';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class NavigationList extends React.Component {
	static defaultProps = {
		color: 'info-contrasted',
	};

	/**  @returns {JSX.Element} */
	render() {
		const linkList = this.props.links
			.filter((link) => {
				if (link.flag === undefined) {
					return true;
				}

				return this.props.settings.features.get(link.flag);
			})
			.map(
				/**
				 *
				 * @param {Props['links'][0]} link
				 * @returns {JSX.Element}
				 */
				(link) => (
					<NavigationListLink
						icon={link.icon}
						key={link.key}
						onListItemClick={this.props.onListItemClick}
						to={link.to}
					>
						{link.label}
					</NavigationListLink>
				)
			);

		return (
			<React.Fragment>
				<h2 className="nav-heading">{this.props.heading}</h2>
				<ul className="nav-group">
					{linkList}
					{this.props.appendItem}
				</ul>
			</React.Fragment>
		);
	}
}

export default withSettings(NavigationList);
