/**
 * @typedef {import('Registration/RegisterManager/ManagerRegistrationForm/Values').default} FormValues
 * @typedef {import('Registration/RegisterManager/Props').default} Props
 * @typedef {import('Registration/RegisterManager/State').default} State
 */

import { Redirect } from 'react-router';
import { translate } from '../../App/Internationalization';
import { withAuth } from '../../shared/AuthProvider';
import { withRegistrationService } from '../../service-container';
import captureError from '../../utils/captureError';
import FormErrorMessages from '../../shared/Forms/Messages/FormErrorMessages';
import ManagerRegistrationForm from './ManagerRegistrationForm/ManagerRegistrationForm';
import paths from '../../config/local/paths';
import React from 'react';
import SpamMessage from '../../shared/Forms/Messages/SpamMessage';

/** @extends {React.Component<Props, State>} */
export class RegisterManager extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			message: null,
			registrationWasSuccessful: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	/**
	 * @param {FormValues} values
	 * @returns {Promise<void>}
	 */
	async handleSubmit(values) {
		try {
			await this.props.registrationService.registerManager({
				emailAddress: values.emailAddress,
				group: values.group,
				name: { first: values.firstName, last: values.lastName },
				occupation: values.occupation,
				password: values.password,
			});

			await this.#signIn(values.emailAddress, values.password);

			this.setState({ registrationWasSuccessful: true });
		} catch (error) {
			captureError(error);

			// Message from backend contains useful information about user data
			this.setState({
				message:
					error instanceof Error
						? error.message
						: translate('registration.getting_contact_information'),
			});
		}
	}

	/**
	 * @param {string} emailAddress
	 * @param {string} password
	 * @returns {Promise<void>}
	 */
	async #signIn(emailAddress, password) {
		try {
			await this.props.auth.signIn(emailAddress, password);
		} catch (error) {
			captureError(error);

			this.setState({
				message: translate('sign_in.generic_sign_in_error'),
			});
		}
	}

	/** @returns {JSX.Element} */
	render() {
		if (this.state.registrationWasSuccessful) {
			return (
				<Redirect
					to={{
						pathname:
							this.props.match.params.whiteLabelSlug !== undefined
								? `/${this.props.match.params.whiteLabelSlug}${paths.auth.signIn}`
								: paths.auth.signIn,
						state: {
							message: translate('registration.success'),
							referrer: this.props.referrer,
						},
					}}
				/>
			);
		}

		return (
			<React.Fragment>
				<ManagerRegistrationForm onSubmit={this.handleSubmit} />
				<section className="form-message-area">
					<FormErrorMessages messages={this.state.message} />
					<SpamMessage />
				</section>
			</React.Fragment>
		);
	}
}

export default withAuth(withRegistrationService(RegisterManager));
