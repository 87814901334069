/* eslint-disable max-classes-per-file -- Container needs to define multiple classes */
/* eslint-disable react/jsx-filename-extension -- TODO: Convert to JSX */
/* eslint-disable react/jsx-props-no-spreading -- Prop spreading required for HOCs */
/* eslint-disable react/no-multi-comp -- Container needs to define multiple HOCs */
/**
 * @typedef {import('@mooveguru/js-utilities/types/KeyedObject').default} KeyedObject
 */
import ActivityService from './shared/ActivityService';
import AddressService from './App/shared/AddressService/AddressService';
import AdministratorService from './App/shared/AdministratorService';
import AgentContactService from './shared/AgentContactService';
import AgentService from './App/shared/AgentService';
import AllConnectService from './App/Homeowner/Utilities/AllConnectService';
import ApplianceService from './shared/ApplianceService';
import AuthService from './Auth/AuthService';
import BlogService from './shared/BlogService';
import BlogServiceActivityProxy from './shared/BlogServiceActivityProxy';
import ChecklistService from './App/Homeowner/Checklists/ChecklistService';
import ContactService from './App/shared/ContactService';
import DocumentsService from './App/shared/DocumentsService';
import ExportService from './App/shared/ExportService';
import HomeownerService from './App/shared/HomeownerService';
import HttpClient from '@mooveguru/js-http-client';
import MailingService from './Mailing/MailingService';
import MarketplaceService from './shared/MarketplaceService';
import MarketplaceServiceActivityProxy from './shared/MarketplaceServiceActivityProxy';
import OfferService from './App/shared/OfferService/OfferService';
import GroupService from './App/shared/GroupService';
import PropertyService from './App/shared/PropertyService';
import React from 'react';
import RegistrationService from './Registration/RegistrationService';
import SettingService from './shared/SettingsService';
import SubscriptionService from './App/shared/SubscriptionService';
import TaskService from './App/Homeowner/Checklists/TaskService';
import ThemeService from './shared/ThemeService';
import UserService from './App/Profile/UserService';

const httpClient = new HttpClient();
const authService = new AuthService(httpClient);
const addressService = new AddressService({
	authService,
	httpClient,
});
const administratorService = new AdministratorService({
	authService,
	httpClient,
});
const agentService = new AgentService({ authService, httpClient });
const agentContactService = new AgentContactService({
	authService,
	httpClient,
});
const contactService = new ContactService({
	authService,
	httpClient,
});
const applianceService = new ApplianceService({ authService, httpClient });
const documentsService = new DocumentsService({ authService, httpClient });
const exportService = new ExportService({
	authService,
	httpClient,
});
const homeownerService = new HomeownerService({
	authService,
	httpClient,
});
const groupService = new GroupService({
	authService,
	httpClient,
});
const offerService = new OfferService({ authService, httpClient });
const themeService = new ThemeService({ authService, httpClient });

const taskService = new TaskService({ authService, httpClient });
const checklistService = new ChecklistService({
	authService,
	httpClient,
	taskService,
});

const userService = new UserService(httpClient);
const activityService = new ActivityService({
	authService,
	httpClient,
});
const blogService = new BlogService(httpClient);
const mailingService = new MailingService(httpClient);
const marketplaceService = new MarketplaceService({ authService, httpClient });
const registrationService = new RegistrationService({ httpClient });
const settingService = new SettingService({ httpClient });
const propertyService = new PropertyService({
	authService,
	httpClient,
});

const subscriptionService = new SubscriptionService({
	authService,
	httpClient,
});

const blogServiceActivityProxy = new BlogServiceActivityProxy({
	activityService,
	blogService,
});

const marketplaceServiceActivityProxy = new MarketplaceServiceActivityProxy({
	activityService,
	marketplaceService,
});
const allConnectService = new AllConnectService();

/**
 * @template {{[key: string]: any, addressService: Partial<AddressService>}} Props
 * @param {React.ComponentType<Props>} Component
 * @returns {React.ComponentType<Props>}
 */
export function withAddressService(Component) {
	return class AddressServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component addressService={addressService} {...this.props} />
			);
		}
	};
}
/**
 * @template {{[key: string]: any, authService: Partial<AuthService>}} Props
 * @param {React.ComponentType<Props>} Component
 * @returns {React.ComponentType<Props>}
 */
export function withAuthService(Component) {
	return class AuthServiceInjectedComponent extends React.Component {
		render() {
			return <Component authService={authService} {...this.props} />;
		}
	};
}

/**
 * @template {{[key: string]: any, blogService: Partial<BlogService>}} Props
 * @param {React.ComponentType<Props>} Component
 * @returns {React.ComponentType<Props>}
 */
export function withBlogService(Component) {
	return class BlogServiceInjectedComponent extends React.Component {
		render() {
			return <Component blogService={blogService} {...this.props} />;
		}
	};
}

/**
 * @template {{[key: string]: any, blogService: Partial<BlogService>}} Props
 * @param {React.ComponentType<Props>} Component
 * @returns {React.ComponentType<Props>}
 */
export function withBlogServiceActivityProxy(Component) {
	return class BlogServiceActivityProxyInjectedComponent extends React.Component {
		render() {
			return (
				<Component
					blogService={blogServiceActivityProxy}
					{...this.props}
				/>
			);
		}
	};
}

/**
 * @template Props
 * @param {React.ComponentType<Props>} Component
 * @returns {React.ComponentType<Props>}
 */
export function withContactService(Component) {
	return class ContactServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component contactService={contactService} {...this.props} />
			);
		}
	};
}

/**
 * @template Props
 * @param {React.ComponentType<Props>} Component
 * @returns {React.ComponentType<Props>}
 */
export function withDocumentsService(Component) {
	return class DocumentServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component
					documentsService={documentsService}
					{...this.props}
				/>
			);
		}
	};
}

/**
 * @param {React.ComponentType<{[key: string]: any, exportService: Partial<ExportService>}>} Component
 * @returns {React.ComponentType}
 */
export function withExportService(Component) {
	return class ExportServiceInjectedComponent extends React.Component {
		render() {
			return <Component exportService={exportService} {...this.props} />;
		}
	};
}

/**
 * @param {React.ComponentType<{[key: string]: any, homeownerService: Partial<HomeownerService>}>} Component
 * @returns {React.ComponentType}
 */
export function withHomeownerService(Component) {
	return class HomeownerServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component
					homeownerService={homeownerService}
					{...this.props}
				/>
			);
		}
	};
}

/**
 * @param {React.ComponentType<{[key: string]: any, marketplaceService: Partial<MarketplaceService>}>} Component
 * @returns {React.ComponentType}
 */
export function withMarketplaceService(Component) {
	return class MarketplaceServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component
					marketplaceService={marketplaceService}
					{...this.props}
				/>
			);
		}
	};
}

/**
 * @param {React.ComponentType<{[key: string]: any, marketplaceService: Partial<MarketplaceService>}>} Component
 * @returns {React.ComponentType}
 */
export function withMarketplaceServiceActivityProxy(Component) {
	return class MarketplaceServiceActivityProxyInjectedComponent extends React.Component {
		render() {
			return (
				<Component
					marketplaceService={marketplaceServiceActivityProxy}
					{...this.props}
				/>
			);
		}
	};
}

/**
 * @param {React.ComponentType<{[key: string]: any, groupService: Partial<GroupService>}>} Component
 * @returns {React.ComponentType}
 */
export function withGroupService(Component) {
	return class GroupServiceInjectedComponent extends React.Component {
		render() {
			return <Component groupService={groupService} {...this.props} />;
		}
	};
}

/**
 * @param {React.ComponentType<{[key: string]: any, themeService: Partial<ThemeService>}>} Component
 * @returns {React.ComponentType}
 */
export function withThemeService(Component) {
	return class ThemeServiceInjectedComponent extends React.Component {
		render() {
			return <Component themeService={themeService} {...this.props} />;
		}
	};
}

/**
 * @param {React.ComponentType<{[key: string]: any, taskService: Partial<TaskService>}>} Component
 * @returns {React.ComponentType}
 */
export function withTaskService(Component) {
	return class TaskServiceInjectedComponent extends React.Component {
		render() {
			return <Component taskService={taskService} {...this.props} />;
		}
	};
}

/**
 * @param {React.ComponentType<{[key: string]: any, offerService: Partial<OfferService>}>} Component
 * @returns {React.ComponentType}
 */
export function withOfferService(Component) {
	return class OfferServiceInjectedComponent extends React.Component {
		render() {
			return <Component offerService={offerService} {...this.props} />;
		}
	};
}

/**
 * @param {React.ComponentType<{[key: string]: any, userService: Partial<UserService>}>} Component
 * @returns {React.ComponentType}
 */
export function withApplianceService(Component) {
	return class ApplianceServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component
					applianceService={applianceService}
					{...this.props}
				/>
			);
		}
	};
}

/**
 * @param {React.ComponentType<{[key: string]: any, userService: Partial<UserService>}>} Component
 * @returns {React.ComponentType}
 */
export function withUserService(Component) {
	return class UserServiceInjectedComponent extends React.Component {
		render() {
			return <Component userService={userService} {...this.props} />;
		}
	};
}

/**
 * @param {any} Component
 * @returns {React.ComponentType}
 */
export function withActivityService(Component) {
	return class ActivityServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component activityService={activityService} {...this.props} />
			);
		}
	};
}

/**
 * @param {React.ComponentType<{[key: string]: any, userService: Partial<UserService>}>} Component
 * @returns {React.ComponentType}
 */
export function withAdministratorService(Component) {
	return class AdministratorServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component
					administratorService={administratorService}
					{...this.props}
				/>
			);
		}
	};
}

/**
 * @param {React.ComponentType<{[key: string]: any, userService: Partial<UserService>}>} Component
 * @returns {React.ComponentType}
 */
export function withAgentService(Component) {
	return class AgentServiceInjectedComponent extends React.Component {
		render() {
			return <Component agentService={agentService} {...this.props} />;
		}
	};
}

/**
 * @param {React.ComponentType<{[key: string]: any, userService: Partial<UserService>}>} Component
 * @returns {React.ComponentType}
 */
export function withAgentContactService(Component) {
	return class AgentContactServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component
					agentContactService={agentContactService}
					{...this.props}
				/>
			);
		}
	};
}

/**
 * @param {React.ComponentType<{[key: string]: any, userService: Partial<UserService>}>} Component
 * @returns {React.ComponentType}
 */
export function withAllConnectService(Component) {
	return class AllConnectServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component
					allConnectService={allConnectService}
					{...this.props}
				/>
			);
		}
	};
}

/**
 * @param {React.ElementType} Component
 * @returns {React.ComponentType}
 */
export function withRegistrationService(Component) {
	return class RegistrationServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component
					registrationService={registrationService}
					{...this.props}
				/>
			);
		}
	};
}

/**
 * @param {React.ElementType} Component
 * @returns {React.ComponentType}
 */
export function withSubscriptionService(Component) {
	return class SubscriptionServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component
					subscriptionService={subscriptionService}
					{...this.props}
				/>
			);
		}
	};
}

/**
 * @template {{[key: string]: any, authService: Partial<AuthService>}} Props
 * @param {React.ComponentType<Props>} Component
 * @returns {React.ComponentType<Props>}
 */
export function withPropertyService(Component) {
	return class PropertyServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component propertyService={propertyService} {...this.props} />
			);
		}
	};
}

/**
 * @template {{[key: string]: any, authService: Partial<AuthService>}} Props
 * @param {React.ComponentType<Props>} Component
 * @returns {React.ComponentType<Props>}
 */
export function withChecklistService(Component) {
	return class ChecklistServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component
					checklistService={checklistService}
					{...this.props}
				/>
			);
		}
	};
}

/**
 * @template {KeyedObject} Props
 * @template {React.ComponentType<Props>} ReactComponent
 * @param {ReactComponent} Component
 * @returns {React.ComponentType<Props>}
 */
export function withSettingService(Component) {
	/* eslint-disable-next-line react/display-name */
	return class extends React.Component {
		render() {
			return (
				// @ts-ignore -- TODO: Reconcile React prop issues
				<Component settingService={settingService} {...this.props} />
			);
		}
	};
}

/**
 * @param {React.ElementType} Component
 * @returns {React.ComponentType}
 */
export function withMailingService(Component) {
	return class MailingServiceInjectedComponent extends React.Component {
		render() {
			return (
				<Component mailingService={mailingService} {...this.props} />
			);
		}
	};
}
