import { AuthContext } from '../../shared/AuthProvider';
import { translate } from '../../App/Internationalization';
import images from '../../config/local/images';
import NavigationListItem from '../../App/shared/NavigationListItem';
import NavigationListItemContent from '../../App/shared/NavigationListItemContent';
import React from 'react';

export default class NavigationListSignOut extends React.Component {
	/**  @returns {JSX.Element} */
	render() {
		const handleSignOut = this.context.signOut;
		return (
			<NavigationListItem onClick={handleSignOut}>
				<button className="nav-link w-full" type="button">
					<NavigationListItemContent icon={images.icons.signOut}>
						{translate('registration.sign_out')}
					</NavigationListItemContent>
				</button>
			</NavigationListItem>
		);
	}
}

NavigationListSignOut.contextType = AuthContext;
