/**
 * @param value
 */
export default function formatWebsiteForDisplay(value) {
	if (!value) {
		return value;
	}

	return value.includes('://') ? value : `http://${value}`;
}
