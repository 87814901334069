import { chartColor } from '../../../../../utils/chartColor';
import { translate } from '../../../../Internationalization';
import BaseDonutChart from '../../../../../shared/BaseDonutChart';
import React from 'react';

/** @extends {React.Component<>} */
export default class GenericMoversOverlay extends React.Component {
	static get #labels() {
		return [
			translate('agent.pages.dashboard.movers.labels.likely'),
			translate('agent.pages.dashboard.movers.labels.potential'),
			translate('agent.pages.dashboard.movers.labels.unlikely'),
		];
	}

	static get #data() {
		return {
			datasets: [
				{
					backgroundColor: [
						chartColor.movers.green,
						chartColor.movers.yellow,
						chartColor.movers.grey,
					],
					data: [15, 35, 50],
				},
			],
			labels: GenericMoversOverlay.#labels,
		};
	}

	/**
	 * @returns {JSX.Element}
	 */
	/* eslint-disable class-methods-use-this -- Render method must be invokable by `this` */
	render() {
		return (
			<section className="md:flex justify-between mt-2">
				<article className="mr-4">
					<p className="h-ms-4">
						{translate(
							'agent.pages.dashboard.movers.non_upgraded.title'
						)}
					</p>
					<p className="text-sm mt-2">
						{translate(
							'agent.pages.dashboard.movers.non_upgraded.description_1'
						)}
					</p>
					<p
						className="text-sm mt-2"
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: translate(
								'agent.pages.dashboard.movers.non_upgraded.description_2'
							),
						}}
					/>
				</article>
				<figure className="graph">
					<BaseDonutChart
						data={GenericMoversOverlay.#data}
						hideInnerText={false}
						text={50}
					/>
				</figure>
			</section>
		);
	}
}
