import { translate } from 'App/Internationalization';
import Page from 'shared/Page';
import paths from 'config/local/paths';
import React from 'react';
import Switch from 'shared/Switch';
import ViewWidgets from './ViewWidgets';

export default class Widgets extends React.Component {
	// eslint-disable-next-line class-methods-use-this
	render() {
		return (
			<Switch>
				<Page
					component={ViewWidgets}
					exact={true}
					path={paths.app.agent.widgets.root}
					title={translate('agent.nav.main.widgets')}
				/>
			</Switch>
		);
	}
}
