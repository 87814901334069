import { translate } from '../../../App/Internationalization';
import FormField from './FormField';
import InputEmail from '../Inputs/InputEmail';
import React from 'react';

export default class FieldEmail extends React.Component {
	render() {
		const label = translate('global.forms.inputs.email.label');

		return (
			<FormField
				className={this.props.className}
				inputName={this.props.name}
				label={this.props.label ?? label}
				labelClass={this.props.labelClass}
				name={this.props.name}
				required={this.props.required}
			>
				<InputEmail
					className={this.props.inputClass}
					disabled={this.props.disabled}
					name={this.props.name}
					placeholder={this.props.placeholder}
					required={this.props.required}
				/>
			</FormField>
		);
	}
}

FieldEmail.defaultProps = {
	className: FormField.defaultProps.className,
	disabled: InputEmail.defaultProps.disabled,
	inputClass: InputEmail.defaultProps.className,
	labelClass: FormField.defaultProps.labelClass,
	name: InputEmail.defaultProps.name,
	placeholder: InputEmail.defaultProps.placeholder,
	required: InputEmail.defaultProps.required,
};
