// Two separate typedef blocks required because template is used.
/**
 * @template Values
 * @typedef {import('formik').FormikProps<Values>} FormikProps
 */

/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 * @typedef {import('../../../../shared/ImportSpreadsheetForm/Values').default} FormValues
 * @typedef {import('../../../../config/local/Import/Data').default<'agent'>} ImportData
 */

import { Redirect } from 'react-router-dom';
import { translate } from '../../../Internationalization';
import {
	withAgentService,
	withGroupService,
} from '../../../../service-container';
import assetUrls from '../../../../config/local/asset-urls';
import captureError from '../../../../utils/captureError';
import FormWarningMessages from '../../../../shared/Forms/Messages/FormWarningMessages';
import Import from '../../../../shared/Import/Import';
import Loading from '../../../../shared/Loading';
import NoContent from '../../../../shared/NoContent';
import React from 'react';

const defaultGroupName = translate(
	'admin.pages.import_agents.default_group_name'
);
const warningMessage = translate(
	'global.import.warning_message',
	translate('import_spreadsheet_form.agents')
);

/** @extends {React.Component<Props, State>} */
export class ImportAgents extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			formErrorMessages: [],
			formWarningMessages: [],
			groupName: defaultGroupName,
			groupNotFound: false,
			isLoading: true,
			submissionIsComplete: false,
		};

		this._handleFormSubmit = this._handleFormSubmit.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		if (this.props.match?.params?.groupId !== undefined) {
			await this.#getAndSetGroupName(this.props.match.params.groupId);
		} else {
			this.setState({
				isLoading: false,
			});
		}
	}

	/**
	 * @param {string} groupId
	 * @returns {Promise<void>}
	 */
	async #getAndSetGroupName(groupId) {
		try {
			const group = await this.props.groupService.getGroup(groupId);

			this.setState({
				groupName: group?.name ?? defaultGroupName,
				groupNotFound: group === null,
				isLoading: false,
			});
		} catch (exception) {
			this.setState({
				isLoading: false,
			});
		}
	}

	/**
	 * @param {ImportData} data
	 * @param {File} file
	 * @protected
	 * @returns {Promise<void>}
	 */
	async _handleFormSubmit(data, file) {
		if (!data.validData.length) {
			this.setState({
				formErrorMessages: [
					translate(
						'import_spreadsheet_form.no_valid_data_uploaded',
						'agents'
					),
				],
			});
			return;
		}

		try {
			await this.props.agentService.importAgents(
				data,
				file,
				this.props.match?.params?.groupId
			);

			this.setState({
				formWarningMessages: [warningMessage],
				submissionIsComplete: true,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				formErrorMessages: [
					translate('global.unexpected_error_occurred'),
				],
			});
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (
			this.state.submissionIsComplete &&
			this.props.location?.state?.referrer
		) {
			return (
				<Redirect
					to={{
						pathname: this.props.location.state.referrer,
						state: {
							heading: translate('global.import.warning_heading'),
							message: warningMessage,
							warning: true,
						},
					}}
				/>
			);
		}

		if (this.state.isLoading) {
			return <Loading />;
		}

		if (this.state.groupNotFound) {
			return (
				<NoContent
					message={translate(
						'admin.pages.import_agents.group_not_found'
					)}
				/>
			);
		}

		const lead = translate('admin.pages.import_agents.lead');

		const formTitle = translate(
			'admin.pages.import_agents.form_title',
			this.state.groupName
		);

		return (
			<React.Fragment>
				<section className="max-w-md">
					<FormWarningMessages
						heading={translate(
							'import_spreadsheet_form.limit.title'
						)}
						messages={[
							translate('import_spreadsheet_form.limit.text'),
						]}
					/>
				</section>

				<Import
					disabled={this.state.submissionIsComplete}
					errorMessages={this.state.formErrorMessages}
					formTitle={formTitle}
					lead={lead}
					onSubmit={this._handleFormSubmit}
					successMessages={this.state.formWarningMessages}
					template={assetUrls.imports.templates.agents.basic}
					type="agent"
					userType="administrator"
				/>
			</React.Fragment>
		);
	}
}

/* @ts-ignore -- service-container error */
export default withGroupService(withAgentService(ImportAgents));
