// Two separate typedef blocks required because template is used.
/**
 * @template Values
 * @typedef {import('formik').FormikProps<Values>} FormikProps
 */

/**
 * @typedef {import('../../../shared/ImportSpreadsheetForm/Values').default} FormValues
 * @typedef {import('../../../config/local/Import/Data').default<'contact'>} ImportData
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */

import { AuthContext } from '../../../shared/AuthProvider';
import { BaseSVG } from '../../../shared/BaseSVG';
import { Redirect } from 'react-router-dom';
import { translate } from '../../Internationalization';
import {
	withAgentService,
	withContactService,
	withSubscriptionService,
} from '../../../service-container';
import assetUrls from '../../../config/local/asset-urls';
import captureError from '../../../utils/captureError';
import Feature from '../../../shared/Feature';
import FieldRadioGroup from '../../../shared/Forms/Fields/FieldRadioGroup';
import GroupSelection from '../../Admin/shared/GroupSelection';
import images from '../../../config/local/images';
import Import from '../../../shared/Import/Import';
import Loading from '../../../shared/Loading';
import NoContent from '../../../shared/NoContent';
import paths from '../../../config/local/paths';
import React from 'react';

const defaultAgentName = translate('import_contacts.default_agent_name');
const warningMessage = translate(
	'global.import.warning_message',
	translate('import_spreadsheet_form.contacts')
);

/** @extends {React.Component<Props, State>} */
export class ImportContacts extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			activeTab: props.location?.state?.activeTab ?? null,
			agentId: null,
			agentName: defaultAgentName,
			agentNotFound: false,
			formErrorMessages: [],
			formWarningMessages: [],
			groupId: null,
			groups: [],
			isLoading: true,
			sendInvites: true,
			submissionIsComplete: false,
		};

		this._handleActiveTab = this._handleActiveTab.bind(this);
		this._handleFormSubmit = this._handleFormSubmit.bind(this);
		this._handleGroupId = this._handleGroupId.bind(this);
		this._handleSendInvites = this._handleSendInvites.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		const administratorImport = this.props.match?.params?.agentId
			? true
			: false;
		const agent = administratorImport
			? await this.props.agentService.getAgent(
					// @ts-ignore checked above
					this.props.match.params.agentId
			  )
			: undefined;

		if (!agent && administratorImport) {
			this.setState({
				agentNotFound: true,
				isLoading: false,
			});
			return;
		}

		if (agent) {
			this.setState({
				agentId: agent.id,
				agentName: `${agent.name.first} ${agent.name.last}`,
				groups: agent.groups,
			});
		}

		this.setState({
			isLoading: false,
		});
	}

	/**
	 * @protected
	 * @param {React.ChangeEvent<HTMLSelectElement>} event
	 * @returns {void}
	 */
	_handleGroupId(event) {
		this.setState({
			groupId: event.target.value,
		});
	}

	/**
	 * @protected
	 * @param {React.ChangeEvent<HTMLSelectElement>} event
	 * @returns {void}
	 */
	_handleSendInvites(event) {
		this.setState({
			sendInvites: event.target.value !== 'no',
		});
	}

	/**
	 * @protected
	 * @param {React.ChangeEvent<HTMLSelectElement>} event
	 * @returns {void}
	 */
	_handleActiveTab(event) {
		this.setState({
			activeTab: event.target.value,
		});
	}

	/**
	 * @param {ImportData} data
	 * @param {File} file
	 * @returns {Promise<void>}
	 */
	// eslint-disable-next-line complexity
	async _handleFormSubmit(data, file) {
		if (!data.validData.length) {
			this.setState({
				formErrorMessages: [
					translate(
						'import_spreadsheet_form.no_valid_data_uploaded',
						'contacts'
					),
				],
			});
			return;
		}

		try {
			const groupId = this.#resolveGroupId();
			const source = this.props.location?.state?.managerRelatedId
				? 'Manager imported'
				: 'Agent imported';

			await this.props.contactService.importContacts(
				{ data, file },
				source,
				this.props.match?.params?.agentId ?? undefined,
				groupId ?? undefined,
				this.state.sendInvites
			);

			this.setState({
				formErrorMessages: [],
				formWarningMessages: [warningMessage],
				submissionIsComplete: true,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				formErrorMessages: [
					translate('global.unexpected_error_occurred'),
				],
			});
		}
	}

	/**
	 * @returns {string | null}
	 */
	#resolveGroupId() {
		const groupId = this.state.groupId;
		if (groupId === '') {
			return null;
		}

		if (groupId) {
			return groupId;
		}

		const activeTab = this.#resolveActiveTab();

		if (activeTab) {
			return activeTab;
		}

		const managerRelatedId = this.props.location?.state?.managerRelatedId;

		if (managerRelatedId) {
			return managerRelatedId;
		}

		return null;
	}

	/**
	 * @returns {string | null}
	 */
	#resolveActiveTab() {
		const activeTab = this.state.activeTab;

		if (!activeTab) {
			return null;
		}

		if (activeTab === 'myContacts') {
			return null;
		}

		return activeTab;
	}

	/**
	 * @returns {string}
	 */
	#getFormTitle() {
		if (this.props.match?.params?.agentId) {
			return translate(
				'import_contacts.agent_title',
				this.state.agentName
			);
		}

		return translate('import_contacts.title');
	}

	/**
	 * @returns {boolean}
	 */
	#isAdminOrOwner() {
		if (this.context.roles.includes('admin')) {
			return true;
		}

		if (this.context.roles.includes('owner')) {
			return true;
		}

		return false;
	}

	/**
	 * @returns {JSX.Element}
	 */
	static get #getImportWarning() {
		const maxRows =
			Number(process.env.REACT_APP_MAX_IMPORT_ROWS)?.toLocaleString() ??
			'3,000';
		const type = translate('import_spreadsheet_form.contacts');

		const heading = (
			<h3 className="title uppercase">
				<i className="icon icon-xs">
					<BaseSVG path={images.icons.alert} />
				</i>
				{translate('global.messages.warning', type)}
			</h3>
		);

		return (
			<section className="form-alert w-full my-5 text-grey-900 form-alert-warning max-w-md">
				{heading}
				<div
					className="text rich-text"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: translate(
							'import_spreadsheet_form.warning_text',
							type,
							maxRows
						),
					}}
				/>
			</section>
		);
	}

	/* eslint-disable-next-line complexity */
	render() {
		const activeTab = this.state.activeTab;

		if (
			this.state.submissionIsComplete &&
			this.props.location?.state?.referrer
		) {
			return (
				<Redirect
					to={{
						pathname: this.props.location.state.referrer,
						state: {
							activeTab,
							heading: translate('global.import.warning_heading'),
							message: warningMessage,
							warning: true,
						},
					}}
				/>
			);
		}

		if (this.state.isLoading) {
			return <Loading />;
		}

		if (this.state.agentNotFound) {
			return (
				<NoContent
					message={translate('import_contacts.agent_not_found')}
				/>
			);
		}

		const lead = translate('import_contacts.lead');

		return (
			<React.Fragment>
				{ImportContacts.#getImportWarning}
				<Import
					activeTab={activeTab ?? null}
					disabled={this.state.submissionIsComplete}
					errorMessages={this.state.formErrorMessages}
					formTitle={this.#getFormTitle()}
					handleActiveTab={this._handleActiveTab}
					initialValues={{
						sendInvites: 'yes',
					}}
					lead={lead}
					onSubmit={this._handleFormSubmit}
					successMessages={this.state.formWarningMessages}
					template={assetUrls.imports.templates.contacts.full}
					type="contact"
					userType={
						this.props.match?.params?.agentId
							? 'administrator'
							: 'agent'
					}
				>
					<Feature
						fallback={null}
						name={
							this.props.location?.pathname ===
							paths.app.agent.contacts.import
								? 'agent:disable_contact_invite'
								: 'admin:disable_contact_invite'
						}
					>
						<fieldset className="mb-8">
							<legend className="h-ms-3 mb-6">
								{translate(
									'admin.pages.contacts.form.invitations'
								)}
							</legend>

							<FieldRadioGroup
								disabled={false}
								name="sendInvites"
								onClick={this._handleSendInvites}
								required={true}
								title={translate(
									'import_contacts.invitations_label'
								)}
								values={[
									[
										'yes',
										translate(
											'import_contacts.invitations_radio_labels.yes'
										),
									],
									[
										'no',
										translate(
											'import_contacts.invitations_radio_labels.no'
										),
									],
								]}
							/>
						</fieldset>
					</Feature>

					<Feature fallback={null} name="admin:group_selection">
						{this.#isAdminOrOwner() ? (
							<GroupSelection
								groups={this.state.groups}
								onClick={this._handleGroupId}
							/>
						) : null}
					</Feature>
				</Import>
			</React.Fragment>
		);
	}
}

ImportContacts.contextType = AuthContext;

// @ts-ignore service container error
export default withAgentService(
	withContactService(withSubscriptionService(ImportContacts))
);
