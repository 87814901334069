/**
 * @typedef {import('chart.js').Chart} Chart
 * @typedef {import('chart.js').LegendItem} LegendItem
 * @typedef {import('./BaseMultiLineChartProps').default} Props
 */
import { array } from '@mooveguru/js-utilities';
import { chartColor } from '../utils/chartColor';
import { formatDollarsForDisplay } from '../utils/formatCentsForDisplay';
import { getCssCustomProperty } from './ThemeProvider/theme-utilities';
import { Line } from 'react-chartjs-2';
import React from 'react';

/** @extends {React.PureComponent<Props>} */
export default class BaseMultiLineChart extends React.PureComponent {
	static defaultProps = {
		autoSkip: true,
		axisPadding: 10_000,
		isCurrency: true,
		showLegend: true,
		uniqueLabels: true,
	};

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const dataSets = [...this.props.values?.datasets];
		const labels = [...this.props.values?.labels];
		dataSets?.map((item) => {
			if (item?.data?.length === 1) {
				// @ts-ignore already declared in Props
				item.data = [0, ...item.data];
				labels.push(...labels);
			}
			return item;
		});
		const data = {
			datasets: dataSets,
			labels: !this.props.uniqueLabels ? labels : array.unique(labels),
		};

		const all = dataSets.flatMap((value) => Object.values(value.data));
		const max = Math.max(...all);
		const min = Math.min(...all);

		const options = {
			maintainAspectRatio: true,
			plugins: {
				legend: {
					display: this.props.showLegend ?? true,
					labels: {
						boxHeight: 6,
						boxWidth: 6,
						font: {
							color: getCssCustomProperty('theme-color-fg'),
							family: 'Outfit,Impact,Franklin Gothic Bold,sans-serif',
							weight: 'bold',
						},
						sort:
							/**
							 *
							 * @param {LegendItem} a
							 * @param {LegendItem} b
							 * @returns {number}
							 */
							(a, b) => a.text.localeCompare(b.text),
					},
					position: 'bottom',
				},
				tooltip: {
					enabled: true,
				},
			},
			responsive: true,
			scales: {
				xAxes: {
					ticks: {
						autoSkip: this.props.autoSkip ?? true,
						padding: 12,
					},
				},
				yAxes: {
					suggestedMax: max + this.props.axisPadding,
					suggestedMin: Math.max(min - this.props.axisPadding, 0),
					ticks: {
						callback: this.props.isCurrency
							? /**
							   * @param {number} value
							   * @returns {string}
							   */
							  (value) => formatDollarsForDisplay(value)
							: undefined,
						padding: 12,
					},
				},
			},
		};

		const plugins = [
			{
				/**
				 * @param {Chart} chart
				 */
				beforeDraw: function (chart) {
					const {
						chartArea: { left, top, width, height },
					} = chart;
					chart?.ctx?.save();
					chart?.ctx?.beginPath();
					chart?.ctx?.rect(left, top, width, height);
					chart.ctx.strokeStyle = chartColor.borderOuterColor;
					chart.ctx.lineWidth = 4;
					chart?.ctx?.stroke();
					chart?.ctx?.restore();
				},
				id: 'multiLineChart',
			},
		];

		return (
			// @ts-ignore -not getting options type as needed, will be updated
			<Line data={data} options={options} plugins={plugins} type="line" />
		);
	}
}
