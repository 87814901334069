/**
 * @typedef {import("./Props").default} Props
 * @typedef {{checked: boolean}} State
 */

import { translate } from '../../App/Internationalization';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export default class BulkSelectButton extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			checked: false,
		};

		this._handleOnChange = this._handleOnChange.bind(this);
	}

	/**
	 * @param {boolean} checked
	 * @returns {void}
	 */
	_handleOnChange(checked) {
		this.setState({ checked });

		if (checked) {
			return this.props.handleSelectAll();
		}

		return this.props.handleDeSelectAll();
	}

	/** @returns {React.ReactNode} */
	#getLabel() {
		if (this.props.children) {
			return this.props.children;
		}

		if (this.props.checked || this.state.checked) {
			return translate('agent.pages.contacts.view.deselect_all');
		}

		return translate('agent.pages.contacts.view.select_all');
	}

	/** @returns {JSX.Element} */
	render() {
		return (
			<div className="flex">
				<span className="form-check form-check-sm">
					<input
						aria-checked={this.props.checked ?? this.state.checked}
						aria-disabled={this.props.disabled || false}
						aria-label="Select All"
						checked={this.props.checked ?? this.state.checked}
						disabled={this.props.disabled || false}
						id="select-all"
						name="select-all"
						onChange={(event) =>
							this._handleOnChange(event.target.checked)
						}
						type="checkbox"
					/>
				</span>

				<label className="form-check-label" htmlFor="select-all">
					{this.#getLabel()}
				</label>
			</div>
		);
	}
}
