/**
 * @typedef {import('../../../Agent/agent-service').Contact} Contact
 * @typedef {import('../../../shared/AdministratorService/GetsAgentsByGroupIds').Agent} Agent
 * @typedef {import('../../Groups/Group').default} Group
 * @typedef {import('./EditContact').Props} Props
 * @typedef {import('./EditContact').State} State
 */

import { AuthContext } from '../../../../shared/AuthProvider';
import { getContact } from '../../../Agent/agent-service';
import { Redirect } from 'react-router';
import { translate } from '../../../Internationalization';
import {
	withContactService,
	withGroupService,
} from '../../../../service-container';
import captureError from '../../../../utils/captureError';
import concatPaths from '../../../../utils/contactPaths';
import ContactForm from '../../../Admin/Users/Contacts/ContactForm';
import ContactLimitPopup from '../../../shared/ContactLimitPopup/ContactLimitPopup';
import formatDateStringForDatepicker from '../../../../utils/formatDateStringForDatepicker';
import images from '../../../../config/local/images';
import Loading from '../../../../shared/Loading';
import paths from '../../../../config/local/paths';
import React from 'react';
import ResendInviteButton from '../../../shared/ResendInviteButton';

/** @extends {React.Component<Props, State>} */
class EditContact extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		/** @type {State} */
		this.state = {
			contactData: null,
			errorMessage: null,
			formComplete: false,
			initialGroupValues: null,
			isGettingData: true,
			resendInviteMessage: null,
			showLimitContactPopup: false,
		};

		this._handleLimitContactClose =
			this._handleLimitContactClose.bind(this);
		this.handleResendInvite = this.handleResendInvite.bind(this);
		this._handleSubmit = this._handleSubmit.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			// TODO: Update ContactService with modern get contact by id method
			const contactResponse = await getContact(
				this.props.match.params.contactId,
				this.context.accessToken
			);

			const initialGroupValues = await this.#getInitialGroupValues(
				contactResponse
			);

			const moveDate = contactResponse?.moveDate
				? formatDateStringForDatepicker(contactResponse?.moveDate)
				: '';
			const closeDate = contactResponse?.closeDate
				? formatDateStringForDatepicker(contactResponse?.closeDate)
				: '';

			this.setState({
				contactData: { ...contactResponse, closeDate, moveDate },
				initialGroupValues,
				isGettingData: false,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {Contact} contactData
	 * @returns {Promise<{agent: Agent | null, agentList: Agent[] | null, group: Group | null}>}
	 */
	async #getInitialGroupValues(contactData) {
		if (!contactData.groupId) {
			return {
				agent: null,
				agentList: null,
				group: null,
			};
		}

		const group = await this.props.groupService.getGroup(
			contactData.groupId
		);

		const agentList =
			await this.props.administratorService.getAgentsByGroupIds(
				contactData.groupId
			);

		const agent = agentList.find(
			/**
			 * @param  {Agent} groupAgent
			 * @returns {boolean}
			 */
			(groupAgent) => groupAgent.id === contactData.agentId
		);

		return {
			agent: agent ?? null,
			agentList,
			group,
		};
	}

	/**
	 * @protected
	 * @param {Contact} values
	 * @returns {Promise<void>}
	 */
	async _handleSubmit(values) {
		try {
			await this.props.contactService.updateContact(
				this.props.match.params.contactId,
				this.context.accessToken,
				values
			);

			this.setState({ errorMessage: null, formComplete: true });
		} catch (error) {
			// @ts-ignore -- status code added to error obj
			if (error?.statusCode === 403) {
				this.setState({
					showLimitContactPopup: true,
				});
				return;
			}
			captureError(error);
			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {React.ChangeEvent<HTMLSelectElement>} event
	 * @returns {Promise<void>}
	 */
	async handleResendInvite(event) {
		event.preventDefault();
		if (!this.state.contactData?.id) {
			return;
		}

		try {
			await this.props.contactService.inviteContact(
				this.state.contactData?.id
			);

			this.setState((prevState) => ({
				resendInviteMessage: translate(
					'admin.pages.contacts.edit.invite.success',
					prevState.contactData?.firstName ?? '',
					prevState.contactData?.lastName ?? ''
				),
			}));
		} catch (error) {
			captureError(error);

			this.setState({
				resendInviteMessage: translate(
					'admin.pages.contacts.edit.invite.error'
				),
			});
		}
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleLimitContactClose() {
		this.setState({ showLimitContactPopup: false });
	}

	/**
	 * @returns {JSX.Element}
	 */
	static #renderContactPopupContent() {
		const contactLimit = 'global.contact_limit';
		const contactCap = process.env.REACT_APP_CONTACT_CAP ?? '100';

		return (
			<React.Fragment>
				<div>
					{translate(
						`global.contact_limit.assign.header`,
						contactCap
					)}{' '}
					<span className="font-bold">
						{translate(`${contactLimit}.assign.body`)}
					</span>
				</div>

				<div>
					{translate(
						`global.contact_limit.assign.group_footer`,
						contactCap
					)}
				</div>
			</React.Fragment>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.formComplete) {
			return (
				<Redirect
					to={{
						pathname: paths.app.admin.contacts.view,
						state: {
							message: translate(
								'admin.pages.contacts.add.success_message'
							),
						},
					}}
				/>
			);
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<React.Fragment>
				<ContactLimitPopup
					onClose={this._handleLimitContactClose}
					showPopup={this.state.showLimitContactPopup}
					title={translate(`global.contact_limit.assign.title`)}
					to={concatPaths(
						paths.app.admin.groups.root,
						`/${this.state.contactData?.groupId}/subscription`
					)}
				>
					{EditContact.#renderContactPopupContent()}
				</ContactLimitPopup>

				<ContactForm
					additionalButtons={[
						<ResendInviteButton
							icon={images.icons.plus}
							key={0}
							onResendInvite={this.handleResendInvite}
							outline={true}
							size="large"
						/>,
					]}
					contactData={this.state.contactData}
					error={this.state?.errorMessage}
					formTitle={translate('admin.pages.contacts.edit.title')}
					initialGroupValues={this.state.initialGroupValues}
					onResendInvite={this.handleResendInvite}
					onSubmit={this._handleSubmit}
					resendInviteMessage={this.state.resendInviteMessage}
					submitText={translate('admin.pages.contacts.edit.update')}
				/>
			</React.Fragment>
		);
	}
}

// @ts-ignore -- service container error
export default withContactService(withGroupService(EditContact));

EditContact.contextType = AuthContext;
