import { translate } from '../../Internationalization';
import convertNumberToFormattedString from '../../../utils/convertNumberToFormattedString';
import formatCentsForDisplay from '../../../utils/formatCentsForDisplay';
import formatDateForDisplay from '../../../utils/formatDateForDisplay';
import React from 'react';

export default class ComparableMarketProperty extends React.Component {
	render() {
		const address = this.props.comparable.location.address;
		const status =
			this.props.comparable.status === 'available'
				? 'listed'
				: this.props.comparable.status;
		return (
			<li className="list-item">
				<h4 className="h-ms">
					{address.substring(0, address.length - 6)}
				</h4>
				<div className="-text-ms-2 mt-1.5">
					{this.props.comparable.property.bedroomCount}
					{translate('homeowner.pages.finances.view.market.bedrooms')}
					, {this.props.comparable.property.bathroomCount}
					{translate(
						'homeowner.pages.finances.view.market.bathrooms'
					)}
					,{' '}
					{convertNumberToFormattedString(
						this.props.comparable.property.squareFootage
					)}
					{translate(
						'homeowner.pages.finances.view.market.square_feet'
					)}
				</div>
				<div className="justified-row mt-1.5">
					<div className="-text-ms-1 font-bold text-theme-accent">
						{translate(
							`homeowner.pages.finances.view.market.${status}`
						)}{' '}
						{formatDateForDisplay(this.props.comparable.date)}
					</div>
					<div className="price">
						{formatCentsForDisplay(
							this.props.comparable.price * 100,
							0
						)}
					</div>
				</div>
			</li>
		);
	}
}
