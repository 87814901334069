import extractHtmlAttributeValue from '../utils/extractHtmlElementAttributeValue';
import HttpClient from '@mooveguru/js-http-client';
import config from '../config/local/blog';

/** @typedef {import ('../Blog/PostExcerpt').default} PostExcerpt */
/** @typedef {import ('../Blog/PostExcerptData').default} PostExcerptData */

const baseUrl = config.api.posts;
//@ts-ignore issue with httpClient package
const httpClient = new HttpClient();

/**
 * @param {string} slug
 */
export async function getPost(slug) {
	const response = await httpClient.get(`${baseUrl}?slug=${slug}`);

	if (!response.isOk) {
		throw new Error();
	}

	const data = response.body[0];

	if (data === undefined) {
		return null;
	}

	return {
		author: {
			headshotUrl: extractHtmlAttributeValue(data.author.image, 'src'),
			name: data.author.display_name,
		},
		canonical: `${config.url}/${slug}`,
		content: data.content.rendered,
		date: new Date(data.date),
		excerpt: data.excerpt.rendered,
		imageUrl: data.featured_media.images.medium_large.src,
		imageWidth: data.featured_media.images.medium_large.width,
		imageHeight: data.featured_media.images.medium_large.height,
		title: data.title.rendered,
		type: data.type,
	};
}

/**
 * @param {string} searchQuery
 * @param {number} page
 * @returns {Promise<{posts: PostExcerpt[], totalPages: number}>}
 */
export async function getPostExcerpts(searchQuery, page) {
	const searchParamsBuilder = new URLSearchParams();

	if (searchQuery) {
		searchParamsBuilder.append('search', searchQuery);
	}

	if (page) {
		searchParamsBuilder.append('page', page.toString());
	}

	const searchParams = searchParamsBuilder.toString();
	const url = searchParams === '' ? baseUrl : `${baseUrl}?${searchParams}`;
	const response = await httpClient.get(url);

	if (!response.isOk) {
		throw new Error();
	}
	return {
		posts: response.body.map(
			/**  @param {PostExcerptData} data  */
			(data) => ({
				author: {
					headshotUrl: extractHtmlAttributeValue(
						data.author.image,
						'src'
					),
					name: data.author.display_name,
				},
				content: data.excerpt.rendered,
				date: new Date(data.date),
				imageUrl: data.featured_media.images.medium_large.src,
				slug: data.slug,
				title: data.title.rendered,
			})
		),
		totalPages: +response.headers.get('X-WP-TotalPages'),
	};
}

/**
 *
 */
export async function getLatestPostExcerpt() {
	const response = await httpClient.get(`${baseUrl}?per_page=1`);

	if (!response.isOk) {
		throw new Error();
	}

	const data = response.body[0];

	if (data === undefined) {
		return null;
	}

	return {
		author: {
			headshotUrl: extractHtmlAttributeValue(data.author.image, 'src'),
			name: data.author.display_name,
		},
		content: data.excerpt.rendered,
		date: new Date(data.date),
		imageUrl: data.featured_media.images.medium_large.src,
		slug: data.slug,
		title: data.title.rendered,
	};
}
