/**
 * @param {number[]} array
 * @returns {boolean}
 */
export default function arrayHasConsecutiveNumbers(array) {
	array.sort((a, b) => a - b);

	// eslint-disable-next-line no-plusplus
	for (let index = 0; index < array.length - 1; index++) {
		if (array[index] + 1 === array[index + 1]) {
			return true;
		}
	}

	return false;
}
