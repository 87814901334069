/**
 * @typedef {{ error: boolean | null; message: string }} State
 * @typedef {{readonly authService: AuthService;}} Props
 * @typedef {import('./AuthService/AuthService').default} AuthService
 */

import { Redirect } from 'react-router-dom';
import { signInSegment } from '../config/local/paths';
import { translate } from '../App/Internationalization';
import AlternateAppHeader from '../App/shared/AlternateAppHeader';
import captureError from '../utils/captureError';
import FormErrorMessages from '../shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from '../shared/Forms/Messages/FormSuccessMessages';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export default class ConfirmEmail extends React.Component {
	/** @param {Props} props  */
	constructor(props) {
		super(props);

		/** @type {State}  */
		this.state = {
			error: null,
			message: translate('registration.confirm_email_error'),
		};

		const searchParams = new URLSearchParams(window.location.search);

		this.token = searchParams.get('token');
	}

	/** @returns {Promise<void>} */
	async componentDidMount() {
		try {
			// @ts-ignore redirects if null
			await this.props.authService.confirmEmail(this.token);

			this.setState({
				message: translate('registration.email_confirmed'),
			});
		} catch (error) {
			captureError(error);

			this.setState({
				error: true,
				message: translate('registration.confirm_email_error'),
			});
		}
	}

	/** @returns {JSX.Element} */
	render() {
		if (this.token === null) {
			return <Redirect to={signInSegment} />;
		}

		const message = this.state.error ? (
			<FormErrorMessages messages={this.state.message} />
		) : (
			<FormSuccessMessages messages={this.state.message} />
		);

		return (
			<React.Fragment>
				<AlternateAppHeader />
				<section className="app-container md:w-1/2 mx-auto rich-text">
					<h2 className="text-center mt-12 mb-8">
						{translate('registration.confirm_email')}
					</h2>
					{message}
				</section>
			</React.Fragment>
		);
	}
}
