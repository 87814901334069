/**
 * @typedef {import('../../../Auth/AuthService').default} AuthService
 * @typedef {import('../AgentService/Agent').AdBanner} AdBanner
 * @typedef {import('../AgentService/Agent').AdBannerData} AdBannerData
 * @typedef {import('./CreatesGroup').default} CreatesGroup
 * @typedef {import('./GetsGroup').default} GetsGroup
 * @typedef {import('./Group').Group} Group
 * @typedef {import('./Group').GroupBase} GroupBase
 * @typedef {import('./Group').GroupBaseRequest} GroupBaseRequest
 * @typedef {import('./Group').GroupRequest} GroupRequest
 * @typedef {import('@mooveguru/js-http-client').BrowserClient} HttpClient
 * @typedef {import('./SearchGroupResponse').default} SearchGroupResponse
 * @typedef {import('./UpdatesGroup').default} UpdatesGroup
 */

import apiUrls from '../../../config/local/api-urls';
import ServiceError from '../../../shared/Errors/ServiceError';

/**
 * @implements {GetsGroup}
 * @implements {UpdatesGroup}
 * @implements {CreatesGroup}
 */
export default class GroupService {
	/**
	 * @param {{authService: AuthService, httpClient: HttpClient }} dependencies
	 */
	constructor(dependencies) {
		this.httpClient = dependencies.httpClient;
		this.authService = dependencies.authService;
	}

	/**
	 * @param {string} query
	 * @param {number} page
	 * @param {number} limit
	 * @returns {Promise<SearchGroupResponse>}
	 */
	async searchGroup(query, page = 1, limit = 100) {
		const params = new URLSearchParams({
			limit: Number(limit).toString(),
			page: Number(page).toString(),
			query: query,
		}); // URLSearchParams wants strings and not number

		const response = await this.httpClient.get(
			`${apiUrls.groups.root}/?${params.toString()}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		const { body } = response;

		const responseMap = body.groups?.map(
			GroupService.#mapSearchGroupResponse
		);

		return (
			{ data: responseMap, pages: body.pages, total: body.total } ?? {}
		);
	}

	/**
	 * @param {GroupRequest} group
	 * @returns {Group}
	 */
	// eslint-disable-next-line complexity -- mapping response body
	static #mapSearchGroupResponse(group) {
		return {
			city: group.address?.city ?? '',
			cobranding: group.cobranding ? 'enabled' : 'disabled',
			country: group.address?.country ?? '',
			id: group.id,
			isSubscribed: group.is_subscribed,
			licenseNumber: group.license_number ?? null,
			name: group.name,
			parentId: group.parent_id,
			parentName: group?.parent_name || null,
			phone: group.phone_number,
			postalCode: group.address?.postal_code ?? '',
			slug: group.slug,
			state: group.address?.state ?? '',
			streetAddress1: group.address?.street_address_1 ?? '',
			streetAddress2: group.address?.street_address_2 ?? '',
			type: group.type,
		};
	}

	/**
	 * @param {string} groupId
	 * @returns {Promise<Group | null>}
	 */
	async getGroup(groupId) {
		const response = await this.httpClient.get(
			`${apiUrls.group.root}/${groupId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (response.statusCode === 404 || response.statusCode === 444) {
			return null;
		}

		if (!response.isOk) {
			throw new ServiceError(
				'There was a problem getting your group.',
				response.body.message
			);
		}

		const { results } = response.body;

		return {
			adBanner: GroupService.#mapAdBanner(results?.ad_banner),
			city: results.address?.city,
			cobranding: results.cobranding ? 'enabled' : 'disabled',
			country: results.address?.country,
			id: results._id,
			licenseNumber: results.license_number,
			name: results.name,
			parentId: results.parent_id,
			phone: results.phone,
			postalCode: results.address?.postal_code,
			slug: results.slug,
			state: results.address?.state,
			streetAddress1: results.address?.street_address_1,
			streetAddress2: results.address?.street_address_2,
			type: results.type,
		};
	}

	/**
	 * @param {AdBannerData[]} adBanner
	 * @returns {AdBanner[]}
	 */
	static #mapAdBanner(adBanner) {
		if (!adBanner?.length) {
			return [];
		}

		return adBanner?.map((banner) => ({
			active: banner?.active,
			brand: banner?.brand,
			location: {
				agentDashboard: banner?.location?.agent_dashboard,
				homeownerDashboard: banner?.location?.homeowner_dashboard,
			},
		}));
	}

	/**
	 * @param {string} groupId
	 * @param {Omit<Group, 'id'>} values
	 * @returns {Promise<void>}
	 */
	async updateGroup(groupId, values) {
		const response = await this.httpClient.put(
			`${apiUrls.groups.root}/${groupId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			GroupService.#mapUpdateGroup(values)
		);

		if (!response.isOk) {
			const { message } = response.body;
			const errorDescription =
				typeof response.body.message === 'object'
					? Object.keys(response.body.message)
							.map((issue) => message[issue].join(''))
							.join('')
					: response.body.error;
			throw new Error(errorDescription);
		}
	}

	/**
	 * @param {Omit<Group, 'id'>} values
	 * @returns {Omit<GroupRequest, 'id'>}
	 */
	// eslint-disable-next-line complexity -- mapping request body
	static #mapUpdateGroup(values) {
		return {
			address: {
				city: values.city ?? null,
				country: values.country ?? null,
				// eslint-disable-next-line camelcase -- mapping request body
				postal_code: values.postalCode ?? null,
				state: values.state ?? null,
				// eslint-disable-next-line camelcase -- mapping request body
				street_address_1: values.streetAddress1 ?? null,
				// eslint-disable-next-line camelcase -- mapping request body
				street_address_2: values.streetAddress2 ?? null,
			},
			// eslint-disable-next-line camelcase -- mapping request body
			cobranding: values.cobranding === 'enabled',
			// eslint-disable-next-line camelcase -- mapping request body
			license_number: values.licenseNumber ?? null,
			name: values.name,
			// eslint-disable-next-line camelcase -- mapping request body
			parent_id: values.parentId ?? null,
			// eslint-disable-next-line camelcase
			phone_number: values.phone ?? null,
			slug: values.slug,
			type: values.type,
		};
	}

	/**
	 * @param {GroupBase} values
	 * @throws {Error}
	 * @returns {Promise<string>}
	 */
	async createGroup(values) {
		const response = await this.httpClient.post(
			`${apiUrls.groups.root}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			GroupService.#createGroupMap(values)
		);

		if (!response.isOk) {
			throw new Error(response.body.join(''));
		}

		return response.body.id;
	}

	/**
	 * @param {GroupBase} values
	 * @returns {GroupBaseRequest}
	 */
	// eslint-disable-next-line complexity -- mapping request body
	static #createGroupMap(values) {
		return {
			address: {
				city: values.city ?? '',
				country: values.country ?? '',
				// eslint-disable-next-line camelcase -- mapping request body
				postal_code: values.postalCode ?? '',
				state: values.state ?? '',
				// eslint-disable-next-line camelcase -- mapping request body
				street_address_1: values.streetAddress1 ?? '',
				// eslint-disable-next-line camelcase -- mapping request body
				street_address_2: values.streetAddress2 ?? '',
			},
			// eslint-disable-next-line camelcase -- mapping request body
			cobranding: false,
			// eslint-disable-next-line camelcase -- mapping request body
			license_number: values.licenseNumber ?? '',
			name: values.name ?? '',
			// eslint-disable-next-line camelcase -- mapping request body
			parent_id: values.parentId ?? '',
			// eslint-disable-next-line camelcase -- mapping request body
			phone_number: values.phone ?? '',
			type: values.type,
		};
	}
}
