import React from 'react';
import { Redirect } from 'react-router-dom';

import AddAdmin from 'App/Admin/Users/Admins/AddAdmin';
import EditAdmin from 'App/Admin/Users/Admins/EditAdmin';
import ViewAdmins from 'App/Admin/Users/Admins/ViewAdmins';
import paths from 'config/local/paths';
import Page from 'shared/Page';
import Switch from 'shared/Switch';
import { translate } from 'App/Internationalization';

export default class Admins extends React.Component {
	render() {
		return (
			<Switch>
				<Redirect
					exact
					from={paths.app.admin.admins.root}
					to={paths.app.admin.admins.view}
				/>
				<Page
					component={ViewAdmins}
					path={paths.app.admin.admins.view}
					title={translate('admin.nav.admin.view')}
				/>
				<Page
					component={AddAdmin}
					path={paths.app.admin.admins.add}
					title={translate('admin.nav.admin.add')}
				/>
				<Page
					component={EditAdmin}
					path={`${paths.app.admin.admins.edit}/:administratorId`}
					title={translate('admin.nav.contacts.edit')}
				/>
			</Switch>
		);
	}
}
