import { translate } from '../../../App/Internationalization';
import ActionMessage from '../../../shared/ActionMessages/ActionMessage';
import React from 'react';

export default class ContactInvitePopup extends React.Component {
	renderPopupContent() {
		const inviteText = translate(
			'global.pages.contacts.resend_invite_popup.bulk',
			this.props.selectedContacts?.length.toString()
		);

		const inviteSingleContactText = (
			<div>
				<div>
					{translate(
						'global.pages.contacts.resend_invite_popup.single'
					)}
				</div>
				<span className="font-bold">{this.props.contactName}</span>
			</div>
		);

		const inviteSingleContact = this.props.selectedContacts?.length === 1;

		return (
			<section>
				{inviteSingleContact ? inviteSingleContactText : inviteText}
			</section>
		);
	}

	render() {
		return (
			<ActionMessage
				action="warning"
				onClose={this.props.onClose}
				onSubmit={this.props.onSubmit}
				title={translate('agent.pages.contacts.popup.header')}
			>
				{this.renderPopupContent()}
			</ActionMessage>
		);
	}
}
