import utilities from '@mooveguru/js-utilities';

/**
 * @deprecated
 * @param {Date} date
 * @returns {string}
 */
export default function convertDateToDateString(date) {
	return utilities.date.convertDateToYearMonthDay(date);
}
