/**
 * @typedef {import('./SubscriptionDetails').AgentSubscriptionLevels} AgentSubscriptionLevels
 * @typedef {import('./CheckoutSession').default} CheckoutSession
 * @typedef {import('./CheckoutSession').Response} CheckoutSessionResponse
 * @typedef {import('./Dependencies').default} Dependencies
 * @typedef {import('./GetsAgentSubscription').default} GetsAgentSubscription
 * @typedef {import('./GetsAgentSubscriptionCheckoutLink').default} GetsAgentSubscriptionCheckoutLink
 * @typedef {import('./GetsAgentSubscriptionManagementLink').default} GetsAgentSubscriptionManagementLink
 * @typedef {import('./GetsAgentSubscriptionStatus').default} GetsAgentSubscriptionStatus
 * @typedef {import('./GetsGroupSubscription').default} GetsGroupSubscription
 * @typedef {import('./GetsGroupSubscriptionCheckoutLink').default} GetsGroupSubscriptionCheckoutLink
 * @typedef {import('./GetsGroupSubscriptionManagementLink').default} GetsGroupSubscriptionManagementLink
 * @typedef {import('./GetsSubscriptionPrice').default} GetsSubscriptionPrice
 * @typedef {import('./ManagementSession').default} ManagementSession
 * @typedef {import('./ManagementSession').Response} ManagementSessionResponse
 * @typedef {import('./PaymentDetails').default} PaymentDetails
 * @typedef {import('./Pricing').default} Pricing
 * @typedef {import('./PricingDetails').default} PricingDetails
 * @typedef {import('./Subscription').default} Subscription
 * @typedef {import('./SubscriptionDetails').default} SubscriptionDetails
 * @typedef {import('@mooveguru/js-http-client/HttpResponse').default<PricingDetails>} PricingResponse
 * @typedef {import('@mooveguru/js-http-client/HttpResponse').default<SubscriptionDetails>} SubscriptionResponse
 */

import apiUrls from '../../../config/local/api-urls';
import ServiceError from '../../../shared/Errors/ServiceError';

/**
 * @implements {GetsAgentSubscription}
 * @implements {GetsAgentSubscriptionCheckoutLink}
 * @implements {GetsAgentSubscriptionManagementLink}
 * @implements {GetsAgentSubscriptionStatus}
 * @implements {GetsGroupSubscription}
 * @implements {GetsGroupSubscriptionCheckoutLink}
 * @implements {GetsGroupSubscriptionManagementLink}
 * @implements {GetsSubscriptionPrice}
 */
export default class SubscriptionService {
	/** @param {Dependencies} dependencies */
	constructor(dependencies) {
		this.authService = dependencies.authService;
		this.httpClient = dependencies.httpClient;
	}

	/** @returns {Promise<Subscription | {subscriptionLevels: AgentSubscriptionLevels}>} */
	async getAgentSubscription() {
		const response = await this.httpClient.get(
			apiUrls.me.subscription.root,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			SubscriptionService.handleNotOkResponse(response);
		}

		if (!response.body?.is_active) {
			return {
				subscriptionLevels: response.body.subscription_levels,
			};
		}

		return {
			agentCount: response.body.agent_count,
			expirationDate: new Date(response.body.expiration_date),
			isActive: response.body.is_active,
			isContactLimitExceeded: response.body.is_contact_limit_exceeded,
			isRenewing: response.body.is_renewing,
			priceInCents: response.body.price_in_cents,
			subscriptionLevels: response.body.subscription_levels,
		};
	}

	/**
	 * @param {string} userId
	 *  @returns {Promise<boolean>}
	 */
	async getAgentSubscriptionStatus(userId) {
		const response = await this.httpClient.get(
			apiUrls.agents.checkSubscription.replace(':userId', userId),
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (response.statusCode === 404 || response.statusCode === 444) {
			return false;
		}
		if (!response.isOk) {
			SubscriptionService.handleNotOkResponse(response);
		}

		return response.body.is_active;
	}

	/**
	 * @param {string} userId
	 * @returns {Promise<Subscription?>}
	 */
	async getAgentSubscriptionById(userId) {
		const response = await this.httpClient.get(
			`${apiUrls.agents.getSubscription}/${userId}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		return SubscriptionService.handleGetSubscriptionResponse(response);
	}

	/**
	 * @param {string} groupId
	 * @returns {Promise<Subscription?>}
	 */
	async getGroupSubscription(groupId) {
		const response = await this.httpClient.get(
			`${apiUrls.groups.root}/${groupId}/subscription`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		return SubscriptionService.handleGetSubscriptionResponse(response);
	}

	/**
	 * @returns {Promise<Pricing>}
	 * @throws {ServiceError}
	 */
	async getSubscriptionPrice() {
		const response = await this.httpClient.get(
			apiUrls.subscription.price,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		return SubscriptionService.handleGetPriceResponse(response);
	}

	/**
	 * @returns {Promise<CheckoutSession>}
	 */
	async getAgentSubscriptionCheckoutLink() {
		const response = await this.httpClient.get(
			apiUrls.me.subscription.checkout,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			})
		);

		if (!response.isOk) {
			SubscriptionService.handleNotOkResponse(response);
		}

		return SubscriptionService.mapResponseToCheckoutSession(response);
	}

	/**
	 * @returns {Promise<ManagementSession>}
	 */
	async getAgentSubscriptionManagementLink() {
		const response = await this.httpClient.get(
			apiUrls.me.subscription.manage,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			})
		);

		if (!response.isOk) {
			SubscriptionService.handleNotOkResponse(response);
		}

		return SubscriptionService.mapResponseToManagementSession(response);
	}

	/**
	 * @param {string} groupId
	 * @returns {Promise<CheckoutSession>}
	 */
	async getGroupSubscriptionCheckoutLink(groupId) {
		const response = await this.httpClient.get(
			`${apiUrls.groups.root}/${groupId}/subscription/checkout`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			})
		);

		if (!response.isOk) {
			SubscriptionService.handleNotOkResponse(response);
		}

		return SubscriptionService.mapResponseToCheckoutSession(response);
	}

	/**
	 * @param {string} groupId
	 * @returns {Promise<ManagementSession>}
	 */
	async getGroupSubscriptionManagementLink(groupId) {
		const response = await this.httpClient.get(
			`${apiUrls.groups.root}/${groupId}/subscription/manage`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			})
		);

		if (!response.isOk) {
			SubscriptionService.handleNotOkResponse(response);
		}

		return SubscriptionService.mapResponseToManagementSession(response);
	}

	/**
	 *  @param {PricingResponse} response
	 *	@returns {Pricing}
	 *  @throws {ServiceError}
	 */
	static handleGetPriceResponse(response) {
		if (!response.isOk) {
			SubscriptionService.handleNotOkResponse(response);
		}

		return {
			contactPriceInCents: response.body.contact_price_in_cents,
			priceInCents: response.body.price_in_cents,
		};
	}

	/** @param {SubscriptionResponse} response */
	static handleGetSubscriptionResponse(response) {
		if (response.statusCode === 404 || response.statusCode === 444) {
			return null;
		}
		if (!response.isOk) {
			SubscriptionService.handleNotOkResponse(response);
		}

		return SubscriptionService.mapResponseToSubscription(response);
	}

	/**
	 *  @param {{ body: any }} response
	 * 	@throws {ServiceError}
	 */
	static handleNotOkResponse(response) {
		throw new ServiceError(
			Array.isArray(response?.body)
				? response?.body[0]
				: 'Unexpected Error Occured: Error Getting Payment Details Response.',
			response?.body
		);
	}

	/**
	 * @param {CheckoutSessionResponse} response
	 * @returns {CheckoutSession}
	 */
	static mapResponseToCheckoutSession(response) {
		return {
			expires: new Date(response.body.expires),
			url: response.body.url,
		};
	}

	/**
	 * @param {ManagementSessionResponse} response
	 * @returns {ManagementSession}
	 */
	static mapResponseToManagementSession(response) {
		return {
			url: response.body.url,
		};
	}

	/**
	 * @param {PricingResponse} response
	 * @returns {PaymentDetails}
	 */
	static mapResponseToPaymentDetails(response) {
		return {
			agentCount: response.body.agent_count,
			clientSecret: response.body.client_secret,
			couponName: response.body.coupon_name,
			priceInCents: response.body.price_in_cents,
		};
	}

	/**
	 * @param {SubscriptionResponse} response
	 * @returns {Subscription}
	 */
	static mapResponseToSubscription(response) {
		return {
			agentCount: response.body.agent_count,
			expirationDate: new Date(response.body.expiration_date),
			isActive: response.body.is_active,
			isContactLimitExceeded: response.body.is_contact_limit_exceeded,
			isRenewing: response.body.is_renewing,
			priceInCents: response.body.price_in_cents,
			subscriptionLevels: response.body.subscription_levels ?? [],
		};
	}
}
