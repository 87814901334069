import { translate } from '../../../App/Internationalization';
import { withThemeService } from 'service-container';
import EditTheme from 'App/shared/EditTheme';
import React from 'react';

/** @typedef {import('App/Agent/Theme/EditAgentThemeProps').default} Props */

/** @extends {React.Component<Props>} */
export class EditAgentTheme extends React.Component {
	render() {
		return (
			<EditTheme
				getTheme={this.props.themeService.getAgentTheme.bind(
					this.props.themeService
				)}
				heading={translate('global.header.edit_your_branding')}
				setFavicon={this.props.themeService.setAgentFavicon.bind(
					this.props.themeService
				)}
				setLogo={this.props.themeService.setAgentLogo.bind(
					this.props.themeService
				)}
				setTheme={this.props.themeService.setAgentTheme.bind(
					this.props.themeService
				)}
				userType="agent"
			/>
		);
	}
}

export default withThemeService(EditAgentTheme);
