/**
 * @typedef {import('./AgentComponent').Props} Props
 * @typedef {import('./AgentComponent').State} State
 */

import { AuthContext } from '../../shared/AuthProvider';
import { Redirect } from 'react-router-dom';
import { translate } from '../Internationalization';
import { withTheme } from '../../shared/ThemeProvider';
import { withSettings } from '../../shared/SettingProvider/SettingProvider';
import { withUserService } from '../../service-container';
import AppLayout from '../AppLayout';
import captureError from '../../utils/captureError';
import Connections from './Connections';
import Contacts from './Contacts';
import Dashboard from './Dashboard';
import Integrations from './Integrations';
import LoadingScreen from '../../shared/LoadingScreen';
import Offers from './Offers';
import Page from '../../shared/Page';
import paths from '../../config/local/paths';
import React from 'react';
import Subscription from './Subscription';
import SubscriptionWarningMessage from '../Admin/Groups/Subscription/SubscriptionWarningMessage';
import Switch from '../../shared/Switch';
import Theme from './Theme';
import Widgets from './Widgets';

const ThemedDashboard = withTheme(Dashboard);
/** @extends {React.Component<Props, State>} */
export class Agent extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/**
		 * @param {State} state
		 */
		this.state = {
			isGettingData: true,
			name: '',
		};
	}

	async componentDidMount() {
		this.context.setModule('agent');
		try {
			await Promise.all([
				this.getAndSetUserName(),
				this.props.theme.getAndSetActiveTheme('agent'),
			]);
		} catch (error) {
			captureError(error);
		}
	}

	async getAndSetUserName() {
		const response = await this.props.userService.getUserName(
			this.context.accessToken
		);
		this.setState({
			isGettingData: false,
			name: response,
		});
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (this.state.isGettingData) {
			return <LoadingScreen />;
		}

		const isContactLimitExceeded =
			this.context?.isContactLimitExceeded &&
			this.props.settings.features.get(
				'agent:limit_contacts_on_free_accounts'
			);

		const dashboard = isContactLimitExceeded
			? SubscriptionWarningMessage
			: ThemedDashboard;

		const theme = isContactLimitExceeded
			? SubscriptionWarningMessage
			: Theme;

		const offer = isContactLimitExceeded
			? SubscriptionWarningMessage
			: Offers;

		return (
			<AppLayout className="sm" userName={this.state.name}>
				<Switch>
					<Redirect
						exact={true}
						from={paths.app.agent.root}
						to={paths.app.agent.dashboard}
					/>
					<Page
						component={dashboard}
						path={paths.app.agent.dashboard}
						title={translate('agent.nav.main.dashboard')}
						withContainer={true}
					/>
					<Page
						component={theme}
						path={paths.app.agent.theme}
						title={translate('agent.nav.main.branding')}
						withContainer={true}
					/>
					<Page
						component={Contacts}
						path={paths.app.agent.contacts.root}
						title={translate('agent.nav.main.contacts')}
						withContainer={true}
					/>
					<Page
						component={offer}
						path={paths.app.agent.offers.root}
						title={translate('agent.nav.main.vendors')}
						withContainer={true}
					/>
					<Page
						component={Connections}
						path={paths.app.agent.connections.root}
						title={translate('agent.nav.main.connections')}
						withContainer={true}
					/>
					<Page
						component={Integrations}
						path={paths.app.agent.integrations.root}
						title={translate('agent.nav.main.integrations')}
						withContainer={true}
					/>
					<Page
						component={Subscription}
						path={paths.app.agent.subscription.root}
						title={translate('agent.nav.main.subscription')}
						withContainer={true}
					/>
					<Page
						component={Widgets}
						path={paths.app.agent.widgets.root}
						title={translate('agent.nav.main.widgets')}
						withContainer={true}
					/>
				</Switch>
			</AppLayout>
		);
	}
}

Agent.contextType = AuthContext;
// @ts-ignore will be fix with service container
export default withUserService(withSettings(Agent));
