/**
 * @typedef {import('./AgentCommunicationPreferencesComponent').Props} Props
 * @typedef {import('./AgentCommunicationPreferencesComponent').State} State
 * @typedef {import('formik').FormikProps<{ [key: string]: string }>} FieldProps
 */

import { translate } from '../Internationalization';
import { withAgentService } from '../../service-container';
import { withAuth } from '../../shared/AuthProvider';
import { withSettings } from '../../shared/SettingProvider/SettingProvider';
import { withTheme } from '../../shared/ThemeProvider';
import BaseForm from '../../shared/Forms/BaseForm';
import captureError from '../../utils/captureError';
import FieldRadioGroup from '../../shared/Forms/Fields/FieldRadioGroup';
import FormErrorMessages from '../../shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from '../../shared/Forms/Messages/FormSuccessMessages';
import Loading from '../../shared/Loading';
import React from 'react';
import SubmitButton from '../../shared/Forms/Inputs/SubmitButton';

/** @extends {React.Component<Props, State>} */
class AgentCommunicationPreferences extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			error: false,
			isGettingData: true,
			marketActivity: false,
			success: false,
		};

		this._handleChange = this._handleChange.bind(this);
		this._handleSubmit = this._handleSubmit.bind(this);
	}

	async componentDidMount() {
		if (!this.#hasMarketActivity) {
			return;
		}

		try {
			const response = await this.props.agentService.getAgentSettings();

			this.setState({
				marketActivity: response?.campaigns?.marketActivity,
			});
		} catch (error) {
			captureError(error);
		} finally {
			this.setState({ isGettingData: false });
		}
	}

	/** @returns {boolean} */
	get #hasMarketActivity() {
		return (
			this.props.settings.features.get('agent:send_market_activity') ??
			false
		);
	}

	/**
	 * @param {string} value
	 * @returns {boolean}
	 */
	static valueToBool(value) {
		return Boolean(Number(value)).valueOf();
	}

	/**
	 * @param {boolean} value
	 * @returns {string}
	 */
	static valueToString(value) {
		return Number(value).valueOf().toString();
	}

	/**
	 * @protected
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @returns {void}
	 */
	_handleChange(event) {
		// @ts-ignore State is only form fields
		if (event.target.value === this.state[event.target.name]) {
			return;
		}

		this.setState(
			/**
			 * @param {State} prev
			 * @returns {State}
			 */
			(prev) => ({
				...prev,
				[event.target.name]: AgentCommunicationPreferences.valueToBool(
					event.target.value
				),
			})
		);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async _handleSubmit() {
		this.setState({ error: false, success: false });

		try {
			await this.props.agentService.updateEmailCommunicationPreferences(
				{
					// eslint-disable-next-line camelcase
					market_activity:
						this.state.marketActivity ?? this.#hasMarketActivity,
				},
				this.props.auth.accessToken
			);

			this.setState({ success: true });
		} catch (error) {
			captureError(error);
			this.setState({ error: true });
		}
	}

	/** @returns {JSX.Element} */
	static #renderHeader() {
		return (
			<h2 className="h-ms-5 mb-6">
				{translate('settings.communications.title')}
			</h2>
		);
	}

	/** @returns {JSX.Element | null} */
	#renderSuccess() {
		if (this.state.success) {
			return (
				<FormSuccessMessages
					messages={translate(
						'settings.communications.messages.success'
					)}
				/>
			);
		}

		if (this.state.error) {
			return (
				<FormErrorMessages
					messages={translate(
						'settings.communications.messages.error'
					)}
				/>
			);
		}

		return null;
	}

	/** @returns {React.ReactNode} */
	render() {
		if (!this.#hasMarketActivity) {
			return null;
		}

		if (this.state.isGettingData) {
			return (
				<section>
					{AgentCommunicationPreferences.#renderHeader()}
					<Loading />
				</section>
			);
		}

		return (
			<section>
				{AgentCommunicationPreferences.#renderHeader()}
				{this.#renderSuccess()}

				<BaseForm
					className="mb-8"
					initialValues={{
						marketActivity: this.state.marketActivity ? '1' : '0',
					}}
					noRequiredText={true}
					onSubmit={this._handleSubmit}
				>
					<fieldset
						className="flex flex-col gap-8"
						key={`campaigns-toggle-market_activity`}
					>
						<FieldRadioGroup
							className="communication-preference"
							helpText={translate(
								`settings.communications.campaign.market_activity.description`,
								this.props.theme.appTitle
							)}
							id={'market_activity'}
							label={translate(
								`settings.communications.campaign.market_activity.title`
							)}
							labelClassName="h-ms-2"
							name={'marketActivity'}
							onChange={this._handleChange}
							title={translate(
								`settings.communications.campaign.market_activity.title`
							)}
							values={[
								[
									'1',
									translate(
										'global.offers.vendor_exclusivity.radio_labels.yes'
									),
								],
								[
									'0',
									translate(
										'global.offers.vendor_exclusivity.radio_labels.no'
									),
								],
							]}
						/>
					</fieldset>
					<SubmitButton>
						{translate('global.forms.buttons.save')}
					</SubmitButton>
				</BaseForm>
			</section>
		);
	}
}

export default withSettings(
	withAuth(
		// @ts-ignore
		withAgentService(withTheme(AgentCommunicationPreferences))
	)
);
