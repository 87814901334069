/**
 * @typedef {{close: boolean}} State
 * @typedef {import('./ActionMessageProps').default} Props
 */

import { BaseSVG } from '../BaseSVG';
import { translate } from '../../App/Internationalization';
import BaseButton from '../BaseButton/BaseButton';
import images from '../../config/local/images';
import React from 'react';

const actions = {
	error: { className: 'message-error', icon: images.icons.delete },
	success: { className: 'message-success', icon: images.icons.submit },
	theme: { className: 'message-themed', icon: images.icons.info },
	warning: { className: 'message-warning', icon: images.icons.alert },
};

/** @extends {React.Component<Props, State>} */
export default class ActionMessage extends React.Component {
	static defaultProps = {
		blockScreen: true,
	};

	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			close: false,
		};

		this.ref = React.createRef();

		this._handleClose = this._handleClose.bind(this);
		this._handleCloseByEsc = this._handleCloseByEsc.bind(this);
	}

	/** @returns {void} */
	componentDidMount() {
		this.#getMountMethods();
	}

	/** @returns {void} */
	componentWillUnmount() {
		this.#getUnMountMethods();
	}

	/**
	 * @param {Props} _prevProps
	 * @param {State} prevState
	 * @returns {void}
	 */
	componentDidUpdate(_prevProps, prevState) {
		if (this.state.close && prevState.close) {
			this.setState({
				close: false,
			});

			this.#getMountMethods();
		}
	}

	/** @returns {void} */
	#getMountMethods() {
		this.ref.current?.querySelectorAll('button, a')[0].focus();
		this.#togglePageOverflow('hidden');
		document.addEventListener('keydown', this._handleCloseByEsc);
	}

	/** @returns {void} */
	#getUnMountMethods() {
		this.#togglePageOverflow('scroll');
		document.removeEventListener('keydown', this._handleCloseByEsc);
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleClose() {
		this.setState({
			close: true,
		});

		this.#getUnMountMethods();
	}

	/**
	 * @protected
	 * @param {{key: string}} event
	 * @returns {((event: {key: string}) => void) | null}
	 */
	_handleCloseByEsc(event) {
		if (event.key === 'Escape') {
			if (this.props.onClose) {
				return this.props.onClose(event);
			}

			this._handleClose();
		}

		return null;
	}

	/**
	 * @param {'hidden' | 'scroll'} overflow
	 * @returns {void}
	 */
	#togglePageOverflow(overflow) {
		if (!this.props.blockScreen) {
			return;
		}

		const body = document.querySelector('body');
		if (body) {
			body.style.overflow = overflow;
		}
	}

	/** @returns {string} */
	#getIcon() {
		if (this.props.icon) {
			return this.props.icon;
		}

		return this.props.action
			? actions[this.props.action].icon
			: images.icons.info;
	}

	/** @returns {JSX.Element | null} */
	#getChildren() {
		return this.props.children ? (
			<div className="my-6 rich-text container-sm text-center">
				{this.props.children}
			</div>
		) : null;
	}

	/** @returns {JSX.Element | null} */
	#renderFullScreenBlock() {
		return this.props.blockScreen ? (
			<div
				aria-hidden="true"
				className="absolute top-0 left-0 z-10 h-full w-full bg-black bg-opacity-20"
			/>
		) : null;
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	// eslint-disable-next-line complexity
	render() {
		if (!this.props.title || this.state.close) {
			return null;
		}

		const classNames = `message centered my-5 ${
			this.props.action && actions[this.props.action].className
		} ${this.props.className}`;

		return (
			<React.Fragment>
				{this.#renderFullScreenBlock()}
				<div
					aria-labelledby="modal_label"
					aria-modal={this.props.blockScreen ? 'true' : 'false'}
					className="message-popover"
					ref={this.ref}
					role="dialog"
				>
					<section className={classNames}>
						<header className="header">
							<i className="icon icon-lg">
								<BaseSVG path={this.#getIcon()} />
							</i>
							<h3
								className={`${this.props.titleClass} title`}
								id="modal_label"
							>
								{this.props.title}
							</h3>
						</header>
						{this.#getChildren()}
						<footer className="footer">
							<div className="flex flex-row">
								{!!this.props?.onClose && (
									<BaseButton
										className="button button-icon"
										icon={images.icons.delete}
										iconClass="icon icon-xs"
										onClick={
											this.props.onClose ??
											this._handleClose
										}
									>
										{translate(
											'global.forms.buttons.close'
										)}
									</BaseButton>
								)}
								{!!this.props.onSubmit && (
									<BaseButton
										className="ml-2"
										icon={this.props.submitIcon}
										onClick={this.props.onSubmit}
									>
										{this.props.submitTitle ??
											translate(
												'global.forms.buttons.confirm'
											)}
									</BaseButton>
								)}
							</div>
						</footer>
					</section>
				</div>
			</React.Fragment>
		);
	}
}
