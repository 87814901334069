/** @typedef {import('shared/BlogService/BlogPost').default} BlogPost */
/** @typedef {import('shared/BlogService/BlogPostExcerpt').default} BlogPostExcerpt */
/** @typedef {import('shared/BlogService/GetsBlogPost').default} GetsBlogPost */
/** @typedef {import('shared/BlogService/GetsBlogPostExcerpts').default} GetsBlogPostExcerpts */
/** @typedef {import('shared/BlogService/GetsLatestBlogPostExcerpt').default} GetsLatestBlogPostExcerpt */
/** @typedef {import('shared/BlogServiceActivityProxy/Dependencies').default} Dependencies  */

/**
 * @implements {GetsBlogPost}
 * @implements {GetsBlogPostExcerpts}
 * @implements {GetsLatestBlogPostExcerpt}
 */
export default class BlogServiceActivityProxy {
	/** @param {Dependencies} dependencies */
	constructor(dependencies) {
		this.activityService = dependencies.activityService;
		this.blogService = dependencies.blogService;
	}

	/** @returns {Promise<?BlogPostExcerpt>} */
	async getLatestPostExcerpt() {
		return this.blogService.getLatestPostExcerpt();
	}

	/**
	 * @param {string} slug
	 * @returns {Promise<?BlogPost>}
	 */
	async getPost(slug) {
		return this.blogService.getPost(slug);
	}

	/**
	 * @param {string} searchQuery
	 * @param {number} page
	 * @param {number} [perPage]
	 * @returns {Promise<{posts: readonly BlogPostExcerpt[], totalPages: number}>}
	 */
	async getPostExcerpts(searchQuery, page, perPage) {
		if (this.activityService.identifiesKeywords(searchQuery)) {
			this.activityService.logActivity(
				searchQuery,
				'blog_search_keywords_highpoint'
			);
		}

		return this.blogService.getPostExcerpts(searchQuery, page, perPage);
	}
}
