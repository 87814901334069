import React from 'react';
import GenericContactsLeadScores from './GenericContactsLeadScores';
import GenericContactsOverlay from './GenericContactsOverlay';
import './GenericContactsTable';

class GenericContactsTable extends React.Component {
	render() {
		return (
			<section className="relative mb-20">
				<GenericContactsLeadScores />
				<div className="message-overlay">
					<GenericContactsOverlay />
				</div>
			</section>
		);
	}
}

export default GenericContactsTable;
