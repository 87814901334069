import React from 'react';
import ShareableLink from './ShareableLink';

export default class ShareableLinks extends React.Component {
	/**
	 * @param {{ label: string, url: string}} link
	 * @param {number} id
	 * @returns {JSX.Element}
	 */
	static #links(link, id) {
		return <ShareableLink key={id} label={link.label} url={link.url} />;
	}

	render() {
		return (
			<React.Fragment>
				<h2 className="h-ms-5 mb-6">{this.props.title}</h2>

				<section className="listing listing-col-size-2">
					{this.props.links.map(ShareableLinks.#links)}
				</section>
			</React.Fragment>
		);
	}
}
