import React from 'react';
import { Redirect, Route } from 'react-router';

import EditAgentTheme from 'App/Admin/Users/Agents/EditAgentTheme';
import ImportAgents from 'App/Admin/shared/ImportAgents';
import { translate } from 'App/Internationalization';
import ImportContacts from 'App/shared/ImportContacts';
import paths from 'config/local/paths';
import { withAdministratorService, withAgentService } from 'service-container';
import Page from 'shared/Page';
import Switch from 'shared/Switch';
import CreateAgent from './CreateAgent';
import EditAgent from './EditAgent';
import ViewAgent from './ViewAgent';
import concatPaths from 'utils/contactPaths';

const InjectedViewAgent = withAdministratorService(withAgentService(ViewAgent));
const InjectedEditAgent = withAgentService(EditAgent);
const InjectedCreateAgent = withAgentService(CreateAgent);

export default class Agents extends React.Component {
	render() {
		return (
			<Switch>
				<Redirect
					exact
					from={paths.app.admin.agents.root}
					to={paths.app.admin.agents.view}
				/>
				<Page
					component={InjectedViewAgent}
					path={paths.app.admin.agents.view}
					title={translate('admin.nav.agent.view')}
				/>
				<Page
					component={InjectedEditAgent}
					path={`${paths.app.admin.agents.edit}/:agentSlug`}
					title={translate('admin.nav.agent.edit')}
				/>
				<Page
					component={InjectedCreateAgent}
					path={paths.app.admin.agents.create}
					title={translate('admin.nav.agent.create')}
				/>
				<Page
					component={ImportAgents}
					path={paths.app.admin.agents.import}
					title={translate('admin.nav.agent.import')}
				/>
				<Page
					component={ImportContacts}
					exact
					path={concatPaths(
						paths.app.admin.agents.root,
						'/:agentId/contacts/import'
					)}
					title={translate('admin.nav.contacts.import')}
				/>
				<Route
					component={EditAgentTheme}
					path={concatPaths(
						paths.app.admin.agents.root,
						'/:agentId/theme'
					)}
				/>
			</Switch>
		);
	}
}
