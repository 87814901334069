/** @typedef {import('Registration/RegistrationIndex/Props').default} Props */

import { translate } from '../../App/Internationalization';
import { withTheme } from '../../shared/ThemeProvider';
import AccountTypeCard from './AccountTypeCard';
import concatPaths from '../../utils/contactPaths';
import images from '../../config/local/images';
import paths from '../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props>} */
export class RegistrationIndex extends React.Component {
	render() {
		const path = this.props.match.url.replace(/\/$/, '');

		return (
			<div className="app-container">
				<header>
					<h1 className="hl-ms-4 mt-2 mb-3">
						{translate('registration.header')}
					</h1>
				</header>

				<section className="my-10">
					<div className="h-ms-4">
						{translate('registration.title')}
					</div>

					<div className="mt-4">{translate('registration.lead')}</div>
				</section>

				<AccountTypeCard
					descriptionText={translate(
						'registration.types.homeowner.prompt',
						this.props.theme.appTitle
					)}
					iconRef={images.registration.homeowner}
					link={concatPaths(path, paths.register.homeownerSegment)}
					titleText={translate('registration.types.homeowner.title')}
				/>
				<AccountTypeCard
					descriptionText={translate(
						'registration.types.agent.prompt',
						this.props.theme.appTitle
					)}
					iconRef={images.registration.agent}
					// TODO: feature flag 'agent:pricing_while_registration' here will be updated with paths.register.agentPricing after feature tag removed
					link={concatPaths(path, paths.register.agentSegment)}
					titleText={translate('registration.types.agent.title')}
				/>
				<AccountTypeCard
					descriptionText={translate(
						'registration.types.group.prompt',
						this.props.theme.appTitle
					)}
					iconRef={images.registration.manager}
					link={concatPaths(path, paths.register.managerSegment)}
					titleText={translate('registration.types.group.title')}
				/>
			</div>
		);
	}
}

export default withTheme(RegistrationIndex);
