/**
 * @typedef {import('../../App/Admin/Users/Admins/GroupList').default} Group
 * @typedef {import('../../App/shared/SubscriptionService/Subscription').default} Subscription
 * @typedef {import('../../Auth/AuthService').default} AuthService
 * @typedef {import('../../Auth/AuthService/AccessTokenPayload').default} AccessTokenPayload
 * @typedef {import('../../shared/AuthProvider/Props').default} Props
 * @typedef {import('./Roles').Roles} Roles
 * @typedef {import('./State').default} State
 */

/* eslint-disable-next-line max-classes-per-file -- created multiple in same file, need to update if required*/
import {
	withAdministratorService,
	withAgentService,
	withAuthService,
	withSubscriptionService,
	withUserService,
} from '../../service-container';
import { logError } from '../../utils/captureError';
import LoadingScreen from '../LoadingScreen';
import React from 'react';

export const AuthContext = React.createContext({});
const userIdStorageKey = 'userId';

/** @extends {React.Component<Props, State>} */
export class AuthProvider extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		/* eslint-disable react/no-unused-state -- state is being used passed down as context */
		this.state = {
			accessToken: null,
			agentSubscriptionLevels: [],
			getAgentSubscription: this.getAgentSubscription.bind(this),
			getGroupSubscription: this.getGroupSubscription.bind(this),
			isAttemptingColdSignInRefresh: true,
			isContactLimitExceeded: false,
			isSubscribed: false,
			justSignedOut: false,
			managerIsSubscribed: false,
			module: null,
			resetPassword: this.resetPassword.bind(this),
			resolveSubscription: this.resolveSubscription.bind(this),
			roles: [],
			setModule: this.setModule.bind(this),
			signIn: this.signIn.bind(this),
			signInWithSAML: this.signInWithSAML.bind(this),
			signInWithSso: this.signInWithSso.bind(this),
			signOut: this.signOut.bind(this),
			signUp: this.signUp.bind(this),
			userId: null,
		};
		/* eslint-enable react/no-unused-state */

		this.getAgentSubscription = this.getAgentSubscription.bind(this);
	}

	async componentDidMount() {
		const userId = localStorage.getItem(userIdStorageKey);
		this.setState({ userId });

		if (userId !== null) {
			await this.refreshSignIn(userId);
		}

		this.setState({ isAttemptingColdSignInRefresh: false });
	}

	/**
	 * @param {Props} prevProps
	 * @param {State} prevState
	 */
	async componentDidUpdate(prevProps, prevState) {
		const userId = localStorage.getItem(userIdStorageKey);

		if (userId !== prevState.userId) {
			this.setState({ userId });
			await this.#setUserSubscription();
		}

		if (this.state.roles !== prevState.roles) {
			await this.#setUserSubscription();
		}
	}

	/**
	 * @param {Subscription} subscription
	 * @returns {boolean}
	 */
	static #isSubscribed(subscription) {
		if (subscription.expirationDate.valueOf() < Date.now()) {
			return false;
		}

		return subscription.isActive;
	}

	/**
	 * @returns {Promise<void>}
	 */
	async getAgentSubscription() {
		let subscriptionData;

		try {
			subscriptionData =
				await this.props.subscriptionService.getAgentSubscription();
		} catch (error) {
			logError(error);
		}

		const agentSubscriptionLevels =
			subscriptionData?.subscriptionLevels || [];

		// @ts-ignore
		if (subscriptionData?.isActive === undefined) {
			this.setState({
				agentSubscriptionLevels,
			});
			return;
		}

		this.setState({
			agentSubscriptionLevels,
			/* eslint-disable-next-line react/no-unused-state -- state is being used passed down as context */
			isContactLimitExceeded:
				// @ts-ignore checked above
				subscriptionData?.isContactLimitExceeded ?? false,
			/* eslint-disable-next-line react/no-unused-state -- state is being used passed down as context */
			isSubscribed: subscriptionData
				? // @ts-ignore checked above
				  AuthProvider.#isSubscribed(subscriptionData)
				: false,
		});
	}

	/**
	 * @param {boolean} [hasSubscription]
	 * @returns {boolean}
	 */
	resolveSubscription(hasSubscription = false) {
		return (
			hasSubscription ||
			this.state.agentSubscriptionLevels?.includes('agent')
		);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #setManagerSubscription() {
		const administrator =
			await this.props.administratorService.getAdministratorPersonalData();

		let managerIsSubscribed = false;

		for (const group of administrator.groups) {
			if (group.hasSubscription) {
				this.setState({
					managerIsSubscribed: true,
				});
				return;
			}
		}

		this.setState({
			managerIsSubscribed,
		});
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #setUserSubscription() {
		if (this.state.roles.includes('manager')) {
			await this.#setManagerSubscription();
		}

		if (this.state.roles.includes('agent')) {
			await this.getAgentSubscription();
		}
	}

	/**
	 * @param {string} groupId
	 * @returns {Promise<boolean>}
	 */
	async getGroupSubscription(groupId) {
		let subscription;

		try {
			subscription =
				await this.props.subscriptionService.getGroupSubscription(
					groupId
				);
		} catch (error) {
			logError(error);
		}

		if (!subscription) {
			return false;
		}

		if (subscription.isActive === false) {
			return false;
		}

		return true;
	}

	componentWillUnmount() {
		this.unscheduleSignInRefresh();
	}

	/**
	 * @param {string} userId
	 * @returns {Promise<void>}
	 */
	async refreshSignIn(userId) {
		try {
			const result = await this.props.authService.refreshSignIn(userId);

			this.scheduleSignInRefresh(userId, result.accessToken.expiration);
			this.setState({
				accessToken: result.accessToken.value,
				roles: result.roles,
			});
		} catch (error) {
			localStorage.removeItem(userIdStorageKey);

			this.setState({
				accessToken: null,
				justSignedOut: false,
				roles: [],
			});
		}
	}

	/**
	 * @param {string} email
	 * @returns {Promise<void>}
	 */
	async resetPassword(email) {
		await this.props.authService.resetPassword(email);
	}

	/**
	 * @param {string} module
	 * @returns {void}
	 */
	setModule(module) {
		this.setState({
			module: module,
		});
	}

	/**
	 * @param {string} email
	 * @param {string} password
	 * @returns {Promise<void>}
	 */
	async signIn(email, password) {
		const result = await this.props.authService.signIn(email, password);
		this.handleSignInResult(result);
	}

	/**
	 * @param {string} token
	 * @returns {Promise<void>}
	 */
	async signInWithSso(token) {
		const result = await this.props.authService.signInWithSso(token);
		this.handleSignInResult(result);
	}

	/**
	 * @param {string} token
	 * @param {string} [issuer]
	 * @returns {void}
	 */
	signInWithSAML(token, issuer = 'SAML') {
		const result = this.props.authService.signInWithSAML(token, issuer);
		this.handleSignInResult(result);
	}

	/**
	 * @param {any} result
	 * @returns {void}
	 */
	handleSignInResult(result) {
		localStorage.setItem(userIdStorageKey, result.userId);

		this.scheduleSignInRefresh(
			result.userId,
			result.accessToken.expiration
		);

		this.setState({
			accessToken: result.accessToken.value,
			justSignedOut: false,
			roles: result.roles,
		});
	}

	/**
	 * @returns {void}
	 */
	signOut() {
		this.unscheduleSignInRefresh();
		localStorage.removeItem(userIdStorageKey);
		this.props.authService.signOut();

		this.setState({
			accessToken: null,
			justSignedOut: true,
			roles: [],
		});
	}

	/**
	 * @param {string} email
	 * @param {string} password
	 * @returns {Promise<void>}
	 */
	async signUp(email, password) {
		const result = await this.props.authService.signUp(email, password);
		localStorage.setItem(userIdStorageKey, result.userId);

		this.scheduleSignInRefresh(
			result.userId,
			result.accessToken.expiration
		);

		this.setState({
			accessToken: result.accessToken.value,
			roles: result.roles,
		});
	}

	/**
	 * @param {string} userId
	 * @param {number} accessTokenExpiration
	 * @returns {void}
	 */
	scheduleSignInRefresh(userId, accessTokenExpiration) {
		/**
		 * @type {NodeJS.Timeout | any}
		 */
		this.refreshSignInTimeoutId = setTimeout(
			() => this.refreshSignIn(userId),
			accessTokenExpiration - Date.now()
		);
	}

	/**
	 * @returns {void}
	 */
	unscheduleSignInRefresh() {
		clearTimeout(this.refreshSignInTimeoutId);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		let content = this.props.children;

		if (this.state.isAttemptingColdSignInRefresh) {
			content = <LoadingScreen />;
		}
		const state = { ...this.state };
		return (
			<AuthContext.Provider value={state}>{content}</AuthContext.Provider>
		);
	}
}

export default withAdministratorService(
	withAgentService(
		// @ts-ignore will be fixed with HoCs
		withAuthService(withSubscriptionService(withUserService(AuthProvider)))
	)
);

/**
 * @template {{[key: string]: any, authService: Partial<AuthService>}} Props
 * @param {React.ComponentType<Props>} Component
 * @returns {React.ComponentType<Props>}
 */

export function withAuth(Component) {
	/* eslint-disable-next-line react/no-multi-comp -- it can be ignored it's created in same file */
	class AuthenticatedComponent extends React.Component {
		render() {
			/* eslint-disable react/jsx-props-no-spreading -- passed props*/
			// @ts-ignore
			return <Component auth={this.context} {...this.props} />;
			/* eslint-enable react/jsx-props-no-spreading -- passed props*/
		}
	}

	AuthenticatedComponent.contextType = AuthContext;

	return AuthenticatedComponent;
}
