import BaseInput from './BaseInput';
import React from 'react';

export default class InputFile extends React.Component {
	render() {
		const accepts = Array.from(new Set(this.props.accept));

		return (
			<BaseInput
				accept={accepts.join(',')}
				className={this.props.className}
				disabled={this.props.disabled}
				id={this.props.id}
				name={this.props.name}
				onChange={this.props.onChange}
				type="file"
			/>
		);
	}
}
