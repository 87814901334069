/**
 *  @typedef {import('./Homeowner').Homeowner} Homeowner
 *  @typedef {import('./Homeowner').HomeownerData} HomeownerData
 *  @typedef {import('./Homeowner').Props} Props
 *  @typedef {import('./Homeowner').State} State
 */

import { AuthContext } from '../../../../shared/AuthProvider';
import { translate } from '../../../Internationalization';
import BaseTable from '../../../../shared/BaseTable';
import captureError from '../../../../utils/captureError';
import FormErrorMessages from '../../../../shared/Forms/Messages/FormErrorMessages';
import images from '../../../../config/local/images';
import InlineSVG from 'react-inlinesvg/esm';
import Loading from '../../../../shared/Loading';
import Pagination from '../../../../shared/Pagination';
import paths from '../../../../config/local/paths';
import React from 'react';
import TableButton from '../../../../shared/TableButton';

/** @extends {React.Component<Props, State>} */
export default class ViewHomeowners extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @param {State} State */
		this.state = {
			bodyData: [],
			currentPageNumber: 1,
			isGettingData: true,
			lastPageNumber: 1,
			message: '',
			totalRecord: 0,
		};

		this.headerData = [
			{ title: 'Name' },
			{ title: 'Email' },
			{ title: 'Phone' },
			{ title: 'Agent' },
			{ title: 'Edit' },
		];

		this._setTableData = this._setTableData.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			await this._setTableData();
		} catch (error) {
			captureError(error);
			this.setState({
				isGettingData: false,
				message:
					error instanceof Error
						? error.message
						: translate('global.error'),
			});
		}
	}

	/**
	 * @returns {React.ReactElement}
	 */
	render() {
		if (this.state.message) {
			return <FormErrorMessages messages={[this.state.message]} />;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		<FormErrorMessages messages={[this.state.message]} />;

		return (
			<React.Fragment>
				<header className="mt-10 mb-6 md:flex justify-between items-center">
					<h1 className="hl-ms-6 mb-6 md:mb-0">
						{`${translate('admin.pages.homeowners.view.title')} (${
							this.state.totalRecord
						})`}
					</h1>
				</header>

				<section className="mb-6 rich-text form-alert form-alert-grey">
					<p className="title">
						<i className="icon icon-xs">
							<InlineSVG src={images.icons.info} />
						</i>

						{translate('admin.pages.homeowners.view.subtext')}
					</p>
				</section>

				<BaseTable
					bodyData={this.state.bodyData}
					errorMessage={translate(
						'admin.pages.homeowners.view.error'
					)}
					headerData={this.headerData}
				/>
				<Pagination
					currentPageNumber={this.state.currentPageNumber}
					lastPageNumber={this.state.lastPageNumber}
					setCurrentPageNumber={this._setTableData}
				/>
			</React.Fragment>
		);
	}

	/**
	 * @protected
	 * @param {number} pageNumber
	 * @returns {Promise<void>}
	 */
	async _setTableData(pageNumber = 1) {
		const homeownerData = await this.props.homeownerService.getHomeowners(
			pageNumber
		);
		this.setState({
			totalRecord: homeownerData.total,
		});
		const homeownerList = homeownerData.data;
		/* eslint-disable sort-keys -- Properties in order of table headers */
		const dataMap = homeownerList.map(
			/**
			 * @param {Homeowner} homeowner
			 * @returns {HomeownerData}
			 */
			(homeowner) => ({
				name: `${homeowner?.name?.first} ${homeowner?.name?.last}`,
				email: homeowner?.email,
				phone:
					ViewHomeowners.getPhoneNumber(homeowner, 'mobile') ||
					ViewHomeowners.getPhoneNumber(homeowner, 'landline') ||
					ViewHomeowners.getPhoneNumber(homeowner, 'home'),
				agent:
					homeowner?.agent?.name?.first ||
					homeowner?.agent?.name?.last
						? `${homeowner?.agent?.name?.first} ${homeowner?.agent?.name?.last}`
						: '—',
			})
		);
		/* eslint-enable sort-keys */

		const bodyData = dataMap.map(
			/**
			 * @param {HomeownerData} homeowner
			 * @returns {{data: string | JSX.Element | null}[]}
			 */
			(homeowner) => Object.values(homeowner).map((data) => ({ data }))
		);

		ViewHomeowners.addEdit(homeownerList, bodyData);

		this.setState({
			bodyData,
			currentPageNumber: pageNumber,
			isGettingData: false,
			lastPageNumber: homeownerData.pages,
		});
	}

	/**
	 * @param {Homeowner} homeowner
	 * @param {string} type
	 * @returns {?string}
	 */
	static getPhoneNumber(homeowner, type) {
		if (!homeowner.phone && !homeowner.phoneNumbers?.length) {
			return null;
		}

		if (!homeowner.phoneNumbers?.length) {
			// @ts-ignore declared
			return homeowner?.phone?.[type];
		}

		const phoneNumber = homeowner.phoneNumbers.find(
			/**
			 * @param {{type:string}} number
			 * @returns {boolean}
			 */
			(number) => number.type === type
		);

		// @ts-ignore declared
		return phoneNumber?.value ?? homeowner?.phone?.[type] ?? null;
	}

	/**
	 * @param {[{id: string}]} data
	 * @param {[{}][]} bodyData
	 */
	static addEdit(data, bodyData) {
		data?.forEach((homeowner, index) => {
			bodyData?.[index].push({
				data: (
					<TableButton
						icon={images.icons.edit}
						to={`${paths.app.admin.homeowners.edit}/${homeowner.id}`}
					/>
				),
			});
		});
	}
}

ViewHomeowners.contextType = AuthContext;
