/**
 * @typedef {import('../../../config/local/NavigationItem').default} NavigationItem
 * @typedef {import('../../../shared/AuthProvider/Roles').Roles} Roles
 * @typedef {import('./Props').Props} Props
 */

import { AuthContext } from '../../../shared/AuthProvider';
import { toAbsoluteUrl } from '../../../utils/toAbsoluteUrl';
import { translate } from '../../../App/Internationalization';
import images from '../../../config/local/images';
import InlineSVG from 'react-inlinesvg';
import navigation, { getNavLinkList } from '../../../config/local/navigation';
import NavigationButton from './NavigationButton';
import React from 'react';

/** @extends {React.Component<Props>} */
export class MobileNavBar extends React.Component {
	/**
	 * @param {string} key
	 * @param {NavigationItem[]} linkList
	 * @param {boolean} isCheckContactLimit
	 * @param {string[]} mobileDisableList
	 * @returns {React.ReactNode}
	 */
	static getNavigationButtons(
		key,
		linkList,
		isCheckContactLimit,
		mobileDisableList
	) {
		return linkList.map(
			(menu) =>
				menu !== null &&
				key === menu.key && (
					<li key={menu.key}>
						{/* @ts-ignore -- issue in props  */}
						<NavigationButton
							icon={menu.icon}
							isCheckContactLimit={isCheckContactLimit}
							key={`nav-item-${menu.key}`}
							menuKey={menu.key}
							menuText={menu.label}
							mobileDisableList={mobileDisableList}
							to={menu.to}
						/>
					</li>
				)
		);
	}

	/** @returns {JSX.Element} */
	#renderHamburgerButton() {
		return (
			<li key="hamburger-menu-button">
				{/* For the hamburger menu - this stays the same for all roles */}
				<button
					aria-controls="side-menu-options"
					aria-expanded={this.props.isOpen ? 'true' : 'false'}
					aria-label={translate('aria_labels.hamburger_button')}
					className="nav-link"
					id="side-menu-button"
					onClick={this.props.onClick}
					type="button"
				>
					<i className="icon">
						<InlineSVG
							src={toAbsoluteUrl(images.icons.navigation.open)}
						/>
					</i>

					<span className="nav-text">{translate('global.menu')}</span>
				</button>
			</li>
		);
	}

	/**  @returns {JSX.Element} */
	render() {
		/** @type {Roles} */
		const role = this.context.module;
		const isCheckContactLimit = role === 'agent' ?? false;
		const linkList = getNavLinkList(role, this.context.isSubscribed);

		return (
			<div className="app-navigation-fixed">
				<nav>
					<ul className="app-navigation-bar">
						{role
							? navigation[role].mobileList.map(
									(/** @type {string} */ key) =>
										MobileNavBar.getNavigationButtons(
											key,
											linkList,
											isCheckContactLimit,
											// @ts-ignore added in types
											navigation[role]?.mobileDisableList
										)
							  )
							: null}
						{this.#renderHamburgerButton()}
					</ul>
				</nav>
			</div>
		);
	}
}

MobileNavBar.contextType = AuthContext;

export default MobileNavBar;
