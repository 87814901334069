/** @typedef {import('../RegistrationIndex/Props').default} Props */

import { BaseSVG } from '../../shared/BaseSVG';
import { Plan } from '../../utils/plans';
import { translate } from '../../App/Internationalization';
import { withTheme } from '../../shared/ThemeProvider';
import AccountTypeCard from '../RegistrationIndex/AccountTypeCard';
import images from '../../config/local/images';
import LinkButton from '../../shared/LinkButton';
import paths from '../../config/local/paths';
import PricingCard from '../RegistrationIndex/PricingCard';
import React from 'react';

/** @extends {React.Component<Props>} */
export class AgentPlans extends React.Component {
	/**
	 * @param {'free' | 'monthly'} type
	 * @returns {JSX.Element | null}
	 */
	static getPlanDetails(type) {
		if (!type) {
			return null;
		}
		return (
			<ul className="list-group mt-3">
				{Plan[type]?.map(
					/**
					 * @param {{text: string, path: string}} plan
					 * @returns {JSX.Element}
					 */
					(plan) => (
						<li className="flex leading-4" key={plan?.path}>
							<BaseSVG
								className="icon icon-fill-success mr-1"
								path={plan?.path}
							/>
							<p
								className="flex"
								// eslint-disable-next-line react/no-danger --  need to add some bold text in message
								dangerouslySetInnerHTML={{
									__html: plan.text,
								}}
							/>
						</li>
					)
				)}
			</ul>
		);
	}

	/**
	 * @param {string} plan
	 * @returns {JSX.Element}
	 */
	static getButton(plan) {
		const contact = process.env.REACT_APP_REACT_APP_CONTACT_CAP ?? '100';
		const isFreePlan = plan === 'free';
		return (
			<footer className="mt-3 mx-4">
				<LinkButton
					icon={images.icons.arrow.right}
					iconClass={`icon md icon-fill${!isFreePlan ? '-fg' : ''}`}
					isIconSpaceBetween={true}
					outline={isFreePlan}
					to={`${paths.register.agent}?plan=${plan}`}
				>
					<span>
						{translate(
							`registration.plan.agent.${
								isFreePlan ? 'free.try_now' : 'monthly.buy_now'
							}`
						)}
					</span>
				</LinkButton>
				{!isFreePlan && (
					<span className="text-center">
						<small>
							{translate(
								'registration.plan.agent.lead',
								'$0.15',
								contact
							)}
						</small>
					</span>
				)}
			</footer>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const freePlan = { text: 'free', value: 0 };
		const monthlyPlan = { text: 'month', value: 4995 };
		return (
			<section className="app-container">
				<header>
					<h1 className="hl-ms-4 mt-2 mb-3">
						{translate('registration.header')}
					</h1>
				</header>
				<AccountTypeCard
					descriptionText={translate(
						'registration.user_type_prompt_1',
						this.props.theme.appTitle
					)}
					iconRef={images.registration.agent}
					titleText={translate('global.agent')}
				/>
				<section className="my-10 text-center">
					<span className="mt-4 h-ms-3">
						{translate('registration.agent_lead')}
					</span>
				</section>

				<section className="flex flex-wrap justify-center">
					<PricingCard
						footer={AgentPlans.getButton('free')}
						plan={AgentPlans.getPlanDetails('free')}
						planTitle={translate(
							'registration.plan.agent.free.titleText'
						)}
						price={freePlan}
					/>
					<PricingCard
						footer={AgentPlans.getButton('monthly')}
						plan={AgentPlans.getPlanDetails('monthly')}
						planTitle={translate(
							'registration.plan.agent.monthly.titleText'
						)}
						price={monthlyPlan}
					/>
				</section>
			</section>
		);
	}
}

export default withTheme(AgentPlans);
