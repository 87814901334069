/**
 * @typedef {import ('@mooveguru/js-utilities').types.Hash} Hash
 */

/**
 * Interact with the Google Tag Manager data layer
 * @see {@link https://developers.google.com/tag-platform/tag-manager/web/datalayer}
 */
class DataLayer {
	/**
	 * @returns {any[]}
	 */
	/* eslint-disable-next-line class-methods-use-this */
	get #dataLayer() {
		// @ts-ignore, added in index.html file
		return window.dataLayer ?? [];
	}

	/**
	 * @param {Hash} variableList
	 * @returns {this}
	 */
	add(variableList) {
		this.#dataLayer.push(variableList);

		return this;
	}

	/**
	 * @param {string} value
	 * @returns {this}
	 */
	event(value) {
		return this.add({ event: value });
	}

	/**
	 * @param {() => void} callable
	 * @returns {this}
	 */
	execute(callable) {
		this.#dataLayer.push(callable);

		return this;
	}

	/**
	 * @param {string} key
	 * @param {any} value
	 * @returns {this}
	 */
	set(key, value) {
		return this.add({ [key]: value });
	}
}

export default new DataLayer();
