import AlternateAppHeader from 'App/shared/AlternateAppHeader';
import React from 'react';
/** @typedef {import('./Props').default} Props */

/** @extends {React.Component<Props>} */
export default class PublicLayout extends React.Component {
	static defaultProps = {
		withoutHeader: false,
	};

	render() {
		return (
			<section className="app-layout app-layout-single-column">
				<section className="app-content">
					{this.props.withoutHeader || <AlternateAppHeader />}
					<main className="app-body">{this.props.children}</main>
					{/* <AppFooter /> */}
				</section>
			</section>
		);
	}
}

/**
 * @template {object} ComponentProps
 * @param {React.ComponentType<ComponentProps>} Component
 * @returns {typeof ComponentWithPublicLayout}
 */
export function withPublicLayout(Component) {
	/** @extends {React.Component<ComponentProps & Props>} */
	class ComponentWithPublicLayout extends React.Component {
		render() {
			const { withoutHeader, ...restProps } = this.props;

			return (
				<PublicLayout withoutHeader={withoutHeader}>
					{
						// This unusual syntax is necessary for typecasting with JSDoc.
						// prettier-ignore
					}
					<Component {...(/** @type {ComponentProps} */ (restProps))} />
				</PublicLayout>
			);
		}
	}

	return ComponentWithPublicLayout;
}
