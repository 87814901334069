import { translate } from 'App/Internationalization';
import FormField from 'shared/Forms/Fields/FormField';
import InputState from 'shared/Forms/Inputs/Address/InputState';
import React from 'react';

export default class FieldState extends React.Component {
	render() {
		const label = translate('global.forms.inputs.address.state.label');

		return (
			<FormField
				className={this.props.className}
				inputName={this.props.name}
				labelClass={this.props.labelClass}
				label={this.props.label ?? label}
				name={this.props.name}
				required={this.props.required}
			>
				<InputState
					className={this.props.inputClass}
					disabled={this.props.disabled}
					name={this.props.name}
					required={this.props.required}
				/>
			</FormField>
		);
	}
}

FieldState.defaultProps = {
	className: InputState.defaultProps.className,
	disabled: InputState.defaultProps.disabled,
	inputClass: InputState.defaultProps.className,
	labelClass: FormField.defaultProps.labelClass,
	name: InputState.defaultProps.name,
	required: InputState.defaultProps.required,
};
