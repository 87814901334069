import utilities from '@mooveguru/js-utilities';

/**
 * @deprecated
 * @param {number} number
 * @param {(string|null)=}locale
 * @returns {string}
 */
export default function convertNumberToFormattedString(number, locale = null) {
	return utilities.number.convertNumberToFormattedString(
		number,
		locale ?? undefined
	);
}
