/**
 * @typedef {import('./DashboardProps').default} Props
 * @typedef {import('./DashboardState').default} State
 */
import { withBreakpoint } from '../../../shared/BreakpointProvider';
import AcceptedContacts from './AcceptedContacts/AcceptedContacts';
import AdminPreview from '../shared/AdminPreview';
import AgentsCard from './Agents/AgentsCard/AgentsCard';
import React from 'react';
import VendorsCard from '../Offers/VendorsCard/VendorsCard';
import WelcomeCard from '../../shared/WelcomeCard/WelcomeCard';

/** @extends {React.Component<Props, State>} */
export class AdminDashboard extends React.Component {
	/* eslint-disable class-methods-use-this -- `render` must be accessible to instance */
	render() {
		const componentList = {
			admins: <AdminPreview key="admin-dashboard-admins" />,
			agents: <AgentsCard key="admin-dashboard-agents" />,
			contacts: <AcceptedContacts key="admin-dashboard-contacts" />,
			vendors: <VendorsCard key="admin-dashboard-vendors" />,
			welcome: <WelcomeCard key="admin-dashboard-welcome" />,
		};

		if (this.props.isBelowBreakpoint) {
			return (
				<React.Fragment>
					{componentList.welcome}
					{componentList.agents}
					{componentList.vendors}
					{componentList.contacts}
					{componentList.admins}
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				{componentList.welcome}
				<section className="app-responsive-area app-responsive-split-grid">
					<section className="responsive-main app-block-list">
						{componentList.agents}
					</section>
					<section className="responsive-secondary app-block-list">
						{componentList.contacts}
						{componentList.vendors}
						{componentList.admins}
					</section>
				</section>
			</React.Fragment>
		);
	}
	/* eslint-enable */
}

export default withBreakpoint(AdminDashboard);
