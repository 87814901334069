/**
 * @typedef {import ('./ViewPropertiesProps').default} Props
 * @typedef {import ('./ViewPropertiesState').default} State
 */

import { AuthContext } from '../../../shared/AuthProvider';
import { toAbsoluteUrl } from '../../../utils/toAbsoluteUrl';
import { translate } from '../../Internationalization';
import { withBreakpoint } from '../../../shared/BreakpointProvider';
import {
	withHomeownerService,
	withPropertyService,
} from '../../../service-container';
import BaseButton from '../../../shared/BaseButton/BaseButton';
import captureError from '../../../utils/captureError';
import formatAddressToString from '../../../utils/formatAddressToString';
import images from '../../../config/local/images';
import LinkButton from '../../../shared/LinkButton';
import Loading from '../../../shared/Loading';
import paths from '../../../config/local/paths';
import PropertyDetailsCards from './PropertyDetailsCards';
import PropertyMap from './PropertyMap';
import React from 'react';
import NoContent from '../../../shared/NoContent';

/** @extends {React.Component<Props, State>} */
export class ViewProperties extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			errorMessage: null,
			isGettingData: true,
			property: null,
			propertyImage: undefined,
		};

		this.getPropertyImage = this.#getPropertyImage.bind(this);
		this.handleCancelPropertyTransfer =
			this.#handleCancelPropertyTransfer.bind(this);
	}

	/** @returns {Promise<void>} */
	async componentDidMount() {
		await this.#getAndSetPropertyList();
	}

	/** @param {Props} prevProps */
	async componentDidUpdate(prevProps) {
		if (
			prevProps.property.selectedProperty?.id !==
			this.props.property.selectedProperty?.id
		) {
			await this.#getAndSetPropertyList();
		}
	}

	/** @returns {Promise<void>} */
	async #getAndSetPropertyList() {
		let primaryProperty;
		let propertyImage;

		try {
			primaryProperty =
				await this.props.homeownerService.getHomeownerPrimaryProperty(
					this.props.property.selectedProperty?.id
				);
		} catch (error) {
			this.catch(error);
		}

		if (!primaryProperty) {
			this.setState({
				errorMessage: translate(
					'homeowner.pages.projects.no_property_found'
				),
				isGettingData: false,
			});

			return;
		}

		try {
			// @ts-ignore method would return early if value is null
			propertyImage = await this.getPropertyImage(primaryProperty?.id);
		} catch (error) {
			this.catch(error);
		}

		this.setState({
			isGettingData: false,
			property: primaryProperty,
			propertyImage,
		});
	}

	/**
	 * @param {string} propertyId
	 * @returns {Promise<string>}
	 */
	async #getPropertyImage(propertyId) {
		const propertyImage = await this.props.propertyService.getDefaultImage(propertyId); // prettier-ignore
		const image = toAbsoluteUrl(propertyImage ?? images.homeowners.properties.streetView); // prettier-ignore

		return image;
	}

	/** @returns {Promise<void>} */
	async #handleCancelPropertyTransfer() {
		try {
			await this.props.propertyService.cancelTransferProperty(
				this.state.property,
				this.context.accessToken
			);

			await this.#getAndSetPropertyList();

			this.props.location.state = {
				message: translate(
					'homeowner.pages.properties.transfer.cancel_message'
				),
			};
		} catch (error) {
			this.catch(error);
		}
	}

	/**
	 * @param {unknown} error
	 * @returns {void}
	 */
	catch(error) {
		captureError(error);

		this.setState({
			errorMessage: translate('global.error'),
			isGettingData: false,
		});
	}

	/**
	 * @returns {JSX.Element}
	 */
	#renderPropertyActions() {
		const transferButton = this.state.property?.initiatedTransfer ? (
			<BaseButton
				icon={images.icons.delete}
				onClick={this.handleCancelPropertyTransfer}
			>
				{translate(
					'homeowner.pages.properties.view.cancel_transfer_button'
				)}
			</BaseButton>
		) : (
			<LinkButton
				icon={images.icons.properties}
				outline={false}
				to={paths.app.homeowner.properties.transfer} // prettier-ignore
			>
				{translate('homeowner.pages.properties.view.transfer_button')}
			</LinkButton>
		);
		return (
			<section
				className="card card-property-detail card-border mb-6"
				key="homeowner-properties-actions"
			>
				<figure>
					<img
						alt={translate('homeowner.pages.properties.alt')}
						className="image rounded w-full"
						src={this.state.propertyImage}
					/>
				</figure>
				<section className="card-body property-details text-start">
					{this.state.property?.title ? (
						<div className="property-detail-item width-full">
							<header className="property-detail-item-header">
								{translate('global.forms.inputs.title.label')}
							</header>
							<footer className="property-detail-item-footer">
								{this.state.property?.title}
							</footer>
						</div>
					) : null}

					<div className="property-detail-item width-full">
						<header className="property-detail-item-header">
							{'Address'}
						</header>
						<footer className="property-detail-item-footer">
							{formatAddressToString(
								// @ts-ignore
								this.state.property?.address
							)}
						</footer>
					</div>
				</section>
				<footer className="card-footer grid grid-cols-1 gap-2">
					<LinkButton
						icon={images.icons.edit}
						outline={false}
						to={`${paths.app.homeowner.properties.edit}/${this.state.property?.id}`}
					>
						{translate('homeowner.components.cta.edit')}
					</LinkButton>
					<LinkButton
						icon={images.icons.add}
						outline={false}
						to={paths.app.homeowner.properties.add}
					>
						{translate('homeowner.components.cta.add')}
					</LinkButton>
					{transferButton}
				</footer>
			</section>
		);
	}

	/** @returns {JSX.Element} */
	render() {
		if (this.state.errorMessage) {
			return <NoContent message={this.state.errorMessage} />;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		const componentMap = {
			actions: this.#renderPropertyActions(),
			details: (
				<PropertyDetailsCards key="homeowner-properties-details" />
			),
			map: (
				<PropertyMap
					address={this.state.property?.address}
					className="property-map mb-6"
					key="homeowner-properties-map"
				/>
			),
			title: (
				<h1
					className="hl-ms-6 mt-10 mb-6"
					key="homeowner-properties-title"
				>
					{translate('homeowner.nav.main.properties')}
				</h1>
			),
		};

		if (this.props.isBelowBreakpoint) {
			return (
				<React.Fragment>
					{componentMap.title}
					{componentMap.actions}
					{componentMap.map}
					{componentMap.details}
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				{componentMap.title}
				<section className="app-responsive-area app-responsive-sidebar-grid">
					<section className="responsive-main app-block-list">
						{componentMap.details}
					</section>

					<section className="responsive-secondary app-block-list">
						{componentMap.actions}
						{componentMap.map}
					</section>
				</section>
			</React.Fragment>
		);
	}
}

// @ts-ignore until service containers are fixed
export default withHomeownerService(
	withPropertyService(withBreakpoint(ViewProperties))
);

ViewProperties.contextType = AuthContext;
