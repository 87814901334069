import { translate } from '../../App/Internationalization';
import ExternalAnchor from '../../shared/ExternalAnchor';
import images from '../../config/local/images';
import React from 'react';

export default class OpenInNewTab extends React.Component {
	render() {
		return (
			<ExternalAnchor
				className="button button-icon button-outline"
				href={this.props.url}
				icon={images.icons.openInNewWindow}
				iconClass="icon icon-fill icon-sm"
				target="_blank"
				title={translate('global.links.open_external')}
			>
				{translate('global.links.open_external_short')}
			</ExternalAnchor>
		);
	}
}
