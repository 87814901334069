/**
 * @typedef {import('./BaseInputProps').default} Props
 * @typedef {import('./BaseInputProps').FormatterProps} FormatterProps
 * @typedef {import('formik').FormikValues} Formik
 */

import { Field } from 'formik';
import React from 'react';

/**
 * @extends {React.Component<Props>}
 */
export default class BaseInput extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		this.state = {
			isMounted: true,
		};

		this.formatValue = this.formatValue.bind(this);
	}

	componentDidMount() {
		this.setState({
			isMounted: false,
		});
	}

	/**
	 * @param {string} value
	 * @param {FormatterProps | Formik} props
	 * @returns {void}
	 */
	formatValue(value, props) {
		if (value && this.props.formatValue && this.state.isMounted) {
			const formattedValue = this.props.formatValue(value);
			props.form.setFieldValue(props.field.name, formattedValue);
		}
	}

	render() {
		return (
			<Field name={this.props.name}>
				{
					// eslint-disable-next-line jsdoc/require-param, jsdoc/check-param-names
					/**
					 * @param {Formik} renderProps
					 * @returns {JSX.Element}
					 */
					// eslint-disable-next-line complexity
					(renderProps) => {
						const value = renderProps.field.value ?? null;

						/**
						 * TODO: This causes the following error:
						 * 		`Warning: Cannot update a component (`Formik`) while rendering a different component (`Field`).
						 * This needs to be moved out of this component and set before values are passed in
						 * as initial values. All components reliant on this method must be updated.
						 */
						this.formatValue(value, renderProps);

						return (
							<input
								accept={this.props.accept}
								autoComplete={this.props.autocomplete}
								className={`input ${this.props.className}`.trim()}
								disabled={this.props.disabled}
								id={this.props.id ?? this.props.name}
								inputMode={this.props.inputmode ?? undefined}
								max={this.props.max}
								min={this.props.min}
								name={renderProps.field.name}
								onBlur={
									this.props.onBlur
										? // @ts-ignore -- Undefined checked in this conditional
										  (event) => this.props.onBlur(event, renderProps.form) // prettier-ignore
										: renderProps.field.onBlur
								}
								onChange={
									this.props.onChange
										? // @ts-ignore -- Undefined checked in this conditional
										  (event) => this.props.onChange(event, renderProps.form) // prettier-ignore
										: renderProps.field.onChange
								}
								pattern={this.props.pattern}
								placeholder={this.props.placeholder}
								required={this.props.required}
								step={this.props.step}
								type={this.props.type}
								value={renderProps.field.value ?? ''}
							/>
						);
					}
				}
			</Field>
		);
	}
}

BaseInput.defaultProps = {
	className: 'input',
	disabled: false,
	required: false,
	type: 'text',
};
