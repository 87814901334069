/**
 * @typedef {import('./InputEmailComponent').Props} Props
 * @typedef {import('./InputEmailComponent').State} State
 */
import { translate } from 'App/Internationalization';
import BaseInput from 'shared/Forms/Inputs/BaseInput';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export default class InputEmail extends React.Component {
	render() {
		return (
			<BaseInput
				autocomplete="email"
				className={this.props.className}
				disabled={this.props.disabled}
				name={this.props.name}
				placeholder={this.props.placeholder}
				required={this.props.required}
				type="email"
			/>
		);
	}
}

InputEmail.defaultProps = {
	className: '',
	disabled: false,
	name: 'email',
	placeholder: translate('global.forms.inputs.email.placeholder'),
	required: false,
};
