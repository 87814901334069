import { AuthContext } from '../../../shared/AuthProvider';
import { translate } from '../../../App/Internationalization';
import * as validators from '../../../shared/validators';
import * as yup from 'yup';
import AddressFields from '../../../shared/Forms/Fields/Address/AddressFields';
import BaseField from '../../../shared/Forms/Fields/BaseField';
import BaseForm from '../../../shared/Forms/BaseForm';
import FieldEmail from '../../../shared/Forms/Fields/FieldEmail';
import FieldLandlinePhone from '../../../shared/Forms/Fields/PhoneNumber/FieldLandlinePhone';
import FieldMobilePhone from '../../../shared/Forms/Fields/PhoneNumber/FieldMobilePhone';
import FormErrorMessages from '../../../shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from '../../../shared/Forms/Messages/FormSuccessMessages';
import images from '../../../config/local/images';
import LinkButton from '../../../shared/LinkButton';
import NameFields from '../../../shared/Forms/Fields/Name/NameFields';
import paths from '../../../config/local/paths';
import React from 'react';
import SubmitButton from '../../../shared/Forms/Inputs/SubmitButton';

const validationSchema = yup.object().shape({
	city: yup.string().nullable(),
	country: yup.string().nullable(),
	email: validators.email,
	firstName: yup.string().nullable(),
	landlinePhoneNumber: yup.string().nullable(),
	lastName: yup.string().nullable(),
	mobilePhoneNumber: yup.string().nullable(),
	postalCode: yup.string().nullable(),
	state: yup.string().nullable(),
	streetAddress1: yup.string().nullable(),
	streetAddress2: yup.string().nullable(),
});

export default class ContactForm extends React.Component {
	static contextType = AuthContext;

	static defaultProps = {
		initialValues: {
			city: '',
			closeDate: '',
			email: '',
			firstName: '',
			landline: '',
			lastName: '',
			mobile: '',
			moveDate: '',
			postalCode: '',
			state: '',
			streetAddress1: '',
			streetAddress2: '',
		},
	};

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<React.Fragment>
				<header className="flex items-center justify-between mt-10 mb-6">
					<h1 className="hl-ms-6">{this.props.formTitle}</h1>
					<LinkButton
						icon={images.icons.delete}
						to={paths.app.agent.contacts.root}
					>
						{translate('agent.pages.contacts.form.cancel')}
					</LinkButton>
				</header>
				<BaseForm
					initialValues={this.props.initialValues}
					onSubmit={this.props.onSubmit}
					validationSchema={validationSchema}
				>
					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<legend className="h-ms-5 mb-6">
							{translate(
								'admin.pages.contacts.form.contact_title'
							)}
						</legend>
						<NameFields />
						<FieldEmail className="col-span-full" required={true} />

						<FieldLandlinePhone name="landline" />
						<FieldMobilePhone name="mobile" />

						<AddressFields
							addressName="streetAddress1"
							cityName="city"
							countryName="country"
							designationName="streetAddress2"
							postalCodeName="postalCode"
							stateName="state"
						/>
					</fieldset>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<legend className="h-ms-5 mb-6">
							{translate(
								'agent.pages.contacts.form.moving_title'
							)}
						</legend>

						<BaseField
							label={translate(
								'agent.pages.contacts.form.move_date.label'
							)}
							name="moveDate"
							placeholder={translate(
								'agent.pages.contacts.form.move_date.placeholder'
							)}
							type="date"
						/>

						<BaseField
							label={translate(
								'agent.pages.contacts.form.close_date.label'
							)}
							name="closeDate"
							placeholder={translate(
								'agent.pages.contacts.form.close_date.placeholder'
							)}
							type="date"
						/>
					</fieldset>

					{this.props.children}

					<footer className="mx-auto sm:ml-0 button-group w-min">
						<SubmitButton>
							{this.props.editText ??
								translate('global.forms.buttons.save_text')}
						</SubmitButton>

						{this.props.additionalButtons}

						<LinkButton
							icon={images.icons.delete}
							size="large"
							to={paths.app.agent.contacts.root}
						>
							{translate('agent.pages.contacts.form.cancel')}
						</LinkButton>
					</footer>

					<FormSuccessMessages
						messages={[
							this.props.success,
							this.props.resendInviteMessage,
						]}
					/>
					<FormErrorMessages messages={[this.props.error]} />
				</BaseForm>
			</React.Fragment>
		);
	}
}
