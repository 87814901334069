/**
 * @typedef {import('../../../../src/types/AdminPhoneNumber').AdminPhoneNumberApi} AdminPhoneNumberApi
 * @typedef {import('../../../Registration/RegistrationService/Agent').default} Agent
 * @typedef {import('../../Admin/Users/Admins/Admin').default} Admin
 * @typedef {import('../../Admin/Users/Admins/AdminResponse').default} AdminResponse
 * @typedef {import('../../Admin/Users/Admins/GroupList').default} GroupList
 * @typedef {import('../AgentService/Name').default} AgentName
 * @typedef {import('../OfferForm/GroupField')} GroupField
 * @typedef {import('./Administrator').default} Administrator
 * @typedef {import('./AdministratorData').default} AdministratorData
 * @typedef {import('./AdministratorPersonalData').default} AdministratorPersonalData
 * @typedef {import('./AdminPreview').default} AdminPreview
 * @typedef {import('./Dependencies').default} Dependencies
 * @typedef {import('./GetsAdministratorPersonalData').default} GetsAdministratorPersonalData
 * @typedef {import('./GetsAgentsByGroupIds').default} GetsAgentsByGroupIds
 * @typedef {import('./GetsAllAdmins').default} GetsAllAdmins
 * @typedef {import('./GetsAllowedGroups').default} GetsAllowedGroups
 * @typedef {import('./MappedAgent').default} MappedAgent
 * @typedef {import('./Group').default} Group
 * @typedef {import('./GroupData').default} GroupData
 */

import { array } from '@mooveguru/js-utilities';
import { resolvePhoneNumberRequest } from '../../../utils/convertToPhoneNumberList';
import apiUrls from '../../../config/local/api-urls';
import formatPhoneNumberForData from '../../../utils/formatPhoneNumberForData';
import mapInputToFormData from '../../../utils/mapInputToFormData';

/**
 * @implements {GetsAdministratorPersonalData}
 * @implements {GetsAgentsByGroupIds}
 * @implements {GetsAllAdmins}
 * @implements {GetsAllowedGroups}
 */

export default class AdministratorService {
	/** @param {Dependencies} dependencies */
	constructor(dependencies) {
		this.authService = dependencies.authService;
		this.httpClient = dependencies.httpClient;
	}

	/** @param {string} id */
	async getAdministrator(id) {
		const response = await this.httpClient.get(
			`${apiUrls.administrator.root}/${id}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const data = response.body.results;

		return {
			active: data?.active,
			email: data?.email,
			firstName: data?.name?.first,
			groups:
				data?.groups?.map(
					/**
					 * @param {GroupData} group
					 * @returns {{id: string | null, isPrimary: boolean | null, name: string | null}}
					 */
					(group) => ({
						id: group.id,
						isPrimary: group.is_primary,
						name: group.name,
					})
				) ?? [],
			landline: data?.phone?.landline,
			lastName: data?.name?.last,
			mobile: data?.phone?.mobile,
			roles: data?.roles ?? [],
		};
	}

	/**
	 * @param {string | boolean | null | []} value
	 * @param {string | boolean | null | []} returnValue
	 * @returns {string | boolean | null | []}
	 */
	static validateValue(value, returnValue = '') {
		if (value) {
			return value;
		}
		if (returnValue) {
			return returnValue;
		}
		return '';
	}

	async getAdministratorId() {
		const response = await this.httpClient.get(
			`${apiUrls.me.administrator.root}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		return response.body.results.id;
	}

	/**
	 * @param {string[] | string} groupIds
	 * @returns {Promise<MappedAgent[] | []>}
	 */
	async getAgentsByGroupIds(groupIds) {
		const data = {
			ids: array.wrap(groupIds).flat(),
		};

		const response = await this.httpClient.post(
			apiUrls.group.getAgentsGroups,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			data
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		if (!response.body.results) {
			return [];
		}

		const agentList = response.body.results;

		if (!agentList.length) {
			return [];
		}

		return agentList.map(
			/**
			 * @param {Agent} agent
			 * @returns {{firstName: string, id:string | undefined, lastName:string, name: string}}
			 */
			(agent) => ({
				firstName: agent.name.first,
				id: agent._id,
				lastName: agent.name.last,
				name: `${agent.name.first} ${agent.name.last}`,
			})
		);
	}

	/**
	 * @param {number=} limit
	 * @returns {Promise<[{id: string, name: string}]>}
	 */
	async getAllowedGroups(limit = 100) {
		const response = await this.httpClient.get(
			`${apiUrls.groups.root}/?limit=${limit}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const groupList = response.body.groups;

		return groupList.map(
			/**
			 * @param {Group} group
			 * @returns {Omit<Group,'isPrimary'>}
			 */
			(group) => ({
				id: group.id,
				name: group.name,
			})
		);
	}

	/**
	 * @returns {Promise<Admin>}
	 */
	async getAdministratorPersonalData() {
		const response = await this.httpClient.get(
			`${apiUrls.me.administrator.root}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		const results = response.body;

		const admin = {
			active: results.active,
			email: results.email,
			groups:
				results.groups.map(
					/**
					 * @param {{has_subscription: boolean, id: string, is_primary: boolean, name: string}} group
					 * @returns {Group & { hasSubscription: boolean }}
					 */
					(group) => ({
						hasSubscription: group.has_subscription,
						id: group.id,
						isPrimary: group.is_primary,
						name: group.name,
					})
				) ?? [],
			headshot: results.headshot,
			id: results.id,
			name: { first: results.name.first, last: results.name.last },
			networkVendors: results.suppress_external_vendors,
			occupation: results.occupation,
			phone: {
				landline: results.phone.landline,
				mobile: results.phone.mobile,
			},
			roles: results.roles,
			userId: results.user_id,
		};

		return admin;
	}

	/**
	 * @param {AdministratorPersonalData} values
	 * @param {File=} file
	 * @returns {Promise<void>}
	 */
	async updateAdministratorPersonalData(values, file) {
		const formData = mapInputToFormData({
			email: values.email,
			// eslint-disable-next-line camelcase -- Mapping request body
			first_name: values.name.first,
			// eslint-disable-next-line camelcase -- Mapping request body
			last_name: values.name.last,
			occupation: values.occupation,
		});

		if (file) {
			formData.append('files', file);
		}

		const phoneNumbers = resolvePhoneNumberRequest(
			values.phone?.mobile || null,
			values.phone?.landline || null
		);

		if (phoneNumbers) {
			formData.append('phone_numbers', JSON.stringify(phoneNumbers));
		}

		const response = await this.httpClient.put(
			apiUrls.me.administrator.root,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			}),
			formData
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}
	}

	/**
	 * @returns {Promise<Agent>}
	 */
	async getAllAgentNames() {
		const response = await this.httpClient.get(
			apiUrls.me.administrator.agents,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		return response.body.results.data.map((/** @type {Agent} */ agent) => ({
			label: `${agent.name.first} ${agent.name.last}`,
			value: agent.id,
		}));
	}

	/**
	 * @param {string[]} agentIds
	 * @returns {Promise<void>}
	 */
	async resendAgentsInvite(agentIds) {
		const response = await this.httpClient.post(
			apiUrls.agents.resendInvite,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			// eslint-disable-next-line camelcase
			{ agent_ids: agentIds }
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}
	}

	/**
	 * @param {number} limit
	 * @param {number} page
	 * @returns {Promise<{administrators: AdminPreview[], totalPages: number, total: number}>}
	 */
	async getAllAdmins(limit = 100, page = 1) {
		const response = await this.httpClient.get(
			`${apiUrls.administrator.all}?limit=${limit}&page=${page}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}
		return {
			administrators: response.body.results.data.map(
				/**
				 * @param {Administrator} administrator
				 * @returns {any}
				 */
				(administrator) => ({
					active: administrator?.active,
					email: administrator?.email,
					groups: administrator?.groups?.find(
						(group) => group?.isPrimary
					)?.name,
					id: administrator?.id,
					landline: administrator?.phone?.landline,
					mobile: administrator?.phone?.mobile,
					name: `${administrator?.name?.first} ${administrator?.name?.last}`,
					roles: administrator?.roles?.join(', ') ?? '',
				})
			),
			total: response.body.results?.total,
			totalPages: response.body.results?.pages,
		};
	}

	/**
	 * @param {Admin & {groups: Group[]}} values
	 * @returns {Promise<void>}
	 */
	async createAdmin(values) {
		const dataMap = {
			// eslint-disable-next-line camelcase
			email_address: values?.email,
			groups:
				values.groups?.map(
					/**
					 * @param {{id: string, isPrimary: boolean}} group
					 * @returns {{id: string, is_primary: boolean}}
					 */ (group) => ({
						id: group.id,
						// eslint-disable-next-line camelcase
						is_primary: group?.isPrimary ?? false,
					})
				) ?? null,
			name: {
				first: values?.firstName,
				last: values?.lastName,
			},
			// eslint-disable-next-line camelcase
			phone_numbers: AdministratorService.#generatePhoneNumbers(
				values?.mobile || null,
				values?.landline || null
			),
			role: values?.roles,
		};

		const response = await this.httpClient.post(
			`${apiUrls.administrators.add}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			dataMap
		);

		if (!response.isOk) {
			throw new Error(response.body?.message ?? response.body?.error);
		}
	}

	/**
	 * @param {string} id
	 * @param {Admin & {groups: Group[]}} values
	 * @returns {Promise<void>}
	 */
	async editAdmin(id, values) {
		let active = null;
		if (typeof values.active === 'string') {
			active = values.active === 'active';
		}

		const phoneNumbers = AdministratorService.#generatePhoneNumbers(
			values?.mobile || null,
			values?.landline || null
		);

		const groups = values.groups?.map(
			/**
			 * @param {Group} group
			 * @returns {{_id: string | null, is_primary: boolean}}
			 */
			(group) => ({
				id: group.id,
				// eslint-disable-next-line camelcase
				is_primary: group?.isPrimary ?? false,
			})
		);

		const response = await this.httpClient.put(
			`${apiUrls.administrators.edit}/${id}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			}),
			{
				active: active ?? values?.active,
				// eslint-disable-next-line camelcase
				email_address: values?.email,
				groups: AdministratorService.validateValue(groups, []),
				name: {
					first: values?.firstName,
					last: values?.lastName,
				},
				// eslint-disable-next-line camelcase
				phone_numbers: phoneNumbers,
				role: values?.roles ?? null,
			}
		);

		if (!response.isOk) {
			throw new Error(response.body.message ?? response.body[0]);
		}
	}

	/**
	 *@param {string | null} mobile
	 * @param {string | null} landline
	 * @returns {AdminPhoneNumberApi[] | null}
	 */
	static #generatePhoneNumbers(mobile, landline) {
		/** @type {AdminPhoneNumberApi[]} */
		const phoneNumbers = [];

		if (mobile) {
			phoneNumbers.push({
				// eslint-disable-next-line camelcase
				is_primary: true,
				type: 'mobile',
				value: formatPhoneNumberForData(mobile),
			});
		}

		if (landline) {
			phoneNumbers.push({
				// eslint-disable-next-line camelcase
				is_primary: !phoneNumbers.length,
				type: 'landline',
				value: formatPhoneNumberForData(landline),
			});
		}

		if (phoneNumbers.length) {
			return phoneNumbers;
		}

		return null;
	}
}
