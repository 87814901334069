/**
 * @typedef {import('./Contacts/ContactProps').Contact} Contact
 * @typedef {import('./Contacts/ContactProps').ContactList} ContactList
 * @typedef {import('./Contacts/ContactProps').MapContactToRequest} ContactToRequest
 * @typedef {import('./GetContact').default} GetContact
 * @typedef {import('./Contacts/ContactProps').ReturnContactList} ReturnContactList
 * @typedef {import('./Contacts/ContactProps').Source} Source
 */

import apiUrls from '../../config/local/api-urls';
import formatDateForSaving from '../../utils/formatDateForSaving';
import HttpClient from '@mooveguru/js-http-client';

// @ts-ignore it can be ignored
const httpClient = new HttpClient();
/**
 * @param {string} accessToken
 * @returns {Promise<number>}
 */
export async function getNewContacts(accessToken) {
	const response = await httpClient.get(
		apiUrls.group.getContacts,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}

	return response.body.results.contacts.new;
}

/**
 * @param {string} accessToken
 * @returns {Promise<{missing: {address: number, phone: number, email: number}}>}
 */
export async function getMissingContactsData(accessToken) {
	const response = await httpClient.get(
		apiUrls.group.getContacts,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}

	const results = response.body.results;

	return {
		missing: {
			address: results.missing.address,
			email: results.missing.email,
			phone: results.missing.phone,
		},
	};
}

/**
 * @param {string} accessToken
 * @returns {Promise<Contact[]>}>}
 */
export async function getGroupContacts(accessToken) {
	const response = await httpClient.get(
		apiUrls.me.contacts,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}

	const contactsList = response.body.contacts;

	return contactsList.map(
		/**
		 * @param {ContactList} contact
		 * @returns {ReturnContactList}
		 */
		(contact) => ({
			address: contact.moving_from,
			email: contact.email_address,
			id: contact.id,
			landline: contact.landline_phone_number,
			leadscore: contact.leadscore,
			mobile: contact.mobile_phone_number,
			moveDate: contact.move_date,
			name: generateFullName(contact.name),
		})
	);
}

/**
 * @param {string} accessToken
 * @returns {Promise<string>}
 */
export async function getAgentId(accessToken) {
	const response = await httpClient.get(
		apiUrls.me.info,
		new Headers({
			'X-Access-Token': accessToken,
		})
	);

	return response.body.results.meta.agent._id;
}

/**
 * @param {Contact} contact
 * @param {Source} source
 * @returns {ContactToRequest}
 */
export function mapContactToRequest(contact, source) {
	/* eslint-disable camelcase -- to create as needed data kay */
	const phoneNumbers = ['landline', 'mobile']
		.map(
			/**
			 * @param {string} key
			 * @returns {{ is_primary: boolean, type: string, value: string } | null}
			 */
			(key) => {
				// @ts-ignore -- type is declared
				if (!contact[key]) {
					return null;
				}

				return {
					is_primary: key === 'mobile',
					type: key === 'landline' ? 'home' : key,
					// @ts-ignore -- type is declared
					value: contact[key],
				};
			}
		)
		.filter((phoneNumber) => phoneNumber !== null);

	return {
		address: {
			city: contact.city,
			country: contact.country,
			postal_code: contact.postalCode,
			state: contact.state,
			street_address_1: contact.streetAddress1,
			street_address_2: contact.streetAddress2,
		},
		agent_id: contact.agentId,
		close_date: contact.closeDate
			? formatDateForSaving(contact.closeDate)
			: null,
		email_address: contact.email,
		group_id: contact.groupId ?? null,
		move_date: contact.moveDate
			? formatDateForSaving(contact.moveDate)
			: null,
		name: {
			first: contact.firstName,
			last: contact.lastName,
		},
		phone_numbers: phoneNumbers,
		source: source,
	};
	/* eslint-enable camelcase */
}

/**
 * @param {Contact} contact
 * @param {string} accessToken
 * @param {Source} source
 * @param {boolean} [sendInvite]
 * @returns {Promise<void>}
 */

export async function addContact(
	contact,
	accessToken,
	source,
	sendInvite = true
) {
	const response = await httpClient.post(
		`${apiUrls.contacts.add}?sendInvite=${sendInvite}`,
		new Headers({
			'Content-Type': 'application/json',
			'X-Access-Token': accessToken,
		}),
		mapContactToRequest(contact, source)
	);

	if (!response.isOk) {
		const error = new Error(response.body);
		// @ts-ignore adding statusCode to error object
		error.statusCode = response.statusCode;
		throw error;
	}
}

/**
 * @param {string} contactId
 * @param {string} accessToken
 * @returns {Promise<GetContact>}
 */

// eslint-disable-next-line complexity
export async function getContact(contactId, accessToken) {
	const response = await httpClient.get(
		`${apiUrls.contacts.view}/${contactId}`,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}

	const results = response.body;

	return {
		agentId: results.agent_id,
		city: results.address?.city ?? null,
		closeDate: results.close_date,
		country: results.address?.country ?? null,
		email: results.email_address,
		firstName: results?.name?.first ?? null,
		groupId: results?.group_id,
		homeownerId: results.homeownerId ?? [],
		id: results.id,
		landline: results.phone_numbers.find(
			/**
			 * @param {{type: string}} phoneNumber
			 * @returns {boolean}
			 */
			(phoneNumber) => ['landline', 'home']?.includes(phoneNumber.type)
		)?.value,
		lastName: results?.name?.last ?? null,
		mobile: results.phone_numbers.find(
			/**
			 * @param {{type:string}} phoneNumber
			 * @returns {boolean}
			 */
			(phoneNumber) => phoneNumber.type === 'mobile'
		)?.value,
		moveDate: results.move_date,
		postalCode: results.address?.postal_code ?? null,
		state: results.address?.state ?? null,
		streetAddress1: results.address?.street_address_1 ?? null,
		streetAddress2: results.address?.street_address_2 ?? null,
	};
}

/**
 * @param {string} id
 * @param {Contact} data
 * @param {string} accessToken
 */
export async function updateContact(id, data, accessToken) {
	const response = await httpClient.put(
		`${apiUrls.contacts.update}/${id}`,
		new Headers({
			'Content-Type': 'application/json',
			'X-Access-Token': accessToken,
		}),
		mapContactToRequest(data, null)
	);

	if (!response.isOk) {
		throw new Error(response.body.message);
	}
}

/**
 * @param {{first: string, last: string}} name
 * @returns {string|null}
 */

function generateFullName(name) {
	const { first, last } = name;
	if (first && last) {
		return `${first} ${last}`;
	}
	if (first) {
		return first;
	}
	if (last) {
		return last;
	}
	return null;
}
