/**
 * @typedef {import('./Component').FilterQuery} FilterQuery
 * @typedef {import('./Component').Input} Input
 * @typedef {import('./Component').Props} Props
 * @typedef {import('./Component').State} State
 * @typedef {import('./Component').SearchQuery} SearchQuery
 * @typedef {import('./Component').QueryParameters} QueryParameters
 */
import { CheckList, RadioList, Search } from './Types';
import BaseForm from '../../../../shared/Forms/BaseForm';
import Multiselect from './Types/Multiselect';
import React from 'react';
import ToggleList from './Types/ToggleList';

/**
 * @extends {React.Component<Props, State>}
 */
export default class Filters extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		// this._handleSelect = this._handleSelect.bind(this);
		this._handleClose = this._handleClose.bind(this);
		this._handleSubmit = this._handleSubmit.bind(this);
		// this.formRef = React.createRef();
		this.submitRef = React.createRef();
		// this.state = { filters: this.#initialState };
	}

	/**
	 * @param {'checkbox' | 'radio'} type
	 * @returns {string[]}
	 */
	#extractFilterKeys(type) {
		return Object.values({ ...(this.props[type] ?? {}) })
			.map((object) => Object.keys(object.values))
			.flat();
	}

	/**
	 * @param {string} key
	 * @returns {string}
	 */
	static #inputName(key) {
		return `filters-${ToggleList.cleanName(key)}`;
	}

	/**
	 * TODO: Store selections in cookie and retrieve
	 * @returns {{ [key: string]: '' }}
	 */
	get #initialValues() {
		/**
		 * @type {{ [key: string]: '' }}
		 */
		const values = {};

		if (this.props.search) {
			values.search = '';
		}

		// Extracts all field group names to map default value
		this.#extractFilterKeys('checkbox').forEach((key) => {
			// @ts-ignore
			values[Filters.#inputName(key)] = [];
		});

		// Extracts all field group names to map default value
		this.#extractFilterKeys('radio').forEach((key) => {
			// @ts-ignore
			values[Filters.#inputName(key)] = '';
		});

		Object.keys(this.props.multiselect ?? {}).forEach((key) => {
			// @ts-ignore
			values[Filters.#inputName(key)] = [];
		});

		return values;
	}

	// /**
	//  * @returns {{ relation: true } & { [Key in string]?: '' | [] }}
	//  */
	// get #initialState() {
	// 	/**
	// 	 * @type {{ relation: true } & { [Key in string]?: '' | [] }}
	// 	 */
	// 	// @ts-ignore
	// 	const values = { relation: 'and' };

	/*
	 * 	// Extracts all field group names to map default value
	 * 	this.#extractFilterKeys('checkbox').forEach((key) => {
	 * 		// @ts-ignore
	 * 		values[`filter[${key}]`] = [];
	 * 	});
	 */

	/*
	 * 	// Extracts all field group names to map default value
	 * 	this.#extractFilterKeys('radio').forEach((key) => {
	 * 		// @ts-ignore
	 * 		values[`filter[${key}]`] = '';
	 * 	});
	 */

	/*
	 * 	return values;
	 * }
	 */

	/**
	 * @param {Input} values
	 * @returns {SearchQuery}
	 */
	#handleSearch(values) {
		if (['', null, undefined].includes(values.search)) {
			return {};
		}

		if (this.props.search === undefined) {
			return { search: values.search };
		}

		/** @type {SearchQuery} */
		const filterList = {};

		this.props.search.forEach((field) => {
			filterList[`search[${field}]`] = values.search;
		});

		return filterList;
	}

	/**
	 * @param {any} value
	 * @returns {boolean}
	 */
	static #isEmpty(value) {
		if (['', undefined, null].includes(value)) {
			return true;
		}

		if (Array.isArray(value) && value.length === 0) {
			return true;
		}

		if (Object.keys(value).length === 0) {
			return true;
		}

		return false;
	}

	// /**
	//  * @param {QueryParameters} filters
	//  * @returns {boolean}
	//  */
	// #isDirty(filters) {
	// 	for (const [key, value] of Object.entries(filters)) {
	// 		if (this.state[key] !== value) {
	// 			return true;
	// 		}
	// 	}

	/*
	 * 	return false;
	 * }
	 */

	/**
	 * @param {Input} values
	 * @returns {FilterQuery}
	 */
	static #handleFilters(values) {
		const filterList = {};

		Object.keys(values)
			.filter((key) => key.startsWith('filters-'))
			.forEach((key) => {
				// @ts-ignore
				if (Filters.#isEmpty(values[key])) {
					return;
				}

				const index = key.replace('filters-', '');

				// @ts-ignore
				filterList[`filter[${index}]`] = values[key];
			});

		if (Object.values(filterList).length === 0) {
			return {};
		}

		filterList.relation = 'and';

		// @ts-ignore
		return filterList;
	}

	/**
	 * @param {any} value
	 * @returns {boolean}
	 */
	static #isSet(value) {
		switch (typeof value) {
			case 'boolean':
			case 'number':
				return true;

			// no default
		}

		switch (value) {
			case undefined:
			case '':
				return false;

			// no default
		}

		if (Array.isArray(value)) {
			return value.length > 0;
		}

		return Object.keys(value).length > 0;
	}

	// TODO: Implement dirty checks so we're not submitting repeat queries
	// /**
	//  * @param {QueryParameters} filters
	//  * @returns {boolean}
	//  */
	// #isDirty(filters) {
	// 	for (const [key, value] of Object.entries(filters)) {
	// 		if (this.state[key] !== value) {
	// 			return true;
	// 		}
	// 	}

	/*
	 * 	return false;
	 * }
	 */

	/**
	 * @param {Input} values
	 * @returns {QueryParameters}
	 */
	_handleSubmit(values) {
		const filters = {
			...Filters.#handleFilters(values),
			...this.#handleSearch(values),
		};

		/*
		 * const isDirty = this.#isDirty(filters);
		 * console.log(filters, this.state.filters, isDirty);
		 */

		this.setState((prev) => ({ ...prev, filters }));

		/*
		 * if (!isDirty) {
		 * return filters;
		 * }
		 */

		return this.props.onSubmit(filters);
	}

	_handleClose() {
		// this.formRef.current.

		this.submitRef.current.click();
	}

	// eslint-disable-next-line complexity
	render() {
		const elements = [];

		if (this.props.search) {
			elements.push(<Search key="filter-search" />);
		}

		if (this.props.multiselect) {
			elements.push(
				<Multiselect
					fields={this.props.multiselect}
					gatedText={this.props.gatedText}
					key="filter-multiselct"
					onClose={this._handleClose}
					subscriptionLink={this.props.subscriptionLink}
				/>
			);
		}

		if (this.props.children) {
			elements.push(this.props.children);
		}

		if (this.props.checkbox) {
			elements.push(
				<CheckList
					fields={this.props.checkbox}
					gatedText={this.props.gatedText}
					key="filter-checklist"
					onClose={this._handleClose}
					subscriptionLink={this.props.subscriptionLink}
				/>
			);
		}

		if (this.props.radio) {
			elements.push(
				<RadioList
					fields={this.props.radio}
					gatedText={this.props.gatedText}
					key="filter-radiolist"
					onClose={this._handleClose}
					subscriptionLink={this.props.subscriptionLink}
				/>
			);
		}

		if (elements.length === 0) {
			return null;
		}

		return (
			<BaseForm
				className={`flex flex-wrap gap-2 items-center justify-center table-filters ${this.props.className}`.trim()}
				initialValues={this.#initialValues}
				noRequiredText={true}
				onSubmit={this._handleSubmit}
				// ref={this.formRef}
			>
				{elements}

				<button
					className="hidden order-3"
					ref={this.submitRef}
					type="submit"
				>
					{'Submit'}
				</button>
			</BaseForm>
		);
	}
}
