import { array } from '@mooveguru/js-utilities';
import { BaseSVG } from '../../BaseSVG';
import images from '../../../config/local/images';
import React from 'react';

export default class FormMessages extends React.Component {
	render() {
		if (!this.props.messages) {
			return null;
		}

		const messageList = array.wrap(this.props.messages).filter(Boolean);

		if (!messageList.length) {
			return null;
		}

		/* prettier-ignore */
		const heading = this.props.heading
			? (<h3 className="title">
				<i className="icon icon-xs">
					<BaseSVG
						path={this.props.icon ?? images.icons.info}
					/>
				</i>
				{this.props.heading}
			</h3>)
			: null;
		/* prettier-enable */

		return messageList.map((child) => (
			<section
				className={`form-alert w-full my-5 text-grey-900 ${this.props.className}`}
				key={`form-alert my-5 ${this.props.className}`}
			>
				{heading}
				<div className="text rich-text">{child}</div>
			</section>
		));
	}
}

FormMessages.defaultTypes = {
	className: '',
};
