/**
 * @typedef {import('./BaseFormType').default} Props
 * @typedef {import('formik/dist/types').FormikErrors<{[key: string]: string}>} FormikErrors
 */

import { Form, Formik } from 'formik';
import FieldRequiredMessage from './FieldRequiredMessage/FieldRequiredMessage';
import FormWarningMessages from './Messages/FormWarningMessages';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class BaseForm extends React.Component {
	/**
	 * @param {FormikErrors} errors
	 * @returns {JSX.Element | null}
	 */
	static #errorList(errors) {
		const errorList = Object.values(errors);

		if (!errorList.length) {
			return null;
		}

		return <FormWarningMessages messages={errorList} />;
	}

	/**  @returns {JSX.Element} */
	render() {
		return (
			<Formik
				initialValues={this.props.initialValues ?? {}}
				innerRef={this.props.innerRef}
				onSubmit={(values, { setSubmitting }) =>
					this.props.onSubmit(values, setSubmitting)
				}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={this.props.validationSchema}
			>
				{(renderProps) => (
					<Form className={`form ${this.props.className}`.trim()}>
						{!this.props.noRequiredText && <FieldRequiredMessage />}
						{this.props.children}
						{BaseForm.#errorList(renderProps.errors)}
					</Form>
				)}
			</Formik>
		);
	}
}
