/**
 * @typedef {import('./ResetPasswordProps').default} Props
 * @typedef {import('./ResetPasswordState').default} State
 */

import { signInSegment } from '../config/local/paths';
import { translate } from '../App/Internationalization';
import * as validators from '../shared/validators';
import * as yup from 'yup';
import AppLogo from '../App/shared/AppLogo';
import BaseForm from '../shared/Forms/BaseForm';
import captureError from '../utils/captureError';
import FieldEmail from '../shared/Forms/Fields/FieldEmail';
import FormErrorMessages from '../shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from '../shared/Forms/Messages/FormSuccessMessages';
import LinkButton from '../shared/LinkButton';
import React from 'react';
import SpamMessage from '../shared/Forms/Messages/SpamMessage';
import SubmitButton from '../shared/Forms/Inputs/SubmitButton';

const initialFormValues = { email: '' };

const validationSchema = yup.object().shape({
	email: validators.email,
});

const email = 'registration.forgot_password.email';

/** @extends {React.Component<Props, State>} */
export default class ResetPassword extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/**  @type {State} */
		this.state = {
			errorMessage: '',
			isSubmittedSuccessfully: false,
			successMessage: '',
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	// eslint-disable-next-line class-methods-use-this
	componentDidMount() {
		const searchParams = new URLSearchParams(window.location.search);

		if (searchParams.has('email')) {
			initialFormValues['email'] = searchParams.get('email') ?? '';
		}
	}

	/**
	 * @param {{email: string}} values
	 * @returns {Promise<void>}
	 */
	async handleSubmit(values) {
		try {
			await this.props.auth.resetPassword(values.email);

			this.setState({
				isSubmittedSuccessfully: true,
				successMessage: translate(`${email}.success_message`),
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate(`${email}.error_message`),
			});
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<section className="app-login">
				<div className="login-container">
					<AppLogo />

					<BaseForm
						className="my-20"
						initialValues={initialFormValues}
						noRequiredText={true}
						onSubmit={this.handleSubmit}
						validationSchema={validationSchema}
					>
						<FieldEmail
							placeholder={translate(`${email}.placeholder`)}
						/>

						<div className="button-group mt-10">
							<SubmitButton
								disabled={this.state.isSubmittedSuccessfully}
								icon=""
							>
								{translate('registration.form.submit')}
							</SubmitButton>

							<LinkButton to={signInSegment}>
								{translate('sign_in.title_sign_in')}
							</LinkButton>
						</div>
						<FormSuccessMessages
							messages={this.state.successMessage}
						/>
						<FormErrorMessages messages={this.state.errorMessage} />
					</BaseForm>
				</div>
				<SpamMessage />
			</section>
		);
	}
}
