import ExternalAnchor from '../shared/ExternalAnchor';
import React from 'react';

export default class ExternalAnchorButton extends React.Component {
	render() {
		return (
			<ExternalAnchor
				className={this.props.className}
				dataActivityClick={this.props.dataActivityClick}
				dataActivityId={this.props.dataActivityId}
				download={this.props.download}
				href={this.props.href}
				icon={this.props.icon}
				iconClass={this.props.iconClass}
				rel={this.props.rel}
				target={this.props.target}
			>
				{this.props.children}
			</ExternalAnchor>
		);
	}
}

ExternalAnchorButton.defaultProps = {
	rel: ExternalAnchor.defaultProps.rel,
	target: ExternalAnchor.defaultProps.target,
};
