/** @typedef {import('Registration/RegisterAgent/AgentRegistrationForm/Values').default} Values */
/** @typedef {import('Registration/RegisterAgent/AgentRegistrationForm/Props').default} Props */

import { translate } from '../../../App/Internationalization';
import * as validators from '../../../shared/validators';
import * as yup from 'yup';
import agentOccupations from '@mooveguru/yhh-shared-config/users/agent/occupations.json';
import AgreesToTermsOfUseLabelContent from '../../AgreesToTermsOfUseLabelContent';
import BaseForm from '../../../shared/Forms/BaseForm';
import FieldCheckbox from '../../../shared/Forms/Fields/FieldCheckbox';
import FieldEmail from '../../../shared/Forms/Fields/FieldEmail';
import FieldPassword from '../../../shared/Forms/Fields/FieldPassword';
import FieldPostalCode from '../../../shared/Forms/Fields/Address/FieldPostalCode';
import FieldSelect from '../../../shared/Forms/Fields/FieldSelect';
import NameFields from '../../../shared/Forms/Fields/Name/NameFields';
import React from 'react';
import SelectOptionList from '../../../shared/Forms/Inputs/SelectOptionList';
import SubmitButton from '../../../shared/Forms/Inputs/SubmitButton';

/** @type {Values} */
const initialValues = {
	agreesToTermsOfUse: false,
	emailAddress: '',
	firstName: '',
	lastName: '',
	occupation: '',
	password: '',
	passwordConfirmation: '',
	postalCode: '',
};

const validationSchema = yup.object().shape({
	agreesToTermsOfUse: validators.terms,
	emailAddress: validators.email,
	firstName: validators.firstName,
	lastName: validators.lastName,
	password: validators.password,
	passwordConfirmation: validators.matchConfirmation('password', 'Passwords'),
	postalCode: validators.postalCode,
});

/** @extends {React.Component<Props>} */
export default class AgentRegistrationForm extends React.Component {
	/** @returns {JSX.Element} */
	render() {
		return (
			<React.Fragment>
				<h1 className="hl-ms-6 mt-10 mb-2">
					{translate('registration.form.registration')}
				</h1>

				<section className="rich-text mb-6">
					<p> {translate('registration.form.agent_lead')}</p>
				</section>

				<BaseForm
					initialValues={initialValues}
					onSubmit={this.props.onSubmit}
					validationSchema={validationSchema}
				>
					<h2 className="h-ms-5 mb-2">
						{translate('registration.form.personal_details')}
					</h2>

					<section className="rich-text mb-6">
						<p>
							{translate(
								'registration.form.agent_personal_details_lead'
							)}
						</p>
					</section>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<NameFields required={true} />
						<FieldPostalCode name="postalCode" required={true} />
						<FieldSelect
							label={translate(
								'homeowner.pages.dashboard.agent.occupation.label'
							)}
							name="occupation"
							noDefaultOption={true}
							required={true}
						>
							<SelectOptionList values={agentOccupations} />
						</FieldSelect>
					</fieldset>

					<h2 className="h-ms-5 mb-2">
						{translate('registration.form.account_details')}
					</h2>
					<section className="rich-text mb-6">
						<p>
							{translate(
								'registration.form.account_details_lead'
							)}
						</p>
					</section>

					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<FieldEmail
							className="col-start-1"
							name="emailAddress"
							required={true}
						/>

						<FieldPassword
							className="col-start-1"
							help={translate('global.forms.help.password')}
						/>

						<FieldPassword
							className="col-start-1"
							label={translate(
								'registration.form.confirm_password'
							)}
							name="passwordConfirmation"
						/>
					</fieldset>
					<fieldset className="mb-8">
						<FieldCheckbox
							className="mb-4"
							label={<AgreesToTermsOfUseLabelContent />}
							name="agreesToTermsOfUse"
							required={true}
							value={initialValues.agreesToTermsOfUse !== true}
						/>
					</fieldset>

					<footer className="mx-auto sm:ml-0 button-group w-min">
						<SubmitButton>
							{translate('registration.form.submit')}
						</SubmitButton>
					</footer>
				</BaseForm>
			</React.Fragment>
		);
	}
}
