/**
 * @typedef {import('./AcceptTransferPropertyProps').default} Props
 * @typedef {import('./AcceptTransferPropertyState').default} State
 */

import { Redirect } from 'react-router-dom';
import { translate } from '../../../App/Internationalization';
import { withAuth } from '../../../shared/AuthProvider';
import { withProperty } from '../PropertyProvider';
import { withPropertyService } from '../../../service-container';
import BaseForm from '../../../shared/Forms/BaseForm';
import captureError from '../../../utils/captureError';
import FieldTitle from '../../../shared/Forms/Fields/FieldTitle';
import FormErrorMessages from '../../../shared/Forms/Messages/FormErrorMessages';
import FormWarningMessages from '../../../shared/Forms/Messages/FormWarningMessages';
import images from '../../../config/local/images';
import LinkButton from '../../../shared/LinkButton';
import Loading from '../../../shared/Loading';
import paths from '../../../config/local/paths';
import React from 'react';
import SubmitButton from '../../../shared/Forms/Inputs/SubmitButton';

/** @extends {React.Component<Props, State>} */
export class AcceptTransferProperty extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		/**
		 * @type {State}
		 */
		this.state = {
			errorMessage: null,
			formComplete: false,
			isGettingData: true,
			property: {
				city: '',
				country: '',
				id: '',
				postalCode: '',
				state: '',
				streetAddress1: '',
				streetAddress2: '',
			},
		};

		this.handleSubmit = this.#handleSubmit.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		try {
			const query = new URLSearchParams(this.props?.location?.search);
			const address = (query.get('address') ?? '')
				.split(',')
				.map((string) => string.trim());
			const city = query.get('city');
			const postalCode = query.get('postal_code');
			const state = query.get('state');
			const country = query.get('country');

			this.setState({
				isGettingData: false,
				property: {
					city: city,
					country: country,
					id: this.props.match.params.propertyId,
					postalCode: postalCode,
					state: state,
					streetAddress1: address[0],
					streetAddress2: address[1],
				},
			});
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {{title: string}} values
	 * @returns {Promise<void>}
	 */
	async #handleSubmit(values) {
		try {
			await Promise.all([
				await this.props.propertyService.confirmTransferProperty(
					values,
					this.props.match.params.propertyId,
					this.props.auth.userId,
					this.props.auth.accessToken
				),
				await this.props.property.setSelectedProperty(
					this.props.match.params.propertyId,
					true
				),
			]);

			this.setState({ formComplete: true });
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate(
					'homeowner.pages.properties.accept_transfer.error'
				),
			});
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		if (!this.props.match.params.propertyId) {
			return (
				<Redirect
					to={{
						pathname: paths.app.homeowner.dashboard,
					}}
				/>
			);
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		if (this.state.formComplete) {
			return (
				<Redirect
					to={{
						pathname: paths.app.homeowner.dashboard,
						state: {
							message: translate(
								'homeowner.pages.properties.accept_transfer.submit_message'
							),
						},
					}}
				/>
			);
		}

		return (
			<React.Fragment>
				<h1 className="hl-ms-6 mt-10 mb-6">
					{translate(
						'homeowner.pages.properties.accept_transfer.title'
					)}
				</h1>

				<section className="rich-text">
					<p className="font-bold">
						{this.state.property?.streetAddress1}{' '}
						{this.state.property?.streetAddress2}
						<br />
						{`${this.state.property?.city}, `}
						{`${this.state.property?.state}, `}
						{this.state.property?.postalCode}
					</p>
				</section>

				<BaseForm onSubmit={this.handleSubmit}>
					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
						<FieldTitle
							label={translate(
								'global.forms.inputs.title.placeholder'
							)}
						/>
					</fieldset>
					<footer className="mx-auto sm:ml-0 button-group w-min">
						<SubmitButton>
							{translate(
								'homeowner.pages.properties.form.submit'
							)}
						</SubmitButton>

						<LinkButton
							icon={images.icons.delete}
							size="large"
							to={paths.app.homeowner.dashboard}
						>
							{translate('global.forms.buttons.cancel')}
						</LinkButton>
					</footer>
					<FormWarningMessages
						heading={translate('global.import.warning_heading')}
						messages={translate(
							'homeowner.pages.properties.accept_transfer.disclaimer'
						)}
					/>
					<FormErrorMessages messages={this.state.errorMessage} />
				</BaseForm>
			</React.Fragment>
		);
	}
}

export default withAuth(
	// @ts-ignore until service container types are fixed
	withProperty(withPropertyService(AcceptTransferProperty))
);
