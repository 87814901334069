/**
 * @typedef {import('@mooveguru/js-http-client').BrowserClient} HttpClient
 * @typedef {import('./UpdateEmailNotificationSubscription').default} UpdateEmailNotificationSubscription;
 */

import apiUrls from '../config/local/api-urls';

/**
 * @implements {UpdateEmailNotificationSubscription}
 */
export default class MailingService {
	/** @param {HttpClient} httpClient */
	constructor(httpClient) {
		this.baseHeaders = new Headers({ 'Content-Type': 'application/json' });
		this.httpClient = httpClient;
	}

	/**
	 * @param {string} campaign
	 * @param {string} recipient
	 * @param {string} sender
	 * @param {boolean} isSubscribing
	 * @returns {Promise<void>}
	 */
	async #toggle(campaign, recipient, sender, isSubscribing) {
		const response = await this.httpClient.put(
			`${apiUrls.settings.email}/${campaign}`,
			this.baseHeaders,
			{
				/* eslint-disable-next-line camelcase */
				is_subscribing: isSubscribing,
				recipient,
				sender,
			}
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}
	}

	/**
	 * @param {string} campaign
	 * @param {string} recipient
	 * @param {string} sender
	 * @returns {Promise<void>}
	 */
	async subscribe(campaign, recipient, sender) {
		await this.#toggle(campaign, recipient, sender, true);
	}

	/**
	 * @param {string} campaign
	 * @param {string} recipient
	 * @param {string} sender
	 * @returns {Promise<void>}
	 */
	async unsubscribe(campaign, recipient, sender) {
		await this.#toggle(campaign, recipient, sender, false);
	}
}
