import { array } from '@mooveguru/js-utilities';
import BaseInput from '../Inputs/BaseInput';
import FormField from './FormField';
import HelpMessage from '../HelpMessage';
import React from 'react';

export default class BaseField extends React.Component {
	renderHelpMessages() {
		if (!this.props.descriptiveText) {
			return '';
		}

		const messageList = array.wrap(this.props.descriptiveText);

		return (
			<ul className="mb-4">
				{messageList.map((message, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<li key={index}>
						<HelpMessage message={message} />
					</li>
				))}
			</ul>
		);
	}

	render() {
		return (
			<FormField
				className={this.props.className}
				inputName={this.props.id ?? this.props.name}
				label={this.props.label}
				labelClass={this.props.labelClass}
				name={this.props.name ?? this.props.id}
				required={this.props.required}
			>
				{this.renderHelpMessages()}
				<BaseInput
					accept={this.props.accept}
					autocomplete={this.props.autocomplete}
					className={this.props.inputClass}
					disabled={this.props.disabled}
					id={this.props.id ?? this.props.name}
					inputmode={this.props.inputmode ?? ''}
					name={this.props.name ?? this.props.id}
					onBlur={this.props.onBlur}
					onChange={this.props.onChange}
					placeholder={this.props.placeholder}
					required={this.props.required}
					type={this.props.type}
					value={this.props.value}
				/>
			</FormField>
		);
	}
}

BaseField.defaultProps = {
	disabled: false,
	required: false,
	type: 'text',
};
