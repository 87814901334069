import FieldRadio from './FieldRadio';
import InputLabel from '../Inputs/InputLabel';
import React from 'react';

export default class FieldRadioGroup extends React.Component {
	/**
	 * @param {number} index
	 * @returns {boolean}
	 */
	#isLastValue(index) {
		return index === this.props.values.length - 1;
	}

	/**
	 * @returns {JSX.Element[]}
	 */
	#renderRadio() {
		return this.props.values.map(
			/**
			 * @param {[boolean | string | number, string]} param
			 * @param {number} index
			 * @returns {JSX.Element}
			 */
			([value, title], index) => (
				<li
					className={this.#isLastValue(index) ? '' : 'mr-2'}
					key={`${value}`}
				>
					<FieldRadio
						disabled={this.props.disabled}
						id={`${this.props.name}-${index + 1}`}
						label={title}
						name={`${this.props.name}`}
						onChange={this.props.onChange}
						onClick={this.props.onClick}
						required={this.props.required}
						value={value}
					/>
				</li>
			)
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<section
				className={`input-group radio-group ${
					this.props.className ?? ''
				}`.trim()}
			>
				<InputLabel
					className={this.props.labelClassName}
					inputName={this.props.id}
				>
					{this.props.title}
				</InputLabel>

				<p className="text-sm help-text">{this.props.helpText}</p>

				<ul className="button-group my-5 w-fit">
					{this.#renderRadio()}
				</ul>
			</section>
		);
	}
}

FieldRadioGroup.defaultProps = {
	disabled: FieldRadio.defaultProps.disabled,
	required: FieldRadio.defaultProps.required,
};
