/**
 * @typedef {import('../../../../App/Admin/Users/Admins/Roles').Roles} AdminRoles
 * @typedef {import('./EditAgentProps').default} Props
 * @typedef {import('./EditAgentState').default} State
 */
import { AuthContext } from '../../../../shared/AuthProvider';
import { file as validateFile } from 'shared/validators';
import { hasCapability } from '../../../../../src/utils/capabilities';
import { Redirect } from 'react-router';
import { translate } from 'App/Internationalization';
import AgentForm from './AgentForm';
import GeneratePasswordReset from 'shared/Links/GeneratePasswordReset';
import ImageUploadForm from '../../../../shared/Forms/ImageUploadForm';
import Loading from '../../../../shared/Loading';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import paths from 'config/local/paths';
import React from 'react';
import ShareableLinks from 'shared/ShareableLinks';

const successMessage = translate('admin.pages.agents.edit.success_message');

/** @extends {React.Component<Props, State>} */
export default class EditAgent extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);
		/** @type {State} */
		this.state = {
			agentData: null,
			agentId: null,
			error: '',
			file: null,
			headshot: null,
			isGettingData: true,
			success: false,
			userId: '',
		};

		this.handleFileUploadChange = this.handleFileUploadChange.bind(this);
		this.handleSubmitDetails = this.handleSubmitDetails.bind(this);
		this.handleSubmitFile = this.handleSubmitFile.bind(this);
	}

	render() {
		if (this.state.success) {
			return (
				<Redirect
					to={{
						pathname: paths.app.admin.agents.view,
						state: { message: successMessage },
					}}
				/>
			);
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		const registrationBase = `${window.location.protocol}//${window.location.hostname}/${this.props.match.params.agentSlug}/register`;

		return (
			<React.Fragment>
				<h1 className="hl-ms-6 mt-10 mb-6">
					{`${this.state.agentData?.firstName ?? ''} ${
						this.state.agentData?.lastName ?? ''
					}`.trim()}
				</h1>

				<section className="mb-8">
					<ShareableLinks
						links={[
							{
								label: translate(
									'registration.links.homeowner'
								),
								url: `${registrationBase}/homeowner`,
							},
						]}
						title={translate('registration.links.title')}
					/>
				</section>

				<section className="mb-8">
					<h2 className="h-ms-5">
						{translate('admin.pages.agents.form.agent_title')}
					</h2>

					<AgentForm
						error={this.state.error}
						hasStatus={true}
						initialValues={this.state.agentData}
						onChange={this.handleFileUploadChange}
						onSubmit={this.handleSubmitDetails}
						submitText={translate('global.forms.buttons.save')}
						success={this.state.success}
					/>
				</section>

				<section className="mb-8">
					<ImageUploadForm
						label={translate('global.forms.inputs.image.title')}
						name="headshot"
						onChange={this.handleFileUploadChange}
						onSubmit={this.handleSubmitFile}
						preview={this.state.headshot}
						submit={translate('global.forms.buttons.save')}
					/>
				</section>

				{this.#renderGeneratePassword() ? (
					// @ts-ignore do not need to pass in auth service here
					<GeneratePasswordReset userId={this.state.userId} />
				) : null}
			</React.Fragment>
		);
	}

	async componentDidMount() {
		// TODO: switch to agent ids instead of slug when route supports
		if (this.props.match.params.agentSlug) {
			const agentData = await this.props.agentService.getAgentBySlug(
				this.props.match.params.agentSlug
			);

			const primaryGroup = agentData.groups?.length
				? agentData.groups.find(
						/**
						 * @param {{id: string, isPrimary: boolean, name: string}} group
						 * @returns {boolean}
						 */ (group) => group.isPrimary
				  )?.id
				: null;

			agentData.active = agentData.active ? 'active' : 'disabled';

			this.setState({
				agentData: { ...agentData, primaryGroup },
				agentId: agentData.id,
				headshot: agentData.headshot?.src ?? '',
				isGettingData: false,
				userId: agentData.userId,
			});
		}
	}

	/**
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 */
	handleFileUploadChange(event) {
		if (!event?.target?.files?.length) {
			return;
		}

		this.setState({ file: event.target.files[0] });
	}

	/** @param {State['agentData']} data */
	async handleSubmitDetails(data) {
		try {
			await this.props.agentService.updateAgentData(
				data,
				this.state.agentId ?? ''
			);
		} catch (error) {
			this.setState({
				error:
					error instanceof Error
						? error.message
						: translate('global.error'),
			});
			return;
		}

		this.setState({ success: true });
	}

	async handleSubmitFile() {
		if (this.state.file) {
			try {
				validateFile(this.state.file, mimeList.images, 'image');
			} catch (error) {
				this.setState({ error: translate('global.error') });
				return;
			}
		}
		try {
			await this.props.agentService.updateAgentHeadshot(
				this.state.agentId ?? '',
				this.state.file || undefined
			);
		} catch (error) {
			this.setState({ error: translate('global.error') });
			return;
		}

		this.setState({ success: true });
	}

	/**
	 * @returns {boolean}
	 */
	#renderGeneratePassword() {
		if (hasCapability(this.context, 'create_password_reset_url')) {
			return true;
		}

		return false;
	}
}

EditAgent.contextType = AuthContext;
