/**
 * @typedef {import('./Parameters').default} QueryParameters
 */

export { default as Filters } from './Filters/Filters';
export { default as Pagination } from './Pagination/Pagination';
export { default as Sort } from './Sort/Sort';

/**
 * @param {URLSearchParams} query
 * @param {string} prefix
 * @returns {string}
 */
function queryToString(query, prefix) {
	const asString = query.toString();

	return asString ? prefix + asString : '';
}

/**
 * @template {boolean} [IsString=false]
 * @param {URLSearchParams} query
 * @param {IsString} toString
 * @param {string} prefix
 * @returns {IsString extends true ? string : URLSearchParams}
 */
function cast(query, toString, prefix) {
	// @ts-ignore Matches return type, TS not reconciling conditional return type
	return toString ? queryToString(query, prefix) : query;
}

/**
 * @template {boolean} [IsString=false]
 * @param {QueryParameters | URLSearchParams} query
 * @param {IsString} [toString]
 * @param {string} [prefix]
 * @returns {IsString extends true ? string : URLSearchParams}
 */
// @ts-ignore IsString is a boolean
export function prepareQueryForUrl(query, toString = false, prefix = '?') {
	if (query instanceof URLSearchParams) {
		return cast(query, toString, prefix);
	}

	/** @type {{ [Key in string]: string }} */
	const params = {};
	const fieldList = Object.entries(query);

	if (fieldList.length === 0) {
		// @ts-ignore Matches return type, TS not reconciling conditional return type
		return toString ? '' : new URLSearchParams();
	}

	fieldList.forEach(([key, value]) => {
		if (value === undefined) {
			return;
		}

		params[key] = value?.toString() ?? 'null';
	});

	return cast(new URLSearchParams(params), toString, prefix);
}
