import React from 'react';
import mergeClassNames from '../../utils/mergeClassNames';

export default class HelpMessage extends React.Component {
	/**
	 * @param {React.ComponentProps<any>} props
	 */
	constructor(props) {
		super(props);

		this.margin = 2;
	}

	/**
	 * @returns {React.ReactElement | null}
	 */
	render() {
		if (!this.props.message) {
			return null;
		}

		return (
			<span
				className={mergeClassNames(
					`help-message text-sm mb-${this.margin}`,
					this.props.className
				)}
				dangerouslySetInnerHTML={{ __html: this.props.message }}
			/>
		);
	}
}

HelpMessage.defaultProps = {
	message: [],
};
