/**
 * @typedef {import('../../shared/OfferForm/OfferFields').default} OfferFields
 * @typedef {import('../../shared/OfferService/AddsAgentOffer').default} AddsAgentOffer
 * @typedef {import('../../shared/OfferService/AddsOffer').default} AddsOffer
 * @typedef {import('../../shared/OfferService/AddsOfferImage').default} AddsOfferImage
 * @typedef {import('../../shared/OfferService/AddsGroupOffer').default} AddsGroupOffer
 * @typedef {import('../../shared/OfferService/EditsOffer').default} EditsOffer
 * @typedef {import('../../shared/OfferService/GetsAgentOffers').default} GetsAgentOffers
 * @typedef {import('../../shared/OfferService/GetsGroupOffers').default} GetsGroupOffers
 * @typedef {import('./ImageData')} ImageData
 * @typedef {AddsAgentOffer & AddsGroupOffer & GetsAgentOffers & GetsGroupOffers & EditsOffer & AddsOfferImage} OfferService
 * @typedef {import('./AddOfferState').default} State
 */
import { file as validateFile } from '../../../shared/validators';
import { Redirect } from 'react-router-dom';
import { translate } from 'App/Internationalization';
import { withOfferService } from 'service-container';
import captureError from '../../../utils/captureError';
import categoryList from '@mooveguru/yhh-shared-config/marketplace-categories.json';
import FormErrorMessages from '../../../shared/Forms/Messages/FormErrorMessages';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import OfferForm from 'App/shared/OfferForm/OfferForm';
import paths from 'config/local/paths';
import React from 'react';

const successMessage = translate('agent.pages.offers.success_message');

/** @extends {React.Component<{ offerService: OfferService }>} */
export class AddOffer extends React.Component {
	/**
	 * @param {{ offerService: OfferService }} props
	 */
	constructor(props) {
		super(props);

		/**
		 * @type {State}
		 */
		this.state = {
			errorMessage: null,
			formComplete: false,
			image: null,
		};

		this.handleAddOfferSubmit = this.handleAddOfferSubmit.bind(this);
		this._handleFileUploadChange = this._handleFileUploadChange.bind(this);
	}

	/**
	 * @param {OfferFields} values
	 * @returns {Promise<void>}
	 */
	async handleAddOfferSubmit(values) {
		try {
			await this.submitAddOffer(values);
		} catch (error) {
			captureError(error);
		}
	}

	/**
	 * @param {OfferFields} offer
	 * @returns {Promise<void>}
	 */
	async submitAddOffer(offer) {
		const offerId = await this.props.offerService.addAgentOffer(offer);
		await this.#handleImageSubmit(offerId);
		this.setState({ formComplete: true });
	}

	/**
	 * @param {string} offerId
	 * @returns {Promise<void>}
	 */
	async #handleImageSubmit(offerId) {
		if (!this.state.image) {
			return;
		}
		validateFile(this.state.image, mimeList.images, 'image');

		try {
			await this.props.offerService.addOfferImage(
				this.state.image,
				offerId
			);
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate(
					'admin.pages.offers.image_upload.error'
				),
			});
		}
	}

	/**
	 * @protected
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @returns {void}
	 */
	_handleFileUploadChange(event) {
		if (!event?.target?.files?.length) {
			return;
		}

		this.setState({ image: event.target.files[0] });
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const viewRoute = paths.app.agent.offers.view;

		if (this.state.formComplete) {
			return (
				<Redirect
					to={{
						pathname: viewRoute,
						state: { message: successMessage },
					}}
				/>
			);
		}

		const initialValues = {
			category: Object.keys(categoryList)[0] ?? '',
			offerDescription: '',
			offerImage: '',
			offerName: '',
			offerUrl: '',
			offerZip: '',
			status: true,
			vendorEmail: '',
			vendorName: '',
			vendorPhone: '',
		};

		return (
			<React.Fragment>
				<OfferForm
					cancelPath={viewRoute}
					formSubmitText={translate('global.offers.add')}
					formTitle={translate('global.offers.add_title')}
					hasGroupField={false}
					hasImageField={false}
					// @ts-ignore -- declared
					initialValues={initialValues}
					onChange={this._handleFileUploadChange}
					onSubmit={this.handleAddOfferSubmit}
				/>
				<FormErrorMessages messages={this.state.errorMessage} />
			</React.Fragment>
		);
	}
}

// @ts-ignore -- will be updated with HOCs
export default withOfferService(AddOffer);
