import { translate } from 'App/Internationalization';
import { withAgentService } from 'service-container';
import { withSettings } from './SettingProvider/SettingProvider';
import captureError from 'utils/captureError';
import paths from '../config/local/paths';
import React from 'react';
import ShareableLink from './ShareableLink';

/**
 * @typedef {import('./ShareableLinksCardState').default} State
 * @typedef {import('./ShareableLinksCardProps').default} Props
 */

/** @extends {React.Component<Props, State>} */
export class ShareableLinksCard extends React.Component {
	/** @param {} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			slug: null,
		};
	}

	async componentDidMount() {
		try {
			const response =
				await this.props.agentService.getAgentPersonalData();

			this.setState({ slug: response.slug });
		} catch (error) {
			captureError(error);
		}
	}

	renderLinks() {
		const base = `${window.location.protocol}//${window.location.hostname}/${this.state.slug}`;

		const linkList = [
			{
				label: translate('agent.pages.dashboard.links.blog'),
				url: base + paths.blog,
			},
			{
				label: translate('agent.pages.dashboard.links.register'),
				url: base + paths.register.homeowner,
			},
			{
				label: translate('agent.pages.dashboard.links.sign_in'),
				url: base + paths.auth.signIn,
			},
		];

		if (this.props.settings.features.get('agent:public_pros')) {
			linkList.push({
				label: translate('agent.pages.dashboard.links.vendors'),
				url: base + paths.marketplace,
			});
		}

		return linkList.map((link) => (
			<ShareableLink
				className="mb-4"
				key={link.url}
				label={link.label}
				url={link.url}
			/>
		));
	}

	render() {
		return (
			<section className="mt-10">
				<h1 className="h-ms-3">
					{translate('agent.pages.dashboard.links.title')}
				</h1>
				<ul className="sharable-urls-list list-group mt-4">
					{this.renderLinks()}
				</ul>
			</section>
		);
	}
}

export default withAgentService(withSettings(ShareableLinksCard));
