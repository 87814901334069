import categories from '@mooveguru/yhh-shared-config/document-categories.json';
import DocumentTable from './DocumentTable';
import React from 'react';

export default class DocumentCategoryList extends React.Component {
	render() {
		const tables = Object.entries(categories)
			.sort((a, b) => a[1].localeCompare(b[1]))
			.map(([slug, title]) => (
				<DocumentTable
					deleteDocument={this.props.deleteDocument}
					documents={this.props.categories[title] ?? []}
					downloadOnClick={this.props.downloadOnClick}
					key={slug}
					slug={slug.replaceAll('_', '-')}
					title={title}
				/>
			));

		return tables;
	}
}
