// typedef in this structure so that it works with @template
/** @template Values */
/** @typedef {import('../../../Agent/agent-service').Contact} Contact */
/** @typedef {import('./CreateContactProps').default} Props */
/** @typedef {import('./CreateContactState').default} State */
/** @typedef {import('formik').FormikProps<Values>} FormikProps */

import { AuthContext } from '../../../../shared/AuthProvider';
import { Redirect } from 'react-router-dom';
import { translate } from '../../../Internationalization';
import captureError from '../../../../utils/captureError';
import concatPaths from '../../../../utils/contactPaths';
import ContactForm from './ContactForm';
import ContactLimitPopup from '../../../shared/ContactLimitPopup/ContactLimitPopup';
import errors from '../../../../config/local/errors';
import Loading from '../../../../shared/Loading';
import paths from '../../../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export default class CreateContact extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		/**
		 * @type {State}
		 */
		this.state = {
			agents: [],
			errorMessage: '',
			formComplete: false,
			group: '',
			groupAgentList: null,
			groupId: '',
			isGettingData: true,
			showLimitContactPopup: false,
		};

		this.getCorrespondingAgents = this.getCorrespondingAgents.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this._handleLimitContactClose =
			this._handleLimitContactClose.bind(this);
		this._handleSubmit = this._handleSubmit.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		const allowedGroups =
			await this.props.administratorService.getAllowedGroups(50);

		this.setState({
			groupAgentList: allowedGroups,
			isGettingData: false,
		});
	}

	/**
	 * @protected
	 * @param {Contact & { readonly sendInvite?: 'yes' | 'no', readonly groupId: string }} data
	 * @returns {Promise<void>}
	 */
	async _handleSubmit(data) {
		try {
			await this.props.contactService.createContact(
				this.context.accessToken,
				data,
				'Manager added',
				data.sendInvite !== 'no'
			);
			this.setState({
				errorMessage: '',
				formComplete: true,
			});
		} catch (error) {
			// @ts-ignore -- status code added to error obj
			if (error?.statusCode === errors.overContactLimitError) {
				this.setState({
					groupId: data.groupId,
					showLimitContactPopup: true,
				});
				return;
			}
			captureError(error);
			this.setState({
				errorMessage: 'admin.pages.contacts.add.error',
			});
		}
	}

	/**
	 * @param {string} groupId
	 * @returns {Promise<void>}
	 */
	async getCorrespondingAgents(groupId) {
		try {
			const response =
				await this.props.administratorService.getAgentsByGroupIds(
					groupId
				);
			this.setState({
				agents: response,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: 'admin.pages.contacts.add.retrieve_agents_error',
			});
		}
	}

	/**
	 * @param {React.ChangeEvent<HTMLInputElement|HTMLSelectElement>} event
	 * @param { FormikProps} formik
	 * @returns {Promise<void>}
	 */
	async handleChange(event, formik) {
		formik.setFieldValue(event.target.name, event.target.value);
		await this.getCorrespondingAgents(event.target.value);
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleLimitContactClose() {
		this.setState({ showLimitContactPopup: false });
	}

	/**
	 * @protected
	 * @returns {JSX.Element}
	 */
	static _renderContactPopupContent() {
		const contactCap = process.env.REACT_APP_CONTACT_CAP ?? '100';

		return (
			<React.Fragment>
				<p>
					{translate(`global.contact_limit.add.header`, contactCap)}{' '}
					<span className="font-bold">
						{translate('global.contact_limit.add.body')}
					</span>
				</p>

				<p>
					{translate(
						`global.contact_limit.add.group_footer`,
						contactCap
					)}
				</p>
			</React.Fragment>
		);
	}

	/** @returns {JSX.Element} */
	render() {
		if (this.state.formComplete) {
			return (
				<Redirect
					to={{
						pathname: paths.app.admin.contacts.view,
						state: {
							message: translate(
								'admin.pages.contacts.add.success_message'
							),
						},
					}}
				/>
			);
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}
		return (
			<React.Fragment>
				<ContactLimitPopup
					onClose={this._handleLimitContactClose}
					showPopup={this.state.showLimitContactPopup}
					title={translate(`global.contact_limit.add.title`)}
					to={concatPaths(
						paths.app.admin.groups.root,
						`/${this.state.groupId}/subscription`
					)}
				>
					{CreateContact._renderContactPopupContent()}
				</ContactLimitPopup>

				<ContactForm
					agents={this.state.agents}
					contactData={{ sendInvite: 'yes' }}
					create={true}
					editText={translate('admin.pages.contacts.add.add')}
					error={this.state.errorMessage}
					formTitle={translate('admin.pages.contacts.add.title')}
					group={this.state.group}
					groups={this.state.groupAgentList}
					onChange={this.handleChange}
					onSubmit={this._handleSubmit}
				/>
			</React.Fragment>
		);
	}
}

CreateContact.contextType = AuthContext;
