/**
 * @typedef {import('../../shared/PropertyService/Valuation').default} Valuation
 * @typedef {import('../../shared/PropertyService/PropertyService').default} PropertyService
 * @typedef {import('./PropertyValuationsComponent').Props} Props
 * @typedef {import('./PropertyValuationsComponent').State} State
 */
import { withProperty } from '../PropertyProvider';
import { withPropertyService } from 'service-container';
import CarouselCard from '../shared/CarouselCard/CarouselCard';
import Loading from '../../../shared/Loading';
import React from 'react';
import PropertyValuationGraph from './PropertyValuationGraph';
import captureError from '../../../utils/captureError';
import { translate } from '../../Internationalization';
import NoContent from '../../../shared/NoContent';

/** @extends {React.Component<Props, State>} */
class PropertyValuations extends React.Component {
	static defaultProps = {
		withCarousel: true,
	};

	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		/**
		 * @type {State}
		 */
		this.state = {
			isGettingData: true,
			valuations: null,
		};
	}

	async componentDidMount() {
		try {
			const propertyId = this.props.property?.selectedProperty?.id;
			await this.#setValuations(propertyId);

			this.setState({
				isGettingData: false,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {Props} prevProps
	 * @returns {Promise<void>}
	 */
	async componentDidUpdate(prevProps) {
		if (
			this.props.property?.selectedProperty?.id !==
			prevProps.property?.selectedProperty?.id
		) {
			await this.#setValuations(
				this.props.property?.selectedProperty?.id
			);
		}
	}

	/**
	 * @param {string | undefined} propertyId
	 * @returns {Promise<void>}
	 */
	async #setValuations(propertyId) {
		if (!propertyId) {
			this.setState({
				valuations: null,
			});
			return;
		}

		const valuations = await this.props.propertyService.getValuations(
			propertyId
		);

		this.setState({
			valuations,
		});
	}

	/**
	 * @returns {boolean}
	 */
	#hasValuations() {
		if (this.state.valuations === null) {
			return false;
		}

		if (this.state.valuations.size < 1) {
			return false;
		}

		for (const [, valuationList] of this.state.valuations) {
			if (valuationList.length > 0) {
				return true;
			}
		}

		return false;
	}

	/**
	 * @returns {JSX.Element |null}
	 */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		const header = (
			<h2 className="h-ms-4 mb-6">
				{translate('homeowner.pages.finances.view.estimate.title')}
			</h2>
		);

		if (!this.#hasValuations()) {
			return (
				<section>
					{header}
					<NoContent />
				</section>
			);
		}

		if (this.props.withCarousel) {
			return (
				<section className="mt-4">
					{header}

					<CarouselCard values={this.state.valuations} />
				</section>
			);
		}

		return <PropertyValuationGraph valuations={this.state.valuations} />;
	}
}

export default withPropertyService(withProperty(PropertyValuations));
