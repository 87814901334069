import { translate } from 'App/Internationalization';
import images from 'config/local/images';
import LinkButton from 'shared/LinkButton';
import paths from 'config/local/paths';
import React from 'react';

export default class GenericContactsOverlay extends React.Component {
	render() {
		return (
			<section className="my-5 message message-themed-light">
				<header className="header">
					<h2 className="title large">
						{translate(
							'agent.pages.contacts.view.upgrade_table.popup.title'
						)}
					</h2>
				</header>
				<main className="container-sm">
					<ul className="list-group">
						<li
							dangerouslySetInnerHTML={{
								__html: translate(
									'agent.pages.contacts.view.upgrade_table.popup.gain'
								),
							}}
						></li>
						<li
							dangerouslySetInnerHTML={{
								__html: translate(
									'agent.pages.contacts.view.upgrade_table.popup.activity'
								),
							}}
						></li>
						<li
							dangerouslySetInnerHTML={{
								__html: translate(
									'agent.pages.contacts.view.upgrade_table.popup.marketing'
								),
							}}
						></li>
					</ul>
				</main>
				<footer className="footer">
					<LinkButton
						className="button button-icon"
						icon={images.icons.subscription}
						iconClass="icon"
						to={paths.app.agent.subscription.root}
					>
						{translate(
							'agent.pages.contacts.view.upgrade_table.upgrade'
						)}
					</LinkButton>
				</footer>
			</section>
		);
	}
}
