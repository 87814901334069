/**
 * @typedef {import('./AccessTokenPayload').default} AccessTokenPayload
 * @typedef {import('./GeneratePasswordResetUrl').default} GeneratePasswordResetUrl
 * @typedef {import('./GetsAccessToken').default} GetsAccessToken
 * @typedef {import('./RefreshesSignIn').default} RefreshesSignIn
 * @typedef {import('./ResetsPassword').default} ResetsPassword
 * @typedef {import('./SignsIn').default} SignsIn
 * @typedef {import('./SignInWithSAML').default} SignsInWithSsoAnywhere
 * @typedef {import('./SignsInWithSso').default} SignsInWithSso
 * @typedef {import('./SignsOut').default} SignsOut
 * @typedef {import('./SignsUp').default} SignsUp
 * @typedef {import('@mooveguru/js-http-client').BrowserClient} HttpClient
 */
import apiUrls from '../../config/local/api-urls';
import DataLayer from '../../DataLayer';
import getJwtPayload from '../get-jwt-payload';
import time from '../../config/local/time';

/**
 * @implements {GeneratePasswordResetUrl}
 * @implements {GetsAccessToken}
 * @implements {RefreshesSignIn}
 * @implements {ResetsPassword}
 * @implements {SignsIn}
 * @implements {SignsInWithSsoAnywhere}
 * @implements {SignsInWithSso}
 * @implements {SignsOut}
 * @implements {SignsUp}
 */
export default class AuthService {
	/** @param {HttpClient} httpClient */
	constructor(httpClient) {
		this.accessToken = null;
		this.baseHeaders = new Headers({ 'Content-Type': 'application/json' });
		this.httpClient = httpClient;
	}

	/**
	 * @param {string} token
	 * @returns {Promise<void>}
	 */
	async confirmEmail(token) {
		const response = await this.httpClient.post(
			apiUrls.auth.confirmEmail,
			new Headers(this.baseHeaders),
			{ token }
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}
	}

	/**
	 * @param {string} userId
	 * @returns {Promise<AccessTokenPayload>}
	 */
	async refreshSignIn(userId) {
		const response = await this.httpClient.post(
			apiUrls.auth.refreshSignIn,
			new Headers(this.baseHeaders),
			// eslint-disable-next-line camelcase
			{ user_id: userId },
			true
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		this.accessToken = response.body.results.accessToken;
		return this.extractPayloadData(this.accessToken);
	}

	/**
	 * @param {string} email
	 * @returns {Promise<void>}
	 */
	async resetPassword(email) {
		const response = await this.httpClient.post(
			apiUrls.auth.resetPassword,
			new Headers(this.baseHeaders),
			{ email }
		);

		if (!response.isOk) {
			throw Error(response.body.message);
		}
	}

	/**
	 * @param {string} email
	 * @param {string} password
	 * @returns {Promise<AccessTokenPayload>}
	 */
	async signIn(email, password) {
		const response = await this.httpClient.post(
			apiUrls.auth.signIn,
			new Headers(this.baseHeaders),
			{ email, password },
			true
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		this.accessToken = response.body.results.accessToken;
		const payload = this.extractPayloadData(this.accessToken);

		DataLayer.add({
			event: 'UserLogin',
			userEmail: email,
			userId: payload.userId,
		});

		return payload;
	}

	/**
	 * @param {string} token
	 * @returns {Promise<AccessTokenPayload>}
	 */
	async signInWithSso(token) {
		const response = await this.httpClient.post(
			apiUrls.auth.signInWithSso,
			new Headers(this.baseHeaders),
			{ token },
			true
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		this.accessToken = response.body.results.accessToken;
		const payload = this.extractPayloadData(this.accessToken);

		DataLayer.add({
			event: 'UserLoginSSO',
			userId: payload.userId,
		});

		return payload;
	}

	/**
	 * @param {string} token
	 * @param {string} [from]
	 * @returns {AccessTokenPayload}
	 */
	signInWithSAML(token, from = 'SAML') {
		this.accessToken = token;
		const payload = this.extractPayloadData(this.accessToken);

		DataLayer.add({
			event: 'UserLoginSSO',
			ssoFrom: from,
			userId: payload.userId,
		});

		return payload;
	}

	/**
	 * @param {string} email
	 * @param {string} password
	 * @returns {Promise<AccessTokenPayload>}
	 */
	async signUp(email, password) {
		const response = await this.httpClient.post(
			apiUrls.auth.signUp,
			new Headers(this.baseHeaders),
			{ email, password },
			true
		);

		if (!response.isOk) {
			throw new Error(response.body.message);
		}

		DataLayer.add({ event: 'UserLoginRegistration', userEmail: email });

		this.accessToken = response.body.results.accessToken;
		return this.extractPayloadData(this.accessToken);
	}

	signOut() {
		DataLayer.event('UserSignOut');

		this.accessToken = null;
	}

	/**
	 * @param {string} accessToken
	 * @returns {AccessTokenPayload}
	 */
	// eslint-disable-next-line class-methods-use-this -- used outside this file, causes errors
	extractPayloadData(accessToken) {
		const payload = getJwtPayload(accessToken);

		return {
			accessToken: {
				expiration: AuthService.createAccessTokenExpiration(
					payload.exp ?? 0
				),
				value: accessToken,
			},
			aud: payload.aud ?? '',
			roles: payload.roles,
			userId: payload._id,
		};
	}

	/**
	 * @param {number} exp
	 * @returns {Date}
	 */
	static createAccessTokenExpiration(exp) {
		return new Date(exp * time.millisecondsPerSecond);
	}

	getAccessToken() {
		if (this.accessToken === null) {
			throw new Error('User is not logged in.');
		}

		return this.accessToken;
	}

	/**
	 * @param {string} userId
	 * @returns {Promise<string>}
	 */
	async generatePasswordResetUrl(userId) {
		const response = await this.httpClient.post(
			apiUrls.user.resetPassword.replace(':userId', userId),
			new Headers({
				Authorization: `Bearer ${this.getAccessToken()}`,
				'Content-Type': 'application/json',
			})
		);

		if (!response.isOk) {
			throw new Error(response.body[0]);
		}

		return response.body.url;
	}
}
