/**
 * @typedef {import('./CheckoutComponent').default} Props
 */

import { translate } from '../../../Internationalization';
import { withTheme } from '../../../../shared/ThemeProvider';
import formatCentsForDisplay from '../../../../utils/formatCentsForDisplay';
import React from 'react';

/** @extends {React.Component<Props>} */
export class SubscriptionDetails extends React.Component {
	/**
	 * @returns {JSX.Element | null}
	 */
	static #renderCoBranding() {
		const cta = 'subscription.cta';
		return (
			<div className="border rounded-lg border-theme-light">
				<section className="m-3">
					<h3 className="h-ms-5">
						{translate(
							`${cta}.go_premium.feature.title_cobranding`
						)}
					</h3>
					<p
						className="text-left mt-4"
						// eslint-disable-next-line react/no-danger -- `dangerouslySetInnerHTML` is needed for display text with html formatting.
						dangerouslySetInnerHTML={{
							__html: translate(
								`${cta}.go_premium.feature.branding_description`
							),
						}}
						// eslint-enable-next-line react/no-danger
					/>
					<p className="my-8 text-left">
						{translate(
							`${cta}.go_premium.feature.branding_description_1`
						)}
					</p>
					<p className="my-8 text-left">
						{translate(
							`${cta}.go_premium.feature.branding_description_2`
						)}
					</p>
				</section>
			</div>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const cta = 'subscription.cta';
		const contactCap = process.env.REACT_APP_CONTACT_CAP ?? '100';

		return (
			<section className="upgrade-page">
				<header className="top-banner-area">
					<div className="banner-area">
						<header className="upgrade-banner">
							<h1 className="hl-ms-4">
								<p
									// eslint-disable-next-line react/no-danger -- `dangerouslySetInnerHTML` is needed for display text with html formatting.
									dangerouslySetInnerHTML={{
										__html: translate(
											`${cta}.go_premium.cta_2_title`
										),
									}}
									// eslint-enable-next-line react/no-danger
								/>
							</h1>
							<p>
								{translate(
									`${cta}.go_premium.cta_2_description`,
									this.props.theme.appTitle
								)}
							</p>
						</header>
					</div>
					<div className="cta-area">
						<section className="upgrade-cta h-full">
							<h2 className="title">
								{translate(`${cta}.go_premium.upgrade_premium`)}
							</h2>
							<h2 className="hl-ms-5">
								{translate(
									`${cta}.go_premium.price_per_month`,
									formatCentsForDisplay(
										this.props.pricing.priceInCents
									)
								)}
							</h2>
							{this.props.children}
						</section>
					</div>
				</header>
				<section className="features-area">
					<h3 className="header">
						{translate(`${cta}.go_premium.feature.title`)}
					</h3>
					<section className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
						<div className="border rounded-lg border-theme-light">
							<section className="m-3">
								<h3 className="h-ms-5">
									{translate(
										`${cta}.go_premium.feature.title_mover`
									)}
								</h3>
								<p className="text-left mt-4">
									{translate(
										`${cta}.go_premium.feature.mover_description`
									)}
								</p>
								<p className="my-8 text-left">
									{translate(
										`${cta}.go_premium.feature.mover_description_1`,
										translate(
											`${cta}.go_premium.feature.mover_description_1_lead`
										)
									)}
								</p>
								<p
									className="my-8 text-left"
									// eslint-disable-next-line react/no-danger -- `dangerouslySetInnerHTML` is needed for display text with html formatting.
									dangerouslySetInnerHTML={{
										__html: translate(
											`${cta}.go_premium.feature.mover_description_2`,
											translate(
												`${cta}.go_premium.feature.mover_description_2_lead`
											)
										),
									}}
									// eslint-enable-next-line react/no-danger
								/>
							</section>
						</div>
						{SubscriptionDetails.#renderCoBranding()}
						<section className="border rounded-lg border-theme-light">
							<div className="m-3">
								<h3 className="h-ms-5">
									{translate(
										`${cta}.go_premium.feature.title_contacts`
									)}
								</h3>
								<p
									className="text-left mt-4"
									// eslint-disable-next-line react/no-danger -- `dangerouslySetInnerHTML` is needed for display text with html formatting.
									dangerouslySetInnerHTML={{
										__html: translate(
											`${cta}.go_premium.feature.contacts_description`,
											contactCap,
											formatCentsForDisplay(
												this.props.pricing
													.contactPriceInCents
											)
										),
									}}
									// eslint-enable-next-line react/no-danger
								/>
							</div>
						</section>
					</section>
				</section>
				<footer className="bottom-banner-area">
					<section className="upgrade-cta horizontal">
						<header className="description-area">
							<h2 className="title large">
								{translate(`${cta}.go_premium.upgrade_premium`)}
							</h2>
							<p className="mt-4">
								{translate(
									`${cta}.go_premium.cta_2_description`,
									this.props.theme.appTitle
								)}
							</p>
						</header>
						<footer className="pricing-area">
							<h2 className="hl-ms-4 mb-3">
								{translate(
									`${cta}.go_premium.price_per_month`,
									formatCentsForDisplay(
										this.props.pricing.priceInCents
									)
								)}
							</h2>
							{this.props.children}
						</footer>
					</section>
				</footer>
			</section>
		);
	}
}

export default withTheme(SubscriptionDetails);
