import { AuthContext } from '../../../shared/AuthProvider';
import { translate } from '../../../App/Internationalization';
import { withBreakpoint } from '../../../shared/BreakpointProvider';
import * as validators from '../../../shared/validators';
import * as yup from 'yup';
import AddressFields from '../../../shared/Forms/Fields/Address/AddressFields';
import BaseButton from '../../../shared/BaseButton/BaseButton';
import BaseForm from '../../../shared/Forms/BaseForm';
import FieldFile from '../../../shared/Forms/Fields/FieldFile';
import FieldTitle from '../../../shared/Forms/Fields/FieldTitle';
import FormField from '../../../shared/Forms/Fields/FormField';
import images from '../../../config/local/images';
import InlineSVG from 'react-inlinesvg/esm';
import LinkButton from '../../../shared/LinkButton';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import paths from '../../../config/local/paths';
import PropertyDetailFields from '../../../shared/Forms/Fields/PropertyDetails/PropertyDetailFields';
import PropertyImage from '../Dashboard/PropertyCard/PropertyImage/PropertyImage';
import React from 'react';
import SubmitButton from '../../../shared/Forms/Inputs/SubmitButton';
import uploadSize from '@mooveguru/yhh-shared-config/files/max-upload.json';

const validationSchema = yup.object().shape({
	acreage: validators.onlyNumber,
	amountToLoan: validators.onlyNumber,
	bedrooms: validators.onlyNumber,
	buildYear: validators.onlyNumber,
	city: validators.city.required('City is Required'),
	constructionType: validators.onlyString,
	country: validators.country.required('Country is Required'),
	dueDate: validators.onlyDate,
	fireplace: validators.onlyString,
	foundationType: validators.onlyString,
	garage: validators.onlyString,
	heating: validators.onlyString,
	heatingFuelType: validators.onlyString,
	lenderName: validators.onlyString,
	loanType: validators.onlyString,
	lotSizeSquareFootage: validators.onlyNumber,
	maximumInterestRate: validators.onlyNumber,
	numberOfBaths: validators.onlyNumber,
	numberOfPartialBaths: validators.onlyNumber,
	originalDateOfContract: validators.onlyDate,
	otherRooms: validators.onlyNumber,
	pool: validators.onlyString,
	postalCode: validators.postalCode.required('Postal Code is Required'),
	rate: validators.onlyNumber,
	roof: validators.onlyString,
	sewer: validators.onlyString,
	state: validators.state.required('State is Required'),
	stories: validators.onlyNumber,
	streetAddress1: validators.streetAddress.required(
		'Street Address is Required'
	),
	streetAddress2: validators.streetAddress.nullable(),
	title: validators.propertyTitle.notRequired(),
	water: validators.onlyString,
});

class PropertyForm extends React.Component {
	static defaultProps = {
		image: null,
		initialValues: {
			acreage: '',
			amountToLoan: '',
			bedrooms: '',
			buildYear: '',
			city: '',
			constructionType: '',
			dueDate: '',
			fireplace: '',
			foundationType: '',
			garage: '',
			heating: '',
			heatingFuelType: '',
			isPrimary: false,
			lenderName: '',
			loanType: '',
			lotSizeSquareFootage: '',
			maximumInterestRate: '',
			numberOfBaths: '',
			numberOfPartialBaths: '',
			originalDateOfContract: '',
			otherRooms: '',
			pool: '',
			postalCode: '',
			rate: '',
			roof: '',
			sewer: '',
			state: '',
			stories: '',
			streetAddress1: '',
			streetAddress2: '',
			title: '',
			water: '',
		},
		showDetailsFields: true,
		showImageField: true,
	};

	/**
	 * @returns {JSX.Element | null}
	 */
	#showFileField() {
		return this.props.showImageField ? (
			<FormField
				className="input-label h-ms"
				label={translate(
					'homeowner.nav.properties.update_property_image_label'
				)}
			>
				<FieldFile
					accept={Object.values(mimeList.images)}
					className="flex-1"
					maxSize={uploadSize.image}
					name="image"
					onChange={this.props.onChange}
					preview={this.props.image ?? ''}
				/>
			</FormField>
		) : null;
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	#showDetailsFields() {
		return this.props.showDetailsFields ? <PropertyDetailFields /> : null;
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	#showDeleteButton() {
		return this.props.image ? (
			<BaseButton className="ml-4" onClick={this.props.handleDeleteImage}>
				{translate('global.forms.inputs.image.delete')}
			</BaseButton>
		) : null;
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const propertySections = {
			primary: (
				<section className="responsive-main app-block-list">
					<details className="mt-6 toggle-disclosure" open={true}>
						<summary className="justified-row mb-4">
							<legend className="h-ms-5 mb-6">
								{translate(
									'global.forms.inputs.address.section_title'
								)}
							</legend>
							<i className="icon input-icon toggle-disclosure-indicator">
								<InlineSVG
									src={images.icons.arrow.dropdown.small}
								/>
							</i>
						</summary>
						<fieldset className="grid lg:grid-cols-3 sm:grid-cols-2 gap-6 mb-8">
							<AddressFields
								postalCodeName="postalCode"
								required={true}
							/>
						</fieldset>
					</details>

					{this.#showDetailsFields()}
					<footer className="mx-auto sm:ml-0 button-group">
						<SubmitButton>
							{this.props.submitButtonContent}
						</SubmitButton>
						<LinkButton
							icon={images.icons.delete}
							size="large"
							to={
								this.props.cancelButtonReferrerPath ||
								paths.app.homeowner.properties.root
							}
						>
							{translate('global.forms.buttons.cancel')}
						</LinkButton>
					</footer>
				</section>
			),
			secondary: (
				<section className="responsive-secondary app-block-list">
					<FieldTitle className="mb-6" />
					<PropertyImage image={this.props.image} />
					{this.#showFileField()}
					<div className="w-fit mx-auto mb-6">
						{this.#showDeleteButton()}
					</div>
				</section>
			),
		};

		return (
			<React.Fragment>
				<h1 className="hl-ms-6 mt-10 mb-6">
					{this.props.headingContent}
				</h1>
				<BaseForm
					className="property-form-card"
					initialValues={this.props.initialValues}
					onSubmit={this.props.onSubmit}
					serverError={this.props.errorMessage}
					validationSchema={validationSchema}
				>
					<section className="app-responsive-area app-responsive-sidebar-grid">
						{this.props.isBelowBreakpoint
							? propertySections.secondary
							: propertySections.primary}
						{this.props.isBelowBreakpoint
							? propertySections.primary
							: propertySections.secondary}
					</section>
				</BaseForm>
			</React.Fragment>
		);
	}
}

export default withBreakpoint(PropertyForm);

PropertyForm.contextType = AuthContext;
