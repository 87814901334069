/** @typedef {import('./Props').default} Props */
/** @typedef {import('./State').default} State */

import { withBreakpoint } from '../../shared/BreakpointProvider';
import MobileNavBar from '../Navigation/MobileNavBar/MobileNavBar';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class SideDrawer extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @param {State} props */
		this.state = {
			isOpen: false,
		};

		this._handleCloseByEsc = this._handleCloseByEsc.bind(this);
		this._handleToggle = this._handleToggle.bind(this);
	}

	/** @returns {void} */
	componentDidMount() {
		document.addEventListener('keydown', this._handleCloseByEsc);
	}

	/**
	 * @param {Props} prevProps
	 * @returns {void}
	 */
	componentDidUpdate(prevProps) {
		if (
			this.props.isBelowBreakpoint !== prevProps.isBelowBreakpoint &&
			!this.props.isBelowBreakpoint
		) {
			this.setState({ isOpen: false });
		}

		if (this.state.isOpen) {
			document.addEventListener('keydown', this._handleCloseByEsc);
		} else {
			document.removeEventListener('keydown', this._handleCloseByEsc);
		}
	}

	/** @returns {void} */
	componentWillUnmount() {
		document.removeEventListener('keydown', this._handleCloseByEsc);
	}

	/**
	 * @protected
	 * @param {{key: string}} event
	 * @returns {void}
	 */
	_handleCloseByEsc(event) {
		if (event.key === 'Escape') {
			this.setState({
				isOpen: false,
			});
		}
	}

	/**
	 * @protected
	 * @returns {void}
	 */
	_handleToggle() {
		this.setState(
			/**
			 * @param {State} prevState
			 * @returns {State}
			 */
			(prevState) => ({
				isOpen: !prevState.isOpen,
			})
		);
	}

	/**  @returns {JSX.Element} */
	render() {
		// Tab indentation issue
		/*eslint-disable */
		const renderedChildren = React.Children.map(
			this.props.children,
			(child) =>
				React.isValidElement(child) && typeof child.type === 'function'
					? React.cloneElement(child, {
							...child.props,
							handleCloseSideDrawer: this._handleToggle,
					  })
					: child
		);
		/* eslint-enable */

		const toggleActive = this.state.isOpen ? 'active' : '';

		return (
			<React.Fragment>
				<MobileNavBar
					isOpen={this.state.isOpen}
					onClick={this._handleToggle}
				/>
				<nav
					aria-labelledby="side-menu-button"
					className={`app-navigation-menu ${toggleActive}`}
					id="side-menu-options"
				>
					{renderedChildren}
				</nav>
			</React.Fragment>
		);
	}
}

export default withBreakpoint(SideDrawer);
