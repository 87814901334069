/**
 * @typedef {import('./EditablePropertyDetails').default} EditablePropertyDetails
 */

import { mapRawPropertyDetails } from '../HomeownerService/mapPropertyDetails';

/**
 * Dot notated mapping of keys to set from returned property
 * @type {Map<string, string>}
 */
const fieldMap = new Map([
	['acreage', 'lot_size_acres'],
	['amountToLoan', 'mortgage.mtg_loan_amount'],
	['basement', 'basement'],
	['bedrooms', 'number_of_bedrooms'],
	['buildYear', 'year_built'],
	['constructionType', 'type_construction'],
	['dueDate', 'mortgage.mtg_due_date'],
	['fireplace', 'fireplace'],
	['floorCover', 'floor_cover'],
	['foundationType', 'foundation'],
	['garageCars', 'garage_cars'],
	['garageType', 'garage_type'],
	['heating', 'heating.heating'],
	['heatingFuelType', 'heating.heating_fuel_type'],
	['interiorWalls', 'interior_walls'],
	['lenderName', 'mortgage.mtg_lender_name'],
	['loanType', 'mortgage.mtg_loan_type'],
	['lotSizeSquareFootage', 'lot_size_square_feet'],
	['maximumInterestRate', 'mortgage.mtg_maximum_interest_rate'],
	['numberOfBaths', 'number_of_baths'],
	['numberOfPartialBaths', 'number_of_partial_baths'],
	['originalDateOfContract', 'mortgage.mtg_original_date_of_contract'],
	['otherRooms', 'other_rooms'],
	['plumbingFixtures', 'n_of_plumbing_fixtures'],
	['pool', 'pool'],
	['rate', 'mortgage.mtg_interest_rate'],
	['roofCover', 'roof_cover'],
	['roofType', 'roof_type'],
	['sewer', 'sewer'],
	['stories', 'no_of_stories'],
	['style', 'style'],
	['totalNumberOfRooms', 'total_number_of_rooms'],
	['water', 'water'],
]);

/**
 * @param {{ property: any; }} data
 * @returns {EditablePropertyDetails}
 */
export default function mapEditablePropertyDetails(data) {
	const { property } = data;
	const details = property?.details;

	return {
		address: {
			streetAddress1: property.address.street_address_1,
			streetAddress2: property.address.street_address_2,
			city: property.address.city,
			country: property.address.country,
			state: property.address.state,
			postalCode: property.address.postal_code,
		},
		// @ts-ignore properties are defined in `fieldMap`
		details: mapRawPropertyDetails(details, fieldMap),
		image: property.image
			? {
					id: property.image.id,
					path: property.image.path,
			  }
			: null,
		title: property.title ?? '',
	};
}
