/** @typedef {{children: React.ReactNode}} Props */

import React from 'react';

/** @extends {React.Component<Props>} */
export default class PageHeading extends React.Component {
	render() {
		return <h1 className="hl-ms-6">{this.props.children}</h1>;
	}
}
