/** @typedef {import('./NavigationItem').default} NavigationItem */

import { object } from '@mooveguru/js-utilities';
import { translate } from '../../App/Internationalization';
import images from './images';
import paths from './paths';
import roles from '@mooveguru/yhh-shared-config/users/roles.json';

const administratorNav = {
	linkList: [
		{
			icon: images.icons.dashboard,
			key: 'admin-dashboard',
			label: translate('admin.nav.main.dashboard'),
			to: paths.app.admin.dashboard,
		},
		{
			icon: images.icons.theme,
			key: 'admin-theme',
			label: translate('admin.nav.main.branding'),
			to: paths.app.admin.theme,
		},
		{
			icon: images.icons.offers,
			key: 'admin-vendors',
			label: translate('admin.nav.main.vendors'),
			to: paths.app.admin.offers.root,
		},
		{
			icon: images.icons.groups,
			key: 'admin-groups',
			label: translate('admin.nav.main.groups'),
			to: paths.app.admin.groups.root,
		},
		{
			icon: images.icons.admins,
			key: 'admin-admins',
			label: translate('admin.nav.main.admins'),
			to: paths.app.admin.admins.root,
		},
		{
			icon: images.icons.agents,
			key: 'admin-agents',
			label: translate('admin.nav.main.agents'),
			to: paths.app.admin.agents.root,
		},
		{
			icon: images.icons.homeowners,
			key: 'admin-homeowners',
			label: translate('admin.nav.main.homeowners'),
			to: paths.app.admin.homeowners.root,
		},
		{
			icon: images.icons.contacts,
			key: 'admin-contacts',
			label: translate('admin.nav.main.contacts'),
			to: paths.app.admin.contacts.root,
		},
	],
	mobileList: [
		'admin-dashboard',
		'admin-groups',
		'admin-agents',
		'admin-admins',
	],
};

const navLinkList = {
	owner: administratorNav,
	/* eslint-disable-next-line sort-keys -- Keys reflect navigation order */
	admin: {
		linkList: administratorNav.linkList.filter(
			(link) => link.key !== 'admin-theme'
		),
		mobileList: administratorNav.mobileList,
	},
	manager: {
		linkList: administratorNav.linkList.filter(
			(link) => link.key !== 'admin-theme'
		),
		mobileList: administratorNav.mobileList,
	},
	/* eslint-disable-next-line sort-keys -- Keys reflect navigation order */
	agent: {
		linkList: [
			{
				disable: true,
				icon: images.icons.dashboard,
				key: 'agent-dashboard',
				label: translate('agent.nav.main.dashboard'),
				to: paths.app.agent.dashboard,
			},
			{
				disable: true,
				icon: images.icons.theme,
				key: 'agent-theme',
				label: translate('agent.nav.main.branding'),
				to: paths.app.agent.theme,
			},
			{
				icon: images.icons.contacts,
				key: 'agent-contacts',
				label: translate('agent.nav.main.contacts'),
				to: paths.app.agent.contacts.root,
			},
			{
				disable: true,
				icon: images.icons.offers,
				key: 'agent-vendors',
				label: translate('agent.nav.main.vendors'),
				to: paths.app.agent.offers.root,
			},
			{
				icon: images.icons.connections,
				key: 'agent-connections',
				label: translate('agent.nav.main.connections'),
				to: paths.app.agent.connections.root,
			},
			{
				hideWidget: true,
				icon: images.icons.widgets,
				key: 'agent-widgets',
				label: translate('agent.nav.main.widgets'),
				to: paths.app.agent.widgets.root,
			},
			{
				hideIfSubscribed: true,
				icon: images.icons.subscription,
				key: 'agent-upgrade',
				label: translate('admin.nav.main.subscription'),
				to: paths.app.agent.subscription.root,
			},
		],
		mobileDisableList: ['agent-dashboard', 'agent-vendors'],
		mobileList: [
			'agent-dashboard',
			'agent-contacts',
			'agent-vendors',
			'agent-upgrade',
		],
	},
	homeowner: {
		linkList: [
			{
				icon: images.icons.dashboard,
				key: 'homeowner-dashboard',
				label: translate('homeowner.nav.main.dashboard'),
				to: paths.app.homeowner.dashboard,
			},
			{
				icon: images.icons.marketplace,
				key: 'homeowner-marketplace',
				label: translate('homeowner.nav.main.marketplace'),
				to: paths.app.homeowner.marketplace,
			},
			{
				icon: images.icons.checklists,
				key: 'homeowner-checklists',
				label: translate('homeowner.nav.main.checklists'),
				to: paths.app.homeowner.checklists,
			},
			{
				icon: images.icons.properties,
				key: 'homeowner-properties',
				label: translate('homeowner.nav.main.properties'),
				to: paths.app.homeowner.properties.root,
			},
			{
				icon: images.icons.documents,
				key: 'homeowner-documents',
				label: translate('homeowner.nav.main.documents'),
				to: paths.app.homeowner.documents.root,
			},
			{
				icon: images.icons.applianceCenter,
				key: 'homeowner-appliances',
				label: translate('homeowner.nav.main.appliance_center'),
				to: paths.app.homeowner.applianceCenter.root,
			},
			{
				icon: images.icons.finances,
				key: 'homeowner-finances',
				label: translate('homeowner.nav.main.finances'),
				to: paths.app.homeowner.finances,
			},
			{
				icon: images.icons.blog,
				key: 'homeowner-blog',
				label: translate('homeowner.nav.main.blog'),
				to: paths.app.homeowner.blog,
			},
			{
				flag: 'homeowner:projects',
				icon: images.icons.projects,
				key: 'homeowner-projects',
				label: translate('homeowner.nav.main.projects'),
				to: paths.app.homeowner.projects,
			},
			{
				hideIfSubscribed: true,
				icon: images.icons.utilities,
				key: 'homeowner-utilities',
				label: translate('homeowner.nav.main.utilities'),
				to: paths.app.homeowner.utilities,
			},
		],
		mobileList: [
			'homeowner-dashboard',
			'homeowner-finances',
			'homeowner-properties',
			'homeowner-checklists',
		],
	},
};

export default navLinkList;

/**
 * @param {string} key
 * @param {boolean} isSubscribed
 * @param {boolean} hideWidget
 * @returns {NavigationItem[]}
 */
function getNavigation(key, isSubscribed, hideWidget) {
	/** @type {NavigationItem[] | null} */
	/* @ts-ignore Type issue on `object.get` */
	const nav = object.get(navLinkList, `${key}.linkList`, null);

	if (nav === null) {
		return [];
	}

	return nav.filter((navItem) => {
		if (isSubscribed && navItem.hideIfSubscribed) {
			return false;
		}

		if (hideWidget && navItem.hideWidget) {
			return false;
		}

		return true;
	});
}

/**
 * @param {keyof navLinkList} key
 * @param {boolean} isSubscribed
 * @param {boolean} [hideWidget]
 * @returns {NavigationItem[]}
 */
export function getNavLinkList(key, isSubscribed, hideWidget = true) {
	if (!roles.all.includes(key)) {
		return [];
	}

	/*
	 * Using set to ensure that there are no duplicates,
	 * when using `.push()` this nav item was duplicated every
	 * time the nav menu was opened. While, this works as intended
	 * with a spread operator `[...]`, we're using a `Set` here to
	 * ensure consistency.
	 */
	const nav = new Set(getNavigation(key, isSubscribed, hideWidget));

	return [...nav];
}
