import PropertyDetailsCard from '../PropertyDetailsCard/PropertyDetailsCard';
import React from 'react';

export default class PropertyDetailsCards extends React.Component {
	#sections = [
		'construction',
		'rooms',
		'size',
		'amenities',
		'hvac',
		'utilities',
	];

	/**
	 * @returns {JSX.Element[]}
	 */
	render() {
		return this.#sections.map((details) => (
			<PropertyDetailsCard details={details} key={details} />
		));
	}
}
