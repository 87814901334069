/* eslint-disable max-classes-per-file, react/no-multi-comp */
// Multiple classes and components used for HOC
/**
 * @typedef {import('../../shared/PropertyService/Comparables').default} Comps;
 * @typedef {import('./PropertyContext').default} Context
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 * @typedef {import('./State').Property} Property
 */
import { AuthContext } from '../../../shared/AuthProvider';
import { getComparables } from '../homeowner-service';
import { selectedPropertyStorageKey } from '../../../config/local/properties';
import { toAbsoluteUrl } from '../../../utils/toAbsoluteUrl';
import { translate } from '../../Internationalization';
import {
	withHomeownerService,
	withPropertyService,
} from '../../../service-container';
import captureError from '../../../utils/captureError';
import images from '../../../config/local/images';
import LoadingScreen from '../../../shared/LoadingScreen';
import React from 'react';

/** @type {React.Context<Context>} */
// @ts-ignore error, says Context !== Context ??
export const PropertyContext = React.createContext({
	comparables: [],
	propertyImage: null,
	propertyList: [],
	selectedProperty: null,
	setSelectedProperty: () => Promise.resolve(),
});

/** @extends {React.Component<Props, State>} */
class PropertyProvider extends React.Component {
	#storageId = selectedPropertyStorageKey;

	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			comparables: [],
			errorMessage: null,
			isGettingData: true,
			propertyImage: undefined,
			propertyList: [],
			selectedProperty: null,
			setSelectedProperty: this.setSelectedProperty.bind(this),
		};
	}

	/**
	 * @param {unknown} error
	 * @returns {void}
	 */
	#error(error) {
		captureError(error);

		this.setState({ errorMessage: translate('global.error') });
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		const propertyList = await this.getPropertyList();
		const propertyImage = await this.getPropertyImage();

		this.setState((state) => ({
			...state,
			propertyImage,
			propertyList,
		}));

		await this.getPrimaryProperty();
	}

	/**
	 * @param {Props} prevProps
	 * @param {State} prevState
	 * @returns {Promise<void>}
	 */
	async componentDidUpdate(prevProps, prevState) {
		const propertyList = await this.getPropertyList();

		const selectedProperty = propertyList?.filter(
			(property) => property.id === this.state.selectedProperty?.id
		)[0];

		/*
		 * This checks if the selected property data returned by the API is the same as the selected property data currently in state.
		 * If they are not equal then update state with API data.
		 */
		if (
			JSON.stringify(prevState?.selectedProperty) !==
			JSON.stringify(selectedProperty)
		) {
			this.setState({
				isGettingData: false,
				propertyList,
				selectedProperty,
			});
		}

		if (propertyList?.length !== prevState.propertyList?.length) {
			this.setState((state) => ({
				...state,
				isGettingData: false,
				propertyList,
			}));
		}
		if (
			this.state.selectedProperty?.id &&
			prevState?.selectedProperty?.id !== this.state.selectedProperty?.id
		) {
			/**
			 * @type {Comps[]}
			 */
			const comparables = await getComparables(
				this.context.accessToken,
				this.state.selectedProperty?.id
			);

			this.setState((state) => ({
				...state,
				comparables,
			}));
		}
	}

	/**
	 * @returns {Promise<Property[]>}
	 */
	async getPropertyList() {
		try {
			/*
			 * TODO: This prop is often used to set the state. Using a prop to
			 * set state has unforeseen consequences.
			 *
			 * Ref: https://reactjs.org/docs/state-and-lifecycle.html#state-updates-may-be-asynchronous
			 */
			return this.props.propertyService.getHomeownerProperties();
		} catch (error) {
			/*
			 * TODO: This setting the state is can lead to unforeseen consequences
			 * as this is using props to set state and this method is called
			 * frequently to set the state.
			 */
			this.#error(error);
		}

		return [];
	}

	defaultImage = images.homeowners.properties.streetView;

	/** @returns {Promise<string>} */
	async getPropertyImage() {
		let propertyImage;
		let property;

		try {
			property =
				await this.props.homeownerService.getHomeownerPrimaryProperty();
		} catch (error) {
			captureError(error);
		}

		localStorage.getItem(this.#storageId);

		try {
			propertyImage =
				property &&
				(await this.props.propertyService.getDefaultImage(
					property?.id
				));
		} catch (error) {
			captureError(error);
		}

		return toAbsoluteUrl(propertyImage ?? this.defaultImage);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async getPrimaryProperty() {
		const storedId = localStorage.getItem(this.#storageId);

		this.setState((prevState) => {
			let selected = prevState.propertyList.find(
				(property) => property.id === storedId
			);

			if (!selected) {
				selected = prevState.propertyList.find(
					(property) => property.isPrimary === true
				);
			}

			if (!selected) {
				selected = prevState.propertyList.find(Boolean);
			}

			return {
				...prevState,
				isGettingData: false,
				selectedProperty: selected ?? null,
			};
		});
	}

	/**
	 * @param {string} id
	 * @param {boolean} [flag]
	 * @returns {Promise<void>}
	 */
	async setSelectedProperty(id, flag = false) {
		const refreshed = flag ? await this.getPropertyList() : null;

		this.setState((prevState) => {
			const propertyList = refreshed ?? prevState.propertyList;

			const selected = propertyList.find(
				(property) => property.id === id
			);

			if (selected === undefined) {
				return { ...prevState };
			}
			window.localStorage.setItem(this.#storageId, selected.id);
			return {
				isGettingData: false,
				propertyList,
				selectedProperty: selected,
			};
		});
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		/**
		 * @type {React.ReactChild | React.ReactChildren}
		 */
		let content = this.props.children;

		if (this.state.isGettingData) {
			content = <LoadingScreen />;
		}

		return (
			<PropertyContext.Provider
				value={{
					comparables: this.state.comparables,
					propertyImage: this.state.propertyImage,
					propertyList: this.state.propertyList,
					selectedProperty: this.state.selectedProperty,
					setSelectedProperty: this.state.setSelectedProperty,
				}}
			>
				{content}
			</PropertyContext.Provider>
		);
	}
}

PropertyProvider.contextType = AuthContext;

// @ts-ignore TODO: Remove when service container types are fixed
export default withPropertyService(withHomeownerService(PropertyProvider));

// @ts-ignore TODO:Add types when service container types are resolved
export function withProperty(Component) {
	class PropertyComponent extends React.Component {
		render() {
			/* eslint-disable-next-line react/jsx-props-no-spreading -- necessary for HOC */
			return <Component property={this.context} {...this.props} />;
		}
	}

	PropertyComponent.contextType = PropertyContext;

	return PropertyComponent;
}
