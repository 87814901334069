/**
 * @typedef {import('../../shared/PropertyService/FormPropertyFields').default} Values
 * @typedef {import('./AddPropertyProps').default} Props
 * @typedef {import('./AddPropertyState').default} State
 */

import { AuthContext } from '../../../shared/AuthProvider';
import { Redirect } from 'react-router';
import { translate } from '../../Internationalization';
import { withPropertyService } from '../../../service-container';
import captureError from '../../../utils/captureError';
import paths from '../../../config/local/paths';
import PropertyForm from './PropertyForm';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class AddProperty extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			errorMessage: null,
			formComplete: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	/** @param {Values} values */
	async handleSubmit(values) {
		try {
			const [properties, id] = await Promise.all([
				this.props.propertyService.getHomeownerProperties(),
				this.props.propertyService.addHomeownerProperty(
					values,
					this.context.userId
				),
			]);

			this.setState({
				formComplete: true,
			});

			if (!properties?.length) {
				window.location.href = paths.app.homeowner.properties.root;
			}

			// @ts-ignore value not void
			await this.props.property.setSelectedProperty(id, true);

			if (!properties?.length) {
				window.location.href = paths.app.homeowner.properties.root;
			}
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('global.error'),
			});
		}
	}

	render() {
		if (this.state.formComplete) {
			return (
				<Redirect
					to={{
						pathname: paths.app.homeowner.properties.root,
						state: {
							message: translate(
								'homeowner.pages.properties.add.success_message'
							),
						},
					}}
				/>
			);
		}

		return (
			<PropertyForm
				errorMessage={this.state.errorMessage}
				headingContent={translate(
					'homeowner.pages.properties.add.title'
				)}
				onSubmit={this.handleSubmit}
				showDetailsFields={false}
				showImageField={false}
				submitButtonContent={translate(
					'homeowner.pages.properties.form.submit'
				)}
			/>
		);
	}
}

AddProperty.contextType = AuthContext;

// @ts-ignore until service container types are fixed
export default withPropertyService(AddProperty);
