import { translate } from '../App/Internationalization';

/**
 * If used with `catch` it returns the error message as-is
 * if the error is an instance of `Error`. In most instances
 * this likely returns errors that should not be returned to
 * end users.
 * @deprecated
 * @param {unknown} error
 * @returns {string}
 */
export default function getErrorMessage(error) {
	if (error instanceof Error) {
		return error.message;
	}
	return translate('global.error');
}
