const baseURL = process.env.REACT_APP_VALUATION_URL ?? 'https://widget.homekeepr.com/valuation/'; // prettier-ignore
const helpURL =
	'https://help.homekeepr.com/how-to-embed-a-widget-code-to-your-website';
const scriptURL = `${baseURL}assets/dist/widget.min.js`;

export default {
	dataAttributes: {
		tenant: getHostURL(),
	},
	scriptType: 'text/javascript',
	urls: {
		base: baseURL,
		help: helpURL,
		script: scriptURL,
	},
};

/**
 * @returns {string}
 */
function getHostURL() {
	const currentURL = window.location.href;
	const url = new URL(currentURL);

	return url.host;
}
