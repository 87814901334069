/* eslint-disable max-classes-per-file, react/no-multi-comp */
// Multiple classes and components used for HOC
/**
 * @typedef {import('./Context').default} Context
 * @typedef {import('./Component').Props} Props
 * @typedef {import('./Component').State} State
 */
import { withSettingService } from '../../service-container';
import captureError from '../../utils/captureError';
import React from 'react';

/** @type {Context} */
const defaultContext = {
	features: new Map(),
};

/** @type {React.Context<Context>} */
export const SettingContext = React.createContext(defaultContext);

/** @extends {React.Component<Props, State>} */
export class SettingProvider extends React.Component {
	/** @type {Omit<Props, 'settingService'>} */
	static defaultProps = {};

	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = defaultContext;
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		await this.#setFeatureFlags();
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #setFeatureFlags() {
		try {
			this.setState({
				/* eslint-disable-next-line react/no-unused-state -- Consumed outside of this component */
				features: await this.props.settingService.getFeatureFlags(),
			});
		} catch (error) {
			captureError(error);
		}
	}

	render() {
		return (
			<SettingContext.Provider value={this.state}>
				{this.props.children}
			</SettingContext.Provider>
		);
	}
}

/**
 * @template {Readonly<{ [Key in string]: any}>} PropsTypes
 * @param {React.ComponentType<PropsTypes>} Component
 * @returns {React.ComponentType<Omit<PropsTypes, 'settings'>>}
 */
export function withSettings(Component) {
	/* eslint-disable-next-line react/display-name -- Returned immediately into a named reference */
	return class extends React.Component {
		static contextType = SettingContext;

		render() {
			/* eslint-disable react/jsx-props-no-spreading -- necessary for HOC */
			// @ts-ignore -- TODO: Reconcile React prop issues
			return <Component settings={this.context} {...this.props} />;
			/* eslint-enable react/jsx-props-no-spreading */
		}
	};
}

/** @type {React.ComponentType<Props>} */
export default withSettingService(SettingProvider);
