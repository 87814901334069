/**
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 */

import { translate } from '../../../Internationalization';
import { withHomeownerService } from '../../../../service-container';
import { withProperty } from '../../PropertyProvider';
import captureError from '../../../../utils/captureError';
import images from '../../../../config/local/images';
import LinkButton from '../../../../shared/LinkButton';
import Loading from '../../../../shared/Loading';
import paths from '../../../../config/local/paths';
import PropertyImage from './PropertyImage/PropertyImage';
import PropertyInfo from './PropertyInfo/PropertyInfo';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class PropertyCard extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State}  */
		this.state = {
			errorMessage: null,
			image: null,
			isGettingData: true,
			propertyDetails: null,
		};
	}

	async componentDidMount() {
		try {
			const propertyInfo =
				await this.props.homeownerService.getHomeownerPrimaryProperty(
					this.props.property.selectedProperty?.id ?? undefined
				);

			this.setState({
				image: propertyInfo.image,
				isGettingData: false,
				propertyDetails: propertyInfo.details,
			});
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate(
					'homeowner.pages.dashboard.properties.property_info_error'
				),
				isGettingData: false,
			});
		}
	}

	render() {
		if (this.state.errorMessage) {
			return <p>{this.state.errorMessage}</p>;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		/**
		 * @type {NonNullable<State['propertyDetails']>}
		 */
		// @ts-ignore the only time property details  is null is when errorMessage is truthy*/
		const details = this.state.propertyDetails;

		return (
			<section className="card card-property-detail horizontal card-border mt-3">
				<PropertyImage image={this.state.image} />

				<section className="card-body property-details">
					<PropertyInfo propertyDetails={details} />
					<div className="py-2">
						<LinkButton
							className="button button-icon mx-auto"
							icon={images.icons.properties}
							iconClass="icon icon-fill-fg icon-sm"
							to={{
								pathname: paths.app.homeowner.properties.root,
								state: {
									referrer: `${location.pathname}${location.search}${location.hash}`,
								},
							}}
						>
							{translate(
								'homeowner.pages.dashboard.properties.cta'
							)}
						</LinkButton>
					</div>
				</section>
			</section>
		);
	}
}

// @ts-ignore
export default withHomeownerService(withProperty(PropertyCard));
