import { translate } from '../../../App/Internationalization';
import BaseField from './BaseField';
import BaseInput from '../Inputs/BaseInput';
import FormField from './FormField';
import React from 'react';

export default class FieldTitle extends React.Component {
	render() {
		const label = translate('global.forms.inputs.title.label');

		return (
			<BaseField
				className={this.props.className}
				disabled={this.props.disabled}
				inputClass={this.props.inputClass}
				label={this.props.label ?? label}
				labelClass={this.props.labelClass}
				name={this.props.name}
				placeholder={this.props.placeholder}
				required={this.props.required}
			/>
		);
	}
}

FieldTitle.defaultProps = {
	className: '',
	disabled: BaseField.defaultProps.disabled,
	inputClass: BaseInput.defaultProps.className,
	labelClass: FormField.defaultProps.labelClass,
	name: 'title',
	placeholder: BaseField.defaultProps.placeholder,
	required: BaseInput.defaultProps.required,
};
