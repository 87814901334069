import { AuthContext } from 'shared/AuthProvider';
import { Redirect } from 'react-router-dom';
import { withUserService } from 'service-container';
import AppLayout from 'App/AppLayout';
import Loading from '../../shared/Loading';
import Page from 'shared/Page';
import paths from 'config/local/paths';
import React from 'react';
import Settings from 'App/Profile/Settings';
import Switch from 'shared/Switch';

export class Profile extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: null,
			isGettingData: true,
			name: null,
		};
	}

	async componentDidMount() {
		try {
			await this.getAndSetUserName();
		} catch (error) {
			this.setState({ errorMessage: error.message });
		}
	}

	async getAndSetUserName() {
		const response = await this.props.userService.getUserName(
			this.context.accessToken
		);

		this.setState({
			name: response,
			isGettingData: false,
		});
	}

	render() {
		if (this.state.errorMessage !== null) {
			return <p>{this.state.errorMessage}</p>;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<AppLayout userName={this.state.name}>
				<Switch>
					<Redirect
						exact
						from={paths.app.profile.root}
						to={paths.app.profile.settings}
					/>
					<Page
						component={Settings}
						title="Settings"
						path={paths.app.profile.settings}
					/>
				</Switch>
			</AppLayout>
		);
	}
}

Profile.contextType = AuthContext;

export default withUserService(Profile);
