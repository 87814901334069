/**
 * @typedef {import('./EditAgentDetailsFormType').FormValues} FormValues
 * @typedef {import('./EditAgentDetailsFormType').State} State
 * @typedef {import("./EditAgentDetailsFormType").Props} Props
 */

import { file as validateFile } from '../../shared/validators';
import { translate } from '../Internationalization';
import * as validators from '../../shared/validators';
import * as yup from 'yup';
import agentOccupations from '@mooveguru/yhh-shared-config/users/agent/occupations.json';
import BaseForm from '../../shared/Forms/BaseForm';
import captureError from '../../utils/captureError';
import Feature from '../../shared/Feature';
import FieldEmail from '../../shared/Forms/Fields/FieldEmail';
import FieldFile from '../../shared/Forms/Fields/FieldFile';
import FieldMobilePhone from '../../shared/Forms/Fields/PhoneNumber/FieldMobilePhone';
import FieldGroupPhone from '../../shared/Forms/Fields/PhoneNumber/FieldGroupPhone';
import FieldPostalCode from '../../shared/Forms/Fields/Address/FieldPostalCode';
import FieldSelect from '../../shared/Forms/Fields/FieldSelect';
import FieldSlug from '../../shared/Forms/Fields/Slug/FieldSlug';
import formatPhoneNumberForDisplay from '../../utils/formatPhoneNumberForDisplay';
import FormErrorMessages from '../../shared/Forms/Messages/FormErrorMessages';
import FormSuccessMessages from '../../shared/Forms/Messages/FormSuccessMessages';
import Loading from '../../shared/Loading';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import NameFields from '../../shared/Forms/Fields/Name/NameFields';
import React from 'react';
import SelectOptionList from '../../shared/Forms/Inputs/SelectOptionList';
import SocialMediaFields from '../../shared/Forms/Fields/SocialMedia/SocialMediaFields';
import SubmitButton from '../../shared/Forms/Inputs/SubmitButton';
import uploadSize from '@mooveguru/yhh-shared-config/files/max-upload.json';

const validationSchema = yup.object().shape({
	email: validators.email,
	firstName: validators.required('First Name'),
	lastName: validators.required('Last Name'),
	mobilePhoneNumber: validators.phoneNumber.required(
		'Mobile Phone Number is Required'
	),
	phoneNumber: validators.phoneNumber,
	slug: validators.slug.required(),
});

/** @extends {React.Component<Props, State>} */
export default class EditAgentDetailsForm extends React.Component {
	/** @param {Props} props*/
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			error: null,
			file: null,
			headshot: null,
			initialFormValues: {
				email: null,
				facebook: null,
				firstName: null,
				lastName: null,
				mobilePhoneNumber: null,
				occupation: null,
				phoneNumber: null,
				postalCode: null,
				slug: null,
				twitter: null,
				website: null,
			},
			isGettingData: true,
			success: null,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleFileUploadChange = this.handleFileUploadChange.bind(this);
	}

	/** @returns {Promise<void>} */
	// eslint-disable-next-line complexity
	async componentDidMount() {
		try {
			const agentData =
				await this.props.agentService.getAgentPersonalData();
			this.setState({
				headshot: agentData.headshot ?? '',
				initialFormValues: {
					email: agentData.email,
					facebook: agentData.facebook,
					firstName: agentData.name.first,
					lastName: agentData.name.last,
					mobilePhoneNumber: agentData.phone.mobile ? formatPhoneNumberForDisplay(agentData.phone.mobile) : '', // prettier-ignore
					occupation: agentData.occupation ?? 'agent',
					phoneNumber: agentData.phone.landline ? formatPhoneNumberForDisplay(agentData.phone.landline) : '', // prettier-ignore
					postalCode: agentData.postalCode,
					slug: agentData.slug,
					twitter: agentData.twitter,
					website: agentData.website,
				},
				isGettingData: false,
			});
			// eslint-enable-next-line complexity
		} catch (error) {
			this.setState({
				error: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 * @returns {void}
	 */
	handleFileUploadChange(event) {
		if (!event?.target?.files?.length) {
			return;
		}

		this.setState({ file: event.target.files[0] });
	}

	/**
	 * @param {FormValues} input
	 * @returns {Promise<void>}
	 */
	async handleSubmit(input) {
		if (this.state.file) {
			try {
				validateFile(this.state.file, mimeList.images, 'image');
			} catch (error) {
				this.setState({
					error: translate('homeowner.pages.documents.upload_error'),
				});

				return;
			}
		}

		try {
			await this.props.agentService.updateAgentPersonalData(
				input,
				this.props.auth.accessToken,
				this.state.file || undefined
			);

			this.setState({ success: 'Profile updated successfully!' });
		} catch (error) {
			captureError(error);
			this.setState({ error: translate('global.error') });
		}
	}

	/** @returns {JSX.Element} */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			// @ts-ignore -- will fix with BaseForm
			<BaseForm
				className="search-form-field"
				initialValues={this.state.initialFormValues}
				onSubmit={this.handleSubmit}
				validationSchema={validationSchema}
			>
				<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
					<NameFields required={true} />
					<FieldEmail required={true} />
					<FieldPostalCode name="postalCode" required={true} />
					<FieldGroupPhone name="phoneNumber" />
					<FieldMobilePhone
						name="mobilePhoneNumber"
						required={true}
					/>

					<FieldSelect
						className="col-span-full"
						label="Occupation"
						name="occupation"
					>
						<SelectOptionList values={agentOccupations} />
					</FieldSelect>
					<SocialMediaFields />
					<Feature fallback={null} name="agent:show_field_url_slug">
						<FieldSlug required={true} />
					</Feature>
				</fieldset>

				<fieldset className="mb-8">
					<label className="block h-ms-3 mb-4 input-label">
						{translate('global.forms.inputs.image.title')}
					</label>

					<FieldFile
						accept={Object.values(mimeList.images)}
						// @ts-ignore issue with FieldFile
						label="Upload an image"
						labelClass="label"
						maxSize={uploadSize.image}
						name="agent-headshot"
						onChange={this.handleFileUploadChange}
						preview={this.state.headshot ?? ''}
						type="file"
					/>
				</fieldset>

				<footer className="mx-auto sm:ml-0 button-group w-min">
					<SubmitButton>
						{translate('global.forms.buttons.save')}
					</SubmitButton>
				</footer>
				<FormSuccessMessages messages={[this.state.success]} />
				<FormErrorMessages messages={this.state.error} />
			</BaseForm>
		);
	}
}
