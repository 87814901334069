export default {
	roomMapping: {
		bathroom: 'Bathroom',
		bedroom: 'Bedroom',
		dining_room: 'Dining Room',
		garage: 'Garage',
		kitchen: 'Kitchen',
		laundry_utility_room: 'Laundry/Utility Room',
		living_room: 'Living Room',
	},
};
