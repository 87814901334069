import { translate } from 'App/Internationalization';
import React from 'react';
import InlineSVG from 'react-inlinesvg/esm';
import images from '../../../config/local/images';

export default class DocumentActions extends React.Component {
	render() {
		return (
			<span className="actions-list">
				<button
					className="link-icon link-icon-sm"
					// eslint-disable-next-line react/jsx-handler-names -- TODO: Fix handlers
					onClick={this.props.deleteDocument}
					type="button"
				>
					<span>
						{translate('homeowner.pages.documents.actions.delete')}
					</span>
					<i className="icon icon-sm">
						<InlineSVG src={images.icons.delete} />
					</i>
				</button>

				<button
					className="link-icon link-icon-sm"
					onClick={() =>
						this.props.downloadOnClick(
							this.props.document.id,
							this.props.document.src
						)
					}
					type="button"
				>
					<span>
						{translate(
							'homeowner.pages.documents.actions.download'
						)}
					</span>
					<i className="icon icon-sm">
						<InlineSVG src={images.icons.download} />
					</i>
				</button>
				{/* TODO: Add Edit functionality */}
			</span>
		);
	}
}
