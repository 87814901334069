/**
 * @typedef {import('./FieldFileComponent').Props} Props
 * @typedef {import('./FieldFileComponent').State} State
 * @typedef {import('react').ChangeEvent<HTMLInputElement>} ChangeEvent
 */
import { array } from '@mooveguru/js-utilities';
import { translate } from '../../../App/Internationalization';
import images from '../../../config/local/images';
import InlineSVG from 'react-inlinesvg/esm';
import InputFile from '../Inputs/InputFile';
import InputFilePreview from '../Inputs/Previews/InputFilePreview';
import mimeList from '@mooveguru/yhh-shared-config/files/allowed-mimes.json';
import React from 'react';
import InputLabel from '../Inputs/InputLabel';

/** @extends {React.Component<Props, State>} */
export default class FieldFile extends React.Component {
	static defaultProps = {
		className: '',
		disabled: false,
		fullWidth: false,
		maxSize: '100MB',
		preview: `${translate('global.forms.help.browse_file')}...`,
		required: false,
	};

	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			placeholder:
				this.props.preview?.split('/').pop() ||
				FieldFile.defaultProps.preview,
			preview: null,
		};

		this.handleChange = this.handleChange.bind(this);
	}

	/**
	 * @returns {string[]}
	 */
	#getMimeTypeExtensions() {
		const extensions = new Set();

		/* eslint-disable max-nested-callbacks -- In this case, the nesting isn't overly complex */
		Object.values(mimeList).forEach((mimes) => {
			Object.entries(mimes).forEach(([key, value]) => {
				if (
					this.props.accept.some(
						/**
						 * @param {string[]|string} needle
						 * @returns {boolean}
						 */
						(needle) => array.wrap(value).includes(needle)
					)
				) {
					extensions.add(key);
				}
			});
		});
		/* eslint-enable max-nested-callbacks */

		return [...extensions];
	}

	/**
	 * @template {any} Values
	 * @param {ChangeEvent} event
	 * @param {import('formik').FormikState<Values>} formik
	 */
	handleChange(event, formik) {
		if (typeof this.props.onChange === 'function') {
			this.props.onChange(event, formik);
		}

		const file = (event.currentTarget.files ?? [])[0];

		if (file === null || file === undefined) {
			return;
		}

		this.#renderPreview(file);

		this.setState({
			placeholder: file.name,
		});
	}

	/**
	 * @param {File} file
	 * @returns {void}
	 */
	#renderPreview(file) {
		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onloadend = () => {
			this.setState({
				preview:
					typeof reader.result === 'string' ? reader.result : null,
			});
		};
	}

	render() {
		const extensions = this.#getMimeTypeExtensions();
		const maxSize = translate(
			'global.forms.help.max_upload',
			`${this.props.maxSize}`
		);
		const allowed = `(${extensions.join(', ')})`;

		return (
			<section className={`${this.props.fullWidth ? '' : 'max-w-md'}`}>
				<InputFilePreview
					// @ts-ignore -- type added in props file.
					src={this.state.preview ?? this.props.preview ?? ''}
				/>

				<section className="input-group file-uploader">
					{/*
					 * Do not add `required` to input, the way the `hidden` and
					 * onChange events seem to work, the actual value of the input
					 * doesn't seem to always get set with Formik. Formik can't actually
					 * handle file inputs to begin with so an `onChange` handler is
					 * absolutely necessary and the file must be validated in an `onSubmit`
					 * handler.
					 */}
					<InputFile
						accept={this.props.accept}
						className="hidden"
						disabled={this.props.disabled}
						name={this.props.name}
						onChange={this.handleChange}
					/>

					<InputLabel
						className="cursor-pointer file-uploader-files font-normal overflow-hidden text-ellipsis whitespace-nowrap"
						inputName={this.props.name}
						required={this.props.required}
					>
						{this.state.placeholder}
					</InputLabel>

					<aside className="file-uploader-info">
						<span className="capitalize">{maxSize}</span>{' '}
						<span className="uppercase">{allowed}</span>
					</aside>

					<label
						className="button button-icon button-outline mx-auto w-fit"
						htmlFor={this.props.name}
					>
						<span>
							{translate('global.forms.help.browse_file')}
						</span>

						<i className="icon icon-fill icon-sm">
							<InlineSVG src={images.icons.folder} />
						</i>
					</label>
				</section>
			</section>
		);
	}
}
