const documentStylesheet = window?.getComputedStyle(document.documentElement);
export const chartColor = {
	avms: {
		blackKnight: 'yellow',
		quantarium: 'green',
		default: 'blue',
	},
	borderOuterColor:
		documentStylesheet.getPropertyValue('--gray') !== ''
			? documentStylesheet.getPropertyValue('--gray')
			: '#E6E6E6',
	colors: {
		blue:
			documentStylesheet.getPropertyValue('--blue') !== ''
				? documentStylesheet.getPropertyValue('--blue')
				: '#0F8D86',
		gray:
			documentStylesheet.getPropertyValue('--gray') !== ''
				? documentStylesheet.getPropertyValue('--gray')
				: '#C7C7C7',
		green:
			documentStylesheet.getPropertyValue('--green') !== ''
				? documentStylesheet.getPropertyValue('--green')
				: '#428B09',
		mutedGreen: '#a3c58f',
		yellow:
			documentStylesheet.getPropertyValue('--yellow') !== ''
				? documentStylesheet.getPropertyValue('--yellow')
				: '#F4AF01',
	},
	font: documentStylesheet.getPropertyValue('font-family'),
	movers: {
		green: '#97BF78',
		grey: '#C7C7C7',
		yellow: '#FEE089',
	},
};
