/**
 * @typedef {import('../FeatureFlags').default} FeatureFlags
 * @typedef {import('./GetsFeatureFlags').default} GetsFeatureFlags
 * @typedef {import('./Responses').GetFeatureFlags} FeatureFlagResponse
 * @typedef {import('@mooveguru/js-http-client').BrowserClient} HttpClient
 */
import apiUrls from '../../config/local/api-urls';

/**
 * @implements {GetsFeatureFlags}
 */
export default class SettingService {
	#httpClient;

	/** @param {{ httpClient: HttpClient }} dependencies */
	constructor(dependencies) {
		this.#httpClient = dependencies.httpClient;
	}

	/**
	 * @returns {Promise<FeatureFlags>}
	 */
	async getFeatureFlags() {
		/** @type {FeatureFlagResponse} */
		const response = await this.#httpClient.get(
			`${apiUrls.settings.features}`
		);

		if (!response.isOk) {
			throw new Error('Unable to get application settings.');
		}

		/*
		 * TODO: This is the one API we've keep snake case to match current feature
		 * flag conventions. However, this could be mapped in any way that we want
		 * including grouping it by prefix, etc.
		 */
		return new Map(Object.entries(response.body));
	}
}
