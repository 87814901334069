/**
 * @typedef {import('../../../shared/PropertyService/EditablePropertyDetails').default} EditablePropertyDetails
 * @typedef {import('./PropertyDetails').default} PropertyDetails
 * @typedef {import('./PropertyDetailsCardProps').default} Props
 * @typedef {import('./PropertyDetailsCardState').default} State
 */
import {
	mapAmenities,
	mapConstruction,
	mapHvac,
	mapRooms,
	mapSize,
	mapUtilities,
} from './propertyDetailsMappers';
import { translate } from '../../../Internationalization';
import { withProperty } from '../../../../App/Homeowner/PropertyProvider';
import { withPropertyService } from 'service-container';
import captureError from '../../../../utils/captureError';
import Loading from '../../../../shared/Loading';
import React from 'react';
import NoContent from '../../../../shared/NoContent';
import InlineSVG from 'react-inlinesvg/esm';
import images from '../../../../config/local/images';

/** @extends {React.Component<Props, State>} */
export class PropertyDetailsCard extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			errorMessage: null,
			isGettingData: true,
			propertyDetails: [],
			title: '',
		};

		this.getPropertyDetails = this.getPropertyDetails.bind(this);
	}

	async componentDidMount() {
		try {
			const propertyDetails = await this.getPropertyDetails();

			this.setState({
				isGettingData: false,
				propertyDetails,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**  @returns {Promise<PropertyDetails>} */
	async getPropertyDetails() {
		const data = await this.props.propertyService.getEditablePropertyById(
			this.props.property?.selectedProperty?.id
		);

		return this.#mapPropertyDetails(data);
	}

	/**
	 * @param {EditablePropertyDetails} data
	 * @returns {PropertyDetails}
	 */
	// props are a required enum
	// eslint-disable-next-line consistent-return, complexity
	#mapPropertyDetails(data) {
		const details = this.props.details;
		// eslint-disable-next-line default-case
		switch (details) {
			case 'amenities':
				this.setState({
					title: translate(
						'homeowner.pages.dashboard.properties.titles.my_amenities'
					),
				});
				return mapAmenities(data);
			case 'construction':
				this.setState({
					title: translate(
						'homeowner.pages.dashboard.properties.titles.construction'
					),
				});
				return mapConstruction(data);
			case 'hvac':
				this.setState({
					title: translate(
						'homeowner.pages.dashboard.properties.titles.hvac'
					),
				});
				return mapHvac(data);
			case 'rooms':
				this.setState({
					title: translate(
						'homeowner.pages.dashboard.properties.titles.rooms'
					),
				});
				return mapRooms(data);
			case 'size':
				this.setState({
					title: translate(
						'homeowner.pages.dashboard.properties.titles.size'
					),
				});
				return mapSize(data);
			case 'utilities':
				this.setState({
					title: translate(
						'homeowner.pages.dashboard.properties.titles.utilities'
					),
				});
				return mapUtilities(data);
		}
	}

	renderPropertyDetails() {
		if (!this.state.propertyDetails.length) {
			return <NoContent />;
		}

		const data = this.state.propertyDetails.map(
			/**
			 *  @param {{footer: string | number, full?: boolean, header: string, id: number}} info
			 *  @returns {JSX.Element}
			 */ (info) => (
				<div
					className="border-1 border-solid border-grey-300"
					key={info.id}
				>
					<dt className="input-label">{info.header}</dt>
					<dd>{info.footer}</dd>
				</div>
			)
		);

		return (
			<dl className="gap-x-4 gap-y-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
				{data}
			</dl>
		);
	}

	/**
	 * @param {JSX.Element | JSX.Element[] | null} content
	 * @returns {JSX.Element}
	 */
	#renderContainer(content) {
		return (
			<details className="toggle-disclosure mb-8" open={true}>
				<summary className="justified-row mb-6">
					<h2 className="h-ms-4">{this.state.title}</h2>
					<i className="icon icon-fill toggle-disclosure-indicator">
						<InlineSVG src={images.icons.arrow.dropdown.small} />
					</i>
				</summary>
				{content}
			</details>
		);
	}

	render() {
		if (this.state.errorMessage !== null) {
			return this.#renderContainer(
				<NoContent message={this.state.errorMessage} />
			);
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return this.#renderContainer(this.renderPropertyDetails());
	}
}

export default withPropertyService(withProperty(PropertyDetailsCard));
