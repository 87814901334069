/**
 * @param  {...string} classNames
 * @returns {string}
 */
export default function mergeClassNames(...classNames) {
	const sanitizedClassNames = classNames
		.filter((className) => className)
		.flatMap((className) => className.split(' '))
		.sort();

	const uniqueClassNames = new Set(sanitizedClassNames);
	return [...uniqueClassNames].join(' ');
}
