/**
 * @typedef {import("./Props").default} Props
 */

import { translate } from '../../../../Internationalization';
import BaseButton from '../../../../../shared/BaseButton/BaseButton';
import BaseForm from '../../../../../shared/Forms/BaseForm';
import BaseInput from '../../../../../shared/Forms/Inputs/BaseInput';
import FilterGroup from '../Types/FilterGroup';
import FormField from '../../../../../shared/Forms/Fields/FormField';
import React from 'react';

const fieldNames = {
	from: 'mover_score_from',
	to: 'mover_score_to',
};

/** @extends	{React.Component<Props>} */
export default class MoverScoreFilter extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		this.state = { active: false };

		this._handleSubmit = this._handleSubmit.bind(this);
		this._handleReset = this._handleReset.bind(this);
	}

	/**
	 * @param {{mover_score_from: string, mover_score_to: string}} values
	 * @returns {void}
	 */
	_handleSubmit(values) {
		const { min, max } = MoverScoreFilter.#swapMinMax(
			values.mover_score_from,
			values.mover_score_to
		);

		this.props.onSubmit({
			compare: 'in',
			'range[lead_scores.0.score]': `${min - 1};${max + 1}`, // +-1 because the range is exclusive
			relation: 'and',
		});

		this.setState({ active: true });
	}

	_handleReset() {
		const query = this.props.query;

		if (query['range[lead_scores.0.score]']) {
			delete query['range[lead_scores.0.score]'];

			this.props.onSubmit({
				...query,
			});
		}

		this.setState({ active: false });
	}

	/**
	 * @param {number | string} min
	 * @param {number| string} max
	 * @returns {{max: number, min: number}}
	 */
	static #swapMinMax(min = 0, max = 100) {
		min = Number(min);
		max = Number(max);

		return min > max ? { max: min, min: max } : { max, min };
	}

	/** @returns {JSX.Element} */
	render() {
		return (
			<FilterGroup
				active={this.state.active}
				locked={this.props.gated}
				title={translate('filters.title.mover_score')}
			>
				<BaseForm noRequiredText={true} onSubmit={this._handleSubmit}>
					<div className="flex flex-col gap-4">
						<FormField
							inputName={fieldNames.from}
							label={translate('filters.range.from')}
							name={fieldNames.from}
							required={false}
						>
							<BaseInput
								max={100}
								min={0}
								name={fieldNames.from}
								placeholder={'0'}
								type="number"
							/>
						</FormField>

						<FormField
							inputName={fieldNames.to}
							label={translate('filters.range.to')}
							name={fieldNames.to}
							required={false}
						>
							<BaseInput
								max={100}
								min={0}
								name={fieldNames.to}
								placeholder={'100'}
								type="number"
							/>
						</FormField>

						<div className="flex gap-2">
							<BaseButton
								className="w-fit"
								iconSize="small"
								size="xsmall"
								type="submit"
							>
								{translate('filters.submit')}
							</BaseButton>
							<BaseButton
								className="w-fit"
								iconSize="small"
								onClick={this._handleReset}
								outline={true}
								size="xsmall"
								type="reset"
							>
								{translate('filters.reset')}
							</BaseButton>
						</div>
					</div>
				</BaseForm>
			</FilterGroup>
		);
	}
}
