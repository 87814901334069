import React from 'react';
import Loading from './Loading';

export default class LoadingScreen extends React.Component {
	/* eslint-disable class-methods-use-this -- `render` must be accessible to instance */
	render() {
		return (
			<section className="loading-screen">
				<Loading />
			</section>
		);
	}
	/* eslint-enable class-methods-use-this */
}
