import { Redirect, Route } from 'react-router';
import { withGroupService, withSubscriptionService } from 'service-container';
import { withSettings } from '../../../shared/SettingProvider/SettingProvider';
import concatPaths from 'utils/contactPaths';
import CreateGroup from './CreateGroup';
import EditGroup from './EditGroup';
import EditGroupTheme from 'App/Admin/Groups/EditGroupTheme';
import ImportAgents from 'App/Admin/shared/ImportAgents';
import Page from 'shared/Page';
import paths from 'config/local/paths';
import React from 'react';
import Subscription from 'App/Admin/Groups/Subscription';
import Switch from 'shared/Switch';
import ViewGroups from './ViewGroups';

const InjectedViewGroups = withGroupService(
	withSubscriptionService(withSettings(ViewGroups))
);
const InjectedEditGroups = withGroupService(withSubscriptionService(EditGroup));
const InjectedCreateGroup = withGroupService(CreateGroup);

export default class Groups extends React.Component {
	render() {
		return (
			<Switch>
				<Redirect
					exact
					from={paths.app.admin.groups.root}
					to={paths.app.admin.groups.view}
				/>
				<Page
					component={InjectedViewGroups}
					exact
					path={paths.app.admin.groups.view}
					title="Groups"
				/>
				<Page
					component={InjectedEditGroups}
					exact
					path={`${paths.app.admin.groups.edit}/:groupId`}
					title="Edit Group"
				/>
				<Page
					component={InjectedCreateGroup}
					exact
					path={paths.app.admin.groups.add}
					title="Create Group"
				/>
				<Page
					component={ImportAgents}
					exact
					path={concatPaths(
						paths.app.admin.groups.root,
						'/:groupId/agents/import'
					)}
					title="Import Agents"
				/>
				<Route
					component={Subscription}
					path={concatPaths(
						paths.app.admin.groups.root,
						'/:groupId/subscription'
					)}
					title="Manage Group Subscription"
				/>
				<Route
					component={EditGroupTheme}
					path={concatPaths(
						paths.app.admin.groups.root,
						'/:groupId/theme'
					)}
					title="Manage Group Theme"
				/>
			</Switch>
		);
	}
}
