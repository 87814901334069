/**
 * @typedef {import ('./LinkButtonProps').default} LinkButtonProps
 * @typedef {import('./BaseButton/BaseButtonProps').default} BaseButton
 */

import { buttonSize, iconSize } from './BaseButton/BaseButton';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../utils/toAbsoluteUrl';
import InlineSVG from 'react-inlinesvg';
import React from 'react';

/** @extends {React.Component<LinkButtonProps>} */
export default class LinkButton extends React.Component {
	static defaultProps = {
		outline: true,
	};

	/**
	 * @param {string} className
	 * @returns {string}
	 */
	#isIconSpaceBetween(className) {
		if (this.props.isIconSpaceBetween) {
			return `${className} button-icon button-icon-between`;
		}
		return `${className} button-icon`;
	}

	/** @returns {string} */
	#getDefaultClass() {
		let defaultClass = 'button';

		if (this.props.border === 'rounded') {
			defaultClass = `${defaultClass} button-pill`;
		} else if (this.props.border === 'square') {
			defaultClass = `${defaultClass} button-datum`;
		}

		if (this.props.icon) {
			defaultClass = this.#isIconSpaceBetween(defaultClass);
		}

		if (this.props.outline) {
			defaultClass = `${defaultClass} button-outline`;
		}

		if (this.props.size) {
			defaultClass += ` ${buttonSize[this.props.size]}`;
		}

		return defaultClass;
	}

	/** @returns {string} */
	#getIconClass() {
		let iconClass = `icon ${iconSize.small}`;

		if (this.props.icon) {
			if (this.props.outline) {
				iconClass = `${iconClass} icon-fill`;
			} else {
				iconClass += ` ${iconClass} icon-fill-fg`;
			}
		}

		return iconClass;
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<Link
				className={this.props.className ?? this.#getDefaultClass()}
				data-activity-click={this.props.dataActivityClick}
				data-activity-id={this.props.dataActivityId}
				style={{ pointerEvents: this.props.disabled ? 'none' : 'auto' }}
				to={this.props.to}
			>
				<span>{this.props.children}</span>
				{this.props.icon ? (
					<i className={this.props.iconClass ?? this.#getIconClass()}>
						<InlineSVG src={toAbsoluteUrl(this.props.icon)} />
					</i>
				) : null}
			</Link>
		);
	}
}
