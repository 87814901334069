/**
 * @typedef {import('./SettingsComponent').Props} Props
 * @typedef {import('./SettingsComponent').State} State
 */
import { translate } from '../Internationalization';
import { withAuth } from '../../shared/AuthProvider';
import { withSubscriptionService } from '../../service-container';
import captureError from '../../utils/captureError';
import ChangePasswordCard from './ChangePasswordCard';
import ChangeUserEmailCard from './ChangeUserEmailCard';
import EditAdministratorDetailsCard from './EditAdministratorDetailsCard';
import EditAgentDetailsCard from './EditAgentDetailsCard';
import EditHomeownerDetailsCard from './EditHomeownerDetailsCard';
import ExternalAnchorButton from '../../shared/ExternalAnchorButton';
import images from '../../config/local/images';
import React from 'react';
import RoleGate from '../shared/RoleGate';

/** @extends {React.Component<Props, State>} */
class Settings extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			subscriptionManagementLink: null,
		};
	}

	async componentDidMount() {
		if (this.props.auth.agentSubscriptionLevels.includes('agent')) {
			await this.#manageSubscription();
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #manageSubscription() {
		try {
			const managementSession =
				await this.props.subscriptionService.getAgentSubscriptionManagementLink();

			this.setState({
				subscriptionManagementLink: managementSession.url ?? null,
			});
		} catch (error) {
			captureError(error);
		}
	}

	/**
	 * @returns {JSX.Element | null}
	 */
	#renderSubscriptionManagementPortalLink() {
		if (!this.state.subscriptionManagementLink) {
			return null;
		}

		return (
			<section className="w-fit">
				<ExternalAnchorButton
					className="button button-lg button-icon"
					href={this.state.subscriptionManagementLink}
					icon={images.icons.subscription}
					iconClass="icon icon-fill-fg"
					target="_self"
				>
					{translate('subscription.index.subscription_management')}
				</ExternalAnchorButton>
			</section>
		);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<section className="app-container">
				<h1 className="hl-ms-6 mt-10 mb-6">
					{translate('settings.title')}
				</h1>

				{/* @ts-ignore -- Service container issue */}
				<RoleGate allowedRoles={['admin', 'manager', 'owner']}>
					<section className="mb-10">
						<EditAdministratorDetailsCard />
					</section>
				</RoleGate>

				{/* @ts-ignore -- Service container issue */}
				<RoleGate allowedRoles={['agent']}>
					<section className="mb-10">
						<EditAgentDetailsCard>
							{this.#renderSubscriptionManagementPortalLink()}
						</EditAgentDetailsCard>
					</section>
				</RoleGate>

				{/* @ts-ignore -- Service container issue */}
				<RoleGate allowedRoles={['homeowner']}>
					<section className="mb-10">
						<EditHomeownerDetailsCard />
					</section>
				</RoleGate>

				<section className="mb-10">
					<ChangeUserEmailCard />
				</section>

				<section className="mb-10">
					<ChangePasswordCard />
				</section>
			</section>
		);
	}
}

// @ts-ignore
export default withSubscriptionService(withAuth(Settings));
