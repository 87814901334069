/**
 * @typedef {import('./CreatesExport').default} CreatesExport
 * @typedef {import('./Dependencies').default} Dependencies
 * @typedef {import('./Export').ExportType} ExportType
 * @typedef {import('./GetsPendingExport').default} GetsPendingExport
 * @typedef {import('./HasPendingExport').default} HasPendingExport
 * @typedef {import('./GetsPendingExport').PendingExportResponse} PendingExportResponse
 * @typedef {import('../../../App/shared/Query/Parameters').default} QueryParameters
 * @typedef {import('./Export').UserType} UserType
 */

import { prepareQueryForUrl } from '../../../App/shared/Query';
import { translate } from '../../Internationalization';
import apiUrls from '../../../config/local/api-urls';
import captureError from '../../../utils/captureError';
import ServiceError from '../../../shared/Errors/ServiceError';

/**
 * @implements {CreatesExport}
 * @implements {GetsPendingExport}
 * @implements {HasPendingExport}
 */
export default class ExportService {
	/** @param {Dependencies} dependencies */
	constructor(dependencies) {
		this.authService = dependencies.authService;
		this.httpClient = dependencies.httpClient;
	}

	/**
	 * @throws {Error}
	 * @returns {Promise<PendingExportResponse>}
	 */
	async getPendingExport() {
		const response = await this.httpClient.get(
			`${apiUrls.exports.pending}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
			})
		);

		if (!response.isOk) {
			throw new ServiceError(
				translate('exports.errors.get_pending'),
				response.body.message
			);
		}

		const pendingExport = response.body.pending_export;

		if (!pendingExport) {
			return null;
		}

		return {
			createdAt: pendingExport.created_at,
			id: pendingExport.id,
		};
	}

	/**
	 * @param {ExportType} exportType
	 * @param {UserType} userType
	 * @param {QueryParameters} [query]
	 * @throws {Error}
	 * @returns {Promise<void>}
	 */
	async createExport(exportType, userType, query) {
		let params = '';
		if (query) {
			params = prepareQueryForUrl(query, true);
		}

		const response = await this.httpClient.post(
			`${apiUrls.exports.create}/${exportType}/${userType}${params}`,
			new Headers({
				Authorization: `Bearer ${this.authService.getAccessToken()}`,
				'Content-Type': 'application/json',
			})
		);

		if (!response.isOk) {
			throw new ServiceError(
				translate('exports.errors.create'),
				response.body.message
			);
		}
	}

	/**
	 * @returns {Promise<boolean>}
	 */
	async hasPendingExport() {
		try {
			const pendingExport = await this.getPendingExport();

			return !!pendingExport;
		} catch (error) {
			captureError(error);

			return true;
		}
	}
}
