/**
 * @typedef {import ('./OnboardingChecklistProps').default} Props
 * @typedef {import ('./OnboardingChecklistState').default} State
 */

import { object } from '@mooveguru/js-utilities';
import { translate } from '../../../App/Internationalization';
import { withTaskService } from '../../../service-container';
import BaseChecklist from './BaseChecklist';
import captureError from '../../../utils/captureError';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class OnboardingChecklist extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			errorMessage: null,
			taskList: object.clone(this.props.taskList),
		};

		this.handleChecklistItemChange =
			this.#handleChecklistItemChange.bind(this);
	}

	/**
	 * @param {Event & { currentTarget: HTMLInputElement }} event
	 * @param {string} taskId
	 * @returns {Promise<void>}
	 */
	async #handleChecklistItemChange(event, taskId) {
		event.preventDefault();

		try {
			await this.#toggleTask(taskId);
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate('homeowner.pages.checklists.error'),
			});
		}
	}

	/**
	 * @param {string} taskId
	 * @returns {Promise<void>}
	 */
	async #toggleTask(taskId) {
		const index = this.state.taskList.findIndex(
			(item) => item.id === taskId
		);

		if (index === -1) {
			return;
		}

		const nextTaskList = this.state.taskList.slice(index + 1);
		const previousTaskList = this.state.taskList.slice(0, index);
		const task = this.state.taskList[index];

		if (task.isComplete) {
			task.isComplete = false;
			await this.props.taskService.undoTask(task.id);

			this.setState({
				errorMessage: null,
				taskList: [...previousTaskList, task, ...nextTaskList],
			});

			return;
		}
		task.isComplete = true;
		await this.props.taskService.completeTask(task.id);

		this.setState({
			errorMessage: null,
			taskList: [...previousTaskList, task, ...nextTaskList],
		});
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<BaseChecklist
				dealMessage={translate(
					'homeowner.pages.checklists.onboarding.deal'
				)}
				description={translate(
					'homeowner.pages.checklists.onboarding.description'
				)}
				disabled={false}
				errorMessage={this.state.errorMessage}
				onHandleChange={this.handleChecklistItemChange}
				taskList={this.state.taskList}
				// @ts-ignore -- type declared in props.
				taskType="onboarding"
				title={translate('homeowner.pages.checklists.onboarding.title')}
			/>
		);
	}
}

// @ts-ignore until service container types are fixed
export default withTaskService(OnboardingChecklist);
