import React from 'react';

import EditHomeownerDetailsForm from 'App/Profile/EditHomeownerDetailsForm';
import { withHomeownerService } from 'service-container';
import { withAuth } from 'shared/AuthProvider';
import Card from 'shared/Card';
import { translate } from '../Internationalization';

const InjectedEditHomeownerDetailsForm = withAuth(
	withHomeownerService(EditHomeownerDetailsForm)
);

export default class EditProfileCard extends React.Component {
	render() {
		return (
			<React.Fragment>
				<h2 className="h-ms-5 mb-6">
					{translate('settings.sections.homeowner')}
				</h2>
				<InjectedEditHomeownerDetailsForm />
			</React.Fragment>
		);
	}
}
