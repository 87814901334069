/** @typedef {import('./CopyToClipboardProps').default} Props */

import { translate } from '../../App/Internationalization';
import BaseButton from '../BaseButton/BaseButton';
import captureError from '../../utils/captureError';
import images from '../../config/local/images';
import React from 'react';

/** @extends {React.Component<Props>} */
export default class CopyToClipboard extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async handleClick() {
		try {
			await window.navigator.clipboard.writeText(this.props.url);

			if (this.props.onSuccess) {
				this.props.onSuccess();
			}
		} catch (error) {
			captureError(error);
		}
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<BaseButton
				className={this.props.className}
				icon={images.icons.link}
				onClick={this.handleClick}
				outline={true}
				title={translate('global.links.copy')}
			>
				{this.props.text}
			</BaseButton>
		);
	}
}

CopyToClipboard.defaultProps = {
	text: translate('global.links.copy'),
};
