/**
 * @typedef {import('./Props').default} Props
 */

import { translate } from '../../Internationalization';
import { withHomeownerService } from '../../../service-container';
import { withProperty } from 'App/Homeowner/PropertyProvider';
import AllConnectWidget from './AllConnectWidget';
import BaseHero from '../../../shared/BaseHero';
import images from '../../../config/local/images';
import React from 'react';
import paths from '../../../config/local/paths';

/** @extends {React.Component<Props>} */
export class Utilities extends React.Component {
	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<article>
				<BaseHero
					heroImageUrl={images.homeowners.utilities.header}
					title={translate('homeowner.pages.utilities.title')}
				/>

				<section className="rich-text">
					<p>
						{translate(
							'homeowner.pages.properties.view.utilities.subtext'
						)}
					</p>

					<h2>
						{translate(
							'homeowner.pages.properties.view.utilities.concierge_title'
						)}
					</h2>

					<section
						className="rich-text"
						dangerouslySetInnerHTML={{
							__html: translate(
								'homeowner.pages.properties.view.utilities.concierge_copy',
								paths.concierge
							),
						}}
					/>

					{/* eslint-disable-next-line react/iframe-missing-sandbox */}
					<iframe
						className="w-full h-[70rem] concierge-widget"
						src={paths.concierge}
						title="Schedule a call with our concierges!"
					/>

					<h2>
						{translate(
							'homeowner.pages.properties.view.utilities.allconnect_title'
						)}
					</h2>

					<p>{translate('homeowner.pages.utilities.lead')}</p>
					<AllConnectWidget
						address={this.props.property.selectedProperty?.address}
					/>
				</section>
			</article>
		);
	}
}

export default withHomeownerService(withProperty(Utilities));
