import { BaseSVG } from '../../BaseSVG';
import { translate } from '../../../App/Internationalization';
import FormField from './FormField';
import images from '../../../config/local/images';
import InputDate from '../Inputs/InputDate';
import React from 'react';

export default class FieldDate extends React.Component {
	render() {
		const label = translate('global.forms.inputs.date.label');

		return (
			<FormField
				className={this.props.className}
				inputName={this.props.name}
				label={this.props.label ?? label}
				labelClass={this.props.labelClass}
				name={this.props.name}
				required={this.props.required}
			>
				<section className="input-stylized">
					<InputDate
						className={this.props.inputClass}
						name={this.props.name}
						required={this.props.required}
					/>
					<BaseSVG
						className="input-icon"
						path={images.icons.calendar}
					/>
				</section>
			</FormField>
		);
	}
}
