import React from 'react';

import { translate } from 'App/Internationalization';
import NavigationList from 'App/shared/NavigationList';
import RoleGate from 'App/shared/RoleGate';
import navigation from 'config/local/navigation';

export default class HomeownerNavigationList extends React.Component {
	render() {
		return (
			// @ts-ignore -- issue with parent component
			<RoleGate allowedRoles={['homeowner']}>
				<NavigationList
					className="mb-8"
					heading={translate('homeowner.nav.heading')}
					// @ts-ignore -- type added in parent component
					links={navigation.homeowner.linkList.filter(
						(link) => link !== null
					)}
					onListItemClick={this.props.onListItemClick}
				/>
			</RoleGate>
		);
	}
}
