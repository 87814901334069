/**
 * @typedef {import('./Component').Props} Props
 * @typedef {import('./Component').State} State
 */
import { Redirect } from 'react-router-dom';
import { withAuth } from '../../shared/AuthProvider';
import { withGroupService } from '../../service-container';
import captureError from '../../utils/captureError';
import concatPaths from '../../utils/contactPaths';
import Loading from '../../shared/Loading';
import paths from '../../config/local/paths';
import React from 'react';

/** @extends {React.Component<Props, State>} */
export class Subscribe extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		this.state = {
			groupId: null,
			isGettingData: true,
		};
	}

	async componentDidMount() {
		try {
			const currentGroups = await this.props.groupService.searchGroup(
				'',
				1
			);

			this.setState({
				groupId: currentGroups.data[0].id,
				isGettingData: false,
			});
		} catch (error) {
			captureError(error);
			this.setState({ isGettingData: false });
		}
	}

	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		if (this.props.auth.roles.includes('manager')) {
			return (
				<Redirect
					to={{
						pathname: concatPaths(
							paths.app.admin.groups.root,
							`/${this.state.groupId}/subscription`
						),
						search: location.search,
					}}
				/>
			);
		}

		if (this.props.auth.roles.includes('agent')) {
			return (
				<Redirect
					to={{
						pathname: paths.app.agent.subscription.root,
						search: location.search,
					}}
				/>
			);
		}

		return <Redirect to={paths.app.root} />;
	}
}

// @ts-ignore -- Service container issue
export default withAuth(withGroupService(Subscribe));
