const cityAndStateOrZipWidgetTypeList = [
	'MedianSalesPriceSqft',
	'MonthsOfSupply',
	'NumberOfHomesSold',
	'SupplyAndDemand',
];

const streetAddressAndZipWidgetTypeList = [
	'ComparableHomeSales',
	'HomeValuationCombo',
	'HomeValuationEstimate',
	'HomeValuationHistory',
	'NearbySchools',
	'PriceHistory',
	'PropertyFacts',
	'TaxHistory',
];

const loadWidgetQueue = [];
let errorMessage = null;
let isInitialized = false;
let widgetsIntegration = null;

/**
 *
 */
export function clearWidgets() {
	widgetsIntegration?.clear();
}

/**
 * @param type
 * @param elementId
 * @param options
 */
export function loadWidget(type, elementId, options) {
	if (!isInitialized) {
		initialize();
	}

	if (widgetsIntegration === null) {
		return new Promise((resolve, reject) => {
			loadWidgetQueue.push({ type, elementId, options, resolve, reject });
		});
	}

	return new Promise((resolve, reject) => {
		processLoadWidgetRequest({ type, elementId, options, resolve, reject });
	});
}

/**
 *
 */
function initialize() {
	const script = createScript();
	document.body.append(script);
	isInitialized = true;
}

// TODO: Currently exported to be eager initialized as lazy initialization on
//       load creates a race condition with our own Map component. Should be
//       moved or removed when we create our own custom widgets based on Xome
//       API data.
/**
 *
 */
export function initializeGoogleMaps() {
	const script = createGoogleMapsScript();
	document.body.append(script);
}

/**
 *
 */
function createGoogleMapsScript() {
	const script = document.createElement('script');
	script.async = true;
	script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
	return script;
}

/**
 *
 */
function createScript() {
	const script = document.createElement('script');
	script.async = true;
	script.src = process.env.REACT_APP_XOME_API_URL;
	script.onerror = handleError;
	script.onload = handleLoad;
	return script;
}

/**
 *
 */
function handleError() {
	errorMessage = 'There was a problem loading Xome.';
	loadQueuedWidgets();
}

/**
 *
 */
function handleLoad() {
	const apiKey = process.env.REACT_APP_XOME_API_KEY;
	// WidgetsIntegration is provided by loaded Xome script
	widgetsIntegration = new WidgetsIntegration(apiKey); // eslint-disable-line
	loadQueuedWidgets();
}

/**
 *
 */
function loadQueuedWidgets() {
	while (loadWidgetQueue.length > 0) {
		processLoadWidgetRequest(loadWidgetQueue.shift());
	}
}

/**
 * @param request
 */
function processLoadWidgetRequest(request) {
	const { type, elementId, options, resolve, reject } = request;

	if (errorMessage !== null) {
		return reject(new Error(errorMessage));
	}

	if (!areValidOptionsAndType(options, type)) {
		return reject(new Error('Invalid combination of options and type.'));
	}

	widgetsIntegration.load(type, elementId, options);
	resolve();
}

/**
 * @param options
 * @param type
 */
function areValidOptionsAndType(options, type) {
	if (cityAndStateOrZipWidgetTypeList.includes(type)) {
		return (
			(options.displayArea === 'city' && options.city && options.state) ||
			(options.displayArea === 'zip' && options.zip)
		);
	}

	if (streetAddressAndZipWidgetTypeList.includes(type)) {
		return options.streetAddress && options.zip;
	}

	return false;
}

export const displayAreaList = ['city', 'zip'];

export const widgetTypeList = [
	...cityAndStateOrZipWidgetTypeList,
	...streetAddressAndZipWidgetTypeList,
];
