/**
 * @typedef {import('./SearchBoxComponent').Props} Props
 */

import { Field } from 'formik';
import { translate } from '../../../App/Internationalization';
import BaseButton from '../../BaseButton/BaseButton';
import BaseField from './BaseField';
import images from '../../../config/local/images';
import InlineSVG from 'react-inlinesvg';
import React from 'react';

/**
 * @extends {React.Component<Props>}
 */
export default class SearchBox extends React.Component {
	/**
	 * @param {string} value
	 * @returns {JSX.Element | null}
	 */
	#handleClear(value) {
		if (!this.props.onClear || !value) {
			return null;
		}

		return (
			<button
				className="absolute top-1.5 right-2"
				onClick={() =>
					this.props.onClear && this.props.onClear({ search: '' })
				}
				type="reset"
			>
				<i className="icon-sm icon-fill icon">
					<InlineSVG src={images.icons.delete} />
				</i>
			</button>
		);
	}

	/** @returns {JSX.Element} */
	render() {
		return (
			<Field name={this.props.name}>
				{/* @ts-ignore formik props */}
				{(formik) => (
					<div
						className={`flex items-center ${
							this.props.className ?? ''
						}`.trim()}
					>
						<div className="relative grow">
							<BaseField
								className="grow"
								name={this.props.name ?? 'search'}
								placeholder={this.props.placeholder}
								type="search"
								value={this.props.value ?? ''}
							/>

							{this.#handleClear(formik?.field?.value)}
						</div>

						<BaseButton
							className="ml-3 shrink-0"
							icon={images.icons.search}
							type="submit"
						>
							{translate('global.search_text')}
						</BaseButton>
						{!!this.props.value && (
							<BaseButton
								border="rounded"
								className="ml-3"
								icon={images.icons.delete}
								onClick={
									this.props.onClear
										? this.props.onClear({
												search: '',
										  })
										: undefined
								}
								outline={true}
								size="small"
								type="button"
							/>
						)}
					</div>
				)}
			</Field>
		);
	}
}
