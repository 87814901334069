/** @typedef {import('./Props').default} Props */

import { translate } from '../../Internationalization';
import { withSettings } from '../../../shared/SettingProvider/SettingProvider';
import PageHeading from '../../../shared/PageHeading/PageHeading';
import React from 'react';
import utilities from '@mooveguru/js-utilities';
import Zapier from './Zapier/Zapier';

/** @extends {React.Component<Props>} */
class Connections extends React.Component {
	/**
	 * @param {string} feature
	 * @returns {boolean}
	 */
	#getFeature(feature) {
		return this.props.settings.features.get(feature) ?? false;
	}

	/** @returns {string} */
	static #getTenant() {
		const tenant = window.location.hostname.split('.')[0] ?? 'Homekeepr';

		return utilities.string.convertToTitleCase(tenant, ' ');
	}

	render() {
		return (
			<section>
				<header className="mt-10 mb-6">
					<PageHeading>
						{translate('agent.pages.connections.view.title')}
					</PageHeading>

					<p className="mt-4">
						{translate(
							'agent.pages.connections.view.description',
							Connections.#getTenant()
						)}
					</p>
				</header>

				{this.#getFeature('agents:connections_zapier') && <Zapier />}
			</section>
		);
	}
}

export default withSettings(Connections);
