/**
 * @typedef {Readonly<{ allowedRoles: string[], auth: { roles: string[] } }>} Props
 */

import { withAuth } from 'shared/AuthProvider';
import React from 'react';

/** @extends {React.Component<Props>} */
export class RoleGate extends React.Component {
	userHasRole() {
		return this.props.allowedRoles.some((role) =>
			this.props.auth.roles.includes(role)
		);
	}

	render() {
		if (!this.userHasRole()) {
			return null;
		}

		return this.props.children;
	}
}

export default withAuth(RoleGate);
