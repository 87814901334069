import { translate } from '../Internationalization';
import { withAgentService } from 'service-container';
import { withAuth } from 'shared/AuthProvider';
import AgentCommunicationPreferences from './AgentCommunicationPreferences';
import EditAgentDetailsForm from './EditAgentDetailsForm';
import Feature from '../../shared/Feature';
import React from 'react';

const InjectedEditAgentDetailsForm = withAuth(
	/* @ts-ignore -- Service container props issue */
	withAgentService(EditAgentDetailsForm)
);

export default class EditAgentDetailsCard extends React.Component {
	render() {
		return (
			<React.Fragment>
				<Feature
					fallback={null}
					name="agent:contact_communication_preferences"
				>
					<AgentCommunicationPreferences />
				</Feature>

				<h2 className="h-ms-5 mb-6">
					{translate('settings.sections.agent')}
				</h2>
				{this.props.children}
				{/* @ts-ignore -- Service container props issue */}
				<InjectedEditAgentDetailsForm />
			</React.Fragment>
		);
	}
}
