/**
 * @typedef {import('./AppFooterComponent').Props} Props
 * @typedef {import('./AppFooterComponent').State} State
 */
import { translate } from '../Internationalization';
import app from '../../config/local/app';
import ExternalAnchor from '../../shared/ExternalAnchor';
import ExternalAnchorButton from '../../shared/ExternalAnchorButton';
import images from '../../config/local/images';
import LocalProsSearch from './LocalProsSearch/LocalProsSearch';
import paths from '../../config/local/paths';
import React from 'react';
import RoleGate from './RoleGate';

/** @extends {React.Component<Props, State>} */
export class AppFooter extends React.Component {
	render() {
		const localProsSearch = (
			<section className="footer-search">
				<header className="input-label">
					<h2>{translate('global.local_pros_search.title')}</h2>
				</header>
				<LocalProsSearch />
			</section>
		);

		return (
			<footer className="app-footer mt-8">
				<section className="footer-container">
					<RoleGate allowedRoles={['homeowner']}>
						{
							// prettier-ignore
							this.props.location?.pathname !== paths.app.homeowner.marketplace ? localProsSearch : ''
						}
					</RoleGate>

					<section className="footer-info">
						<ExternalAnchor
							className="footer-logo"
							href={app.url}
							title={app.title}
						>
							<img alt={`Logo for ${app.title}`} src={app.logo} />
						</ExternalAnchor>

						<ExternalAnchorButton
							className="button max-w-max mx-auto md:ml-0"
							href={app.contactUs}
							type="button"
						>
							{translate('global.footer.report_an_issue')}
						</ExternalAnchorButton>
					</section>

					<figure className="footer-image-area">
						<img
							alt="Illustration of person holding boxes"
							className="footer-img"
							src={images.footer.footerMoving}
						/>
					</figure>
				</section>
			</footer>
		);
	}
}

export default AppFooter;
