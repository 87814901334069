import apiUrls from 'config/local/api-urls';
import categories from '@mooveguru/yhh-shared-config/document-categories.json';
import fileTypes from 'config/local/file-types';
import groupBy from 'utils/groupBy';
import HttpClient from '@mooveguru/js-http-client';
const httpClient = new HttpClient();

/** @typedef {import('./Document').default} Document  */

/**
 * @param {string} accessToken
 * @returns {Promise<{[key: string]: Document[]}>}
 */
export async function getDocuments(accessToken) {
	const response = await httpClient.get(
		apiUrls.me.homeowner.documents.view,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!response.isOk) {
		throw new Error(response.message);
	}

	if (!response.body.documents) {
		return [];
	}

	const documentList = response.body.documents.map((document) => ({
		category: categories[document.category] ?? null,
		createdAt: document.created_at ? new Date(document.created_at) : null,
		id: document.id,
		title: document.title,
		type: fileTypes[document.src?.split('.').pop()] || null,
		src: document.src,
	}));

	documentList.sort(
		/**
		 *
		 * @param {Document} a
		 * @param {Document} b
		 * @returns {-1|0|1}
		 */
		(a, b) => a.category?.localeCompare(b.category)
	);

	return groupBy(documentList, 'category');
}

/**
 * @param accessToken
 * @param documentId
 */
export async function getDocumentDownloadLink(accessToken, documentId) {
	const response = await httpClient.get(
		`${apiUrls.me.homeowner.documents.download}/${documentId}`,
		new Headers({ 'X-Access-Token': accessToken })
	);

	if (!response.isOk) {
		throw new Error(response.message);
	}

	return response.body;
}

/**
 * @param {{}} formData
 * @param {string} accessToken
 * @returns {unknown}
 */
export async function saveDocument(formData, accessToken) {
	const response = await httpClient.post(
		apiUrls.me.homeowner.documents.save,
		new Headers({
			'X-Access-Token': accessToken,
		}),
		formData
	);

	return response;
}
