export default {
	csv: 'CSV',
	doc: 'Word Doc',
	docx: 'Word Doc',
	jpeg: 'JPG',
	jpg: 'JPG',
	pdf: 'PDF',
	png: 'PNG',
	svg: 'SGV',
};
