/**
 * @typedef {import('./Agent').default} Agent
 * @typedef {import('./Group').default} Group
 * @typedef {import('./AgentActivityProps').default} Props
 * @typedef {import('./AgentActivityState').default} State
 */
import { AuthContext } from '../../../../../shared/AuthProvider';
import { translate } from '../../../../../App/Internationalization';
import { withAgentService } from 'service-container';
import captureError from '../../../../../utils/captureError';
import Feature from '../../../../../shared/Feature';
import images from '../../../../../config/local/images';
import Loading from '../../../../../shared/Loading';
import React, { Component } from 'react';

/** @extends {React.Component<Props, State>} */
export class AgentActivity extends Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			agents: null,
			errorMessage: null,
			isGettingData: true,
		};

		this.getAgents = this.getAgents.bind(this);
	}

	async componentDidMount() {
		try {
			await this.getAgents();
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
			});
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async getAgents() {
		const { data } = await this.props.agentService.getAgents(
			this.context.accessToken
		);

		if (!data) {
			return;
		}

		if (this.props.type === 'recent') {
			const activeAgents = AgentActivity.#getActiveAgents(data);

			const agents = AgentActivity.#sortDescendingAgents(activeAgents);

			this.setState({
				agents,
				isGettingData: false,
			});
			return;
		}

		const agents = AgentActivity.#getInActiveAgents(data);

		this.setState({
			agents,
			isGettingData: false,
		});
	}

	/**
	 * @param {Agent[]} agents
	 * @returns {Agent[]}
	 */
	static #getActiveAgents(agents) {
		return agents.filter((agent) => agent.lastLoggedInAt !== null);
	}

	/**
	 * @param {Agent[]} agents
	 * @returns {Agent[]}
	 */
	static #getInActiveAgents(agents) {
		return agents.filter((agent) => agent.lastLoggedInAt === null);
	}

	/**
	 * @param {Agent[]} agents
	 * @returns {Agent[]}
	 */
	static #sortDescendingAgents(agents) {
		return agents.sort(function (a, b) {
			return (
				new Date(b.lastLoggedInAt).getTime() -
				new Date(a.lastLoggedInAt).getTime()
			);
		});
	}

	/**
	 * @param {{readonly first: string?, readonly last: string?}} name
	 * @returns {string?}
	 */
	static #generateFullName(name) {
		if (name.first && name.last) {
			return `${name.first} ${name.last}`;
		}

		if (name.first) {
			return name.first;
		}

		if (name.last) {
			return name.last;
		}

		return null;
	}

	/**
	 * @param {Group[]} groups
	 * @returns {string}
	 */
	static #getPrimaryGroupName(groups) {
		if (!groups?.length) {
			return '-';
		}

		const primaryGroup = groups.find((group) => group.isPrimary);

		if (primaryGroup) {
			return primaryGroup.name;
		}

		return '-';
	}

	/**
	 * @returns {JSX.Element | (JSX.Element | null)[]}
	 */
	renderAgents() {
		if (!this.state.agents) {
			return <h1>{translate('global.error')}</h1>;
		}

		const agentList = this.state.agents;

		return agentList.slice(0, 3).map(
			/**
			 * @param {Agent} agent
			 * @returns {JSX.Element?}
			 */ (agent) => (
				<li className="list-item" key={agent.id}>
					<header className="justified-row mb-1">
						<p className="hl-ms">
							{AgentActivity.#generateFullName(agent.name)}
						</p>
						<div className="hl-ms">
							{agent.lastLoggedInAt
								? new Date(
										agent.lastLoggedInAt
								  ).toLocaleDateString('en-US')
								: '-'}
						</div>
					</header>
					<footer>
						<div className="-text-ms-2">
							{AgentActivity.#getPrimaryGroupName(agent.groups)}
						</div>
						<Feature
							fallback={null}
							name="admin:show_premium_agent_badge"
						>
							{agent.isSubscribed ? (
								<p className="badge badge-themed mt-2">
									<i className="icon">
										<img
											alt={translate(
												'admin.nav.agent.upgraded_agents_alt'
											)}
											src={images.icons.premium}
										/>
									</i>
									<span>
										{translate('admin.nav.agent.premium')}
									</span>
								</p>
							) : null}
						</Feature>
					</footer>
				</li>
			)
		);
	}

	render() {
		if (this.state.errorMessage !== null) {
			return <p>{this.state.errorMessage}</p>;
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		return (
			<section className="mt-10">
				<section className="card card-border-light">
					<header className="card-header bg-theme-light text-theme-fg-light justified-row mb-0">
						<div className="h-ms-1">
							{this.props.type === 'recent'
								? translate('admin.nav.agent.recent_logins')
								: translate('admin.nav.agent.inactive_agents')}
						</div>
						<div className="-h-ms-1">
							{translate('admin.nav.agent.last_login')}
						</div>
					</header>
					<ul className="list-group card-list divided px-3">
						{this.renderAgents()}
					</ul>
				</section>
			</section>
		);
	}
}

AgentActivity.contextType = AuthContext;

// @ts-ignore issue with service containers
export default withAgentService(AgentActivity);
