import InputPhoneNumber from 'shared/Forms/Inputs/PhoneNumber/InputPhoneNumber';
import React from 'react';

export default class InputLandlinePhone extends React.Component {
	render() {
		return <InputPhoneNumber name={this.props.name} type="landline" />;
	}
}

InputLandlinePhone.defaultProps = {
	name: 'landlinePhoneNumber',
};
