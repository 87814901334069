/* eslint-disable max-classes-per-file, react/no-multi-comp */
// Multiple classes and components used for HOC
/* eslint-disable react/no-unused-state -- TODO: clean up state */

/**
 * @typedef {import('../../App/shared/HomeownerService/Agent').default} Agent
 * @typedef {import('./Context').default} Context
 * @typedef {import('../../App/shared/HomeownerService/Homeowner').HomeownerAgent} HomeownerAgent
 * @typedef {import('../../App/shared/HomeownerService/Homeowner').HomeownerTeamMember} HomeownerTeamMember
 * @typedef {import('@mooveguru/js-utilities/types/KeyedObject').default} KeyedObject
 * @typedef {import('./Props').default} Props
 * @typedef {import('./State').default} State
 * @typedef {import('../../shared/ThemeService/Theme').default} Theme
 * @typedef {import('../ThemeService/ThemeService').default} ThemeResults
 */

import { generateTextColor, setCssCustomProperty } from './theme-utilities';
import { toAbsoluteUrl } from '../../utils/toAbsoluteUrl';
import { translate } from '../../App/Internationalization';
import {
	withHomeownerService,
	withThemeService,
} from '../../service-container';
import { withSettings } from '../../shared/SettingProvider/SettingProvider';
import app from '../../config/local/app';
import captureError from '../../utils/captureError';
import images from '../../config/local/images';
import LoadingScreen from '../LoadingScreen';
import React from 'react';

/** @type {React.Context<Context>} */
// @ts-ignore
export const ThemeContext = React.createContext({
	agentEmail: null,
	agentGroup: null,
	agentHeadshotUrl: `${process.env.PUBLIC_URL}/media/images/avatars/avatar-transparent.png`,
	agentName: null,
	agentOccupation: 'lender',
	agentPhoneNumber: null,
	agentSlug: null,
	appTitle: app.title,
	colorDark: null,
	colorLight: null,
	colorMain: null,
	errorMessage: null,
	faviconUrl: app.meta.favicon,
	/* eslint-disable-next-line no-unused-vars -- default function will be updated */
	getAndSetActiveTheme: (userType) => Promise.resolve(),
	getAndSetHomeownerAgent: () => Promise.resolve(),
	/* eslint-disable-next-line no-unused-vars -- default function will be updated */
	getAndSetThemeBySlug: (slug) => Promise.resolve(),
	isGettingDomainTheme: true,
	logoUrl: app.logo,
	parentEntitySlug: null,
});

/** @extends {React.Component<Props, State>} */
export class ThemeProvider extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			agentEmail: null,
			agentGroup: null,
			agentHeadshotUrl: `${process.env.PUBLIC_URL}${images.avatar}`,
			agentId: null,
			agentName: null,
			agentOccupation: 'lender',
			agentPhoneNumber: null,
			agentSlug: null,
			appTitle: 'HomeKeepr',
			colorDark: null,
			colorLight: null,
			colorMain: null,
			errorMessage: null,
			faviconUrl: `${process.env.PUBLIC_URL}${images.brand.favicon}`,
			getAndSetActiveTheme: this.getAndSetActiveTheme.bind(this),
			getAndSetHomeownerAgent: this.getAndSetHomeownerAgent.bind(this),
			getAndSetHomeownerTeam: this.getAndSetHomeownerTeam.bind(this),
			getAndSetThemeBySlug: this.getAndSetThemeBySlug.bind(this),
			isGettingDomainTheme: true,
			logoUrl: `${process.env.PUBLIC_URL}${images.brand.logo}`,
			networkVendors: false,
			networkVendorsIdList: [],
			parentEntitySlug: null,
			teamList: [],
		};
	}

	/**
	 * @param {unknown} error
	 * @returns {void}
	 */
	#error(error) {
		captureError(error);

		this.setState({ errorMessage: translate('global.error') });
	}

	/** @returns {Promise<void>} */
	async componentDidMount() {
		await this.getAndSetThemeBySlug('domain');
	}

	/**
	 * @param {string} slug
	 * @returns {Promise<void>}
	 */
	async getAndSetThemeBySlug(slug) {
		try {
			const theme = await this.props.themeService.getThemeBySlug(slug);
			// @ts-ignore  -- Will be resolved when theme is consistent
			this.setThemeAndSettings(theme, slug);
		} catch (error) {
			this.#error(error);
		}
	}

	/**
	 * @param {'administrator'|'agent'|'homeowner'} userType
	 * @returns {Promise<void>}
	 */
	async getAndSetActiveTheme(userType) {
		try {
			const theme = await this.props.themeService.getActiveTheme(
				userType
			);

			this.setTheme(theme);
		} catch (error) {
			this.#error(error);
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async getAndSetHomeownerTeam() {
		if (!this.props.settings.features.get('group:show_cobranding')) {
			return;
		}
		try {
			const teamList =
				await this.props.homeownerService.getHomeownerTeam();

			this.setState({
				teamList,
			});
		} catch (error) {
			this.setState({
				teamList: [],
			});

			// @ts-ignore, type check does not read local file
			if ((process.env.NODE_ENV ?? 'local') !== 'local') {
				/* eslint-disable-next-line no-console -- Only consoles in local envs */
				console.warn('No agent found.', { error });
			}
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async getAndSetHomeownerAgent() {
		try {
			const agent = await this.props.homeownerService.getHomeownerAgent();

			this.setPrimaryAgent(agent);
		} catch (error) {
			this.setState({
				agentEmail: null,
				agentGroup: null,
				agentHeadshotUrl: `${process.env.PUBLIC_URL}${images.avatar}`,
				agentId: null,
				agentName: null,
				agentPhoneNumber: null,
				agentSlug: null,
			});

			// @ts-ignore, type check does not read local file
			if ((process.env.NODE_ENV ?? 'local') !== 'local') {
				/* eslint-disable-next-line no-console -- Only consoles in local envs */
				console.warn('No agent found.', { error });
			}
		}
	}

	/**
	 * @param {Theme} theme
	 * @returns {void}
	 */
	setTheme(theme) {
		this.setState(
			(state) => ({
				colorDark: theme.color.dark ?? state.colorDark,
				colorLight: theme.color.light ?? state.colorLight,
				colorMain: theme.color.main ?? state.colorMain,
				faviconUrl: theme.favicon?.url ?? state.faviconUrl,
				isGettingDomainTheme: false,
				logoUrl: theme.logo?.url ?? state.logoUrl,
			}),
			this.setCssCustomProperties
		);
	}

	/**
	 * @param {import('../ThemeService/ThemeService').ThemeResults} theme
	 * @param {string=} slug
	 * @returns {void}
	 */
	setThemeAndSettings(theme, slug) {
		this.setState(
			/* eslint-disable complexity -- TODO: Simplify theme initiation */
			(state) => ({
				agentHeadshotUrl:
					theme.agent?.headshotUrl ?? state.agentHeadshotUrl,
				agentName: theme.agent?.name ?? state.agentName,
				agentSlug: theme.agent?.slug ?? null,
				appTitle: theme.appTitle ?? state.appTitle,
				colorDark: theme.color.dark ?? state.colorDark,
				colorLight: theme.color.light ?? state.colorLight,
				colorMain: theme.color.main ?? state.colorMain,
				errorMessage: null,
				faviconUrl: theme.faviconUrl ?? state.faviconUrl,
				isGettingDomainTheme: false,
				logoUrl: theme.logoUrl ?? state.logoUrl,
				parentEntitySlug: slug ?? null,
			}),
			this.setCssCustomProperties
		);
	}

	/**
	 * @returns {void}
	 */
	setCssCustomProperties() {
		/** @type {[string, string|null][]} */
		const colorList = [
			['theme-color-light', this.state.colorLight],
			['theme-color', this.state.colorMain],
			['theme-color-dark', this.state.colorDark],
		];

		colorList.forEach(([property, value]) => {
			if (!value) {
				return;
			}

			setCssCustomProperty(property, value);
		});

		/** @type {[string, string|null, string][]} */
		const textList = [
			[
				'theme-color-fg-light',
				this.state.colorLight,
				'theme-filter-light',
			],
			['theme-color-fg', this.state.colorMain, 'theme-filter'],
			['theme-color-fg-dark', this.state.colorDark, 'theme-filter-dark'],
		];

		// This will turn the image "white"
		const knockout = 'brightness(0) grayscale(0)';

		textList.forEach(([property, value, knockoutProperty]) => {
			if (!value) {
				return;
			}

			const color = generateTextColor(value);

			setCssCustomProperty(property, color);
			setCssCustomProperty(
				knockoutProperty,
				['#000', '#000000'].includes(color)
					? knockout
					: `${knockout} invert(1)`
			);
		});
	}

	/**
	 * @param {HomeownerAgent} agent
	 * @returns {void}
	 */
	setPrimaryAgent(agent) {
		const headshot = agent?.headshotUrl ?? images.avatar;
		const agentHeadshotUrl = toAbsoluteUrl(headshot);

		const agentPhoneNumber = ThemeProvider.determinePhoneNumber(
			agent.phone
		);

		// TeamList will be be set to primary agent if show_cobranding feature flag is off.
		if (!this.props.settings.features.get('group:show_cobranding')) {
			this.setState({
				teamList: [
					{
						email: agent.email,
						groupName: agent.primaryGroup?.name ?? null,
						headshotUrl: agentHeadshotUrl,
						name: agent.name,
						occupation: agent.occupation ?? 'lender',
						phoneNumber: agentPhoneNumber,
					},
				],
			});
		}

		this.setState({
			agentEmail: agent.email,
			agentGroup: agent.primaryGroup ?? null,
			agentHeadshotUrl: agentHeadshotUrl,
			agentId: agent.id,
			agentName: agent.name,
			agentOccupation: agent.occupation ?? 'lender',
			agentPhoneNumber: agentPhoneNumber,
			agentSlug: agent.slug,
			networkVendors: agent.networkVendors,
			networkVendorsIdList: agent.networkVendorsIdList,
		});
	}

	/**
	 * @param {{mobile:string|null, landline:string|null}} phone
	 * @returns {string | null}
	 */
	static determinePhoneNumber(phone) {
		return phone?.mobile ?? phone?.landline ?? null;
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		let content = this.props.children;

		if (this.state.isGettingDomainTheme) {
			content = <LoadingScreen />;
		}

		return (
			<ThemeContext.Provider value={this.state}>
				{content}
			</ThemeContext.Provider>
		);
	}
}

// @ts-ignore TODO: Add types when service container types are resolved
export function withTheme(Component) {
	class ThemedComponent extends React.Component {
		render() {
			/* eslint-disable-next-line react/jsx-props-no-spreading -- necessary for HOC */
			return <Component theme={this.context} {...this.props} />;
		}
	}

	ThemedComponent.contextType = ThemeContext;

	return ThemedComponent;
}

// @ts-ignore TODO: Remove when service container types are fixed
export default withHomeownerService(
	withThemeService(withSettings(ThemeProvider))
);
