/**
 * @typedef {import('./TransferPropertyType').Props} Props
 * @typedef {import('./TransferPropertyType').State} State
 */

import { Redirect } from 'react-router';
import { translate } from '../../Internationalization';
import {
	withAgentContactService,
	withPropertyService,
} from '../../../service-container';
import { withAuth } from '../../../shared/AuthProvider';
import { withProperty } from '../PropertyProvider';
import * as validators from '../../../shared/validators';
import * as yup from 'yup';
import BaseForm from '../../../shared/Forms/BaseForm';
import captureError from '../../../utils/captureError';
import FieldEmail from '../../../shared/Forms/Fields/FieldEmail';
import FieldFirstName from '../../../shared/Forms/Fields/Name/FieldFirstName';
import FormErrorMessages from '../../../shared/Forms/Messages/FormErrorMessages';
import images from '../../../config/local/images';
import InlineSVG from 'react-inlinesvg/esm';
import LinkButton from '../../../shared/LinkButton';
import Loading from '../../../shared/Loading';
import paths from '../../../config/local/paths';
import React from 'react';
import SubmitButton from '../../../shared/Forms/Inputs/SubmitButton';

const validationSchema = yup.object().shape({
	confirmEmail: yup
		.string()
		?.oneOf(
			[yup.ref('email'), null],
			'Email and confirm email must match.'
		),
	email: validators.email,
	firstName: validators.required('First Name'),
});

const initialValues = {
	confirmEmail: '',
	email: '',
	firstName: '',
	property: '',
};

const successMessage = translate(
	'homeowner.pages.properties.transfer.submit_message'
);

/** @extends {React.Component<Props>} */
export class TransferProperty extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			contacts: [],
			errorMessage: '',
			formComplete: false,
			isGettingData: true,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	/**  @returns {Promise<void>} */
	async componentDidMount() {
		/** @type {State['contacts']} */
		let contactList = [];

		if (this.props.auth.roles.includes('agent')) {
			const response = await this.props.agentContactService.getContacts();
			contactList = response?.contacts ?? [];
		}

		this.setState({
			contacts: contactList,
			isGettingData: false,
		});
	}

	/**
	 * @param {{email: string; firstName: string}} values
	 * @returns {Promise<void>}
	 */
	async handleSubmit(values) {
		let relatedContact;

		if (this.props.auth.roles.includes('agent')) {
			relatedContact =
				this.state.contacts.find(
					(contact) => contact.email === values.email
				) ?? null;
		}

		try {
			await this.props.propertyService.transferProperty(
				values,
				this.props.property.selectedProperty.id,
				this.props.auth.accessToken,
				relatedContact?.id ?? null
			);

			this.setState({ formComplete: true });
		} catch (error) {
			captureError(error);
			this.setState({
				errorMessage: translate(
					'homeowner.pages.properties.transfer.submit_error'
				),
			});
		}
	}

	/**  @returns {JSX.Element} */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		if (this.state.formComplete) {
			return (
				<Redirect
					to={{
						pathname: paths.app.homeowner.properties.root,
						state: { message: successMessage },
					}}
				/>
			);
		}

		return (
			<React.Fragment>
				<h1 className="hl-ms-6 mt-10 mb-6">
					{translate('homeowner.pages.properties.transfer.title')}
				</h1>

				<BaseForm
					initialValues={initialValues}
					onSubmit={this.handleSubmit}
					validationSchema={validationSchema}
				>
					<fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
						<FieldFirstName required={true} />
						<FieldEmail required={true} />
						<FieldEmail
							label="Confirm Email"
							name="confirmEmail"
							required={true}
						/>
					</fieldset>

					<section className="my-6 form-alert form-alert-warning w-fit">
						<h2 className="title">
							<i className="icon icon-xs">
								<InlineSVG src={images.icons.alert} />
							</i>
							{translate('global.messages.warning')}
						</h2>

						<div className="text rich-text">
							{translate(
								'homeowner.pages.properties.transfer.disclaimer'
							)}
						</div>
					</section>

					<FormErrorMessages messages={[this.state.errorMessage]} />

					<footer className="mx-auto sm:ml-0 button-group w-min mt-6">
						<SubmitButton>
							{translate(
								'homeowner.pages.properties.form.submit'
							)}
						</SubmitButton>
						<LinkButton
							icon={images.icons.delete}
							size="large"
							to={paths.app.homeowner.properties.root}
						>
							{translate(
								'homeowner.pages.properties.form.cancel'
							)}
						</LinkButton>
					</footer>
				</BaseForm>
			</React.Fragment>
		);
	}
}

export default withAuth(
	withAgentContactService(withPropertyService(withProperty(TransferProperty)))
);
