const baseUrl = 'https://assets.yourhomehub.com';
const baseImagePath = `${baseUrl}/images`;

const pathList = {
	bannerAds: `${baseImagePath}/ads/banners`,
	templates: `${baseUrl}/templates`,
};

export default {
	fonts: 'https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;700&family=Pragati+Narrow:wght@400;700&family=Red+Hat+Text:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap',
	images: {
		ads: {
			banners: {
				cinch: `${pathList.bannerAds}/cinch-logo.svg`,
			},
		},
	},
	imports: {
		templates: {
			agents: {
				basic: `${pathList.templates}/import-agents-template-basic.csv`,
			},
			contacts: {
				basic: `${pathList.templates}/import-contacts-template-basic.csv`,
				full: `${pathList.templates}/import-contacts-template-advanced.csv`,
			},
		},
	},
};
