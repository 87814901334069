/**
 * @typedef {import('./InputSearchableSelectComponent').Props} Props
 * @typedef {import('./InputSearchableSelectComponent').State} State
 * @typedef {import('@mooveguru/js-utilities/types/KeyedObject').default} KeyedObject
 * @typedef {import("react-select").MultiValue<string> | import("react-select").SingleValue<string>} Event
 * @typedef {import('formik').FormikValues} Formik
 */
import { Field } from 'formik';
import React from 'react';
import Select from 'react-select';

/** @extends {React.Component<Props, State>} */
export default class InputSearchableSelect extends React.Component {
	static defaultProps = {
		isMultipleSelect: false,
	};

	/** @param {Props} props */
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	/**
	 * @param {Event} event
	 * @param {Formik} formik
	 * @returns {void}
	 */
	onChange(event, formik) {
		// @ts-ignore - type declared
		formik.form.setFieldValue(this.props.name, event.value);
	}

	/**
	 * @param {Formik} formik
	 * @returns {void}
	 */
	onBlur(formik) {
		formik.form.setFieldTouched(this.props.name, true);
	}

	/**
	 * @returns {JSX.Element}
	 */
	render() {
		const customStyles = {
			/**
			 * @todo Is there a style list type?
			 * @param {KeyedObject} provided
			 * @returns {KeyedObject}
			 */
			control: (provided) => ({
				...provided,
				borderColor: 'var(--theme-color)',
				borderRadius: 0,
				fontFamily: 'inherit',
				height: '100%',
			}),
		};

		return (
			<Field name={this.props.name}>
				{
					/* eslint-disable jsdoc/require-param-name -- Error: `Missing JSDoc @param "event"`, param doesn't exist, not reading `onBlur` and `onChange` callbacks correctly */
					/**
					 * @param {Formik} renderProps
					 * @returns {JSX.Element}
					 */
					/* eslint-enable jsdoc/require-param-name */
					(renderProps) => (
						<Select
							className={this.props.className ?? 'search-select'}
							defaultValue={this.props.defaultValue}
							id={this.props.id ?? this.props.name}
							isMulti={this.props.isMulti}
							name={this.props.name}
							onBlur={
								this.props.onBlur
									? (event) => this.props.onChange(event, renderProps) // prettier-ignore
									: () => this.onBlur(renderProps)
							}
							onChange={
								this.props.onChange
									? (event) => this.props.onChange(event, renderProps) // prettier-ignore
									: (event) => this.onChange(event, renderProps) // prettier-ignore
							}
							options={this.props.options}
							placeholder={this.props.placeholder}
							styles={customStyles}
						/>
					)
				}
			</Field>
		);
	}
}
