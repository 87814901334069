import { FormikContext } from 'formik';
import React from 'react';
import { translate } from '../../../App/Internationalization';
import images from '../../../config/local/images';
import BaseButton from '../../BaseButton/BaseButton';

export default class SubmitButton extends React.Component {
	render() {
		return (
			<BaseButton
				className={this.props.className}
				disabled={this.context.isSubmitting || this.props.disabled}
				icon={this.props.icon ?? images.icons.submit}
				iconClass={this.props.iconClass}
				id={this.props.id}
				size={this.props.size}
				type="submit"
			>
				{this.props.children ??
					translate('global.forms.buttons.submit')}
			</BaseButton>
		);
	}
}

SubmitButton.contextType = FormikContext;

SubmitButton.defaultProps = {
	className: 'button',
	size: 'large',
};
