import images from './images';

export default {
	contactUs: 'mailto:support@homekeepr.com',
	logo: images.brand.logo,
	meta: {
		facebook: {
			appId: '2444871092244116',
		},
		favicon: images.brand.favicon,
	},
	title: 'HomeKeepr',
	url: 'https://homekeepr.com',
};
