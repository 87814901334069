/**
 * @typedef {import('../App/shared/AgentService/Agent').AgentPersonalData} Agent
 * @typedef {import('./FeaturedBlogsProps').default} Props
 * @typedef {import('./FeaturedBlogsState').default} State
 */

import { BaseSVG } from '../shared/BaseSVG';
import { translate } from '../App/Internationalization';
import { withAgentService, withBlogService } from '../service-container';
import { withTheme } from '../shared/ThemeProvider';
import BlogPostExcerptCard from '../shared/BlogPostExcerptCard';
import captureError from 'utils/captureError';
import config from '../config/local/blog';
import images from '../config/local/images';
import Loading from '../shared/Loading';
import NoContent from '../shared/NoContent';
import React from 'react';

/** @extends {React.Component<Props, State>} */
class FeaturedBlogs extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			agentHeadshotUrl: '',
			agentName: '',
			agentSlug: '',
			errorMessage: '',
			isGettingData: true,
			postExcerptList: [],
		};
	}

	/** @returns {Promise<void>} */
	async componentDidMount() {
		try {
			await this.#setAgent();
			const result = await this.props.blogService.getPostExcerpts(
				'',
				1,
				config.perPage.recent
			);

			this.setState({
				isGettingData: false,
				postExcerptList: result.posts,
			});
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate(
					'homeowner.dashboard.marketplace.error'
				),
				isGettingData: false,
			});
		}
	}

	/** @returns {Promise<void>} */
	async #setAgent() {
		if (this.props.userRole === 'agent') {
			const agent = await this.props.agentService.getAgentPersonalData();

			this.setState({
				agentHeadshotUrl: agent.headshot ? `/${agent.headshot}` : null,
				agentName: `${agent.name?.first} ${agent.name?.last}`.trim(),
				agentSlug: agent.slug,
			});

			return;
		}

		this.setState({
			agentHeadshotUrl: this.props.theme.agentHeadshotUrl ?? '',
			agentName: this.props.theme.agentName ?? '',
			agentSlug: this.props.theme.agentSlug ?? '',
		});
	}

	/** @returns {JSX.Element[] | null} */
	#getBlogPosts() {
		if (this.state.postExcerptList.length) {
			return this.state.postExcerptList.map((postExcerpt) => (
				<BlogPostExcerptCard
					agentSlug={this.state.agentSlug}
					authorName={this.state.agentName || postExcerpt.author.name}
					content={postExcerpt.content}
					date={postExcerpt.date}
					headshotUrl={this.state.agentHeadshotUrl}
					imageUrl={postExcerpt.imageUrl}
					key={postExcerpt.title}
					slug={postExcerpt.slug}
					title={postExcerpt.title}
				/>
			));
		}
		return null;
	}

	/** @returns {JSX.Element} */
	render() {
		if (this.state.isGettingData) {
			return <Loading />;
		}

		if (this.state.errorMessage) {
			return <NoContent message={this.state.errorMessage} />;
		}

		return (
			<section className="mt-10">
				<header className="h-ms-4 mb-4">{this.props.title}</header>
				{!!this.props.showFeatureImage && (
					<BaseSVG path={images.blog.header} />
				)}

				<section className="listing listing-col-size-3">
					{this.#getBlogPosts()}
				</section>
			</section>
		);
	}
}

export default withAgentService(withBlogService(withTheme(FeaturedBlogs)));
